import React, { useEffect } from "react";

import ReplySymbol from "../../images/quill_reply.svg";
import { useState } from "react";
import "../../styles/ReviewSection.css";
// import dot from "../../images/dot.svg";
import LoadingSign from "../../images/Spinner.svg";

import EmptyStateImage from "../../images/Frame.png";

import moment from "moment";
import { usePopup } from "../../components/PopupContext";
import httpsignup from "../../js/http-lms_userprofile";

const ReviewSection = ({ mentorId }) => {
  const [selectedRating, setSelectedRating] = useState("All");
  const [visibleCount, setVisibleCount] = useState(4);

  const [replies, setReplies] = useState({});

  const [showModal, setShowModal] = useState(false);
  const [currentReview, setCurrentReview] = useState(null);
  const [reply, setReply] = useState("");
  const [reviews, setReviews] = useState([]);
  const {} = usePopup();

  useEffect(() => {
    try {
      const fetchReviews = async () => {
        const response = await httpsignup.get(
          `/mentor/getAllReviews?mentorId=${mentorId}`
        );
        if (response && response.data) {
          setReviews(response.data);
        }
        const ratings = await httpsignup.get(
          `/mentor/getRating?mentorId=${mentorId}`
        );
        if (ratings && ratings.data) {
          setReviews(response.data);
        }
      };
      fetchReviews();
    } catch (error) {
      console.log(error);
    }
  }, []);

  const [selectedReply, setSelectedReply] = useState({
    id: null,
    review: null,
  });

  const handleFilterChange = (event) => {
    setSelectedRating(event.target.value);
  };

  const filteredReviews = reviews?.filter((review) => {
    if (selectedRating === "All") return true;
    return review.rating === parseInt(selectedRating);
  });

  const handleLoadMore = () => {
    setVisibleCount((prevCount) => prevCount + 3);
  };

  const calculateRatingStats = (reviews) => {
    const totalReviews = reviews.length;
    const ratingCounts = [0, 0, 0, 0, 0];

    const totalRating = reviews?.reduce((sum, review) => {
      ratingCounts[review.rating - 1]++;
      return sum + review.rating;
    }, 0);

    const averageRating = totalReviews > 0 ? totalRating / totalReviews : 0;

    return { averageRating, ratingCounts, totalReviews };
  };
  const posted = (time) => {
    const now = moment();
    const givenTime = moment(time);
    const duration = moment.duration(now.diff(givenTime));
    return duration.asHours() < 24
      ? givenTime.fromNow()
      : `${Math.floor(duration.asDays())} days ago`;
  };
  const { averageRating, ratingCounts, totalReviews } =
    calculateRatingStats(reviews);

  return (
    <>
      <div className="o-r-c">
        <div
          className="overall-rating-section mx-3 bg-white p-5 shadow mb-4"
          style={{
            borderRadius: "15px",
            width: "100%",
          }}
        >
          <h3 className="" style={{ textAlign: "left" }}>
            Overall Rating
          </h3>
          <div className="rating-section">
            <div className="average-rating-box ">
              <div className="average-rating">{averageRating.toFixed(1)}</div>
              <div className="rating-stars">
                {[...Array(5)].map((_, i) => (
                  <span
                    key={i}
                    className={`star ${
                      i < Math.round(averageRating) ? "filled" : ""
                    }`}
                  >
                    &#9733;
                  </span>
                ))}
              </div>
              <div className="overall-rating-text">Mentor Rating</div>
            </div>
            <div className="rating-breakdown">
              {[5, 4, 3, 2, 1].map((star, index) => (
                <div className="d-flex align-items-center mb-2" key={index}>
                  <div
                    className="d-flex align-items-center"
                    style={{ width: "150px" }}
                  >
                    {[...Array(5)].map((_, i) => (
                      <span
                        key={i}
                        className={`star ${i < star ? "filled" : ""}`}
                      >
                        &#9733;
                      </span>
                    ))}
                    <span className="ms-3 star-rating-text">
                      {star} Star Rating
                    </span>
                  </div>
                  <div className="progress-review ms-5">
                    <div
                      className="progress-bar-review"
                      style={{
                        width: `${
                          (ratingCounts[star - 1] / totalReviews) * 100
                        }%`,
                      }}
                    ></div>
                  </div>
                  <div className="ms-2">
                    {((ratingCounts[star - 1] / totalReviews) * 100).toFixed(0)}
                    %
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div
          className=" bg-white p-5 shadow mb-4 o-r-p"
          style={{ borderRadius: "15px", marginTop: "2rem", width: "100%" }}
        >
          <div className="d-flex justify-content-between">
            <h3>Students Feedback</h3>
            <select
              className="form-select w-auto text-muted"
              onChange={handleFilterChange}
            >
              <option value="All">All Ratings</option>
              <option value="5">5 Star Rating</option>
              <option value="4">4 Star Rating</option>
              <option value="3">3 Star Rating</option>
              <option value="2">2 Star Rating</option>
              <option value="1">1 Star Rating</option>
            </select>
          </div>

          {filteredReviews?.length === 0 && (
            <div className="text-center mt-5">
              <img
                src={EmptyStateImage}
                alt="No reviews yet"
                className="review-empty-img"
              />

              <div className="reviews-empty-msg1 mt-3">
                You don?t have any reviews yet, but that's about to change!
              </div>
              <div className="reviews-empty-msg2">
                Connect with eager learners to get reviews.
              </div>
            </div>
          )}
          {filteredReviews?.slice(0, visibleCount).map((review, index) => (
            <div
              className="r-c mt-3"
              style={{
                border: "none",
                borderTop: "1px solid #E9EAF0",
                borderRadius: "0px",
                maxWidth: "100%",
                width: "100%",
                minHeight: "auto",
                // width:'60%'
              }}
              key={index}
            >
              <div className="card-body mt-2" style={{ paddingBottom: "0px" }}>
                <div className="d-flex align-items-center">
                  <div>
                    <div
                      className="card-title d-flex align-items-start m-auto reviewer-title"
                      style={{ gap: "0.5rem" }}
                    >
                      <div>
                        <img
                          src={review.userProfileUrl}
                          alt="icon"
                          className="review-profile-place"
                        />
                      </div>
                      <div>
                        <div className="d-flex">
                          <div>
                            {review.userProfileName} rated you {review.rating}{" "}
                            Star
                          </div>
                          <div>
                            <p className="card-text">
                              <div
                                className="text-muted d-flex"
                                style={{ fontSize: "12px", marginLeft: "1rem" }}
                              >
                                <div className="dot">
                                  {/* <img src={dot} /> */}
                                </div>{" "}
                                {posted(review.createdDate)}
                              </div>
                            </p>
                          </div>
                        </div>
                        <div className="d-flex mt-1 mb-1">
                          {[...Array(review.rating)].map((_, i) => (
                            <span key={i} className="star filled">
                              &#9733;
                            </span>
                          ))}
                        </div>
                        <div className="card-text mt-2 text-start  text-muted">
                  {review.review}
                </div>
                {review.reviewReplyVO !== null && (
                  <div class="reply-sectionpage">
                    <img
                      src={review.reviewReplyVO.mentorProfileUrl}
                      alt="Vijay Aurora"
                      class="reply-icon"
                    />
                    <div>
                      <div class="reply-header">
                        <span class="reply-name">
                          {review.reviewReplyVO.mentorName}
                        </span>
                        <span class="reply-time"> {posted(review.reviewReplyVO.replyCreationDate)}</span>
                      </div>
                      <div class="reply-text">{review.reviewReplyVO.reply}</div>
                    </div>
                  </div>
                )}
                      </div>
                    </div>

                   
                  </div>
                </div>
               

                {/* <div className="d-flex justify-content-end">
                  <button
                    className={`btn btn-link btn-reply ${
                      replies[review.id] ? "text-muted" : ""
                    }`}
                    style={{ marginLeft: "1rem" }}
                    
                  >
                    <img
                      src={ReplySymbol}
                      style={{ opacity: replies[review.id] ? 0.5 : 1 }}
                      alt="icon"
                    />

                    <div style={{ color: "#000" }}>
                      {review.reviewReplyVO !== null ? "You Replied" : "Reply"}
                    </div>
                  </button>
                </div> */}
              </div>
            </div>
          ))}
          {visibleCount < filteredReviews?.length && (
            <div className="text-center mt-4">
              <button className="loading-btn" onClick={handleLoadMore}>
                Load More <img src={LoadingSign} alt="loading" />
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ReviewSection;
