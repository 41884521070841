import React, { useState, useEffect, useRef } from 'react'
import { NewHeader } from '../components/NewHeader'
import "../styles/Allcourse.css";
import frame from "../images/Frame.png"
import amazon from "../images/amazon.svg"
import google from "../images/Google.svg"
import apple from "../images/Apple.svg"
import samsung from "../images/Samsung.svg"
import microsoft from "../images/Microsoft.png"
import cardimage from "../images/cardimage.png"
import network from "../images/network.png"
import onlinelab from "../images/online lab.png"
import jobopportunity from "../images/jobopportunities.png"
import mailsupport from "../images/mailsupport.png"
import Avatar1 from "../images/Avatar1.png"
import Avatar2 from "../images/Avatar2.png"
import Avatar3 from "../images/Avatar3.png"
import Commas from "../images/commas.svg"
import calender from "../images/calendar-date.png"
import locker from "../images/locker-room-suitcase-umbrella.png"
import modulepuzz from "../images/module-puzzle.png"
import programmingkeyboard from "../images/programming-keyboard-type.png"
import image1235 from "../images/image 1235.png"
import thumbnail from "../images/Thumbnail.png"
import programmingteam from "../images/programming-team-chat-3.png"
import redalarm from "../images/redalarm.png"
import arrowright from "../images/arrow-right.png"
import starimage from "../images/5 stars.png"
import rsimage from "../images/rsimage3500.png"
import Marquee from "react-fast-marquee";
import { useNavigate } from 'react-router-dom';
import { NewFooter } from '../components/Footer/NewFooter';
import { element } from 'screenfull';
import { Link } from 'react-router-dom';
import httpprograms from "../js/http-lms_programs"
import Spinner from 'react-bootstrap/Spinner';
import moment from "moment/moment";
import NoRecFound from "../components/NORecFound";
import bharath from '../images/bahrath.webp'
import ErikillaAshish from '../images/Erikilla_Ashish.webp'
import EthuriAbhiram from '../images/Ethuri.Abhiram.webp'
import James from '../images/James.webp'
import { trackEvent } from '../facebookPixel';



export default function Allcourse() {
  const [activeTab, setActiveTab] = useState(0);
  const [progress, setProgress] = useState(0);
  const [imageSrc, setImageSrc] = useState(image1235); // Initial image source
  const [isMobile, setIsMobile] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0); // State to track active index
  const containerRef = useRef(null); // Ref to the container element
  const [categoryData, setCategoryData] = useState([])
  const [selectionId, setSelectionId] = useState(0);
  const [error, setError] = useState()
  const [loading, setLoading] = useState(0)
  const [refresh, setRefresh] = useState(false)
  const [data, setData] = useState([])

  const headings = ['Design', 'Front End', 'Data Analysis', 'Machine Learning', 'AI', 'Development', 'Cybersecurity'];
  const toggleActiveIndex = () => {
    setActiveIndex((prevIndex) => (prevIndex + 1) % headings.length); // Toggle to next index
  };
  useEffect(() => {
    const isMobileScreen = window.innerWidth <= 540;

    if (isMobileScreen) {
      const interval = setInterval(toggleActiveIndex, 3000);

      return () => clearInterval(interval);
    }
  }, []);
  useEffect(() => {
    if (containerRef.current) {
      const activeHeading = containerRef.current.children[activeIndex];
      if (activeHeading) {
        activeHeading.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, [activeIndex]);





  const tabDuration = 4000;
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);



  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress < 100) {
          return prevProgress + 100 / (tabDuration / 1000);
        } else {
          clearInterval(interval);
          changeTab();
          return 0;
        }
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [activeTab, tabDuration]);

  const changeTab = () => {
    setActiveTab((prevTab) => {
      const nextTab = prevTab < 4 ? prevTab + 1 : 0;
      handleChangeImage(nextTab);
      return nextTab;
    });
  };

  const handleChangeImage = (index) => {
    // switch (index) {
    //   case 0:
    //     setImageSrc(image1235);
    //     break;
    //   case 1:
    //     setImageSrc(frame);
    //     break;
    //   case 2:
    //     setImageSrc(jobopportunity);
    //     break;
    //   case 3:
    //     setImageSrc(image1235);
    //     break;
    //   case 4:
    //     setImageSrc(frame);
    //     break;
    //   default:
    //     setImageSrc(jobopportunity);
    // }
    if (index === 0) {
      setImageSrc(image1235);
    } else if (index === 1) {
      setImageSrc(frame);
    } else if (index === 2) {
      setImageSrc(image1235);
    }
    else if (index === 3) {
      setImageSrc(frame);
    }
    else {
      setImageSrc(image1235);
    }
  };

  const resetProgress = () => {
    setProgress(0);
  };

  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" })
    }
  }
  const navigate = useNavigate();
  const projectPage = () => {
    navigate("/projects")
  }

  useEffect(() => {
    trackEvent('ViewContent');
    getCategoryList()


  }, [])

  function handleClickStart() {

    navigate("/my-course");
  }

  function handleClick(pId) {
    sessionStorage.setItem("programId", pId)
    navigate("/videos");
  }

  function BuyCourse(pId) {
    sessionStorage.setItem("courseProgramId", pId)
    //navigate("/coursePage");
  }
const [courseActiveTab,setCourseActiveTab] = useState(0);

  function programCards(id) {
    setCourseActiveTab(id);
    httpprograms.get("/usersLMS/getAllCoursesByCategoryId?categoryId=" + id)
      .then((response) => {
        console.log("getAllCourseList : ", JSON.stringify(response.data))
        setData(response.data)
        setLoading(1)
        setRefresh(false)
        setSelectionId(id)
      }).catch((err) => {
        setLoading(1)
        setError("Bad request")
      })

  }

  function getCategoryList() {
    httpprograms.get("/usersLMS/getAllCategoryNames")
      .then((response) => {
        console.log("getAllCourseList : ", JSON.stringify(response.data))
        setCategoryData(response.data)
        console.log(response.data[0].categoryId, "category id this one")
        programCards(response.data[0].categoryId)
        setSelectionId(response.data[0].categoryId)
        //setLoading(1)
        //setRefresh(false)
      }).catch((err) => {
        //setLoading(1)
        //setError("Bad request")
      })

  }

  function signUpPage() {
    navigate("/sign-up")
  }

  function buySignup(tag) {
    trackEvent('InitiateCheckout');
    navigate("/sign-up/" + tag)
  }

  function handleCourse(pId) {
    console.log(pId)
    // sessionStorage.setItem("courseProgramId", pId)
    // sessionStorage.getItem("courseProgramId")
    sessionStorage.setItem("seoUrl", pId)
    navigate("/courses/" + pId);
  }

  function freeTrailSignUp(pId){
    trackEvent('InitiateCheckout');
    sessionStorage.setItem("freeTrailId", pId)
    navigate("/sign-up")
  }

// const [visibleCount,setVisibleCount] = useState(3);

// const loadMore = () =>{
//   setVisibleCount(prev => prev + 3);
// }

// const seeLess = () => {
//   setVisibleCount(prev => prev - 3);
// }

const limitedText = (text,limit) =>{
  console.log("program name",text)
  if (text.length > limit){
    return text.substring(0,limit) + "..."
  }
  return text;
}

function purchaseType(type){
  sessionStorage.setItem("purchaseType" ,type)
}

return (
    <div className='parentcontainer'>
      <NewHeader />
      {/* firstdiv */}
      <section id='all-hero'>
        <div className='container firstdiv'>
          <div className='row justify-content-evenly p-3'>
            <div className='col-lg-6    firstdivleft align-content-center'>
              <h1 className="firstdivheading1 mb-3">Limitless Learning Awaits</h1>
              <div className='firstdivparahead'>
                {/* <p className=" firstdivpara mb-0">Start, switch, or advance your career with more than</p>
                <p className=" firstdivpara mb-0">6,900 courses, professional certificates, and degrees</p>
                <p className=" firstdivpara mb-0">from world class universities and companies.</p> */}
                <p className="firstdivpara">Launch, Shift, or Elevate Your Career with 50+ Courses, Certificates, and Degrees from Top Universities and Companies</p>
                <p className=" firstdivpara2 mb-0 ">50k+ students and professionals use <span style={{ color: "#5627FF" }}>Skillsquad.</span></p>
              </div>
              <div className='d-flex firstdivbtnhead  mt-5'>
                <button className='firstdivbuttonsignup' onClick={() => scrollToSection('course-card')}>Explore Courses </button>

                <Link to="/sign-in"><button className='firstdivbuttonlogin'  >Login</button></Link>
              </div>
            </div>
            <div className='col-lg-6  firstdivright'>
              <div className='row   d-flex justify-content-between align-content-center gap-3 '>
                <img src={frame} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* secondiv */}
      {/* industrycontainer */}
      <div className="container-fluid industrycontainer" >
        <div className='container'>
          <div className="row justify-content-evenly align-items-center" >
            <div className="col-lg-12 col-md-12 col-sm-12 mb-5">
              <p className="text-center industrycontainerpara">Trusted by World's Best Companies</p>
            </div>
            <div className="col-lg-2 col-sm-12 col-md-6 d-none d-lg-block">
              <img className="industrycontainerimage" src={amazon} alt="Amazon" />
            </div>
            <div className="col-lg-2 col-sm-12 col-md-6 d-none d-lg-block">
              <img className="industrycontainerimage" src={google} alt="Google" />
            </div>
            <div className="col-lg-2 col-sm-12 col-md-6 d-none d-lg-block">
              <img className="industrycontainerimage" src={apple} alt="Apple" />
            </div>
            <div className="col-lg-2 col-sm-12 col-md-6 d-none d-lg-block">
              <img className="industrycontainerimage" src={microsoft} alt="Microsoft" />
            </div>
            <div className="col-lg-2 col-sm-12 col-md-6 d-none d-lg-block">
              <img className="industrycontainerimage" src={samsung} alt="Samsung" />
            </div>
            <div className="marquee-wrapper" >
              <Marquee >
                <div className="col-lg-2 col-sm-12 col-md-6 " >
                  <img className="industrycontainerimage mx-4" src={amazon} alt="Amazon" />
                </div>
                <div className="col-lg-2 col-sm-12 col-md-6 mx-4">
                  <img className="industrycontainerimage" src={google} alt="Google" />
                </div>
                <div className="col-lg-2 col-sm-12 col-md-6 mx-4">
                  <img className="industrycontainerimage" src={apple} alt="Apple" />
                </div>
                <div className="col-lg-2 col-sm-12 col-md-6 mx-4">
                  <img className="industrycontainerimage" src={microsoft} alt="Microsoft" />
                </div>
                <div className="col-lg-2 col-sm-12 col-md-6 ">
                  <img className="industrycontainerimage" src={samsung} alt="Samsung" />
                </div>
              </Marquee>
            </div>
          </div>
        </div>
      </div>

      {/* thirdiv */}
      <h1 className='thirdivheading ' style={{ marginTop: "5rem" }}>Our Courses</h1>
      <p className='thirdivpara'>Learn Job Ready Courses With Hands On Learning And Projects</p>

     
      <div className='container'>
        <div className=' topheadings'>
          {categoryData.map((heading, index) => (
            <div key={index} onClick={() => { programCards(heading.categoryId) }} className={` ${heading.categoryId === selectionId ? 'activeCourse' : 'text-black'}`}>
              {heading.categoryName}
            </div>
          ))}
        </div>
      </div>
      <section id='course-card'>
        <div className=''>
          <div className='top-level-course-cards'>

            {loading === 0 ? <div className="d-flex py-5 justify-content-center w-100"><Spinner animation="border" variant="lightsecondary" /> </div> :
              error ? <div className='col-12 text-center'> <NoRecFound error="No programs found." /> </div> :

              data.map((res) => {
                  return (
                    <>
                    <div className=' thirdiv2'>
                      
                      <img className='thumbnailimage' src={res.programCourseImage} alt="" style={{ borderRadius: "15px 15px 0px 0px" ,cursor:"pointer"}} onClick={() => { handleCourse(res.skillsquadCourses.seoUrl) }}/>
                      <div className='thirdiv2main' >
                        <div onClick={() => { handleCourse(res.skillsquadCourses.seoUrl) }} style={{cursor:"pointer"}}>
                        <h1>{limitedText(res.programName,40)}</h1>

                        <div className=' paraimagediv '>
                          <span >{res.skillsquadCourses.rating}</span>
                          <img src={starimage} alt="" />
                           <span className='thirdivpara2'>({res.skillsquadCourses.ratingUser}) | {res.skillsquadCourses.duration} hours </span>
                          
                        </div>

                        {/* <p style={{ color: "#000", fontSize: "18px" }}>Learn: <span style={{ color: "#78909C", fontSize: "18px" }}>Figma,UI design,UX methods,</span></p> */}
                        <div style={{ border: "1px solid #5627FF", marginBottom: "1rem" }}></div>
                        <div className=' rsimagebuttondiv'>
                          <div className=' rsimagediv ' >
                            <h1 >Rs. {res.skillsquadCourses.selfPacedDiscountedAmount}</h1>
                            {/* <img className='rsimage'  src={rsimage} alt="" /> */}
                            <p className='strikelinepara'>Rs.{res.skillsquadCourses.selfPacedOrginalAmount}</p>

                          </div>
                          <button className='cardbutton'>{res.selfPacedPercentageOff}% OFF</button>

                        </div>
                        <div className='alarmimgpara '>
                          <img src={redalarm} alt="" />
                          <p className='alarmtext'>{moment(res.skillsquadCourses.deadlineTimer).fromNow(true)} left with this price!</p>
                        </div>
                        <div style={{ border: "1px solid #5627FF", marginTop: "1rem" }}></div>
                        <div className=' dateparaimgdiv'>
                          {/* <p className='thirdivpara2' >{moment(res.skillsquadCourses.batchStartDate).format("MMMM D, YYYY")}</p> */}
                          <div className=' parareadmorediv' style={{ cursor: "pointer" }}>
                            {/* <p className='readmore' onClick={() => { handleCourse(res.skillsquadCourses.seoUrl) }} >Read More</p> */}
                            {/* <img className='arrowrightimage' src={arrowright} alt="" /> */}
                          </div>
                        </div>
                        </div>
                        <div className=' freetrialbuynowdiv' style={{ marginBottom: "20px" }}>
                          {/* <button className='freetrialbtn' onClick={signUpPage}>Free Trial</button>
                         <button className='buynowbtn' onClick={() => { buySignup(res.skillsquadCourses.seoUrl) }}>Buy Now</button> */}
                          <div className="free-trail-button" onClick={()=> {freeTrailSignUp(res.skillsquadCourses.lmsProgramId)} }><span>Free Trial</span></div>
                          <div className="course-buy-now-button" onClick={() => { buySignup(res.skillsquadCourses.seoUrl); purchaseType(2); }}><span>Buy Now</span></div>
                        </div>
                      </div>
                      
                    </div>
                   
                    </>
                    


                  )
                 
                })}
           
          </div>
          {/* <div className="d-flex container justify-content-center align-items-center">
          {visibleCount < data.length && <div classname="">
                    <button className="btn btn-dark text-center load-more-button container" onClick={loadMore}>Load More</button>
                  </div>}
                  {visibleCount >= 6 && <div classname="" style={{marginLeft:"15px"}}>
                    <button className="btn btn-dark text-center load-more-button container" onClick={seeLess}>See Less</button>
                  </div>}
                  </div> */}
                
        </div>
      </section>

      {/* fourthdiv */}
      <div>
        {isMobile ? (<div className="container mt-5 fourthdiv">
          <div className="row justify-content-evenly">
            <div className="col-lg-6 col-md-12 d-flex flex-col fourthdivleft">
              <h1 className="fourthdivleftheading mb-5 mt-5">
              Goal-Driven Learning: Your Path to Success
              </h1>


              <div
                className={` fourthdivleft1 ${activeTab === 0 ? 'active-tab' : ''}`}
                onClick={() => setActiveTab(0)}

              >
                <img className="fourthdivleftimage" src={calender} alt="" />
                <div className="headpara">
                  <h2 className="fourthdivleftheading2">Day wise learning</h2>
                  <p className="fourthdivleftpara">
                  Structured Learning for Progress and Mastery
                  </p>
                </div>
              </div>
              {activeTab === 0 && <img src={require("../images/Rectangle 22567.png")} alt="" />}



              <div
                className={` fourthdivleft1 ${activeTab === 1 ? 'active-tab' : ''}`}
                onClick={() => setActiveTab(1)}
              >
                <img className="fourthdivleftimage" src={programmingkeyboard} alt="" />
                <div className="headpara">
                  <h2 className="fourthdivleftheading2">Hands on training</h2>
                  <p className="fourthdivleftpara">
                  Gain Practical Skills and Excel in Your Field
                  </p>
                </div>
              </div>
              {activeTab === 1 && <img src={require("../images/Rectangle 22568.png")} alt="" />}


              <div
                className={` fourthdivleft1 ${activeTab === 2 ? 'active-tab' : ''}`}
                onClick={() => setActiveTab(2)}
              >
                <img className="fourthdivleftimage" src={modulepuzz} alt="" />
                <div className="headpara">
                  <h2 className="fourthdivleftheading2">Hands on projects</h2>
                  <p className="fourthdivleftpara">
                  Engage, Create, and Succeed with Practical Learning Experiences
                  </p>
                </div>
              </div>
              {activeTab === 2 && <img src={require("../images/Rectangle 22569.png")} alt="" />}


              <div
                className={` fourthdivleft1 ${activeTab === 3 ? 'active-tab' : ''}`}
                onClick={() => setActiveTab(3)}
              >
                <img className="fourthdivleftimage" src={locker} alt="" />
                <div className="headpara">
                  <h2 className="fourthdivleftheading2">Job assistance</h2>
                  <p className="fourthdivleftpara">
                  Personalized Support for Your Career Journey
                  </p>
                </div>
              </div>
              {activeTab === 3 && <img src={require("../images/Rectangle 22570.png")} alt="" />}



              <div
                className={` fourthdivleft1 ${activeTab === 4 ? 'active-tab' : ''}`}
                onClick={() => setActiveTab(4)}
              >
                <img className="fourthdivleftimage" src={programmingteam} alt="" />
                <div className="headpara">
                  <h2 className="fourthdivleftheading2">Discussion form</h2>
                  <p className="fourthdivleftpara">
                  Join Conversations, Share Ideas, and Connect with Peers
                  </p>
                </div>
              </div>
              {activeTab === 4 && <img src={require("../images/Rectangle 22571.png")} alt="" />}


            </div>

          </div>
        </div>) : (<div className="container mt-5 fourthdiv">
          <div className="row justify-content-evenly">
            <div className="col-lg-6 col-md-12 d-flex flex-col fourthdivleft">
              <h1 className="fourthdivleftheading mb-5 mt-5">
              Goal-Driven Learning: Your Path to Success
              </h1>

              {/* Day wise learning */}
              <div
                className={` fourthdivleft1 ${activeTab === 0 ? 'active-tab' : ''}`}
                onClick={() => setActiveTab(0)}

              >
                <img className="fourthdivleftimage" src={calender} alt="" />
                <div className="headpara">
                  <h2 className="fourthdivleftheading2">Day wise learning</h2>
                  <p className="fourthdivleftpara">
                  Structured Learning for Progress and Mastery
                  </p>
                </div>
              </div>

              {/* Hands on training */}
              <div
                className={` fourthdivleft1 ${activeTab === 1 ? 'active-tab' : ''}`}
                onClick={() => setActiveTab(1)}
              >
                <img className="fourthdivleftimage" src={programmingkeyboard} alt="" />
                <div className="headpara">
                  <h2 className="fourthdivleftheading2">Hands on training</h2>
                  <p className="fourthdivleftpara">
                  Gain Practical Skills and Excel in Your Field
                  </p>
                </div>
              </div>

              {/* Hands on projects */}
              <div
                className={` fourthdivleft1 ${activeTab === 2 ? 'active-tab' : ''}`}
                onClick={() => setActiveTab(2)}
              >
                <img className="fourthdivleftimage" src={modulepuzz} alt="" />
                <div className="headpara">
                  <h2 className="fourthdivleftheading2">Hands on projects</h2>
                  <p className="fourthdivleftpara">
                  Engage, Create, and Succeed with Practical Learning Experiences
                  </p>
                </div>
              </div>

              {/* Job assistance */}
              <div
                className={` fourthdivleft1 ${activeTab === 3 ? 'active-tab' : ''}`}
                onClick={() => setActiveTab(3)}
              >
                <img className="fourthdivleftimage" src={locker} alt="" />
                <div className="headpara">
                  <h2 className="fourthdivleftheading2">Job assistance</h2>
                  <p className="fourthdivleftpara">
                  Personalized Support for Your Career Journey
                  </p>
                </div>
              </div>

              {/* Discussion form */}
              <div
                className={` fourthdivleft1 ${activeTab === 4 ? 'active-tab' : ''}`}
                onClick={() => setActiveTab(4)}
              >
                <img className="fourthdivleftimage" src={programmingteam} alt="" />
                <div className="headpara">
                  <h2 className="fourthdivleftheading2">Discussion form</h2>
                  <p className="fourthdivleftpara">
                  Join Conversations, Share Ideas, and Connect with Peers
                  </p>
                </div>
              </div>

            </div>
            <div className="col-lg-6 col-md-12 fourthdivright" >
              {/* Display the image associated with active tab */}
              {activeTab === 0 && <img src={require("../images/Rectangle 22567.png")} alt="" />}
              {activeTab === 1 && <img src={require("../images/Rectangle 22568.png")} alt="" />}
              {activeTab === 2 && <img src={require("../images/Rectangle 22569.png")} alt="" />}
              {activeTab === 3 && <img src={require("../images/Rectangle 22570.png")} alt="" />}
              {activeTab === 4 && <img src={require("../images/Rectangle 22571.png")} alt="" />}
            </div>
          </div>
        </div>)}
      </div>





      <h1 style={{ textAlign: "center", fontWeight: "800px", fontSize: "32px", marginTop: "7rem" }}>Why Skillsquad?</h1>
      <p className='d-lg-block d-none' style={{ textAlign: "center", Color: "#000", fontSize: "24px", marginBottom: "0.2rem" }}>We're committed to empowering individuals to achieve their full potential with personalized </p>
      <p className='d-lg-block d-none' style={{ textAlign: "center", Color: "#000", fontSize: "24px" }}>learning, impactful connections, and endless opportunities for growth.</p>

      <div class="container  ">

        <div className=' skills-squad-first-container row-gap'>
          <div className='col-lg-4 div1'>

            <p className='gridcontainerpara' style={{ marginTop: "35px", marginLeft: "35px", fontSize: "25px", color: "#0D0D0D", fontWeight: "400px" }}>Network</p>
            <img className='network' src={network} />
          </div>
          <div className='col-lg-4 div2'>
            <p className='gridcontainerpara' style={{ marginTop: "0px", marginLeft: "25px", fontSize: "25px", color: "#0D0D0D", fontWeight: "400px" }}>Online Lab</p>
            <img className="onlinelab" src={onlinelab} />
          </div>
          <div className='col-lg-4 div3'>
            <p className='gridcontainerpara' style={{ marginTop: "0px", marginLeft: "25px", fontSize: "25px", color: "#0D0D0D", fontWeight: "400px" }}>Job Opportunities</p>
            <img className="jobopp" src={jobopportunity} />
          </div>
        </div>
        <div class="second-container-skills-squad" >
          <div class=" div4  ">
            <p className='gridcontainerpara' style={{ fontSize: "25px", color: "#0D0D0D", fontWeight: "400px" }}>24/7</p>
            <img className='whyskillsquadimage' src={mailsupport} />
          </div>
          <div class=" div5 ">

            <div className="row">
              <div className='col-lg-8 col-sm-12 inside-first-container' >
                <p className='gridcontainerpara' style={{ fontSize: "25px", marginLeft: "1.5rem", color: "#0D0D0D", fontWeight: "400px" }}>Expert Mentors</p>
                <p className="mentorleft d-lg-block d-none">At our core, we empower individuals to realize their full potential through personalized learning, meaningful connections, and boundless opportunities for growth.</p>
              </div>
              <div className='col-lg-4 col-sm-12 inside-second-container'>
                <img className="mentoright " src={cardimage} />
              </div>
            </div>
          </div>
        </div>
      </div>






      <section>
        <div className="container ">
          <div className="row"></div>
        </div>
        {/* How it works section */}
        <div className="container Trusted-heading"  style={{padding:"0px 35px"}}>
          <h1 >
            The Next Success Story is Going to be Your's..
          </h1>
        </div>
        <Marquee>
          {/* <div className="row" style={{ margin: "0 10px" }}> */}
          <div
            className="main-card mb-2 mx-4 marq-card"
            style={{
              padding: "15px",
              borderRadius: "5px",
              backgroundColor: "#EFF1F0",
              borderRadius: "10px",
            }}
          >
            <div className="content-card d-flex flex-column">
              <div className="align-items-center mb-3 p-3 content-card-deatails">
                <img style={{ height: "60px", width: "60px", borderRadius: "15px" }} className="learn-profile-image" src={EthuriAbhiram} alt="" />
                <div
                  className="d-flex flex-column align-items-start"
                  style={{ marginLeft: "7px" }}
                >
                  <h3 style={{ marginBottom: "5px" }}>Abhiram</h3>
                  <p>Java Backend Developer</p>
                </div>
                <div className="ms-4">
                  <img src={Commas} alt="" style={{ float: "right" }} />
                </div>
              </div>
              <p style={{ fontSize: "19px", padding: "8px" }}>
                "Thanks to Skillsquad's comprehensive Java backend course, I gained the skills needed to land a new job as a backend developer! The course provided real-world
                exercises that helped me understand how backend systems work in practice. Now I'm confidently writing code that powers web applications!"
              </p>
            </div>
          </div>

          <div
            className="main-card mb-2 mx-4 marq-card"
            style={{
              padding: "15px",
              borderRadius: "5px",
              backgroundColor: "#EFF1F0",
              borderRadius: "10px",
            }}
          >
            <div className="content-card d-flex flex-column">
              <div className="align-items-center mb-3 p-3 content-card-deatails">
                <img style={{ height: "60px", width: "60px", borderRadius: "15px" }} className="learn-profile-image" src={James} alt="" />
                <div
                  className="d-flex flex-column align-items-start"
                  style={{ marginLeft: "7px" }}
                >
                  <h3 style={{ marginBottom: "5px" }}>James</h3>
                  <p>Java Full Stack Developer</p>
                </div>
                <div className="ms-4">
                  <img src={Commas} alt="" style={{ float: "right" }} />
                </div>
              </div>
              <p style={{ fontSize: "19px", padding: "8px" }}>
                "Skillsquad's Java fullstack course was instrumental in helping me launch my own e-commerce platform. The course covered everything
                from front-end development to databases, giving me the full picture of how web applications function. I highly recommend it for anyone looking to build their own website!."
              </p>
            </div>
          </div>

          <div
            className="main-card mb-2 mx-4 marq-card"
            style={{
              padding: "15px",
              borderRadius: "5px",
              backgroundColor: "#EFF1F0",
              borderRadius: "10px",
            }}
          >
            <div className="content-card d-flex flex-column">
              <div className="align-items-center mb-3 p-3 content-card-deatails">
                <img style={{ height: "60px", width: "60px", borderRadius: "15px" }} className="learn-profile-image" src={bharath} alt="" />
                <div
                  className="d-flex flex-column align-items-start"
                  style={{ marginLeft: "7px" }}
                >
                  <h3 style={{ marginBottom: "5px" }}>Bharath</h3>
                  <p>AWS Cloud Engineer</p>
                </div>
                <div className="ms-4">
                  <img src={Commas} alt="" style={{ float: "right" }} />
                </div>
              </div>
              <p style={{ fontSize: "19px", padding: "20px" }}>
                "Skillsquad's AWS cloud computing course was a game-changer for my career. I learned how to leverage the power of the cloud to
                store and analyze my datasets more efficiently. This course opened up new possibilities for my data analysis work."
              </p>
            </div>
          </div>

          <div
            className="main-card mb-2 mx-3"
            style={{
              padding: "15px",
              borderRadius: "5px",
              backgroundColor: "#EFF1F0",
              borderRadius: "10px",
            }}
          >
            <div className="content-card d-flex flex-column">
              <div className="align-items-center mb-3 p-3 content-card-deatails">
                <img style={{ height: "60px", width: "60px", borderRadius: "15px" }} className="learn-profile-image" src={ErikillaAshish} alt="" />
                <div
                  className="d-flex flex-column align-items-start"
                  style={{ marginLeft: "7px" }}
                >
                  <h3 style={{ marginBottom: "5px" }}>Ashish</h3>
                  <p>Software Developer</p>
                </div>
                <div className="ms-4">
                  <img src={Commas} alt="" style={{ float: "right" }} />
                </div>
              </div>
              <p style={{ fontSize: "19px", padding: "20px" }}>
                I needed to brush up on my coding skills to land a new job. Skillsquad offered a wide range of courses taught by industry experts.
                Their flexible learning format allowed me to study at my own pace, and I landed my dream job!

                ."
              </p>
            </div>
          </div>
          {/* </div> */}
        </Marquee>
      </section>


      {/* onlytext */}
      {/* <div className="container onlytextdiv">
        <h1 className='onlytext' style={{ textAlign: "center", color: "#000", fontSize: "3.5rem", marginBottom: "-15px", marginTop: "10rem" }}>We have a lot of exciting courses. Startup</h1>
        <h1 className='onlytext'  style={{ textAlign: "center", color: "#000", fontSize: "3.5rem", marginBottom: "-15px" }}>opportunities and candidates - only a few are</h1>
        <h1 className='onlytext'  style={{ textAlign: "center", color: "#000", fontSize: "3.5rem", marginBottom: "7rem" }}>perfect for you. we'll help you find the few.</h1>
      </div> */}
      <div className=" onlytextdiv container">
        <h1 className='onlytext whitepage' >Discover our extensive range of engaging courses, innovative startup opportunities, and exceptional candidates tailored to your goals. Let us guide you to the perfect fit for your aspirations.</h1>
        {/* <h1 className='onlytext'  >opportunities and candidates - only a few are</h1>
        <h1 className='onlytext' >perfect for you. we'll help you find the few.</h1> */}
      </div>

      {/* onlybuttons */}
      <hr style={{ border: "1px solid #000000", borderColor: "#000000 ", marginBottom: "7rem" }} />
      <div className='container fivebuttonhead '>
        <div className='row main-buttons' >
          <button className=' col-md-6 col-sm-12 fivebutton' onClick={() => scrollToSection("all-hero")}>Courses</button>
          {/* <button className='col-lg-2 col-md-6 col-sm-12 fivebutton'>Professional network</button> */}
          {/* <button className='col-lg-2 col-md-6 col-sm-12 fivebutton'>Mentorship</button> */}
          <button className=' col-md-6 col-sm-12 fivebutton' onClick={projectPage}>Projects</button>
          {/* <button className='col-lg-3 col-md-6 col-sm-12 fivebutton'>Jobs</button> */}

        </div>
      </div>
      <hr style={{ border: "1px solid #000000", borderColor: "#000000 ", marginTop: "7rem" }} />

      <NewFooter />
    </div>
  )
  }