import React from 'react'
import { useState, useEffect } from "react";
import httpvideos from "../../js/http-lms_videos"
import Spinner from 'react-bootstrap/Spinner';
import NoRecords from '../../components/NORecFound';
import moment from 'moment'
// images
import upload_assignment from '../../images/upload_assignment.svg'
import delete_assignment from '../../images/delete_assignment.svg'
import closebtn from '../../images/close_btn.svg'
import gray_upload_assignment from '../../images/gray_upload_assignment.svg'
import gray_delete_assignment from '../../images/gray_delete_assignment.svg'
const Submited = () => {
  const [submittedAssignments, setSubmittedAssignments] = useState([])
  const [submittedLoading, setSubmittedLoading] = useState(true)
  const [selectedAssignmentsToDelete, setSelectedAssignmentsToDelete] = useState({})
  const [deleteAssignments, setDeleteAssignments] = useState(false)
  const [deletedLoading, setDeletedLoading] = useState(false)
  useEffect(() => {
    fetchSubmittedAssignments()
  },[])

  function fetchSubmittedAssignments() {
    httpvideos.get('/api/v1/topicAssignmentLMS/lmsStudentsReceivedAssignmentsList?RoleId='+sessionStorage.getItem("roleid")+'&UserId='+sessionStorage.getItem('userEmail')+'&studentId='+sessionStorage.getItem('profileid'))
    .then((response) => {
      console.log("fetch assigned assginemnts : ", response.data)
      setSubmittedAssignments(response.data)
      setSubmittedLoading(false)
    }).catch((e) => {
      console.log(e)
      setSubmittedLoading(false)
    })
  }

  //delete assignments functionality
  function openDeleteAssignmentPopup(data, index){
    setSelectedAssignmentsToDelete(data)
    setDeleteAssignments(true)
  }
  function closeDeletePop() {
    setDeleteAssignments(false)
    setDeletedLoading(true)
    setSelectedAssignmentsToDelete({})
  }
  function deletingAssignment() {
    console.log("final delete")
    setDeletedLoading(true)
    httpvideos.delete('/api/v1/topicAssignmentLMS/lmsDeleteAssignmentsFromS3?RoleId='+sessionStorage.getItem("roleid")+'&UserId='+sessionStorage.getItem('userEmail')+'&assignmentId='+selectedAssignmentsToDelete.assignmentId+'&studentId='+sessionStorage.getItem('profileid'))
    .then((response) => {
      console.log("deleting assignment : ", response.data)
      setDeletedLoading(false)
      setDeleteAssignments(false)
      setSelectedAssignmentsToDelete({})
      setSubmittedAssignments([])
      setSubmittedLoading(true)
      fetchSubmittedAssignments()
    }).catch((e) => {
      console.log(e)
      setDeletedLoading(false)
    })
  }
  const sortData = (data, sortKey, sortDirection) => {
    return data.sort((a, b) => {
      if (sortDirection === 'asc') {
        return a[sortKey] > b[sortKey] ? 1 : -1;
      } else {
        return a[sortKey] < b[sortKey] ? 1 : -1;
      }
    });
  };
  
    const [sortKey, setSortKey] = useState(null);
    const [sortDirection, setSortDirection] = useState(null);
  const handleSort = key => {
    if (key === sortKey) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortKey(key);
      setSortDirection('asc');
    }
  };
  const sortedData = sortData(submittedAssignments, sortKey, sortDirection);

  return (
    <>
    {deleteAssignments && 
        <div className="position-fixed P-Update-Success-1 d-flex justify-content-center align-items-center">
        <div className="card border-0 col-11 p-3 col-md-8 col-lg-3 d-flex flex-column">
          <div className="col-12 p-2 pb-3 radius10 border-0 bold1 font18 text-center">
              <img src={closebtn} alt="close" width="25px" className="closebtn mouseoverstyles1 radiushalf pointer border-0" onClick={closeDeletePop}/>
          </div>
          <div className="col-12 p-2 py-3 d-flex flex-column">
            <span className="font16 black bold text-center">Are you sure you want to delete {selectedAssignmentsToDelete.assignmentName} submitted answerset?</span>
          </div>
          <div className="d-flex justify-content-around footer text-center p-2">
            {deletedLoading? <div className=" col-5 d-flex justify-content-center align-items-center"> <Spinner animation="border" variant="secondary" /></div> :
            <button className="background-organe white col-5 border-0 p-1 radius15" onClick={deletingAssignment}>Delete</button>}
            <button className="background-gray ml-1 black60 col-5 border-0 p-1 radius15" onClick={closeDeletePop}>Cancel</button>
          </div>
        </div>
      </div>
    }
    {submittedLoading ? <div className=" h-100 d-flex justify-content-center align-items-center mt-5 mb-3 py-5 "> <Spinner animation="border" variant="secondary" /></div> : 
    <div>
      { (sortedData.length > 0) ? 
      <table className="table whitecolor">
        <thead>
          <tr>
            <th scope="col">Assignment Name <img onClick={() => handleSort('assignmentName')} src={require("../../images/updown.png")} alt="sort" className="ms-1 updown"/></th>
            {/* <th scope="col">Program</th> */}
            <th scope="col">Module Name <img onClick={() => handleSort('moduleName')} src={require("../../images/updown.png")} alt="sort" className="ms-1 updown"/></th>
            <th scope="col">Topic Name <img onClick={() => handleSort('topicName')} src={require("../../images/updown.png")} alt="sort" className="ms-1 updown"/></th>
            <th scope="col">Start Date <img onClick={() => handleSort('startDate')} src={require("../../images/updown.png")} alt="sort" className="ms-1 updown"/></th>
            <th scope="col">End Date <img onClick={() => handleSort('endDate')} src={require("../../images/updown.png")} alt="sort" className="ms-1 updown"/></th>
            <th scope="col">Submited Date <img onClick={() => handleSort('assignmentSubmitionDate')} src={require("../../images/updown.png")} alt="sort" className="ms-1 updown"/></th>
            <th scope="col">Submited Document</th>
            <th scope="col">Score <img onClick={() => handleSort('score')} src={require("../../images/updown.png")} alt="sort" className="ms-1 updown"/></th>
            <th scope="col">Feedback</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          {submittedAssignments.map((data, index) => {
            return (
              <tr key={index}>
                <td scope="row">{data.assignmentName}</td>
                {/* <td>{data.programName}</td> */}
                <td>{data.moduleName}</td>
                <td>{data.topicName}</td>
                <td>{moment(data.startDate).format('MM/DD/YYYY')}</td>
                <td>{moment(data.endDate).format('MM/DD/YYYY')}</td>
                <td>{moment(data.assignmentSubmitionDate).format('MM/DD/YYYY')}</td>
                <td><a href={data.s3PreSignedAssignmentLink} target="_blank" rel="noopener noreferrer">view</a></td>
                <td>{data.score? data.score : '--'}</td>
                <td>{data.feedBack? data.feedBack : '--'}</td>
                <td>
                  <button className={ (!data.assignmentSubmitionDate || data.score) ? 'background-none radiushalf p-1 border-white' : 'background-none radiushalf p-1 mouseoverstyles1' }
                    disabled={!data.assignmentSubmitionDate || data.score}
                    onClick={() => {openDeleteAssignmentPopup(data,index)}}>
                      <img src={(data.assignmentSubmitionDate && !data.score) ? delete_assignment : gray_delete_assignment } alt="delete" width="20px" title={(data.assignmentSubmitionDate && !data.score) ? 'Delete Assignment' : ''} />
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table> 
      : <NoRecords message={"No Submitted Assignments Found"} /> }
    </div>
  }
  </>
  )
}

export default Submited
