import React, { useEffect, useState } from "react";
import "../../styles/Bookings.css";

// import calender from "../images/calendar.svg";
import mmImage from "../../images/mm-image.svg";
import star from "../../images/Star_light.svg";
import tick from "../../images/material-symbols_verified.svg";
import microsoft from "../../images/Mask group.png";
import money from "../../images/fluent_money-16-regular.svg";
import exp from "../../images/hugeicons_new-job.svg";
import session from "../../images/ph_video-conference.svg";
import flash from "../../images/ic_round-flash-on.svg";
import httpsignup from "../../js/http-lms_userprofile";
import time from "../../images/Group 1000003649.svg";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import MentorCardSkeleton from "../../components/MentorCardSkeleton";
import { usePopup } from "../../components/PopupContext";
import company from "../../images/mentor-company.svg";
import noservice from "../../images/no services.svg";
import { Helmet } from "react-helmet";
import { useMentors } from "../MentorLandingPage/MentorDataContext";


const Bookings = () => {
  
  const {  getMentorClickedService } = usePopup();
  const {mentorsList,loading,filteredData} = useMentors();
  const [id, setId] = useState("");

  const getLoggedInUserDetailsMentor = async () => {
    try {
      const response = await httpsignup.get(
        `/skillsquadUserprofileLMS/getSkillsquadUserProfileBySignUpId?signUpId=${sessionStorage.getItem(
          "userId"
        )}`
      );
      if (response && response.data) {
        const mentor = await httpsignup.get(
          `/mentor/getSkillsquadMentorApplicationsByProfileId?profileId=${response.data.profileId}`
        );
        if (mentor && mentor.data) {
          const mentorId = mentor.data.mentorId;
          sessionStorage.setItem("mentorId", mentorId);
          setId(mentorId);
        }
      }
    } catch (error) {
      console.error("Failed to fetch logged-in user details:", error);
    }
  };

  useEffect(() => {
    getLoggedInUserDetailsMentor();
  }, [loading]);

  const mentorListToFilter =  mentorsList || [];
  const filteredMentorList = mentorListToFilter?.filter(
    (mentor) => mentor.mentorId !== id
  );
  const listToRender =
    id === "" ? filteredMentorList.slice(0, 8) : filteredMentorList;

  const navigate = useNavigate();
  const setPascalCase = (name) => {
    if (name && name?.length >= 20) {
      return (
        name
          .split(" ")
          .map(
            (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
          )
          .join(" ")
          .substring(0, 20) + "..."
      );
    } else if(name) {
      return name
        .split(" ")
        .map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        )
        .join(" ");
    }
    return;
  };
  const getNewName = (name) => {
    if (name && name.length >= 30) {
      return name.substring(0, 30) + "...";
    }
    return name;
  };
  const handleShowMentorProfile = (
    serviceId,
    card,
    signupid,
    profileid,
    mentorid,
    mentorName,
    unique
  ) => {
    if (sessionStorage.getItem("userId")) {
      sessionStorage.setItem("m-signupid", signupid);
      sessionStorage.setItem("m-profileid", profileid);
      sessionStorage.setItem("m-mentorid", mentorid);
      sessionStorage.setItem("munique", mentorName);
      getMentorClickedService(serviceId, card);
      navigate(`/${mentorName}/${unique}`);
    } else {
      navigate("/sign-up");
    }
  };
  const navigateToMentorProfile = (signupid, profileid, mentorid,mentorName) => {
    if (sessionStorage.getItem("userId")) {
      sessionStorage.setItem("m-signupid", signupid);
      sessionStorage.setItem("m-profileid", profileid);
      sessionStorage.setItem("m-mentorid", mentorid);
      sessionStorage.setItem("munique", mentorName);
      navigate(`/${mentorName}`);
    } else {
      navigate("/sign-up");
    }
  };

  

  return (
    <div>
      <div className="container">
        {loading ? (
          <div  className={
            window.location.pathname === "/mentor-landing"
              ? "all-mentors-landing-page-cards"
              : "all-mentor-profile-cards"
          }>
            {[...Array(4)].map((_, index) => (
              <MentorCardSkeleton />
            ))}
          </div>
        ) : (
          <div
            className={
              window.location.pathname === "/mentor-landing"
                ? "all-mentors-landing-page-cards"
                : "all-mentor-profile-cards"
            }
          >
            {listToRender.map((card) => (
            
                <div className="new-mentor-card">
                  
                  <div className="n-m-i-l">
                    <div
                      onClick={() =>
                        navigateToMentorProfile(
                          card.signUpId,
                          card.profileId,
                          card.mentorId,
                          card.uniqueUserName
                        )
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <div className="n-m-p-i-s">
                        <div>
                          <img
                            className="n-m-p-p"
                            src={card.mentorProfileUrl}
                            alt="profile"
                          />
                        </div>
                        <div className="n-m-p-d">
                          <div className="n-m-t">
                            <div className="n-m-n">
                              {setPascalCase(card?.mentorName)}
                            </div>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="17"
                              height="16"
                              viewBox="0 0 17 16"
                              fill="none"
                            >
                              <path
                                d="M6.09256 15.4375L4.74673 13.1708L2.19673 12.6042L2.44465 9.98333L0.709229 8L2.44465 6.01667L2.19673 3.39583L4.74673 2.82917L6.09256 0.5625L8.5009 1.58958L10.9092 0.5625L12.2551 2.82917L14.8051 3.39583L14.5571 6.01667L16.2926 8L14.5571 9.98333L14.8051 12.6042L12.2551 13.1708L10.9092 15.4375L8.5009 14.4104L6.09256 15.4375ZM7.75714 10.5146L11.7592 6.5125L10.7676 5.48542L7.75714 8.49583L6.23423 7.00833L5.24256 8L7.75714 10.5146Z"
                                fill="#5627FF"
                              />
                            </svg>
                          </div>
                          <div className="n-m-r">{card.mentorDesignation}</div>
                          {/* <div className="n-m-t">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                            >
                              <path
                                d="M8.45314 5.52303C9.10864 3.88886 9.43639 3.07178 10 3.07178C10.5636 3.07178 10.8914 3.88886 11.5469 5.52303L11.5774 5.59912C11.9477 6.52235 12.1329 6.98396 12.5102 7.26453C12.8876 7.5451 13.383 7.58947 14.3738 7.6782L14.5529 7.69424C16.1744 7.83946 16.9852 7.91207 17.1586 8.42789C17.3321 8.94371 16.73 9.4915 15.5258 10.5871L15.1239 10.9527C14.5143 11.5073 14.2095 11.7846 14.0675 12.1481C14.041 12.2158 14.0189 12.2853 14.0015 12.356C13.9081 12.7349 13.9974 13.1371 14.1759 13.9417L14.2315 14.1921C14.5595 15.6707 14.7236 16.4101 14.4372 16.7289C14.3301 16.8481 14.191 16.9339 14.0365 16.9761C13.623 17.0889 13.036 16.6105 11.8618 15.6538C11.0909 15.0255 10.7054 14.7114 10.2628 14.6408C10.0887 14.613 9.9113 14.613 9.73721 14.6408C9.29462 14.7114 8.90914 15.0255 8.13816 15.6538C6.96403 16.6105 6.37697 17.0889 5.96347 16.9761C5.80895 16.9339 5.66986 16.8481 5.56284 16.7289C5.27644 16.4101 5.44047 15.6707 5.76853 14.1921L5.8241 13.9417C6.00261 13.1371 6.09186 12.7349 5.99848 12.356C5.98106 12.2853 5.95903 12.2158 5.93253 12.1481C5.79047 11.7846 5.48567 11.5073 4.87609 10.9527L4.47419 10.5871C3.26998 9.4915 2.66788 8.94371 2.84136 8.42789C3.01484 7.91207 3.8256 7.83946 5.44711 7.69424L5.62623 7.6782C6.61699 7.58947 7.11237 7.5451 7.48975 7.26453C7.86712 6.98396 8.05229 6.52235 8.42261 5.59912L8.45314 5.52303Z"
                                fill="black"
                                stroke="black"
                              />
                            </svg>
                            <div className="n-m-t">
                              <span className="n-m-r-n">
                                {card.mentorRating}
                              </span>
                              <span className="n-m-r-r">
                                ({card.reviewCount})
                              </span>
                            </div>
                          </div> */}
                        </div>
                      </div>
                      <div className="n-m-n-c-d">
                        <div className="n-m-c-d">
                          <img
                            style={{width:"25px",height:"25px",objectFit:"contain"}}
                            src={
                              card.companyUrl != null
                                ? card.companyUrl
                                : company
                            }
                            alt="company logo"
                          />
                          <div className="n-m-c-n">{card.mentorCompany}</div>
                        </div>
                        <div className="n-m-c-d">
                          <img  style={{width:"25px",height:"25px",objectFit:"contain"}} src={exp} alt="experience logo" />
                          <div className="n-m-c-n">
                            Experience:{" "}
                            <span className="exp-n-m">
                              {card.mentorExperience} years
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="n-m-b-l"></div>
                    <div className="n-m-s-d">
                      <div className="n-m-p-d">
                        <div className="n-m-s-n">
                          {getNewName(card.serviceName)}
                        </div>
                        <div className="n-m-t-d">
                          <img src={time} alt="timer image" />
                          <span>{card.duration} Mins | Video Session</span>
                        </div>
                        <div className="n-p-d">
                          <div className="n-m-a">
                            <div className="available-on">Available on</div>
                            <div className="n-m-a-d">
                              {moment(card.availableDate).format("DD MMM YY")},{" "}
                              {card.availableTime}
                            </div>
                          </div>
                          <div
                            className="n-m-pricing-button"
                            onClick={() =>
                              handleShowMentorProfile(
                                card.serviceId,
                                card,
                                card.signUpId,
                                card.profileId,
                                card.mentorId,
                                card.uniqueUserName,
                                card.uniqueServiceId
                              )
                            }
                          >
                            ₹{card.mentorPrice}{" "}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="12"
                              height="10"
                              viewBox="0 0 12 10"
                              fill="none"
                            >
                              <path
                                d="M10.375 5L1.625 5M10.375 5L6.625 8.75M10.375 5L6.625 1.25"
                                stroke="white"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
             
            ))}
            {listToRender?.length <= 0 && (
              <div className="no-serive-found">
                <div className="n-s-f-l">
                  <img
                    src={noservice}
                    alt="no service found"
                    style={{
                      height: "150px",
                      width: "150px",
                      objectFit: "contain",
                    }}
                  />
                  <div className="n-s-f-h">No services found</div>
                  <div className="n-s-f-d">
                    Looks like we don't have what you are looking for.
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Bookings;
