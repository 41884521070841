// import React, { useState } from "react";
// import clear from "../../images/Clear.svg";
// import Slider from "react-slick";
// import left from "../../images/left-chev.svg";
// import right from "../../images/right-chev.svg";
// import "../../styles/offer.css";
// import { usePopup } from "../../components/PopupContext";

// const MentorOfferings = () => {
//  const {services,getAllMentorsList} = usePopup();
//   const SamplePrevArrow = (props) => {
//     const { className, style, onClick } = props;
//     return (
//       <div onClick={onClick} className={`arrow ${className}`}>
//         <img src={left} />
//       </div>
//     );
//   };

//   function SampleNextArrow(props) {
//     const { className, style, onClick } = props;
//     return (
//       <div onClick={onClick} className={`arrow ${className}`}>
//         <img src={right} />
//       </div>
//     );
//   }

//   const settings = {
//     dots: false,
//     infinite: false,
//     speed: 500,
//     variableWidth: true,
//     slideToShow: 4,
//     slidesToScroll: 4,
//     autoScroll: false,
//     nextArrow: <SampleNextArrow />,
//     prevArrow: <SamplePrevArrow />,
//   };
//   const [selectedOffers, setSelectedOffers] = useState("");
//   const handleSelectedItems = (id) => {
//     setSelectedOffers(id)
//     getAllMentorsList(id)
//   };

//   return (
//     <div className="offers-container">
//       <Slider {...settings}>
//         {services?.map((tech, index) => (
//           <div
//             className={
//               selectedOffers === tech.offerId
//                 ? "mentor-offerings-selected"
//                 : "mentor-offerings"
//             }
//             key={index}
//             onClick={() => handleSelectedItems(tech.offerId)}
//           >
//             <span className="">{tech.offerName}</span>
//             {selectedOffers == tech.offerId ? <img className="offer-icon" src={clear} alt={tech.offerName} /> : ""}
//           </div>
//         ))}
//       </Slider>
//     </div>
//   );
// };

// export default MentorOfferings;
import React, { useEffect, useState, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import left from "../../images/left-chev.svg";
import right from "../../images/right-chev.svg";
import { usePopup } from "../../components/PopupContext";
import { Navigation } from "swiper/modules";
import clear from "../../images/Clear.svg";
import "../../styles/offer.css";
import { useNavigate, useParams } from "react-router-dom";
import { useMentors } from "../MentorLandingPage/MentorDataContext";
import OfferSkeleton from "../../components/OfferSkeleton";

const Technologies = () => {
  const { services, offerLoading, selectedOffers, getId } = useMentors();
  const { category, offername } = useParams();
  const navigate = useNavigate();

  const { removeFilteredData } = useMentors();

  useEffect(() => {
    if (offername) {
      const newCat = category
        .replace(/[&/.-]/g, " ")
        .replace(/\s+/g, " ")
        .trim();
      const newOffer = offername
        .replace(/[&/.-]/g, " ")
        .replace(/\s+/g, " ")
        .trim();
      getId(newCat, newOffer);
    }
  }, [offername]);

  const handleSelectedItems = (id, name) => {
    const currentMainPath = window.location.pathname.split("/")[1];
    const encodedPath = name.replace(/[\s/]+/g, "-").toLowerCase();
    const encodedPathService = "1:1 services"
      .replace(/\s+/g, "-")
      .toLowerCase();
    navigate(
      `/${currentMainPath}/${encodedPathService}/${category}/${encodedPath}`
    );
  };
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const swiperRef = useRef(null);

  // Function to update arrow visibility based on slides and container width
  const updateNavigationVisibility = () => {
    if (swiperRef.current && prevRef.current && nextRef.current) {
      const swiper = swiperRef.current;
      const { slides, width } = swiper;
      const slidesPerView = swiper.params.slidesPerView;
      const slideWidth = swiper.slides[0]?.offsetWidth || 0;
      const slidesContainerWidth = slidesPerView * slideWidth;

      // Show arrows only if the total slide width exceeds the container width
      const totalSlidesWidth = slides.length * slideWidth;
      if (totalSlidesWidth > width) {
        prevRef.current.style.display = swiper.isBeginning ? "none" : "block";
        nextRef.current.style.display = swiper.isEnd ? "none" : "block";
      } else {
        prevRef.current.style.display = "none";
        nextRef.current.style.display = "none";
      }
    }
  };

  useEffect(() => {
    updateNavigationVisibility(); // Initial check
  }, [services]);

  return (
    <div
      className={
        window.location.pathname === "/mentor-page"
          ? "mentor-page-layout-offers"
          : "offers-container"
      }
      style={{ position: "relative" }}
    >
      {offerLoading ? (
        <div className="o-sk-l">
          {[...Array(13)].map((_, index) => (
            <OfferSkeleton />
          ))}
        </div>
      ) : (
        <>
          <Swiper
            modules={[Navigation]}
            slidesPerView="auto"
            spaceBetween={20}
            navigation={{
              prevEl: prevRef.current,
              nextEl: nextRef.current,
            }}
            pagination={false}
            onSwiper={(swiper) => {
              swiperRef.current = swiper;
              updateNavigationVisibility(); // Initial check
            }}
            onSlideChange={() => {
              updateNavigationVisibility(); // Check on slide change
            }}
          >
            {services?.map((tech, index) => (
              <SwiperSlide
                key={index}
                style={{ width: tech.isBig ? "auto" : "auto" }}
              >
                <div
                  className={
                    selectedOffers === tech.offerId
                      ? "mentor-offerings-selected"
                      : "mentor-offerings"
                  }
                  key={index}
                  onClick={() =>
                    handleSelectedItems(tech.offerId, tech.offerName)
                  }
                >
                  <span className="">{tech.offerName}</span>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>

          <div
            ref={prevRef}
            className="swiper-button-prev"
            style={{
              position: "absolute",
              top: "75%",
              left: "-10px",
              transform: "translateY(-50%)",
              zIndex: 10,
              cursor: "pointer",
            }}
          >
            <img src={left} alt="Previous" />
          </div>
          <div
            ref={nextRef}
            className="swiper-button-next"
            style={{
              position: "absolute",
              top: "75%",
              right: "-10px",
              transform: "translateY(-50%)",
              zIndex: 10,
              cursor: "pointer",
            }}
          >
            <img src={right} alt="Next" />
          </div>
        </>
      )}
    </div>
  );
};

export default Technologies;
