import React, { useEffect } from 'react'
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useState } from "react";
import 'react-image-crop/dist/ReactCrop.css'
import httpsignup from '../../js/http-lms_userprofile';
import "react-datepicker/dist/react-datepicker.css";
import RightSidebar from '../RightSidebar';
import Sidebar from '../Sidebar';

const AddCohort = ({ onClose }) => {

  const [defaultuserDetails, setUserDetails] = useState({})
  //form data
  const [errorMessage, setErrorMessage] = useState("");
  const [formErros, setFormErrors] = useState({});
  const [disable, setDisable] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [cardHeader, setCardHeader] = useState("Add Cohort")
  const [cardButton, setCardButton] = useState("Save")

  useEffect(() => {
    getLoggedInUserDetails()
  }, [])
  const getLoggedInUserDetails = async () => {
    httpsignup.get(`/skillsquadUserprofileLMS/getSkillsquadUserCohortBySignUpId?signUpId=${sessionStorage.getItem('userId')}`)
      .then((response) => {
        console.log("UserDetails:", JSON.stringify(response))
        setUserDetails(response.data[0])
        if(response.data[0].cohortName != null){
          setCardHeader("Edit Cohort")
          setCardButton("Update")
        }
      }).catch((err) => {
        console.log(err)
      })
  }

  // validate data and enable/disable submit btn
  useEffect(() => {
    setFormErrors(validate(defaultuserDetails))
    enableDisableBtn()
  }, [defaultuserDetails])
  function enableDisableBtn() {
    if (defaultuserDetails.cohortName && defaultuserDetails.courseName && defaultuserDetails.title) {
      console.log("false")
     // setDisable(false)
    }
    else {
      console.log("true")
     // setDisable(true)
    }
  }
  //updating defaultuserDetails for final submission
  function handleChange(e) {
    setErrorMessage('')
    const { name, value } = e.target;
    setUserDetails((pValue) => {
      console.log(pValue)
      return {
        ...pValue,
        [name]: value
      }
    })
    console.log("after change : ", defaultuserDetails)
  }
  function SubmitFinalData(e) {
    e.preventDefault()
    setFormErrors(validate(defaultuserDetails))
    setIsSubmit(true)
  }


  useEffect(() => {
    if(Object.keys(formErros).length === 0 && isSubmit){
      httpsignup.put('/skillsquadUserprofileLMS/updateSkillsquadUserCohort', defaultuserDetails)
      .then((response) => {
        console.log("update response : ", response.data)
        setErrorMessage("Updated sucessfully")
        setTimeout(() => {
          setErrorMessage('')
        }, 5000)
        setUserDetails({})
        setIsSubmit(false)
      }).catch((e) => {
        console.log("error update : ", e.response)
      })
    }
  }, [formErros])
  // final submission
  
  const validate = (values) => {
    const errors = {};
    const allowaplaspaces = /^[a-zA-Z ]*$/;
    // if (!values.cohortName) {
    //   errors.cohortName = "Cohort Name is required!"
    // } else if (!allowaplaspaces.test(values.cohortName)) {
    //   errors.cohortName = "Cohort name should contain alphabets and spaces"
    // }
    // if (!values.courseName) {
    //   errors.courseName = "Course Name is required!"
    // }
    // if (!values.title) {
    //   errors.title = "Title is required!"
    // }

    return errors;
  }


  return (
    <div className="profile-popup-container">
    <div className="profile-popup">
    <div style = {{textAlign: "right", color:"black",cursor:"pointer"}} class="mb-3"> <div  onClick={onClose}>X</div></div>
      <h2 class="d-flex common_main_text_highlight">{cardHeader}</h2>
      <p class="common_main_text_normal">Let’s get started with some personal details</p>
      <p class="mt-2 common_main_text_normal">Your online profiles</p>
     <center><p style={{font: 18,fontWeight: 600, color: "green"}}>{errorMessage}</p></center> 
      <Form id="create-User-Form">
                <Row className="pb-3">
                <Col className="col-lg-6 col-12 d-flex flex-column align-items-start">
                <div className="d-flex required align-items-center pb-1" ><img class="img-fluid"
                          src={require("../../images/cohort_name_icon.png")}
                          alt="Image not found"
                          className="more"
                          width={20}

                        /> <h6 style={{fontWeight:700,marginLeft: 5}}>Cohort Name</h6></div>
                      <Form.Control
                      className={formErros.cohortName ? "borderred" : ""}
                      placeholder="Enter Cohort Name"
                      autoComplete="off"
                      name='cohortName'
                      defaultValue={defaultuserDetails.cohortName}
                      onChange={handleChange}
                    />
                    <p className="red font12">{formErros.cohortName}</p>
                  </Col>
                  <Col className="col-lg-6 col-12 d-flex flex-column align-items-start">
                    <div className="d-flex required align-items-center pb-1"> 
                    <img class="img-fluid"
                          src={require("../../images/course_new_icon.png")}
                          alt="Image not found"
                          className="more"
                          width={20}

                        />
                    <h6 style={{fontWeight:700,marginLeft: 5}}>Course Name</h6></div>
                       <Form.Control
                      className={formErros.courseName ? "borderred" : ""}
                      placeholder="Enter Course Name"
                      autoComplete="off"
                      name='courseName'
                      defaultValue={defaultuserDetails.courseName}
                      onChange={handleChange}
                    />
                    <p className="red font12">{formErros.courseName}</p>
                  </Col>
                  
                
                  
                </Row>
                <Row className="pb-3">
                <Col className="col-lg-6 col-12 d-flex flex-column align-items-start">
                    <div className="d-flex required align-items-center pb-1">
                    <img class="img-fluid"
                          src={require("../../images/title_icon.png")}
                          alt="Image not found"
                          className="more"
                          width={20}

                        />
                      <h6 style={{fontWeight:700,marginLeft: 5}}>Title</h6></div>
                       <Form.Control
                      className={formErros.title ? "borderred" : ""}
                      placeholder="Enter title"
                      autoComplete="off"
                      name='title'
                      defaultValue={defaultuserDetails.title}
                      onChange={handleChange}
                    />
                    <p className="red font12">{formErros.title}</p>
                  </Col>

                  
                 


                </Row>
        
                         

                <div>
                  <Row >
                   
                    <Col className="col-lg-2 col-12 d-flex flex-column align-items-start mt-lg-0 mt-3">
                      <button disabled={disable} className={disable ? "profile-buttons-update mt-3 mb-5 grey" : "profile-buttons-update mt-3 mb-5 common_main_button"} onClick={SubmitFinalData}>
                      {cardButton}
                      </button>
                    </Col>
                   

                  </Row>
                </div>
              </Form>
      {/* <div class="activityButton" onClick={allcourses} style={{width: "100%", textAlign:"center"}}>See More Courses</div> */}
      
    </div>
  </div>
  )
}

export default AddCohort;
