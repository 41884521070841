import { useState, useEffect } from "react";
import { NewFooter } from '../../components/Footer/NewFooter';
import { NewHeader } from '../../components/NewHeader'

const CodeOfConduct = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])


    return (

        <>

            <div className="" style={{ width: "100%" }}>

                {/* <Navb /> */}

                <div className=" mt-5">

                    <section class="section">
                        <div class="container">

                            <div class="row ">

                                <div class="col-lg-12">
                                    <div class="row mb-3">
                                      
                                            <div className="col"></div>
                                                <div className="col-9">
                                                    <center><div style={{ fontSize: 50, fontWeight: 700 }} class="mb-5">Code of Conduct</div></center>
                                                    <div class="mt-3" style={{ border: "1px solid grey", borderRadius: 10, boxShadow: "2px 1px 5px 0px rgba(0, 0, 0, 0.15)", padding: "3%" }}>
                                                        <div class="mt-3">A code of conduct is a set of guidelines that outlines expected behaviors and standards for individuals or organizations. It is often used to promote ethical behavior, prevent conflicts of interest, and establish a culture of accountability.</div>

                                                        <div class="mt-3">A code of conduct typically includes rules and principles that govern behavior in areas.</div>
                                                        <div class="mt-3" style={{ fontSize: 20, fontWeight: 700, lineHeight: 1 }}>Do's:</div>
                                                        <div class="mt-3 mx-5">Do take advantage of our courses to develop your skills and knowledge in your desired area.</div>
                                                        <div class="mt-3 mx-5">Do interact with mentors and other learners to get the most out of your learning experience.</div>
                                                        <div class="mt-3 mx-5">Provide accurate and up-to-date information when registering or creating an account on our platform.</div>
                                                        <div class="mt-3 mx-5">Do give feedback on our courses and services so we can continually improve.</div>
                                                        <div class="mt-3 mx-5">Do respect the intellectual property rights of our mentors and other platform users.</div>
                                                        
                                                        <div class="mt-3" style={{ fontSize: 20, fontWeight: 700, lineHeight: 1 }}>Don't s:</div>
                                                        <div class="mt-3 mx-5">Don't engage in any behavior that is discriminatory, harassing, or otherwise violates our code of conduct.</div>
                                                        <div class="mt-3 mx-5">Don't share your login information or allow others to access your account.</div>
                                                        <div class="mt-3 mx-5">Don't plagiarize or copy content from our platform or mentors.</div>
                                                        <div class="mt-3 mx-5">Don't engage in any activity that disrupts or interferes with the functioning of our platform or services.</div>
                                                        <div class="mt-3 mx-5">Don't engage in any activity that is illegal or unethical.</div>

                                                        <div class="mt-3">Following these guidelines ensures a positive learning environment for yourself and others on the Skillsquad platform.</div>

                                                        <div class="mt-3">Organizations often develop codes of conduct to establish a shared understanding of expectations and responsibilities and to guide decision-making consistently and ethically. Codes of conduct are typically communicated to employees, customers, partners, and other stakeholders to ensure that everyone understands and adheres to the established standards.</div>
                                                        
                                                        
                                                        
                                                    </div>

                                                </div>
                                                <div className="col"></div>
                                                </div></div></div></div>

                    </section>

                </div>
               
            </div>

        </>
    );
}

export default CodeOfConduct