import React, { useCallback, useEffect, useRef, useState } from "react";
import "../styles/Bookings.css";
import mentor from "../images/Ellipse 2.svg";
import Vector from "../images/Vector.svg";
import time from "../images/Group 1000003649.svg";
import timeleft from "../images/Vector (1).svg";
import closeIcon from "../images/closeIcon.svg";
import photo from "../images/Ellipse 2.svg";
import greenVector from "../images/Vector (2).svg";
import httpsignup from "../js/http-lms_userprofile";
import illustration from "../images/booking empty.svg";
import StatsIcon2 from "../images/filter_list_FILL0_wght400_GRAD0_opsz24 (1) 1.svg";
import Sidebar from "./Sidebar";
import calender from "../images/calendar.svg";
import Tooltip from "@mui/material/Tooltip";
import cal from '../images/BCalender.svg'

// import calender from "../images/calendar.svg";
import moment from "moment";
import SessionTimer from "../components/SessionTimer";
import { usePopup } from "../components/PopupContext";
import MentorSessionCardSkeleton from "../components/MentorSessionCardSkeleton";

const UserSessionDetails = () => {
  const [upcoming, setUpcoming] = useState(true);
  const [completed, setCompleted] = useState(false);
  const [openView, setopenView] = useState(false);
  const [joinSession, setJoinSessions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);

  const [upcomingSession, setUpcomingSessions] = useState([]);
  const [completedSessions, setCompletedSessions] = useState([]);
  const { getLoggedInUserDetails, userProfileData } = usePopup();
  const emailSentRef = useRef(false);
  const [openCancel, setOpenCancel] = useState(false);
  const [cancelReason, setCancelReason] = useState("");
  const [selectedSession, setSelectedSession] = useState(
    upcomingSession.length > 0 ? upcomingSession[0] : []
  );
  const [cancelLimitHours, setCancelLimitHours] = useState(null);
  const [sessionLoading, setSessionLoading] = useState(false)
  useEffect(() => {
    setSelectedSession(upcomingSession.length > 0 ? upcomingSession[0] : []);
  }, [upcomingSession]);

  const changeTab = () => {
    setUpcoming(false);
    setCompleted(true);
    // resetLoadingState();
  };

  const changeTabUpcoming = () => {
    setUpcoming(true);
    setCompleted(false);
    // resetLoadingState();
  };

  const [user, setUser] = useState(null);

  const getCancelDuration = async () => {
    try {
      const response = httpsignup.get(`/mentor/getCancelDuration`);
      if (response && response.data) {
        setCancelLimitHours(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getCancelDuration();
  }, []);

  const handleCanReasonSubmit = async () => {
    if (cancelReason !== "" || !cancelReason.trim()) {
      setLoading(true);
      try {
        const response = await httpsignup.delete(
          `/mentor/cancelBooking?bookingId=${selectedSession.bookingId}&reason=${cancelReason}`
        );
        if (response && response.data) {
          getSessions()
          setLoading(false);
          setOpenCancel(false);
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
        setOpenCancel(false);
      }
    }
  };
  const getSessions = async () => {
    setSessionLoading(true)
    try {
      const upcomingResponse = await httpsignup.get(
        `/mentor/getUserProfileJoinBooking?profileId=${userProfileData?.profileId
        }&status=${"upcoming"}`
      );
      // /mentor/getUserProfileJoinBooking?profileId=&status=
      if (upcomingResponse && upcomingResponse.data) {
        setUpcomingSessions(upcomingResponse.data);
      }
      console.log("join session details", upcomingResponse.data);
      const completedResponse = await httpsignup.get(
        `/mentor/getUserProfileJoinBooking?profileId=${userProfileData?.profileId
        }&status=${"completed"}`
      );

      if (completedResponse && completedResponse.data) {
        console.log("join session details", completedResponse.data);
        setCompletedSessions(completedResponse.data);
        setSessionLoading(false)
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      setSessionLoading(false)
    }
  };
  useEffect(() => {
    getLoggedInUserDetails();
    if (userProfileData?.profileId) {
      getSessions();
    }
  }, [userProfileData?.profileId]);

  const handleOpenPopUp = (session) => {
    setopenView(true);
    console.log("current session", session);
    setSelectedSession(session);
  };
  const handleCancelPopupOpen = (session) => {
    setOpenCancel(true);
    setSelectedSession(session);
  };
  const JoinCall = async (room) => {
    if (selectedSession.status == "upcoming") {
      window.open(
       `https://sessions.skillsquad.io/mentor-onboarding/join-session/${room}/mentee`,
        "_blank"
      );
      await sendMeetUpdate(selectedSession.bookingId, "mentee", "joined");
    }
  };
  useEffect(() => {
    if (selectedSession?.status === "upcoming") {
      const sessionStartTime = moment(
        `${selectedSession.sessionDate} ${selectedSession.sessionStartTime}`
      );

      const sessionEndTime = sessionStartTime.clone().add(15, "minutes");

      const now = moment();
      console.log("time now", now);

      const durationUntilEnd = sessionEndTime.diff(now);

      console.log(
        "Session end time:",
        sessionEndTime.format("YYYY-MM-DD HH:mm:ss")
      );
      console.log("Current time:", now.format("YYYY-MM-DD HH:mm:ss"));

      if (durationUntilEnd > 0) {
        const timeout = setTimeout(() => {
          sendMeetUpdate(selectedSession.bookingId, "mentee", "not joined");
        }, durationUntilEnd);

        return () => clearTimeout(timeout);
      }
    }
  }, [selectedSession]);
  const sendMeetUpdate = async (bookingId, joineeType, status) => {
    try {
      const response = await httpsignup.put(
        `/mentor/updateBookingJoiningStatus?bookingId=${bookingId}&joineeType=${joineeType}&status=${status}`
      );
      console.log("meet update triggered", response.data);
      getSessions();
      console.log("Update sent:", response.data);
    } catch (error) {
      console.log(error);
    }
  };
  const tabs = ["upcoming", "completed"];
  const [joinEnabled, setJoinEnabled] = useState(false);

  const handleJoinEnable = async (enabled) => {
    setJoinEnabled(enabled);

    try {
      const response = await httpsignup.get(
        `/mentor/getSkillsquadMentorSessionBooking?bookingId=${selectedSession.bookingId}`
      );
      if (response && response.data) {
        if (response.data.reminderSent == null) {
          const sendReminder = await httpsignup.post(
            `/mentor/sendSessionRemainderEmail?bookingId=${selectedSession.bookingId}`
          );
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const setPascalCase = (name) => {
    if (name) {
      return name
        .split(" ")
        .map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        )
        .join(" ");
    } else {
      return;
    }
  };
  const isSessionCancelable = (
    sessionStartTime,
    sessionDate,
    cancelLimitHours
  ) => {
    const startMoment = moment(sessionStartTime, "h:mm A");
    const sessionStartDateTime = moment(sessionDate).set({
      hour: startMoment.get("hour"),
      minute: startMoment.get("minute"),
    });

    const currentMoment = moment();
    const hoursDifference = sessionStartDateTime.diff(currentMoment, "hours");
    return hoursDifference >= cancelLimitHours;
  };
  return (
    <>
      <div className="" style={{ padding: "70px 0" }}>
        <div className="container s-b-p" style={{ padding: "20px" }}>
          <div className="booking-tabs">
            <div className="booking-heading">Bookings </div>
            <div className={`b-tabs`}>
              <div
                onClick={changeTabUpcoming}
                className={upcoming ? "active" : ""}
                style={{ cursor: "pointer" }}
              >
                Upcoming
              </div>
              <div
                onClick={changeTab}
                className={!upcoming ? "active" : ""}
                style={{ cursor: "pointer" }}
              >
                Completed
              </div>
            </div>
          </div>
          {upcoming && (
            <div className="list-of-upcoming-sessions">
              <div className="session-container">
                {sessionLoading ? <div>
                  {[...Array(3)].map((_, index) => (
                    <MentorSessionCardSkeleton />
                  ))}
                </div> : ""}
                {(upcomingSession.length === 0 && !sessionLoading) ? (
                  <div
                    className="empty-session"
                    style={{
                      padding: "150px 100px",
                      display: "flex",
                      flexDirection: "column",
                      gap: "15px",
                      alignItems: "center",
                    }}
                  >
                    <img src={illustration} />
                    <span className="upcoming-empty-msg">
                      Welcome to your booking dashboard!
                    </span>
                    <span className="upcoming-empty-msg2">
                      Looks like you don't have any upcoming sessions.
                    </span>
                  </div>
                ) : (
                  <div>
                    {upcomingSession.map((session) => {
                      const isCancelable = isSessionCancelable(
                        session.sessionStartTime,
                        session.sessionDate,
                        4
                      );
                      return (
                        <div className="mentor-session-card mb-3 mt-4">
                          <div className="mentor-session-profile">
                            <div className="mentor-image ">
                              <img
                                className="mentor-image  "
                                src={session.profileURL}
                              />
                            </div>
                            <div className="mentor-session-details">
                              <div className="upcoming-mentor ">
                                <span>mentorship session with</span>
                              </div>
                              <div className="mentor-name-session">
                                <div className="mentor-name ">
                                  {session.userName}
                                </div>
                                <div className="session-number ">
                                  (
                                  {session.sessionCount === null
                                    ? 0
                                    : session.sessionCount}{" "}
                                  Session)
                                </div>
                              </div>
                              <div className="session-time-details">
                                <div className="session-data s-date">
                                  <img src={cal} />{" "}
                                  <span>
                                    {moment(session.sessionDate).format(
                                      "DD/MM/YYYY"
                                    )}
                                  </span>
                                </div>
                                <div className="session-data ">
                                  <img src={time} />{" "}
                                  <span>{session.sessionDuration} Mins</span>
                                </div>
                                <div className="session-data take-full-width">
                                  <img src={timeleft} />{" "}
                                  <span>
                                    <SessionTimer
                                      date={session.sessionDate}
                                      time={session.sessionStartTime}
                                    />
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="cancel-join">
                            <Tooltip title="Able to cancel only before 4 hours">
                              <div
                                className={`cancel-btn ${!isCancelable ? "disableCancel" : ""
                                  }`}
                                onClick={() =>
                                  isCancelable && handleCancelPopupOpen(session)
                                }
                              >
                                Cancel
                              </div>
                            </Tooltip>
                            <div
                              className="join-session join-session-view"
                              onClick={() => handleOpenPopUp(session)}
                            >
                              Join session
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          )}
          {completed && (
            <div className="list-of-upcoming-sessions">
              <div className="session-container">
                {completedSessions.length === 0 ? (
                  <div
                    className="empty-session"
                    style={{
                      padding: "150px 100px",
                      display: "flex",
                      flexDirection: "column",
                      gap: "15px",
                      alignItems: "center",
                    }}
                  >
                    <img src={illustration} />
                    <span className="upcoming-empty-msg">
                      Welcome to your booking dashboard!
                    </span>
                    <span className="upcoming-empty-msg2">
                      Looks like you don't have any completed sessions.
                    </span>
                  </div>
                ) : (
                  <div>
                    {completedSessions.map((session) => (
                      <div className="mentor-session-card mb-3 mt-4">
                        <div className="mentor-session-profile">
                          <div className="mentor-image ">
                            <img
                              className="mentor-image  "
                              src={session.profileURL}
                            />
                          </div>
                          <div className="mentor-session-details">
                            <div className="upcoming-mentor ">
                              <span>mentorship session with</span>
                            </div>
                            <div className="mentor-name-session">
                              <div className="mentor-name ">
                                {session.userName}
                              </div>
                              <div className="session-number ">
                                (
                                {session.sessionCount === null
                                  ? 0
                                  : session.sessionCount}{" "}
                                Session)
                              </div>
                            </div>
                            <div className="session-time-details">
                              <div className="session-data ">
                                <img src={cal} />{" "}
                                <span>
                                  {moment(session.sessionDate).format(
                                    "DD/MM/YYYY"
                                  )}
                                </span>
                              </div>
                              <div className="session-data ">
                                <img src={time} />{" "}
                                <span>{session.sessionDuration} Mins</span>
                              </div>
                              <div className="session-data ">
                                <img src={timeleft} />{" "}
                                <span>
                                  {/* <SessionTimer
                                  date={session.sessionDate}
                                  time={session.sessionTime}
                                /> */}
                                  {session.sessionDate}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="join-session join-session-view"
                          onClick={() => handleOpenPopUp(session)}
                        >
                          View
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          )}


          <div className={`popup-overlay ${openView ? "open" : ""}`}>
            <div className="popup-content">
              <div className="first-box">
                <div className="session-details-heading">Session Details</div>
                <div onClick={() => setopenView(false)}>
                  <img src={closeIcon} />
                </div>
              </div>
              <div className="mentor-view-profile">
                <div className="actual-view">
                  <div>
                    <img
                      className="mentor-image"
                      src={selectedSession.profileURL}
                    />
                  </div>
                  <div className="view-mentor-details">
                    <div className="view-name">Mentor</div>
                    <div classname="view-role">
                      {setPascalCase(selectedSession.userName)}
                    </div>
                  </div>
                </div>
              </div>
              <div className="mentor-session-call-details">
                <div className="call-notes">
                  <div className="appoint-date-heading">Appointment Date</div>
                  <div className="date-box">
                    <img src={cal} style={{ width: "16px", height: "16px" }} />
                    <div className="session-date">
                      {moment(selectedSession.sessionDate).format(
                        "DD MMM YY"
                      )}
                    </div>
                  </div>
                </div>
                <div className="call-notes">
                  <div className="appoint-date-heading">Session Time</div>
                  <div>
                    <div className="session-date">
                      {selectedSession.sessionStartTime} -{" "}
                      {selectedSession.sessionEndTime}
                    </div>
                  </div>
                </div>
                <div className="call-notes">
                  <div className="appoint-date-heading">Session Name</div>
                  <div>
                    {" "}
                    <div className="session-date">
                      {selectedSession.offerName}
                    </div>
                  </div>
                </div>
                <div className="call-notes">
                  <div className="appoint-date-heading">Session Number</div>
                  <div>
                    {" "}
                    <div className="session-date">
                      {selectedSession.sessionCount}
                    </div>
                  </div>
                </div>
                <div className="call-notes">
                  <div className="appoint-date-heading">
                    Time{" "}
                    {selectedSession.status == "upcoming" ? "Remaining" : ""}
                  </div>
                  <div className="date-box">
                    <img
                      src={greenVector}
                      style={{ width: "16px", height: "16px" }}
                    />
                    <div className="session-date-left">
                      {selectedSession.status == "upcoming" ? (
                        <SessionTimer
                          date={selectedSession.sessionDate}
                          time={selectedSession.sessionStartTime}
                          onJoinEnable={handleJoinEnable}
                        />
                      ) : (
                        selectedSession.sessionDate
                      )}
                    </div>
                  </div>
                </div>
                <div className="call-notes">
                  <div className="appoint-date-heading">Meeting Link</div>
                  <div className="j-s-e-m">Access in larger devices</div>
                  <div className="d-j-s-i-m">
                    <div
                      className={`join-session-view ${joinEnabled ? "" : "disabled"
                        }`}
                      onClick={() =>
                        joinEnabled && JoinCall(selectedSession.joinRoom)
                      }
                      data-tip={
                        !joinEnabled
                          ? "Allowed to join before 5 minutes only"
                          : ""
                      }
                    >
                      {selectedSession.status === "completed"
                        ? "Completed"
                        : "Join session"}
                    </div>
                  </div>
                </div>
              </div>
              <div className="view-notes">
                <div classname="notes-heading">Notes:</div>
                <div classname="notes-description">
                  {selectedSession.notes}
                </div>
              </div>
            </div>
          </div>


          <div className={`popup-overlay ${openCancel ? "open" : ""}`}>
            <div className="popup-content popup-pad-cancel">
              <div className="first-box">
                <div className="session-details-heading">Session Details</div>
                <div onClick={() => setOpenCancel(false)}>
                  <img src={closeIcon} />
                </div>
              </div>
              <div className="cancel-bbooking-details">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                  }}
                >
                  {/* profiel phot details */}
                  <div className="mentor-view-profile">
                    <div className="actual-view">
                      <div>
                        <img
                          className="mentor-image"
                          src={selectedSession.profileURL}
                        />
                      </div>
                      <div className="view-mentor-details">
                        <div className="view-name">Mentor</div>
                        <div classname="view-role">
                          {setPascalCase(selectedSession.userName)}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="call-notes">
                    <div className="appoint-date-heading">Plan</div>
                    <div>
                      {" "}
                      <div className="session-date">
                        {selectedSession.offerName}
                      </div>
                    </div>
                  </div>
                  <div className="call-notes">
                    <div className="appoint-date-heading">Price</div>
                    {selectedSession.price}
                  </div>
                  <div className="call-notes">
                    <div className="appoint-date-heading">Duration</div>
                    {selectedSession.sessionDuration} Mins
                  </div>
                  <div className="call-notes">
                    <div className="appoint-date-heading">About</div>
                    {selectedSession.notes}
                  </div>
                </div>
                <div className="cancel-reason-section">
                  <div className="c-r-h">Cancel your booking</div>
                  <div className="c-d-section">
                    <div>Date</div>
                    <div className="d-flex mt-2">
                      <div className="d-flex align-items-center" style={{ marginRight: "10px" }}>
                        <img
                          src={calender}
                          alt="date"
                          style={{ marginRight: "5px" }}
                        />
                        <div>
                          {" "}
                          {moment(selectedSession.sessionDate).format(
                            "MMM"
                          )},{" "}
                          {moment(selectedSession.sessionDate).format(
                            "DD MMM YY"
                          )}
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <img
                          src={time}
                          alt="time"
                          style={{ marginRight: "5px" }}
                        />
                        {selectedSession.sessionStartTime}
                      </div>
                    </div>
                  </div>
                  <div className="d-flex flex-column">
                    <div className="cancel-message-heading">
                      Cancel Message<span style={{ color: "red" }}>*</span>
                    </div>
                    <textarea
                      spellCheck={false}
                      className="c-r-i"
                      placeholder="Let the mentor know why you are canceling the call with them."
                      onChange={(e) => setCancelReason(e.target.value)}
                    />
                  </div>
                  <div
                    onClick={handleCanReasonSubmit}
                    className={`c-b-b ${cancelReason === "" || !cancelReason.trim()
                        ? "c-disable"
                        : ""
                      }`}
                  >
                    {loading ? "Submitting" : "Cancel booking"}
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </>
  );
};

export default UserSessionDetails;
