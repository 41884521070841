import React, { useEffect, useState } from "react";
import videoIcon from "../../images/hugeicons_video-01.svg";
import clock from "../../images/Group 1000003649.svg";
import circle1 from "../../images/circle states (1).svg";
import circle2 from "../../images/circle states.svg";
import copyImg from '../../images/copy.png'
import { useNavigate } from "react-router-dom";
import { usePopup } from "../../components/PopupContext";
import BookingCallSkeleton from "./BookingCallSkeleton";

const BookingCall = ({
  mentorServices = [],
  handleUpdateTabClick,
  updateInfo,
  admin,
  fromMentor,
  serviceLoading,
  userProfile
}) => {
  const [selected, setSelected] = useState(null);
  const [copied, setCopied] = useState(false);
  const handleSelectService = (id) => {
    setSelected(id);
  };
  const navigate = useNavigate();
  const { getMentorClickedService, getMentorInfo, mentorInfo } = usePopup();
  console.log("mentordetails in booking call", mentorInfo);
  console.log(updateInfo, "updateInfo");
  useEffect(() => {
    getMentorInfo();
  }, [mentorInfo?.mentorId]);
  const mentorClickedService = async (serviceId,unique) => {
    if (admin) {
      navigate("/sign-up");
    } else if (mentorInfo?.mentorId) {
      getMentorClickedService(serviceId, mentorInfo);
      navigate(`/${sessionStorage.getItem('munique')}/${unique}`);
    }
  };
  function copy(uniqueUserName) {
    const el = document.createElement("input");
    el.value = "https://www.skillsquad.io/" + uniqueUserName;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    setCopied(true);
  }
  return serviceLoading ? (
    <BookingCallSkeleton />
  ) : (
    <div className="booking-call">
      <div className="booking-call-heading">
        Book 1:1 sessions from the options based on your needs
      </div>
      <div className="select-service-container">
        <div className="select-service-heading">Select a Service</div>
        {mentorServices.slice(0, 4).map((item, index) => (
          <div
            key={item.serviceId} 
            className={`call-service-card-selected ${
              selected === index ? "selected" : ""
            }`}
            onClick={() => handleSelectService(index)}
          >
            <div className="video-details">
              <div className="video-details-first">
                <img src={videoIcon} alt="video" />
                <div>{item.serviceName}</div>
              </div>
              <div className="booking-call-price-time">
                <div className="booking-call-price">₹ {item.sessionAmount}</div>
                <div className="booking-call-time">
                  <img src={clock} alt="clock" />
                  <div className="call-time">{item.serviceDuration} mins</div>
                </div>
              </div>
            </div>
            <div>
              <div
                className={`service-book-now ${
                  !admin && !fromMentor ? "bookdisable" : ""
                }`}
                onClick={() => mentorClickedService(item.serviceId,item.uniqueServiceId)}
              >
                Book Now
              </div>
            </div>
          </div>
        ))}
        <div
          className={mentorServices.length > 4 ? "" : "bookings-button-full"}
          style={{ display: "grid", gridAutoColumns: "1fr", width: "100%" }}
        >
          <div
            className="view-services"
            onClick={() => handleUpdateTabClick(2)}
          >
            View Services
          </div>
        </div>
      </div>
      <div class="d-flex align-items-center" style={{width:"100%"}}>
        <div
          style={{
            borderRadius: 5,
            border: "1px #DFEAF2",
            padding: "3%",
            backgroundColor: "#F7F7F9",
          }}
        >
          skillsquad.io/{userProfile?.uniqueUserName}
        </div>

        <div
          onClick={() => {
            copy(userProfile?.uniqueUserName);
          }}
          style={{
            cursor: "pointer",
            marginLeft: 20,
            borderRadius: 5,
            border: "1px #DFEAF2",
            padding: "2%",
            backgroundColor: "#F7F7F9",
            width:"auto"
          }}
        >
          {!copied ? (
            <img
             style={{height:"30px",width:"30px",maxWidth:"none",objectFit:"scale-down"}}
              alt="copy image"
              src={copyImg}
            />
          ) : (
            <div style={{ fontSize: 12, color: "green" }}>Copied!</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BookingCall;
