import React from "react";
import Sidebar from "./Sidebar";
import httpsignup from "../js/http-lms_userprofile";
import { useState, useEffect } from "react";
import "../styles/Discussion.css";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faArrowDown } from "@fortawesome/free-solid-svg-icons";
import Popup from "../components/DiscussionPopup";
import DiscussionView from "./DiscussionView";
import ReactHtmlParser from "html-react-parser";
import moment from "moment/moment";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";

import {motion} from 'framer-motion';
import RightSidebar from "./RightSidebar";

const Discussion = (props) => {
 
  const [userDetails, setUserDetails] = useState([]);
  const [pId, setPId] = useState();
  const [cId, setCId] = useState(0);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [openView, setOpenView] = useState(false);
  const [selectedTopicsOption, setSelectedTopicsOption] = useState(null);
  const [topicsList, setTopicsList] = useState([]);
  const [defaultDetails, setDetails] = useState({});
  const [selectedCourseId, setSelectedCourseId] = useState(0);
  const [email, setEmail] = useState();
  const [discussionsList, setDiscussionsList] = useState([]);
  const [discussionsData, setDiscussionsData] = useState({});
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [selectedCourseOption, setSelectedCourseOption] = useState(null);
  const [message, setMessage] = useState(null);
  const [titleId, setTitleId] = useState(0);
  const [hideThread, setHideThread] = useState(false);

  useEffect(() => {
    getLoggedInUserDetails();
    if(props.programId != null && props.topicId != null && props.profileId != null){
        getDiscusssionList(props.programId, props.topicId, 'desc', props.profileId, message)
    }
  }, []);

  function openViewBox(data) {
    setDiscussionsData(data);
    setOpenView(true);
  }

  function closeViewBox() {
    setOpenView(false);
    getLoggedInUserDetails();
  }

  const getLoggedInUserDetails = async () => {
    httpsignup
      .get(
        `/skillsquadUserprofileLMS/getSkillsquadUserProfileBySignUpId?signUpId=${sessionStorage.getItem(
          "userId"
        )}`
      )
      .then((response) => {
        httpsignup
          .get(`/usersLMS/getCheckTrainer?email=${response.data.userEmail}`)
          .then((response) => {
            if (response.data.profileId != null) {
              setHideThread(true);
              sessionStorage.setItem("discussionpId", response.data.profileId);
              setPId(response.data.profileId);
              httpsignup
                .get(
                  `/usersLMS/getTrainerDiscussionCourses?profileId=${response.data.profileId}`
                )
                .then((response) => {
                  console.log("Hello : " + JSON.stringify(response.data));
                  setUserDetails(response.data);

                  if (response.data.length > 0) {
                    console.log("BY Batch : " + JSON.stringify(response.data));
                    setSelectedCourseOption(response.data[0]);
                    // setCId(response.data[0].batchId)
                    setSelectedCourseId(response.data[0].courseId);
                    getTrainerDiscusssionList(
                      response.data[0].courseId,
                      0,
                      "desc",
                      sessionStorage.getItem("discussionpId"),
                      null
                    );
                  }
                })
                .catch((err) => {
                  console.log(err);
                });
            } else {
              httpsignup
                .get(
                  `/skillsquadUserprofileLMS/getSkillsquadUserProfileBySignUpId?signUpId=${sessionStorage.getItem(
                    "userId"
                  )}`
                )
                .then((response) => {
                  if (response.data.lmsProfileId != null) {
                    setPId(response.data.lmsProfileId);
                    sessionStorage.setItem(
                      "discussionpId",
                      response.data.lmsProfileId
                    );
                    setEmail(response.data.userEmail);
                    httpsignup
                      .get(
                        `/usersLMS/getDiscussionCourses?profileId=${response.data.lmsProfileId}`
                      )
                      .then((response) => {
                        setUserDetails(response.data);

                        if (response.data.length > 0) {
                          console.log(
                            "BY Batch : " + JSON.stringify(response.data)
                          );
                          setSelectedCourseOption(response.data[0]);
                          // setCId(response.data[0].batchId)
                          setSelectedCourseId(response.data[0].courseId);
                          if(props.programId != null && props.topicId != null && props.profileId != null){
                          
                            getDiscusssionList(props.programId, props.topicId, 'desc', props.profileId, message)
                        }else{
                          getDiscusssionList(
                            response.data[0].courseId,
                            0,
                            "desc",
                            sessionStorage.getItem("discussionpId"),
                            null
                          );
                        }
                        }
                      })
                      .catch((err) => {
                        console.log(err);
                      });
                  }
                })
                .catch((err) => {
                  console.log(err);
                });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const openPopup = () => {
    setIsPopupOpen(true);
    //navigate("/discussionPopup")
  };

  const closePopup = () => {
    setIsPopupOpen(false);
    getLoggedInUserDetails();
  };

  function getDiscusssionList(courseId, topicId, filter, pId, text) {
    
    setLoading(true);
    httpsignup
      .get(
        `/usersLMS/getDiscussions?programId=${courseId}&topicId=${topicId}&filter=${filter}&profileId=${pId}&searchText=${text}`
      )
      .then((response) => {
        setLoading(false);
        setDiscussionsList(response.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }
  function getTrainerDiscusssionList(courseId, topicId, filter, pId, text) {
    setLoading(true);
    httpsignup
      .get(
        `/usersLMS/getTrainerDiscussions?programId=${courseId}&topicId=${topicId}&filter=${filter}&profileId=${pId}&searchText=${text}`
      )
      .then((response) => {
        setLoading(false);
        setDiscussionsList(response.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }

  function oldest() {
    if(props.programId != null && props.topicId != null && props.profileId != null){
       
        getDiscusssionList(props.programId, props.topicId, 'desc', props.profileId, message)
    }else{
    getDiscusssionList(selectedCourseId, 0, "asc", pId);
    }
  }
  function newest() {
    if(props.programId != null && props.topicId != null && props.profileId != null){
        
        getDiscusssionList(props.programId, props.topicId, 'desc', props.profileId, message)
    }else{
    getDiscusssionList(selectedCourseId, 0, "desc", pId);
    }
  }

  function handleTopicsChange(e) {
    console.log("selected degree", e);
    console.log("selected default", e);
    //reset states & cities, once changed selected country
    setSelectedTopicsOption(e);
    setTitleId(e.lmsTopicMasterId);
    if (hideThread == true) {
      getTrainerDiscusssionList(
        selectedCourseId,
        e.lmsTopicMasterId,
        "desc",
        pId,
        message
      );
    } else {
        if(props.programId != null && props.topicId != null && props.profileId != null){
           
            getDiscusssionList(props.programId, props.topicId, 'desc', props.profileId, message)
        }else{
      getDiscusssionList(
        selectedCourseId,
        e.lmsTopicMasterId,
        "desc",
        pId,
        message
      );
    }
    }
  }

  function handleCourseChange(e) {
    console.log("selected degree", e);
    console.log("selected default", e);
    //reset states & cities, once changed selected country
    setSelectedCourseOption(e);
    //defaultDetails.titleId = e.lmsTopicMasterId;

    // getDiscusssionList(e.courseId, e.lmsTopicMasterId, 'desc', pId)
    changeProgram(e.courseId, e.batchId);
  }

  function changeProgram(courseId, batchId) {
    setSelectedCourseId(courseId);
    // setCId(batchId)
    setTopicsList([]);
    setSelectedTopicsOption(null);
    httpsignup
      .get(`/usersLMS/getTopicByProgram?programId=${courseId}`)
      .then((response) => {
        setTopicsList(response.data);
        if (hideThread == true) {
          getTrainerDiscusssionList(courseId, 0, "desc", pId, message);
        } else {
            if(props.programId != null && props.topicId != null && props.profileId != null){
               
                getDiscusssionList(props.programId, props.topicId, 'desc', props.profileId, message)
            }else{
          getDiscusssionList(courseId, 0, "desc", pId, message);
            }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const colourStyles = {
    control: (styles) => ({ ...styles, borderRadius: "10px" }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "#5627ff" : null,
        color: isFocused ? "white" : "black",
        fontWeight: isFocused ? 600 : 500,
      };
    },
  };

  const handleChanges = (event) => {
    setMessage(event.target.value);
    if (event.target.value.length > 0) {
      if (hideThread == true) {
        getTrainerDiscusssionList(
          selectedCourseId,
          titleId,
          "desc",
          pId,
          event.target.value
        );
      } else {
        if(props.programId != null && props.topicId != null && props.profileId != null){
            
            getDiscusssionList(props.programId, props.topicId, 'desc', props.profileId, message)
        }else{
        getDiscusssionList(
          selectedCourseId,
          titleId,
          "desc",
          pId,
          event.target.value
        );
    }
      }
    } else if (event.target.value.length == 0) {
      if (hideThread == true) {
        getTrainerDiscusssionList(
          selectedCourseId,
          titleId,
          "desc",
          sessionStorage.getItem("discussionpId"),
          null
        );
      } else {
        if(props.programId != null && props.topicId != null && props.profileId != null){
            
            getDiscusssionList(props.programId, props.topicId, 'desc', props.profileId, message)
        }else{
        getDiscusssionList(
          selectedCourseId,
          titleId,
          "desc",
          sessionStorage.getItem("discussionpId"),
          null
        );
    }
      }
    }
  };

  return (
    <>
      {(props.programId != null && props.topicId != null && props.profileId != null) ?  <></> : <Sidebar />}
      <div className="jobs-dashboard-container">
        {isPopupOpen && (
            <div>
            {(props.programId != null && props.topicId != null && props.profileId != null) ? 
                <Popup
            onClose={closePopup}
            
            pId={props.profileId}
            email={email}
            userDetails={userDetails}
            topicId = {props.topicId}
           programId = {props.programId}
          /> :<Popup
          
          onClose={closePopup} selectedCourseId={selectedCourseId} pId={pId} email={email} userDetails={userDetails}
        />
            }</div>
          
        )}
        {/* <Navb /> */}

        <div className="px-md-5  mt-5">
          <section class="section">
            <div class="container">
              <div class="row ">
                <div class="col-lg-12">
                  <div class="row">
                    <div class="">
                      <div class="mb-4">
                        <div className="col-lg-9">
                          {openView == false && isPopupOpen == false && (
                            <div>
                              <div class="row mt-5">
                              {(props.programId != null && props.topicId != null && props.profileId != null) ?  <></> :  <div class="col-5">
                                  <Select
                                    placeholder="Choose a Topic"
                                    className="multiselectdropdown"
                                    classNamePrefix="react-select"
                                    value={selectedCourseOption}
                                    onChange={handleCourseChange}
                                    getOptionLabel={(option) =>
                                      `${option.courseName}`
                                    }
                                    options={userDetails}
                                    styles={colourStyles}
                                  />
                                </div> }
                                {(props.programId != null && props.topicId != null && props.profileId != null) ?  <></> : <div class="col-5">
                                  <Select
                                    placeholder="Choose a Topic"
                                    className="multiselectdropdown"
                                    classNamePrefix="react-select"
                                    value={selectedTopicsOption}
                                    onChange={handleTopicsChange}
                                    getOptionLabel={(option) =>
                                      `${option.topicName}`
                                    }
                                    options={topicsList}
                                    styles={colourStyles}
                                  />
                                </div> }
                                {(props.programId != null && props.topicId != null && props.profileId != null) ?  <div class="col-3">
                                  {hideThread == false && (
                                    <div
                                      class=" new-thread-button"
                                      onClick={openPopup}
                                    >
                                      <div class="d-flex align-items-center">
                                        <img
                                          src={require("../images/user-plus.png")}
                                          alt="user"
                                          style={{
                                            marginLeft: 10,
                                            marginRight: "8px",
                                          }}
                                          //   width={13}
                                        />
                                        <div class="new-thread">Ask Doubt</div>
                                      </div>
                                    </div>
                                  )}
                                </div> :   <div class="col-2">
                                  {hideThread == false && (
                                    <div
                                      class=" new-thread-button"
                                      onClick={openPopup}
                                    >
                                      <div class="d-flex align-items-center">
                                        <img
                                          src={require("../images/user-plus.png")}
                                          alt="user"
                                          style={{
                                            marginLeft: 10,
                                            marginRight: "8px",
                                          }}
                                          //   width={13}
                                        />
                                        <div class="new-thread">Ask Doubt</div>
                                      </div>
                                    </div>
                                  )}
                                </div> }
                              </div>

                              <div class=" align-items-center mt-3">
                              {(props.programId != null && props.topicId != null && props.profileId != null) ?  <></> :    <div
                                  class="col d-flex align-items-center"
                                  style={{
                                    border: "1px solid rgb(182, 182, 182)",
                                    borderRadius: 10,
                                    padding: "5px 15px",
                                  }}
                                >
                                  <img
                                    src={require("../images/searchbtn.png")}
                                  />
                                  <input
                                    id="message"
                                    name="message"
                                    type="text"
                                    onChange={handleChanges}
                                    value={message}
                                    style={{
                                      border: 0,
                                      width: "auto",
                                      padding: 10,
                                    }}
                                    placeholder="Search your questions"
                                  />
                                </div> }
                                {/* <div class="col">
                                                            <div class="row">
                                                                <div class="col"><div class="discussion_search_tab_active  text-center">New</div></div>
                                                                <div class="col"><div class="discussion_search_tab  text-center">Top</div></div>
                                                                <div class="col"><div class="discussion_search_tab  text-center">Hot</div></div>
                                                                <div class="col"><div class="discussion_search_tab  text-center">Closed</div></div>
                                                            </div>
                                                        </div> */}
                              </div>
                              {/* <div class="row text-center mt-3">
                                                            <div class="col discussion_tabs_active common_main_heading pb-2">General</div>
                                                           <div class="col discussion_tabs">Project</div>
                                                        </div> */}
                            </div>
                          )}
                          {userDetails.length > 0 ? (
                            <div className="row">
                              {openView ? (
                                <DiscussionView
                                  discussionsData={discussionsData}
                                  closeViewBox={closeViewBox}
                                />
                              ) : (
                                <div>
                                  {isPopupOpen == false && (
                                    <div>
                                      {loading ? (
                                        <div className="d-flex py-5 justify-content-center w-100">
                                          <Spinner
                                            animation="border"
                                            variant="lightsecondary"
                                          />{" "}
                                        </div>
                                      ) : (
                                        <div>
                                          {discussionsList.length > 0 ? (
                                            <div>
                                              {discussionsList.map(
                                                (data, index) => {
                                                  return (
                                                    <div
                                                      class="discuaaion_card mt-4"
                                                      onClick={() => {
                                                        openViewBox(data);
                                                      }}
                                                      style={{
                                                        cursor: "pointer",
                                                        position: "relative",
                                                      }}
                                                    >
                                                      <div class="row">
                                                        <div class="col">
                                                          <div class="d-flex align-items-center">
                                                            <img
                                                              className=""
                                                              style={{
                                                                width: 40,
                                                                height: 40,
                                                                borderRadius:
                                                                  "50%",
                                                                marginRight:
                                                                  "6px",
                                                              }}
                                                              src={
                                                                data.profilePicture
                                                              }
                                                              alt="profile"
                                                            />
                                                            <div>
                                                              <div
                                                                class="discussion_username"
                                                                style={{
                                                                  marginBottom:
                                                                    "5px",
                                                                }}
                                                              >
                                                                {data.userName}
                                                              </div>
                                                              <div class="discussion_data">
                                                                {moment
                                                                  .utc(
                                                                    data.discussionCreatedDate
                                                                  )
                                                                  .local()
                                                                  .startOf(
                                                                    "seconds"
                                                                  )
                                                                  .fromNow()}
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div class=" student_query_block">
                                                        {/* <div className="discussion_query">Query</div>
                                                        <div class=" mt-3">
                                                          {data.title}
                                                        </div> */}
                                                        <span className="student_query">
                                                          Query
                                                        </span>
                                                        <span className="discussion_title">
                                                          {data.title}
                                                        </span>
                                                      </div>
                                                      {/* <div class="row"><div class="col mt-3"><ReadMore text={data.description} /></div></div> */}
                                                      <div class="discussion_footer mt-3 ">
                                                        <div class="d-flex align-items-center">
                                                          {/* <span className="relatedto">Related to</span> {" "}: */}
                                                          <div
                                                            class="discussion_tag_coursename"
                                                            style={{
                                                              marginRight: 5,
                                                            }}
                                                          >
                                                            {data.courseName}
                                                          </div>
                                                          <div class="discussion_tag_coursename">
                                                            {data.topicName}
                                                          </div>
                                                        </div>
                                                        <div class="discussion_upvote">
                                                          <div class="d-flex align-items-center">
                                                            <img
                                                              src={require("../images/uil_comment-dots.png")}
                                                              style={{
                                                                marginRight: 5,
                                                              }}
                                                            />
                                                            {data.replyCount}
                                                          </div>
                                                          <div class="d-flex align-items-center">
                                                            {data.upvoteFlag ==
                                                            "Y" ? (
                                                              <motion.div
                                                                // initial={{
                                                                //   y: 30,
                                                                //   opacity: 0,
                                                                //   scale: 0.8,
                                                                // }}
                                                                // animate={{
                                                                //   y: 0,
                                                                //   opacity: 1,
                                                                //   scale: 1,
                                                                // }}
                                                                // transition={{
                                                                //   type: "spring",
                                                                //   stiffness: 100,
                                                                // }}
                                                                class="d-flex align-items-center"
                                                                style={{
                                                                  color:
                                                                    "#1682FD",
                                                                }}
                                                              >
                                                                <img
                                                                  src={require("../images/bx_upvote.png")}
                                                                  style={{
                                                                    marginRight: 5,
                                                                  }}
                                                                />
                                                                {data.upvote}
                                                              </motion.div>
                                                            ) : (
                                                              <div class="d-flex align-items-center">
                                                                <img
                                                                  style={{
                                                                    marginRight: 5,
                                                                  }}
                                                                  src={require("../images/bx_upvote.png")}
                                                                />
                                                                {data.upvote}
                                                              </div>
                                                            )}
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  );
                                                }
                                              )}{" "}
                                            </div>
                                          ) : (
                                            <div class="d-flex align-items-center justify-content-center mt-5">
                                              <div class="liveclasses-schedule">
                                                No Discussions Available
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          ) : (
                            <div class="d-flex align-items-center justify-content-center mt-5">
                              <div class="liveclasses-schedule">
                                No Course is assigned yet
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      {/* <RightSidebar /> */}
    </>
  );
};

export default Discussion;
