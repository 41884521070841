import React, { useEffect } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import "../styles/AdminProfile.css";
import { useNavigate, useParams } from "react-router-dom";
import httpsignup from "../js/http-lms_userprofile";
import { useState } from "react";
import logo from "../images/blue_skillsquad_logo.png";
import ProfilePopup from "../components/RechargePopup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import {
  faMessage,
  faListUl,
  faBookOpenReader,
  faClipboardCheck,
  faHeadset,
  faLaptopCode,
  faBriefcase,
  faUsersRectangle,
  faUserTie,
  faSatelliteDish,
  faLayerGroup,
  faHouse,
  faComments,
} from "@fortawesome/free-solid-svg-icons";
import mixpanel from "mixpanel-browser";
import { usePopup } from "../components/PopupContext";
import search from "./MentorLandingPage/mentorimages/search 1.svg";
import Bookings from "./AllMentors/Bookings";
import useFetchMentorData, {
  useMentors,
} from "./MentorLandingPage/MentorDataContext";

const Navb = () => {
  const [image, setImage] = useState(null);
  const [wallet, setWallet] = useState(0);
  useEffect(() => {
    if (
      // window.location.pathname === "/" ||
      window.location.pathname === "/sign-in" ||
      window.location.pathname === "/custom" ||
      window.location.pathname === "/forgetpassword" ||
      window.location.pathname === "/verifyotp" ||
      window.location.pathname === "/updatepass" ||
      window.location.pathname === "/projects" ||
      window.location.pathname === "/sign-up" ||
      window.location.pathname === "/course" ||
      window.location.pathname === "/refund-policy" ||
      window.location.pathname === "/code-of-conduct" ||
      window.location.pathname === "/privacy-policy" ||
      window.location.pathname == "/terms-and-conditions"
    ) {
      nav.current.style.display = "none";
    } else {
      nav.current.style.display = "block";
    }
  }, [window.location.pathname]);

  const [userFirstName, setUserFirstName] = useState();

  const [isProfilePopupOpen, setIsProfilePopupOpen] = useState(false);
  const {
    openFirstPopup,
    isFirstPopupOpen,
    isSecondPopupOpen,
    isInitialPop,
    updateSearch,
    getAllMentorsListByCat,
    allMentors,
    stopMentorLoading,
    setMentorLoading,
    updateAdmin,
    adminProfile
  } = usePopup();

  const nav = React.useRef(null);

  const navigate = useNavigate();

  const openProfilePopup = () => {
    setIsProfilePopupOpen(true);
  };

  const closeProfilePopup = () => {
    setIsProfilePopupOpen(false);
  };

  function handleClickDash() {
    navigate("/activity");
  }

  function handleClickProg() {
    navigate("/build-project");
  }

  function handleClickBatch() {
    navigate("/user-profile");
  }

  function handleClickAssign() {
    navigate("/job-portal");
  }
  function handleClickHome() {
    if(sessionStorage.getItem("JWT")){
      navigate("/all-mentors");
    }else{
      window.location.reload();

    }
    
  }

  function handleClick6() {
    navigate("/");
    sessionStorage.clear();
    localStorage.clear();
  }

  function handleProfile1() {
    console.log("ko");
    setShowOptions(false)
    navigate("/user-profile");
  }

  function signUp() {
    navigate("/sign-up");
  }

  function handleProfile2() {
    window.open("https://forms.gle/rs5DttSSbWd6TMr47", "_blank");
    setShowOptions(false)
  }
  function talkToCounseller() {
    window.open("https://forms.gle/FZnu8MK6aJHr4SL7A", "_blank");
  }

  const [profileId, setprofileId] = useState(null);
  const { uniqueUserName } = useParams();
  const getLoggedInUserDetails = async () => {
    
      const response = await httpsignup
      .get(
        `/skillsquadUserprofileLMS/getSkillsquadUserProfileBySignUpId?signUpId=${sessionStorage.getItem(
          "userId"
        )}`
      )
      .then((response) => {
        console.log("UserDetails:", JSON.stringify(response));
        sessionStorage.setItem("userName", response.data.userName);
        setUserFirstName(response.data.userName);
        setImage(response.data.profilePictureUrl);
        setprofileId(response.data);
        getMentorInfo(response.data.profileId);
        if (response.data.eWalletAmount === null) {
          setWallet(0);
        } else {
          setWallet(response.data.eWalletAmount);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  
    }
    
  useEffect(() => {
    getLoggedInUserDetails();
  }, [sessionStorage.getItem('userId')]);

  const [mentorInfo, setMentorInfo] = useState(null);

  const [mentorHomeInfo, setMentorHomeInfo] = useState(null);

  const [updateInfo, setUpdateInfo] = useState(false);

  const getMentorInfo = async (id) => {
    try {
      const response = await httpsignup.get(
        `/mentor/getSkillsquadMentorApplicationsByProfileId?profileId=${id}`
      );
      if (response && response.data) {
        setMentorInfo(response.data);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const getMentorHomeInfo = async () => {
    try {
      const redirectChange = await httpsignup.get(
        `/mentor/getMentorHomeInfo?mentorId=${mentorInfo?.mentorId}`
      );
      if (redirectChange && redirectChange.data) {
        setMentorHomeInfo(redirectChange.data);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    getMentorHomeInfo();
  }, [mentorInfo?.mentorId]);
  useEffect(() => {
    if (mentorHomeInfo) {
      const Info = Object.values(mentorHomeInfo).every(
        (value) => value === "Y"
      );
      setUpdateInfo(Info);
    }
    console.log("update info", updateInfo, mentorHomeInfo);
  }, [mentorHomeInfo, updateInfo, mentorInfo?.mentorId]);
  const [appliedMentor, setAppliedMentor] = useState(false);
  const getStatusApplied = async () => {
    try {
      const response = await httpsignup.get(
        `/mentor/checkMentorApplied?profileId=${profileId?.profileId}`
      );
      if (response.data === "Applied") {
        setAppliedMentor(true);
        console.log("applied", appliedMentor);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getStatusApplied();
  }, [profileId, isSecondPopupOpen]);
  function handleClick1() {
    mixpanel.track("Clicked on Feed");
    navigate("/career-help");
  }

  function handleClick2() {
    mixpanel.track("Clicked on All Courses");
    navigate("/dashboard");
  }

  function handleClick3() {
    mixpanel.track("Clicked on My Course");
    navigate("/my-course");
  }

  function handleClick4() {
    mixpanel.track("Clicked on Discussion");
    navigate("/discussion");
  }

  function handleClick5() {
    mixpanel.track("Clicked on Live Classes");
    navigate("/live-classes");
  }

  function handleClick6() {
    mixpanel.track("Clicked on Projects");
    navigate("/build-project");
  }

  function handleClick7() {
    mixpanel.track("Clicked on Playground");
    navigate("/homes");
    //window.open("https://forms.gle/Rjzm4X64st2GEa7N9", "_blank")
  }

  function handleClick8() {
    mixpanel.track("Clicked on Jobs");
    navigate("/job-portal");
  }

  function handleClick9() {
    mixpanel.track("Clicked on Profile");
    navigate("/user-profile");
  }

  function handleClick10() {
    mixpanel.track("Clicked on Support");
    navigate("/support");
    //window.open("https://forms.gle/Rjzm4X64st2GEa7N9", "_blank")
  }
  

const [admin,setAdmin] = useState(false)

  const getUnique = async () => {
   
    if (uniqueUserName !== undefined && uniqueUserName !== null )  {
      try {
        console.log("before making api request",uniqueUserName)
        const response = await httpsignup.get(`/skillsquadUserprofileLMS/getSkillsquadUserProfileByUniqueName?uniqueUserName=${uniqueUserName}`)
        console.log("unique mentor id in provider",response.data)
        sessionStorage.setItem("userId", response.data.signUpId);

        setAdmin(true)
      }
      catch(error){
        console.log(error)
      }
    } else {
      console.error("error");
    }
    
  };
  useEffect(() => {
    getUnique();
  }, [uniqueUserName]);
useEffect(() =>{
  console.log("admin",admin,uniqueUserName)
  },[uniqueUserName,admin])
  const handleOpenPopup = () => {
    if (sessionStorage.getItem("userId") && sessionStorage.getItem("JWT")) {
      navigate("/user-profile");
      openFirstPopup();
    } else {
      navigate("/sign-up");
    }
  };
  // --------------------------------

  const { filterDataWithOpenAI, scrollToElementById } = useMentors();
  const [query, setQuery] = useState("");

  const handleFilter = (e) => {
    e.preventDefault();
    if(query.length >= 4){
      filterDataWithOpenAI(query);
        scrollToElementById("bookings-section");
    }else{
      alert("The query should have at least four characters ")
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleFilter(e);
    }
  };
  function loginPage() {
    navigate("/sign-in")
  }

  function handleClickLogOut() {
    navigate("/");
    sessionStorage.clear()
    localStorage.clear()
    setShowOptions(false);
  }
  const [showOptions,setShowOptions] = useState(false);
  const handleShowEditOptions = () =>{
    setShowOptions(!showOptions)
  }
  console.log("options",showOptions)
  return (
    <>
      {isProfilePopupOpen && (
        <ProfilePopup
          onClose={closeProfilePopup}
          refreshPage={() => getLoggedInUserDetails()}
        />
      )}

      <div ref={nav} className="mt-4 mx-1">
        <Navbar
          className=" nav1"
          fixed="top"
          expand="lg"
          style={{ padding: 0, backgroundColor: "#fff", borderBottom: "none" }}
        >
          <Container fluid>
            {/* <Navbar.Brand href="#">Navbar scroll</Navbar.Brand> */}
            <Navbar.Toggle aria-controls="navbarScroll" />
            <Navbar.Collapse id="navbarScroll">
              <Nav
                className="me-auto my-2 my-lg-0"
                style={{
                  maxHeight: "100px",
                  cursor: "pointer",
                  display: "flex",
                  gap: "20px",
                }}
                navbarScroll
              >
                <div
                  className="ms-2 ps-2 pt-3 pb-3 d-flex"
                  onClick={handleClickHome}
                >
                  <img
                    src={logo}
                    alt="logo"
                    className="ps-4"
                    style={{ width: 200, marginLeft: "10%", maxWidth: "none" }}
                  />
                </div>
                {updateSearch && (
                  <div
                    className="h-m-s"
                    style={{
                      height: "50px",
                      marginLeft: "20px",
                      width: "300px",
                    }}
                  >
                    <input
                      className="h-m-s-i"
                      placeholder="Search"
                      onChange={(e) => {
                        setQuery(e.target.value);
                      }}
                      onKeyDown={handleKeyDown}
                      value={query}
                    />
                    <div className="search-icon" onClick={handleFilter}>
                      <img src={search} alt="search" />
                    </div>
                  </div>
                )}
              </Nav>

              {/* {!adminProfile  && <Nav class="d-flex align-items-center"><div class="mx-2" style={{fontWeight: 600}}>Available Balance : ? {wallet}</div><div class="common_main_button" onClick={openProfilePopup}>Recharge</div></Nav>} */}
              {!(
                isInitialPop ||
                isFirstPopupOpen ||
                isSecondPopupOpen ||
                adminProfile ||
                updateInfo ||
                appliedMentor
              ) && (
                <Nav class="d-flex align-items-center">
                  <div
                    class="mx-2"
                    style={{ fontWeight: 600, cursor: "pointer" }}
                  ></div>
                  <div
                    className="become-mentor-button"
                    style={{ cursor: "pointer" }}
                    onClick={handleOpenPopup}
                  >
                    Become a mentor
                  </div>
                </Nav>
              )}
              {/* <div className='join-box-button button-trans' style={{padding:10}} onClick={talkToCounseller}>Talk to Counsellor</div> */}
              <Nav class="d-flex">
                <div
                  class="collapse navbar-collapse pe-2"
                  id="navbarNavDarkDropdown"
                  style={{ width: "10%" }}
                >
                  <ul class="navbar-nav" style={{ margin: "right" }}>
                    {(profileId && sessionStorage.getItem("JWT")) ? (
                      <li class="nav-item dropdown align-items-center">
                        <button
                          class="btn btn-dark dropdown-toggle"
                          style={{
                            backgroundColor: "white",
                            color: "black",
                            padding: 5,
                            marginBottom: 10,
                            position:"relative"
                          }}
                          
                          aria-expanded="false"
                          onClick={handleShowEditOptions}
                        >
                          <div className="d-flex pe-5 align-items-center">
                            <img
                              className="admin-pro-pic my-1 me-2"
                              style={{ width: 35, height: 35 }}
                              src={image}
                              alt="okay"
                            />

                            <h6 className="font14 mb0 mb-0">{userFirstName}</h6>
                            <h6 style={{ marginLeft: 5 }}>
                              <i class="arrow down"></i>
                            </h6>
                            {/* <h6 className='font10 mb0 black60'>Timestamp</h6> */}
                          </div>
                        </button>
                        {showOptions && 
                       <ul style={{ width: "100%",position:"absolute" }} className="user-dropdown">
                          {!(
                            isInitialPop ||
                            isFirstPopupOpen ||
                            isSecondPopupOpen ||
                            adminProfile
                          ) && (
                            <>
                              <li onClick={handleProfile1}>
                                <p >Edit</p>
                              </li>
                            </>
                          )}
                          <li onClick={handleProfile2}>
                            <p >Feedback</p>
                          </li>
                          <li onClick={handleClickLogOut}>
                            <p >Logout</p>
                          </li>
                        </ul>}
                      </li>
                    ) : (
                      <li class="nav-item dropdown align-items-center">
                        <button
                          class="btn btn-dark dropdown-toggle"
                          style={{
                            backgroundColor: "white",
                            color: "black",
                            padding: 5,
                           
                          }}
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <div className="d-flex align-items-center">
                            
                            <div
                            style={{marginRight:"10px"}}
                className="navbar-login-button logindiv"

                onClick={loginPage} >
                Login
              </div>
                            <button
                              class="login-button-nav logindiv"
                              onClick={signUp}
                            >
                              Sign up
                            </button>
                          </div>
                        </button>
                      </li>
                    )}
                  </ul>
                </div>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>

       
      </div>
    </>
  );
};

export default Navb;
