import React, { useEffect } from 'react'
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useState } from "react";
import 'react-image-crop/dist/ReactCrop.css'
import Select from 'react-select'
import httpsignup from '../../js/http-lms_userprofile';
import "react-datepicker/dist/react-datepicker.css";
import RightSidebar from '../RightSidebar';
import Sidebar from '../Sidebar';
import DatePicker from "react-datepicker";

const ReceivedAssignments = ({ onClose }) => {

  const [defaultuserDetails, setUserDetails] = useState({})
  //form data
  const [errorMessage, setErrorMessage] = useState("");
  const [formErros, setFormErrors] = useState({});
  const [disable, setDisable] = useState(false);
  const [selectedDegreeTypeOption, setSelectedDegreeTypeOption] = useState(null);
  const [degreeTypeList, setDegreeTypeList] = useState([])
  const [isSubmit, setIsSubmit] = useState(false);
  const [cardHeader, setCardHeader] = useState("Add Education")
  const [cardButton, setCardButton] = useState("Save")
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  useEffect(() => {
    getLoggedInUserDetails()
  }, [])
  const getLoggedInUserDetails = async () => {
    httpsignup.get(`/skillsquadUserprofileLMS/getSkillsquadUserEducationBySignUpId?signUpId=${sessionStorage.getItem('userId')}`)
      .then((response) => {
        
        setUserDetails(response.data[0])
        console.log("UserDetails:", JSON.stringify(response.data[0]))
        if (response.data[0].schoolCollegeName != null) {
          setStartDate(new Date(response.data[0].startDate))
          if (response.data[0].currentlyWorking == 'N') {

            setChecked(true)
            setEndDate(new Date(response.data[0].endDate))
          } else if (response.data[0].currentlyWorking == 'Y') {
            setChecked(false)
            

          }
          setSelectedDegreeTypeOption(response.data[0].degreeTypeMaster)
          setCardHeader("Edit Education")
          setCardButton("Update")
        } else {

        }
      }).catch((err) => {
        console.log(err)
      })
  }

  // validate data and enable/disable submit btn
  useEffect(() => {
    setFormErrors(validate(defaultuserDetails))
    enableDisableBtn()
  }, [defaultuserDetails])
  function enableDisableBtn() {
    if (defaultuserDetails.schoolCollegeName && defaultuserDetails.degreeTypeId && defaultuserDetails.majorFieldOfStudy) {
      console.log("false")
      //setDisable(false)
    }
    else {
      console.log("true")
      // setDisable(true)
    }
  }
  //updating defaultuserDetails for final submission
  function handleChange(e) {
    setErrorMessage('')
    const { name, value } = e.target;
    setUserDetails((pValue) => {
      console.log(pValue)
      return {
        ...pValue,
        [name]: value
      }
    })
    console.log("after change : ", defaultuserDetails)
  }

  function handleDegreeChange(e) {


    console.log("selected degree", e)
    console.log("selected default", e)
    //reset states & cities, once changed selected country
    setSelectedDegreeTypeOption(e)
    defaultuserDetails.degreeTypeId = e.degreeTypeId;
    setFormErrors(validate(defaultuserDetails))
  }

  // react-select custom styles and events
  const colourStyles = {
    control: (styles) => ({
      ...styles,
      // border: `1px solid #ced4da`, 
      // boxShadow: `0 0 0 1px`, 
      // ':hover' : {
      //   // boxShadow: `0 0 0 1px `,  
      //   backgroundColor : "blue"
      // } 
    }),
    option: (provided, state) => ({
      ...provided,
      fontWeight: state.isSelected ? "bold" : "normal",
      color: "black",
      backgroundColor: "white",
      fontSize: "14px",
      paddingTop: "4px",
      paddingBottom: "4px",
      ':hover': {
        // boxShadow: `0 0 0 1px `,  
        backgroundColor: "blue",
        color: "white"
      }
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: state.data.color,
      fontSize: state.selectProps.myFontSize
    })
  };
  function SubmitFinalData(e) {
    e.preventDefault()
    setFormErrors(validate(defaultuserDetails))
    setIsSubmit(true)
  }


  useEffect(() => {
    if (Object.keys(formErros).length === 0 && isSubmit) {
      if (checked == false) {
        defaultuserDetails.endDate = null;
        defaultuserDetails.currentlyStudying = 'Y';
      } else {
        defaultuserDetails.endDate = endDate;
        defaultuserDetails.currentlyStudying = 'N';
      }
      defaultuserDetails.startDate = startDate;
      httpsignup.put('/skillsquadUserprofileLMS/updateSkillsquadUserEducation', defaultuserDetails)
        .then((response) => {
          onClose()
          console.log("update response : ", response.data)
          setErrorMessage("Updated sucessfully")
          setTimeout(() => {
            setErrorMessage('')
          }, 5000)
        }).catch((e) => {
          console.log("error update : ", e.response)
        })
    }
  }, [formErros])
  // final submission

  const validate = (values) => {
    const errors = {};
    const allowaplaspaces = /^[a-zA-Z ]*$/;
    const emailregex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
    // if (!values.schoolCollegeName) {
    //   errors.schoolCollegeName = "School Or College Name is required!"
    // } else if (!allowaplaspaces.test(values.schoolCollegeName)) {
    //   errors.schoolCollegeName = "School or College name should contain alphabets and spaces"
    // }
    // if (!values.userEmail) {
    //   errors.userEmail = "Email Id is required!"
    // } else if (!emailregex.test(values.userEmail)) {
    //   errors.userEmail = "Please enter valid email id"
    // }
    // if (!values.majorFieldOfStudy) {
    //   errors.majorFieldOfStudy = "Major Field Of Study is required!"
    // }

    return errors;
  }

  //API call when page loader
  useEffect(() => {

    //fetching countries list
    httpsignup
      .get("/skillsquadUserprofileLMS/getDegreeTypeMaster")
      .then((res) => {
        console.log("user countries", res)
        setDegreeTypeList(res.data)
      })


  }, [])

  const [checked, setChecked] = React.useState(false);

  const handleCheckChange = () => {

    setChecked(!checked);
  };

  return (
    <div className="profile-popup-container">
      <div className="profile-popup">
        <div style={{ textAlign: "right", color: "black", cursor: "pointer" }} class="mb-3"> <div onClick={onClose}>X</div></div>
        <h2 class="d-flex common_main_text_highlight">{cardHeader}</h2>
        <p class="common_main_text_normal">Let’s get started with some personal details</p>
        <p class="mt-2 common_main_text_normal">Your online profiles</p>
        <center><p style={{ font: 18, fontWeight: 700, color: "green" }}>{errorMessage}</p></center>
        <Form id="create-User-Form">
          <Row className="pb-3">
            <Col className="col-lg-6 col-12 d-flex flex-column align-items-start">
              <div className="d-flex required align-items-center pb-1" ><img class="img-fluid"
                src={require("../../images/school_icon.png")}
                alt="Image not found"
                className="more"
                width={20}

              /> <h6 style={{ fontWeight: 600, marginLeft: 5 }}>School / College Name</h6></div>
              <Form.Control
                className={formErros.schoolCollegeName ? "borderred" : ""}
                placeholder="Enter School Or College Name"
                autoComplete="off"
                name='schoolCollegeName'
                defaultValue={defaultuserDetails.schoolCollegeName}
                onChange={handleChange}
              />
              <p className="red font12">{formErros.schoolCollegeName}</p>
            </Col>
            <Col className="col-lg-6 col-12 d-flex flex-column align-items-start">
              <div className="d-flex required align-items-center pb-1">
                <img class="img-fluid"
                  src={require("../../images/degree_icon.png")}
                  alt="Image not found"
                  className="more"
                  width={20}

                />
                <h6 style={{ fontWeight: 600, marginLeft: 5 }}>Degree Type</h6></div>
              <Select
                value={selectedDegreeTypeOption}
                onChange={handleDegreeChange}
                placeholder="Select Degree Type"
                className="multiselectdropdown"
                classNamePrefix="react-select"
                styles={colourStyles}
                getOptionLabel={option =>
                  `${option.degreeType}`
                }
                options={degreeTypeList}
              />
              <p className="red font12">{formErros.degreeTypeId}</p>
            </Col>



          </Row>
          <Row className="pb-3">
            <Col className="col-lg-6 col-12 d-flex flex-column align-items-start">
              <div className="d-flex required align-items-center pb-1">
                <img class="img-fluid"
                  src={require("../../images/study_icon.png")}
                  alt="Image not found"
                  className="more"
                  width={20}

                />
                <h6 style={{ fontWeight: 600, marginLeft: 5 }}>Major / Field Of Study </h6></div>
              <Form.Control
                className={formErros.majorFieldOfStudy ? "borderred" : ""}
                placeholder="Enter Major Field Of Study"
                autoComplete="off"
                name='majorFieldOfStudy'
                defaultValue={defaultuserDetails.majorFieldOfStudy}
                onChange={handleChange}
              />
              <p className="red font12">{formErros.majorFieldOfStudy}</p>
            </Col>


            <Col className="col-lg-4 col-12 d-flex flex-column align-items-start mt-lg-0 mt-3">
              <div className="d-flex required"><h6 style={{ fontWeight: 600, marginLeft: 5 }}>Start Date</h6></div>
              <DatePicker selected={startDate} onChange={(sdate) => setStartDate(sdate)} dateFormat="MM/dd/yyyy" />
            </Col>


          </Row>
          <Row className="pb-3">





            <Col className="col-lg-6 col-12 d-flex flex-column align-items-start">

              <div ><h6 style={{ fontWeight: 600, marginLeft: 5 }} className="d-flex mt-4 align-items-center"><input class="form-check-input" type="checkbox" id="check1" name="option1" value="something" checked={!checked}
                onChange={handleCheckChange} />
                <label class="form-check-label mx-3">Currently Studying</label></h6></div>

            </Col>

            {checked && <Col className="col-lg-6 col-12 d-flex flex-column align-items-start" >
              <div className="d-flex required"><h6 style={{ fontWeight: 600, marginLeft: 5 }}>End Date</h6></div>
              <DatePicker selected={endDate} onChange={(edate) => setEndDate(edate)} dateFormat="MM/dd/yyyy" disabled={!checked} />
            </Col>}

          </Row>


          <div>
            <Row >

              <Col className="col-lg-2 col-12 d-flex flex-column align-items-start mt-lg-0 mt-3">
                <button disabled={disable} className={disable ? "profile-buttons-update mt-3 mb-5 grey" : "profile-buttons-update mt-3 mb-5 common_main_button"} onClick={SubmitFinalData}>
                  {cardButton}
                </button>
              </Col>


            </Row>
          </div>
        </Form>
        {/* <div class="activityButton" onClick={allcourses} style={{width: "100%", textAlign:"center"}}>See More Courses</div> */}

      </div>
    </div>
  )
}

export default ReceivedAssignments;
