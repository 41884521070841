import React, { useContext, useState, useEffect } from 'react'
import CodeEditor from './CodeEditor'
import styled from 'styled-components'
import { BiEditAlt, BiImport, BiExport, BiFullscreen } from 'react-icons/bi'
import { ModalContext } from '../../context/ModalContext'
import Select from 'react-select';
import { languageMap } from '../../context/PlaygroundContext'
import { useNavigate, useParams } from "react-router-dom";
import httpsignup from '../../js/http-lms_userprofile';


const StyledEditorContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: ${({ isFullScreen }) => isFullScreen ? '100vh' : 'calc(100vh - 4.5rem)'};
`

const UpperToolBar = styled.div`
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.4rem;
  padding: 0.8rem 0.4rem;

  @media (max-width: 540px){
    height: 8rem;
  }
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 540px){
    width: 100%;
  }
`

const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-right: 2.3rem;
  font-size: 1.3rem;
  @media (min-width: 540px){
    margin-right: 1rem;
  }
`

const SelectBars = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;

  & > div{
    width: 8rem;
  }

  & > div:last-child{
    width: 10rem;
  }
`

const Button = styled.button`
  padding: 0.7rem 0.4rem;
  width: 6.2rem;
  background: #5627ff;
  border: none;
  border-radius: 32px;
  font-weight: 700;
  cursor: pointer;
`

const CodeEditorContainer = styled.div`
    height: calc(100% - 4rem);

    & > div{
        height: 100%;
    }
`

const LowerToolBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 0.8rem;
  padding: 0.8rem 1rem;

  input{
    display: none;
  }

  label, a, button{
    font-size: 1.2rem;
    border: none;
    display: flex;
    align-items: center;
    gap: 0.7rem;
    color: black;
  }
  button:first-child{
    background: none;
  }
  button:last-child{
    font-weight: 400;
    font-size: 1.1rem;
  }
`
const SaveAndRunButton = styled.button`
  padding: 0.6rem 1rem;
  background: #5627ff;
  border: none;
  border-radius: 32px;
  font-weight: 700;
  cursor: pointer;
`

const CompletedAssignmentEditorContainer = ({
  title,
  currentLanguage,
  setCurrentLanguage,
  currentCode,
  setCurrentCode,
  folderId,
  playgroundId,
  saveCode,
  runCode,
  getFile,
  isFullScreen,
  setIsFullScreen,
  statusName,
  finalOutput,
  expectedOutput
}) => {

  const { openModal } = useContext(ModalContext)
 
  

  const themeOptions = [
    { value: 'githubDark', label: 'githubDark' },
    { value: 'githubLight', label: 'githubLight' },
    { value: 'bespin', label: 'bespin' },
    { value: 'duotoneDark', label: 'duotoneDark' },
    { value: 'duotoneLight', label: 'duotoneLight' },
    { value: 'dracula', label: 'dracula' },
    { value: 'xcodeDark', label: 'xcodeDark' },
    { value: 'xcodeLight', label: 'xcodeLight' },
    { value: 'vscodeDark', label: 'vscodeDark' },
    { value: 'vscodeLight', label: 'vscodeLight' },
    { value: 'okaidia', label: 'okaidia' },
  ]

  const languageOptions = [
    { value: 'cpp', label: 'cpp' },
    { value: 'javascript', label: 'javascript' },
    { value: 'java', label: 'java' },
    { value: 'python', label: 'python' },
  ]

  const handleThemeChange = (selectedOption) => {
    setCurrentTheme(selectedOption)
  }

  const handleLanguageChange = (selectedOption) => {
    setLanguage(selectedOption)
    setCurrentLanguage(selectedOption.value)
    setCurrentCode(languageMap[selectedOption.value].defaultCode)
  }

  const [currentTheme, setCurrentTheme] = useState({ value: 'githubDark', label: 'githubDark' })
  const [language, setLanguage] = useState(() => {
    for (let i = 0; i < languageOptions.length; i++) {
      if (languageOptions[i].value === currentLanguage) {
        return languageOptions[i]
      }
    }
    return languageOptions[0];
  })
function navHome(){
  navigate("/homes");
}

function submit(){
  httpsignup.post(`/api/v1/topicAssignmentLMS/saveCodingAssignmentAnswers`,{
    lmsProfileId : sessionStorage.getItem("dayProfileId"),
    codingAssignmentsId : sessionStorage.getItem("assignmentTopicId"),
    code : currentCode,
    creationUserI : sessionStorage.getItem('userEmail')
  })
  .then((response) => {
     console.log("response : " + JSON.stringify(response))
     httpsignup.put(`/programsLMS/updateScheduleDayChallege?profileId=${sessionStorage.getItem("dayProfileId")}&videoCount=${sessionStorage.getItem("assignmentCount")}&programId=${sessionStorage.getItem("dayProgramId")}`)
     .then((response) => {
      
         console.log("video unlock count updated:", response.data)
         navigate("/day-challenge")
 
     }).catch((err) => {
       console.log(err)
     })
  }).catch((err) => {
      console.log(err)
  })
}
const navigate = useNavigate();
  return (
    <StyledEditorContainer isFullScreen={isFullScreen}>
      
     {!isFullScreen && <UpperToolBar>
        
      </UpperToolBar>
      }
      <CodeEditorContainer>
        <CodeEditor
          currentLanguage={currentLanguage}
          currentTheme={currentTheme.value}
          currentCode={currentCode}
          setCurrentCode={setCurrentCode}
        />
      </CodeEditorContainer>
      <LowerToolBar>
         </LowerToolBar>
    </StyledEditorContainer >
  )
}

export default CompletedAssignmentEditorContainer