import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'; 

const MentorSessionCardSkeleton = () => {
  return (
    <div className="mentor-session-card mb-3 mt-4">
      <div className="mentor-session-profile">
        <div className="mentor-image">
          <Skeleton circle={true} height={62} width={62} />
        </div>
        <div className="mentor-session-details">
          <div className="upcoming-mentor">
            <Skeleton width={200} />
          </div>
          <div className="mentor-name-session">
            <Skeleton width={150} />
            <Skeleton width={100} />
          </div>
          <div className="session-time-details">
            <div className="session-data s-date">
              <Skeleton width={100} />
            </div>
            <div className="session-data">
              <Skeleton width={100} />
            </div>
            <div className="session-data take-full-width">
              <Skeleton width={150} />
            </div>
          </div>
        </div>
      </div>
      <div className="cancel-join">
        <Skeleton  height={30} />
        <Skeleton  height={30} />
      </div>
    </div>
  );
};

export default MentorSessionCardSkeleton;
