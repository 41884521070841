import React from "react";
import Sidebar from "./Sidebar";
import "../styles/DayChallenge.css";
import "../fonts/Roboto/Roboto-Black.ttf";
import { useState, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import DatePicker from "./Date/DatePicker";
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles
} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import greenB from "../images/blackB.png";
import arrow from "../images/thin_blue_arrow_icon.png";
import book from "../images/writing_icon.png";
import clock from "../images/clock_icon.png";
import httpsignup from '../js/http-lms_userprofile';
import greyB from "../images/grey_correct_icon.png";
import blackL from "../images/black_lock_icon.png";
import code from "../images/code_icon.png"
import App from "../pages/MCQCompleted"
import ProgressBar from "react-bootstrap/ProgressBar";
import Popup from "../components/ChallengeComplete";
import RightSidebar from "./RightSidebar";
import arrowL from "../images/left-arrow_icon.png"
import resultB from "../images/result_icon.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCirclePlay } from '@fortawesome/free-solid-svg-icons'
import { VerticalAlignCenter } from "@material-ui/icons";


const DayChallengeComplete = () => {
    const percentage = 0/1;

    const [errorMessage, setErrorMessage] = useState(" ");

    const [allJobsList, setAllJobsList] = useState([])
    const[dayChallenge, setDayChallenge] = useState({})
    const [loading, setLoading] = useState(true)
    const [topicId, setTopicId] = useState()
    const [openQuiz, setOpenQuiz] = useState(false)
    const [lastTopicCount, setLastTopicCount] = useState()
    const [completedTask, setCompletedTask] = useState()
    const navigate = useNavigate();
    const [totalTaskDays, setTotalTaskDays] = useState(0);
    const [progressData, setProgressData] = useState(true);
    const [sideBarLoading, setSideBarLoading] = useState(true)
 

  

    //API call when page loader
    useEffect(() => {

      getLoggedInUserDetails();
       

    }, [])

    function getLoggedInUserDetails  ()  {
      console.log("TRIGGERED LOGGED")
      httpsignup.get(`/skillsquadUserprofileLMS/getSkillsquadUserProfileBySignUpId?signUpId=${sessionStorage.getItem('userId')}`)
      .then((response) => {
        sessionStorage.setItem("dayProfileId", response.data.lmsProfileId)
        sessionStorage.setItem("dayProgramId", sessionStorage.getItem("myCourseProgramId"))
        programProgress(response.data.lmsProfileId,0)
        httpsignup.get(`/programsLMS/getCompletedDayChallege?profileId=${sessionStorage.getItem("dayProfileId")}&programId=${sessionStorage.getItem("myCourseProgramId")}&day=${sessionStorage.getItem("dayId")}`)
        .then((response) => {
          console.log("UserDetails Day Challeneg : " + JSON.stringify(response.data))
          setLoading(false)
          setDayChallenge(response.data)
          setLastTopicCount(response.data.extraTopicList.length + 2)
          setCompletedTask(response.data.completedTask);
          setTopicId(response.data.quizCode)
       
        
          
        }).catch((err) => {
          console.log(err)
        })

      }).catch((err) => {
        console.log(err)
      })

    
    }
    const selectedDay = (val) => {
        console.log("date is : " + val)
      };

      function topicVideo(topicUrl,topicName){
        sessionStorage.setItem("completetopicUrl", topicUrl)
        sessionStorage.setItem("completetopicName", topicName)

        navigate("/complete-topic-video")
      }

      function quiz(){
        setOpenQuiz(true)
       
      }

      function quitQuiz(){
        setOpenQuiz(false)
        getLoggedInUserDetails();
        

      }

      function assignment(aId,attempt,count){
        if(attempt === null){
        sessionStorage.setItem("assignmentTopicId", aId)
        
        navigate("/completed-assignment-editor")
        }else{
          sessionStorage.setItem("assignmentTopicId", aId)
        sessionStorage.setItem("assignmentCount", count)
        navigate("/assignment-editor")
        }
      }

     

      function dayChalleneg() {
        navigate("/day-challenge")
      }

      function cheatSheetWindow(url) {
        // window.open(url, "_blank");
        console.log("URL : " + url)
        window.open(url, "_blank")
      }

      function programProgress(id,count) {
   
        httpsignup.get(`/programsLMS/getRightSideProgram?profileId=${id}&programId=${sessionStorage.getItem("myCourseProgramId")}&offset=${count}`)
          .then((response) => {
            console.log("UserDetails Day Challeneg Right Side : " + JSON.stringify(response.data))
            setSideBarLoading(false)
            setProgressData(null)
            setProgressData(response.data)
            setTotalTaskDays(response.data.totalTasks)
          }).catch((err) => {
            console.log(err)
          })
      }

    return (
        <>
            <Sidebar />

            <div className="jobs-dashboard-container">

        {/* <Navb /> */}

        <div className="px-md-5  mt-5">

          <section class="section">
            <div class="container">

              <div class="row ">

                <div class="col-lg-9">
                  <div class="row">
                    <div class="">
                      <div class="mb-4">
                        <div className="col-lg-12">
                          <div className="row" >
                    {openQuiz === false ?
                    <div class="d-flex align-items-center mt-5">
              <img src={arrowL} width={30} alt="arrow" style={{ cursor: "pointer", marginRight: 5 }} onClick={dayChalleneg} />   <h1 class="mb-4 common_main_heading">Your Completed Schedule - Day {sessionStorage.getItem("dayId")}</h1>
                 
      </div> : <></>}
      
      {openQuiz === false ? <div class="dayChallenge-task mt-4 mb-4" style={{ border: "1px solid #989898", padding: 20, borderRadius: 20,boxShadow:" 0 0 4px 0px #989898" }}>
                              
                              <div>
                                {/* <div class="d-flex align-items-center mb-2"><div style={{ width: "3%", marginRight: 5 }}>
                  <CircularProgressbar
                    value={(dayChallenge.completedTask / dayChallenge.totalTask) * 100}
                    strokeWidth={50}
                    styles={buildStyles({
                      strokeLinecap: "butt",
                      pathColor: "#1ae881",
                      trailColor: "black"
                    })}

                  />
                </div><div style={{ fontWeight: 700, fontSize: 20 }}>{dayChallenge.completedTask}/{dayChallenge.totalTask}</div></div> */}

                                {dayChallenge.topicURL != null && <div class="dayChallenge_box">

                                  <div class="container">
                                    <div class="row align-items-center">
                                      <div class="col-1">

                                        <div class='d-flex align-items-center stepper_line'>
                                          {dayChallenge.taskCount <= dayChallenge.totalTask && dayChallenge.completedTask != 0 ? <div><img src={greenB} alt="correct" width={30} height={30} />{dayChallenge.totalTask <= dayChallenge.completedTask && dayChallenge.completedTask != 0 ? <div style={{ marginLeft: "43%" }} class="n_vl"></div> : <div style={{ marginLeft: "43%" }} class="n_vl_u"></div>} </div> : <div>{(dayChallenge.taskCount === (dayChallenge.completedTask + 1) || dayChallenge.completedTask === dayChallenge.totalTask) ? <input type="radio"   style={{ height: 25, width: 25, verticalAlign: "middle" }}   checked = {true} onClick = {(e) => {e.preventDefault()}}/>  : <input type="radio"  style={{ height: 25, width: 25, verticalAlign: "middle" }}  onClick = {(e) => {e.preventDefault()}} />}  <div style={{ marginLeft: "51%" }} class="n_vl_u"></div></div>}


                                        </div>

                                      </div>
                                      <div class="col align-items-center" style={{ border: "1px solid black", borderRadius: 10, padding: "1%" }}>
                                        <div class="row align-items-center">
                                          <div class="col-9"><div class="row"><div class="col-2 col-lg-2"><img src={require("../images/day_icon.png")} alt="" /></div><div class="col" ><div class='day_topic_name common_main_text_highlight mb-2' >{dayChallenge.topicName}</div><div><div class="row"><div class="col-3 d-flex align-items-center"><img  src={require("../images/day_book.png")} width={15} style={{marginRight : 5}} alt=""/><div>Learning</div></div><div class="col-3 d-flex align-items-center"> <img  src={require("../images/day_clock.png")} width={15} style={{marginRight : 5}} alt=""/><div>12 minutes</div></div></div></div></div></div></div>
                                          <div class="col-3"><div class="d-flex align-items-center">
                                           

                                            <div class="d-flex align-items-center" style={{ cursor: "pointer" }} >
                                              <div class="common_main_button_review d-flex" onClick={() => { topicVideo(dayChallenge.topicURL, dayChallenge.topicName, dayChallenge.taskCount, 'start', dayChallenge.topicIndex) }}>Review <FontAwesomeIcon icon={faCirclePlay} style={{ marginLeft: 5 }} /></div>
                                            </div>

                                            </div></div></div>

                                      </div>
                                    </div>
                                  </div>
                                </div>}

                                <div>
                                  {loading ? <></> : dayChallenge.extraTopicList.length === 0 ? <></> :
                                    dayChallenge.extraTopicList.map((res) => {
                                      return (
                                        <div>
                                          {res.extraTopicTaskCount <= dayChallenge.completedTask && dayChallenge.completedTask != 0 ? <div class="n_vl"></div> : <div class="n_vl_u"></div>}

                                          <div class="dayChallenge_box">

                                            <div class="container">
                                              <div class="row align-items-center">
                                                <div class="col-1">

                                                  <div class='d-flex align-items-center stepper_line'>
                                                    {res.extraTopicTaskCount <= dayChallenge.completedTask && dayChallenge.completedTask != 0 ? <div><img src={greenB} alt="correct" width={30} height={30} />{res.extraTopicTaskCount <= dayChallenge.completedTask && dayChallenge.completedTask != 0 ? <div style={{ marginLeft: "43%" }} class="n_vl"></div> : <div style={{ marginLeft: "43%" }} class="n_vl_u"></div>} </div> : <div>{(res.extraTopicTaskCount === dayChallenge.completedTask+1  || dayChallenge.completedTask == dayChallenge.totalTask) ? <input type="radio"  style={{ height: 25, width: 25, verticalAlign: "middle" }} checked={true} onClick = {(e) => {e.preventDefault()}}/> : <input type="radio"  style={{ height: 25, width: 25, verticalAlign: "middle" }} onClick = {(e) => {e.preventDefault()}}  />}  <div style={{ marginLeft: "51%" }} class="n_vl_u"></div></div>}


                                                  </div>

                                                </div>
                                                <div class="col align-items-center" style={{ border: "1px solid black", borderRadius: 10, padding: "1%" }}>
                                                  <div class="row align-items-center">
                                                    <div class="col-9"><div class="row"><div class="col-2 col-lg-2"><img src={require("../images/day_icon.png")} alt="" /></div><div class="col" ><div class='day_topic_name common_main_text_highlight mb-2' >{res.extraTopicName}</div><div><div class="row"><div class="col-3 d-flex align-items-center"><img  src={require("../images/day_book.png")} width={15} style={{marginRight : 5}} alt=""/><div>Learning</div></div><div class="col-3 d-flex align-items-center"> <img  src={require("../images/day_clock.png")} width={15} style={{marginRight : 5}} alt=""/><div>12 minutes</div></div></div></div></div></div></div>
                                                    <div class="col-3"><div class="d-flex align-items-center">
                                                   

                                                    <div class="d-flex align-items-center" style={{ cursor: "pointer" }} >
                                                        <div class="common_main_button_review d-flex" onClick={() => { topicVideo(res.extraTopicURL, res.extraTopicName, res.extraTopicTaskCount, 'middle', res.extraTopicIndex) }}>Review <FontAwesomeIcon icon={faCirclePlay} style={{ marginLeft: 5 }} /></div>
                                                      </div>

                                                      </div></div></div>

                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      )
                                    })

                                  }
                                </div>
                                {(dayChallenge.cheatSheetUrl != null && dayChallenge.cheatSheetUrl != "") && <div>
                                  {dayChallenge.cheatSheetCount <= dayChallenge.completedTask && dayChallenge.completedTask != 0 ? <div class="n_vl"></div> : <div class="n_vl_u"></div>}

                                  <div class="dayChallenge_box">

                                    <div class="container">
                                      <div class="row align-items-center">
                                        <div class="col-1">

                                          <div class='d-flex align-items-center stepper_line'>
                                            
                                            {dayChallenge.cheatSheetCount <= dayChallenge.completedTask && dayChallenge.completedTask != 0 ? <div><img src={greenB} alt="correct" width={30} height={30} />{dayChallenge.cheatSheetCount <= dayChallenge.completedTask && dayChallenge.completedTask != 0 ? <div style={{ marginLeft: "43%" }} class="n_vl"></div> : <div style={{ marginLeft: "43%" }} class="n_vl_u"></div>} </div> : <div>{(dayChallenge.cheatSheetCount === dayChallenge.completedTask+1  || dayChallenge.completedTask == dayChallenge.totalTask) ? <input type="radio"  style={{ height: 25, width: 25, verticalAlign: "middle" }} checked={true} onClick = {(e) => {e.preventDefault()}}/> : <input type="radio"  style={{ height: 25, width: 25, verticalAlign: "middle" }} onClick = {(e) => {e.preventDefault()}} />}  <div style={{ marginLeft: "51%" }} class="n_vl_u"></div></div>}

                                          </div>

                                        </div>
                                        <div class="col align-items-center" style={{ border: "1px solid black", borderRadius: 10, padding: "1%" }}>
                                          <div class="row align-items-center">
                                            <div class="col-9"><div class="row"><div class="col-2 col-lg-2"><img src={require("../images/day_icon.png")} alt="" /></div><div class="col" ><div class='day_topic_name common_main_text_highlight mb-2' >Cheat Sheet</div><div><div class="row"><div class="col-3 d-flex align-items-center"><img  src={require("../images/day_book.png")} width={15} style={{marginRight : 5}} alt=""/><div>Learning</div></div><div class="col-3 d-flex align-items-center"> <img  src={require("../images/day_clock.png")} width={15} style={{marginRight : 5}} alt=""/><div>12 minutes</div></div></div></div></div></div></div>
                                            <div class="col-3"><div class="d-flex align-items-center">
                                             

                                               <div class="d-flex align-items-center" style={{ cursor: "pointer" }} >
                                                <div class="common_main_button_review d-flex" onClick={() => { cheatSheetWindow(dayChallenge.cheatSheetUrl) }}>Review <FontAwesomeIcon icon={faCirclePlay} style={{ marginLeft: 5 }} /></div>
                                              </div>

                                              </div></div></div>

                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>}
                                {(dayChallenge.interviewQuestIonsUrl != null && dayChallenge.interviewQuestIonsUrl != "") && <div>
                                  {dayChallenge.interviewQuestionsCount <= dayChallenge.completedTask && dayChallenge.completedTask != 0 ? <div class="n_vl"></div> : <div class="n_vl_u"></div>}

                                  <div class="dayChallenge_box">

                                    <div class="container">
                                      <div class="row align-items-center">
                                        <div class="col-1">

                                          <div class='d-flex align-items-center stepper_line'>
                                            
                                            {dayChallenge.interviewQuestionsCount <= dayChallenge.completedTask && dayChallenge.completedTask != 0 ? <div><img src={greenB} alt="correct" width={30} height={30} />{dayChallenge.interviewQuestionsCount <= dayChallenge.completedTask && dayChallenge.completedTask != 0 ? <div style={{ marginLeft: "43%" }} class="n_vl"></div> : <div style={{ marginLeft: "43%" }} class="n_vl_u"></div>} </div> : <div>{(dayChallenge.interviewQuestionsCount === dayChallenge.completedTask+1  || dayChallenge.completedTask == dayChallenge.totalTask) ? <input type="radio"  style={{ height: 25, width: 25, verticalAlign: "middle" }} checked={true} onClick = {(e) => {e.preventDefault()}} /> : <input type="radio"  style={{ height: 25, width: 25, verticalAlign: "middle" }} onClick = {(e) => {e.preventDefault()}} />}  <div style={{ marginLeft: "51%" }} class="n_vl_u"></div></div>}

                                          </div>

                                        </div>
                                        <div class="col align-items-center" style={{ border: "1px solid black", borderRadius: 10, padding: "1%" }}>
                                          <div class="row align-items-center">
                                            <div class="col-9"><div class="row"><div class="col-2 col-lg-2"><img src={require("../images/day_icon.png")} alt="" /></div><div class="col" ><div class='day_topic_name common_main_text_highlight mb-2' >Interview Questions</div><div><div class="row"><div class="col-3 d-flex align-items-center"><img  src={require("../images/day_book.png")} width={15} style={{marginRight : 5}} alt=""/><div>Learning</div></div><div class="col-3 d-flex align-items-center"> <img  src={require("../images/day_clock.png")} width={15} style={{marginRight : 5}} alt=""/><div>12 minutes</div></div></div></div></div></div></div>
                                            <div class="col-3"><div class="d-flex align-items-center">
                                              

                                               <div class="d-flex align-items-center" style={{ cursor: "pointer" }} >
                                                <div class="common_main_button_review d-flex" onClick={() => { cheatSheetWindow(dayChallenge.interviewQuestIonsUrl) }}>Review <FontAwesomeIcon icon={faCirclePlay} style={{ marginLeft: 5 }} /></div>
                                              </div>

                                             </div></div></div>

                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>}
                                {dayChallenge.quizCode != null && <div>
                                  {dayChallenge.mcqCount <= dayChallenge.completedTask && dayChallenge.completedTask != 0 ? <div class="n_vl"></div> : <div class="n_vl_u"></div>}

                                  <div class="dayChallenge_box">

                                    <div class="container">
                                      <div class="row align-items-center">
                                        <div class="col-1">

                                          <div class='d-flex align-items-center stepper_line'>
                                          {dayChallenge.mcqCount <= dayChallenge.completedTask && dayChallenge.completedTask != 0 ? <div><img src={greenB} alt="correct" width={30} height={30} />{dayChallenge.mcqCount <= dayChallenge.completedTask && dayChallenge.completedTask != 0 ? <div style={{ marginLeft: "43%" }} class="n_vl"></div> : <div style={{ marginLeft: "43%" }} class="n_vl_u"></div>} </div> : <div>{(dayChallenge.mcqCount === dayChallenge.completedTask+1  || dayChallenge.completedTask == dayChallenge.totalTask) ? <input type="radio"  style={{ height: 25, width: 25, verticalAlign: "middle" }} checked={true} onClick = {(e) => {e.preventDefault()}} /> : <input type="radio"  style={{ height: 25, width: 25, verticalAlign: "middle" }} onClick = {(e) => {e.preventDefault()}} />}  <div style={{ marginLeft: "51%" }} class="n_vl_u"></div></div>}

                                            {/* {(dayChallenge.mcqCount < dayChallenge.completedTask + 1 || dayChallenge.completedTask == dayChallenge.totalTask) && dayChallenge.completedTask != 0 && <div><img src={greenB} alt="correct" width={30} height={30} />{dayChallenge.mcqCount <= dayChallenge.completedTask && dayChallenge.completedTask != 0 ? <div style={{ marginLeft: "43%" }} class="n_vl"></div> : <div style={{ marginLeft: "43%" }} class="n_vl_u"></div>} </div>}
                                            {(dayChallenge.mcqCount == dayChallenge.completedTask + 1 || dayChallenge.completedTask == dayChallenge.totalTask) && dayChallenge.completedTask != 0 && <div><input type="radio"  style={{ height: 25, width: 25, verticalAlign: "middle" }} checked={true} /><div style={{ marginLeft: "51%" }} class="n_vl_u"></div></div>}
                                            {(dayChallenge.mcqCount > dayChallenge.completedTask + 1 || dayChallenge.completedTask == dayChallenge.totalTask) && dayChallenge.completedTask != 0 && <div><input type="radio"  style={{ height: 25, width: 25, verticalAlign: "middle" }} /><div style={{ marginLeft: "51%" }} class="n_vl_u"></div></div>} */}
                                          </div>

                                        </div>
                                        <div class="col align-items-center" style={{ border: "1px solid black", borderRadius: 10, padding: "1%" }}>
                                          <div class="row align-items-center">
                                            <div class="col-9"><div class="row"><div class="col-2 col-lg-2"><img src={require("../images/Quiz_icon.png")} alt="" /></div><div class="col" ><div class='day_topic_name common_main_text_highlight mb-2' >Quiz</div><div><div class="row"><div class="col-3 d-flex align-items-center"><img  src={require("../images/day_book.png")} width={15} style={{marginRight : 5}} alt=""/><div>Learning</div></div><div class="col-3 d-flex align-items-center"> <img  src={require("../images/day_clock.png")} width={15} style={{marginRight : 5}} alt=""/><div>12 minutes</div></div></div></div></div></div></div>
                                            <div class="col-3"><div class="d-flex align-items-center">
                                              

                                   <div class="d-flex align-items-center" style={{ cursor: "pointer" }} >
                                                <div class="common_main_button_review d-flex" onClick={quiz}>Review <FontAwesomeIcon icon={faCirclePlay} style={{ marginLeft: 5 }} /></div>
                                              </div>

                                             



                                            </div></div></div>

                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>}




                                {dayChallenge.extraAssessmentList != null && <div>
                                  {loading ? <></> : dayChallenge.extraTopicList.length === 0 ? <></> :
                                    dayChallenge.extraAssessmentList.map((res) => {
                                      return (
                                        <div>
                                          {res.extraAssessmentTaskCount <= dayChallenge.completedTask && dayChallenge.completedTask != 0 ? <div class="n_vl"></div> : <div class="n_vl_u"></div>}

                                          <div class="dayChallenge_box">

                                            <div class="container">
                                              <div class="row align-items-center">
                                                <div class="col-1">

                                                  <div class='d-flex align-items-center stepper_line'>
                                                    {res.extraAssessmentTaskCount <= dayChallenge.completedTask && dayChallenge.completedTask != 0 ? <div><img src={greenB} alt="correct" width={30} height={30} />{res.extraAssessmentTaskCount <= dayChallenge.completedTask && dayChallenge.completedTask != 0 ? <div style={{ marginLeft: "43%" }} class="n_vl"></div> : <div style={{ marginLeft: "43%" }} class="n_vl_u"></div>} </div> : <div>{(res.extraAssessmentTaskCount == dayChallenge.completedTask + 1 || dayChallenge.completedTask == dayChallenge.totalTask) ? <input type="radio"  style={{ height: 25, width: 25, verticalAlign: "middle" }} checked={true} onClick = {(e) => {e.preventDefault()}} /> : <input type="radio"  style={{ height: 25, width: 25, verticalAlign: "middle" }} onClick = {(e) => {e.preventDefault()}}/>}  <div style={{ marginLeft: "51%" }} class="n_vl_u"></div></div>}


                                                  </div>

                                                </div>
                                                <div class="col align-items-center" style={{ border: "1px solid black", borderRadius: 10, padding: "1%" }}>
                                                  <div class="row align-items-center">
                                                    <div class="col-9"><div class="row"><div class="col-2 col-lg-2"><img src={require("../images/Coding_icon.png")} alt="" /></div><div class="col" ><div class='day_topic_name common_main_text_highlight mb-2' >Coding Practice</div><div><div class="row"><div class="col-3 d-flex align-items-center"><img  src={require("../images/day_book.png")} width={15} style={{marginRight : 5}} alt=""/><div>Learning</div></div><div class="col-3 d-flex align-items-center"> <img  src={require("../images/day_clock.png")} width={15} style={{marginRight : 5}} alt=""/><div>12 minutes</div></div></div></div></div></div></div>
                                                    <div class="col-3"><div class="d-flex align-items-center">

                                                     

                                                       <div class="d-flex align-items-center" style={{ cursor: "pointer" }} >
                                                      {res.assessmentAttempt === 'Y' ? <div class="common_main_button d-flex" onClick={() => { assignment(res.extraAssessmentId, res.assessmentAttempt, res.extraAssessmentTaskCount) }}>Code<FontAwesomeIcon icon={faCirclePlay} style={{ marginLeft: 5 }} /></div>
                                                       :  <div class="common_main_button_review d-flex" onClick={() => { assignment(res.extraAssessmentId, res.assessmentAttempt, res.extraAssessmentTaskCount) }}>Review <FontAwesomeIcon icon={faCirclePlay} style={{ marginLeft: 5 }} /></div>} 
                                                        
                                                      </div>

                                                      
                                                    </div></div></div>

                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      )
                                    })

                                  }
                                </div>}
                              </div> </div>: <App  topicId={topicId} totalTask={completedTask + 1} callparentfunction={()=> quitQuiz()} />}





</div>
                        </div></div></div></div></div></div></div>

          </section>

        </div>
      </div>
            <RightSidebar callDayComplete={getLoggedInUserDetails} progressData={progressData} totalTaskDays={totalTaskDays} sideBarLoading={sideBarLoading} progressDataFunc={(id,count) => programProgress(id,count)}/>
        </>
    );
};

export default DayChallengeComplete;
