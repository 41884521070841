import React, { useEffect, useState } from "react";
import "../styles/BuildProject.css";
import Sidebar from "./Sidebar";

import httpsignup from '../js/http-lms_userprofile'

import star from "../images/twitter_project_icon.png"
import whatsapp from "../images/whatsapp_project_icon.png"
import amazon from "../images/amazon_project_icon.png"
import netflix from "../images/netflix_project_icon.png"
import flipkart from "../images/flipkart_project_icon.png"
import blue_tick from "../images/project_blue_tick_icon.png"
import Spinner from 'react-bootstrap/Spinner';
import ProjectDescription from "./ProjectDescription";


const Programs = () => {






  const [userDetails, setUserDetails] = useState([])
  const [cId, setCId] = useState(0)
  const [pId, setPId] = useState([])
  const [projectList, setProjectList] = useState([])
  const [projectDesc, setProjectDesc] = useState(false);
  const [projectData, setProjectData] = useState({})
  const [projectStatus, setProjectStatus] = useState({});
  const [loading, setLoading] = useState(true)







  const getLoggedInUserDetails = async () => {
    httpsignup.get(`/skillsquadUserprofileLMS/getSkillsquadUserProfileBySignUpId?signUpId=${sessionStorage.getItem("userId")}`)
      .then((response) => {
        console.log("BY Batch : " + JSON.stringify(response.data))
        setPId(response.data.profileId)
        ProgramDetailsList(cId, response.data.profileId);
        httpsignup.get(`/lmsVideoRecordingManager/getLiveClassByBatchId?profileId=${response.data.lmsProfileId}`)
          .then((response) => {
            setUserDetails(response.data)
            // console.log("BY Batch : " + JSON.stringify(response.data))

          }).catch((err) => {
            console.log(err)
          })

      }).catch((err) => {
        console.log(err)
      })
  }



  function ProgramDetailsList(id, profId) {
    //  mixpanel.track("My fav btn was clicked",{
    // name : "jay",
    //  class : "5th"
    //   })
    setLoading(true)
    setCId(id)
    httpsignup.get('/programsLMS/getProject?batchId=' + id + '&profileId=' + profId)
      .then((response) => {
        // console.log("program details : ", JSON.stringify(response.data))
        if (response.data && response.data.length > 0) {
          setLoading(false)
          setProjectList(response.data)

        } else {
          setLoading(false)
          setProjectList([])
        }

      }).catch((e) => {
        setLoading(false)
        console.log(e.response, "not successful")


      })
  }

  function disPlayDescription(data) {
    console.log("DATA SSS : " + JSON.stringify(data))
    setProjectData(data)
    setProjectDesc(true);
  }

  function stopDescription() {
    setProjectDesc(false);
    ProgramDetailsList(cId, pId)
  }

  useEffect(() => {
    getLoggedInUserDetails()


  }, [])

  return (
    <>
      <Sidebar />
      <div className="jobs-dashboard-container mx-4">
      <div className="px-md-5 mt160">

                    <section class="section">
                        <div class="container">

                            <div class="row">

                                <div class="col-lg-12">
                                    <div class="row">
                                        <div class="">
                                            <div class="section-title  mb-4 pb-2">

        {/* <Navb /> */}

        {projectDesc == false && <div className="">
          <div class="d-flex align-items-center">
            <div style={{ width: "50%" }}>
              <div class='common_main_heading'>The Best Way To Learn Is By Doing</div>
              <div class="common_main_sub_heading mt-4">Explore hundreds of free coding projects and complete
                them to get hands-on learning experience. Use them as
                your portfolio projects to get a job, or as a proof of work.</div>
            </div>
            <div style={{ width: "50%" }}>
              <div class="marquee-wrapper mt-5">
                <div class="container d-flex">
                  <div class="marquee-block">
                    <div class="marquee-inner to-left">
                      <span>
                        <div class="marquee-item">
                          <img src={star} alt="star" />
                        </div>
                        <div class="marquee-item">
                          <img src={whatsapp} alt="star" />
                        </div>
                        <div class="marquee-item">
                          <img src={amazon} alt="star" />
                        </div>
                        <div class="marquee-item">
                          <img src={netflix} alt="star" />
                        </div>
                        <div class="marquee-item">
                          <img src={flipkart} alt="star" />
                        </div>
                        <div class="marquee-item">
                          <img src={star} alt="star" />
                        </div>
                      </span>



                    </div>
                  </div>
                  <div class="marquee-block">
                    <div class="marquee-inner to-right">
                      <span>

                        <div class="marquee-item">
                          <img src={whatsapp} alt="star" />
                        </div>
                        <div class="marquee-item">
                          <img src={amazon} alt="star" />
                        </div>
                        <div class="marquee-item">
                          <img src={netflix} alt="star" />
                        </div>
                        <div class="marquee-item">
                          <img src={flipkart} alt="star" />
                        </div>
                        <div class="marquee-item">
                          <img src={star} alt="star" />
                        </div>
                        <div class="marquee-item">
                          <img src={star} alt="star" />
                        </div>
                        <div class="marquee-item">
                          <img src={star} alt="star" />
                        </div>
                        <div class="marquee-item">
                          <img src={star} alt="star" />
                        </div>
                      </span>
                    </div>
                  </div>
                  <div class="marquee-block">
                    <div class="marquee-inner to-left">
                      <span>

                        <div class="marquee-item">
                          <img src={whatsapp} alt="star" />
                        </div>
                        <div class="marquee-item">
                          <img src={amazon} alt="star" />
                        </div>
                        <div class="marquee-item">
                          <img src={netflix} alt="star" />
                        </div>
                        <div class="marquee-item">
                          <img src={flipkart} alt="star" />
                        </div>
                        <div class="marquee-item">
                          <img src={star} alt="star" />
                        </div>
                        <div class="marquee-item">
                          <img src={star} alt="star" />
                        </div>
                        <div class="marquee-item">
                          <img src={star} alt="star" />
                        </div>
                        <div class="marquee-item">
                          <img src={star} alt="star" />
                        </div>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex items-align-center mt-5" style={{ backgroundColor: '#F4f4f4' }}>
            <div class="build_project_get_on common_main_text_highlight" style={{ width: "50%" }}>Get hands-on with real-world projects</div>
            <div style={{ width: "50%", padding: "3%", borderRadius: "5% 0 0 5% / 50% 0 0 50%", backgroundImage: " linear-gradient(to right, #ECEAFE ,#F7F7FC)" }}>
              <div class="mb-4 d-flex align-items-center"> <img src={blue_tick} alt="tick" width={22} /> <div class="mx-3 common_main_text_normal">Level up your tech career </div></div>
              <div class="mb-4 d-flex align-items-center"> <img src={blue_tick} alt="tick" width={22} /> <div class="mx-3 common_main_text_normal">Explore 220+ projects to enhance your skills</div></div>
              <div class="d-flex align-items-center"> <img src={blue_tick} alt="tick" width={22} /> <div class="mx-3 common_main_text_normal">Build your portfolio</div></div>
            </div>
          </div>

          <div class="d-flex">
            {userDetails.map((data, index) => {
              return (<div class={cId === data.batchId ? "mt-3 liveActive liveBatch common_main_button_edit_nocircle" : ' mt-3 liveBatch common_main_button_edit_nocircle'} onClick={() => { ProgramDetailsList(data.batchId, pId) }}>{data.courseName}</div>)
            })}
          </div>

          <div class="mt-5 mb-5 row">
          {loading ? <div className="d-flex py-5 justify-content-center w-100"><Spinner animation="border" variant="lightsecondary" /> </div> :
            (projectList.length > 0) ?  projectList.map((data, index) => {
              return (<div class="build_project_cards col-3" onClick={() => { disPlayDescription(data) }}>
                <div class="container">
                  <div class="row">
                    <div class="col">
                      <img class="build_project_cards_img mb-3" src={data.skillsquadProjects.projectIconUrl} width={30} alt="icon" />
                    </div>
                    <div class="col">

                    </div>
                    <div class="col">
                      {data.skillsquadProjectsStatus == null ? <div class="build_project_cards_status common_main_text_tag" >Not Started</div> 
                        : <div>{data.skillsquadProjectsStatus.status == 'InProgress' ? <div class="build_project_cards_status common_main_text_tag" >{data.skillsquadProjectsStatus.status}</div> : <div class="build_project_cards_status common_main_text_tag" >{data.skillsquadProjectsStatus.status}</div>}</div>
                      }

                    </div>
                  </div>

                </div>

                <div class="build_project_cards_category common_main_text_tag mb-3"> {data.skillsquadProjects.projectCategory}</div>
                <div class="build_project_cards_project_name common_main_text_highlight mb-3">{data.skillsquadProjects.projectTitle}</div>
                <div class="build_project_cards_project_desc common_main_text_normal mb-3">{data.skillsquadProjects.projectDescription}</div>
                <div class="build_project_cards_skill common_main_text_highlight mb-2">SKILLS</div>
                <div class="build_project_cards_skill_desc common_main_text_normal">{data.skillsquadProjects.skills}</div>
              </div>)
            })  : <div class="d-flex align-items-center justify-content-center mt-5" >

            <div class="liveclasses-schedule">No Projects Available</div>




        </div>}


            {/* <div class="build_project_cards col-3" onClick={allcourses}>
              <img class="build_project_cards_img mb-3" src={require("../images/html_icon.png")} width={30} alt="icon" />
              <div class="build_project_cards_category mb-3"> Easy</div>
              <div class="build_project_cards_project_name mb-3">Multiverse HTML5 Photo Gallery</div>
              <div class="build_project_cards_project_desc mb-3">A photo gallery website. This will test your HTML and CSS skills. All the designs are available inside the project.</div>
              <div class="build_project_cards_skill mb-2">SKILLS</div>
              <div class="build_project_cards_skill_desc">HTML</div>
            </div> */}


          </div>

        </div>}

        {projectDesc == true && <ProjectDescription projectList={projectData} stopDescription={stopDescription} />}
        </div></div></div></div></div></div></section></div></div>


    </>
  );
};

export default Programs;
