import React, { useEffect, useRef, useState } from "react";
import "./MentorLanding.css";
import search from "./mentorimages/search 1.svg";
import amazon from "./mentorimages/amazon.svg";
import google from "./mentorimages/Google.svg";
import apple from "./mentorimages/Apple.svg";
import microsoft from "./mentorimages/Microsoft.svg";
import samsung from "./mentorimages/Samsung.svg";
// import why from "../mentorimages/why.svg";
import frame1 from "./mentorimages/Frame 1.svg";
import frame2 from "./mentorimages/Frame 2.svg";
import frame3 from "./mentorimages/Frame 3.svg";
import frame4 from "./mentorimages/Frame 4.svg";
import man from "./mentorimages/image 8.png";
import browsearrow from './mentorimages/arrow-right-browse.svg'
import manSittting from "./mentorimages/image 9.png";
import MentorTrustSlides from "./MentorTrustSlides";
import Bookings from "../AllMentors/Bookings";
import Technologies from "../AllMentors/Technologies";
import MentorOfferings from "../AllMentors/MentorOfferings";
import useElementInView from "./useElementInView";
import { usePopup } from "../../components/PopupContext";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useMentors } from "./MentorDataContext";
import {NewFooter} from '../../components/Footer/NewFooter'
import noservice from '../../images/no services.svg'
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Helmet } from "react-helmet";
import Fuse from 'fuse.js';
import { VscClose } from "react-icons/vsc";


const MentorLanding = () => {
  const navigate = useNavigate();
  const {
    updateSearchBar,
    getAllMentorsListByCat,
    allMentors,
    stopMentorLoading,
    setMentorLoading,
    mentorsList
  } = usePopup();
  const {
    filteredData,
    filterDataWithOpenAI,
    scrollToElementById,
    handleSearch,
    getId
  } = useMentors();
  const { category, offername } = useParams();
  useEffect(() =>{
    if(category === undefined){
      getId("recommended",null)
    }
    
    
  },[])
  const brandLogos = [amazon, google, apple, microsoft, samsung];
  const whyChoose = [
    {
      logo: frame1,
      heading: "100+ Categories",
      description:
        "Skillsquad delivers a striking solution for all your problems",
    },
    {
      logo: frame2,
      heading: "500+ Mentors",
      description:
        "Skillsquad delivers a striking solution for all your problems",
    },
    {
      logo: frame3,
      heading: "fast support",
      description:
        "Skillsquad delivers a striking solution for all your problems",
    },
    {
      logo: frame4,
      heading: "Secure Pay",
      description:
        "Skillsquad delivers a striking solution for all your problems",
    },
  ];

  const whyData = [
    {
      heading: "Proof of quality",
      description:
        "Check any mentor’s experience, mentor reviews, and identity verification.",
    },
    {
      heading: "Network and Career Support",
      description:
        "  Gain valuable connections within your field through your mentor's network.",
    },
    {
      heading: "Safe and secure",
      description:
        "Focus on your work knowing we help protect your data and privacy. We’re here with 24/7 support if you need it.",
    },
  ];
  const exploreData = [
    "Find opportunities for every stage of your mentoring career",
    "Control when, where, and how you work",
    "Explore different ways to earn",
  ];

  const [targetRef, isInView] = useElementInView({ threshold: 0.09 });

  useEffect(() => {
    if (isInView) {
      updateSearchBar(true);
    } else {
      updateSearchBar(false);
    }
  }, [isInView]);
  // -----------------------------
  const [query, setQuery] = useState("");

  const handleFilter = () => {
if(query.length >= 1){
  handleSearch(query)
    scrollToElementById("bookings-section");
}
  
  };
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleFilter();
    }
  };
  const handleLoginNavigate = () => {
    navigate("/sign-up");
  };
  const handleSearchMentor = (e) =>{
    const {value} = e.target;
    setQuery(value)
    
  }
  const removeHandleSearch = () =>{
    setQuery("");
    getId("recommended",null);
  }
  // -------------------------------------------
  

  
  return (
    <div>
      <div className="mentor-page-page" style={{ marginTop: "60px" }}>
     
           <Helmet>
       
        <title>Transform Your Career with 1:1 Mentorship | Skillsquad - Expert Guidance for Success</title>

       
        <meta name="description" content="Accelerate your career with personalized 1:1 mentorship from industry leaders. Skillsquad offers exclusive sessions in technology, business, design, and more to help you achieve your professional goals. Join now to unlock your potential." />

        
        <meta name="keywords" content="1:1 mentorship, career coaching, expert guidance, professional growth, technology mentorship, business mentorship, design mentorship, Skillsquad" />

        
        <meta property="og:url" content="https://www.skillsquad.io/aws-solution-architect-certification" />
        <meta property="og:title" content="1:1 Services" />
        <meta property="og:description" content="Comprehensive Training to architect, deploy, and manage scalable applications on Amazon Web Services" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="skillsquad.io" />
        <meta property="og:image" content="https://res.cloudinary.com/diok04k3u/image/upload/v1717652732/Meta_tag_zrtf6k.png" />
        <meta content="1200" property="og:image:width" />
        <meta content="630" property="og:image:height" />


       
        <meta name="twitter:card" content="https://res.cloudinary.com/diok04k3u/image/upload/v1717652732/Meta_tag_zrtf6k.png" />
        <meta name="twitter:title" content="Transform Your Career with 1:1 Mentorship | Skillsquad" />
        <meta name="twitter:description" content="Unlock your potential with personalized 1:1 mentorship from industry experts in technology, business, and design at Skillsquad." />
        <meta name="twitter:image" content="https://res.cloudinary.com/diok04k3u/image/upload/v1717652732/Meta_tag_zrtf6k.png" />
      </Helmet>
        <section id="hero-section">
          <div className="home-section">
            <div className="hero-section-search">
              <div className="h-m-m">
                <div className="h-m-h">
                  Connect 1:1 through video anytime, anywhere, for any
                  challenge.
                </div>
                <div className="h-m-p">
                  Connect with professionals across various fields to solve your
                  day-to-day challenges.
                </div>
                <div className="h-m-s">
                  <input
                    className="h-m-s-i"
                    placeholder="Search by mentor,company,role ..."
                    onChange={handleSearchMentor}
                    onKeyDown={handleKeyDown}
                    value={query}
                  />
                 {query.trim().length > 0 &&  <VscClose className="close-i-l" onClick={removeHandleSearch} />}
                  <div className="search-icon" onClick={handleFilter}>
                    <img src={search} alt="search" />
                  </div>
                </div>
                
                <div className="brand-logos">
                  {brandLogos.map((logo) => (
                    <img src={logo} />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
        <section style={{ marginTop: "30px" }}>
          <div className="container">
            <div className="browse-mentors">Browse Mentors by Category</div>
            <div className="have-a-skill">
              <div className="have-skill">Have a skill?</div>
              <Link to="/sign-up" className="b-h"><div  >Become a mentor</div></Link>
            </div>
          </div>
        </section>

        <section id="bookings-section">
          <div className="container">
            <Technologies />
            <MentorOfferings />
          </div>
        </section>
        <div ref={targetRef} className="mentor-page-page">
          <section className="load-more-function">
            <Bookings filterData={filteredData} />
            {(mentorsList?.length >= 8 && filteredData == null) && (
              <div className="load-more" onClick={handleLoginNavigate}>
               View More
              </div>
            )}
            
          </section>

          <section id={`why-choose`}>
            <div className="container">
              <div className="choose-skillsquad">
                <div className="c-s-h-s">
                  <div className="c-s-h">Why choose Skillsquad</div>
                  <div className="c-s-h-p">
                    Skillsquad delivers a striking solution for all your
                    problems
                  </div>
                </div>
                <div className="why-choose-cards">
                  {whyChoose.map((item) => (
                    <div className="why-layout">
                      <div className="w-c-l">
                        <img src={item.logo} />
                      </div>
                      <div className="w-c-h">{item.heading}</div>
                      <div className="w-c-d">{item.description}</div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </section>
          <section id="find-mentors" className="find-mentors">
            <div className="container mobile-find">
              <div className="find-mentors-section">
                <div className="f-m-i-s">
                  <div className="f-m-i-s-h">Find mentors your way</div>
                  <div className="f-m-i-s-p">
                    Work with the largest network of independent mentors and get
                    professional advice—from quick turnarounds to big
                    transformations.
                  </div>
                  <div className="browse-cards">
                    <div className="mentor-director">
                      <div className="mentor-d-h">
                        Browse the Mentor Directory
                      </div>
                      <Link to="/sign-in" className="mentor-na">Browse top mentors{""} <img src={browsearrow} alt="arrow" /></Link>
                    </div>
                    <div className="mentor-director">
                      <div className="mentor-d-h">Chat or book a Session</div>
                      <Link to="/sign-in" className="mentor-na">Book a mentor{""} <img src={browsearrow} alt="arrow" /></Link>
                    </div>
                    <div className="mentor-director-last">
                      <div className="mentor-d-h-last">
                        Let us help you find the right mentor
                      </div>
                      <div className="mentor-na-last">
                        Match mentors with skills{""} <img src={browsearrow} alt="arrow" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="browse-cards-mobile">
                <div className="mentor-director">
                  <div className="mentor-d-h">Browse the Mentor Directory</div>
                  <div className="mentor-na">Browse top mentors</div>
                </div>
                <div className="mentor-director">
                  <div className="mentor-d-h">Chat or book a Session</div>
                  <Link to="/sign-in" className="mentor-na">Book a mentor</Link>
                </div>
                <div className="mentor-director-last">
                  <div className="mentor-d-h-last">
                    Let us help you find the right mentor
                  </div>
                  <div className="mentor-na-last">
                    Match mentors with skills
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="people-turn-section">
            <div className="container">
              <div className="whole-why-people-section">
                <div className="why-people-section">
                  <div className="why-heading">
                    Why people turn to Skillsquad
                  </div>
                  <div className="quality-data">
                    {whyData.map((item) => (
                      <div className="quality-main">
                        <div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="25"
                            viewBox="0 0 24 25"
                            fill="none"
                          >
                            <path
                              d="M16.23 18.395L12 15.845L7.77 18.395L8.89 13.585L5.16 10.355L10.08 9.93502L12 5.39502L13.92 9.92502L18.84 10.345L15.11 13.575L16.23 18.395ZM12 2.39502C6.47 2.39502 2 6.89502 2 12.395C2 15.0472 3.05357 17.5907 4.92893 19.4661C5.85752 20.3947 6.95991 21.1313 8.17317 21.6338C9.38642 22.1364 10.6868 22.395 12 22.395C14.6522 22.395 17.1957 21.3415 19.0711 19.4661C20.9464 17.5907 22 15.0472 22 12.395C22 11.0818 21.7413 9.78144 21.2388 8.56818C20.7362 7.35493 19.9997 6.25254 19.0711 5.32395C18.1425 4.39537 17.0401 3.65877 15.8268 3.15622C14.6136 2.65368 13.3132 2.39502 12 2.39502Z"
                              fill="#2D432C"
                            />
                          </svg>
                        </div>
                        <div className="why-description">
                          <div className="why-d-h">{item.heading}</div>
                          <div className="why-d-p">{item.description}</div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div>
                  <img className="top-image" src={man} />
                </div>
                <div className="marketing-section">
                  <div className="inside-marketing">
                    <div className="inside-heading">
                      We’re <br />
                      the best mentor marketplace
                    </div>
                    <div className="i-m-rating">
                      <div>
                        <img src="" />
                      </div>
                      <div className="r-section">
                        <div className="r-h">4.9/5</div>
                        <div className="r-p">
                          mentees rate mentors on Skillsquad
                        </div>
                      </div>
                    </div>
                    {/* <div className="i-m-rating">
                      <div>
                        <img src="" />
                      </div>
                      <div className="r-section">
                        <div className="r-h">Award winner</div>
                        <div className="r-p">
                          G2’s 2024 Best Platform Awards
                        </div>
                      </div>
                    </div> */}
                  </div>
                  <div className="">
                    <img className="mobile-top-image" src={man} />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section
            id="find-great-mentee-section"
            className="find-great-mentee-section"
          >
            <div className="container">
              <div className="mentee-page container">
                <img className="think-image" src={manSittting} />
                <div className="for-mentors">
                  <div className="for-heading-section">
                    <div className="for-heading">
                      Find a great mentee to guide
                    </div>
                    <div className="for-para">
                      Meet mentees you’re excited to work with and take your
                      career to new heights.
                    </div>
                  </div>
                  <div className="explore-section">
                    <div className="explore-line"></div>
                    <div className="explore-data">
                      {exploreData.map((item) => (
                        <div className="explore-desc">{item}</div>
                      ))}
                    </div>
                    <Link to="/sign-up"><div className="b-mentor">Become a mentor</div></Link>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="testimonials-section">
            <div className="container">
              <div className="trust-heading" style={{ marginBottom: "30px" }}>
                Trusted by leading brands and startups
              </div>
             
              
            </div>
          </section>
          <MentorTrustSlides />
        </div>
      </div>
      <NewFooter />
    </div>
  );
};

export default MentorLanding;
