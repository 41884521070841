import React, { useEffect, useState } from "react";
import "../../styles/mentorlisting.css";
import search from "../../images/MagnifyingGlass.svg";
import Technologies from "./Technologies";
import MentorOfferings from "./MentorOfferings";
import Bookings from "./Bookings";
import Sidebar from "../../pages/Sidebar";
import { usePopup } from "../../components/PopupContext";
import axios from "axios";
import httpsignup from "../../js/http-lms_userprofile";
import { useMentors } from "../MentorLandingPage/MentorDataContext";
import { Helmet } from "react-helmet";
import ProfilePopup from "../../components/CompleteProfilePopup"
import { useNavigate } from "react-router-dom";

const MentorListing = () => {
  //  getAllMentorsListByCat(17);
  const {
    getAllMentorsListByCat,
    allMentors,
    stopMentorLoading,
    setMentorLoading,
     hideNavButtons
  } = usePopup();





  const openProfilePopup = () => {
    setIsProfilePopupOpen(true);
    hideNavButtons();
   
};

const closeProfilePopup = () => {
    setIsProfilePopupOpen(false);
    hideNavButtons();
   
};

  const [query, setQuery] = useState("");
const { setFilteredData,filteredData,filterDataWithOpenAI} = useMentors();
const [isProfilePopupOpen, setIsProfilePopupOpen] = useState(false);
const navigate = useNavigate()
  const handleFilter = (e) => {
    e.preventDefault();
    filterDataWithOpenAI(query);
  };

  useEffect(() => {
    getLoggedInUserDetails()
  }, []);


  const getLoggedInUserDetails = async () => {
    httpsignup.get(`/skillsquadUserprofileLMS/getSkillsquadUserProfileBySignUpId?signUpId=${sessionStorage.getItem('userId')}`)
        .then((response) => {
            sessionStorage.setItem("circullumpId", response.data.lmsProfileId);
            sessionStorage.setItem("skillsquadProfileId", response.data.profileId);
            console.log("RESPONSE Profile: " + JSON.stringify(response.data))
            if (response.data.uniqueUserName == null) {
                openProfilePopup()
            }else{
                if( sessionStorage.getItem("checkoutCourse") != null){
                    navigate("/courses/" +sessionStorage.getItem("checkoutCourse"))
                }
                if(sessionStorage.getItem("freeTrailId") != null){
                    
                    sessionStorage.setItem("myCourseProgramId",sessionStorage.getItem("freeTrailId"))
                    navigate("/day-circullum")
                }
            }
            
           
        }).catch((err) => {
            console.log(err)
        })
}

  return (
    <div>
     
      {isProfilePopupOpen && <ProfilePopup onClose={closeProfilePopup} />}
<Helmet>
        <title>
          Free 1:1 Mentor Sessions | Skillsquad - Expert Video Guidance for
          Every Professional
        </title>

        <meta
          name="description"
          content="Get free 1:1 video sessions with expert mentors in various fields, including technology, business, and design. Skillsquad connects you with industry leaders to help you advance your career, refine your skills, and achieve your professional aspirations. Start your journey with personalized guidance today."
        />

        <meta
          name="keywords"
          content="free 1:1 mentorship, video mentoring, career coaching, expert advice, professional development, technology mentors, business mentors, design mentors, Skillsquad"
        />

        <meta
          property="og:url"
          content="https://www.skillsquad.io/aws-solution-architect-certification"
        />
        <meta property="og:title" content="1:1 Services" />
        <meta
          property="og:description"
          content="Comprehensive Training to architect, deploy, and manage scalable applications on Amazon Web Services"
        />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="skillsquad.io" />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/diok04k3u/image/upload/v1717652732/Meta_tag_zrtf6k.png"
        />
        <meta content="1200" property="og:image:width" />
        <meta content="630" property="og:image:height" />
      </Helmet>
      <div
        className="container"
        style={{ paddingTop:"100px", minHeight: "100%" }}
      >
        <div className="mentor-listing-section">
         
          <Technologies />
          <MentorOfferings />

          <Bookings filterData={filteredData} />
        </div>
      </div>
    </div>
  );
};

export default MentorListing;
