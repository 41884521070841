import React, { useEffect, useState } from "react";
import { IoCloseOutline } from "react-icons/io5";
import info from "./info.svg";
import circleStates from "./circle states.svg";
import emptyCircle from "./emptyCircle.svg";
import httpsignup from "../../js/http-lms_userprofile";

const BecomeMentorAllDetails = ({
  becomeMentor,
  userProfile,
  education = [],
  experience = [],
  hndleMentorSubmitMentorDetails,
  handleBecomeMentorClose,
  handleGoSecondPopUp,
  totalExperience,
}) => {
  console.log(
    "these are all details mentor user profile information",
    totalExperience
  );
  const becomeMentorDetails = [
    {
      checked: userProfile.bio != null,
      filledCircle: circleStates,
      emptyOne: emptyCircle,
      heading: "Add bio",
      paragraph: "Add your information in the bio.",
    },
    {
      checked: userProfile.about != null,
      filledCircle: circleStates,
      emptyOne: emptyCircle,
      heading: "Add About",
      paragraph: "Add about yourself.",
    },
    {
      checked: totalExperience >= 3,
      filledCircle: circleStates,
      emptyOne: emptyCircle,
      heading: "Add Experience",
      paragraph: "Add your professional experience.(Min 3 years )",
    },
    {
      checked: education.length >= 1,
      filledCircle: circleStates,
      emptyOne: emptyCircle,
      heading: "Add Education",
      paragraph: "Add your education.",
    },
  ];

  const allFilled = becomeMentorDetails
    .map((item) => item.checked)
    .every(Boolean);
  const [profileId, setProfileId] = useState(null);
  const [loading, setLoading] = useState(false);
  const getLoggedInUserDetails = async () => {
    try {
      const response = await httpsignup.get(
        `/skillsquadUserprofileLMS/getSkillsquadUserProfileBySignUpId?signUpId=${sessionStorage.getItem(
          "userId"
        )}`
      );
      setProfileId(response.data.profileId);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getLoggedInUserDetails();
  }, []);

  const sendUserData = async () => {
    setLoading(true);
    try {
      const response = await httpsignup.post(
        `/dashBoardLMS/saveSkillsquadMentorApplications?profileId=${profileId}`
      );
      console.log("mentor saved successfully", response.data);
      setLoading(false);
      handleGoSecondPopUp();
    } catch (error) {
      console.log(error);
    }
  };

  const handleCombinedClick = () => {
    sendUserData();
  };

  return (
    <div>
      <div>
        <div
          className=""
          style={{ position: "relative", paddingBottom: "20px" }}
        >
          <div className="d-flex align-items-center justify-content-between">
            <h2 style={{ marginBottom: "10px" }}>Become a mentor</h2>
            <div className="">
              <IoCloseOutline
                style={{ fontSize: "2rem" }}
                onClick={handleBecomeMentorClose}
              />
            </div>
          </div>
          {!allFilled && (
            <div
              className="d-flex align-items-center "
              style={{ color: "red", marginBottom: "15px", width: "auto" }}
            >
              <img src={info} alt="info" style={{ marginRight: "6px" }} />
              <p style={{ color: "red" }}>Please complete your profile first</p>
            </div>
          )}
          {becomeMentorDetails.map((become, index) => (
            <div key={index} className="mentor-fill-card mb-3">
              <div className="mentor-main">
                {!become.checked ? (
                  <img src={become.emptyOne} alt="empty circle" />
                ) : (
                  <img src={become.filledCircle} alt="filled circle" />
                )}
                <div className="d-flex flex-column">
                  <h3 style={{ marginBottom: "3px" }}>{become.heading}</h3>
                  <p>{become.paragraph}</p>
                </div>
              </div>
              {!become.checked && (
                <div
                  onClick={() => hndleMentorSubmitMentorDetails(become.heading)}
                >
                  <button
                    className="btn btn-primary"
                    style={{ background: "#000", color: "#fff" }}
                  >
                    Add Now
                  </button>
                </div>
              )}
            </div>
          ))}
          {allFilled && (
            <div
              className="d-flex"
              style={{ alignItems: "start", width: "90%" }}
            >
              <img src={info} alt="info" />
              <p style={{ marginLeft: "5px" }}>
                Are you sure you want to submit your profile? Once submitted,
                your profile will undergo verification by our admin team. Please
                ensure that all information provided is accurate and complete.
                Click 'Submit' to proceed or 'Cancel' to review your profile
                before submission.
              </p>
            </div>
          )}
          {allFilled && (
            <div style={{ marginBottom: "20px" }} className="button-margin">
              <div className="mentor-button">
                <button
                  className="btn btn-info"
                  onClick={handleBecomeMentorClose}
                  style={{ background: "grey", color: "#fff" }}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-success"
                  onClick={handleCombinedClick}
                  style={{ background: "#000", color: "#fff" }}
                >
                  {loading ? "loading" : "Submit"}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BecomeMentorAllDetails;
