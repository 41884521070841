import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { IoCloseOutline } from "react-icons/io5";

const ProfileForm = ({
  editProfile,
  onClose,
  profileFillData,
  handleSubmit,
  optionCat,
  optionLan,
  socialMediaEntries,
  setSocialMediaEntries,
  socialMediaPlatforms,
  newEntry,
  handlePlatformChange,
  handleUrlChange,
  handleAddEntry,
  urlError,
}) => {
  const initialValues = {
    userName: profileFillData.userName || "",
    uniqueUserName: profileFillData.uniqueUserName,
    prefession: profileFillData.prefession || "",
    cityId: profileFillData.cityId || null,
    bio: profileFillData.bio || "",
    languages:
      profileFillData.languages 
        ? profileFillData.languages.split(",").map((id) => id.trim())
        : [],
  };
  const validationSchema = Yup.object({
    userName: Yup.string()
      .trim("This sfield should not contain spaces")
      .min(3, "User Name must be at least 3 characters")
      .max(30, "User Name cannot exceed 30 characters")
      .required("Required"),
    prefession: Yup.string()
      .trim("This sfield should not contain spaces")
      .required("Required"),
    cityId: Yup.number().required("Required"),
    bio: Yup.string()
      .trim("This sfield should not contain spaces")
      .min(50, "Minimum 50 characters")
      .max(300, "Maximum 300 characters")
      .required("Required"),
      languages: Yup.array().min(1, "At least one language is required"),
  });

  const onSubmit = (values) => {
    const updatedValues = {
      ...profileFillData,
      ...values,
      languages: values.languages.join(","),
    };
    handleSubmit(updatedValues);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ setFieldValue, values }) => (
        <Form>
          <div className={`popup-overlay ${editProfile ? "open" : ""}`}>
            <div className={`popup-content`}>
              <h2>Edit Profile</h2>

              <div className="add-course-layout mb-3">
                <p className="course-title" style={{ marginBottom: "4px" }}>
                  Your Name<span style={{ color: "red" }}>*</span>
                </p>
                <Field
                  className="course-title-input"
                  type="text"
                  name="userName"
                  placeholder="Add your name here"
                />
                <ErrorMessage
                  name="userName"
                  component="span"
                  style={{ color: "red" }}
                />
              </div>

              <div className="add-course-layout mb-3">
                <p className="course-title" style={{ marginBottom: "4px" }}>
                  Profession<span style={{ color: "red" }}>*</span>
                </p>
                <Field
                  className="course-title-input"
                  type="text"
                  name="prefession"
                  placeholder="Add your profession ex:software developer"
                />
                <ErrorMessage
                  name="prefession"
                  component="span"
                  style={{ color: "red" }}
                />
              </div>

              <div className="add-course-layout mb-3">
                <p className="course-title" style={{ marginBottom: "4px" }}>
                  Location<span style={{ color: "red" }}>*</span>
                </p>
                <Select
                  name="cityId"
                  options={optionCat}
                  placeholder="Select Location"
                  onChange={(option) => setFieldValue("cityId", option.value)}
                  value={optionCat.find(
                    (option) => option.value == values.cityId
                  )}
                />
                <ErrorMessage
                  name="cityId"
                  component="p"
                  style={{ color: "red" }}
                />
              </div>
              <div className="add-course-layout mb-3">
                <p className="course-title" style={{ marginBottom: "4px" }}>
                  Languages<span style={{ color: "red" }}>*</span>
                </p>
                <Select
                  name="languages"
                  options={optionLan}
                  placeholder="Select Languages"
                  isMulti
                  onChange={(options) => {
                    const selectedValues = options.map((option) => option.value);
                    setFieldValue("languages", selectedValues);
                  }}
                  
                  value={optionLan.filter((language) =>
                    values.languages
                      ?.map((id) => String(id))
                      .includes(String(language.value))
                  )}
                />
              
                <ErrorMessage
                  name="languages"
                  component="p"
                  style={{ color: "red" }}
                />
              </div>

              <div className="add-course-layout mb-3">
                <p className="course-title" style={{ marginBottom: "4px" }}>
                  Bio<span style={{ color: "red" }}>*</span>
                </p>
                <Field
                  as="textarea"
                  rows={4}
                  cols={10}
                  className="add-information"
                  name="bio"
                  placeholder="Add your bio here"
                />
                <ErrorMessage
                  name="bio"
                  component="span"
                  style={{ color: "red" }}
                />
              </div>

              <div>
                <h2 className="social-heading">Social network</h2>
                <p style={{ marginBottom: "" }}>
                  Indicate the desired communication method
                </p>
                {socialMediaEntries.map((entry, index) => (
                  <div key={index}>
                    <select
                      value={entry.platform}
                      className="social-select platform-select"
                      onChange={(e) =>
                        setSocialMediaEntries(
                          socialMediaEntries.map((item, id) =>
                            id === index
                              ? { ...item, platform: e.target.value }
                              : item
                          )
                        )
                      }
                    >
                      <option value="">Select a platform</option>
                      {socialMediaPlatforms.map((platform) => (
                        <option key={platform.name} value={platform.name}>
                          {platform.icon} {platform.name}
                        </option>
                      ))}
                    </select>
                    <input
                      className="social-select"
                      type="url"
                      value={entry.url}
                      onChange={(e) =>
                        setSocialMediaEntries(
                          socialMediaEntries.map((item, id) =>
                            index === id
                              ? { ...item, url: e.target.value }
                              : item
                          )
                        )
                      }
                      placeholder="Ex: https://www.linkedin/your-profile"
                    />
                    <button
                      type="button"
                      className="btn btn-danger"
                      onClick={() =>
                        setSocialMediaEntries(
                          socialMediaEntries.filter((_, id) => id !== index)
                        )
                      }
                    >
                      Remove
                    </button>
                  </div>
                ))}
                <div className="mt-1 mobile-application-social">
                  <select
                    value={newEntry.platform}
                    onChange={handlePlatformChange}
                    className="social-select-options platform-select"
                  >
                    <option value="">Select a platform</option>
                    {socialMediaPlatforms.map((platform) => (
                      <option key={platform.name} value={platform.name}>
                        {platform.icon} {platform.name}
                      </option>
                    ))}
                  </select>
                  <input
                    className="social-select"
                    type="url"
                    value={newEntry.url}
                    onChange={handleUrlChange}
                    placeholder="Ex: https://www.linkedin/your-profile"
                  />
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleAddEntry}
                  >
                    Add
                  </button>
                </div>
              </div>
              <div style={{ color: "red" }}>{urlError}</div>
              <div className="" style={{ alignSelf: "end" }}>
                <button type="submit" className="saveButton text-right mt-2">
                  Save
                </button>
              </div>
              <IoCloseOutline
                onClick={onClose}
                style={{
                  position: "absolute",
                  top: "20px",
                  right: "20px",
                  fontSize: "2rem",
                }}
              />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ProfileForm;
