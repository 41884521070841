import { useState, useEffect } from "react";
import { NewFooter } from '../../components/Footer/NewFooter';
import { NewHeader } from '../../components/NewHeader'

const RefundPolicy = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return (

        <>
 <NewHeader />
            <div className="jobs-dashboard-container" style={{ width: "100%" }}>

                {/* <Navb /> */}

                <div className="px-md-5  mt-5">

                    <section class="section">
                        <div class="container">

                            <div class="row ">

                                <div class="col-lg-12">
                                    <div class="row mb-3">
                                      
                                            <div className="col"></div>
                                                <div className="col-9">
                                                    <center><div style={{ fontSize: 50, fontWeight: 700 }} class="mb-5">Refund Policy</div></center>
                                                    <div class="mt-3" style={{ border: "1px solid grey", borderRadius: 10, boxShadow: "2px 1px 5px 0px rgba(0, 0, 0, 0.15)", padding: "3%" }}>
                                                        <div class="mt-3">Thank you for your purchase of our courses. We are committed to ensuring a rewarding experience for our users as they explore, evaluate, and purchase our training programs, whether  it's for self-paced, self-paced with live support, or our Job Guarantee program.</div>

                                                        <div class="mt-3">As with any online purchase, please take note of the following important terms and conditions that govern our Refund Policy. By purchasing a training course on the Skillsquad website, you agree to abide by our Privacy Policy and Terms of Use, as well as the following provisions.</div>
                                                        <div class="mt-3" style={{ fontSize: 30, fontWeight: 700, lineHeight: 1 }}>Self-Paced Program / Self-Paced with Live Support Program</div>
                                                        <div class="mt-3">Hey there, we're sorry to break it to you, but once you've enrolled in our self-paced courses, there's no going back. No refunds, no take-backs. It's like committing to a long-distance relationship, but with knowledge instead of cuddles.</div>
                                                        <div class="mt-3" style={{ fontSize: 20, fontWeight: 700, lineHeight: 1 }}>Sharing of Course Materials:</div>
                                                        <div class="mt-3">
                                                            We get it, sharing is caring, but not when it comes to our course materials, videos, self paced projects etc... Keep them all to yourself, like your secret stash of snacks. If we catch you spreading the love (or sharing) with others, your access will vanish faster than a magician's disappearing act. Poof!</div>
                                                        <div class="mt-3" style={{ fontSize: 20, fontWeight: 700, lineHeight: 1 }}>
                                                            Account Suspension:</div>
                                                        <div class="mt-3">We don't want to be the bad guys, but if you break the rules or misuse our precious course materials, videos etc. we have no choice but to suspend your account. It's like being sent to the virtual detention room. Sorry, no refunds for rebellious behavior.</div>
                                                        <div class="mt-3" style={{ fontSize: 30, fontWeight: 700, lineHeight: 1 }}>Job Guarantee Program</div>
                                                        <div class="mt-3" style={{ fontSize: 20, fontWeight: 700, lineHeight: 1 }}>Event Postponement/Cancellation:</div>
                                                        <div class="mt-3">We've got you covered even in unpredictable situations like instructor illness or force-majeure events. If we need to postpone or cancel an event, unfortunately, a refund won't be in the cards.</div>
                                                        <div class="mt-3" style={{ fontSize: 20, fontWeight: 700, lineHeight: 1 }}>Refund Eligibility:</div>
                                                        <div class="mt-3">Once you've dipped your toes into our online classroom training or accessed/downloaded course material, we can't reverse the magic. So, no refunds if you've already dived into the content.</div>
                                                        <div class="mt-3" style={{ fontSize: 20, fontWeight: 700, lineHeight: 1 }}>Discounted Courses:</div>
                                                        <div class="mt-3">We love a good deal, but unfortunately, no refunds are available for courses purchased at discounted prices.</div>
                                                        <div class="mt-3" style={{ fontSize: 20, fontWeight: 700, lineHeight: 1 }}>Academic Institute Tie-up Courses:</div>
                                                        <div class="mt-3">Our Collab's with academic institutes are super special, but refunds aren't on the syllabus for these courses.</div>
                                                        <div class="mt-3" style={{ fontSize: 20, fontWeight: 700, lineHeight: 1 }}>Self-paced Courses:</div>
                                                        <div class="mt-3">When you go at your own pace, refunds take a backseat. All enrollments and payments for self-paced courses are considered final.</div>
                                                        <div class="mt-3" style={{ fontSize: 20, fontWeight: 700, lineHeight: 1 }}>Non-attendance:</div>
                                                        <div class="mt-3">We'll miss you if you don't show up for the scheduled sessions, but refunds won't be waving hello.</div>
                                                        <div class="mt-3" style={{ fontSize: 20, fontWeight: 700, lineHeight: 1 }}>Taxes:</div>
                                                        <div class="mt-3">We wish we could refund your taxes too, but alas, refunds won't include any taxes paid.</div>
                                                        <div class="mt-3" style={{ fontSize: 20, fontWeight: 700, lineHeight: 1 }}>Technical Access Issues:</div>
                                                        <div class="mt-3">If gremlins get in the way of accessing self-paced videos or online recordings, and you've already completed the course through instructor-led online classes, refunds won't be making a guest appearance. Plus, with lifetime access, you won't miss out on anything.</div>
                                                        <div class="mt-3" style={{ fontSize: 20, fontWeight: 700, lineHeight: 1 }}>Refund Processing:</div>
                                                        <div class="mt-3">We promise to hustle and process refund requests within 30 working days. Your patience will be rewarded!</div>
                                                        <div class="mt-3" style={{ fontSize: 20, fontWeight: 700, lineHeight: 1 }}>Additional Information:</div>
                                                        <div class="mt-3">We believe in your potential and offer a job guarantee program, provided you meet specific criteria like completing the course, acing assessments, tackling projects, clearing mock interviews, and nailing at least one job interview out of the ten provided. If a refund becomes a possibility, it may come in the form of cash or credit, with Skillsquad sprinkling its magic touch.</div>
                                                        <div class="mt-3" style={{ fontWeight: 700, lineHeight: 2 }}>Please take a moment to review our refund policy. If you have any further questions or need a virtual pep talk, our support team is here to cheer you on.</div>
                                                        <div class="mt-3" style={{ fontWeight: 700, lineHeight: 2 }}>Remember, we're here to provide an awesome learning experience, so let's stick to the guidelines and make this educational journey unforgettable. Now, go forth and conquer the world of knowledge!</div>
                                                    </div>

                                                </div>
                                                <div className="col"></div>
                                                </div></div></div></div>

                    </section>

                </div>
                <NewFooter />
            </div>

        </>
    );
}

export default RefundPolicy