import React from 'react'

const MentorTermsPage = () => {
  return (
    <>
      <div>
        {/* <NewHeader /> */}
        <div  style={{ width: "100%" }}>
          {/* <Navb /> */}

          <div className="pt-5">
            <section class="section">
              <div class="container">
                <div class="row ">
                  <div class="col-lg-12 ">
                    <div class="row mb-3">
                      {/* <div className="col"></div> */}
                      <div className="col">
                        <center>
                          <div
                            style={{ fontSize: 50, fontWeight: 700 }}
                            class="mb-5"
                          >
                            Terms & Conditions
                          </div>
                        </center>
                        <div
                          class="mt-3"
                          style={{
                            border: "1px solid grey",
                            borderRadius: 10,
                            boxShadow: "2px 1px 5px 0px rgba(0, 0, 0, 0.15)",
                            padding: "3%",
                            background: "white",
                          }}
                        >
                          <div
                            class="mt-3"
                            style={{
                              fontSize: 20,
                              fontWeight: 700,
                              lineHeight: 2,
                            }}
                          >
                            User Account and Registration
                            <br /> 1. Account Creation
                            <br />
                            1.1 Eligibility Requirements:
                          </div>
                          <div class="mt-3">
                            Users must be at least 18 years of age or the age of
                            majority in their jurisdiction to register for an
                            account on SkillSquad. Minors under 18 must obtain
                            parental or guardian consent. By registering, you
                            affirm that you meet these eligibility criteria.
                          </div>
                          <div
                            class="mt-3"
                            style={{
                              fontSize: 20,
                              fontWeight: 700,
                              lineHeight: 2,
                            }}
                          >
                            1.2 Account Categories:
                          </div>
                          <div class="mt-3 ">
                            SkillSquad provides two distinct account types:
                            <li>
                              <b>Learner Account</b>: For individuals seeking to
                              purchase courses, access the job portal, and book
                              expert services.
                            </li>
                            <li>
                              <b>Expert Account</b>: For professionals offering
                              services via the SkillSquad marketplace.
                            </li>
                          </div>
                          <div
                            class="mt-3"
                            style={{
                              fontSize: 20,
                              fontWeight: 700,
                              lineHeight: 2,
                            }}
                          >
                            1.3 Registration Obligations:
                          </div>
                          <div class="mt-3">
                            Users must provide accurate, current, and complete
                            registration information, including but not limited
                            to, name, email address, and any other mandatory
                            details. Users are obligated to promptly update
                            their information as necessary.
                          </div>

                          <div
                            class="mt-3"
                            style={{
                              fontSize: 20,
                              fontWeight: 700,
                              lineHeight: 2,
                            }}
                          >
                            2. Account Security <br />
                            2.1 Confidentiality Obligations:
                          </div>
                          <div class="mt-3">
                            Users are responsible for maintaining the
                            confidentiality of their login credentials.
                            Unauthorized sharing of account details is
                            prohibited. Any suspected unauthorized use must be
                            reported to SkillSquad immediately.
                          </div>

                          <div
                            class="mt-3"
                            style={{
                              fontSize: 20,
                              fontWeight: 700,
                              lineHeight: 2,
                            }}
                          >
                            2.2 Account Liability:{" "}
                          </div>
                          <div class="mt-3">
                            Users are accountable for all activities conducted
                            under their account, regardless of authorization.
                            SkillSquad disclaims liability for any losses
                            arising from a user s failure to secure their
                            account.
                          </div>

                          <div
                            class="mt-3"
                            style={{
                              fontSize: 20,
                              fontWeight: 700,
                              lineHeight: 2,
                            }}
                          >
                            2.3 Prohibition on Multiple Accounts:
                          </div>

                          <div class="mt-3">
                            Users may not create multiple accounts for the same
                            purpose without express authorization from
                            SkillSquad. Violation of this policy may result in
                            suspension or termination of all associated
                            accounts.
                          </div>

                          <div
                            class="mt-3"
                            style={{
                              fontSize: 20,
                              fontWeight: 700,
                              lineHeight: 2,
                            }}
                          >
                            3. Account Usage
                            <br /> 3.1 Personal Use Limitation:
                          </div>
                          <div class="mt-3">
                            Learner accounts are strictly for personal use.
                            Transfer, assignment, or sale of accounts is
                            prohibited without SkillSquad s prior written
                            consent. Unauthorized commercial use is strictly
                            forbidden.
                          </div>
                          <div
                            class="mt-3"
                            style={{
                              fontSize: 20,
                              fontWeight: 700,
                              lineHeight: 2,
                            }}
                          >
                            3.2 Expert Account Obligations:
                          </div>
                          <div class="mt-3">
                            Experts must ensure that all profile information and
                            services offered are accurate, lawful, and conform
                            to SkillSquad s guidelines and applicable laws.
                            Experts are responsible for providing services as
                            described and maintaining professional standards.
                          </div>

                          <div
                            class="mt-3"
                            style={{
                              fontSize: 20,
                              fontWeight: 700,
                              lineHeight: 2,
                            }}
                          >
                            3.3 Prohibited Conduct:
                          </div>
                          <div class="mt-3">
                            Users are prohibited from engaging in fraudulent,
                            harassing, or otherwise unlawful activities on the
                            platform. Violations may result in immediate account
                            suspension or termination.
                          </div>

                          <div
                            class="mt-3"
                            style={{
                              fontSize: 20,
                              fontWeight: 700,
                              lineHeight: 2,
                            }}
                          >
                            4. Account Suspension and Termination <br />
                            4.1 SkillSquad s Right to Suspend or Terminate:
                          </div>
                          <div class="mt-3">
                            SkillSquad reserves the right to suspend or
                            terminate accounts at its sole discretion, without
                            prior notice, for violations of these terms,
                            fraudulent or illegal activities, or actions
                            detrimental to the platform s integrity. Notice of
                            suspension or termination will be provided, with
                            potential for appeal.
                          </div>
                          <div
                            class="mt-3"
                            style={{
                              fontSize: 20,
                              fontWeight: 700,
                              lineHeight: 2,
                            }}
                          >
                            4.2 User-Initiated Termination:
                          </div>
                          <div class="mt-3">
                            Users may terminate their account at any time
                            through the account settings. Upon termination,
                            access to all associated courses, services, and data
                            will be revoked.
                          </div>
                          <div
                            class="mt-3"
                            style={{
                              fontSize: 20,
                              fontWeight: 700,
                              lineHeight: 2,
                            }}
                          >
                            4.3 Post-Termination Effects:
                          </div>
                          <div class="mt-3">
                            Upon account termination, SkillSquad will deactivate
                            or delete the account and all associated data. Users
                            acknowledge that SkillSquad is under no obligation
                            to retain or provide copies of any data following
                            termination.
                          </div>

                          <div
                            class="mt-3"
                            style={{
                              fontSize: 20,
                              fontWeight: 700,
                              lineHeight: 2,
                            }}
                          >
                            5. Account Recovery
                            <br /> 5.1 Password Recovery:
                          </div>
                          <div class="mt-3">
                            In the event of a forgotten password, users may
                            utilize the  Forgot Password  feature to securely
                            reset their credentials by following the provided
                            instructions.
                          </div>
                          <div
                            class="mt-3"
                            style={{
                              fontSize: 20,
                              fontWeight: 700,
                              lineHeight: 2,
                            }}
                          >
                            5.2 Reinstatement of Suspended or Terminated
                            Accounts:
                          </div>
                          <div class="mt-3">
                            Users whose accounts have been suspended or
                            terminated may contact customer support to request
                            reinstatement. Reinstatement is at the sole
                            discretion of SkillSquad and may be subject to
                            additional conditions.
                          </div>

                          <div
                            class="mt-3"
                            style={{
                              fontSize: 20,
                              fontWeight: 700,
                              lineHeight: 2,
                            }}
                          >
                            5.2 Reinstatement of Suspended or Terminated
                            Accounts:
                          </div>
                          <div class="mt-3">
                            Users whose accounts have been suspended or
                            terminated may contact customer support to request
                            reinstatement. Reinstatement is at the sole
                            discretion of SkillSquad and may be subject to
                            additional conditions.
                          </div>

                          <div
                            class="mt-3"
                            style={{
                              fontSize: 20,
                              fontWeight: 700,
                              lineHeight: 2,
                            }}
                          >
                            6. Invoicing and Receipts
                            <br />
                            6.1 Electronic Documentation:
                          </div>
                          <div class="mt-3">
                            Users will receive electronic invoices or receipts
                            post-transaction. These documents will include a
                            detailed breakdown of charges and taxes.
                          </div>

                          <div
                            class="mt-3"
                            style={{
                              fontSize: 20,
                              fontWeight: 700,
                              lineHeight: 2,
                            }}
                          >
                            6.2 Billing History Access:
                          </div>
                          <div class="mt-3">
                            Users can access and download billing history from
                            their account dashboard and are responsible for
                            retaining these records.
                          </div>

                          <div
                            class="mt-3"
                            style={{
                              fontSize: 20,
                              fontWeight: 700,
                              lineHeight: 2,
                            }}
                          >
                            7. Payment Disputes
                            <br />
                            7.1 Disputed Charges:
                          </div>
                          <div class="mt-3">
                            Users must report billing errors or unauthorized
                            transactions within 30 days of the charge.
                            SkillSquad will investigate and rectify if
                            necessary.
                          </div>
                          <div
                            class="mt-3"
                            style={{
                              fontSize: 20,
                              fontWeight: 700,
                              lineHeight: 2,
                            }}
                          >
                            7.2 Chargebacks:
                          </div>
                          <div class="mt-3">
                            Initiating a chargeback without first contacting
                            SkillSquad may result in account suspension or
                            termination. SkillSquad reserves the right to
                            contest chargebacks.
                          </div>

                          <div
                            class="mt-3"
                            style={{
                              fontSize: 20,
                              fontWeight: 700,
                              lineHeight: 2,
                            }}
                          >
                            8. Late Payments
                            <br />
                            8.1 Access Restrictions:
                          </div>
                          <div class="mt-3">
                            Failure to complete payment may result in restricted
                            access to the relevant courses, services, or
                            subscriptions until payment is resolved.
                          </div>

                          <div
                            class="mt-3"
                            style={{
                              fontSize: 20,
                              fontWeight: 700,
                              lineHeight: 2,
                            }}
                          >
                            8.2 Collections and Legal Recourse:
                          </div>
                          <div class="mt-3">
                            In cases of non-payment, SkillSquad reserves the
                            right to pursue legal remedies, including the
                            engagement of collection agencies.F
                          </div>

                          <div
                            class="mt-3"
                            style={{
                              fontSize: 20,
                              fontWeight: 700,
                              lineHeight: 2,
                            }}
                          >
                            Mentor Responsibilities
                            <br />
                            1. Professional Obligations
                          </div>
                          <div class="mt-3">
                            1.1 Standards of Conduct:
                            <br /> Mentors shall adhere to the highest standards
                            of professional conduct, ensuring all services are
                            delivered with due care, skill, and diligence, in
                            compliance with applicable legal and regulatory
                            requirements.
                            <br />
                            <br /> 1.2 Accuracy and Representation:
                            <br /> Mentors warrant that all representations made
                            in their profiles, including qualifications,
                            expertise, and services offered, are truthful,
                            accurate, and not misleading. Any misrepresentation
                            shall constitute grounds for immediate termination
                            of the mentor s account.
                            <br />
                            <br /> 1.3 Compliance with Platform Directives:
                            <br /> Mentors are obliged to strictly comply with
                            all SkillSquad policies, procedures, and guidelines.
                            Non-compliance may result in corrective measures,
                            including but not limited to account suspension,
                            termination, or legal action.
                          </div>

                          <div
                            class="mt-3"
                            style={{
                              fontSize: 20,
                              fontWeight: 700,
                              lineHeight: 2,
                            }}
                          >
                            2. Service Execution
                          </div>
                          <div class="mt-3">
                            2.1 Standard of Service:
                            <br />
                            Mentors shall execute all services with the highest
                            level of professionalism, ensuring punctuality,
                            preparation, and adherence to the agreed scope of
                            work.
                            <br />
                            <br />
                            2.2 Expertise Limitation:
                            <br />
                            Mentors shall not provide services outside their
                            stated areas of expertise. Any deviation from this
                            shall be deemed a material breach of these terms.
                            <br />
                            <br />
                            2.3 Communication Protocol:
                            <br />
                            Mentors must ensure clear, timely, and effective
                            communication with users, including any necessary
                            adjustments to scheduled sessions, which must be
                            communicated promptly.
                          </div>

                          <div
                            class="mt-3"
                            style={{
                              fontSize: 20,
                              fontWeight: 700,
                              lineHeight: 2,
                            }}
                          >
                            3. Confidentiality and Data Protection
                          </div>
                          <div class="mt-3">
                            3.1 Confidential Handling of Information:
                            <br />
                            Mentors shall maintain strict confidentiality of all
                            user information and session content, disclosing
                            such information only as required by law or with the
                            user s explicit consent.
                            <br />
                            <br />
                            3.2 Compliance with Data Protection Laws:
                            <br />
                            Mentors must ensure that any user data processed or
                            stored is handled in accordance with applicable data
                            protection legislation and SkillSquad s privacy
                            policy.
                          </div>

                          <div
                            class="mt-3"
                            style={{
                              fontSize: 20,
                              fontWeight: 700,
                              lineHeight: 2,
                            }}
                          >
                            4. Payment and Financial Conduct
                          </div>
                          <div class="mt-3">
                            4.1 Fee Adherence: <br />
                            Mentors must adhere strictly to the fee structures
                            agreed upon with users and must not alter fees
                            without prior written consent from the user.
                            <br />
                            <br /> 4.2 Prohibition on Direct Payments: <br />
                            All financial transactions must be conducted through
                            SkillSquad s payment system. Direct payment
                            solicitation from users outside the platform is
                            prohibited and shall result in immediate account
                            termination.
                          </div>

                          <div
                            class="mt-3"
                            style={{
                              fontSize: 20,
                              fontWeight: 700,
                              lineHeight: 2,
                            }}
                          >
                            5. Prohibited Conduct
                          </div>
                          <div class="mt-3">
                            5.1 Avoidance of Conflicts of Interest:
                            <br /> Mentors must avoid any situation that could
                            give rise to a conflict of interest. Engaging in
                            off-platform business dealings with users is
                            strictly prohibited.
                            <br />
                            <br />
                            5.2 Restrictions on Solicitation:
                            <br /> Mentors are prohibited from using the
                            SkillSquad platform to solicit users for services or
                            products not offered through the platform, except as
                            expressly authorized.
                            <br />
                            <br /> 5.3 Zero Tolerance for Misconduct:
                            <br /> Any form of harassment, discrimination, or
                            unethical behavior will result in immediate and
                            severe disciplinary action, up to and including
                            account termination.
                          </div>

                          <div
                            class="mt-3"
                            style={{
                              fontSize: 20,
                              fontWeight: 700,
                              lineHeight: 2,
                            }}
                          >
                            6. Accountability and Indemnification
                          </div>
                          <div class="mt-3">
                            6.1 Performance Monitoring:
                            <br />
                            SkillSquad reserves the right to monitor and
                            evaluate mentor performance, including user
                            feedback, to ensure compliance with these terms. Any
                            breach may lead to immediate suspension or
                            termination.
                            <br />
                            <br />
                            6.2 Indemnification:
                            <br />
                            Mentors agree to indemnify and hold harmless
                            SkillSquad and its affiliates against any and all
                            claims, liabilities, losses, and expenses (including
                            legal fees) arising out of or in connection with
                            their provision of services, including any breach of
                            these terms or applicable law.
                          </div>

                          <div
                            class="mt-3"
                            style={{
                              fontSize: 20,
                              fontWeight: 700,
                              lineHeight: 2,
                            }}
                          >
                            Intellectual Property Rights
                            <br />
                            1. Ownership of Content
                          </div>
                          <div class="mt-3">
                            1.1 Platform Content:
                            <br />
                            All content available on the SkillSquad platform,
                            including but not limited to text, graphics, logos,
                            images, videos, course materials, software, and
                            trademarks (collectively, "Platform Content"), is
                            the exclusive property of SkillSquad and/or its
                            licensors. Such content is protected under
                            applicable intellectual property laws, including
                            copyright, trademark, and patent laws.
                            <br />
                            <br />
                            1.2 User-Generated Content:
                            <br />
                            Users retain ownership of any content they create or
                            submit to the platform, including but not limited to
                            reviews, feedback, and any materials uploaded in the
                            course of utilizing SkillSquad services ("User
                            Content"). By submitting User Content, users grant
                            SkillSquad a non-exclusive, royalty-free, worldwide,
                            perpetual, irrevocable, and sublicensable license to
                            use, reproduce, modify, distribute, and display such
                            content for the purposes of operating, marketing,
                            and improving the platform.
                          </div>

                          <div
                            class="mt-3"
                            style={{
                              fontSize: 20,
                              fontWeight: 700,
                              lineHeight: 2,
                            }}
                          >
                            2. License Restrictions
                          </div>
                          <div class="mt-3">
                            2.1 Non-Commercial Use: <br />
                            Users are granted a limited, non-exclusive,
                            non-transferable license to access and use the
                            Platform Content for personal, non-commercial
                            purposes. This license is subject to the compliance
                            with these terms and does not include the right to
                            sublicense, sell, or exploit the Platform Content
                            for any commercial purpose.
                            <br /> <br />
                            2.2 Prohibition of Unauthorized Use: <br />
                            Any unauthorized use of Platform Content, including
                            but not limited to the reproduction, modification,
                            distribution, or public display of such content, is
                            strictly prohibited and constitutes a violation of
                            SkillSquad's intellectual property rights.
                            SkillSquad reserves the right to take legal action
                            to enforce its rights and seek damages for any
                            infringement.
                          </div>

                          <div
                            class="mt-3"
                            style={{
                              fontSize: 20,
                              fontWeight: 700,
                              lineHeight: 2,
                            }}
                          >
                            3. Infringement Claims
                          </div>
                          <div class="mt-3">
                            3.1 Reporting Infringement:
                            <br />
                            SkillSquad respects the intellectual property rights
                            of others and expects users to do the same. If you
                            believe that your intellectual property rights have
                            been infringed upon by any content on the platform,
                            you must promptly notify SkillSquad by providing a
                            detailed written notice including the nature of the
                            alleged infringement, your ownership of the rights,
                            and any supporting documentation. <br />
                            <br />
                            3.2 Response to Claims: <br />
                            Upon receipt of a valid infringement notice,
                            SkillSquad will investigate the claim and may, in
                            its sole discretion, remove or disable access to the
                            allegedly infringing content. SkillSquad reserves
                            the right to terminate the accounts of repeat
                            infringers without notice.
                            <br />
                            <br />
                          </div>

                          <div
                            class="mt-3"
                            style={{
                              fontSize: 20,
                              fontWeight: 700,
                              lineHeight: 2,
                            }}
                          >
                            4. Intellectual Property of Third Parties
                          </div>
                          <div class="mt-3">
                            4.1 Third-Party Content:
                            <br /> The platform may contain content owned by
                            third parties, including but not limited to
                            third-party trademarks, logos, and course materials
                            ("Third-Party Content"). Use of such Third-Party
                            Content is subject to the terms and conditions of
                            the respective owners. Users acknowledge that they
                            have no rights to such Third-Party Content beyond
                            those granted by the third-party owner.
                            <br />
                            <br /> 4.2 Liability for Use: <br />
                            SkillSquad assumes no responsibility for the
                            legality or appropriateness of any Third-Party
                            Content accessible through the platform. Users
                            access and use such Third-Party Content at their own
                            risk and agree to indemnify and hold harmless
                            SkillSquad from any claims arising from such use.
                          </div>

                          <div
                            class="mt-3"
                            style={{
                              fontSize: 20,
                              fontWeight: 700,
                              lineHeight: 2,
                            }}
                          >
                            Data Privacy and Security
                          </div>
                          <div class="mt-3">
                            1. Data Collection and Use 1.1 Personal Data:
                            SkillSquad collects, processes, and stores personal
                            data provided by users in accordance with applicable
                            data protection laws, including but not limited to
                            the Indian Personal Data Protection Act (if
                            applicable) and the General Data Protection
                            Regulation (GDPR) for users within the European
                            Union. Users expressly consent to the collection and
                            processing of their personal data for purposes
                            outlined in SkillSquad s Privacy Policy. <br />{" "}
                            <br /> 1.2 Purpose Limitation: The personal data
                            collected shall be used strictly for purposes
                            consistent with providing and enhancing the services
                            offered by SkillSquad, including but not limited to
                            user account management, service delivery, customer
                            support, and compliance with legal obligations.{" "}
                            <br /> <br /> 1.3 Data Retention: SkillSquad shall
                            retain personal data only for as long as necessary
                            to fulfill the purposes for which it was collected,
                            or as required by law. Upon the expiration of the
                            retention period, such data shall be securely
                            deleted or anonymized.
                            <br /> <br />
                            2. Data Security Measures 2.1 Security Protocols:
                            SkillSquad implements robust security measures,
                            including but not limited to encryption, firewalls,
                            secure socket layers (SSL), and regular security
                            audits, to safeguard personal data against
                            unauthorized access, disclosure, alteration, or
                            destruction. Users acknowledge and accept the
                            inherent risks associated with data transmission
                            over the internet. <br /> <br /> 2.2 Access Control:
                            Access to personal data is strictly limited to
                            authorized personnel who require such access to
                            perform their job functions. SkillSquad ensures that
                            such personnel are bound by confidentiality
                            obligations and that access rights are regularly
                            reviewed and updated in line with security best
                            practices. <br /> <br />
                            2.3 Breach Notification: In the event of a data
                            breach that compromises personal data, SkillSquad
                            shall promptly notify affected users and relevant
                            authorities, as required by applicable law. Such
                            notification shall include the nature of the breach,
                            the potential impact, and the measures being taken
                            to mitigate harm. <br /> <br /> 3. User Rights 3.1
                            Access and Rectification: Users have the right to
                            access their personal data held by SkillSquad and to
                            request the rectification of any inaccuracies
                            therein. Requests for access or correction shall be
                            made in accordance with the procedures outlined in
                            SkillSquad s Privacy Policy. <br /> <br />
                            3.2 Data Portability and Erasure: Subject to legal
                            constraints, users may request the portability of
                            their data to another service provider or the
                            erasure of their data from SkillSquad s systems.
                            Such requests will be honored in compliance with
                            applicable data protection laws, and any data
                            required for ongoing legal or contractual
                            obligations may be exempt from such erasure. <br />{" "}
                            <br /> 4. International Data Transfers 4.1
                            Cross-Border Data Transfer: Personal data may be
                            transferred to and processed in countries outside
                            the user's jurisdiction, including countries that
                            may not provide the same level of data protection as
                            the user's home country. By using SkillSquad s
                            services, users consent to such cross-border data
                            transfers, subject to appropriate safeguards as
                            required by law. <br /> <br /> 4.2 Data Transfer
                            Agreements: SkillSquad ensures that any cross-border
                            data transfers are conducted under legally
                            recognized mechanisms, such as standard contractual
                            clauses, to provide adequate protection for personal
                            data.
                          </div>

                          <div
                            class="mt-3"
                            style={{
                              fontSize: 20,
                              fontWeight: 700,
                              lineHeight: 2,
                            }}
                          >
                            Cookies Policy and Security
                          </div>
                          <div class="mt-3">
                            1. Use of Cookies
                            <br /> 1.1 Cookies Overview: SkillSquad utilizes
                            cookies, web beacons, and similar tracking
                            technologies ("Cookies") to enhance user experience,
                            personalize content, and analyze platform traffic.
                            By accessing or using the platform, users consent to
                            the deployment of Cookies in accordance with this
                            policy. <br />
                            1.2 Types of Cookies: SkillSquad may employ various
                            types of Cookies, including but not limited to:
                            Essential Cookies: Required for the operation of the
                            platform and enabling users to navigate and use its
                            features. Performance Cookies: Collect anonymous
                            data on how users interact with the platform, aiding
                            in performance improvement and usability
                            enhancements. Functional Cookies: Allow the platform
                            to remember user preferences and provide a more
                            personalized experience. Advertising Cookies: Used
                            to deliver relevant ads to users and track ad
                            campaign performance.
                            <br /> 1.3 Third-Party Cookies: SkillSquad may
                            permit third-party service providers to place
                            Cookies on the platform for purposes such as
                            analytics, advertising, and social media
                            integration. These third parties may have access to
                            certain information collected via their Cookies,
                            subject to their respective privacy policies. <br />
                            2. Managing Cookies
                            <br /> 2.1 User Control: Users retain the right to
                            manage, block, or delete Cookies through their
                            browser settings. However, disabling Cookies may
                            impair the functionality and performance of the
                            platform, potentially restricting access to certain
                            features or services.
                            <br /> 2.2 Opt-Out Mechanisms: Users may opt-out of
                            specific types of Cookies, such as advertising
                            Cookies, by utilizing industry-standard opt-out
                            tools or adjusting their browser settings.
                            SkillSquad provides information on how to opt out of
                            Cookies on its platform, and users are encouraged to
                            review these options.
                            <br /> 3. Cookies Security
                            <br /> 3.1 Security Measures: SkillSquad implements
                            stringent security measures to protect the data
                            collected via Cookies from unauthorized access,
                            alteration, or disclosure. This includes, but is not
                            limited to, the use of encryption, secure storage,
                            and regular security audits.
                            <br /> 3.2 User Responsibility: Users are advised to
                            ensure that their devices are secure and to
                            regularly clear Cookies and browsing data to
                            maintain privacy and security. SkillSquad is not
                            liable for any security breaches arising from user
                            negligence in managing Cookies on their devices.
                            <br /> 4. Data Collected via Cookies
                            <br /> 4.1 Scope of Data: The data collected via
                            Cookies may include, without limitation, IP
                            addresses, browser types, operating systems,
                            browsing behaviors, and user preferences. This data
                            is used to enhance platform functionality, improve
                            user experience, and tailor content and
                            advertisements.
                            <br /> 4.2 Data Retention: SkillSquad retains data
                            collected via Cookies for as long as necessary to
                            fulfill the purposes outlined in this policy,
                            subject to applicable legal and regulatory
                            requirements. Upon expiration of the retention
                            period, the data will be securely deleted or
                            anonymized.
                            <br /> 5. Updates to Cookies Policy
                            <br /> 5.1 Policy Amendments: SkillSquad reserves
                            the right to modify this Cookies Policy at any time
                            to reflect changes in technology, legal
                            requirements, or business practices. Users will be
                            notified of any material changes to the policy, and
                            continued use of the platform constitutes acceptance
                            of such changes.
                          </div>

                          <div
                            class="mt-3"
                            style={{
                              fontSize: 20,
                              fontWeight: 700,
                              lineHeight: 2,
                            }}
                          >
                            Content Liability
                          </div>
                          <div class="mt-3">
                            1. Grant of License
                            <br /> 1.1 Limited License: Subject to compliance
                            with these Terms and Conditions, SkillSquad grants
                            users a non-exclusive, non-transferable, revocable,
                            and limited license to access and utilize the
                            platform and its content strictly for personal,
                            non-commercial use. This license is conditioned upon
                            the user's adherence to all applicable laws and the
                            terms herein. 1.2 Scope of Use: Users are authorized
                            to view, download, and print materials available on
                            the platform for their personal, educational use
                            only, provided that all copyright and proprietary
                            notices remain intact. Any use beyond this limited
                            scope requires express written permission from
                            SkillSquad. <br /> 2. Restrictions on Use <br /> 2.1
                            Prohibited Activities: Users are expressly
                            prohibited from: Modifying, distributing,
                            transmitting, performing, reproducing, publishing,
                            licensing, creating derivative works from,
                            transferring, or selling any information, software,
                            products, or services obtained from the platform.
                            Engaging in any activity that disrupts or interferes
                            with the platform s functionality or security,
                            including but not limited to, hacking, data mining,
                            or the deployment of malicious code. Using the
                            platform for any unlawful or unauthorized purpose,
                            including the infringement of intellectual property
                            rights or privacy rights of others. <br /> 2.2 No
                            Commercial Exploitation: Users may not use the
                            platform or its content for commercial purposes,
                            including any form of monetization, advertising, or
                            revenue generation, without the prior written
                            consent of SkillSquad.
                            <br /> 3. User-Generated Content
                            <br /> 3.1 License to SkillSquad: By submitting,
                            posting, or displaying any content on or through the
                            platform, users grant SkillSquad a worldwide,
                            royalty-free, perpetual, irrevocable,
                            sub-licensable, and transferable license to use,
                            reproduce, modify, adapt, publish, distribute,
                            translate, and display such content in any media.
                            Users represent and warrant that they possess all
                            necessary rights to grant this license.
                            <br /> 3.2 Content Standards: All user-generated
                            content must comply with applicable laws and
                            platform guidelines. SkillSquad reserves the right
                            to remove or disable access to any content that
                            violates these terms or is deemed inappropriate,
                            without prior notice.
                            <br /> 4. Termination of License <br /> 4.1 License
                            Revocation: SkillSquad reserves the right to
                            terminate or suspend the user's license to access
                            and use the platform at any time, with or without
                            cause, including for any breach of these Terms and
                            Conditions. Upon termination, the user must
                            immediately cease all use of the platform and
                            destroy any downloaded or printed materials obtained
                            from it. <br /> 4.2 Post-Termination Obligations:
                            Termination of the license shall not affect any
                            rights or obligations that have accrued prior to
                            termination. All provisions of these Terms and
                            Conditions that, by their nature, should survive
                            termination shall remain in effect, including, but
                            not limited to, intellectual property rights,
                            indemnities, and limitation of liability.
                          </div>

                          <div
                            class="mt-3"
                            style={{
                              fontSize: 20,
                              fontWeight: 700,
                              lineHeight: 2,
                            }}
                          >
                            Hyperlinking to Our Content
                          </div>
                          <div class="mt-3">
                            1. Permission to Link
                            <br />
                            1.1 Limited License: Subject to these Terms and
                            Conditions, SkillSquad grants a limited, revocable,
                            non-exclusive, non-transferable license to link to
                            its content, provided that such links comply with
                            the following conditions: <br />
                            (a) the link must not imply or suggest any
                            endorsement, affiliation, or sponsorship by
                            SkillSquad unless explicitly authorized;
                            <br /> (b) the link must direct users to the
                            SkillSquad homepage or other designated landing
                            pages and not to internal sub-pages; and
                            <br /> (c) the link must not utilize any SkillSquad
                            trademarks or logos without prior written
                            permission.
                            <br /> 1.2 Prohibited Uses: You shall not create
                            links to any SkillSquad content that <br />
                            (a) frames, mirrors, or otherwise incorporates the
                            content into another website or application; <br />
                            (b) misrepresents your affiliation with SkillSquad
                            or otherwise presents misleading or deceptive
                            information; <br />
                            (c) is used for commercial purposes not expressly
                            authorized by SkillSquad; or <br />
                            (d) promotes illegal activities or violates any
                            applicable laws or regulations.
                            <br /> 2. Request for Permission <br />
                            2.1 Formal Request: If you wish to use SkillSquad
                            content in a manner not expressly permitted under
                            these Terms and Conditions, you must obtain prior
                            written consent from SkillSquad. Requests for
                            permission must be submitted to SkillSquad s
                            designated contact point, and SkillSquad reserves
                            the right to grant or deny such requests at its sole
                            discretion.
                            <br /> 2.2 Review and Revoke: SkillSquad reserves
                            the right to review, modify, or revoke any
                            permissions granted to link to its content.
                            SkillSquad may request the removal of any links that
                            do not comply with these Terms and Conditions or
                            that may otherwise harm SkillSquad s reputation or
                            interests.
                            <br /> 3. Liability and Indemnification
                            <br /> 3.1 No Endorsement: SkillSquad shall not be
                            responsible for any content or activities on the
                            website or platform linking to SkillSquad content.
                            The presence of a link to SkillSquad content does
                            not imply endorsement or verification of the linked
                            website or its content.
                            <br />
                            3.2 Indemnification: You agree to indemnify, defend,
                            and hold harmless SkillSquad, its affiliates,
                            officers, directors, employees, and agents from and
                            against any claims, liabilities, damages, losses,
                            costs, or expenses arising out of or related to your
                            linking to SkillSquad content, including but not
                            limited to claims related to misrepresentation,
                            unauthorized use, or infringement of rights.
                            <br /> 4. Termination of Linking Rights <br />
                            4.1 Termination for Cause: SkillSquad reserves the
                            right to terminate or suspend linking rights at any
                            time if SkillSquad determines, in its sole
                            discretion, that your linking activities are in
                            violation of these Terms and Conditions or that such
                            activities may cause harm to SkillSquad or its
                            users.
                            <br /> 4.2 Compliance with Terms: Upon termination
                            of linking rights, you must promptly remove all
                            links to SkillSquad content and cease all use of
                            SkillSquad trademarks and other proprietary
                            information.
                          </div>

                          <div
                            class="mt-3"
                            style={{
                              fontSize: 20,
                              fontWeight: 700,
                              lineHeight: 2,
                            }}
                          >
                            Third-Party Services and Integrations
                          </div>
                          <div class="mt-3">
                            1. Integration with Third-Party Services
                            <br /> 1.1 External Links and Integrations:
                            SkillSquad may incorporate or facilitate access to
                            third-party services, tools, or platforms
                            (collectively referred to as  Third-Party Services )
                            to enhance or support its functionalities. These
                            integrations are provided solely for convenience and
                            do not imply endorsement or affiliation with the
                            third-party providers.
                            <br />
                            <br /> 1.2 Usage and Access: By utilizing
                            third-party integrations, you acknowledge and agree
                            that your use of such services is governed by the
                            respective terms and conditions and privacy policies
                            of the third-party providers. SkillSquad does not
                            control or assume any responsibility for the
                            third-party services' content, operations, or
                            practices.
                            <br />
                            <br /> 2. Disclaimers and Limitation of Liability{" "}
                            <br />
                            2.1 No Warranty: SkillSquad makes no
                            representations, warranties, or guarantees regarding
                            the availability, accuracy, or reliability of any
                            Third-Party Services. All interactions and
                            transactions with third-party providers are
                            conducted at your own risk and discretion.
                            <br />
                            <br /> 2.2 Limitation of Liability: SkillSquad shall
                            not be liable for any direct, indirect, incidental,
                            consequential, or punitive damages arising from or
                            related to your use of or interaction with
                            Third-Party Services, including but not limited to
                            damages arising from errors, omissions, or
                            interruptions in service.
                            <br /> 3. Third-Party Content
                            <br /> 3.1 Content Responsibility: SkillSquad does
                            not endorse or assume any responsibility for the
                            content provided by third-party services. All
                            third-party content accessed through SkillSquad is
                            the sole responsibility of the third-party provider,
                            and any reliance on such content is at your own
                            risk. <br />
                            <br />
                            3.2 No Control: SkillSquad does not control, verify,
                            or monitor the accuracy, legality, or
                            appropriateness of third-party content and is not
                            liable for any damages or losses resulting from the
                            use of or reliance on such content.
                            <br />
                            <br /> 4. Data Sharing and Privacy
                            <br /> 4.1 Data Collection: Certain Third-Party
                            Services may collect, process, or store personal
                            data. By using these services, you consent to the
                            collection and processing of your data by these
                            third parties in accordance with their respective
                            privacy policies. <br />
                            <br />
                            4.2 No Responsibility: SkillSquad is not responsible
                            for the data handling practices of third-party
                            providers. You should review the privacy policies of
                            any third-party services you engage with to
                            understand their data collection and use practices.{" "}
                            <br />
                            <br />
                            5. Termination and Modification <br />
                            5.1 Service Changes: SkillSquad reserves the right
                            to modify, suspend, or terminate access to any
                            Third-Party Services integrated into the platform at
                            its sole discretion, without prior notice or
                            liability. <br />
                            <br />
                            5.2 Discontinuation of Integration: In the event
                            that a third-party service is discontinued or its
                            integration with SkillSquad is terminated,
                            SkillSquad will not be liable for any inconvenience,
                            loss, or disruption caused by such discontinuation.
                          </div>

                          <div
                            class="mt-3"
                            style={{
                              fontSize: 20,
                              fontWeight: 700,
                              lineHeight: 2,
                            }}
                          >
                            Subscription Services and Recurring Payments
                          </div>
                          <div class="mt-3">
                            1. Subscription Services <br />
                            1.1 Subscription Offerings: SkillSquad may provide
                            certain services on a subscription basis, whereby
                            users commit to periodic payments in exchange for
                            access to specific features or content
                            ( Subscription Services ). The terms, including the
                            scope, benefits, and pricing of such subscriptions,
                            will be detailed at the time of subscription. <br />
                            <br />
                            1.2 Subscription Agreement: By subscribing to a
                            Subscription Service, you agree to adhere to the
                            terms specified for that subscription, including
                            payment obligations, renewal terms, and cancellation
                            policies.
                            <br />
                            <br /> 2. Recurring Payments <br />
                            2.1 Billing Cycle: Subscriptions are billed on a
                            recurring basis as defined in the subscription terms
                            (e.g., monthly, annually). The applicable billing
                            cycle and payment amount will be clearly
                            communicated prior to the commencement of the
                            subscription. <br />
                            <br />
                            2.2 Automatic Renewal: Unless terminated by the user
                            in accordance with the subscription terms,
                            subscriptions will automatically renew at the end of
                            each billing cycle. SkillSquad will charge the
                            payment method on file for the renewal fee. Users
                            will receive notification of renewal and any changes
                            to the subscription terms.
                            <br />
                            <br />
                            2.3 Payment Authorization: By subscribing, you
                            authorize SkillSquad to process recurring payments
                            using the payment method provided at the time of
                            subscription. You are responsible for ensuring that
                            your payment information is accurate and up-to-date.
                            <br />
                            <br />
                            3. Cancellation and Termination <br />
                            3.1 User-Initiated Cancellation: You may cancel your
                            subscription at any time through your account
                            settings. Cancellation will take effect at the end
                            of the current billing cycle, and you will retain
                            access to the Subscription Services until the end of
                            the paid period. <br />
                            <br />
                            3.2 Refunds for Cancellation: Refunds for partial
                            periods of subscription are generally not provided.
                            SkillSquad will not issue refunds for any unused
                            portion of the subscription term upon cancellation.
                            <br />
                            <br /> 3.3 Termination by SkillSquad: SkillSquad
                            reserves the right to terminate or suspend a
                            subscription in the event of a violation of these
                            Terms and Conditions or for any reason deemed
                            appropriate. Termination by SkillSquad will result
                            in immediate cessation of access to the Subscription
                            Services, and no refund will be issued for any
                            remaining period.
                            <br />
                            <br /> 4. Payment Disputes and Issues
                            <br /> 4.1 Disputed Charges: If you dispute a charge
                            or believe there has been an error in billing, you
                            must notify SkillSquad within 30 days of the charge.
                            SkillSquad will investigate and, if warranted,
                            correct any billing errors.
                            <br />
                            <br /> 4.2 Chargebacks: Initiating a chargeback with
                            your payment provider without first resolving the
                            issue with SkillSquad may result in account
                            suspension or termination. SkillSquad reserves the
                            right to contest any chargebacks and seek recovery
                            of the disputed amount. <br />
                            <br />
                            5. Taxation
                            <br /> 5.1 Applicable Taxes: Subscription fees are
                            exclusive of any applicable taxes, duties, or
                            governmental charges, which may be added to your
                            subscription fee as required by law. You are
                            responsible for paying all such taxes or charges.
                          </div>

                          <div
                            class="mt-3"
                            style={{
                              fontSize: 20,
                              fontWeight: 700,
                              lineHeight: 2,
                            }}
                          >
                            Advertising and Sponsored Content
                          </div>
                          <div class="mt-3">
                            1. Advertising and Promotion
                            <br /> 1.1 Third-Party Advertisements: SkillSquad
                            may display advertisements or promotional content
                            from third parties on the Platform. Such content is
                            not endorsed or controlled by SkillSquad. The
                            inclusion of third-party advertisements does not
                            imply any affiliation, endorsement, or guarantee of
                            the products or services advertised.
                            <br />
                            <br /> 1.2 Advertiser Agreements: Advertisers or
                            sponsors wishing to place ads or promotional content
                            on SkillSquad must enter into a formal agreement
                            with SkillSquad. All such agreements are subject to
                            SkillSquad s standard advertising policies and
                            terms.
                            <br />
                            <br /> 2. Sponsored Content
                            <br />
                            2.1 Sponsored Content Disclosure: Any content
                            designated as sponsored or paid-for content will be
                            clearly identified as such. SkillSquad ensures
                            transparency by distinguishing sponsored content
                            from regular content and will clearly label any
                            content that has been paid for by sponsors.
                            <br />
                            <br /> 2.2 Content Integrity: SkillSquad retains
                            editorial control over the placement of sponsored
                            content. Despite the sponsorship, SkillSquad ensures
                            that the content adheres to its quality standards
                            and does not mislead or deceive users. Sponsored
                            content must comply with applicable laws and
                            regulations governing advertising and consumer
                            protection. <br />
                            <br />
                            3. User Interaction with Advertising <br />
                            3.1 No Endorsement: Interaction with advertisements
                            or sponsored content does not constitute endorsement
                            or recommendation by SkillSquad. Users are advised
                            to conduct their own due diligence before engaging
                            with third-party products or services.
                            <br />
                            <br /> 3.2 Responsibility for Transactions: Any
                            transactions or interactions with third-party
                            advertisers are conducted at the user's own risk.
                            SkillSquad is not liable for any issues arising from
                            such interactions or transactions.
                            <br />
                            <br /> 4. Prohibition of Misleading Practices
                            <br />
                            4.1 Accuracy of Advertising: Advertisers and
                            sponsors must ensure that all advertisements and
                            sponsored content are accurate, not misleading, and
                            comply with all applicable legal requirements.
                            SkillSquad reserves the right to remove or refuse
                            any advertising or sponsored content that violates
                            these requirements.
                            <br />
                            <br /> 4.2 Content Review: SkillSquad may review and
                            monitor all advertisements and sponsored content to
                            ensure compliance with its policies. SkillSquad
                            reserves the right to remove any content that, in
                            its sole discretion, is deemed inappropriate or
                            non-compliant.
                            <br />
                            <br /> 5. Modifications and Termination <br />
                            5.1 Modification of Content: SkillSquad reserves the
                            right to modify, reject, or remove any advertising
                            or sponsored content at its discretion. Advertisers
                            or sponsors may be required to make changes to
                            comply with SkillSquad s advertising policies.
                            <br />
                            <br /> 5.2 Termination of Advertising Agreements:
                            SkillSquad reserves the right to terminate any
                            advertising or sponsorship agreement for breach of
                            terms or for any reason deemed appropriate. In such
                            cases, no refunds will be provided for any pre-paid
                            advertising services.
                          </div>

                          <div
                            class="mt-3"
                            style={{
                              fontSize: 20,
                              fontWeight: 700,
                              lineHeight: 2,
                            }}
                          >
                            Affiliate and Referral Programs
                          </div>
                          <div class="mt-3">
                            1. Program Participation <br />
                            1.1 Eligibility and Enrollment: To participate in
                            SkillSquad s Affiliate and Referral Programs,
                            individuals or entities must apply through
                            SkillSquad s designated application process.
                            Participation is contingent upon approval by
                            SkillSquad, which reserves the right to accept or
                            reject any application at its sole discretion.
                            <br />
                            <br /> 1.2 Affiliates and Referrers: Affiliates and
                            referrers are those who have been authorized by
                            SkillSquad to promote its services and earn
                            commissions or rewards based on successful referrals
                            or sales. Affiliates and referrers must comply with
                            all terms and conditions outlined in their
                            respective agreements with SkillSquad.
                            <br />
                            <br /> 2. Commission Structure and Payments
                            <br /> 2.1 Commission Terms: Commissions or rewards
                            are calculated based on the performance metrics
                            defined in the affiliate or referral agreement. The
                            commission structure, including rates and payment
                            terms, will be outlined in the respective agreements
                            and is subject to change at SkillSquad s discretion.
                            <br />
                            <br /> 2.2 Payment of Commissions: Commissions will
                            be paid to affiliates and referrers according to the
                            schedule specified in their agreements. SkillSquad
                            is not responsible for any errors in payment if the
                            affiliate or referrer fails to provide accurate and
                            up-to-date payment information.
                            <br />
                            <br /> 3. Referral Tracking and Reporting <br />
                            3.1 Tracking Mechanisms: SkillSquad employs tracking
                            mechanisms to monitor referrals and sales attributed
                            to affiliates and referrers. Affiliates and
                            referrers must use the provided tracking links and
                            tools to ensure proper attribution of their
                            activities.
                            <br />
                            <br /> 3.2 Reporting Discrepancies: Affiliates and
                            referrers are responsible for reviewing their
                            performance reports regularly. Any discrepancies or
                            issues with tracking must be reported to SkillSquad
                            within 30 days of occurrence. SkillSquad will review
                            and address such discrepancies at its sole
                            discretion.
                            <br />
                            <br /> 4. Obligations and Restrictions <br />
                            4.1 Compliance with Laws: Affiliates and referrers
                            must comply with all applicable laws, regulations,
                            and ethical standards in their promotional
                            activities. This includes, but is not limited to,
                            adherence to advertising standards and anti-spam
                            laws.
                            <br />
                            <br /> 4.2 Prohibited Practices: Affiliates and
                            referrers are prohibited from engaging in fraudulent
                            activities, misrepresenting SkillSquad s services,
                            or employing unethical practices to generate
                            referrals or sales. Violations may result in
                            immediate termination from the program and
                            forfeiture of any earned commissions.
                            <br />
                            <br /> 5. Program Modifications and Termination
                            <br /> 5.1 Modifications: SkillSquad reserves the
                            right to modify the terms, structure, or operation
                            of its Affiliate and Referral Programs at any time.
                            Any changes will be communicated to affiliates and
                            referrers, who must comply with the revised terms.
                            <br />
                            <br /> 5.2 Termination: SkillSquad may terminate an
                            affiliate or referrer s participation in the program
                            for breach of terms, inactivity, or at its
                            discretion. Upon termination, affiliates and
                            referrers will cease to accrue further commissions
                            or rewards, and any pending payments will be settled
                            according to the terms of the agreement.
                            <br />
                            <br /> 6. Liability and Indemnification <br />
                            6.1 Liability: SkillSquad is not liable for any
                            losses, damages, or claims arising from
                            participation in the Affiliate and Referral
                            Programs, including but not limited to, errors in
                            commission payments or disputes between affiliates
                            and third parties.
                            <br />
                            <br /> 6.2 Indemnification: Affiliates and referrers
                            agree to indemnify and hold SkillSquad harmless from
                            any claims, damages, or liabilities arising from
                            their participation in the programs, including
                            breaches of these terms or violations of applicable
                            laws.
                          </div>

                          <div
                            class="mt-3"
                            style={{
                              fontSize: 20,
                              fontWeight: 700,
                              lineHeight: 2,
                            }}
                          >
                            Compliance with Local and International Laws
                          </div>
                          <div class="mt-3">
                            1. Legal Compliance
                            <br /> 1.1 General Compliance: By using SkillSquad s
                            services, you agree to comply with all applicable
                            local, national, and international laws,
                            regulations, and ordinances relevant to your use of
                            the platform. This includes, but is not limited to,
                            compliance with laws pertaining to e-commerce, data
                            protection, intellectual property, and consumer
                            rights.
                            <br />
                            <br /> 1.2 Specific Jurisdictions: SkillSquad
                            operates globally and may be subject to regulatory
                            requirements in different jurisdictions. Users are
                            responsible for ensuring that their use of the
                            platform adheres to the legal and regulatory
                            frameworks of their respective locations.
                            <br />
                            <br /> 2. Data Protection and Privacy
                            <br /> 2.1 Jurisdictional Compliance: SkillSquad
                            commits to adhering to data protection laws
                            applicable in jurisdictions where it operates. This
                            includes compliance with the Indian Personal Data
                            Protection Act, the General Data Protection
                            Regulation (GDPR) for users in the European Union,
                            and relevant U.S. data privacy laws.
                            <br />
                            <br />
                            2.2 Data Transfers: In the course of providing
                            services, SkillSquad may transfer data across
                            borders. Such transfers will comply with applicable
                            data protection regulations, including mechanisms
                            for cross-border data transfers as required by law.
                            <br />
                            <br />
                            3. Export Control and Trade Compliance <br />
                            3.1 Export Restrictions: You agree to comply with
                            all applicable export control laws and regulations
                            governing the transfer of data, technology, and
                            services. This includes restrictions on the export
                            of certain technologies and services to embargoed or
                            sanctioned countries. <br />
                            <br />
                            3.2 Sanctions and Trade Restrictions: SkillSquad
                            will not knowingly engage in transactions or provide
                            services to individuals or entities subject to trade
                            restrictions or sanctions imposed by relevant
                            authorities.
                            <br />
                            <br /> 4. Regulatory Compliance for Services
                            <br /> 4.1 Service-Specific Regulations: Certain
                            services offered through SkillSquad may be subject
                            to industry-specific regulations, including but not
                            limited to, financial, educational, and
                            healthcare-related laws. Users are responsible for
                            ensuring that their use of these services complies
                            with applicable regulations. <br />
                            <br />
                            4.2 Expert Services: Experts offering services via
                            SkillSquad are required to adhere to professional
                            standards and legal requirements specific to their
                            field of expertise, including licensing and
                            certification requirements. <br />
                            <br />
                            5. Changes in Legal Requirements
                            <br /> 5.1 Adaptation to Legal Changes: SkillSquad
                            reserves the right to update its practices and Terms
                            and Conditions to reflect changes in applicable laws
                            and regulations. Users will be notified of such
                            changes, and continued use of the platform
                            constitutes acceptance of the updated terms.
                            <br />
                            <br /> 5.2 User Responsibility: It is the
                            responsibility of users to stay informed of and
                            comply with any legal obligations that arise due to
                            changes in local or international laws affecting
                            their use of SkillSquad s services.
                            <br />
                            <br /> 6. Legal Disputes and Enforcement
                            <br /> 6.1 Dispute Resolution: Any disputes arising
                            from the use of SkillSquad s services that involve
                            legal or regulatory compliance issues will be
                            resolved in accordance with the governing laws and
                            dispute resolution procedures specified in these
                            Terms and Conditions.
                            <br />
                            <br /> 6.2 Enforcement: SkillSquad reserves the
                            right to enforce compliance with these Terms and
                            Conditions through legal action if necessary,
                            including but not limited to, seeking remedies for
                            violations of applicable laws.
                          </div>

                          <div
                            class="mt-3"
                            style={{
                              fontSize: 20,
                              fontWeight: 700,
                              lineHeight: 2,
                            }}
                          >
                            Child Protection
                          </div>
                          <div class="mt-3">
                            1. Age Restrictions
                            <br />
                            1.1 Minimum Age Requirement: SkillSquad s services
                            are not intended for individuals under the age of
                            13. By accessing and using the platform, you affirm
                            that you are at least 13 years of age. If you are
                            between the ages of 13 and 18, you must obtain
                            consent from a parent or legal guardian to use
                            SkillSquad s services.
                            <br />
                            <br /> 1.2 Parental Consent: For users under 18
                            years of age, parental or guardian consent is
                            required to create an account and access
                            SkillSquad s services. It is the responsibility of
                            the parent or guardian to oversee the minor s use of
                            the platform and ensure compliance with these Terms
                            and Conditions.
                            <br />
                            <br /> 2. Data Collection and Usage
                            <br /> 2.1 Collection of Minor Data: SkillSquad does
                            not knowingly collect personal data from individuals
                            under the age of 13. If SkillSquad becomes aware
                            that it has inadvertently collected personal data
                            from a minor, the information will be promptly
                            deleted.
                            <br />
                            <br /> 2.2 Parental Access: Parents or guardians may
                            request access to, correction, or deletion of
                            personal data collected from their children by
                            contacting SkillSquad s support team. SkillSquad
                            will take reasonable steps to comply with such
                            requests in accordance with applicable laws.
                            <br />
                            <br /> 3. Content and Interaction <br />
                            3.1 Content Appropriateness: SkillSquad strives to
                            ensure that all content available on the platform is
                            appropriate for its intended audience. Content is
                            reviewed periodically to maintain compliance with
                            relevant child protection laws and standards.
                            <br />
                            <br /> 3.2 User Interaction: Users are prohibited
                            from engaging in any interactions with minors that
                            are inappropriate, harmful, or in violation of
                            applicable laws. SkillSquad reserves the right to
                            monitor interactions and take necessary actions to
                            protect minor users.
                            <br />
                            <br /> 4. Reporting and Enforcement
                            <br /> 4.1 Reporting Violations: Users are
                            encouraged to report any suspected violations of
                            child protection policies to SkillSquad. Reports
                            should be made through the designated channels, and
                            SkillSquad will investigate any allegations of
                            misconduct or policy breaches.
                            <br />
                            <br /> 4.2 Enforcement Actions: SkillSquad reserves
                            the right to take appropriate enforcement actions
                            against users who violate child protection policies.
                            This may include suspension or termination of
                            accounts, reporting to relevant authorities, and
                            legal action as necessary.
                            <br />
                            <br /> 5. Compliance with Legal Requirements
                            <br /> 5.1 Regulatory Compliance: SkillSquad
                            complies with applicable child protection laws,
                            including but not limited to, the Children s Online
                            Privacy Protection Act (COPPA) in the United States
                            and other relevant international regulations.
                            <br />
                            <br /> 5.2 Policy Review: SkillSquad s child
                            protection policies are reviewed periodically to
                            ensure ongoing compliance with legal requirements
                            and industry best practices. Updates to these
                            policies will be communicated to users.
                          </div>

                          <div
                            class="mt-3"
                            style={{
                              fontSize: 20,
                              fontWeight: 700,
                              lineHeight: 2,
                            }}
                          >
                            Taxation
                          </div>
                          <div class="mt-3">
                            1. Sales and Transaction Taxes <br /> 1.1 Applicable
                            Taxes: All fees, charges, and payments processed
                            through SkillSquad are exclusive of applicable sales
                            tax, value-added tax (VAT), or other similar taxes
                            imposed by governmental authorities. Such taxes will
                            be added to the transaction total as required by
                            law.
                            <br />
                            <br /> 1.2 Responsibility for Taxes: Users are
                            responsible for the payment of any taxes, duties, or
                            levies that may be applicable to their transactions
                            on SkillSquad. This includes, but is not limited to,
                            taxes related to the purchase of courses, services,
                            or subscriptions.
                            <br /> 2. Tax Reporting
                            <br /> 2.1 User Tax Compliance: Users are solely
                            responsible for compliance with tax obligations
                            related to their use of SkillSquad s services. This
                            includes, but is not limited to, reporting and
                            remitting any taxes due to relevant tax authorities.
                            <br />
                            <br /> 2.2 Expert Tax Reporting: Experts utilizing
                            the SkillSquad platform for offering services are
                            responsible for declaring and paying all applicable
                            taxes on their earnings. SkillSquad will not
                            withhold or remit taxes on behalf of experts, and
                            experts are responsible for their own tax compliance
                            and reporting. <br />
                            <br />
                            3. Tax Information <br />
                            3.1 Tax Documentation: SkillSquad may provide users
                            with electronic invoices or receipts that include
                            details of applicable taxes for their records. It is
                            the user s responsibility to retain such documents
                            for tax purposes. <br />
                            <br />
                            3.2 Tax Exemptions: Users claiming tax exemption
                            must provide valid documentation to SkillSquad.
                            SkillSquad reserves the right to verify and reject
                            any tax exemption claims if proper documentation is
                            not provided or if the claim does not meet
                            applicable legal requirements.
                            <br />
                            <br /> 4. Tax Disputes
                            <br /> 4.1 Resolution of Tax Disputes: Any disputes
                            regarding taxes charged by SkillSquad should be
                            addressed in writing to SkillSquad s customer
                            support. SkillSquad will review such disputes in
                            accordance with applicable tax laws and make any
                            necessary adjustments to ensure compliance.
                            <br />
                            <br />
                            4.2 Liability for Tax Discrepancies: SkillSquad is
                            not liable for any discrepancies or disputes arising
                            from the calculation or payment of taxes. Users must
                            resolve any tax-related issues directly with the
                            relevant tax authorities.
                          </div>

                          <div
                            class="mt-3"
                            style={{
                              fontSize: 20,
                              fontWeight: 700,
                              lineHeight: 2,
                            }}
                          >
                            User Feedback and Ratings
                          </div>
                          <div class="mt-3">
                            1. Feedback and Rating Submission <br /> 1.1
                            Submission Rights: Users may submit feedback and
                            ratings for courses, services, and experts through
                            the SkillSquad platform. By submitting feedback or
                            ratings, users grant SkillSquad a non-exclusive,
                            perpetual, royalty-free license to use, display, and
                            reproduce such content in connection with
                            SkillSquad's business operations. <br /> <br /> 1.2
                            Content Standards: All feedback and ratings
                            submitted must adhere to SkillSquad s content
                            standards and community guidelines. Users are
                            prohibited from submitting content that is
                            defamatory, obscene, offensive, or otherwise
                            unlawful. SkillSquad reserves the right to review,
                            moderate, and remove any feedback or ratings that
                            violate these standards. <br /> <br />
                            2. Accuracy and Authenticity <br /> 2.1 Truthfulness
                            of Reviews: Users are required to provide honest and
                            accurate feedback and ratings. Submissions must
                            reflect the user s genuine experience and opinions.
                            Fraudulent or misleading feedback, including but not
                            limited to false claims or impersonation, is
                            strictly prohibited. <br /> <br /> 2.2 Verification
                            of Reviews: SkillSquad may, at its discretion,
                            verify the authenticity of feedback and ratings
                            before publication. SkillSquad reserves the right to
                            remove any feedback or ratings that cannot be
                            verified or that violate the Terms and Conditions.{" "}
                            <br /> <br /> 3. Intellectual Property Rights <br />
                            3.1 Ownership of Feedback: Users retain ownership of
                            their feedback and ratings, but by submitting such
                            content, users grant SkillSquad a global,
                            non-exclusive, royalty-free, and irrevocable license
                            to use, modify, publish, and distribute the feedback
                            as part of its services. <br /> <br /> 3.2
                            Prohibited Use of Feedback: Users may not use
                            feedback or ratings submitted by others for
                            commercial purposes without obtaining prior written
                            consent from SkillSquad. Unauthorized use or
                            reproduction of feedback is a violation of these
                            Terms and may result in legal action. <br /> <br />{" "}
                            4. Liability and Disputes <br /> 4.1 Liability for
                            Feedback: SkillSquad is not liable for any content
                            submitted by users, including feedback and ratings.
                            Users agree to indemnify and hold SkillSquad
                            harmless from any claims, damages, or liabilities
                            arising from their submissions. <br /> <br /> 4.2
                            Dispute Resolution: Any disputes arising from
                            feedback or ratings should be addressed in
                            accordance with SkillSquad s dispute resolution
                            procedures. SkillSquad will make reasonable efforts
                            to address disputes but is not obligated to mediate
                            or resolve conflicts between users. <br /> <br /> 5.
                            Modification of Feedback Policies <br /> 5.1 Policy
                            Changes: SkillSquad reserves the right to modify or
                            update its feedback and ratings policies at any
                            time. Users will be notified of any significant
                            changes, and continued use of the platform
                            constitutes acceptance of the revised policies.
                          </div>

                          <div
                            class="mt-3"
                            style={{
                              fontSize: 20,
                              fontWeight: 700,
                              lineHeight: 2,
                            }}
                          >
                            Force Majeure
                          </div>
                          <div class="mt-3">
                            1. Definition of Force Majeure
                            <br /> 1.1 Force Majeure Events: For the purposes of
                            these Terms and Conditions, "Force Majeure" refers
                            to any event or circumstance beyond the reasonable
                            control of SkillSquad, including but not limited to
                            acts of God, natural disasters, war, terrorism,
                            civil unrest, government actions, pandemics,
                            strikes, or other industrial disturbances, failure
                            of utilities or communications, or any other
                            unforeseen event that materially impairs the ability
                            of SkillSquad to perform its obligations under these
                            Terms.
                            <br />
                            <br /> 2. Force Majeure Relief <br />
                            2.1 Suspension of Obligations: In the event of a
                            Force Majeure occurrence, SkillSquad shall be
                            entitled to suspend performance of its obligations
                            under these Terms and Conditions for the duration of
                            such event. SkillSquad will make reasonable efforts
                            to mitigate the impact of the Force Majeure event
                            and resume performance as soon as reasonably
                            practicable. <br />
                            <br />
                            2.2 No Liability: SkillSquad shall not be liable for
                            any failure to perform its obligations under these
                            Terms and Conditions if such failure is directly
                            attributable to a Force Majeure event. This
                            includes, but is not limited to, delays in providing
                            services, access, or content.
                            <br />
                            <br /> 3. Notification and Mitigation
                            <br /> 3.1 Notification Requirement: SkillSquad
                            shall promptly notify users of any Force Majeure
                            event that affects its ability to perform its
                            obligations. The notification shall include a
                            description of the Force Majeure event, its
                            anticipated duration, and the impact on SkillSquad s
                            performance.
                            <br />
                            <br /> 3.2 Mitigation Efforts: SkillSquad shall
                            undertake all reasonable efforts to mitigate the
                            effects of the Force Majeure event and resume its
                            obligations under these Terms and Conditions as
                            expeditiously as possible. <br />
                            <br />
                            4. Termination Rights
                            <br /> 4.1 Termination for Extended Force Majeure:
                            If a Force Majeure event continues for a period
                            exceeding [insert number] days, SkillSquad reserves
                            the right to terminate these Terms and Conditions,
                            or any part thereof, with immediate effect upon
                            written notice to users. In such event, SkillSquad
                            shall not be liable for any damages or losses
                            incurred by users as a result of such termination.{" "}
                            <br />
                            <br />
                            5. Limitation of Liability <br />
                            5.1 No Consequential Damages: In no event shall
                            SkillSquad be liable for any indirect,
                            consequential, or special damages arising out of or
                            in connection with a Force Majeure event, including
                            but not limited to loss of profits, revenue, or
                            data.
                          </div>

                          <div
                            class="mt-3"
                            style={{
                              fontSize: 20,
                              fontWeight: 700,
                              lineHeight: 2,
                            }}
                          >
                            Dispute Resolution
                          </div>
                          <div class="mt-3">
                            1. Governing Law <br />
                            1.1 Applicable Law: These Terms and Conditions shall
                            be governed by and construed in accordance with the
                            laws of [Insert Jurisdiction], without regard to its
                            conflict of law principles.
                            <br />
                            <br /> 2. Dispute Resolution Mechanisms
                            <br /> 2.1 Informal Resolution: Prior to initiating
                            formal proceedings, the parties agree to attempt to
                            resolve any disputes arising under or in connection
                            with these Terms and Conditions through informal
                            negotiations. The party asserting a dispute must
                            provide written notice to the other party detailing
                            the nature of the dispute and the desired
                            resolution.
                            <br />
                            <br /> 2.2 Mediation: If the dispute remains
                            unresolved within 90 days following the initiation
                            of informal negotiations, the parties agree to
                            submit the dispute to mediation administered by
                            Mediation Organization in accordance with its rules.
                            The mediation shall occur in India, and the costs of
                            mediation shall be shared equally by the parties.{" "}
                            <br />
                            <br />
                            2.3 Arbitration: Should mediation fail to resolve
                            the dispute, the parties agree that the dispute
                            shall be finally resolved by arbitration
                            administered by Arbitration Organization in
                            accordance with its arbitration rules. The
                            arbitration shall take place in [Insert Location],
                            and the decision of the arbitrator shall be binding
                            and enforceable. The parties shall bear their own
                            costs and attorney's fees unless otherwise
                            determined by the arbitrator.
                            <br />
                            <br /> 3. Jurisdiction and Venue <br />
                            3.1 Exclusive Jurisdiction: For any disputes that
                            cannot be resolved through mediation or arbitration,
                            the parties agree that the courts located in
                            India,Telangana shall have exclusive jurisdiction.
                            The parties consent to the personal jurisdiction of
                            such courts and waive any objections to venue or
                            jurisdiction.
                            <br />
                            <br /> 4. Waiver of Class Actions
                            <br />
                            4.1 No Class Actions: The parties agree that any
                            dispute resolution proceedings will be conducted on
                            an individual basis, and not as a class action or
                            representative action. Each party waives any right
                            to participate in a class action or similar
                            proceeding.
                            <br />
                            <br /> 5. Enforcement
                            <br /> 5.1 Enforceability: Any provision of this
                            Dispute Resolution section found to be unenforceable
                            by a court of competent jurisdiction shall be
                            severed, and the remaining provisions shall continue
                            in full force and effect. The parties agree to
                            attempt to renegotiate the severed provision in good
                            faith.
                          </div>

                          <div
                            class="mt-3"
                            style={{
                              fontSize: 20,
                              fontWeight: 700,
                              lineHeight: 2,
                            }}
                          >
                            Digital Signature Acceptance
                          </div>
                          <div class="mt-3">
                            1. Acceptance of Digital Signatures
                            <br /> 1.1 Digital Signatures Validity: By
                            accessing, registering for, or using the SkillSquad
                            platform, you acknowledge and agree that digital
                            signatures, electronic confirmations, and other
                            forms of electronic acceptance shall be deemed
                            equivalent to traditional handwritten signatures for
                            all purposes, including but not limited to the
                            execution of agreements, transactions, and consents.
                            <br />
                            <br /> 2. Electronic Records
                            <br />
                            2.1 Electronic Recordkeeping: SkillSquad may
                            maintain electronic records of transactions,
                            communications, and consents. Such electronic
                            records, including digital signatures, shall
                            constitute a record of your acceptance and consent
                            as if signed in ink, and shall be admissible as
                            evidence in any legal or regulatory proceeding.
                            <br />
                            <br /> 3. Binding Effect <br />
                            3.1 Binding Agreement: You agree that your digital
                            signature or electronic acceptance creates a binding
                            agreement between you and SkillSquad. Such agreement
                            shall have the same legal effect, validity, and
                            enforceability as a physical signature on a paper
                            document. <br />
                            <br />
                            4. Security and Authentication <br />
                            4.1 Security Measures: SkillSquad employs
                            industry-standard security measures to authenticate
                            digital signatures and protect the integrity of
                            electronic transactions. You are responsible for
                            maintaining the confidentiality of your digital
                            signature credentials and for any unauthorized use
                            of such credentials.
                            <br />
                            <br /> 5. Disputes and Challenges
                            <br /> 5.1 Challenge to Digital Signatures: Any
                            challenge to the validity or enforceability of a
                            digital signature must be raised promptly and must
                            provide clear and convincing evidence that the
                            digital signature was not authorized by the
                            signatory. Absent such evidence, digital signatures
                            shall be presumed valid and enforceable.
                            <br />
                            <br /> 6. Consent to Electronic Communication
                            <br /> 6.1 Consent: By using SkillSquad, you consent
                            to receive communications, notifications, and
                            disclosures electronically. This consent applies to
                            all transactions and communications conducted
                            through the platform.
                          </div>

                          <div
                            class="mt-3"
                            style={{
                              fontSize: 20,
                              fontWeight: 700,
                              lineHeight: 2,
                            }}
                          >
                            Ethical Guidelines and User Conduct
                          </div>
                          <div class="mt-3">
                            1. Standards of Conduct
                            <br /> 1.1 Professionalism: Users, including both
                            learners and experts, shall conduct themselves in a
                            professional, ethical, and respectful manner while
                            using the SkillSquad platform. This includes, but is
                            not limited to, adhering to established codes of
                            conduct within their respective industries.
                            <br />
                            <br /> 1.2 Prohibition of Misconduct: Users are
                            expressly prohibited from engaging in any conduct
                            that is fraudulent, deceptive, defamatory,
                            harassing, or otherwise harmful to other users or
                            the integrity of the platform. This includes, but is
                            not limited to, false representations, unlawful
                            activities, and the distribution of malicious
                            content.
                            <br />
                            <br /> 2. Compliance with Laws
                            <br /> 2.1 Legal Compliance: Users must comply with
                            all applicable laws and regulations governing their
                            activities on the platform. This includes compliance
                            with data protection laws, intellectual property
                            rights, and other legal requirements pertinent to
                            their use of SkillSquad s services.
                            <br />
                            <br /> 2.2 Reporting Violations: Users are required
                            to report any suspected violations of these terms or
                            any illegal activities encountered on the platform
                            to SkillSquad promptly. Failure to report such
                            violations may result in the termination of the
                            user s access to the platform.
                            <br />
                            <br /> 3. Content Standards
                            <br />
                            3.1 Accuracy and Integrity: Users must ensure that
                            all content provided or shared on the platform is
                            accurate, truthful, and does not infringe on any
                            third-party rights. Content must not be misleading,
                            discriminatory, or otherwise detrimental to the
                            platform s reputation.
                            <br />
                            <br /> 3.2 Intellectual Property: Users must respect
                            all intellectual property rights, including
                            copyrights, trademarks, and patents. Unauthorized
                            use, reproduction, or distribution of proprietary
                            content is strictly prohibited.
                            <br />
                            <br /> 4. Use of Platform <br />
                            4.1 Permitted Use: The platform is to be used solely
                            for the purposes specified by SkillSquad.
                            Unauthorized use of the platform, including any
                            activities that disrupt or interfere with its
                            functionality, is prohibited.
                            <br />
                            <br /> 4.2 Account Security: Users are responsible
                            for maintaining the confidentiality of their account
                            credentials and for all activities conducted under
                            their account. Any unauthorized use must be reported
                            to SkillSquad immediately.
                            <br />
                            <br /> 5. Enforcement <br />
                            5.1 Monitoring and Enforcement: SkillSquad reserves
                            the right to monitor user activity and enforce
                            compliance with these guidelines. Violations may
                            result in suspension or termination of access, legal
                            action, or other remedies as deemed appropriate by
                            SkillSquad. <br />
                            <br />
                            5.2 Dispute Resolution: Any disputes arising from
                            alleged breaches of these ethical guidelines shall
                            be resolved in accordance with the dispute
                            resolution provisions set forth in these Terms and
                            Conditions.
                          </div>

                          <div
                            class="mt-3"
                            style={{
                              fontSize: 20,
                              fontWeight: 700,
                              lineHeight: 2,
                            }}
                          >
                            Accessibility Compliance
                          </div>
                          <div class="mt-3">
                            1. Commitment to Accessibility <br />
                            1.1 Inclusivity: SkillSquad is committed to ensuring
                            that its platform is accessible to all users,
                            including individuals with disabilities. We strive
                            to adhere to recognized accessibility standards and
                            guidelines to provide an equitable user experience.
                            <br />
                            <br /> 1.2 Accessibility Standards: SkillSquad aims
                            to comply with relevant accessibility standards,
                            including but not limited to the Web Content
                            Accessibility Guidelines (WCAG)
                            <br />
                            <br /> 2.1 and applicable laws and regulations such
                            as the Americans with Disabilities Act (ADA) and the
                            Rights of Persons with Disabilities Act (RPWD).{" "}
                            <br />
                            <br />
                            2. Platform Accessibility
                            <br />
                            2.1 Ongoing Efforts: We are dedicated to
                            continuously improving the accessibility of our
                            platform. This includes regular reviews, updates,
                            and the implementation of features designed to
                            enhance usability for all users.
                            <br />
                            <br /> 2.2 Feedback Mechanism: Users are encouraged
                            to provide feedback regarding accessibility issues.
                            SkillSquad will review and address such feedback in
                            accordance with our accessibility improvement
                            initiatives. <br />
                            <br />
                            3. User Responsibilities
                            <br /> 3.1 Notification of Accessibility Issues:
                            Users who encounter accessibility barriers are
                            required to notify SkillSquad promptly. Such
                            notifications should include a description of the
                            issue to facilitate a timely resolution.
                            <br />
                            <br /> 3.2 Assistive Technologies: While SkillSquad
                            endeavors to support compatibility with assistive
                            technologies, users are responsible for ensuring
                            that their own assistive devices or software are
                            appropriately configured for optimal access to the
                            platform.
                            <br />
                            <br /> 4. Limitation of Liability <br />
                            4.1 No Guarantee of Complete Accessibility:
                            SkillSquad makes reasonable efforts to ensure
                            accessibility; however, we do not guarantee that the
                            platform will be fully accessible at all times. The
                            ongoing nature of technological and regulatory
                            changes may impact accessibility. <br />
                            <br />
                            4.2 Exclusions: SkillSquad shall not be liable for
                            any direct, indirect, incidental, or consequential
                            damages arising from any accessibility issues,
                            except as required by applicable law.
                            <br />
                            <br /> 5. Legal Compliance <br /> 5.1 Regulatory
                            Adherence: SkillSquad shall comply with all
                            applicable accessibility laws and regulations. This
                            section does not limit or alter any rights or
                            obligations under such laws.
                            <br />
                            <br /> 5.2 Updates and Amendments: This
                            Accessibility Compliance section may be updated or
                            amended to reflect changes in accessibility laws,
                            regulations, or platform capabilities.
                          </div>

                          <div
                            class="mt-3"
                            style={{
                              fontSize: 20,
                              fontWeight: 700,
                              lineHeight: 2,
                            }}
                          >
                            Corporate Social Responsibility (CSR)
                          </div>
                          <div class="mt-3">
                            1. Commitment to Social Responsibility
                            <br /> 1.1 Ethical Standards: SkillSquad is
                            dedicated to operating in a socially responsible
                            manner, adhering to the highest ethical standards in
                            all business practices. We are committed to
                            contributing positively to the communities we serve
                            and minimizing our environmental impact.
                            <br />
                            <br /> 1.2 CSR Initiatives: Our CSR initiatives
                            include, but are not limited to, promoting
                            education, supporting local communities, fostering
                            diversity and inclusion, and implementing
                            sustainable practices.
                            <br />
                            <br /> 2. Community Engagement
                            <br /> 2.1 Educational Outreach: SkillSquad supports
                            various educational programs and initiatives aimed
                            at enhancing learning opportunities and providing
                            resources to underserved communities. We actively
                            seek partnerships and collaborations that align with
                            our educational mission. <br />
                            <br />
                            2.2 Volunteerism and Giving: SkillSquad encourages
                            employee volunteerism and philanthropy. We may also
                            engage in charitable activities, donations, and
                            sponsorships that align with our corporate values
                            and community needs.
                            <br />
                            <br /> 3. Environmental Responsibility
                            <br /> 3.1 Sustainable Practices: SkillSquad is
                            committed to reducing our environmental footprint
                            through sustainable business practices. This
                            includes optimizing resource use, reducing waste,
                            and supporting green technologies.
                            <br />
                            <br />
                            3.2 Environmental Impact: We regularly assess our
                            operations to identify opportunities for improving
                            environmental performance and implementing practices
                            that contribute to the protection of the
                            environment. <br />
                            4. Ethical Conduct <br />
                            <br />
                            4.1 Compliance with Laws: SkillSquad ensures
                            compliance with all applicable laws and regulations
                            pertaining to corporate social responsibility. We
                            uphold transparency and accountability in all
                            CSR-related activities. <br />
                            <br />
                            4.2 Anti-Corruption Measures: We maintain strict
                            anti-corruption policies and practices to prevent
                            unethical conduct. Any form of bribery, corruption,
                            or unethical behavior is strictly prohibited. <br />
                            <br />
                            5. Reporting and Transparency
                            <br />
                            5.1 Disclosure: SkillSquad will transparently report
                            on its CSR activities and performance. Relevant
                            information will be available through periodic
                            reports and disclosures, reflecting our commitment
                            to accountability and responsible business
                            practices. <br />
                            <br />
                            5.2 Feedback Mechanism: We encourage stakeholders to
                            provide feedback on our CSR efforts. SkillSquad will
                            review and consider such feedback as part of our
                            ongoing commitment to social responsibility. <br />
                            <br />
                            6. Limitation of Liability <br />
                            6.1 No Guarantee of Impact: While SkillSquad is
                            committed to CSR, we do not guarantee specific
                            outcomes or results from our CSR activities. The
                            effectiveness of our initiatives may vary, and we
                            shall not be liable for any direct or indirect
                            impact resulting from these activities. 6.2
                            Exclusions: This section does not create any legally
                            binding obligations beyond those specified herein or
                            required by applicable laws.
                          </div>

                          <div
                            class="mt-3"
                            style={{
                              fontSize: 20,
                              fontWeight: 700,
                              lineHeight: 2,
                            }}
                          >
                            Miscellaneous Provisions
                          </div>
                          <div class="mt-3">
                            1. Severability <br />
                            1.1 Invalidity of Provisions: If any provision of
                            these Terms and Conditions is found to be invalid,
                            illegal, or unenforceable by a competent court or
                            authority, such provision shall be deemed severed
                            from these Terms and Conditions. The remaining
                            provisions shall continue in full force and effect,
                            and the parties shall endeavor to substitute a valid
                            provision that reflects the original intent of the
                            severed provision. <br />
                            <br />
                            2. Entire Agreement <br />
                            2.1 Comprehensive Agreement: These Terms and
                            Conditions, along with any other policies or
                            agreements expressly incorporated by reference,
                            constitute the entire agreement between SkillSquad
                            and the User regarding the subject matter herein and
                            supersede all prior or contemporaneous agreements,
                            understandings, and communications, whether written
                            or oral, relating to the subject matter. <br />
                            <br />
                            3. Amendments
                            <br />
                            3.1 Modification of Terms: SkillSquad reserves the
                            right to amend, modify, or update these Terms and
                            Conditions at any time. Such modifications will be
                            effective immediately upon posting the revised terms
                            on our platform. Continued use of the Services
                            following any changes constitutes acceptance of the
                            revised terms.
                            <br />
                            <br /> 4. Waiver
                            <br /> 4.1 Non-Enforcement of Rights: Failure or
                            delay by SkillSquad in exercising any right or
                            remedy under these Terms and Conditions shall not
                            constitute a waiver of such right or remedy. A
                            waiver of any provision or breach of these Terms and
                            Conditions shall be effective only if made in
                            writing and signed by an authorized representative
                            of SkillSquad.
                            <br />
                            <br /> 5. Assignment <br />
                            5.1 Transfer of Rights and Obligations: SkillSquad
                            may assign or transfer its rights, obligations, and
                            interests under these Terms and Conditions to any
                            third party without notice or consent. Users may not
                            assign or transfer their rights or obligations under
                            these Terms and Conditions without the prior written
                            consent of SkillSquad.
                            <br />
                            <br /> 6. Relationship of Parties
                            <br /> 6.1 Independent Contractors: Nothing in these
                            Terms and Conditions shall be construed as creating
                            a partnership, joint venture, agency, or employment
                            relationship between SkillSquad and the User. Both
                            parties are independent contractors, and neither
                            party shall have the authority to bind the other.
                            <br />
                            <br /> 7. Notices <br />
                            7.1 Communication: All notices required or permitted
                            under these Terms and Conditions shall be in writing
                            and delivered to the parties at their respective
                            addresses or electronic addresses provided during
                            registration or as updated from time to time.
                            Notices to SkillSquad shall be sent to our
                            designated contact address or email provided on the
                            platform.
                            <br />
                            <br /> 8. Force Majeure <br />
                            8.1 Uncontrollable Events: SkillSquad shall not be
                            liable for any delay or failure to perform its
                            obligations under these Terms and Conditions due to
                            causes beyond its reasonable control, including but
                            not limited to natural disasters, acts of terrorism,
                            war, labor disputes, or government regulations.
                            <br />
                            <br /> 9. Governing Law and Jurisdiction
                            <br /> 9.1 Legal Jurisdiction: These Terms and
                            Conditions shall be governed by and construed in
                            accordance with the laws of [Indian state] or [U.S.
                            state], as applicable. Any disputes arising out of
                            or relating to these Terms and Conditions shall be
                            subject to the exclusive jurisdiction of the courts
                            of [Indian state] or [U.S. state], as applicable.{" "}
                            <br />
                            <br />
                            10. Headings <br />
                            10.1 Interpretation: Headings used in these Terms
                            and Conditions are for convenience only and shall
                            not affect the interpretation or construction of any
                            provision.
                          </div>

                          <div
                            class="mt-3"
                            style={{
                              fontSize: 20,
                              fontWeight: 700,
                              lineHeight: 2,
                            }}
                          >
                            Additional Provisions
                          </div>
                          <div class="mt-3">
                            1. Jurisdictional Flexibility <br />
                            1.1 Dispute Resolution by Location: In the event of
                            any disputes or claims arising out of or in
                            connection with these Terms and Conditions, the
                            jurisdiction and venue for resolving such disputes
                            shall be determined based on the User's location.
                            SkillSquad retains the right to bring any legal
                            action in the courts of Telangana or depending on
                            the User s residence or place of business. <br />{" "}
                            <br /> 2. Anti-Money Laundering (AML) Compliance
                            <br />
                            2.1 Regulatory Compliance: SkillSquad adheres to
                            applicable anti-money laundering (AML) regulations
                            and requirements. Users shall comply with all AML
                            obligations as stipulated by relevant financial
                            authorities. SkillSquad reserves the right to
                            monitor transactions, verify user identities, and
                            report any suspicious activity in accordance with
                            AML laws. <br /> <br /> 3. User Support and Customer
                            Service <br />
                            3.1 Support Services: Users may access customer
                            support and resolve issues through designated
                            support channels provided on the SkillSquad
                            platform. SkillSquad will endeavor to address user
                            inquiries and complaints promptly, within reasonable
                            timeframes, and in accordance with our support
                            policies. <br /> <br /> 4. Accessibility and
                            Usability <br /> 4.1 Compliance with Accessibility
                            Standards: SkillSquad is committed to ensuring that
                            its platform complies with relevant accessibility
                            standards to accommodate users with disabilities. We
                            strive to provide a usable experience for all users,
                            and any feedback on accessibility issues should be
                            directed to our support team. <br /> <br />
                            5. Pandemic and Global Crisis Clauses
                            <br />
                            5.1 Service Disruptions: SkillSquad shall not be
                            liable for any failure to perform its obligations
                            under these Terms and Conditions due to disruptions
                            caused by pandemics, epidemics, or other global
                            crises. Such disruptions may include, but are not
                            limited to, service delays, interruptions, or
                            unavailability of platform features. <br /> <br />
                            6. Beta Services or Features <br />
                            6.1 Experimental Features: From time to time,
                            SkillSquad may offer beta or experimental features.
                            These features are provided "as is" and may be
                            subject to additional terms. SkillSquad makes no
                            warranties regarding the performance, stability, or
                            reliability of such features and reserves the right
                            to modify or discontinue them at its discretion.{" "}
                            <br /> <br />
                            7. End-of-Life Services <br />
                            7.1 Termination and Data Handling: In the event that
                            SkillSquad discontinues any services, Users will be
                            notified in advance. SkillSquad will provide
                            instructions for data retrieval and deletion.
                            Following termination of services, SkillSquad shall
                            not be obligated to retain or provide access to User
                            data beyond the discontinuation date. <br /> <br />{" "}
                            8. Data Portability <br />
                            8.1 Access and Transfer: Users have the right to
                            access and transfer their data in accordance with
                            applicable data protection laws. SkillSquad will
                            facilitate data portability requests in a
                            structured, commonly used, and machine-readable
                            format, subject to technical and operational
                            limitations. <br /> <br /> 9. AI and Automated Tools
                            Disclosure <br />
                            9.1 Use of AI Tools: SkillSquad utilizes AI and
                            automated tools to enhance the platform's
                            functionality and user experience. Users acknowledge
                            that AI tools may be employed for various purposes,
                            including but not limited to personalized
                            recommendations and automated support. SkillSquad
                            will ensure that such tools comply with applicable
                            regulations and standards.
                          </div>

                          
                          

                          
                        </div>
                      </div>
                      {/* <div className="col"></div> */}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          {/* <NewFooter /> */}
        </div>
      </div>
    </>
  );
};

export default MentorTermsPage