import axios from 'axios'

const callaxios = axios.create({
	baseURL: "https://api.skillsquad.io",
	headers:{
        'Accept': 'application/json, text/plain',
        'Content-Type': 'application/json;charset=UTF-8',
      //  'Access-Control-Allow-Origin' : "https://api.skillsquad.io",
      'Access-Control-Allow-Origin' : "https://api.skillsquad.io",
        'Access-Control-Allow-Methods' :  "POST, GET, OPTIONS, PUT, DELETE",
        'Access-Control-Allow-Headers' :  "Content-Type, X-Auth-Token, Origin, Authorization, UserId"
    },
    mode: 'no-cors'
    
});

// configure headers
callaxios.interceptors.request.use(
    config =>
  {
    if(sessionStorage.getItem("LOGGEDIN")){
      config.headers['Authorization'] = 'Bearer ' + sessionStorage.getItem('JWT');
      config.headers['UserId'] = sessionStorage.getItem('JWTuserName');
    }
    return config;
  },
  (error) => {
    return Promise.reject(error)
  }
)


export default callaxios