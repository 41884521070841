import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Container,
  Segment,
  Item,
  Button,
  Icon,
  Message,
  Menu,
  Header,
} from "semantic-ui-react";
import he from "he";
import { getLetter } from "../../utils";
import Countdown from "../Countdown";

const Quiz = ({ data, countdownTime, endQuiz }) => {
  const [questionIndex, setQuestionIndex] = useState(0);
  const [correctAnswers, setCorrectAnswers] = useState(0);
  const [userSelectedAns, setUserSelectedAns] = useState(null);
  const [questionsAndAnswers, setQuestionsAndAnswers] = useState([]);
  const [timeTaken, setTimeTaken] = useState(null);
  const [answered, setAnswered] = useState(false);
  const [lastSelectedOption, setLastSelectedOption] = useState(null);
  const [isOptionDisabled, setIsOptionDisabled] = useState(false);

  const [previouslySelectedOption, setPreviouslySelectedOption] =
    useState(null);
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    if (questionIndex > 0) window.scrollTo({ top: 0, behavior: "smooth" });
  }, [questionIndex]);

  const handleItemClick = (e, { name }) => {

    


    // setUserSelectedAns(name);
    // setLastSelectedOption(name);
    // setSelectedOption(name);
    // handleNext();

    if (!isOptionDisabled) { // Check if option selection is disabled
      setUserSelectedAns(name);
      setLastSelectedOption(name);
      setSelectedOption(name);
      setIsOptionDisabled(true); // Disable further option clicks
      handleNext(); // Process answer or move to next question
    }
  };

  const handleNextWithTimeout = () => {
    // Call the main logic in handleNext and capture its return values
    const { point, qna } = handleNext();
  
    // Set a timeout to transition to the next question or end the quiz
    // setTimeout(() => {
      if (questionIndex === data.length - 1) {
        endQuiz({
          totalQuestions: data.length,
          correctAnswers: correctAnswers + point,
          timeTaken,
          questionsAndAnswers: qna,
        });
      } else {
        setQuestionIndex(questionIndex + 1);
        setUserSelectedAns(null);
        setAnswered(false);
        setLastSelectedOption(null);
      }

      setIsOptionDisabled(false); // Re-enable option clicks for the next question
    
    // }, 2000);
  };
  
  // Core Logic Function
  const handleNext = () => {
    setPreviouslySelectedOption(userSelectedAns); // Update previouslySelectedOption
  
    let point = 0;
    if (userSelectedAns === he.decode(data[questionIndex].correct_answer)) {
      point = 1;
    }
  
    const qna = [...questionsAndAnswers];
    qna.push({
      question: he.decode(data[questionIndex].question),
      user_answer: userSelectedAns,
      correct_answer: he.decode(data[questionIndex].correct_answer),
      point,
    });
  
    setCorrectAnswers(correctAnswers + point);
    setQuestionsAndAnswers(qna);
    setAnswered(true);
  
    // Return point and qna as an object to be captured by handleNextWithTimeout

    
    return { point, qna };
  };
  

  const timeOver = (timeTaken) => {
    return endQuiz({
      totalQuestions: data.length,
      correctAnswers,
      timeTaken,
      questionsAndAnswers,
    });
  };

  const options = ["Option 1", "Option 2", "Option 3", "Option 4", "Option 5"];

  return (
    <div>
      <Item.Header>
        <Container>
          <Segment>
            <Item.Group divided>
              <Item>
                <Item.Content>
                  <div>
                    <Countdown
                      countdownTime={countdownTime}
                      timeOver={timeOver}
                      setTimeTaken={setTimeTaken}
                    />
                  </div>
                  <br />
                  <br />
                  <Item.Extra>
                    <Header as="p">
                      <Header.Content>
                        <div
                          class="questionnum2"
                          style={{
                            color: "rgb(99,101,102)",
                            fontSize: "15px",
                            marginBottom: "10px",
                          }}
                        >{`Question ${questionIndex + 1} of ${
                          data.length
                        }`}</div>
                      </Header.Content>
                    </Header>
                  </Item.Extra>
                  {/* <br /> */}
                  <Item.Meta>
                    <Message
                      style={{
                        display: "inline",
                        border: "none",
                       
                        boxShadow: "none",
                        padding: "0",
                      }}
                    >
                      {/* <div className='questiontext'><b style={{fontSize:'25px', color:'rgb(24,33,46)'}}>{`${questionIndex + 1} ${he.decode(data[questionIndex].question)}`}</b></div> */}
                      <div className="questiontext">
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              borderWidth: "2px",
                              borderRadius: "50%",
                              border: "2px solid rgb(200,207,218)",
                              padding: "10px",
                              width: "30px",
                              height: "30px",
                              marginRight: "15px",
                            }}
                          >
                            <b>{`${questionIndex + 1}`}</b>
                          </div>
                          <div style={{ fontSize: "20px" }}>
                            <b>{he.decode(data[questionIndex].question)}</b>
                          </div>
                        </div>
                      </div>
                    </Message>
                    <br />
                    <Menu
                      vertical
                      fluid
                      size="massive"
                      style={{ border: "none" }}
                    >
                      {data[questionIndex].options.map((option, i) => {
                        const letter = getLetter(i);
                        const decodedOption = he.decode(option);
                        const isActive = userSelectedAns === decodedOption;
                        const isLastSelectedOption =
                          lastSelectedOption === decodedOption;

                        let optionStyle = {
                          marginBottom: "20px",
                          // border: '1px solid grey', // rgb(215, 215, 215)
                          // Dynamic border stylergb(142, 142, 142)
                          border: "0.5px solid ",
                          borderColor: isActive
                            ? "rgb(126,91,253)"
                            : "rgb(200,207,218)",
                          borderRadius: "4px",
                          padding: "0px",
                          color: isActive ? "rgb(96,51,255)" : "rgb(24,33,46)",
                          backgroundColor: isLastSelectedOption
                            ? "rgb(241,238,255)"
                            : "transparent",
                          // cursor: 'pointer',
                        };

                        if (answered) {
                          const isCorrectOption =
                            he.decode(data[questionIndex].correct_answer) ===
                            decodedOption;
                          if (isActive && isCorrectOption) {
                            optionStyle.borderColor = "rgb(84,165,64)";
                            optionStyle.color = "rgb(27,135,0)";
                            optionStyle.backgroundColor = "rgb(227,255,220)";
                          } else if (!isActive && isCorrectOption) {
                            optionStyle.borderColor = "rgb(84,165,64)";
                            optionStyle.color = "rgb(27,135,0)";
                            optionStyle.backgroundColor = "rgb(227,255,220)";
                          } else if (isActive && !isCorrectOption) {
                            optionStyle.borderColor =
                              "rgba(199, 84, 76, 0.849)"; //rgb(199, 84, 76) //rgb(199,65,56)
                            optionStyle.color = "rgb(189,38,28)";
                            optionStyle.backgroundColor = "rgb(255,228,226)";
                          }
                        }

                        return (
                          <div style={{ marginBottom: "20px", border: "none" }}>
                            <Menu.Item
                              // name={decodedOption}
                              // onClick={handleItemClick}
                              // style={optionStyle}
                              name={decodedOption}
  onClick={!isOptionDisabled ? handleItemClick : null}
  style={optionStyle}
                              
                            >
                              <div
                                className="questionnum"
                                style={{ padding: "7px", marginLeft: "6px" }}
                              >
                                <span
                                  style={{
                                    border: "1px solid",
                                    padding: "8px",
                                    paddingLeft: "9px",
                                    paddingTop: "9px",
                                    paddingRight: "9px",
                                    borderRadius: "4px",
                                    marginRight: "5px",
                                    display: "inline-block",
                                    fontSize: "14px",
                                  }}
                                >
                                  {letter}
                                </span>
                                <span
                                  style={{
                                    fontSize: "14px",
                                    marginLeft: "8px",
                                  }}
                                >
                                  {decodedOption}
                                </span>
                              </div>
                            </Menu.Item>
                            {/* <div>{letter}{decodedOption}</div> */}
                          </div>
                        );
                      })}
                    </Menu>
                  </Item.Meta>
                  <br />
                  <Item.Extra>
                    <Button
                      style={{ backgroundColor: "#5627ff" }}
                      primary
                      content="Next"
                      // onClick={handleNext}
                      onClick={handleNextWithTimeout}
                      floated="right"
                      size="big"
                      icon="right chevron"
                      labelPosition="right"
                      disabled={!userSelectedAns}
                    />
                  </Item.Extra>
                </Item.Content>
              </Item>
            </Item.Group>
          </Segment>
          <br />
        </Container>
      </Item.Header>
    </div>
  );
};

Quiz.propTypes = {
  data: PropTypes.array.isRequired,
  countdownTime: PropTypes.number.isRequired,
  endQuiz: PropTypes.func.isRequired,
};

export default Quiz;             