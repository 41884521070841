import React from "react";
import Sidebar from "./Sidebar";
import "../styles/Dashboard.css";
import "../fonts/Roboto/Roboto-Black.ttf";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Spinner from 'react-bootstrap/Spinner';
import httpsignup from '../js/http-lms_userprofile'
import NoRecFound from "../components/NORecFound";

const BatchDetails = () => {

  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([]);
  const [img, setImg] = useState("grey");
  const [err, setErr] = useState()
  const [courseName, setCourseName] = useState()


  useEffect(() => {
    console.log(data, 'llllllllllll');
  }, [data])



  function mouseInImg() {
    setImg("blue");
  }

  function mouseOutImg() {
    setImg("grey");
  }

  const navigate = useNavigate();

  function handleClick() {
    navigate('/build-project')
  }

  useEffect(() => {
    setData([]);
    getStudentData();
  }, [])

  async function getStudentData() {
    setCourseName(sessionStorage.getItem("projectName"))
    console.log("Project Id : " + sessionStorage.getItem("projectId"));
    httpsignup.get(`/userprofileLMS/getSkillsquadTasksMaster?projectId=${sessionStorage.getItem("projectId")}`)
      .then((result) => {
        console.log("batch details :", result)
        if (result.data === "No records found") {
          setLoading(false)
          setErr(result.data)
        }
        else {
          result.data.map((res) => {
            setData((pValue) => {
              return [...pValue,
              {
                name: res.taskName
              }]
            })
          })
          setLoading(false)
        }

      })
  }


  return (
    <>
      <Sidebar />

      {/* Main Container */}

      <div className="dashboard-container mx-4">
  
        <div className="px-md-5 mt160">

          <div className="container-fluid batch-dates">
            <div className="row d-flex flex-column flex-sm-row justify-content-between mt-4">
              <div className="col-2 d-flex align-items-center me-5">
                {img === 'grey' &&
                  <img onClick={handleClick} onMouseEnter={mouseInImg}
                    className="pe-3 backarrow"
                    src={require("../images/leftarrow.png")}
                    alt="Image not found"
                    id="barrow1"
                  />}
                {img === 'blue' &&
                  <img onClick={handleClick} onMouseLeave={mouseOutImg}
                    className="pe-3 backarrow"
                    src={require("../images/leftarrow-blue.png")}
                    alt="Image not found"
                    id="barrow2"
                  />}
                <h4 className="mt-2">{courseName}</h4>
              </div>


            </div>

          </div>

          <div className="container-fluid">

            <div className="d-flex justify-content-between mt-5">
              <h1 className="mb-4">Tasks List</h1>

            </div>
            {loading ? <div className=" h-100 d-flex  bgwhite justify-content-center align-items-center mb-3 py-5 "> <Spinner animation="border" variant="secondary" /></div> :
              err ? <div className='col-12 text-center'>
                <NoRecFound error="No batch details found." />
              </div> :
                data && <div className="scrollme">
                  {data.map((data, index) => {
                    return (
                      <div class="container mt-3" key={index}>
                        <table class="table">
                          <thead>
                            <tr>
                              <th scope="col">S.no</th>
                              <th scope="col">Title</th>
                              <th scope="col">Type</th>
                              <th scope="col">Status</th>
                              <th scope="col">Action</th>
                            </tr>
                          </thead>
                        </table>
                        <h2><a href="#demo" class="btn btn-primary" data-bs-toggle="collapse">V</a>{data.name}</h2>

                        <div id="demo" class="collapse">
                          <table class="table">
                            <tbody>
                              <tr>
                                <th scope="row">1</th>
                                <td>Mark</td>
                                <td>Otto</td>
                                <td>@mdo</td>
                              </tr>
                              <tr>
                                <th scope="row">2</th>
                                <td>Jacob</td>
                                <td>Thornton</td>
                                <td>@fat</td>
                              </tr>
                              <tr>
                                <th scope="row">3</th>
                                <td colspan="2">Larry the Bird</td>
                                <td>@twitter</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>


                    );
                  })}



                </div>}

          </div>
        </div>
      </div>
    </>
  );
};

export default BatchDetails;
