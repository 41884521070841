import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { trackPageView } from './facebookPixel';

// Initialize Facebook Pixel on app load
trackPageView();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App />
);
