import React from "react";
import Sidebar from "./Sidebar";
import "../fonts/Roboto/Roboto-Black.ttf";
import BatchListTable from "../components/BatchListTable";
import { useState, useEffect } from "react";
import httpsignup from '../js/http-lms_userprofile'
import RightSidebar from "./RightSidebar";
import rocket from "../images/rocket.png"
import { useNavigate } from "react-router-dom"
import "../styles/Activity.css"
import book from "../images/book_icon.png"
import arrow from "../images/arrow_grey_icon.png"
import task from "../images/task_icon.png"
import clock from "../images/clock_activity_icon.png"
import httpvideos from "../js/http-lms_videos"
import moment from "moment/moment";
import pads from "../images/exam_pads.png"
import coach from "../images/coach.png"
import Spinner from 'react-bootstrap/Spinner';
import ProgressBar from "react-bootstrap/ProgressBar";
import next from "../images/next_icon.png"
import "../styles/common.css";

const BatchList = () => {


  const navigate = useNavigate();
  const [topicCompleted, setTopicCompleted] = useState();

  const [currentlyLearning, setCurrentlyLearning] = useState();
  const [userTypeId, setuserTyepId] = useState();



  useEffect(() => {
    allCards()
  }, [])


  function allCards() {
    httpsignup.get(`/skillsquadUserprofileLMS/getSkillsquadUserProfileBySignUpId?signUpId=${sessionStorage.getItem('userId')}`)
      .then((response) => {
        console.log("API Values DCards: : ", response.data)
        setuserTyepId(response.data.userTypeMasterId);


      }).catch((e) => {
        console.log(e.response)
      })
  }

  const [userDetails, setUserDetails] = useState([])

  const [programDetails, setProgramDetails] = useState([])

  const [loading, setLoading] = useState(true)

  const [error, setError] = useState()
  const [totalTopic, setTotalTopic] = useState(0);
  const [watchTopic, setWatchTopic] = useState(0);
  const [allocatedPrograms, setAllocatedPrograms] = useState([])
  const [courseCount, setCourseCount] = useState(0);
  const [newCourseCount, setNewCourseCount] = useState(0);
  const [totalTask, setTotaltask] = useState(0);
  const [completedTask, setCompletedtask] = useState(0);

  function handleClick(pId) {
    sessionStorage.setItem("myCourseProgramId", pId)
    navigate("/my-course-videos");
  }

  function newHandleClick(pId) {
    sessionStorage.setItem("myNewCourseProgramId", pId)
    navigate("/my-new-course-videos");
  }

  useEffect(() => {
    getLoggedInUserDetails()
    getTaskCount()

  }, [])




  const getLoggedInUserDetails = async () => {
    httpsignup.get(`/skillsquadUserprofileLMS/getSkillsquadUserProfileBySignUpId?signUpId=${sessionStorage.getItem("userId")}`)
      .then((response) => {
        console.log("UserDetails:", response)
        setWatchTopic(response.data.unlockVideoCount + 1);
        sessionStorage.setItem("profileid", response.data.lmsProfileId)
        sessionStorage.setItem("roleid", 2)
        sessionStorage.setItem("fullName", response.data.userName)
        programCards(response.data.lmsProfileId, response.data.userAccess);
        getUserNewCourses(response.data.lmsProfileId, response.data.userAccess);
        setUserDetails(response.data)
      }).catch((err) => {
        console.log(err)
      })
  }


  function programCards(id, access) {
    setLoading(true)
    httpvideos.get('/lmsVideoRecordingManager/api/v1/getLMSProgramDetails?roleId=2' + '&profileId=' + id + '&accessType=' + access)
      .then((response) => {
        if (response.data == "Records not found") {
          setLoading(false)

          setError(response.data)
        }
        else {
          console.log("progDetails: " + JSON.stringify(response.data.length))
          setProgramDetails(response.data)
          setTotalTopic(response.data[0].topicCount);
          setLoading(false)
          setCourseCount(response.data.length)
        }
      }).catch((err) => {
        console.log(err)
        setLoading(false)
      })
  }

  function allcourses() {
    navigate("/dashboard")
  }
  function myCourses() {
    navigate("/my-course")
  }



  function getUserNewCourses(id, access) {
    httpvideos.get(`/programsLMS/getPaidPrograms?profileId=${id}&accessType=${access}`)
      .then((response) => {
        // setUserType(response.data.userTypeMasterId)
        console.log(" new progDetails: " + JSON.stringify(response.data.length))
        setAllocatedPrograms(response.data)
        setNewCourseCount(response.data.length)

      }).catch((err) => {
        console.log(err)
      })
  }

  function getTaskCount() {
    httpvideos.get(`/lmsVideoRecordingManager/getTaskList?profileId=${sessionStorage.getItem("profileid")}`)
      .then((response) => {
        // setUserType(response.data.userTypeMasterId)
        console.log(" new Task List Deatils : " + JSON.stringify(response.data))
        setTotaltask(response.data.totalTask)
        setCompletedtask(response.data.completedTask)
      }).catch((err) => {
        console.log(err)
      })
  }

  return (
    <>
      <Sidebar />

      <div className="jobs-dashboard-container mx-4">

        {/* <Navb /> */}
        {userTypeId === 3 ?

          <div className="px-md-5 mt160">
            <section class="section">
              <div class="container">

                <div class="row">

                  <div class="col-lg-12">
                    <div class="row">
                      <div class="">
                        <div class="section-title  mb-4 pb-2">
                          <div className="me-2 mt-2 common_main_heading" style={{marginLeft: "4%"}}>Learning Overview</div>
                          <section class="section">
                            <div class="container">

                              <div class="row p-4">

                                <div class="col-lg-9">
                                  <div class="row">
                                    <div class="">
                                      <div class="section-title  mb-4 pb-2">
                                        <div className="col-lg-12">

                                          <div class="container">
                                            <div class="row" >
                                              <div class="col" >
                                                <div class="activity_course_count">
                                                  <div class="d-flex mb-2">
                                                    <div><img src={book} width={20} alt="book" /></div>
                                                    <div style={{ marginLeft: 5 }} class="common_main_text_highlight">Courses</div></div>
                                                  <div style={{ marginLeft: 25 }} class="activity_course_count_blue common_main_text_normal">{newCourseCount + courseCount} Courses</div>

                                                  <div class="d-flex flex-row-reverse bd-highlight">
                                                    <div class="p-2 bd-highlight"><div class="d-flex"><div class="activity_view" style={{ marginRight: 5, cursor: "pointer" }} onClick={myCourses}>View Details</div><img src={arrow} width={20} alt="book" /></div></div>

                                                  </div>
                                                </div>
                                              </div>
                                              <div class="col">
                                                <div class="activity_course_count">
                                                  <div class="d-flex mb-2">
                                                    <div><img src={task} width={20} alt="book" /></div>
                                                    <div style={{ marginLeft: 5 }} class="common_main_text_highlight">Tasks Complete</div></div>
                                                  <div style={{ marginLeft: 25 }} class="activity_course_count_blue common_main_text_normal">{completedTask} Tasks <span style={{ color: "#929292"}}>/{totalTask} Tasks</span></div>

                                                  <div class="d-flex flex-row-reverse bd-highlight">
                                                    <div class="p-2 bd-highlight"><div class="d-flex"><div class="activity_view" style={{ marginRight: 5, cursor: "pointer" }} onClick={myCourses}>View Details</div><img src={arrow} width={20} alt="book" /></div></div>

                                                  </div>
                                                </div>
                                              </div>
                                              {/*  <div class="col">
                                  <div class="activity_course_count">
                                    <div class="d-flex mb-2">
                                      <div><img src={clock} width={20} alt="book" /></div>
                                      <div style={{ marginLeft: 5 }}>Spend Hours</div></div>
                                    <div class="activity_course_count_blue">8 Hours <span style={{ color: "#929292", fontWeight: "600", fontSize: 16 }}>In a week</span></div>

                                    <div class="d-flex flex-row-reverse bd-highlight">
                                      <div class="p-2 bd-highlight"><div class="d-flex"><div class="activity_view" style={{ marginRight: 5 }}>View Details</div><img src={arrow} width={20} alt="book" /></div></div>

                                    </div>
                                  </div>
                                </div> */}
                                            </div>
                                          </div>


                                          {/* <div className="row">

            <div className="d-flex flex-column align-items-center">

<img
                    src={rocket}
                    alt="No image found"
                    
                    width={300}
                    height={300}
                  />    
                  
                  
                  
        </div>
        
        <center>  <hr style={{margin : 0,width:"70%"}} class="mb-2"></hr>
      <p class="noactivity mb-5">You don't have any active courses at the moment</p>
      <div class="activityButton" onClick={allcourses}>See More Courses</div>

      </center>
            </div> */}
                                        </div>

                                      </div>
                                    </div>
                                  </div>


                                </div>

                                {/*Filter section*/}
                              </div>

                            </div>

                          </section>
                          {/* <h1 className="me-2 mt-2">My Course</h1> */}
                          <section class="section">
                            <div class="container">

                              <div class="row p-4">

                                <div class="col-lg-9">
                                  <div class="row">
                                    <div class="">
                                      <div class="section-title  mb-4 pb-2">
                                        <div className="col-lg-12">

                                          <div class="container">

                                            <div class="row">
                                              <div class="col">
                                                <h1 className=" mt-2 mb-4 common_main_heading">My Course</h1>
                                              </div>
                                              <div class="col-md-auto">

                                              </div>
                                              <div class="col col-lg-4">
                                                <div class="d-flex flex-row-reverse bd-highlight">
                                                  <div class="p-2 bd-highlight"><div class="d-flex"><div class="activity_my_course_view common_main_text_highlight" style={{ marginRight: 5, cursor: "pointer" }} onClick={myCourses}>View Details</div><img src={next} width={20} alt="book" /></div></div>

                                                </div>
                                              </div>
                                            </div>
                                            <div className="row">


                                              {loading ? <div className="d-flex py-5 justify-content-center w-100"><Spinner animation="border" variant="lightsecondary" /> </div> :
                                                (error || programDetails.length < 1 || programDetails[0].programId == null) ? <div className='col-12 text-center'><center> <div> <div><img src={pads} alt="pad" width={300} height={200} class="mb-3" /></div><div class="mb-3 mycourse-nodata">You haven't enrolled in any courses yet </div><div class="activityButton" onClick={allcourses}>See More Courses</div></div></center>

                                                </div> :

                                                  programDetails.map((res) => {
                                                    return (
                                                      <div class="col-6">

                                                        <div class="mb-3">
                                                          <div class="activity_my_course p-3">
                                                            <div class="mycourse d-flex">

                                                              <div>

                                                                <div class="common_main_text_highlight">{res.programName}</div>
                                                              </div>

                                                            </div>
                                                            <div class='mb-2 p-1'>
                                                              <div class="common_main_text_highlight">Course Progress</div>
                                                              <div class="common_main_text_highlight">{((res.completedTopics / res.totalTopic) * 100).toFixed()}% Completed ({res.completedTopics}/{res.totalTopic})</div>
                                                              <ProgressBar now={((res.completedTopics / res.totalTopic) * 100).toFixed()} label={`${((res.completedTopics / res.totalTopic) * 100).toFixed()}%`} />
                                                            </div>

                                                          </div>
                                                        </div>
                                                      </div>
                                                    )
                                                  }
                                                  )}
                                              {loading ? <div className="d-flex py-5 justify-content-center w-100"><Spinner animation="border" variant="lightsecondary" /> </div> :
                                                (error || allocatedPrograms.length < 1 || allocatedPrograms[0].programId == null) ? <></> :

                                                  allocatedPrograms.map((res) => {
                                                    return (
                                                      <div class="col-6">

                                                        <div class="mb-3">
                                                          <div class="activity_my_course p-3">
                                                            <div class="mycourse d-flex">

                                                              <div>

                                                                <div class="common_main_text_highlight">{res.programName}</div>
                                                              </div>

                                                            </div>
                                                            <div class='mb-2 p-1'>
                                                              <div class="common_main_text_highlight">Course Progress</div>
                                                              <div class="common_main_text_highlight">{((res.completedTopics / res.totalTopic) * 100).toFixed()}% Completed ({res.completedTopics}/{res.totalTopic})</div>
                                                              <ProgressBar now={((res.completedTopics / res.totalTopic) * 100).toFixed()} label={`${((res.completedTopics / res.totalTopic) * 100).toFixed()}%`} />
                                                            </div>

                                                          </div>
                                                        </div>
                                                      </div>
                                                    )
                                                  }
                                                  )}





                                            </div>
                                          </div>


                                        </div>

                                      </div>
                                    </div>
                                  </div>


                                </div>

                                {/*Filter section*/}
                              </div>

                            </div>

                          </section>


                        </div></div></div></div></div></div></section></div> :

          <div className="mt160">
            <section class="section">
              <div class="container">

                <div class="row p-4">

                  <div class="col-lg-9">
                    <div class="row">
                      <div class="">
                        <div class="section-title  mb-4 pb-2">
                          <div className="col-lg-12">




                            <div className="row">

                              <div className="d-flex flex-column align-items-center">

                                <img
                                  src={rocket}
                                  alt="No image found"

                                  width={300}
                                  height={300}
                                />



                              </div>

                              <center>  <hr style={{ margin: 0, width: "70%" }} class="mb-2"></hr>
                                <p class="noactivity mb-5">You don't have any active courses at the moment</p>
                                <div class="activityButton" onClick={allcourses}>See More Courses</div>

                              </center>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>


                  </div>

                  {/*Filter section*/}
                </div>

              </div>

            </section>



          </div>
        }


      </div>

      <RightSidebar />
    </>
  );
};

export default BatchList;
