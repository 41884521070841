import React, { useEffect, useRef } from 'react'
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useState } from "react";
import 'react-image-crop/dist/ReactCrop.css'
import httpsignup from '../js/http-lms_userprofile';
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select'
import attch from '../images/file_attach.svg';
import JoditEditor from "jodit-react"
import Spinner from 'react-bootstrap/Spinner';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from "draft-js";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import arrow from "../images/left-arrow_icon.png"
import Sidebar from "../pages/Sidebar";
import { useNavigate } from "react-router-dom";




const DiscussionPopup = ({ onClose, selectedCourseId, pId, email, userDetails, topicId, programId }) => {
    const [selectedTopicsOption, setSelectedTopicsOption] = useState(null);
    const [topicsList, setTopicsList] = useState([])

    const [defaultDetails, setDetails] = useState({})

    const [formData, setFormData] = useState({});
    const [formErros, setFormErrors] = useState({});
    const editor = useRef(null)
    const contentFieldChanged = (data) => {
        setPost({ ...post, 'content': data })
    }
    const [post, setPost] = useState({
        content: ''
    })
    const [disable, setDisable] = useState(true)
    const [submitLoading, setSubmitLoading] = useState(false);
    const [editorState, setEditorState] = useState();
    const [openEditor, setOpenEditor] = useState(false);
    const navigate = useNavigate();
    const [selectedCourseOption, setSelectedCourseOption] = useState(null);


    useEffect(() => {
        setSelectedCourseOption(userDetails[0])
    }, [])

    function openEdit() {
        setOpenEditor(true)
    }

    function closeEdit() {
        //setOpenEditor(false)
        navigate("/discussion")
    }

    function handleTopicsChange(e) {


        console.log("selected degree", e)
        console.log("selected default", e)
        //reset states & cities, once changed selected country
        setSelectedTopicsOption(e)
        defaultDetails.titleId = e.lmsTopicMasterId;



    }




    const colourStyles = {
        control: styles => ({ ...styles, borderRadius: '10px' }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {

            return {
                ...styles,
                backgroundColor: isFocused ? "#5627ff" : null,
                color: isFocused ? "white" : "black",
                fontWeight: isFocused ? 600 : 500,

            };
        },

    }

    function handleChange(e) {
        // setErrorMessage('')

        const { name, value } = e.target;
        setDetails((pValue) => {
            console.log(pValue)
            return {
                ...pValue,
                [name]: value
            }
        })
        console.log("after change : ", defaultDetails)
    }

    function saveDiscussion(e) {
        //defaultDetails.courseId = selectedCourseId;
        defaultDetails.desc = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        defaultDetails.profileId = pId;
        defaultDetails.upvote = 0;
        defaultDetails.email = email;
        if(topicId != null){
            defaultDetails.titleId = topicId
        }
        if(programId != null){
            defaultDetails.courseId = programId
        }
        e.preventDefault()
        httpsignup.post(`/usersLMS/saveDiscussion`,
            {
                lmsProgramId: defaultDetails.courseId,
                lmsTopicMasterId: defaultDetails.titleId,
                title: defaultDetails.title,
                description: defaultDetails.desc,
                profileId: defaultDetails.profileId,
                upvote: defaultDetails.upvote,
                creationUserI: defaultDetails.email,
                lastUpdatedUserI: defaultDetails.email
            }
        )
            .then((response) => {
                console.log(JSON.stringify(response.data))
                onClose()

            }).catch((err) => {
                console.log(err)
            })
    }

    function onEditorStateChange(editorState) {
        setEditorState(editorState)


    };

    function handleCourseChange(e) {


        console.log("selected degree", e)
        console.log("selected default", e)
        //reset states & cities, once changed selected country
        setSelectedCourseOption(e)
        defaultDetails.courseId = e.courseId;
        //defaultDetails.titleId = e.lmsTopicMasterId;

        // getDiscusssionList(e.courseId, e.lmsTopicMasterId, 'desc', pId)
        //changeProgram(e.courseId,e.batchId)
        setTopicsList([])
        setSelectedTopicsOption(null)
        httpsignup.get(`/usersLMS/getTopicByProgram?programId=${e.courseId}`)
            .then((response) => {
                setTopicsList(response.data)


            }).catch((err) => {
                console.log(err)
            })
    }

    return (
        <>

            <div className="jobs-dashboard-container mt-5">


                <div className="px-md-5  mt-5">

                    <section class="section">
                        <div class="container">

                            <div class="row ">

                                <div class="col-lg-12">
                                    <div class="row">
                                        <div class="">
                                            <div class="mb-4">
                                                <div className="col-lg-9">
                                                    <img src={arrow} width={30} alt="arrow" style={{ cursor: "pointer" }} onClick={onClose} />
                                                    <div class="discussion_thread_card mt-3">
                                                       {topicId != null ? <></> : <div class="discussion_thread_radio">
                                                            <div class="d-flex align-items-center"><input type="radio" id="general" name="fav_language" value="GENERAL" style={{accentColor: "#5627FF"}} checked />
                                                                <div for="general" class="mx-2 common_main_text_highlight">General Discussion</div></div>
                                                            {/* <div><input type="radio" id="project" name="fav_language" value="PROJECT" />
                                                                <label for="project">Project Discussion</label></div> */}
                                                        </div> }
                                                     {topicId != null ? <></> :  <div class="row mt-4"> <div class="col">
                                                            <Select

                                                                placeholder="Choose a Topic"
                                                                className="multiselectdropdown"
                                                                classNamePrefix="react-select"
                                                                value={selectedCourseOption}
                                                                onChange={handleCourseChange}
                                                                getOptionLabel={option =>
                                                                    `${option.courseName}`
                                                                }

                                                                options={userDetails}
                                                                styles={colourStyles}

                                                            />
                                                        </div></div> }
                                                       {topicId != null ? <></> : <div class="row mt-4"> <div class="col">
                                                            <Select

                                                                placeholder="Choose a Topic"
                                                                className="multiselectdropdown"
                                                                classNamePrefix="react-select"
                                                                value={selectedTopicsOption}
                                                                onChange={handleTopicsChange}
                                                                getOptionLabel={option =>
                                                                    `${option.topicName}`
                                                                }

                                                                options={topicsList}
                                                                styles={colourStyles}

                                                            />
                                                        </div></div>}
                                                        <div class="mt-4">
                                                            <Form.Control
                                                                className={formErros.title ? "borderred" : ""}
                                                                placeholder="Enter title"
                                                                autoComplete="off"
                                                                name='title'
                                                                onChange={handleChange}

                                                                defaultValue={defaultDetails.title}
                                                            />
                                                        </div>
                                                        <div style={{ border: "1px solid grey", borderRadius: 5 }} class="mt-4">
                                                            <Editor
                                                                editorState={editorState}
                                                                toolbarClassName="toolbarClassName"
                                                                wrapperClassName="wrapperClassName"
                                                                editorClassName="editorClassName"
                                                                onEditorStateChange={onEditorStateChange}
                                                            />
                                                        </div>
                                                        <div class="row mt-4"><div class='col'></div><div class='col'></div><div class='col'></div><div class='col'><div class="common_main_button align-items-center" onClick={(e) => { saveDiscussion(e) }}><img src={require("../images/send.png")} style={{marginRight: 10}} />Publish</div></div></div>

                                                    </div>
                                                </div></div></div></div></div></div></div>

                    </section>

                </div>
            </div>
        </>
    )
}

export default DiscussionPopup;
