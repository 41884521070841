import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const BookingCallSkeleton = () => {
  return (
    <div className="booking-call">
      <div className="">
        <Skeleton width={300} height={20} />
      </div>
      <div className="select-service-container">
        <div className="select-service-heading">
          <Skeleton width={200} height={20} />
        </div>
        {[...Array(4)].map((_, index) => (
          <div
            className="call-service-card-selected"
            key={index}
          >
            <div className="video-details">
              <div className="video-details-first">
                <Skeleton circle width={40} height={40} />
                <Skeleton width={150} height={20} />
              </div>
              <div className="booking-call-price-time">
                <Skeleton width={80} height={20} />
                <Skeleton width={60} height={20} />
              </div>
            </div>
            <div>
              <Skeleton width={80} height={30} />
            </div>
          </div>
        ))}
        
      </div>
    </div>
  );
};

export default BookingCallSkeleton;
