import React, { useState } from "react";

const AccordionItem = ({ item }) => {
  console.log(item);
  return (
    <>
      {item.options.map((dataItem, index) => (
        <div className="content mt-3">
          <div
            className="options d-flex align-items-center"
            style={{
              border: "1px solid",
              width: "90%",
              padding: "8px",
              borderRadius: "8px",

              backgroundColor:
                item.userAnswer === item.correctAnswer &&
                dataItem === item.userAnswer 
                  ? "rgb(227, 255, 220)"
                  : item.userAnswer !== item.correctAnswer &&
                    dataItem === item.userAnswer 
                  ? "rgb(255, 228, 226)"
                  : dataItem === item.correctAnswer 
                  ? "rgb(227, 255, 220)"
                  : "white",
            }}
          >
            <div className="d-flex align-items-center">
              <div
                className="d-flex align-items-center"
                style={{
                  border: "1px solid",
                  height: "30px",
                  width: "30px",
                  padding: "10px",
                  borderRadius: "6px",
                  marginRight: "15px",
                }}
              >
                <span>{index + 1}</span>
              </div>
              <span>{dataItem}</span>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default AccordionItem;