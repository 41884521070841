import React, { useEffect, useState } from "react";
import "../../styles/slotbooking.css";
import profile from "../../images/m-b-profile.svg";
import testprofile from "../../images/test-profile.png";
import Slider from "react-slick";
import left from "../../images/left-chev.svg";
import right from "../../images/right-chev.svg";
import MentorTestimonialsCards from "./MentorTestimonialsCards";
import Sidebar from "../../pages/Sidebar";
import { usePopup } from "../../components/PopupContext";
import moment from "moment";
import httpsignup from "../../js/http-lms_userprofile";
import { useNavigate, useParams } from "react-router-dom";
import backArrow from "../../images/mentor_profile_back_button.svg";
import full from "../../images/mentor full.svg";
import useRazorpay from "react-razorpay";
import { useCallback } from "react";
import axios from 'axios'
import MentorTimeSlots from "./MentorTimeSlots";
import SkeletonSlotBooking from "../../components/SkeletonSlotBooking";

const SlotBookingPage = () => {
  const {
    clickedService,
    clickedDates,
    userProfileData,
    selectedServiceId,
    getLoggedInUserDetails,
    getIndividualServices,
    slotBookingLoader,
  } = usePopup();
  const [mentorTimeSlots, setMentorTImeSlots] = useState(null);
  const [mentorInfo, setMentorInfo] = useState(null);
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedTime, setSelectedTime] = useState("");
  const [meetingPurpose, setMeetingPurpose] = useState("");
  const [selectedDateBox, setSelectedDateBox] = useState(0);
  const [selectedTimeBox, setSelectedTimeBox] = useState(0);
  const [errorMessage, setErrorMessage] = useState(false);
  const [disableBookNow, setDisableBookNow] = useState(false);
  const navigate = useNavigate();
  const { id, munique } = useParams();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const mId =
    sessionStorage.getItem("m-mentorid") ||
    sessionStorage.getItem("m-umentorid");
  useEffect(() => {
    const fetchInitialTime = async () => {
      if (Array.isArray(clickedDates) && clickedDates.length > 0) {
        setSelectedDate(clickedDates[0]);
        try {
          const response = await httpsignup.get(
            `/mentor/getSessionTimingsByDate?mentorId=${mId}&sessionDate=${clickedDates[0].availabledate}&serviceId=${selectedServiceId}`
          );
          if (response && response.data) {
            const sortedTimeSlots = response.data.sort((a, b) => {
              const parseTime = (time) => {
                const [timePart, modifier] = time.split(" ");
                let [hours, minutes] = timePart.split(":").map(Number);

                if (modifier === "PM" && hours !== 12) {
                  hours += 12;
                } else if (modifier === "AM" && hours === 12) {
                  hours = 0;
                }

                return hours * 60 + minutes;
              };

              const minutesA = parseTime(a.startTime);
              const minutesB = parseTime(b.startTime);

              return minutesA - minutesB;
            });

            setMentorTImeSlots(sortedTimeSlots);
            setSelectedTime(mentorTimeSlots[0]);
          }
        } catch (error) {
          console.log("Error", error);
        }
      }
    };

    fetchInitialTime();
  }, [clickedDates]);
  useEffect(() => {
    if (mentorTimeSlots) {
      setSelectedTime(mentorTimeSlots[0]);
    }
  }, [mentorTimeSlots]);

  const getMentorInfo = async () => {
    try {
      const response = await httpsignup.get(
        `/mentor/getSkillsquadMentorApplicationsByProfileId?profileId=${sessionStorage.getItem(
          "m-profileid"
        )}`
      );
      if (response && response.data) {
        setMentorInfo(response.data);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    getMentorInfo();
    getLoggedInUserDetails();
    getLoggedInUserDetailsForBooking();
  }, []);

  useEffect(() => {
    getIndividualServices(id);
  }, [id]);

  const SamplePrevArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div onClick={onClick} className={`arrow prev-arrow ${className}`}>
        <img src={left} alt="Previous" />
      </div>
    );
  };

  const SampleNextArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div onClick={onClick} className={`arrow next-arrow ${className}`}>
        <img src={right} alt="Next" />
      </div>
    );
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    // slidesToScr  oll: 3,
    autoScroll: false,
    rows: 2,
    slidesPerRow: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };
  const handleTimeSlots = (time, index) => {
    setSelectedTime(time);
    setSelectedTimeBox(index);
  };

  const handleGetTimes = async (sessionDate, slot = null, index = null) => {
    setSelectedDate(slot);
    setSelectedDateBox(index);

    try {
      const response = await httpsignup.get(
        `/mentor/getSessionTimingsByDate?mentorId=${mentorInfo?.mentorId}&sessionDate=${sessionDate}&serviceId=${selectedServiceId}`
      );
      if (response && response.data) {
        setMentorTImeSlots(response.data);
      }
    } catch (error) {
      console.log("Error", error);
    }
  };
  const handlePurposeChange = (e) => {
    setMeetingPurpose(e.target.value);
  };

  const [message, setMessage] = useState(false);
  const handleSubmit = async () => {
    if (selectedDate !== "" && selectedTime !== "" && meetingPurpose !== "" &&  sessionStorage.getItem("JWT")) {
      setDisableBookNow(true)
      if(clickedService?.price != 'free'){
        handlePayment(clickedService?.price, mentorInfo.mentorId,userProfileData.profileId,selectedServiceId,selectedTime.availabilityId,meetingPurpose)
        }else{
setDisableBookNow(true);
      const selectedData = {
        mentorId: mentorInfo.mentorId,
        profileId: userProfileData.profileId,
        serviceId: selectedServiceId,
        availabilityId: selectedTime.availabilityId,
        meetingPurpose: meetingPurpose,
      };
      console.log("selected data", selectedData);
      const sendData = await httpsignup.post(
        `/mentor/saveSkillsquadMentorSessionBooking`,
        selectedData
      );
      if (sendData && sendData.data) {
        console.log("response data", sendData);
        setMessage(true);
      }
    }
    } else {
      setMessage(false);
      setErrorMessage(true);
      if (!sessionStorage.getItem("JWT")) {
        navigate("/sign-up");
      }
    }
  };

  const [timeLeft, setTimeLeft] = useState("");

  useEffect(() => {
    const combinedDateTime = moment(selectedDate?.availabledate).set({
      hour: moment(selectedTime?.startTime, "hh:mm A").hour(),
      minute: moment(selectedTime?.startTime, "hh:mm A").minute(),
      second: 0,
    });

    const updateTimer = () => {
      const now = moment();
      const duration = moment.duration(combinedDateTime.diff(now));
      const days = Math.floor(duration.asDays());
      const hours = Math.floor(duration.asHours()) % 24;
      const minutes = duration.minutes();
      const seconds = duration.seconds();

      let timeString = "";

      if (days > 0) {
        timeString += `${days}d `;
      }
      if (hours > 0 || days > 0) {
        timeString += `${hours}h `;
      }
      if (minutes > 0 || hours > 0 || days > 0) {
        timeString += `${minutes}m `;
      }
      timeString += `${seconds}s`;

      if (duration.asHours() <= 1) {
        timeString = `Hurry Up! ${timeString}`;
      } else {
        timeString += " left";
      }

      setTimeLeft(timeString);
    };

    updateTimer();
    const intervalId = setInterval(updateTimer, 1000);

    return () => clearInterval(intervalId);
  }, [selectedDate?.availabledate, selectedTime?.startTime]);

  const handleBackmentor = () => {
    if (sessionStorage.getItem("JWT")) {
      navigate(`/${sessionStorage.getItem("munique")}`);
    } else {
      navigate("/mentor-page/1:1-services/recommended");
    }
  };
  const handleBookingspage = () =>{
    navigate("/session-details")
  }

  function getLoggedInUserDetailsForBooking() {
    console.log("TRIGGERED LOGGED")
    httpsignup.get(`/skillsquadUserprofileLMS/getSkillsquadUserProfileBySignUpId?signUpId=${sessionStorage.getItem('userId')}`)
        .then((response) => {
            sessionStorage.setItem("bookingName", response.data.userName)
            sessionStorage.setItem("bookingEmail", response.data.userEmail)
            sessionStorage.setItem("bookingMobile", response.data.mobileNumber)
            console.log("USER DETAILS RESPONSE : " + JSON.stringify(response.data))
  
  
        }).catch((err) => {
            console.log(err)
        })
  
  
  }

  const SuccessHandleSubmit = async ( mentorId,profileId,serviceId,availabilityId,puropose) => {
  
      
          const selectedData = {
            mentorId: mentorId,
            profileId: profileId,
            serviceId: serviceId,
            availabilityId: availabilityId,
            meetingPurpose: puropose,
          };
          console.log("selected data", selectedData);
          const sendData = await httpsignup.post(
            `/mentor/saveSkillsquadMentorSessionBooking`,
            selectedData
          );
          if (sendData && sendData.data) {
            console.log("response data", sendData);
            setMessage(true)
          }
        
   
  };
  
  
  
  const [Razorpay] = useRazorpay();
  
  const handlePayment = useCallback((payAmount, mentorId,profileId,serviceId,availabilityId,puropose) => {

         httpsignup.post(`/dashBoardLMS/web?amount=${payAmount}&currency=INR&auth=cnpwX2xpdmVfc2NQV296M29PUjZkSWQ6STZ3YW5QTVZVU0I5bjlxOUJ3RmtTZVhh`)
        //httpsignup.post(`/dashBoardLMS/web?amount=${payAmount}&currency=INR&auth=cnpwX3Rlc3RfNVIxNzJVeXhRODVGQnY6V1BkRXNoQ2R2MlExYWg5dTRNWXNwVHJl`)
            .then((response) => {
                const options: RazorpayOptions = {
                    // key: "rzp_live_scPWoz3oOR6dId",
                    key: "rzp_live_scPWoz3oOR6dId",
                    amount: parseInt(payAmount) * 100,
                    currency: "INR",
                    name: "Skillsquad",
                    description: "Test Transaction",
                    image: require("../../images/skillsquad_logo_fav_png.png"),
                    order_id: response.data.orderId,
                    handler: (res) => {
                        console.log("HHHH : " + JSON.stringify(res));
                        SuccessHandleSubmit( mentorId,profileId,serviceId,availabilityId,puropose);
  
                    },
                    prefill: {
                        // sessionStorage.getItem("coursePageName")
                        // sessionStorage.getItem("coursePageEmail")
                        // sessionStorage.getItem("coursePageMobile")
                        name: sessionStorage.getItem("bookingName"),
                        email: sessionStorage.getItem("bookingEmail"),
                        contact: sessionStorage.getItem("bookingMobile"),
                    },
                    notes: {
                        address: "Razorpay Corporate Office",
                    },
                    theme: {
                        color: "#5627ff",
                    },
                };
  
                const rzpay = new Razorpay(options);
                rzpay.open();
  
            }).catch((err) => {
                console.log(err)
            })
        console.log(JSON.stringify(payAmount))
    
  
  }, [Razorpay]);
 // function checkAvailability(){
   // httpsignup.get(`/mentor/checkUserAvailability?profileId=${userProfileData.profileId}&availabilityId=${selectedTime.availabilityId}`)
   //.then((response) => {
    //  if(response.data == "Booked"){
      // setBookErrorMessage(true);
    //  }
     //  console.log("USER DETAILS RESPONSE : " + JSON.stringify(response.data))


   //}).catch((err) => {
       //console.log(err)
  // })
 //}
  return (
    <div>
      {slotBookingLoader ? (
        <SkeletonSlotBooking />
      ) : (
        <div className="main-page">
          <div className="container">
            <div className={`arrow-page`}>
              {/* arrow section */}
              <div
                className="d-flex"
                style={{
                  display: "flex",
                  gap: "6px",
                  cursor: "pointer",
                  marginBottom: "10px",
                  flexDirection: "row",
                }}
              >
                <img
                  style={{ height: "20px", width: "20px" }}
                  src={backArrow}
                  onClick={handleBackmentor}
                />
                <div>About the Plan</div>
              </div>
              <div className="slot-booking-page">
                {/* mentor booking details */}
                <div className="book-mentor-details-section">
                  <div className="m-b-details-section">
                    <div className="profile-m-b">
                      <div>
                        <img
                          src={clickedService?.profilePictureUrl}
                          alt="mentor-profile"
                          className="slot-mentor-profile"
                        />
                      </div>
                      <div className="blur-action-name">
                        {clickedService?.mentorName}
                      </div>
                    </div>
                    <div className="m-b-details">
                      <div className="m-b-plan-details">
                        <div className="plan-name">Plan</div>
                        <div className="plan-value">{clickedService?.plan}</div>
                      </div>
                      <div className="m-b-plan-details">
                        <div className="plan-name">Price</div>
                        <div className="plan-value-price">
                          ₹ {clickedService?.price}
                        </div>
                      </div>
                      <div className="m-b-plan-details">
                        <div className="plan-name">Appointment Date</div>
                        <div className="plan-value">
                          {moment(selectedDate?.availabledate).format(
                            "DD MMM YYYY"
                          )}
                        </div>
                      </div>
                      <div className="m-b-plan-details">
                        <div className="plan-name">Time</div>
                        <div className="plan-value">
                          {selectedTime?.startTime} - {selectedTime?.endTime}
                        </div>
                      </div>
                      <div className="m-b-plan-details">
                        <div className="plan-name">Duration</div>
                        <div className="plan-value">
                          {selectedTime?.duration} Mins
                        </div>
                      </div>
                      <div className="m-b-plan-details">
                        <div className="plan-name">Time Remaining</div>
                        <div className="plan-value-time">{timeLeft}</div>
                      </div>
                    </div>
                  </div>
                  <div className="m-b-about-plan-section">
                    <div className="m-b-about-heading">About the Plan</div>
                    <div className="m-b-about-description">
                      {clickedService?.planDesc}
                    </div>
                  </div>
                </div>
                {/* slot booking card */}
                <div className="booking-purspose-section">
                  {clickedDates === null || clickedDates?.length <= 0 ? (
                    <img src={full} />
                  ) : (
                    <div>
                      <div className="b-a-heading ">
                        Book a {selectedTime?.duration} minute call for just
                        <span> ₹{clickedService?.price}</span>
                      </div>
                      <div className="b-a-select-booking">
                        <div className="" style={{ marginBottom: "6px" }}>
                          Select Date <span style={{ color: "red" }}>*</span>
                        </div>
                        <div className="b-a-slot-all-time">
                          {clickedDates?.map((slot, index) => (
                            <div
                              className={
                                selectedDateBox === index
                                  ? "b-a-date"
                                  : "remaining-dates"
                              }
                              onClick={() =>
                                handleGetTimes(slot.availabledate, slot, index)
                              }
                              style={{ cursor: "pointer" }}
                            >
                              <div className="booked-time-slot-details-mentor">
                                <div className="b-a-t-heading">{slot.day}</div>
                                <div className="b-a-t-date">
                                  {moment(slot.availabledate).format("DD MMM")}
                                </div>

                                <div className="b-a-t-slots">
                                  {slot.availableSlots} Slots
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="b-a-select-booking">
                        <div>
                          Select Time<span style={{ color: "red" }}>*</span>
                        </div>

                        <div className="technologies-wrapper">
                          <MentorTimeSlots
                            mentorTimeSlots={mentorTimeSlots}
                            handleTimeSlots={handleTimeSlots}
                            selectedTimeBox={selectedTimeBox}
                          />
                        </div>
                      </div>
                      <div className="b-a-m-section">
                        <div className="b-a-m-section-heading">
                          Meeting Purpose{" "}
                          <span style={{ color: "red" }}>*</span>
                        </div>
                        <textarea
                          onChange={handlePurposeChange}
                          type="text"
                          className="b-a-m-section-purpose mb-3"
                          placeholder="Describe in 300 words."
                        />
                        {errorMessage && (
                          <span style={{ color: "red", marginBottom: "6px" }}>
                            Please select all the required fields
                          </span>
                        )}
                        <div
                          className={`b-a-m-book-now blue-effect ${
                            disableBookNow ? "disablebooknow-button" : ""
                          }`}
                          onClick={handleSubmit}
                        >
                          Book Now
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className={`popup-overlay ${message ? "open" : ""}`}>
            <div className="popup-content popup-content-message">
              <div className="">
                <div className="s-p">
                  <div className="b-c-h">Booking Confirmed</div>
                  <div className="b-c-p">
                    You've successfully booked a session with{" "}
                    {clickedService?.mentorName}. We're excited to see you
                    elevate your learning journey!
                  </div>
                  <div>
                    <img
                      className="b-c-m-p"
                      src={clickedService?.profilePictureUrl}
                      alt="mentor-profile"
                    />
                  </div>
                  <div className="s-b-m-n"></div>
                  <div className="s-b-r"></div>
                  <div className="s-b-s-d">
                    <div className="d-flex">
                      <img src="" style={{ marginRight: "6px" }} />
                      <span>
                        {moment(selectedDate?.availabledate).format(
                          "DD MMM YYYY"
                        )}
                      </span>
                    </div>
                    -
                    <div className="d-flex">
                      <img src="" style={{ marginRight: "6px" }} />
                      <span>
                        {selectedTime?.startTime} - {selectedTime?.endTime}
                      </span>
                    </div>
                  </div>
                  <div className="text-center">
                    You will receive a calendar invite and an email reminder
                    closer to the session date.
                  </div>
                  <div
                    className="s-m-b blue-effect"
                    onClick={handleBookingspage}
                    style={{ cursor: "pointer" }}
                  >
                    Manage Bookings
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SlotBookingPage;
