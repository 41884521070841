import React from 'react';
import '../../styles/Popup.css';
import { useNavigate } from "react-router-dom";
import link from '../../images/link_icon.png'
import httpsignup from '../../js/http-lms_userprofile';
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useState } from "react";
import { useEffect } from 'react'

const AddLinks = ({ onClose }) => {
    const navigate = useNavigate();
    const [defaultuserDetails, setUserDetails] = useState({})
    //form data
    const [errorMessage, setErrorMessage] = useState("");
    const [formErros, setFormErrors] = useState({});
    const [disable, setDisable] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);
    const [cardHeader, setCardHeader] = useState("Add Links")
    const [cardButton, setCardButton] = useState("Save")
  
    useEffect(() => {
      getLoggedInUserDetails()
    }, [])
    const getLoggedInUserDetails = async () => {
      httpsignup.get(`/skillsquadUserprofileLMS/getSkillsquadUserLinksBySignUpId?signUpId=${sessionStorage.getItem('userId')}`)
        .then((response) => {
          console.log("User Links Details:", JSON.stringify(response.data))
          setUserDetails(response.data)
          if(response.data.githubUrl != null){
            setCardHeader("Edit Links")
            setCardButton("Update")
          }
        }).catch((err) => {
          console.log(err)
        })
    }
  
    // validate data and enable/disable submit btn
    useEffect(() => {
      setFormErrors(validate(defaultuserDetails))
      enableDisableBtn()
    }, [defaultuserDetails])
    function enableDisableBtn() {
      if (defaultuserDetails.githubUrl && defaultuserDetails.twitterUrl && defaultuserDetails.linkedinUrl && defaultuserDetails.websiteUrl) {
        console.log("false")
        // setDisable(false)
      }
      else {
        console.log("true")
        // setDisable(true)
      }
    }
    //updating defaultuserDetails for final submission
    function handleChange(e) {
      setErrorMessage('')
      const { name, value } = e.target;
      setUserDetails((pValue) => {
        console.log(pValue)
        return {
          ...pValue,
          [name]: value
        }
      })
      console.log("after change : ", defaultuserDetails)
    }
    function SubmitFinalData(e) {
      e.preventDefault()
      setFormErrors(validate(defaultuserDetails))
      setIsSubmit(true)
    }
  
  
    useEffect(() => {
      if(Object.keys(formErros).length === 0 && isSubmit){
        httpsignup.put('/skillsquadUserprofileLMS/updateSkillsquadUserLinks', defaultuserDetails)
        .then((response) => {
          console.log("update response : ", response.data)
          setErrorMessage("Updated sucessfully")
          setTimeout(() => {
            setErrorMessage('')
          }, 5000)
          setUserDetails({})
          setIsSubmit(false)
        }).catch((e) => {
          console.log("error update : ", e.response)
        })
      }
    }, [formErros])
    // final submission
    // function SubmitFinalData() {
  
    //   httpsignup.put('/skillsquadUserprofileLMS/updateSkillsquadUserLinks', defaultuserDetails)
    //     .then((response) => {
    //       console.log("update response : ", response.data)
    //       setErrorMessage("Updated sucessfully")
    //       setTimeout(() => {
    //         setErrorMessage('')
    //       }, 5000)
    //     }).catch((e) => {
    //       console.log("error update : ", e.response)
    //     })
  
    //   //navigate("/activity")
    // }
    const validate = (values) => {
      const errors = {};
      const allowaplaspaces = /^[a-zA-Z ]*$/;
      // if (!values.githubUrl) {
      //   errors.githubUrl = "Github Url is required!"
      // } else if (!allowaplaspaces.test(values.githubUrl)) {
      //   errors.githubUrl = "First name should contain alphabets and spaces"
      // }
      // if (!values.twitterUrl) {
      //   errors.twitterUrl = "Twitter Url is required!"
      // }
      // if (!values.linkedinUrl) {
      //   errors.linkedinUrl = "Linked Url is required!"
      // }
      // if (!values.websiteUrl) {
      //   errors.websiteUrl = "Website Url is required!"
      // }
      return errors;
    }
  
  
  return (
    <div className="profile-popup-container">
      <div className="profile-popup">
      <div style = {{textAlign: "right", color:"black",cursor:"pointer"}} class="mb-3"> <div  onClick={onClose}>X</div></div>
        <h2 class="d-flex common_main_text_highlight"><img src={link} alt="link" width={30} style={{marginRight: 10}}/>{cardHeader}</h2>
        <p class="common_main_text_normal">Let’s get started with some personal details</p>
        <p class="mt-2 common_main_text_normal">Your online profiles</p>
        <center><p style={{font: 18,fontWeight: 600, color: "green"}}>{errorMessage}</p></center> 
        <Form id="create-User-Form">
                  <Row className="pb-3">
                  <Col className="col-lg-6 col-12 d-flex flex-column align-items-start">
                  <div className="d-flex align-items-center pb-1" ><img class="img-fluid"
                            src={require("../../images/website.png")}
                            alt="Image not found"
                            className="more"
                            width={20}

                          /> <h6 style={{fontWeight: 600,marginLeft: 5}}>Personal Website</h6></div>
                      <Form.Control
                        className={formErros.websiteUrl ? "borderred" : ""}
                        placeholder="https://example.com"
                        autoComplete="off"
                        name='websiteUrl'
                        defaultValue={defaultuserDetails.websiteUrl}
                        onChange={handleChange}
                      />
                      <p className="red font12">{formErros.websiteUrl}</p>
                    </Col>
                    <Col className="col-lg-6 col-12 d-flex flex-column align-items-start">
                      <div className="d-flex  align-items-center pb-1"> 
                      <img class="img-fluid"
                            src={require("../../images/linkedin.png")}
                            alt="Image not found"
                            className="more"
                            width={20}

                          />
                      <h6 style={{fontWeight: 600,marginLeft: 5}}>LinkedIn</h6></div>
                      <Form.Control
                        className={formErros.linkedinUrl ? "borderred" : ""}
                        placeholder="https://www.linkedin.com/username/"
                        autoComplete="off"
                        name='linkedinUrl'
                        defaultValue={defaultuserDetails.linkedinUrl}
                        onChange={handleChange}
                      />
                      <p className="red font12">{formErros.linkedinUrl}</p>
                    </Col>
                    
                  
                    
                  </Row>
                  <Row className="pb-3">
                  <Col className="col-lg-6 col-12 d-flex flex-column align-items-start">
                      <div className="d-flex  align-items-center pb-1">
                      <img class="img-fluid"
                            src={require("../../images/github.png")}
                            alt="Image not found"
                            className="more"
                            width={20}

                          />
                        <h6 style={{fontWeight: 600,marginLeft: 5}}>Github</h6></div>
                      <Form.Control
                        className={formErros.githubUrl ? "borderred" : ""}
                        placeholder="https://github.com/username"
                        autoComplete="off"
                        name='githubUrl'
                        defaultValue={defaultuserDetails.githubUrl}
                        onChange={handleChange}
                      />
                      <p className="red font12">{formErros.githubUrl}</p>
                    </Col>

                    <Col className="col-lg-6 col-12 d-flex flex-column align-items-start mt-lg-0 mt-3">
                      <div className="d-flex  align-items-center pb-1"> 
                      <img class="img-fluid"
                            src={require("../../images/x_icon.png")}
                            alt="Image not found"
                            className="more"
                            width={20}

                          />
                      <h6 style={{fontWeight: 600,marginLeft: 5}}>X</h6></div>
                      <Form.Control
                        className={formErros.twitterUrl ? "borderred" : ""}
                        placeholder="https://twitter.com/username"
                        autoComplete="off"
                        name='twitterUrl'
                        defaultValue={defaultuserDetails.twitterUrl}
                        onChange={handleChange}
                      />
                      <p className="red font12">{formErros.twitterUrl}</p>
                    </Col>

                    {/* <Col className="col-lg-6 col-12 d-flex flex-column align-items-start">
                      <div className="d-flex required align-items-center pb-1">
                      <img class="img-fluid"
                            src={require("../../images/gitlab_icon.png")}
                            alt="Image not found"
                            className="more"
                            width={20}

                          />
                        <h6 style={{fontWeight: 600,marginLeft: 5}}>GitLab</h6></div>
                      <Form.Control
                        className={formErros.githubUrl ? "borderred" : ""}
                        placeholder="https://github.com/username"
                        autoComplete="off"
                        name='githubUrl'
                        defaultValue={defaultuserDetails.githubUrl}
                        onChange={handleChange}
                      />
                      <p className="red font12">{formErros.githubUrl}</p>
                    </Col> */}


                  </Row>
                  {/* <Row className="pb-3">
                  <Col className="col-lg-6 col-12 d-flex flex-column align-items-start">
                      <div className="d-flex required align-items-center pb-1">
                      <img class="img-fluid"
                            src={require("../../images/dribbble_icon.png")}
                            alt="Image not found"
                            className="more"
                            width={20}

                          />
                        <h6 style={{fontWeight: 600,marginLeft: 5}}>Dribbble</h6></div>
                      <Form.Control
                        className={formErros.githubUrl ? "borderred" : ""}
                        placeholder="https://github.com/username"
                        autoComplete="off"
                        name='githubUrl'
                        defaultValue={defaultuserDetails.githubUrl}
                        onChange={handleChange}
                      />
                      <p className="red font12">{formErros.githubUrl}</p>
                    </Col>

                    <Col className="col-lg-6 col-12 d-flex flex-column align-items-start">
                      <div className="d-flex required align-items-center pb-1">
                      <img class="img-fluid"
                            src={require("../../images/behance_icon.png")}
                            alt="Image not found"
                            className="more"
                            width={20}

                          />
                        <h6 style={{fontWeight: 600,marginLeft: 5}}>Behance</h6></div>
                      <Form.Control
                        className={formErros.githubUrl ? "borderred" : ""}
                        placeholder="https://github.com/username"
                        autoComplete="off"
                        name='githubUrl'
                        defaultValue={defaultuserDetails.githubUrl}
                        onChange={handleChange}
                      />
                      <p className="red font12">{formErros.githubUrl}</p>
                    </Col>


                  </Row>
                  <Row className="pb-3">
                  <Col className="col-lg-6 col-12 d-flex flex-column align-items-start mt-lg-0 mt-3">
                      <div className="d-flex required align-items-center pb-1"> 
                      <img class="img-fluid"
                            src={require("../../images/stackoveflow_icon.png")}
                            alt="Image not found"
                            className="more"
                            width={20}

                          />
                      <h6 style={{fontWeight: 600,marginLeft: 5}}>StackOverflow</h6></div>
                      <Form.Control
                        className={formErros.twitterUrl ? "borderred" : ""}
                        placeholder="https://twitter.com/username"
                        autoComplete="off"
                        name='twitterUrl'
                        defaultValue={defaultuserDetails.twitterUrl}
                        onChange={handleChange}
                      />
                      <p className="red font12">{formErros.twitterUrl}</p>
                    </Col>
                  <Col className="col-lg-6 col-12 d-flex flex-column align-items-start mt-lg-0 mt-3">
                      <div className="d-flex required align-items-center pb-1"> 
                      <img class="img-fluid"
                            src={require("../../images/x_icon.png")}
                            alt="Image not found"
                            className="more"
                            width={20}

                          />
                      <h6 style={{fontWeight: 600,marginLeft: 5}}>X</h6></div>
                      <Form.Control
                        className={formErros.twitterUrl ? "borderred" : ""}
                        placeholder="https://twitter.com/username"
                        autoComplete="off"
                        name='twitterUrl'
                        defaultValue={defaultuserDetails.twitterUrl}
                        onChange={handleChange}
                      />
                      <p className="red font12">{formErros.twitterUrl}</p>
                    </Col>
                  </Row> */}

                  <div>
                    <Row >
                      
                      <Col className="col-lg-2 col-12 d-flex flex-column align-items-start mt-lg-0 mt-3">
                        <button disabled={disable} className={disable ? "profile-buttons-update mt-3 mb-5 grey" : "profile-buttons-update mt-3 mb-5 common_main_button"} onClick={SubmitFinalData}>
                        {cardButton}
                        </button>
                      </Col>
                     

                    </Row>
                  </div>
                </Form>
        {/* <div class="activityButton" onClick={allcourses} style={{width: "100%", textAlign:"center"}}>See More Courses</div> */}
        
      </div>
    </div>
  );
};

export default AddLinks;