
import React, { useState } from "react";
import PropTypes from "prop-types";
import { Container, Menu } from "semantic-ui-react";
import { useEffect } from "react";
import httpskillsquaduserprofile from "../../js/http-lms_userprofile";

import Stats from "./Stats";
import QNA from "./QNA";
import "../../styles/Resultpage.css";

const Result = ({
  totalQuestions,
  correctAnswers,
  timeTaken,
  questionsAndAnswers,
  replayQuiz,
  resetQuiz,
  taskCompleted,
  quitQuiz,
  topicId,
}) => {
  const [activeTab, setActiveTab] = useState("Result");
  // console.log(topicId);

  const handleTabClick = (e, { name }) => {
    setActiveTab(name);
  };

  function saveQuiz() {
    httpskillsquaduserprofile
      .post(
        `/programsLMS/saveQuizAnswers?profileId=${sessionStorage.getItem(
          "dayProfileId"
        )}&programId=${sessionStorage.getItem(
          "dayProgramId"
        )}&quizCode=${topicId}`,
        questionsAndAnswers
      )
      .then((response) => {})
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    httpskillsquaduserprofile
      .put(
        `/programsLMS/updateScheduleDayChallege?profileId=${sessionStorage.getItem(
          "dayProfileId"
        )}&videoCount=${taskCompleted}&programId=${sessionStorage.getItem(
          "dayProgramId"
        )}`
      )
      .then((response) => {
        console.log("video unlock count updated:", response.data);
        saveQuiz();
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <Container className="result-container">
      
        <Menu className="tabs">
          <Menu.Item
            className="result"
            name="Result"
            active={activeTab === "Result"}
            onClick={handleTabClick}
            style={{
              backgroundColor: activeTab === "Result" ? "blue" : "",
              color: activeTab === "Result" ? "white" : "black",
              borderRadius: "10px",
              borderRight: "none",
            }}
          />
          <Menu.Item
            className="review"
            name="Review"
            active={activeTab === "Review"}
            onClick={handleTabClick}
            style={{
              backgroundColor: activeTab === "Review" ? "blue" : "",
              color: activeTab === "Review" ? "white" : "black",
              borderRadius: "10px",
              borderRight: "none",
            }}
          />
        </Menu>
      
      {activeTab === "Result" && (
        <Stats
          totalQuestions={totalQuestions}
          correctAnswers={correctAnswers}
          timeTaken={timeTaken}
          replayQuiz={replayQuiz}
          resetQuiz={resetQuiz}
          quitQuiz={quitQuiz}
        />
      )}
      {activeTab === "Review" && <QNA questionsAndAnswers={questionsAndAnswers} topicId={topicId}/>}
    </Container>
  );
};

Result.propTypes = {
  totalQuestions: PropTypes.number.isRequired,
  correctAnswers: PropTypes.number.isRequired,
  timeTaken: PropTypes.number.isRequired,
  questionsAndAnswers: PropTypes.array.isRequired,
  replayQuiz: PropTypes.func.isRequired,
  resetQuiz: PropTypes.func.isRequired,
  quitQuiz: PropTypes.func.isRequired,
  topicId: PropTypes.string.isRequired,
};

export default Result;

