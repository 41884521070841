import React, { useEffect, useState } from "react";
import clock from "../../images/Group 1000003649.svg";
import moment from "moment";
import SlotBookingPage from "./SlotBookingPage";
import httpsignup from "../../js/http-lms_userprofile";
import { useNavigate } from "react-router-dom";
import { usePopup } from "../../components/PopupContext";

const MentorUserServices = ({ mentorServices = [],updateInfo,admin,fromMentor }) => {
  const navigate = useNavigate();
  const { getMentorClickedService, getMentorInfo, mentorInfo } = usePopup();
  console.log("mentordetails in booking call", mentorInfo);
  console.log();
  useEffect(() => {
    getMentorInfo();
  }, [mentorInfo?.mentorId]);
  const mentorClickedService = async (serviceId,unique) => {
    if(admin){
      navigate("/sign-up")
    }
    else if (mentorInfo?.mentorId) {
      getMentorClickedService(serviceId, mentorInfo);
      navigate(`/${sessionStorage.getItem('munique')}/${unique}`);
    }
  };
  const reduceService = (service) => {
    if (service.length >= 25) {
      const red = service.substring(0, 25);
      return red + "...";
    }
    return service;
  };
  return (
    <div className="all-services-cards">
      {mentorServices.map((service, index) => (
        <div className="user-service-card">
          <div className="user-c-heading">
            {reduceService(service.serviceName)}
          </div>
          <div className="user-c-time-and-price">
            <img src={clock} alt="clock" />
            <div className="minutes-user">{service.serviceDuration} Mins</div>
            <div className="user-s-border"></div>
            <div className="user-c-price">₹ {service.sessionAmount}</div>
          </div>
          <div className="services-border"></div>
          <div className="user-profile-booking">
            <div className="mm-avail">
              <div className="mm-available">Available on</div>
              <div className="mm-available-date">
                {moment(service.sessionDate).format("DD MMM YY")},{" "}
                {service.startTime}
              </div>
            </div>
            <div
              disabled={admin}
              className={`service-book-now-mentor ${(!admin && !fromMentor) ? "servicedisable" :""}`}
              onClick={() => mentorClickedService(service.serviceId,service.uniqueServiceId)}
            >
              Book Now
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default MentorUserServices;
