import React, { createContext, useContext, useState, useEffect } from "react";
import httpsignup from "../js/http-lms_userprofile";
import { useParams } from "react-router-dom";
import axios from "axios";

const PopupContext = createContext();

export const usePopup = () => {
  return useContext(PopupContext);
};

export const PopupProvider = ({ children }) => {
  const [isFirstPopupOpen, setIsFirstPopupOpen] = useState(false);
  const [isSecondPopupOpen, setIsSecondPopupOpen] = useState(false);
  const [isInitialPop, setInitialPop] = useState(false);
  const [popupData, setPopupData] = useState({});
  const [experiencesData, setExperiences] = useState([]);
  const [userProfileData, setUserProfile] = useState({});

  const [adminProfile, setAdminProfile] = useState(false);
  const [mentorInfo, setMentorInfo] = useState(null);
  const [hideServiceCarousel, setHideServiceCarousel] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [clickedService, setClickedService] = useState(null);
  const [clickedDates, setClickedDates] = useState(null);
  const [selectedServiceId, setSelectedServiceId] = useState(null);

  const [catagories, setCatagories] = useState(null);
  const [services, setServices] = useState([]);

  const [loading, setLoading] = useState(true);
  const [offerId, setOfferId] = useState(null);
  const [search, setSearch] = useState("null");
  const [mentorHomeInfo, setMentorHomeInfo] = useState(null);
  const [updateInfo, setUpdateIndo] = useState(false);
  const [mentorsList, setAllMentorsList] = useState(
    Array.from({ length: 4 }, () => [])
  );

  const [updateSearch, setUpdateSearch] = useState(false);
  const [allMentors, setAllMentors] = useState([]);
  const [selectedIds, setSelectedIds] = useState({
    cat: null,
    offer: null,
  });
  const [selectedTechnology, setSelectedTechnology] = useState("");
  const [slotBookingLoader, setSlotBookingLoader] = useState(true);
  const [updateCat, setUpdateCat] = useState("");
  const [newOfferName, setNewOfferName] = useState("");
  

  const getIndividualServices = async (id) => {
    const response = await httpsignup.get(
      `/mentor/getSkillsquadMentorServiceByUniqueServiceId?uniqueServiceId=${id}`
    );
    if (response && response.data) {
      getMentorClickedService(response.data.serviceId, response.data);
      sessionStorage.setItem("m-umentorid", response.data.mentorId);
    }
  };
  const getMentorClickedService = async (serviceId, mentorDetails) => {
    console.log("mentor info from booking call in context", mentorDetails);
    setSlotBookingLoader(true);
    setSelectedServiceId(serviceId);
    const profileResponse = await httpsignup.get(
      `/mentor/getMentorServiceById?serviceId=${serviceId}`
    );
    if (profileResponse && profileResponse.data) {
      setClickedService(profileResponse.data);
      console.log("click service", profileResponse.data);
    }
    const datesResponse = await httpsignup.get(
      `/mentor/getSessionDates?mentorId=${mentorDetails?.mentorId}&serviceId=${serviceId}`
    );
    if (datesResponse && datesResponse.data) {
      setClickedDates(datesResponse.data);
      setSlotBookingLoader(false);
    }
  };
  const getId = async (cat, offer) => {
    const response = await httpsignup.get(
      `/mentor/getCategoryOfferIds?categoryName=${cat}&offerName=${offer}`
    );
    if (response && response.data) {
      setSelectedIds((prev) => ({
        ...prev,
        cat: response.data.categoryId,
        offer: response.data.offerId,
      }));
      getAllMentorsListByCat(response.data.categoryId, cat);
    }
  };
  const getIdService = async (cat, offer) => {
    const response = await httpsignup.get(
      `/mentor/getCategoryOfferIds?categoryName=${cat}&offerName=${offer}`
    );
    if (response && response.data) {
      setSelectedIds((prev) => ({
        ...prev,
        cat: response.data.categoryId,
        offer: response.data.offerId,
      }));

      getAllMentorsList(response.data.offerId);
    }
  };
  const getCatagories = async () => {
    try {
      const response = await httpsignup.get(
        `/mentor/getSkillsquadMentorCategoryMasterListForSearch?mentorId=${mentorInfo?.mentorId}
      `
      );
      if (response && response.data) {
        setCatagories(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getmentorListByLanguage = async (id) => {
    setLoading(true);
    try {
      const response = await httpsignup.get(
        `/mentor/getAllMentorsForLanguage?languageIds=${id}
      `
      );
      if (response && response.data) {
        setAllMentorsList(response.data);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const getServiceByCat = async (id, cat) => {
    try {
      const response = await httpsignup.get(
        `/mentor/getSkillsquadMentorOfferMasterListForSearch?masterId=${id}
      `
      );
      if (response && response.data) {
        getIdService(cat, response.data[0].offerName);
        setServices(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getAllMentorsListByCat = async (id, cat) => {
    setLoading(true);
    try {
      const response = await httpsignup.get(
        `/mentor/getAllMentorsForCategory?masterId=${id}`
      );
      if (response && response.data) {
        console.log("all mentors", response.data);

        getServiceByCat(id, cat);
        setAllMentorsList(response.data);
        setAllMentors(response.data);
        if (id == 17) {
          setLoading(false);
        }
        // setLoading(false)
      }
    } catch (error) {
      setLoading(false);
      console.log("mentors list error", error);
    }
  };

  const getAllMentorsList = async (id) => {
    try {
      const response = await httpsignup.get(
        `/mentor/getAllMentors?offerId=${id}&search=${search}`
      );
      if (response && response.data) {
        console.log("all mentors", response.data);
        
        setAllMentorsList(response.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log("mentors list error", error);
    }
  };
  const getMentorInfo = async () => {
    try {
      const response = await httpsignup.get(
        `/mentor/getSkillsquadMentorApplicationsByProfileId?profileId=${sessionStorage.getItem(
          "m-profileid"
        )}`
      );
      if (response && response.data) {
        setMentorInfo(response.data);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const getUserProfileExperience = async () => {
    try {
      const response = await httpsignup.get(
        `/skillsquadUserprofileLMS/getSkillsquadUserExperienceBySignUpId?signUpId=${sessionStorage.getItem(
          "userId"
        )}`
      );
      const data = response.data;
      setExperiences(data);
    } catch (error) {
      console.error("Failed to fetch user profile experience data:", error);
    }
  };

  const getLoggedInUserDetails = async () => {
    try {
      const response = await httpsignup.get(
        `/skillsquadUserprofileLMS/getSkillsquadUserProfileBySignUpId?signUpId=${sessionStorage.getItem(
          "userId"
        )}`
      );
      const data = response.data;
      setUserProfile(data);
    } catch (error) {
      console.error("Failed to fetch logged-in user details:", error);
    }
  };

  const getMentorHomeInfo = async () => {
    try {
      const redirectChange = await httpsignup.get(
        `/mentor/getMentorHomeInfo?mentorId=${mentorInfo?.mentorId}`
      );
      if (redirectChange && redirectChange.data) {
        setMentorHomeInfo(redirectChange.data);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    if (mentorHomeInfo) {
      const Info = Object.values(mentorHomeInfo).every(
        (value) => value === "Y"
      );
      setUpdateIndo(Info);
    }
    console.log("update info in context", updateInfo, mentorHomeInfo);
  }, [mentorHomeInfo, updateInfo, mentorInfo?.mentorId]);

  const updateAdmin = () => {
    setAdminProfile(true);
  };
  useEffect(() => {
    getUserProfileExperience();
  }, []);

  const openFirstPopup = async () => {
    setIsFirstPopupOpen(true);
  };

  const openSecondPopup = async () => {
    setIsSecondPopupOpen(true);
  };
  const hideNavButtons = () => {
    setInitialPop(!isInitialPop);
  };
  const hideCarousel = () => {
    setHideServiceCarousel(!hideServiceCarousel);
  };
  const closeFirstPopup = () => {
    console.log("first popup is triggering close");
    setIsFirstPopupOpen(false);
  };

  const closeSecondPopup = () => {
    setIsSecondPopupOpen(false);
  };
  const setMentorLoading = () => {
    setLoading(true);
  };
  const stopMentorLoading = () => {
    setLoading(false);
  };
  const updateSearchBar = (value) => {
    setUpdateSearch(value);
  };
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const updateTech = (name) => {
    setSelectedTechnology(name);
  };
  const updateIds = () => {
    setServices(null);
    setSelectedIds((prev) => ({
      ...prev,
      cat: 17,
      offer: null,
    }));
    getAllMentorsListByCat(17, "");
  };

  const updateMentorList = (data) => {
    setAllMentorsList(data);
  };
  const getOffferName = (offer) => {
    setNewOfferName(offer);
  };
  return (
    <PopupContext.Provider
      value={{
        getmentorListByLanguage,
        getOffferName,
        updateMentorList,
        updateIds,
        updateCat,
        setUpdateCat,
        updateTech,
        selectedTechnology,
        activeTab,
        handleTabClick,
        slotBookingLoader,
        getIndividualServices,
        getIdService,
        selectedIds,
        getId,
        updateAdmin,
        updateSearchBar,
        updateSearch,
        setMentorLoading,
        stopMentorLoading,
        adminProfile,
        hideNavButtons,
        isFirstPopupOpen,
        isSecondPopupOpen,
        popupData,
        experiencesData,
        userProfileData,
        openFirstPopup,
        openSecondPopup,
        closeFirstPopup,
        closeSecondPopup,
        getMentorClickedService,
        clickedService,
        clickedDates,
        mentorInfo,
        selectedServiceId,
        getMentorInfo,
        getLoggedInUserDetails,
        getCatagories,
        getServiceByCat,
        catagories,
        services,
        getAllMentorsList,
        mentorsList,
        loading,
        getAllMentorsListByCat,
        isInitialPop,
        hideCarousel,
        hideServiceCarousel,
        updateInfo,
        getMentorHomeInfo,
        allMentors,
      }}
    >
      {children}
    </PopupContext.Provider>
  );
};
