import React, { useState } from 'react';
import ProgressBar from "react-bootstrap/ProgressBar";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock, faLockOpen, faCircleCheck } from '@fortawesome/free-solid-svg-icons';

const FAQAccordion = ({ title, content,num }) => {
    const [isActive, setIsActive] = useState(false);

    return (
        <div>
            <div   onClick={() => setIsActive(!isActive)}>
                <div class="container align-items-center faq-transition"><div class="row "><div class="col-1 common_main_text_highlight" style={{color:"#5627FF"}}>{num+1}.</div><div class="col d-flex align-items-center"><div>{title}</div></div>
                    
                    
                    <div class="col-1" style={{ textAlign: "right" }}>{isActive ? <img className='faq-transition'  width={20} src={require("../images/minus.png")} alt="arrow" /> :  <img className='faq-transition'  width={20} src={require("../images/add.png")} alt="arrow" /> }  </div>

                </div></div>
                <hr></hr>
            </div>
            <div>
                

                 <ul  style={{ padding: 0 }}>

                    {isActive && <div style={{ borderBottomLeftRadius: 5, borderBottomRightRadius: 5, padding: "2%" }}>

                        <li class="list-group-item " style={{ backgroundColor: "transparent" }}>
                            <div class="row faq-transition">
                                <div class="col-1 faq-transition"></div>
                                <div class="col common_main_text_normal faq-transition"> {content.answer}</div>
                                     </div>
                                     <div class="col-1"></div>

                        </li>


                    </div>}

                </ul>
            </div>

        </div>
    );
};

export default FAQAccordion;