
import '../styles/LockedPopup.css';
import React, { useEffect, useState } from "react";
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Marquee from "react-fast-marquee"
import { useNavigate } from "react-router-dom";
import star from "../images/star.png";
import httpsignup from '../js/http-lms_userprofile';
import ConfettiExplosion from 'react-confetti-explosion';
import Sidebar from "../pages/Sidebar";



const DayChallengeCompleteStatus = ({ onClose, unlockPop }) => {
  const navigate = useNavigate();
  function allcourses() {
    navigate("/dashboard")
  }
  function myCourses() {
    navigate("/my-course")
  }
  const getLoggedInUserDetails = async () => {
    console.log("sesion " + sessionStorage.getItem('userId'))
    httpsignup.get(`/skillsquadUserprofileLMS/getSkillsquadUserProfileBySignUpId?signUpId=${sessionStorage.getItem('userId')}`)
      .then((response) => {
        setUserType(response.data.userTypeMasterId)

      }).catch((err) => {
        console.log(err)
      })
  }

  const getQuote = async () => {
    console.log("sesion " + sessionStorage.getItem('userId'))
    httpsignup.get(`/programsLMS/getRandomQuote`)
      .then((response) => {
        setQuote(response.data)

      }).catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    getLoggedInUserDetails()
    getQuote()
  }, [])
  const [userType, setUserType] = useState(0);
  const [quote, setQuote] = useState();
  const [isExploding, setIsExploding] = React.useState(true);

  function dayChallengeComplate(id) {
    sessionStorage.setItem("dayId", id)
    navigate("/day-challenge-complete")
  }

  function dayChalleneg() {
    sessionStorage.setItem("continueLearning", "Yes")
    navigate("/day-challenge")
  }

  
  return (

    <>
      <Sidebar />
    <div className="jobs-dashboard-container">

        {/* <Navb /> */}

        <div className="px-md-5  mt-5">

          <section class="section">
            <div class="container">

              <div class="row ">

                <div class="col-lg-12">
                  <div class="row">
                    <div class="">
                      <div class="mb-4">
                        <div className="col-lg-12">
                          <div className="row" >
                          <div >
                          <div class="m-5 day_complete" ><div class="common_main_heading mb-3 mt-3">Well Done</div>
                         <center> <div>{isExploding && <ConfettiExplosion />}</div></center>
       <div class="common_main_text_highlight mb-3">Today Schedule is successfully Completed</div>
       { sessionStorage.getItem("daylock") === 'lock' ? <center><div class="day_complete_unlock common_main_text_normal mb-3">Next schedule unlock <span class="common_main_text_highlight">tomorrow at 10 am</span></div></center> : <></> }
      
      <center><div style={{width : "60%"}} class="common_main_heading mb-5">{quote}</div></center> 
    <center clas="mb-3">  <div class="container">
  <div class="row">
  <div class="col">
    <div class="common_main_button_black mb-3" style={{width : "70%"}} on onClick={() => {dayChallengeComplate(sessionStorage.getItem("dayCompleteId"))}}>Review Today schedule</div>
    </div>
    { sessionStorage.getItem("daylock") === 'lock' ?   <></> : <div class="col"> <div class="common_main_button mb-3" style={{width : "70%",backgroundColor: "white", color: "black"}} onClick={dayChalleneg}>Continue next day learning</div></div>}
    
   
  </div>
 
</div></center>
       </div> 
</div>
        
      



        </div>
                        </div></div></div></div></div></div></div>

          </section>

        </div>
      </div>
      </>
  );
};

export default DayChallengeCompleteStatus;