import React,{ useEffect } from 'react';
import '../styles/Popup.css';
import ConfettiExplosion from 'react-confetti-explosion';
import greenB  from "../images/green_button.png";
import wrongB  from "../images/wrong_icon.png";
import ComparisonSlider from "./ComparisonSlider";
import topImage from "../images/Logo.png";
import bottomImage from "../images/Full-Stack-Developer.png"
import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider';

const AssignmentStatusPopup = (props) => {
    const [isExploding, setIsExploding] = React.useState(false);
   

    useEffect(() => {
        if(props.statusName === 'Accepted'){
            setIsExploding(true);
        }
      }, [])

  return (
    <div className="popup-container mt-5">
        <div>{isExploding && <ConfettiExplosion />}</div>
      <div className="profile-popup  mt-4" style={{width: "70%"}}>
      <div style = {{textAlign: "right", width: '100%',cursor:"pointer"}} class="mb-1"> <div  onClick={props.onClose}><strong>X</strong></div></div>
      {props.statusName === 'Accepted' ? <div>{props.expectedOutput.includes("*") ? <div> {props.finalOutput.trim() === props.expectedOutput.trim() ?
    <div>
    <div class="d-flex align-items-center mb-3" style={{fontSize: 20,fontFamily: 700}}>
        <div>{props.program}</div><img src={greenB} alt="correct" width={25} height={25}/></div>
   <div class="mb-3" style={{fontSize: 20,fontFamily: 700}}>Expected Output : {props.expectedOutput.split(',').map((i,key) => {
            return <div key={key}>{i}</div>;
        })}</div>
   <div class="mb-3" style={{fontSize: 20,fontFamily: 700}}>Your Output : {props.finalOutput.split(',').map((i,key) => {
            return <div key={key}>{i}</div>;
        })}</div>
   <div class="mb-3" style={{fontSize: 20,fontFamily: 700}}>Note: Please Submit Your code to complete your challenge</div>
   </div> :
   <div>
   <div class="d-flex align-items-center mb-3" style={{fontSize: 20,fontFamily: 700}}>
       <div>{props.program}</div><img src={wrongB} alt="correct" width={25} height={25}/></div>
       <div style={{color: 'red',fontFamily: 700, fontSize: 18}}>Test Case Failed</div>
  <div class="mb-3" style={{fontSize: 20,fontFamily: 700}}>Expected Output :  {props.expectedOutput.split(',').map((i,key) => {
            return <div key={key}>{i}</div>;
        })}</div>
  <div class="mb-3" style={{fontSize: 20,fontFamily: 700}}>Your Output :  {props.finalOutput.split(',').map((i,key) => {
            return <div key={key}>{i}</div>;
        })}</div></div>
    
    } </div> : <div> {props.finalOutput.trim() === props.expectedOutput.trim() ?
      <div>
      <div class="d-flex align-items-center mb-3" style={{fontSize: 20,fontFamily: 700}}>
          <div>{props.program}</div><img src={greenB} alt="correct" width={25} height={25}/></div>
     <div class="mb-3" style={{fontSize: 20,fontFamily: 700}}>Expected Output : {props.expectedOutput}</div>
     <div class="mb-3" style={{fontSize: 20,fontFamily: 700}}>Your Output : {props.finalOutput}</div>
     <div class="mb-3" style={{fontSize: 20,fontFamily: 700}}>Note: Please Submit Your code to complete your challenge</div>
     </div> :
     <div>
     <div class="d-flex align-items-center mb-3" style={{fontSize: 20,fontFamily: 700}}>
         <div>{props.program}</div><img src={wrongB} alt="correct" width={25} height={25}/></div>
         <div style={{color: 'red',fontFamily: 700, fontSize: 18}}>Test Case Failed</div>
    <div class="mb-3" style={{fontSize: 20,fontFamily: 700}}>Expected Output : {props.expectedOutput} </div>
    <div class="mb-3" style={{fontSize: 20,fontFamily: 700}}>Your Output : {props.finalOutput} </div></div>
      
      } </div> }</div>:
        <div><div class="d-flex align-items-center"><div>{props.program}</div><img src={wrongB} alt="correct" width={25} height={25}/></div>
       <div style={{color: 'red',fontFamily: 700, fontSize: 18}}>{props.statusName}</div>
       <div>{props.finalOutput}</div></div>}
      
      </div>
    </div>
  );
};

export default AssignmentStatusPopup;