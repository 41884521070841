import React from "react";
import Sidebar from "../pages/Sidebar";
import "../styles/DayChallenge.css";
import "../fonts/Roboto/Roboto-Black.ttf";
import { useState, useEffect } from "react";
import Accordion from "./Accordion";
import { Navigate, useNavigate } from "react-router-dom";
import DatePicker from "react-horizontal-datepicker";
import {
    CircularProgressbar,
    CircularProgressbarWithChildren,
    buildStyles
} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import greenB from "../images/green_button.png";
import arrow from "../images/thin_blue_arrow_icon.png";
import book from "../images/writing_icon.png";
import clock from "../images/clock_icon.png";
import httpsignup from '../js/http-lms_userprofile';
import greyB from "../images/grey_correct_icon.png";
import blackL from "../images/black_lock_icon.png";
import code from "../images/code_icon.png"
import App from "../components/App";
import CompletedQuiz from "../pages/MCQCompleted";
import ProgressBar from "react-bootstrap/ProgressBar";
import Popup from "../components/ChallengeComplete";
import RightSidebar from "../pages/RightSidebar";
import arrowL from "../images/left-arrow_icon.png"
import resultB from "../images/result_icon.png"



const DayCircullum = () => {


    const [allDayCirriculumList, setAllDayCirriculumList] = useState([])
    const navigate = useNavigate();
    const [profileId, setProfileId] = useState();
    const [showCirriculum, setShowCirrriculum] = useState(true)
    const [topicId, setTopicId] = useState()
    const [openQuiz, setOpenQuiz] = useState(false);
    const [eligible, setEligible] = useState("");
    const [freeTrail, setFreeTrail] = useState(null)
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [unlockPop, setUnlockPop] = useState();




    //API call when page loader
    useEffect(() => {

        getLoggedInUserDetails();


    }, [])

    function getLoggedInUserDetails() {
        console.log("TRIGGERED LOGGED")
        httpsignup.get(`/skillsquadUserprofileLMS/getSkillsquadUserProfileBySignUpId?signUpId=${sessionStorage.getItem('userId')}`)
            .then((response) => {
                if(response.data.lmsProfileId === null){
                    httpsignup.get(`/programsLMS/getCourseDayCircullum?programId=${sessionStorage.getItem("myCourseProgramId")}`)
                    .then((response) => {
                        console.log('RESPONSE : ' + JSON.stringify(response.data))
                       
                        setFreeTrail('Y')
                        setAllDayCirriculumList(response.data)
                    }).catch((err) => {
                        console.log(err)
                    })
                }
                else{
                sessionStorage.setItem("cirriculumTopicId", 'No')
                httpsignup.get(`/programsLMS/getDayCircullum?programId=${sessionStorage.getItem("myCourseProgramId")}&profileId=${response.data.lmsProfileId}`)
                    .then((response) => {
                        console.log('RESPONSE : ' + JSON.stringify(response.data))
                        setAllDayCirriculumList(response.data)
                    }).catch((err) => {
                        console.log(err)
                    })

                    httpsignup.get(`/programsLMS/checkCirriculmEligibility?profileId=${response.data.lmsProfileId}&programId=${sessionStorage.getItem("myCourseProgramId")}`)
                    .then((response) => {
                       
                        setEligible(response.data)
                        //setAllDayCirriculumList(response.data)
                    }).catch((err) => {
                        console.log(err)
                    })
                    }

            }).catch((err) => {
                console.log(err)
            })


    }

    function endFreeTrail(){
        openPopup()
        setUnlockPop(true)
    }

    function myCourse() {
        navigate("/day-challenge")
    }

    function hideCircullum(id) {
        setShowCirrriculum(false)
        setTopicId(id)

    }

    function quitQuiz() {
        setShowCirrriculum(true)



    }

    const openPopup = () => {
        setIsPopupOpen(true);
      };
    
      const closePopup = () => {
        setIsPopupOpen(false);
      };

    return (
        <>
            <Sidebar />


            <div className="jobs-dashboard-container">

                {/* <Navb /> */}

                <div className="px-md-5  mt-5">

                    <section class="section">
                        <div class="container">

                            <div class="row ">

                                <div class="col-lg-9">
                                    <div class="row">
                                        <div class="">
                                            <div class="mb-4">
                                                <div className="col-lg-12">
                                                    {isPopupOpen && <Popup onClose={closePopup} unlockPop={unlockPop} freeTrail={freeTrail}/>}
                                                    {isPopupOpen === false && <div className="row mt-3" >
                                                        {showCirriculum ?
                                                            <div>
                                                                <div class="container"><div class="row">
                                                                    <div class="col">
                                                                        <h1 className="d-flex align-items-center"><img src={arrowL} width={30} alt="arrow" style={{ cursor: "pointer" }} onClick={myCourse} /><div style={{ marginLeft: 5 }} class="common_main_heading"><div style={{ textDecoration: "underline" }}>Courses {">"} {sessionStorage.getItem("myCourseProgramName")} {">"} curriculum</div></div></h1>
                                                                    </div>


                                                                </div></div>



                                                                <div class="common_main_heading">Curriculum</div>
                                                                <div>
                                                                    {allDayCirriculumList.map((res) => {
                                                                        return (
                                                                            <Accordion title={res.day} content={res} totalTask={res.totaltasks} dayProgress={res.dayProgress} currentDayProgress={res.currentDayProgress} generic={"not generic"} hideCircullum={hideCircullum} eligible={eligible} freeTrail={freeTrail} endFreeTrail={() => {endFreeTrail()}}/>
                                                                            // <div class="common_main_heading mt-2" style={{ backgroundColor: '#f6f6f6', borderRadius: 10, padding: 15, border: '1px solid #e8e8ea' }}>
                                                                            //    <div class="container"><div class="row"><div class="col">{res.day}</div>
                                                                            //    <div class="col col-lg-1">hello</div>

                                                                            //    </div></div> 
                                                                            //     </div>
                                                                        )
                                                                    })}
                                                                </div> </div> : <div>
                                                                <div class="container"><div class="row">
                                                                    <div class="col">
                                                                        <img src={arrowL} width={30} onClick={quitQuiz} alt="arrow" style={{ cursor: "pointer" }} />
                                                                        <App topicId={topicId} callparentfunction={() => quitQuiz()} quizDuration={70} />
                                                                    </div></div></div></div>}
                                                    </div> }
                                                </div></div></div></div></div></div></div>

                    </section>

                </div>
            </div>
            <RightSidebar />
        </>
    );
};

export default DayCircullum;
