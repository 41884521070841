import React from "react";
import Sidebar from "./Sidebar"
import "../styles/App.css";
import "../styles/Dashboard.css";
import Navb from "./Navb"
import "../fonts/Roboto/Roboto-Black.ttf";
import Accordion from "react-bootstrap/Accordion";
import thum from "../images/vid2.png"
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import httpvideos from "../js/http-lms_videos"
import Spinner from 'react-bootstrap/Spinner';
import NoRecFound from "../components/NORecFound";
import TopicVideo from './coursedetails/TopicVideo'
import TopicDetails from "./coursedetails/TopicDetails";
import CourseTopicsList from "./coursedetails/CourseTopicsList"
import ArrowLeft from '../images/arrow_circle_left.svg'
import ArrowRight from '../images/arrow_circle_right.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock, faLockOpen, faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import httpsignup from '../js/http-lms_userprofile';
import moment from "moment/moment";


// event bus
import eventBus from "../js/Event-bus"
const Videos = () => {
  const params = useParams()
  const [courseData, setCourseData] = useState([])
  const [loading, setLoading] = useState(true)
  const [selectedTopicLoading, setSelectedTopicLoading] = useState(true)
  const [selectedTopicData, setSelectedTopicData] = useState({})
  const [selectedSection, setSelectedSection] = useState('course_details')
  const [hideCourseDetails, setHideCourseDetails] = useState(false)
  const [img, setImg] = useState("grey");
  const [show, setShow] = useState(false);
  const [showPrevious, setShowPrevious] = useState(false);
  const [accessExpiry, setAccessExpiry] = useState(false);
  const [userTypeId, setuserTyepId] = useState();
  const [profId, setProfId] = useState();
  const [videoUnlockCount, setVideoUnlockCount] = useState();


  const navigate = useNavigate();

  function navToPrograms() {
    navigate("/my-course");
  }

  // useEffect(() => {
  //   console.log(selectedTopicData);
  // }, [selectedTopicData])

  function mouseInImg() {
    setImg("blue");
  }

  function mouseOutImg() {
    setImg("grey");
  }

  useEffect(() => {
    videoUnlock()
    programDetails()
  }, [])
  function videoUnlock() {
    httpsignup.get(`/skillsquadUserprofileLMS/getSkillsquadUserProfileBySignUpId?signUpId=${sessionStorage.getItem('userId')}`)
      .then((response) => {
        console.log("start of unlock video")
        console.log("UserDetails:", JSON.stringify(response.data))
        setuserTyepId(response.data.userTypeMasterId);
        setProfId(response.data.lmsProfileId);
        setVideoUnlockCount(response.data.unlockVideoCount);

      }).catch((err) => {
        console.log(err)
      })
  }
  function programDetails() {
    console.log("router parameters : ", params)
    setLoading(true)
    httpvideos.get('/lmsVideoRecordingManager/api/v1/videoRecordingLMS?roleId=1&loggedUserID=1' + '&lmsProgramID=' + sessionStorage.getItem("myCourseProgramId"))
      .then((response) => {
        console.log("program details : ", response.data)
        setCourseData(response.data)
        setLoading(false)
        setInitialRecord()
      })
  }
  function setInitialRecord() {
    setTimeout(() => {
      let topicsList = document.getElementsByClassName('topicsList')
      if (topicsList.length > 0) {
        topicsList[0].click()
      }
    }, 1600);
  }
  function selectTopicToView(e, topic, i) {
    videoUnlock();
    console.log("show previous :" + showPrevious)
    if ((topic.topicIndex > videoUnlockCount + 1)) {
      setShowPrevious(true)

    }
    else {
      setSelectedTopicLoading(true)
      console.log("selected topic to view : ", topic, i)
      setSelectedTopicData(topic)
      setTimeout(() => {
        setSelectedTopicLoading(false)
      }, 1600);




    }
    // eventBus.dispatch("send-selected-topic-info", { message: topic });
  }

  function selectSection(e) {
    console.log("selected section : ", e.target)
    setSelectedSection(e.target.id)
  }
  function handleClick() {
    navigate("/add-user")
  }
  function handleClick1() {
    window.open("https://www.skillsquad.io", "blank")
  }

  const handleClosePrevious = () => setShowPrevious(false);


  return (
    <>
      <Sidebar />
      {/* Main Container */}
      <div className="jobs-dashboard-container mx-4">

        {/* <Navb /> */}

        <div className="px-md-5 mt160">

          <section class="section">
            <div class="container">

              <div class="row">

                <div class="col-lg-12">
                  <div class="row">
                    <div class="">
                      <div class="section-title  mb-4 pb-2">
                        <Navb />

                        {loading ? <div className="d-flex flex-column align-items-center justify-content-center pb-5 pt-5 mt-5"> <Spinner animation="border" variant="secondary" /></div> :

                          <div className="scroll-container">
                            <div className="container-fluid">
                              <div className="row mt-4">
                                <div className="col-lg-12 d-flex">
                                  <div className="d-flex">
                                    {img === 'grey' && <img onClick={navToPrograms} onMouseEnter={mouseInImg} width="15px" className="me-2 pointer img-fluid" src={require("../images/leftarrow.png")} alt="back" />}
                                    {img === 'blue' && <img onClick={navToPrograms} onMouseLeave={mouseOutImg} width="15px" className="me-2 pointer img-fluid" src={require("../images/leftarrow-blue.png")} alt="back" />}
                                    <span className='pointer font18 bold ml-2' onClick={navToPrograms}>{courseData.lmsProgramName}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {(courseData && courseData.moduleMasterList && courseData.moduleMasterList.length > 0) ?
                              <div className="container-fluid scroll-container">
                                <div className="row mt-3 h-100 justify-content-between">
                                  <div className="d-block d-lg-none bgwhite shadow col-12 fit-content-h radius10">
                                    <div className="videosection">



                                    </div>
                                  </div>
                                  <div className="d-block d-lg-none pb-3 mt-3">
                                    <div className="d-flex p-1 justify-content-around border-black60 radius10">
                                      <div onClick={selectSection} id="course_details" className={selectedSection === 'course_details' ? 'orange font14 pointer selectedoption pe-2 ps-2 radius10' : 'black font14 pointer pe-2 ps-2'}>Course Details</div>
                                      <div onClick={selectSection} id="course_description" className={selectedSection === 'course_description' ? 'orange font14 pointer selectedoption pe-2 ps-2 radius10' : 'black font14 pointer pe-2 ps-2'}>Course Description</div>
                                    </div>
                                  </div>
                                  <div className={hideCourseDetails ? 'col-lg-1 radius10' : 'col-lg-4 height100'}>

                                    {/* <CourseTopicsList courseData={courseData}/> */}
                                    {selectedSection === 'course_details' &&
                                      <div className={hideCourseDetails ? "bgwhite shadow radius10" : "bgwhite shadow p-2  radius10 height100"}>
                                        <div className="d-flex justify-content-between px-3">
                                          {!hideCourseDetails && <h4 className="pt-2 pb-2 font18" style={{ fontWeight: "350" }}>{/* Course Completion */} Course Details</h4>}

                                        </div>
                                        {!hideCourseDetails && <Accordion className="height90 scroll-section">
                                          {loading ? <div className="d-flex flex-column align-items-center justify-content-center pb-5 pt-3"> <Spinner animation="border" variant="secondary" /></div> :
                                            courseData.moduleMasterList.map((res, index) => {
                                              return (
                                                <Accordion.Item className="programdetails-accordian background-aliceblue radius10 mb-2 col-11 mx-auto" key={'item' + index} eventKey={index} style={{backgroundColor: "transparent"}}>
                                                  <Accordion.Header className="p-1 background-aliceblue radius10" style={{backgroundColor: "transparent"}}>
                                                    <div className={(selectedTopicData && selectedTopicData.moduleId === res.lmsModuleId) ? "d-flex" : "d-flex"}>
                                                      <span className=""><FontAwesomeIcon icon={faLock} /></span>
                                                      <span className="me-4 ms-2 my-auto moduleCount d-flex justify-content-center align-items-center">{index + 1}</span>
                                                      <span className="">{res.lmsModuleName}</span>

                                                    </div>
                                                  </Accordion.Header>
                                                  <Accordion.Body>
                                                    {res.topicMasterList.map((topic, i) => {
                                                      return (
                                                        <div className="col-12" key={'topic' + i}>
                                                          <div className={(selectedTopicData && selectedTopicData.lmsTopicMasterId === topic.lmsTopicMasterId) ? "d-flex mb-2 pointer blue" : "d-flex mb-2 pointer"}>

                                                            {(videoUnlockCount == null && index <= videoUnlockCount && i <= videoUnlockCount) ? <span style={{ fontSize: 20 }}><FontAwesomeIcon icon={faLockOpen} /></span> :
                                                              (videoUnlockCount != null && videoUnlockCount + 1 === topic.topicIndex) ? <span style={{ fontSize: 20 }}><FontAwesomeIcon icon={faLockOpen} /></span> :
                                                                (videoUnlockCount != null && topic.topicIndex <= videoUnlockCount) ? <span style={{ fontSize: 20, color: "#1ae881" }}><FontAwesomeIcon icon={faCircleCheck} /></span> : <span style={{ fontSize: 20 }}><FontAwesomeIcon icon={faLock} /></span>}


                                                            {/* {videoUnlockCount}{index}{i}
                                            {(videoUnlockCount == null && index <= videoUnlockCount && i <= videoUnlockCount) ? <span  style={{fontSize: 20}}><FontAwesomeIcon icon={faLockOpen} /></span> :
                                            (videoUnlockCount != null && index <= videoUnlockCount && i == videoUnlockCount) ? <span  style={{fontSize: 20}}><FontAwesomeIcon icon={faLockOpen} /></span> :
                                            (videoUnlockCount != null && index <= videoUnlockCount && i <= videoUnlockCount) ? <span style={{fontSize: 20, color:"#1ae881"}}><FontAwesomeIcon icon={faCircleCheck} /></span> : <span  style={{fontSize: 20}}><FontAwesomeIcon icon={faLock} /></span>} */}
                                                            <span className="me-4 ms-2 my-auto submoduleCount d-flex justify-content-center align-items-center">{index + 1}.{i + 1}</span>
                                                            <span className="topicsList" onClick={(e) => selectTopicToView(e, topic, i)}>{topic.topicName}</span>
                                                          </div>
                                                        </div>
                                                      )
                                                    })}
                                                  </Accordion.Body>
                                                </Accordion.Item>
                                              )
                                            })
                                          }
                                        </Accordion>}
                                      </div>
                                    }
                                    {selectedSection === 'course_description' &&
                                      <TopicDetails selectedTopicData={selectedTopicData} />}
                                  </div>

                                  <div className={hideCourseDetails ? "col-lg-11 d-flex flex-column align-items-start pb-3 pb-lg-4 scroll-section h-100 radius10 fixed" : "col-lg-8 d-flex flex-column align-items-start pb-3 pb-lg-4 scroll-section h-100 radius10 fixed"} style={{top: '15%', width: '53%', left: '47%'}}>
                                    <div className="d-none d-lg-block bgwhite shadow col-12 fit-content-h radius10">
                                      <div className="videosection">

                                        <TopicVideo selectedTopicData={selectedTopicData} callparentfunction={() => videoUnlock()} videoCount={videoUnlockCount} loginprofId={profId} />
                                      </div>
                                      <div className="topicdetails d-none d-lg-block p-3">
                                        <TopicDetails selectedTopicData={selectedTopicData} />




                                        {showPrevious === true && <Modal show={showPrevious} onHide={handleClosePrevious} style={{ width: "30%", alignItems: "center", marginLeft: "40%", marginTop: "20%" }} >
                                          <Modal.Header closeButton>

                                          </Modal.Header>
                                          <p class="p-3 h3">Please Finish the current video to unlock the next one</p>
                                          <br></br>
                                          <button class="btn btn-common btn-rm mb-5 m-2" style={{ backgroundColor: '#5627ff', width: "40%", fontWeight: 700 }} onClick={handleClosePrevious}>Continue to watch</button>


                                        </Modal>}




                                      </div>

                                      <div className="d-flex d-none d-lg-block justify-content-between p-4 w-100">
                                        {/* <div className="d-flex">
                      <img src={require("../images/Previous.png")} alt="Image not found" className="me-1"/>
                      <h6 className="mb-0 d-flex align-items-center">Previous</h6>
                    </div>
                    <div className="d-flex">
                      <h6 className="mb-0 d-flex align-items-center">Next</h6>
                      <img src={require("../images/Next.png")} alt="Image not found" className="ms-1"/>
                    </div> */}
                                      </div>
                                    </div>
                                  </div>

                                </div>
                              </div>
                              : <div className='col-12 text-center'><NoRecFound error="No Data found." /></div>}
                          </div>
                        }
                      </div></div></div></div></div></div></section></div></div>
    </>
  );

};

export default Videos;
