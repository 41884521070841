import React from "react";
import Sidebar from "./Sidebar";
import "../styles/DayChallenge.css";
import "../fonts/Roboto/Roboto-Black.ttf";
import { useState, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import DatePicker from "react-horizontal-datepicker";
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles
} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import greenB from "../images/green_button.png";
import wrongB from "../images/wrong_icon.png";
import arrow from "../images/left-arrow_icon.png"
import book from "../images/writing_icon.png";
import clock from "../images/clock_icon.png";
import httpsignup from '../js/http-lms_userprofile';
import greyB from "../images/grey_correct_icon.png";
import blackL from "../images/black_lock_icon.png";
import code from "../images/code_icon.png"
import App from "../components/App"
import ProgressBar from "react-bootstrap/ProgressBar";
import Popup from "../components/ChallengeComplete";
import RightSidebar from "./RightSidebar";



const MCQCompleted = (props) => {
  const percentage = 0 / 1;

  const [errorMessage, setErrorMessage] = useState(" ");

  const [allJobsList, setAllJobsList] = useState([])
  const [dayChallenge, setDayChallenge] = useState([])
  const [loading, setLoading] = useState(true)
  const [topicId, setTopicId] = useState()
  const [openQuiz, setOpenQuiz] = useState(false)
  const [lastTopicCount, setLastTopicCount] = useState()
  const [completedTask, setCompletedTask] = useState()
  const navigate = useNavigate();




  //API call when page loader
  useEffect(() => {

    getLoggedInUserDetails();


  }, [])

  function getLoggedInUserDetails() {
    console.log("TRIGGERED LOGGED")
    httpsignup.get(`/skillsquadUserprofileLMS/getSkillsquadUserProfileBySignUpId?signUpId=${sessionStorage.getItem('userId')}`)
      .then((response) => {
        sessionStorage.setItem("dayProfileId", response.data.lmsProfileId)
        sessionStorage.setItem("dayProgramId", sessionStorage.getItem("myCourseProgramId"))
        httpsignup.get(`/programsLMS/getMCQAnswer?profileId=${sessionStorage.getItem("dayProfileId")}&programId=${sessionStorage.getItem("myCourseProgramId")}&quizCode=${props.topicId}`)
          .then((response) => {
            console.log("UserDetails Day Challeneg : " + JSON.stringify(response.data))
            setLoading(false)
            setDayChallenge(response.data)




          }).catch((err) => {
            console.log(err)
          })

      }).catch((err) => {
        console.log(err)
      })


  }

  function dayChallengeComplete() {
    props.callparentfunction();
  }

  return (
    <>


      <div className="jobs-dashboard-container" style={{width : "100%"}}>

        {/* <Navb /> */}

        <div className="px-md-5">

          <section class="section">
            <div class="container">

              <div class="row p-4">

                <div class="col-lg-12">
                  <div class="row">
                    <div class="">
                      <div class="mb-4 pb-2">
                        <div className="col-lg-9">
                          <div className="row">

                            <div>
                              <h1 class="mb-4 d-flex align-items-center"><img src={arrow} width={30} alt="arrow" style={{ cursor: "pointer" }} onClick={dayChallengeComplete} /><div class="common_main_heading">Your Answers</div></h1>

                            </div>


                            {loading ? <></> : dayChallenge.length === 0 ? <></> :
                              dayChallenge.map((res, index) => {
                                return (<div>{res.correctOrWrong == 1 ? <div class="dayChallenge-task mt-4 mb-4 shadow p-3 mb-5 bg-white rounded" style={{ borderLeft: "10px solid #1ae881", borderRight: "10px solid #1ae881", borderRadius: 10 }}>
                                  <div>  <div class="d-flex align-items-center" style={{ fontSize: 20, fontWeight: 700 }}><div>{index + 1}.</div><div>{res.question}</div><img src={greenB} alt="correct" width={25} height={25} /></div>
                                    <div class="mt-2 mb-2">Your Answer : <span style={{ fontSize: 16, fontWeight: 700 }}>{res.userAnswer}</span></div>
                                    <div class="mt-2 mb-2">Correct Answer : <span style={{ fontSize: 16, fontWeight: 700 }}>{res.correctAnswer}</span></div>
                                  </div>

                                </div> :
                                  <div class="dayChallenge-task mt-4 mb-4 shadow p-3 mb-5 bg-white rounded" style={{ borderLeft: "10px solid red", borderRight: "10px solid red", borderRadius: 10 }}>
                                    <div>  <div class="d-flex align-items-center" style={{ fontSize: 20, fontWeight: 700 }}><div>{index + 1}.</div><div>{res.question}</div><img src={wrongB} alt="correct" width={25} height={25} /></div>
                                      <div class="mt-2 mb-2">Your Answer : <span style={{ fontSize: 16, fontWeight: 700 }}>{res.userAnswer}</span></div>
                                      <div class="mt-2 mb-2">Correct Answer : <span style={{ fontSize: 16, fontWeight: 700 }}>{res.correctAnswer}</span></div>
                                    </div>

                                  </div>
                                }</div>

                                )
                              })}






                          </div>
                        </div></div></div></div></div></div></div>

          </section>

        </div>
      </div>

    </>
  );
};

export default MCQCompleted;
