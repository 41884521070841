import React from "react";
import "../styles/MobileNavmenu.css";
import home from "../images/Home-simple-door.svg";
import sesssion from "../images/Page.svg";
import bookin from "../images/callB.svg";
import profile from "../images/User 1.svg";
import { Link, useNavigate } from "react-router-dom";
import { usePopup } from "./PopupContext";

const MobileApplication = () => {
  const navigate = useNavigate();
  const {isInitialPop} = usePopup();
  const token = sessionStorage.getItem("JWT");
  const location = window.location.pathname;


  return (
    <div>
     {(token && !isInitialPop) && <div className="mobile-navigation-menu">
        <div className="nav-data-application">
          <Link to="/mentors/1:1-services/recommended">
           
            <div
              className={`nav-item-application ${
                location === "/mentors" ? "m-l-active" : ""
              }`}
            >
              <div className="nav-i-i">
                <img src={home} alt="home" />
              </div>
              <div className="nav-i-a">Home</div>
            </div>
          </Link>
          <Link to="/session-details">
            <div
              className={`nav-item-application ${
                location === "/session-details" ? "m-l-active" : ""
              }`}
            >
              <div className="nav-i-i">
                <img src={bookin} alt="bookings" />
              </div>
              <div className="nav-i-a">Bookings</div>
            </div>
          </Link>
          <Link to="/user-profile">
           
            <div
              className={`nav-item-application ${
                location === "/user-profile" ? "m-l-active" : ""
              }`}
            >
              <div className="nav-i-i">
                <img src={profile} alt="profile" />
              </div>
              <div className="nav-i-a">Profile</div>
            </div>
          </Link>
        </div>
      </div>}
    </div>
  );
};

export default MobileApplication;
