import React from "react";
import Sidebar from "./Sidebar";
import "../styles/JobDescription.css";
import "../fonts/Roboto/Roboto-Black.ttf";
import { useState, useEffect } from "react";
import httpuserprofile from "../js/http-lms_userprofile";
import ReactHtmlParser from "html-react-parser";
import RightSidebar from "./RightSidebar";
import moment from "moment/moment";
import { Navigate, useNavigate } from "react-router-dom";
import arrow from "../images/left-arrow_icon.png";
import CalenderBlank from "../images/CalendarBlank.svg";
import bookmark from "../images/material-symbols_bookmark.svg";
import money from "../images/CurrencyDollar.svg";
import time from "../images/Clock.svg";
import location from "../images/MapPinLine.svg";
import stack from "../images/Stack.svg";
import briefcase from "../images/briefcase.svg";
import education from "../images/icon-park-outline_degree-hat.svg";
import tdesign from "../images/tdesign_money.svg";
import LinkSimple from "../images/LinkSimple.svg";
import backArrow from "../images/BackArrow.svg";
import { makeStyles } from "@material-ui/core/styles";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import {
  FaLinkedin,
  FaWhatsapp,
  FaEnvelope,
  FaTwitter,
  FaFacebook,
} from "react-icons/fa";

const JobDescription = () => {
  console.log("Hello")
  const shareUrl = "https://example.com";
  const title = "Example Website";

  const [errorMessage, setErrorMessage] = useState(" ");

  const [allJobsList, setAllJobsList] = useState([]);
  const navigate = useNavigate();

  //API call when page loader
  useEffect(() => {
   
    httpuserprofile
      .get(
        `/skillsquadUserprofileLMS/getPostedJobByJobId/${sessionStorage.getItem("jobId")}/${sessionStorage.getItem("skillsquadProfileId")}`
      )
      .then((res) => {
        console.log(
          "Post a job list",
          JSON.stringify(res.data[0].jobDescription)
        );
        setAllJobsList([]);
        setAllJobsList(res.data);
        setErrorMessage(res.data[0].jobDescription);
      });
  }, []);

  function gotoJobPortal() {
    navigate("/job-portal");
  }

  console.log("jobs description", allJobsList);

  const [copied, setCopied] = useState(false);
  const copyToClipboard = () => {

    navigator.clipboard.writeText("https://skillsquad.io");
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  };


  const skillsAdda = (data) => {
    const newSkills = data.split(",");
    return newSkills;
  }

  const JobDescriptionCode = (html) => {
    const newText = String(html);
    return ReactHtmlParser(newText)
  }
  const RequirementsCode = (html) => {
    const newText = String(html);
    return ReactHtmlParser(newText)
  }

  function jobApply(url){
    httpuserprofile
      .post(
        `/skillsquadUserprofileLMS/saveJobApplications`,
        {
          profileId : sessionStorage.getItem("skillsquadProfileId"),
          jobId : sessionStorage.getItem("jobId"),
          creationUserI:  sessionStorage.getItem("userEmail"),
          lastUpdatedUserI : sessionStorage.getItem("userEmail")
        }
      )
      .then((res) => {
        httpuserprofile
        .get(
          `/skillsquadUserprofileLMS/getPostedJobByJobId/${sessionStorage.getItem("jobId")}/${sessionStorage.getItem("skillsquadProfileId")}`
        )
        .then((res) => {
          console.log(
            "Post a job list",
            JSON.stringify(res.data[0].jobDescription)
          );
          setAllJobsList([]);
          setAllJobsList(res.data);
          setErrorMessage(res.data[0].jobDescription);
          window.open(url, "_blank")
        });
        
      });
  }

  return (
    <>
      <Sidebar />

      <div className="jobs-dashboard-container mx-4">
        {/* <Navb /> */}

        <div className="px-md-5  mt160 container">
          <section classname="section about-job d-flex">
            {Array.isArray(allJobsList) ? (
              <div className="">
                {allJobsList.map((data) => (
                  <div className="row" key={data.id}>
                    <div>
                      <div className="about-profile sticky">
                        <img
                          class="mb-3"
                          src={backArrow}
                          width={30}
                          alt="arrow"
                          style={{
                            cursor: "pointer",
                            width: "24px",
                            height: "16px",
                            marginRight: "23px",
                          }}
                          onClick={gotoJobPortal}
                        />
                        <div className="logo-container-des">
                          <img
                            src={data.companyProfile.companyLogo}
                            alt=""
                          // className="job-image"
                          // style={{ height: "70px", width: "70px" }}
                          />
                        </div>
                        <div className="about-job-details">
                          <h1>{data.roleName}</h1>
                          {/* <h1>adhausidb diebdfu fuehfbuei fhedeknfjen dnwejfnejjfjebfju</h1> */}
                          <span className="description-company">{data.companyProfile.companyName}</span>
                          {data.appliedFlag === "Y" && <div class="d-flex align-items-center"> <img

src={require("../images/tick-circle-solid.png")}
alt=""
// style={{ height: "100px", width: "100px" }}
/>  <div style={{ color: "#7E89A6", marginLeft: 5 }}>Application Submitted</div></div>}
                          <div className="job-perks-about">
                            <div className="job-perks-location">
                              <img src={location} />
                              <span className="">
                                {data.companyProfile.city.cityName}
                              </span>
                            </div>
                            <div className="job-perks-location">
                              <img src={time} />
                              <span>{data.jobTypeMaster.jobTypeName}</span>
                            </div>
                            <div className="job-perks-location">
                              <img src={CalenderBlank} />
                              <span>
                                {moment
                                  .utc(data.postedDate)
                                  .local()
                                  .startOf("seconds")
                                  .fromNow()}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex align-items-center">
                          {/* <img src={bookmark} alt="bookmark" /> */}
                          {data.appliedFlag === 'Y' ? <></>: <div
                            className="apply"
                            style={{ marginLeft: "15px", cursor: "pointer" }}
                            onClick={() => jobApply(data.jobUrl)}
                          >
                            <span>Apply Now</span>
                          </div>}
                        </div>
                      </div>
                      <div
                        className="job-description-part"
                        style={{ marginLeft: "60px" }}
                      >
                        <div className="about-perks">
                          <div className="d-flex flex-column align-items-start">
                           
                              <img src={stack} />
                              <h4>JOB LEVEL</h4>
                            
                            <span>{data.jobLevelName}</span>
                          </div>
                          <div className="d-flex flex-column align-items-start">
                            <img src={briefcase} />
                            <h4>EXPERIENCE</h4>
                            <span>
                              {data.fromExperience}-{data.toExperience}
                            </span>
                          </div>
                          <div className="d-flex flex-column align-items-start">
                            <img src={education} />
                            <h4>EDUCATION</h4>
                            <span>{data.education}</span>
                          </div>
                          <div className="d-flex flex-column align-items-start">
                            <img src={tdesign} />
                            <h4>SALARY</h4>
                            <span>{data.packages} lakh LPA</span>
                          </div>
                        </div>
                        <div className="jd">
                          <h2
                            className="jd-heading"
                            style={{ marginBottom: "5px" }}
                          >
                            Overview
                          </h2>
                          <p className="jd-para">
                            {JobDescriptionCode(data.jobDescription)}
                          </p>
                        </div>

                        <div className="requirements">
                          <h2
                            className="re-heading"
                            style={{ marginBottom: "5px" }}
                          >
                            Requirements
                          </h2>
                          {/* <ul> */}
                          {/* <li className="require-list">
                              Great troubleshooting and analytical skills
                              combined with the desire to tackle challenges
                              head-on
                            </li>
                            <li className="require-list">
                              3+ years of experience in back-end development
                              working either with multiple smaller projects
                              simultaneously or large-scale applications
                            </li>
                            <li className="require-list">
                              Experience with HTML, JavaScript, CSS, PHP,
                              Symphony and/or Laravel
                            </li>
                            <li className="require-list">
                              Working regularly with APIs and Web Services
                              (REST, GrapthQL, SOAP, etc)
                            </li> */}
                          {/* {ReactHtmlParser(data.JobDescription.toString())} */}

                          {RequirementsCode(data.requirements)}
                          {/* </ul> */}
                        </div>
                        <div className="">
                          <h2 className="re-heading">Skills</h2>
                          <div className="skills">
                            {skillsAdda(data.skills).map((skill, index) => (
                              <div className="skills-box" key={index}>
                                <p className="skills-text">{skill}</p>
                              </div>
                            ))}
                          </div>
                        </div>
                        <div className="social-media">
                          <h2 className="jd-heading">Share this Job</h2>
                          <div className="all-social-share">
                            <div className="copy-links" onClick={copyToClipboard}>
                              <div className="d-flex align-items-center">
                                <img
                                  className=""
                                  src={LinkSimple}
                                  style={{ marginRight: "5px" }}
                                />
                                <span>
                                  {copied ? "Copied!" : "Copy Link"}
                                </span>
                              </div>
                            </div>


                            <div className="share-buttons">
                              <LinkedinShareButton url={shareUrl} title={title}>
                                <FaLinkedin size={30} className="icon-share" />
                              </LinkedinShareButton>

                              <WhatsappShareButton url={shareUrl} title={title} >
                                <FaWhatsapp size={32} className="icon-share" />
                              </WhatsappShareButton>

                              <EmailShareButton url={shareUrl} subject={title}>
                                <FaEnvelope size={32} className="icon-share" />
                              </EmailShareButton>

                              <TwitterShareButton url={shareUrl} title={title}>
                                <FaTwitter size={32} className="icon-share" />
                              </TwitterShareButton>

                              <FacebookShareButton url={shareUrl} quote={title}>
                                <FaFacebook size={32} className="icon-share" />
                              </FacebookShareButton>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div class="common_main_text_highlight">
                No Job Description Found
              </div>
            )}
          </section>
        </div>
      </div>
      <RightSidebar />
    </>
  );
};

export default JobDescription;
