import React from 'react'
import { useState, useEffect } from "react";
import httpvideos from "../../js/http-lms_videos"
import Spinner from 'react-bootstrap/Spinner';
import NoRecords from '../../components/NORecFound.jsx';
import moment from 'moment'
import SubmitAssignments from '../../components/UploadAssignments.jsx'
// images
import upload_assignment from '../../images/upload_assignment.svg'
import delete_assignment from '../../images/delete_assignment.svg'
import closebtn from '../../images/close_btn.svg'
import gray_upload_assignment from '../../images/gray_upload_assignment.svg'
import gray_delete_assignment from '../../images/gray_delete_assignment.svg'
const Assigned = () => {
  const [assignedAssignments, setAssignedAssignments] = useState([])
  const [assignedLoading, setAssignedLoading] = useState(true)
  const [uploadAssignments, setUploadAssignments] = useState(false)
  const [deleteAssignments, setDeleteAssignments] = useState(false)
  const [selectedAssignmentsToUpload, setSelectedAssignmentsToUpload] = useState({})
  const [selectedAssignmentsToDelete, setSelectedAssignmentsToDelete] = useState({})
  const [deletedLoading, setDeletedLoading] = useState(false)
  useEffect(() => {
    fetchAssignedAssignments()
  },[])
  function fetchAssignedAssignments(){
    httpvideos.get('/api/v1/topicAssignmentLMS/lmsAssignedAssignmentsListForStudents?RoleId=2&UserId='+sessionStorage.getItem('userEmail')+'&studentId='+sessionStorage.getItem('profileid'))
    .then((response) => {
      console.log("fetch assigned assginemnts : ", response.data)
      let firstdata = response.data;
      let finalData = [];
      if(firstdata && firstdata.length > 0){
        for(var i =0; i < firstdata.length; i++){
          firstdata[i].assignmentName = firstdata[i].assignmentMaster[0].assignmentName;
          firstdata[i].assignmentStartDate = firstdata[i].studentAssignment.assignmentStartDate;
          firstdata[i].assignmentEndDate = firstdata[i].studentAssignment.assignmentEndDate;
          firstdata[i].assignmentSubmittedDate = firstdata[i].studentAssignment.assignmentSubmittedDate;
        }
      }
      setAssignedAssignments(response.data)
      setAssignedLoading(false)
    }).catch((e) => {
      console.log(e)
      setAssignedLoading(false)
    })
  }
  //upload assignments functionality
  function uploadingAssignments(assignment, index) {
    console.log("assignemnt : ", assignment)
    setUploadAssignments(true)
    setSelectedAssignmentsToUpload(assignment)
  }
  function closeUploadPopup(data) {
    console.log("while closing popup : ", data)
    setUploadAssignments(false)
    setSelectedAssignmentsToUpload({})
    if(data == 'SUCCESS'){
      setAssignedAssignments([])
      setAssignedLoading(true)
      fetchAssignedAssignments()
    }
  }
  //delete assignments functionality
  function openDeleteAssignmentPopup(data, index){
    setSelectedAssignmentsToDelete(data)
    setDeleteAssignments(true)
  }
  function closeDeletePop() {
    setDeleteAssignments(false)
    setSelectedAssignmentsToDelete({})
  }
  function deletingAssignment() {
    console.log("final delete")
    setDeletedLoading(true)
    httpvideos.delete('/api/v1/topicAssignmentLMS/lmsDeleteAssignmentsFromS3?RoleId='+sessionStorage.getItem("roleid")+'&UserId='+sessionStorage.getItem('userEmail')+'&assignmentId='+selectedAssignmentsToDelete.assignmentMaster[0].assignmenId+'&studentId='+sessionStorage.getItem('profileid'))
    .then((response) => {
      console.log("deleting assignment : ", response.data)
      setDeletedLoading(false)
      setDeleteAssignments(false)
      setSelectedAssignmentsToDelete({})
      setAssignedAssignments([])
      setAssignedLoading(true)
      fetchAssignedAssignments()
    }).catch((e) => {
      console.log(e)
      setDeletedLoading(false)
    })
  }
  const sortData = (data, sortKey, sortDirection) => {
    return data.sort((a, b) => {
      if (sortDirection === 'asc') {
        return a[sortKey] > b[sortKey] ? 1 : -1;
      } else {
        return a[sortKey] < b[sortKey] ? 1 : -1;
      }
    });
  };
  
    const [sortKey, setSortKey] = useState(null);
    const [sortDirection, setSortDirection] = useState(null);
  const handleSort = key => {
    if (key === sortKey) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortKey(key);
      setSortDirection('asc');
    }
  };
  const sortedData = sortData(assignedAssignments, sortKey, sortDirection);
  return (
    <>
    <div className="jobs-dashboard-container mx-4 mt160">
    {uploadAssignments && <SubmitAssignments selectedAssignment={selectedAssignmentsToUpload} onClose={closeUploadPopup}/>}
    {deleteAssignments && 
        <div className="position-fixed P-Update-Success-1 d-flex justify-content-center align-items-center">
        <div className="card border-0 col-11 p-3 col-md-8 col-lg-3 d-flex flex-column">
          <div className="col-12 p-2 pb-3 radius10 border-0 bold1 font18 text-center">
              <img src={closebtn} alt="close" width="25px" className="closebtn mouseoverstyles1 radiushalf pointer border-0" onClick={closeDeletePop}/>
          </div>
          <div className="col-12 p-2 py-3 d-flex flex-column">
            <span className="font16 black bold text-center">Are you sure you want to delete {selectedAssignmentsToDelete.assignmentMaster[0].assignmentName} submitted answerset?</span>
          </div>
          <div className="d-flex justify-content-around footer text-center p-2">
            {deletedLoading? <div className=" col-5 d-flex justify-content-center align-items-center"> <Spinner animation="border" variant="secondary" /></div> :
            <button className="background-organe white col-5 border-0 p-1 radius15" onClick={deletingAssignment}>Delete</button>}
            <button className="background-gray ml-1 black60 col-5 border-0 p-1 radius15" onClick={closeDeletePop}>Cancel</button>
          </div>
        </div>
      </div>
    }
    {assignedLoading ? <div className=" h-100 d-flex justify-content-center align-items-center mt-5 mb-3 py-5 "> <Spinner animation="border" variant="secondary" /></div> : 
    <div>
      { (sortedData.length > 0) ? 
      <table className="table whitecolor">
        <thead>
          <tr>
            <th scope="col">Assignment Name <img onClick={() => handleSort('assignmentName')} src={require("../../images/updown.png")} alt="sort" className="ms-1 updown"/></th>
            <th scope="col">Program </th>
            <th scope="col">Module Name <img onClick={() => handleSort('moduleName')} src={require("../../images/updown.png")} alt="sort" className="ms-1 updown"/></th>
            <th scope="col">Topic Name <img onClick={() => handleSort('topicName')} src={require("../../images/updown.png")} alt="sort" className="ms-1 updown"/></th>
            <th scope="col">Start Date <img onClick={() => handleSort('assignmentEndDate')} src={require("../../images/updown.png")} alt="sort" className="ms-1 updown"/></th>
            <th scope="col">End Date <img onClick={() => handleSort('assignmentEndDate')} src={require("../../images/updown.png")} alt="sort" className="ms-1 updown"/></th>
            <th scope="col">Submitted Date <img onClick={() => handleSort('assignmentSubmittedDate')} src={require("../../images/updown.png")} alt="sort" className="ms-1 updown"/></th>
            <th scope="col">Assigned Document</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          {assignedAssignments.map((data, index) => {
            return (
              <tr key={data.studentAssignment.studentAssignmentTrackerId}>
                <td scope="row">{data.assignmentName}</td>
                <td>{data.programName}</td>
                <td>{data.moduleName}</td>
                <td>{data.topicName}</td>
                {/* <td>{moment(data.studentAssignment.assignmentStartDate).format('MM/DD/YYYY LT')}</td> */}
                <td>{moment(data.assignmentStartDate).format('MM/DD/YYYY')}</td>
                <td>{moment(data.assignmentEndDate).format('MM/DD/YYYY')}</td>
                <td>{data.assignmentSubmittedDate ? moment(data.assignmentSubmittedDate).format('MM/DD/YYYY') : '--'}</td>
                <td><a disabled={data.assignmentSubmittedDate} href={data.assignmentMaster[0].s3PresignedAssignmentLink} target="_blank" rel="noopener noreferrer">view</a></td>
                <td>
                  <button disabled={data.assignmentSubmittedDate} 
                    className={data.assignmentSubmittedDate? 'background-none radiushalf p-1 border-white' : 'background-none radiushalf p-1 mouseoverstyles1' }
                    onClick={() => {uploadingAssignments(data,index)}}>
                      <img src={data.assignmentSubmittedDate ? gray_upload_assignment : upload_assignment} alt="upload" width="20px" title={data.assignmentSubmittedDate ? '' : 'Upload Assignment'} />
                  </button>
                  {/* <button className={ (!data.studentAssignment.assignmentSubmittedDate || data.studentAssignment.assignmentScorePct) ? 'background-none radiushalf p-1 border-white' : 'background-none radiushalf p-1 mouseoverstyles1' }
                    disabled={!data.studentAssignment.assignmentSubmittedDate || data.studentAssignment.assignmentScorePct}
                    onClick={() => {openDeleteAssignmentPopup(data,index)}}>
                      <img src={(data.studentAssignment.assignmentSubmittedDate && !data.studentAssignment.assignmentScorePct) ? delete_assignment : gray_delete_assignment } alt="delete" width="20px" title={(data.studentAssignment.assignmentSubmittedDate && !data.studentAssignment.assignmentScorePct) ? 'Delete Assignment' : ''} />
                  </button> */}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table> 
      : <NoRecords message={"No Assigned Assignments Found"} /> }
    </div>
  }
  </div>
  </>
  )
}

export default Assigned
