import ReactPixel from 'react-facebook-pixel';

const options = {
  autoConfig: true, // set pixel's autoConfig
  debug: true, // enable logs
};

ReactPixel.init('854259293228714', options);

export const trackPageView = () => {
  ReactPixel.pageView(); // For tracking page view
};

export const trackEvent = (eventName, data = {}) => {
  ReactPixel.track(eventName, data);
};