import { useState, createContext, useContext, useEffect } from "react";
import { usePopup } from "../../components/PopupContext";
import httpsignup from "../../js/http-lms_userprofile";

const MentorDataContext = createContext();

export const useMentors = () => {
  return useContext(MentorDataContext);
};

export const MentorProvider = ({ children }) => {
  const { category, offername } = usePopup();
  const [filteredData, setFilteredData] = useState(null);
  const [allLanguages, setAllLanguages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [offerLoading, setOfferLoading] = useState(false);
  const [catagories, setCatagories] = useState([]);
  const [services, setServices] = useState([]);
  const [mentorsList, setAllMentorsList] = useState(
    Array.from({ length: 4 }, () => [])
  );
  const [selectedOffers, setSelectedOffers] = useState("");
  const [selectedCat, setSelectedCat] = useState("");
  const [storeOfferName, setStoreOfferName] = useState(null);

  const scrollToElementById = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const removeFilteredData = () => {
    setFilteredData(null);
  };
  const updateMentorsList = (data) => {
    setAllMentorsList(data);
  };

  // ------------------------------------------------------
  const getAllLanguages = async () => {
    try {
      const response = await httpsignup.get(
        `/mentor/getAllSkillsquadLanguages`
      );
      if (response && response.data) {
        setAllLanguages(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getCatagories = async () => {
    try {
      const response = await httpsignup.get(
        `/mentor/getSkillsquadMentorCategoryMasterListForSearch?mentorId=${null}
      `
      );
      if (response && response.data) {
        setCatagories(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getServiceByCat = async (id, off) => {
    setOfferLoading(true);
    try {
      const response = await httpsignup.get(
        `/mentor/getSkillsquadMentorOfferMasterListForSearch?masterId=${id}
      `
      );
      if (response && response.data) {
        setServices(response.data);
        console.log(storeOfferName);
        if (off === null) {
          setSelectedOffers(response.data[0].offerId);
          getAllMentorsListByOfferName(response.data[0].offerId);
        }
        setOfferLoading(false);
      }
    } catch (error) {
      setOfferLoading(false);
      console.log(error);
    }
  };
  const getmentorListByLanguage = async (id) => {
    setLoading(true)
    try {
      const response = await httpsignup.get(
        `/mentor/getAllMentorsForLanguage?languageIds=${id}
      `
      );
      if (response && response.data) {
        setAllMentorsList(response.data);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const getAllMentorsListByCat = async (id) => {
    try {
      const response = await httpsignup.get(
        `/mentor/getAllMentorsForCategory?masterId=${id}`
      );
      if (response && response.data) {
        setAllMentorsList(response.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const getAllMentorsListByOfferName = async (id, cat) => {
    if (cat !== null) {
      getServiceByCat(cat);
    }
    try {
      const response = await httpsignup.get(
        `/mentor/getAllMentors?offerId=${id}&search=${null}`
      );
      if (response && response.data) {
        setAllMentorsList(response.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };
  const getId = async (cat, offer) => {
    setLoading(true);
    if (offer === null) {
      setServices([]);
    }
    const response = await httpsignup.get(
      `/mentor/getCategoryOfferIds?categoryName=${cat}&offerName=${offer}`
    );
    if (response && response.data) {
      if (response.data.categoryId && response.data.offerId === null) {
        setSelectedCat(response.data.categoryId);
        setStoreOfferName(null);
        if (response.data.categoryId == 17) {
          getAllMentorsListByCat(17);
        } else {
          getServiceByCat(response.data.categoryId, null);
        }
      } else {
        setSelectedOffers(response.data.offerId);
        setStoreOfferName(response.data.offerId);
        setSelectedCat(response.data.categoryId);
        getAllMentorsListByOfferName(
          response.data.offerId,
          response.data.categoryId
        );
      }
    }
  };
  const handleSearch = async (query) => {
    if (query.trim().length === 0) {
      setLoading(false);
      return getId("recommended", null);
    }
  
    setLoading(true); 
    try {
      const response = await httpsignup.get(
        `/mentor/searchIndex?query=${encodeURIComponent(query)}`
      );
  
      if (response && response.data) {
        setAllMentorsList(response.data); 
      }
    } catch (error) {
      console.error("Error during search:", error); 
    } finally {
      setLoading(false); 
    }
  };
  
  return (
    <MentorDataContext.Provider
      value={{
        allLanguages,
        getAllLanguages,
        filteredData,
        removeFilteredData,
        scrollToElementById,
        catagories,
        services,
        offerLoading,
        getCatagories,
        getServiceByCat,
        getAllMentorsListByCat,
        loading,
        mentorsList,
        getAllMentorsListByOfferName,
        getId,
        selectedOffers,
        selectedCat,
        getmentorListByLanguage,
        updateMentorsList,
        handleSearch
      }}
    >
      {children}
    </MentorDataContext.Provider>
  );
};
