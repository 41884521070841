import React, { useEffect } from 'react'
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useState } from "react";
import 'react-image-crop/dist/ReactCrop.css'
import httpsignup from '../../js/http-lms_userprofile';
import httplmssignup from '../../js/http-lms_userprofile_user';
import "react-datepicker/dist/react-datepicker.css";
import RightSidebar from '../RightSidebar';
import Sidebar from '../Sidebar';
import Select from 'react-select'

const AssignedAssignments = ({ onClose }) => {

  const [defaultuserDetails, setUserDetails] = useState({})
  //form data
  const [errorMessage, setErrorMessage] = useState("");
  const [formErros, setFormErrors] = useState({});
  const [disable, setDisable] = useState(true);
  const [countriesList, setCountriesList] = useState([])
  const [selectedCountryOption, setSelectedCountryOption] = useState(null);
  const [statesList, setStatesList] = useState([])
  const [citiesList, setCitiesList] = useState([])
  const [selectedStateOption, setSelectedStateOption] = useState(null);
  const [selectedCityOption, setSelectedCityOption] = useState(null);
  const [isSubmit, setIsSubmit] = useState(false);
  const [cardHeader, setCardHeader] = useState("Add Profile")
  const [cardButton, setCardButton] = useState("Save")

  useEffect(() => {
    getLoggedInUserDetails()

    httpsignup
    .get("/userprofileLMS/getUserCountries")
    .then((res) => {
      console.log("user countries", res)
      setCountriesList(res.data)
    })
  }, [])
  const getLoggedInUserDetails = async () => {
    console.log("sesion " + sessionStorage.getItem('userId'))
    httpsignup.get(`/skillsquadUserprofileLMS/getSkillsquadUserProfileBySignUpId?signUpId=${sessionStorage.getItem('userId')}`)
      .then((response) => {
        
        if (response.data.lmsProfileId != null) {
          httplmssignup.get(`/userprofileLMS/getByProfileId/${response.data.lmsProfileId}`)
            .then((response) => {
console.log("INDETAILS : " + JSON.stringify(response.data))
setUserDetails(response.data)
setSelectedCountryOption(response.data.country)
setSelectedCityOption(response.data.city)
setSelectedStateOption(response.data.state)
if(response.data.firstName != null){
  setCardHeader("Edit Profile")
  setCardButton("Update")
}

            }).catch((err) => {
              console.log(err)
            })
        }

      }).catch((err) => {
        console.log(err)
      })
  }

  // validate data and enable/disable submit btn
  useEffect(() => {
    setFormErrors(validate(defaultuserDetails))
    enableDisableBtn()
  }, [defaultuserDetails])
  function enableDisableBtn() {
    if (defaultuserDetails.firstName && defaultuserDetails.emailId && defaultuserDetails.phoneNumber && defaultuserDetails.address && defaultuserDetails.cityId && defaultuserDetails.stateId && defaultuserDetails.countryId) {
      console.log("false")
      setDisable(false)
    }
    else {
      console.log("true")
      setDisable(true)
    }
  }
  //updating defaultuserDetails for final submission
  function handleChange(e) {
    setErrorMessage('')
    const { name, value } = e.target;
    setUserDetails((pValue) => {
      console.log(pValue)
      return {
        ...pValue,
        [name]: value
      }
    })
    console.log("after change : ", defaultuserDetails)
  }
  
  // final submission
  // function SubmitFinalData() {

    // httplmssignup.put("/userprofileLMS/updateProfileDetails", defaultuserDetails)
    // .then((response) => {
    //   console.log("Update user profile data : ", response)
      
    //   setErrorMessage(response.data)
    // }).catch((e) => {
    //   console.log("error updating user : ", e.response)
     
    // })

  //   // httpsignup.put('/skillsquadUserprofileLMS/updateSkillsquadUserProfile', defaultuserDetails)
  //   //   .then((response) => {
  //   //     console.log("update response : ", response.data)
  //   //     setErrorMessage("Updated sucessfully")
  //   //     setTimeout(() => {
  //   //       setErrorMessage('')
  //   //     }, 5000)
  //   //   }).catch((e) => {
  //   //     console.log("error update : ", e.response)
  //   //   })

  //   //navigate("/activity")
  // }

  function SubmitFinalData(e) {
    e.preventDefault()
    setFormErrors(validate(defaultuserDetails))
    setIsSubmit(true)
  }
  useEffect(() => {
    if(Object.keys(formErros).length === 0 && isSubmit){
     
    httplmssignup.put("/userprofileLMS/updateProfileDetails", defaultuserDetails)
    .then((response) => {
      console.log("Update user profile data : ", response)
      httplmssignup.put("/skillsquadUserprofileLMS/updateUserName?lmsProfileId="+defaultuserDetails.profileId+"&userName="+defaultuserDetails.firstName)
      .then((response) => {
        
      }).catch((e) => {
        console.log("error updating user : ", e.response)
       
      })
      
      setErrorMessage("Updated sucessfully")
      setTimeout(() => {
        setErrorMessage('')
      }, 5000)
      setIsSubmit(false)
    }).catch((e) => {
      console.log("error updating user : ", e.response)
     
    })
    }
  }, [formErros])
  const validate = (values) => {
    const errors = {};
    const allowaplaspaces = /^[a-zA-Z ]*$/;
    const emailregex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
    const phonenumberregex = /^[6789]\d{9}$/;
    if (!values.firstName) {
      errors.firstName = "Name is required!"
    } else if (!allowaplaspaces.test(values.firstName)) {
      errors.firstName = "First name should contain alphabets and spaces"
    }
    if (!values.emailId) {
      errors.emailId = "Email Id is required!"
    } else if (!emailregex.test(values.emailId)) {
      errors.emailId = "Please enter valid email id"
    }
    if (!values.phoneNumber) {
      errors.phoneNumber = "Phone Number is required!"
    } else if (!phonenumberregex.test(values.phoneNumber)) {
      errors.phoneNumber = "Please enter valid phone number"
    }
    if (!values.address) {
      errors.address = "Address is required!"
    }
    if (!values.cityId) {
      errors.cityId = "City is required!"
    }
    if (!values.stateId) {
      errors.stateId = "State is required!"
    }
    if (!values.countryId) {
      errors.countryId = "Country is required!"
    }
    
    return errors;
  }

 const colourStyles = {
    control: (styles) => ({ 
        ...styles, 
     }),
    option: (provided, state) => ({
      ...provided,
      fontWeight: state.isSelected ? "bold" : "normal",
      color: state.isFocused || state.isSelected ? 'white' : 'black',
      backgroundColor: state.isFocused || state.isSelected ? 'blue' : 'transparent',
      fontSize: "14px",
      paddingTop: "4px",
      paddingBottom: "4px",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: state.data.color,
      fontSize: state.selectProps.myFontSize
    })
  };
  // fetch states list based on selected country
  function handleCountryChange(e){
    console.log("selected country", e)
    //reset states & cities, once changed selected country
    setSelectedCountryOption(e)
    setSelectedStateOption(null);
    setSelectedCityOption(null)
    setStatesList([])
    setCitiesList([])
    setUserDetails.stateId = null;
    setUserDetails.cityId = null;
    // formData.countryId = e.id;
    setUserDetails((pValue) => {
      console.log(pValue)
      return {
        ...pValue,
        ["countryId"]: e.countryId
      }
    })

    // handleChange(e)
    httpsignup
      .get("/userprofileLMS/getUserStates?countryId="+e.countryId)
      .then((res) => {
        console.log("user states", res)
        setStatesList(res.data)
      })
  }

  // triggered after changing the city
  function handleCityChange(e) {
    console.log("selected city : ", e)
    setSelectedCityOption(e)
    // formData.cityId = e.id;
    setUserDetails((pValue) => {
      console.log(pValue)
      return {
        ...pValue,
        ["cityId"]: e.cityId
      }
    })
  }


 // fetch cities list based on selected state
 function handleStateChange(e){
   console.log("selected state", e)
   //reset cities, once changed selected country
   setSelectedStateOption(e)
   setSelectedCityOption(null)
   setCitiesList([])
   // formData.stateId = e.id;
   setUserDetails.cityId = null;
   setUserDetails((pValue) => {
     console.log(pValue)
     return {
       ...pValue,
       ["stateId"]: e.stateId
     }
   })


   // handleChange(e)
   httpsignup
     .get("/userprofileLMS/getUserCities?stateId="+e.stateId)
     .then((res) => {
       console.log("user cities", res)
       setCitiesList(res.data)
     })
 }



  return (
    <div className="profile-popup-container">
    <div className="profile-popup">
    <div style = {{textAlign: "right", color:"black",cursor:"pointer"}} class="mb-3"> <div  onClick={onClose}>X</div></div>
      <p class="d-flex common_main_text_highlight">{cardHeader}</p>
      <p class="common_main_text_normal">Let’s get started with some personal details</p>
      <p class="mt-2 common_main_text_normal">Your online profiles</p>
      <center><p style={{font: 18,fontWeight: 700, color: "green"}}>{errorMessage}</p></center> 
      <Form id="create-User-Form">
                <Row className="pb-3">
                <Col className="col-lg-6 col-12 d-flex flex-column align-items-start">
                <div className="d-flex required align-items-center pb-1" ><img class="img-fluid"
                          src={require("../../images/name_new_icon.png")}
                          alt="Image not found"
                          className="more"
                          width={20}

                        /> <p class="common_main_text_highlight" style={{marginLeft: 5}}>Name</p></div>
                     <Form.Control
                      className={formErros.firstName ? "borderred" : ""}
                      placeholder="Enter first name"
                      autoComplete="off"
                      name='firstName'
                      defaultValue={defaultuserDetails.firstName}
                      onChange={handleChange}
                    />
                    <p className="red font12">{formErros.userName}</p>
                  </Col>
                  <Col className="col-lg-6 col-12 d-flex flex-column align-items-start">
                    <div className="d-flex required align-items-center pb-1"> 
                    <img class="img-fluid"
                          src={require("../../images/email_icon.png")}
                          alt="Image not found"
                          className="more"
                          width={20}

                        />
                    <p style={{marginLeft: 5}} class="common_main_text_highlight">Email</p></div>
                     <Form.Control
                      className={formErros.emailId ? "borderred" : ""}
                      placeholder="e.g. xxxx@gmail.com"
                      autoComplete="off"
                      name='emailId'
                      defaultValue={defaultuserDetails.emailId}
                      disabled
                      onChange={handleChange}
                    />
                    <p className="red font12">{formErros.emailId}</p>
                  </Col>
                  
                
                  
                </Row>
                <Row className="pb-3">
                <Col className="col-lg-6 col-12 d-flex flex-column align-items-start">
                    <div className="d-flex required align-items-center pb-1">
                    <img class="img-fluid"
                          src={require("../../images/phone_icon.png")}
                          alt="Image not found"
                          className="more"
                          width={20}

                        />
                      <p style={{marginLeft: 5}} class="common_main_text_highlight">Phone</p></div>
                      <Form.Control
                      className={formErros.phoneNumber ? "borderred" : ""}
                      placeholder="Enter mobile"
                      autoComplete="off"
                      name='phoneNumber'
                      maxLength={10}
                      defaultValue={defaultuserDetails.phoneNumber}
                      onChange={handleChange}
                    />
                    <p className="red font12">{formErros.phoneNumber}</p>
                  </Col>

                  <Col className="col-lg-6 col-12 d-flex flex-column align-items-start mt-lg-0 mt-3">
                    <div className="d-flex required align-items-center pb-1"> 
                    <img class="img-fluid"
                          src={require("../../images/home_icon.png")}
                          alt="Image not found"
                          className="more"
                          width={20}

                        />
                    <p style={{marginLeft: 5}} class="common_main_text_highlight">Address 1</p></div>
                    <Form.Control
                      className={formErros.address ? "borderred" : ""}
                      placeholder="Street address"
                      autoComplete="off"
                      name='address'
                      defaultValue={defaultuserDetails.address}
                      onChange={handleChange}
                    />
                    <p className="red font12">{formErros.address}</p>
                  </Col>

                 


                </Row>
        
                          <Row className="pb-3">
                <Col className="col-lg-6 col-12 d-flex flex-column align-items-start">
                    <div className="d-flex required align-items-center pb-1">
                    <img class="img-fluid"
                          src={require("../../images/home_icon.png")}
                          alt="Image not found"
                          className="more"
                          width={20}

                        />
                      <p style={{marginLeft: 5}} class="common_main_text_highlight">Address 2</p></div>
                       <Form.Control
                      className=""
                      placeholder="House no."
                      autoComplete="off"
                      name='address2'
                      defaultValue={defaultuserDetails.address2}
                      onChange={handleChange}
                    />
                  </Col>

                  <Col className="col-lg-6 col-12 d-flex flex-column align-items-start mt-lg-0 mt-3">
                    <div className="d-flex required align-items-center pb-1"> 
                   
                    <p style={{marginLeft: 5}} class="common_main_text_highlight">Country</p></div>
                     <Select
                      // value={{value: selectedCountryOption, label: selectedCountryOption}}
                      value={selectedCountryOption}
                      onChange={handleCountryChange}
                      placeholder="Select Country"
                      className="multiselectdropdown"
                      classNamePrefix="react-select"
                      styles={colourStyles}
                      getOptionLabel={option =>
                        `${option.countryName}`
                      }
                      options={countriesList}
                    />
                    <p className="red font12">{formErros.countryId}</p>
                  </Col>

                 


                </Row>
                 <Row className="pb-3">
                <Col className="col-lg-6 col-12 d-flex flex-column align-items-start">
                    <div className="d-flex required align-items-center pb-1">
                    
                      <p style={{marginLeft: 5}} class="common_main_text_highlight">State</p></div>
                      <Select
                      value={selectedStateOption}
                      onChange={handleStateChange}
                      placeholder="Select State"
                      className="multiselectdropdown"
                      classNamePrefix="react-select"
                      styles={colourStyles}
                      getOptionLabel={option =>
                        `${option.stateName}`
                      }
                      options={statesList}
                    />
                    <p className="red font12">{formErros.stateId}</p>
                  </Col>

                  <Col className="col-lg-6 col-12 d-flex flex-column align-items-start mt-lg-0 mt-3">
                    <div className="d-flex required align-items-center pb-1"> 
                    
                    <p style={{marginLeft: 5}} class="common_main_text_highlight">City</p></div>
                     <Select
                      value={selectedCityOption}
                      onChange={handleCityChange}
                      placeholder="Select City"
                      className="multiselectdropdown"
                      classNamePrefix="react-select"
                      styles={colourStyles}
                      getOptionLabel={option =>
                        `${option.cityName}`
                      }
                      options={citiesList}
                    />
                    <p className="red font12">{formErros.cityId}</p>
                  </Col>

                 


                </Row>

                <div>
                  <Row >
                    
                    <Col className="col-lg-2 col-12 d-flex flex-column align-items-start mt-lg-0 mt-3">
                      <button disabled={disable} className={disable ? "profile-buttons-update mt-3 mb-5 grey" : "profile-buttons-update mt-3 mb-5 common_main_button"} onClick={SubmitFinalData}>
                        {cardButton}
                      </button>
                    </Col>
                 

                  </Row>
                </div>
              </Form>
      {/* <div class="activityButton" onClick={allcourses} style={{width: "100%", textAlign:"center"}}>See More Courses</div> */}
      
    </div>
  </div>
  )
}

export default AssignedAssignments;
