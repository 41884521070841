import React from "react";
import { useState, useEffect, useRef } from "react";
import httpskillsquaduserprofile from "../../js/http-lms_userprofile"
import 'video-react/dist/video-react.css';
import { Player, ControlBar, PlaybackRateMenuButton, ForwardControl, ReplayControl } from 'video-react';
import 'react-toastify/dist/ReactToastify.css';
import Sidebar from "../../pages/Sidebar";
import { Navigate, useNavigate } from "react-router-dom";
import arrow from "../../images/left-arrow_icon.png"
import Discussion from "../Discussion";
import RightSidebar from "../RightSidebar";
import GreenCheckbox from "../../images/tick mark.svg";
import httpsignup from "../../js/http-lms_userprofile";
import "../../styles/VideoFeedback.css";


const CurricullumVideosVideo = (props) => {
    const [imageClicked, setImageClicked] = useState(false);
    const [selectedEmoji, setSelectedEmoji] = useState(null);
  
    //
    const [emojis, setEmojis] = useState([]);
    const [reasons, setReasons] = useState([]);
  
    // selected reason
    // const [selectedReason, setSelectedReason] = useState(null);
    const [selectedReasons, setSelectedReasons] = useState([]); 
  
    // store comments
    const [comments, setComments] = useState("");
    const [lmsMasterId, setLmsMasterId] = useState(null);
    const [feedbackCheck, setFeedbackCheck] = useState(false);
  
    const [reasonData, setReasonData] = useState({
      "emojiId": 1,
      "reasonId": [],
      "commentText": "good",
      // profileId: sessionStorage.getItem("profileId"),
      // lmsTopicMasterId: 3,
      "profileId": sessionStorage.getItem("circullumpld"),
        "lmsTopicMasterId": sessionStorage.getItem("topicId")
        // sessionStorage.getItem(circullumpld)
    });
  
    // const sendReasonData = async () => {
      
    //   console.log(reasonData, "this is reason data");
    //   const response = await httpsignup
    //     .post("/dashBoardLMS/saveUserVideoFeedback", reasonData)
    //     .then((response) => {
    //       console.log(response.data);
    //     })
    //     .catch((error) => console.log(error));
    // const dummy = selectedReasons.map(reason => reason.reasonId).toString().replace("[","").replace("]","");
    // console.log("dummy",selectedReasons.map(reason => reason.reasonId));
    // };
    const sendReasonData = async () => {
      const updatedReasonData = {
        
        "emojiId" : selectedEmoji?.emojiId,
        // reasonId: JSON.stringify(selectedReasons.map(reason => reason.reasonId)), // Convert the array of reason IDs to a string
        "reasonId" : JSON.stringify(selectedReasons), 
        
        
        "commentText" : comments,
        "profileId" : sessionStorage.getItem("circullumpId"),
        "lmsTopicMasterId" : sessionStorage.getItem("topicId")
      };
      console.log(updatedReasonData, "this is reason data");
      const response = await httpsignup.post("/dashBoardLMS/saveUserVideoFeedback", updatedReasonData)
        .then((response) => { console.log(response.data) 
          checkComment()
        })
        .catch(error => console.log(error));
    };
  
    const handleReasonData = (e) => {
      const { name, value } = e.target;
      setReasonData((prev) => ({
        ...prev,
        [name]: value,
      }));
    };
  
    // console.log(selectedReason);
    const getEmojisData = async () => {
      const response = await httpsignup
        .get("/dashBoardLMS/getAllFeedbackEmojiMaster")
        .then((response) => {
          console.log("this is the emojis data", response.data);
          setEmojis(response.data);
        })
        .catch((error) => console.log(error));
    };
    useEffect(() => {
      getEmojisData();
    }, []);
  
    const getSelectedEmojiReasons = async (id) => {
      const response = await httpsignup
        .get(`dashBoardLMS/getFeedbackReasonMasterByEmojiId?emojiId=${id}`)
        .then((response) => {
          console.log("reasons data", response.data);
          setReasons(response.data);
        })
        .catch((error) => console.log(error));
    };
  
    const handleImageClick = (emoji) => {
      setSelectedEmoji(emoji);
      getSelectedEmojiReasons(emoji.emojiId);
      setImageClicked(true);
  
      // Reset selected reason and comments
      // setSelectedReasons([]);
      setSelectedReasons([]);
      setComments("");
    };
    // const getEmojiClass = (emoji) => {
    //   return selectedEmoji === emoji ? "highlighted-text" : "";
    // };
  
    // Handle selection of reason
    // const handleReasonSelection = (reason) => {
    //   setSelectedReason(reason);
    // };
    const [idValue,setIdValue] = useState(null);
    const handleReasonSelection = (reason) => {
  
      // setSelectedReasons(prevSelectedReasons => {
      //   if (prevSelectedReasons.includes(reason.reasonId)) {
      //     return prevSelectedReasons.filter(r => r !== reason.reasonId);
      //   } else {
      //     return [...prevSelectedReasons, reason.reasonId];
      //   }
      // });
      console.log("my reason",reason)
      // setSelectedReasons({[...selectedReasons],reasonId:reason.reasonId})
      const newId = reason.reasonId;
      const updatedIds = selectedReasons.filter(id => id !== newId)
      if(!selectedReasons.includes(newId)){
        setSelectedReasons([...selectedReasons,newId])
      }
  
      
      console.log("all reasons",selectedReasons)
  
    };
    
  
    // Handle input change for comments
    const handleCommentsChange = (event) => {
      setComments(event.target.value);
    };
  
    // State to track which checkboxes are checked
    const [checkedStates, setCheckedStates] = useState(
      new Array(reasons.length).fill(false)
    );
  
    // Handle checkbox change
    const handleCheckboxChange = (index) => {
      const updatedCheckedStates = [...checkedStates];
      updatedCheckedStates[index] = !updatedCheckedStates[index];
      setCheckedStates(updatedCheckedStates);
    };
  

    // ------
    const [videoURL, setVideoURL] = useState('')
    const [isSmallDev, setIsSmallDev] = useState(false)
    const navigate = useNavigate();
    const myCallback = () => (
        httpskillsquaduserprofile.put(`/programsLMS/saveOrUpdateCircullumVideo?profileId=${sessionStorage.getItem('circullumpId')}&programId=${sessionStorage.getItem("myCourseProgramId")}&topicIndex=${sessionStorage.getItem("cirricullumIndex")}`).then((response) => {
            console.log("video unlock count updated:", response.data)
           navigate("/day-circullum")
        }).catch((err) => {
            console.log(err)
        })
        
    );

    function checkComment(){
        httpskillsquaduserprofile.get(`/dashBoardLMS/getUserVideoFeedback?profileId=${sessionStorage.getItem("circullumpId")}&topicId=${sessionStorage.getItem("topicId")}`)
        .then((response) => {
    if(response.data.feedbackId != null){
      setFeedbackCheck(true)
    }
          
          
        }).catch((err) => {
          console.log(err)
        })
      }

    useEffect(() => {
          //console.log("VIDEO DATA : " + JSON.stringify(props.videoCount))
          console.log("VIDEO DATA : " + JSON.stringify(sessionStorage.getItem("cirricullumUrl")))
        
        setVideoURL(sessionStorage.getItem("cirricullumUrl"))
        if (window.innerWidth < 900) {
            setIsSmallDev(true)
        } else {
            setIsSmallDev(false)
        }
        checkComment()
    }, [props.selectedTopicData])

    function dayChallenge() {
        navigate("/day-circullum")
      }

    return (
        <div>
            <Sidebar />


<div className="jobs-dashboard-container">

    {/* <Navb /> */}

    <div className="px-md-5  mt-5">
    <h1 className="mt-2 mb-4 d-flex align-items-center"><img src={arrow} width={30} alt="arrow" style={{ cursor: "pointer" }} onClick={dayChallenge} /><div style={{ marginLeft: 5 }} class="common_main_heading">{sessionStorage.getItem("cirricullumTopic")}</div></h1>
        <section class="section">
            <div class="container">

                <div class="row ">

                    <div class="col-lg-9">
                        <div class="row">
                            <div class="">
                                <div class="mb-4">
                                    <div className="col-lg-12">
                                        <div className="row mt-3" >
           
           
            <Player playsInline src={videoURL} fluid={false} width={745} onEnded={() => myCallback()}>
                <ControlBar autoHide={false} disableDefaultControls={false}>
                    <ReplayControl seconds={5} order={2.1} />
                    <ForwardControl seconds={5} order={3.1} />
                    <PlaybackRateMenuButton rates={[5, 2, 1.5, 1, 0.5, 0.1]} />
                </ControlBar>
            </Player>
            </div>

            <div class="row">
{ sessionStorage.getItem("showDiscussion") && <Discussion programId={sessionStorage.getItem("myCourseProgramId")} topicId={sessionStorage.getItem("topicId")} profileId = {sessionStorage.getItem("circullumpId")}/>}
                          </div>
                                                </div></div></div></div></div></div></div>

                    </section>

                </div>
                { sessionStorage.getItem("showDiscussion") && 
                <div style={{ paddingLeft: "4.5rem" }}>
          <div>
            <div
              class="modal fade "
              id="exampleModalToggle"
              aria-hidden="true"
              aria-labelledby="exampleModalToggleLabel"
              tabindex="-1"
            >
              <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content main-modal">
                  <div class="modal-header bg-white">
                    {/* <h1 class="modal-title fs-5" id="exampleModalToggleLabel">
                Modal 1
              </h1> */}
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="modal-body modal-para1">
                    Share your experience
                  </div>
                  <div className="modal-emoji">
                    {emojis.map((emoji, index) => (
                      <div key={index}>
                        <img
                          src={emoji.emojiUrl}
                          alt={emoji.emojiText}
                          onClick={() => handleImageClick(emoji)}
                          style={{ cursor: "pointer" }}
                          className={
                            selectedEmoji === emoji ? "" : "greyed-out"
                          }
                        />
                        {/* <p className={`emoji-text ${getEmojiClass(emoji)}`}>
                          {emoji.emojiText}
                        </p> */}
                        <p
                          className={`emoji-text ${
                            selectedEmoji === emoji ? "before-greyed-out-text" : "greyed-out-text"
                          }`}
                        >
                          {emoji.emojiText}
                        </p>
                      </div>
                    ))}

                    
                  </div>
                  <div>
                    {imageClicked && (
                      <div className="float-div">
                        <p className="para2">What did you like ?</p>
                        <p className="para3">
                          We hope you re looking forward to your course! It
                          would be lovely to know what you liked about the
                          course.
                        </p>

                        
                        <div style={{ cursor: "pointer" }}>
                          
                          <div className="checkbox-container">
                            {reasons.map((reason, index) => (
                              <label
                                key={index}
                                className={`checkbox-label ${
                                  checkedStates[index] ? "checked" : ""
                                }`}
                                onClick={() => handleReasonSelection(reason)} // Call handleReasonSelection on click
                              >
                                {/* {reason.label} */}
                                {reason.reasonText}

                                <input
                                  type="checkbox"
                                  className="custom-checkbox"
                                  name="reason"
                                  value={reason.value}
                                  // checked={checkedStates[index]}
                                  onChange={() => handleCheckboxChange(index)}
                                  checked={selectedReasons === reason} // Check if the reason is selected
                                />
                                <span className="checkmark"></span>
                              </label>
                            ))}
                          </div>
                        </div>

                        <div>
                          {" "}
                          <textarea
                            style={{
                              width: "100%",
                              height: "70px",
                              borderRadius: "9px",
                              paddingTop: "1rem",
                              paddingLeft: "0.5rem",
                            }}
                            className="comment-box"
                            placeholder="Add your comments..."
                            // value={comment}
                            // onChange={handleInputChange}
                            // maxLength={maxLength}

                            value={comments}
                            onChange={handleCommentsChange}
                          />{" "}
                          <button
                            className="submit-btn"
                            data-bs-target="#exampleModalToggle2"
                            data-bs-toggle="modal"
                            onClick={sendReasonData}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    )}
                  </div>


                </div>
              </div>
            </div>

           {feedbackCheck ? <div style={{color: "green",fontWeight: 600}}>Submitted Feedback</div> : <button
              class="btn btn-primary"
              data-bs-target="#exampleModalToggle"
              data-bs-toggle="modal"
              style={{ marginBottom: "3rem" }}
            >
              Give Feedback
            </button>}
          </div>
          <div
            class="modal fade"
            id="exampleModalToggle2"
            aria-hidden="true"
            aria-labelledby="exampleModalToggleLabel2"
            tabindex="-1"
          >
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content main-modal2">
                <div class="modal-header bg-white">
                  <h1 class="modal-title fs-5" id="exampleModalToggleLabel2">
                    {/* Modal 2 */}
                    <img
                      className="header-image"
                      src={GreenCheckbox}
                      alt="checkbox"
                    />
                  </h1>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="modal-body modal2-para1">Feedback Submitted</div>
                <div className="modal2-para2">
                  Thank you for your valuable feedback. Your input helps us
                  improve Skillsquad.
                </div>
                <div className="close-button-div">
                  <button
                    className="close-button"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    Close
                  </button>
                </div>
                {/* <div class="modal-footer">
                  <button
                    class="btn btn-primary"
                    data-bs-target="#exampleModalToggle"
                    data-bs-toggle="modal"
                  >
                    Back to first
                  </button>
                </div> */}
              </div>
            </div>
          </div>
        </div>
}
      </div>
      <RightSidebar />
   
            </div>
           
        
       // <Video src={videoURL} controls={['PlayPause',  'Time', 'Volume', 'Fullscreen']} height={isSmallDev ? '' : '440px'} controlsList="nodownload" className="videotag radius10" onEnded={() => myCallback()}></Video>
       
        
        
       
        
        
    )
}
export default CurricullumVideosVideo;