
import '../styles/LockedPopup.css';
import React, { useEffect, useState } from "react";
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Marquee from "react-fast-marquee"
import { useNavigate } from "react-router-dom";
import star from "../images/star.png";
import httpsignup from '../js/http-lms_userprofile';



const LockedPopup = ({ onClose }) => {
  const navigate = useNavigate();
  function allcourses(){
    navigate("/dashboard")
  }
  function myCourses(){
    navigate("/my-course")
  }
  const getLoggedInUserDetails = async () => {
    console.log("sesion " + sessionStorage.getItem('userId'))
    httpsignup.get(`/skillsquadUserprofileLMS/getSkillsquadUserProfileBySignUpId?signUpId=${sessionStorage.getItem('userId')}`)
      .then((response) => {
        setUserType(response.data.userTypeMasterId)

      }).catch((err) => {
        console.log(err)
      })
  }
  
useEffect(() => {
  getLoggedInUserDetails()
}, [])
  const [userType, setUserType] = useState(0);
  return (
    <div className="locked-popup-container" style={{marginTop : 20}}>
      
       
       {userType === 1 || userType === 2 ?
       <div className="locked-popup">
       <div style = {{textAlign: "right", width: '30%', color:"white",cursor:"pointer"}} class="mb-3"> <div  onClick={onClose}>X</div></div>
      
        <div class="locked-popup-card">
            <div class="locked-popup-div">
                <div>
               
<div style={{textAlign: "left"}}><div class="locked-pop-up-locked"> <FontAwesomeIcon icon={faLock} style={{marginRight:10}} />Content Locked</div></div>
<div class="locked-popup-content mt-3 mb-3">{sessionStorage.getItem('userName')}, You are using Skillsquad's Free Experience</div>
                </div>
                </div>
                <div style={{padding: "2%"}}>
                <div style={{textAlign: "left"}}><div class="locked-join">Join the paid program to experience live learning & more</div></div>
              
                <div className="mb-3">  <Marquee >
                 
                  <div class="locked-marquee d-flex" style={{fontWeight: 400}}><div style={{color:"#5627ff", marginRight: 10}}><img src={star} alt="star" width={17}/></div>Unlock Live Classes</div>
                            <div class="locked-marquee d-flex"><div style={{color:"#5627ff", marginRight: 10}}><img src={star} alt="star" width={17}/></div>Instructor & Mentors</div>
                            <div class="locked-marquee d-flex"><div style={{color:"#5627ff", marginRight: 10}}><img src={star} alt="star" width={17}/></div>Teaching Assistants</div>
                            <div class="locked-marquee d-flex"><div style={{color:"#5627ff", marginRight: 10}}><img src={star} alt="star" width={17}/></div>Skillsquad Community</div>
                            <div class="locked-marquee d-flex"><div style={{color:"#5627ff", marginRight: 10}}><img src={star} alt="star" width={17}/></div>Resume Building</div>
                            <div class="locked-marquee d-flex"><div style={{color:"#5627ff", marginRight: 10}}><img src={star} alt="star" width={17}/></div>Placements & Assistance</div>
                            <div class="locked-marquee d-flex"><div style={{color:"#5627ff", marginRight: 10}}><img src={star} alt="star" width={17}/></div>Learn in Preferred Language</div>
                            <div class="locked-marquee d-flex"><div style={{color:"#5627ff", marginRight: 10}}><img src={star} alt="star" width={17}/></div>Mock Interviews & Much More</div>
                        </Marquee>
                        
                        </div>
                      <div style={{textAlign: "left"}} class="mb-3 mt-3"><div class="locked-ways">Ways to join the premium program</div></div>  
                        <div style={{display: "inline-flex", margin: "0 auto"}} class="align-items-center"> <div className='locked-join-box-button button-trans'>Talk to Career Counsellor</div> <div class="activityButton" onClick={allcourses} style={{width: "60%", textAlign:"center", padding: "2%"}}>See More Courses</div></div>
                
                </div>
                </div>
                </div>:
                <div className="locked-popup">
                <div style = {{textAlign: "right", color:"white",cursor:"pointer"}} class="mb-3"> <div  onClick={onClose}>X</div></div>
               
                <div class="locked-popup-card-paid">
                <div class="locked-popup-div">
                    <div>
                   
    <div style={{textAlign: "left"}}><div class="locked-pop-up-locked"> <FontAwesomeIcon icon={faLock} style={{marginRight:10}} />Content Locked</div></div>
    <div class="locked-popup-content mt-3 mb-3">{sessionStorage.getItem('userName')}, please complete given Task to unlock the Feature</div>
                    </div>
                    </div>
                    <div style={{padding: "2%"}}>
                    
                          <div style={{display: "inline-flex", margin: "0 auto"}} class="align-items-center"> <div className='locked-join-box-button button-trans' style={{fontSize: 20, backgroundColor: '#5627ff', fontWeight: 500}} onClick={myCourses}>Continue Learning</div> </div>
                    
                    </div>
                    </div>
                    </div>
      }
      
    </div>
  );
};

export default LockedPopup;