import React, { useEffect, useState } from "react";
import "../styles/Dashboard.css";
import Navb from "./Navb";
import "../styles/AdminProfile.css";
import Sidebar from "./Sidebar";
import { useNavigate } from "react-router-dom";
import Spinner from 'react-bootstrap/Spinner';
import httpprograms from "../js/http-lms_programs"
import NoRecFound from "../components/NORecFound";
import showPwdImg from '../images/showpwd.svg';
import play from '../images/play_button.png';
import trainer from "../images/backend_trainer.webp"
import arrow from "../images/arrow.png"
import httpsignup from '../js/http-lms_userprofile';
import httplmssignup from '../js/http-lms_userprofile_user';
import courseImage from "../images/java-icon.png";
import correct from "../images/correct_icon.png";
import time from "../images/time_green.png";
import langauge from "../images/language_green.png";
import download from "../images/download_button.png";
import fire from "../images/fire-icon.png";
import arrow_i from "../images/arrow_icon.png";
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from "moment/moment";
import useRazorpay from "react-razorpay";
import { useCallback } from "react";
import { trackEvent } from '../facebookPixel';


const TrailPrograms = () => {

  const [data, setData] = useState([])
  const [refresh, setRefresh] = useState(false)
  const [loading, setLoading] = useState(0)
  const [purchased, setPurchased] = useState()

  const [error, setError] = useState()
  const [userType, setUserType] = useState(0);


  const navigate = useNavigate();

  function handleClick(pId,url) {
    sessionStorage.setItem("myCourseProgramId", pId)
    sessionStorage.setItem("seoUrl",url)
    navigate("/day-circullum");
  }

  function handleClickStart() {

    navigate("/my-course");
  }

  function handleCourse(pId){
    sessionStorage.setItem("seoUrl",pId)
    navigate("/courses/"+pId);
  }

  function BuyCourse(pId){
    sessionStorage.setItem("courseProgramId", pId)
    //navigate("/coursePage");
  }


  useEffect(() => {
    trackEvent('ViewContent');
    getLoggedInUserDetails()
  }, [])
  useEffect(() => {
    if (refresh) {
      programCards()
    }
  }, [refresh])

  const getLoggedInUserDetails = async () => {
    console.log("sesion " + sessionStorage.getItem('userId'))
    httpsignup.get(`/skillsquadUserprofileLMS/getSkillsquadUserProfileBySignUpId?signUpId=${sessionStorage.getItem('userId')}`)
      .then((response) => {
        setUserType(response.data.userTypeMasterId)
        sessionStorage.setItem("allCoursePageName", response.data.userName)
        sessionStorage.setItem("allCoursePageEmail", response.data.userEmail)
        sessionStorage.setItem("allCoursePageMobile", response.data.mobileNumber)
        if(response.data.lmsProfileId != null){
          programCards(response.data.lmsProfileId)
        }else{
          programCards(0)
        }
       
        

      }).catch((err) => {
        console.log(err)
      })
  }
  const programCards = async (id) => {

    // const response=await axios.get("http://34.199.176.17:13052/programsLMS/getModulesTopicCount")
    // httpprograms.get("/programsLMS/getModulesTopicCount")
    //   .then((response) => {
    //     console.log("get modules count", JSON.stringify(response.data))
    //     setData(response.data)
    //     setLoading(1)
    //     setRefresh(false)
    //   }).catch((err) => {
    //     setLoading(1)
    //     setError("Bad request")
    //   })

      httpprograms.get("/usersLMS/getAllCourseList?profileId="+id)
      .then((response) => {
        console.log("getAllCourseList : ", JSON.stringify(response.data))
        setData(response.data)
        setLoading(1)
        setRefresh(false)
      }).catch((err) => {
        setLoading(1)
        setError("Bad request")
      })

  }

  function PricingNav() {
    navigate("/pricing")
  }

  function ProfileNav() {
    navigate("/add-user")
  }

  function changeBackground(e) {
    e.target.style.background = 'red';
  }

  const [Razorpay] = useRazorpay();

  const handlePayment = useCallback ((payAmount) => {
    httpsignup.post(`/dashBoardLMS/web?amount=${payAmount}&currency=INR&auth=cnpwX2xpdmVfc2NQV296M29PUjZkSWQ6STZ3YW5QTVZVU0I5bjlxOUJ3RmtTZVhh`)
    .then((response) => {
        const options: RazorpayOptions = {
            key: "rzp_live_scPWoz3oOR6dId",
            amount: parseInt(payAmount) * 100,
            currency: "INR",
            name: "Skillsquad",
            description: "Test Transaction",
            image: require("../images/skillsquad_logo_fav_png.png"),
            order_id: response.data.orderId, 
            handler: (res) => {
                console.log("HHHH : " + JSON.stringify(res));
                trackEvent('Purchase', { value: payAmount, currency:  "INR"});
                httpsignup.post(`/usersLMS/saveCourseUser?name=${sessionStorage.getItem("allCoursePageName")}&email=${sessionStorage.getItem("allCoursePageEmail")}&phoneNumber=${sessionStorage.getItem("allCoursePageMobile")}&programId=${sessionStorage.getItem("courseProgramId")}&purchaseType=${sessionStorage.getItem("purchaseType")}`)
                    .then((response) => {
                        console.log('RESPONSE : ' + JSON.stringify(response.data))

                        navigate("/my-course")
                    }).catch((err) => {
                        console.log(err)
                    })

            },
            prefill: {
                // sessionStorage.getItem("coursePageName")
                // sessionStorage.getItem("coursePageEmail")
                // sessionStorage.getItem("coursePageMobile")
                name: sessionStorage.getItem("allCoursePageName"),
            email: sessionStorage.getItem("allCoursePageEmail"),
            contact: sessionStorage.getItem("allCoursePageMobile"),
            },
            notes: {
                address: "Razorpay Corporate Office",
            },
            theme: {
                color: "#5627ff",
            },
        };

        const rzpay = new Razorpay(options);
        rzpay.open();
       
    }).catch((err) => {
        console.log(err)
    })
    console.log(JSON.stringify(payAmount))
   
}, [Razorpay]);

function purchaseType(type){
  sessionStorage.setItem("purchaseType" ,type)
}

  return (
    <>
      <Sidebar />

      <div className="jobs-dashboard-container mx-4">

        {/* <Navb /> */}

        <div className="px-md-5 mt160">
          <div class="allcourse-heading"><div class="common_main_heading">Browse our Courses</div>
            <center><div class="common_main_sub_heading mt-3">Keep your skills up-to-date with access to hundreds of the latest courses across Data Science, Machine Learning, DevOps, Cyber Security, full Stack Development, and People and Process Certifications.</div></center>
          </div>
          <h1 className="ps-md-5 mt-5 mb-4 common_main_text_highlight">Choose your career path</h1>
          <section class="section">
            <div class="container">

              <div class="row">

                <div class="col-lg-12">
                  <div class="row">
                    <div class="">
                      <div class="section-title  mb-4 pb-2">
                        <div className="col-lg-12">
                          <div className="row">


                            {loading === 0 ? <div className="d-flex py-5 justify-content-center w-100"><Spinner animation="border" variant="lightsecondary" /> </div> :
                              error ? <div className='col-12 text-center'> <NoRecFound error="No programs found." /> </div> :

                                data.map((res) => {
                                  return (
                                    <div class="col-4">

                                      <div class="allcourse-cards mt-5 pb-3">
                                        <div class="allcourse-video-card mb-3" >
                                          {/* <div class="allcourses-toprated">Top rated</div> */}
                                          <img src={res.programCourseImage} alt="play" style={{ borderRadius: "15px 15px 0px 0px" }} />
                                          {/* <div class="d-flex mt-4">
                                            <div class="allcourse-duration-card align-items-center d-flex"><img src={time} width={20} height={20} alt="time" /><div>4 Months</div></div>
                                            <div class="allcourse-duration-card align-items-center d-flex"><img src={langauge} width={20} height={20} alt="language" /><div>English</div></div>
                                          </div> */}

                                        </div>
                                        <div style={{ padding: "2%" }}>
                                          {/* <div class='liveclass'>
                                            <div class="left"></div>

                                            <div class="dw-right" style={{ cursor: "pointer",fontSize: 20,padding:"10px 20px 10px 20px" }} ><div>

                                              <div onClick={() => {  window.open(res.brochureURL, "_blank")  }} class="hover-text"> <FontAwesomeIcon icon={faDownload} />
                                                <span class="tooltip-text tool-right"style={{ cursor: "pointer",fontSize: 14,padding:5 }}>Download Brochure</span>
                                              </div>

                                            </div></div>
                                          </div> */}
                                          <div class="d-flex mx-2"><div class="common_main_sub_highlight mb-2">{res.programName}</div></div>
                                          {/* <div class="d-flex align-items-center"><img src={correct} alt="correct" width={25} height={25} /> <div class="common_main_text_normal">10+ Hand-on Projects</div></div>
                                          <div class="d-flex align-items-center"><img src={correct} alt="correct" width={25} height={25} /><div class="common_main_text_normal">Live Included</div></div>
                                          <div class="d-flex align-items-center"><img src={correct} alt="correct" width={25} height={25} /><div class="common_main_text_normal">1 Years Job Assistance</div></div>
                                           */}
                                          {/* <div class="row">
  <div class="col">
    <div class="row">
      <div class="col-1">4.7</div>
      <div class="col-2"><img src={require("../images/5 stars.png")} /></div>
      <div class="col-2">(2624)</div>
      <div class="col-2">|</div>
      <div class="col-2">42 hours +</div>
      </div></div></div> */}
                                          <div class="d-flex mb-3 common_main_text_tag mx-2">
                                            <div><span class="common_main_text_tag_highlight">{res.skillsquadCourses.rating}</span> <img src={require("../images/5 stars.png")} />
                                            {/* {res.programName === 'Java Backend Course (English)' ? <span style={{color:"#8F9BB7"}}>(2624) | 120 hours +</span> : <span style={{color:"#8F9BB7"}}>(2624) | 42 hours +</span>} */}
                                            <span style={{color:"#8F9BB7"}}>({res.skillsquadCourses.ratingUser}) | {res.skillsquadCourses.duration} hours </span>
                                            </div>
                                           
                                          </div>
                                          {/* <div class="d-flex mb-3">
                                            <div>Learn: Figma, UI Design, UX methods, </div>
                                          </div> */}
                                          <hr class="mx-2" style={{ height: 0, border: "none", border: "1px solid #5627FF" }}></hr>
                                          <div class="row mx-2">
                                            <div class="col-2 common_main_sub_highlight">₹{res.skillsquadCourses.selfPacedDiscountedAmount}</div>
                                            <div class="col common_main_text_tag linestrike" style={{color:"#8F9BB7"}}>₹{res.skillsquadCourses.selfPacedOrginalAmount}</div>
                                            <div class="col-4 laptop_price_off common_main_text_tag_highlight">{res.selfPacedPercentageOff}% OFF</div>
                                          </div>
                                          <div class="row align-items-center mx-2">
                                            <div class="col common_main_text_tag" style={{ color: "#E34444" }}><img
                                              width={15}
                                              loading="lazy"
                                              alt=""
                                              src={require("../images/alarm.png")}
                                            /> {moment(res.skillsquadCourses.deadlineTimer).fromNow(true)} left at this price!</div>
                                          </div>

                                          <hr class="mx-2" style={{ height: 0, border: "none", border: "1px solid #5627FF" }}></hr>
                                          <div class="row mx-2 common_main_text_tag">
                                            <div class="col" style={{textAlign:"left",color:"#8F9BB7"}}>
                                            {/* {moment(res.skillsquadCourses.batchStartDate).format("MMMM D, YYYY")} */}
                                           
                                            
                                            </div>
                                            <div class="col common_main_text_tag_highlight" style={{textAlign:"right",cursor:"pointer"}} onClick={() => {handleCourse(res.skillsquadCourses.seoUrl)}}>
                                              Read more <FontAwesomeIcon icon={faArrowRight} />
                                            </div>
                                          </div>
                                          {res.programBroughtStatus === 'brought' ? 
                                        <div class="row mx-2 mt-2">
                                        <div class="col text-center common_main_text_highlight common_main_button" style={{border:"1px solid grey", borderRadius:"5px",padding: "2%"}} onClick={handleClickStart}>Continue Learning</div>
                                       
                                      </div>  :
                                       <div class="row mx-2 mt-2">
                                       <div class="col-5 text-center common_main_text_highlight common_main_button_edit_nocircle" style={{border:"1px solid grey", borderRadius:"5px",padding: "2%"}} onClick={()=>{ handleClick(res.skillsquadCourses.lmsProgramId,res.skillsquadCourses.seoUrl)}}>Free Trial</div>
                                      <div class="col"></div>
                                       <div class="col-5 text-center common_main_text_highlight common_main_button_black" style={{border:"1px solid grey", borderRadius:"5px",padding: "2%"}} onClick={() => {BuyCourse(res.skillsquadCourses.lmsProgramId);handlePayment(res.skillsquadCourses.selfPacedDiscountedAmount); purchaseType(2);}}>Buy Now</div>
                                     </div>
                                        }
                                         
                                          {/* {purchased !== res.program_id ?
                                          <div class='liveclass allCourses-points mt-3'>
                                            <div class="left">

                                            </div>

                                            <div class="right"><div>

                                              <div class="d-flex align-items-center common_main_text_normal"><img src={fire} alt="correct" width={25} height={25} />Limited Seats Available</div>

                                            </div></div>
                                          </div> :  <></>}

                                          <div class="common_main_text_normal mt-3"><div ><div>BATCH START ON</div><div>{res.courseStartDate}</div></div></div>

                                          {purchased === res.program_id ?
                                            <div class="allcourse-start-button mb-3 mt-3">
                                              <div class="allcourse-start-button-inside button-trans" onClick={handleClickStart} style={{ width: "100%" }}><center>Continue Learning</center></div>
                                            </div> :
                                            <div class=" d-flex allcourse-start-button mb-3 mt-3">
                                              {userType === 1  ? <div class="allcourse-start-button-inside-green button-trans" onClick={ProfileNav}>Free Trail</div> 
                                              : <div class="allcourse-start-button-inside-green button-trans common_main_button_black" onClick={PricingNav}>Buy Now</div>}
                                              {userType === 1  ? <div class="allcourse-start-button-inside button-trans" onClick={PricingNav}>Buy Now</div> 
                                              : <div class="allcourse-start-button-inside button-trans common_main_button" onClick={() => { handleClick(res.program_id) }}>Continue Learning</div>}
                                            
                                              
                                            </div>
                                          }

                                          <div onClick={() => {  window.open(res.programURL, "_blank")  }} style={{ textAlign: "center", color: "#5627ff", cursor: "pointer" }} class="align-items-center button-trans">Know More<img src={arrow_i} alt="correct" width={25} height={25} /></div>
                                         */}
                                        </div>
                                      </div>

                                    </div>
                                  )
                                })}





                          </div>
                        </div>

                      </div>
                    </div>
                  </div>


                </div>

                {/*Filter section*/}
              </div>

            </div>

          </section>



        </div>


      </div>


    </>
  );
};

export default TrailPrograms;
