import WhiteSkillLogo from "../../images/whitelogo.png";
import FooterLogo from "../../images/skillsquad_logotype.svg";
import Twitter from "../../images/TwitterLogo.png";

import facebook from "../../images/FacebookLogo.svg";
import instagram from "../../images/InstaLogo.svg";
import Linkedin from "../../images/LinkedinLogo.svg";
import { Gradient } from "@material-ui/icons";
import GardientImage from "../../images/gradientbg.png";
import "./NewFooter.css";
import { useNavigate } from "react-router-dom";

export const NewFooter = () => {
  const navigate = useNavigate();

  function termsAndConditions() {
    // navigate("/terms-and-conditions");
    window.location.href = "/terms-and-conditions"
  }

  function refundPolicy() {
    // navigate("/refund-policy");
    window.location.href = "/refund-policy"
  }

  function codeOfConduct() {
    // navigate("/code-of-conduct");
    window.location.href = "/code-of-conduct"
  }

  function privacyPolicy() {
    // navigate("/privacy-policy");
    window.location.href = "/privacy-policy"
  }

  function learn() {
    navigate("/course");
  }

  function project() {
    navigate("/projects");
  }
  return (
    <div>
      <footer>
        <div
          className="container-fluid background-image-div"
          style={{
            paddingBottom: "5rem",
          }}
        >
          <div
            className="container-fluid "
            style={{
              backgroundColor: "", //rgba(15, 11, 51, 1)
              color: "white",
              paddingTop: "90px",

              // paddingLeft:'100px'
            }}
          >
            <div className="row d-flex justify-content-evenly ">
              <div className="col-lg-3 col-md-6 d-none d-md-block">
                <img
                  src={WhiteSkillLogo}
                  alt="Logo"
                  style={{ width: "50%", height: "auto" }}
                />
                <p
                  style={{
                    paddingTop: "15px",
                    fontSize: "14px",
                    color: "rgba(173, 185, 199, 1)",
                    lineHeight: "35px",
                    fontSize: "15px",
                  }}
                >
                  SkillSquad is a marketplace platform that makes top-tier
                  industry expertise accessible to all, enabling users to
                  connect with experts in real-time for personalized guidance,
                  career coaching, and skill development, while only paying for
                  the time they use.
                </p>
                {/* Social media icons */}
                <div style={{ paddingTop: "10px", paddingBottom: "10px" }}>
                  <img
                    src={Twitter}
                    style={{ marginRight: "2%" }}
                    onClick={() => {
                      window.open("https://twitter.com/skillsquadio", "_blank");
                    }}
                  />
                  <img
                    src={facebook}
                    style={{ marginRight: "2%" }}
                    onClick={() => {
                      window.open(
                        "https://www.facebook.com/skillsquadio",
                        "_blank"
                      );
                    }}
                  />
                  <img
                    src={instagram}
                    style={{ marginRight: "2%" }}
                    onClick={() => {
                      window.open(
                        "https://www.instagram.com/skillsquad.io/",
                        "_blank"
                      );
                    }}
                  />
                  <img
                    src={Linkedin}
                    onClick={() => {
                      window.open(
                        "https://www.linkedin.com/company/skillsquadelarning",
                        "_blank"
                      );
                    }}
                  />
                </div>
              </div>
              <div className="col-lg-2 col-md-6 col-sm-3 d-none d-md-block">
                <h5 style={{ fontSize: "22px" }}>Resources</h5>
                <ul
                  className="list-unstyled"
                  style={{ lineHeight: "35px", fontSize: "15px" }}
                >
                  <li>
                    <a
                      href="#"
                      style={{
                        color: "rgba(173, 185, 199, 1)",
                        cursor: "pointer",
                      }}
                      onClick={learn}
                    >
                      Courses
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-lg-2 col-md-6 col-sm-3 d-none d-md-block">
                <h5 style={{ fontSize: "22px" }}>Company</h5>
                <ul
                  className="list-unstyled "
                  style={{ lineHeight: "35px", fontSize: "15px" }}
                >
                  {/* <li>
                    <a href="#" style={{ color: "rgba(173, 185, 199, 1)" }}>
                      Our Team
                    </a>
                  </li> */}

                  <li>
                    <a href="https://www.linkedin.com/company/skillsquadelarning/jobs/"
                            target="_blank" style={{ color: "rgba(173, 185, 199, 1)" }}>
                      Career
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-lg-2 col-md-6 col-sm-12 d-none d-md-block">
                <h5 style={{ fontSize: "22px" }}>Contact</h5>
                <p
                  style={{
                    color: "rgba(173, 185, 199, 1)",
                    lineHeight: "35px",
                    fontSize: "15px",
                  }}
                >
                  Hitex Bizness Square 4th Floor, Unit No 405-411, Madhapur,
                  Hyderabad, Telangana
                </p>
                <a style={{ color: "rgba(173, 185, 199, 1)" }} href="">
                  support@skillsquad.io
                </a>
                <div class="mt-2" style={{ color: "rgba(173, 185, 199, 1)" }}>
                  +91 9281476034
                </div>
              </div>

              {/* mobile design */}
              <div className="col-lg-3 col-md-6 d-block d-sm-none d-flex flex-column align-items-start">
                <img
                  src={WhiteSkillLogo}
                  alt="Logo"
                  style={{ width: "50%", height: "auto" }}
                />
                <p
                  style={{
                    paddingTop: "15px",
                    fontSize: "14px",
                    color: "rgba(173, 185, 199, 1)",
                    lineHeight: "35px",
                    fontSize: "15px",
                    textAlign: "start",
                  }}
                >
                  Take charge of your future with Skillsquad's advanced learning
                  platform and extensive career resources. Equip yourself to
                  thrive and achieve your goals
                </p>
                {/* Social media icons */}
                <div style={{ paddingTop: "10px", paddingBottom: "10px" }}>
                  <img
                    src={Twitter}
                    style={{ marginRight: "5px" }}
                    onClick={() => {
                      window.open("https://twitter.com/skillsquadio", "_blank");
                    }}
                  />
                  <img
                    src={facebook}
                    style={{ marginRight: "5px" }}
                    onClick={() => {
                      window.open(
                        "https://www.facebook.com/skillsquadio",
                        "_blank"
                      );
                    }}
                  />
                  <img
                    src={instagram}
                    style={{ marginRight: "5px" }}
                    onClick={() => {
                      window.open(
                        "https://www.instagram.com/skillsquad.io/",
                        "_blank"
                      );
                    }}
                  />
                  <img
                    src={Linkedin}
                    onClick={() => {
                      window.open(
                        "https://www.linkedin.com/company/skillsquadelarning",
                        "_blank"
                      );
                    }}
                  />
                </div>
              </div>
             

              <div className="container mt-5 d-block d-sm-none ">
                <div className="row ">
                  <div className="col-6">
                    <div className="m-2 d-flex flex-column">
                      <h5 style={{ fontSize: "22px" }}>Resources</h5>
                      <ul
                        className="list-unstyled"
                        style={{ lineHeight: "35px", fontSize: "15px" }}
                      >
                        <li>
                          <a
                            href="#"
                            style={{
                              color: "rgba(173, 185, 199, 1)",
                              cursor: "pointer",
                            }}
                            onClick={learn}
                          >
                            Courses
                          </a>
                        </li>
                       
                       
                      </ul>
                    </div>
                  </div>
                  {/* Right column (takes half width on all screens) */}
                  <div className="col-6">
                    <div className="m-2 d-flex flex-column">
                      <h5 style={{ fontSize: "22px" }}>Company</h5>
                      <ul
                        className="list-unstyled"
                        style={{ lineHeight: "35px", fontSize: "15px" }}
                      >
                        {/* <li>
                          <a
                            href="#"
                            style={{ color: "rgba(173, 185, 199, 1)" }}
                          >
                            Our Team
                          </a>
                        </li> */}
                       
                        <li>
                          <a
                            href="https://www.linkedin.com/company/skillsquadelarning/jobs/"
                            target="_blank"
                            style={{ color: "rgba(173, 185, 199, 1)" }}
                          >
                            Career
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-2 col-md-6 col-sm-12  d-block d-sm-none d-flex flex-column">
                <h5 style={{ fontSize: "22px" }}>Contact</h5>
                <p
                  style={{
                    color: "rgba(173, 185, 199, 1)",
                    lineHeight: "35px",
                    fontSize: "15px",
                    textAlign: "start",
                  }}
                >
                  Hitex Bizness Square 4th Floor, Unit No 405-411, Madhapur,
                  Hyderabad, Telangana
                </p>
                <a style={{ color: "rgba(173, 185, 199, 1)" }} href="">
                  support@skillsquad.io
                </a>
                <div class="mt-2" style={{ color: "rgba(173, 185, 199, 1)" }}>
                  +91 9281476034
                </div>
              </div>
            </div>
          </div>
          <div
            className="text-center"
            style={{
              backgroundColor: "", //rgba(15, 11, 51, 1)
              // height: "auto",
              paddingTop: "30px",
              // position: "relative",
            }}
          >
            <img
              src={FooterLogo}
              alt="Big Logo"
              style={{
                maxWidth: "90%",
                // height: "auto",
                // marginBottom: "20px",
                marginTop: "70px",
              }}
            />
            <br />
           
            <div className="policy">
              2024 All Right Reserved |{" "}
              <span style={{ cursor: "pointer" }} onClick={privacyPolicy}>
                Privacy Policy
              </span>{" "}
              |{" "}
             
              
              <span style={{ cursor: "pointer" }} onClick={termsAndConditions}>
                Terms & Conditions
              </span>{" "}
              |{" "}
              <span style={{ cursor: "pointer" }} onClick={codeOfConduct}>
                Code Of conduct
              </span>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};
