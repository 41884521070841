import React, { useRef, useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import arrowleft from "./mentorimages/arrow-left.svg";
import arrowright from "./mentorimages/arrow-right.svg";
import Commas from "../../images/commas.svg";
import mohammad from "../../images/Mohammed._Irfan.webp"
import pranay from '../../images/Manish_Pranay.webp'
import rakesh from '../../images/Kandhula.Rakesh.webp'
import krishna from "../../images/Kamera Chinni Krishna.webp"

const MentorTrustSlides = () => {
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const [swiperInstance, setSwiperInstance] = useState(null);
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);

  const slides = [
    {
      photo : mohammad,
      name : "Mohammad Irfan",
      role : "Devops Engineer",
      desc : "SkillSquad is a game-changer. I love how easy it is to connect with experts for tailored advice. Whether I need career guidance or tips on mastering a new skill, it’s as simple as making a quick video call. The fact that I only pay for the time I use makes it super affordable too."
    },
    {
      photo : pranay,
      name : "Manish Prany",
      role : "Java Full Stack Developer",
      desc : "SkillSquad is like having a personal mentor on standby. I’ve used it to get advice before a big interview, and the tips I received were spot on. The experts are truly knowledgeable, and the convenience is unmatched. Highly recommended for anyone serious about professional growth."
    },
    {
      photo : rakesh,
      name : "Rakesh",
      role : "Data Analyst",
      desc : "What sets SkillSquad apart is its flexibility. I didn’t have to commit to long-term sessions; I just booked the time I needed. The expert I connected with was fantastic and really helped me figure out the next steps in my career." 
    },
    {
      photo : krishna,
      name : "Chinni Krishna",
      role : "Cyber Security Analyst",
      desc : "SkillSquad has opened doors for me that I didn’t even know existed. The experts are top-tier and provide tailored advice in real-time. It’s like having the guidance of industry leaders right at your fingertips. Definitely a must-try for anyone aiming to level up in their career."
    },
  ];

  const handleSlideChange = (swiper) => {
    setIsBeginning(swiper.isBeginning);
    setIsEnd(swiper.isEnd);
  };

  useEffect(() => {
    if (swiperInstance) {
      swiperInstance.params.navigation.prevEl = prevRef.current;
      swiperInstance.params.navigation.nextEl = nextRef.current;
      swiperInstance.navigation.update();
    }
  }, [swiperInstance]);
  useEffect(() => {
    if (prevRef.current) {
      prevRef.current.style.display = isBeginning ? "none" : "flex";
    }
    if (nextRef.current) {
      nextRef.current.style.display = isEnd ? "none" : "flex";
    }
  }, [isBeginning, isEnd]);

  return (
    <div className="carousel-container">
      <Swiper
        spaceBetween={30}
        slidesPerView={2}
        
        onSlideChange={handleSlideChange}
        onSwiper={setSwiperInstance}
        speed={600}
       
        modules={[Navigation]}
        navigation={{
          prevEl: prevRef.current,
          nextEl: nextRef.current,
        }}
        breakpoints={{
          350: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          768: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          1024: {
            slidesPerView: 2,
            spaceBetween: 30,
          },
          1440: {
            slidesPerView: 2,
            spaceBetween: 30,
          },
        }}
      >
        {slides.map((slide, index) => (
          <SwiperSlide key={index} >
           <div className="trust-main">
              <div className="t-t-i-l">
                <div className="t-t-i">
                  <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                    <img
                      style={{
                        height: "60px",
                        width: "60px",
                        borderRadius: "15px",
                      }}
                      src={slide.photo}
                      alt={slide.name}
                    />
                    <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
                      <div className="t-t-n">{slide.name}</div>
                      <div className="t-t-p">{slide.role}</div>
                    </div>
                  </div>
                  <img src={Commas} alt="" style={{ float: "right" }} />
                </div>
                <div className="t-d">{slide.desc}</div>
              </div>
              </div>
            
          </SwiperSlide>
        ))}
      </Swiper>

     
      <div
        ref={prevRef}
        className={`custom-prev ${isBeginning ? "disabled" : ""}`}
        style={{ display: isBeginning ? "none" : "block" }}
      >
        <img src={arrowleft} alt="Previous" />
      </div>
      <div
        ref={nextRef}
        className={`custom-next ${isEnd ? "disabled" : ""}`}
        style={{ display: isEnd ? "none" : "block" }}
      >
        <img src={arrowright} alt="Next" />
      </div>
    </div>
  );
};

export default MentorTrustSlides;
