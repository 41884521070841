
import offline from "../images/offlineImage.svg"
import rocket from "../images/rocketImage.png"
import books from "../images/books.png"
import "../styles/Offline.css"

const Offline = () => {

  return (
    <>
     

      <div className="jobs-dashboard-container mx-4">

{/* <Navb /> */}

<div className="px-md-5 mt160">

    <section class="section">
        <div class="container">

            <div class="row p-4">

                <div class="col-lg-9">
                    <div class="row">
                        <div class="">
                            <div class="section-title  mb-4 pb-2">
          <div className="col-lg-12">
            <div className="row">

            <div className="d-flex flex-column align-items-center">

            <img
                    src={offline}
                    alt="No image found"
                    
                    width={500}
                    height={300}
                  />  
        </div>
        <center>  <hr style={{margin : 0,width:"70%"}} class="mb-2"></hr>
        
     <p class="offline">No internet connection</p>
    
        <div class="offline-tag d-flex">Take a breather, recharge, and get ready to dive back into learning once the connection is back!  <img
                    src={rocket}
                    alt="No image found"
                    
                    width={30}
                    height={30}
                  /> <img
                  src={books}
                  alt="No image found"
                  
                  width={30}
                  height={30}
                /></div>
     
  
    
     
      </center>
            </div>
          </div>

          </div>
                            </div>
                        </div>


                    </div>

                    {/*Filter section*/}
                </div>

            </div>

        </section>



    </div>
  

</div>


    </>
  );
};

export default Offline;
