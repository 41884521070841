import React from "react";
import { useState, useEffect } from "react";
import httpvideos from "../js/http-lms_videos"
import attch from '../images/file_attach.svg';
import closebtn from '../images/close_btn.svg'
import Spinner from 'react-bootstrap/Spinner';

const UploadAssignments = ({selectedAssignment, onClose}) => {
    const [finalLoading, setFinalLoading] = useState(false)
    const [renderState, setRenderState] = useState(false)
    const [removeState, setRemoveState] = useState(false)
    const [message, setMessage] = useState()
    const [selectedAssignmentFile, setSelectedAssignmentFile] = useState(null)
    function closeUploadAssignment(status) {
        onClose(status)
    }
    function chooseFilesopen(){
        document.getElementById("postfileUpload").click()
    }
    function onAssignmentFileChanged(event) {
        console.log(event)
        setSelectedAssignmentFile(event.target.files[0])
        setRenderState(true)
        setRemoveState(false)
    }
    function RemoveAssignmentDocs(){
        setFinalLoading(false)
        setSelectedAssignmentFile(null);
        setRemoveState(true)
        setRenderState(false)
        console.log(selectedAssignment)
    }
    function submitAssignmentFinal() {
        setFinalLoading(true)
        setMessage(null)
        let filename = sessionStorage.getItem('profileid')+'_'+sessionStorage.getItem('fullName')+'_'+selectedAssignment.assignmentMaster[0].assignmentName+'_answerset'
        let fd = new FormData()
        var obj = {
            assignmentId : selectedAssignment.assignmentMaster[0].assignmenId,
            studentId : sessionStorage.getItem('profileid'),
            assignmentResponseAttachmentFileName : filename,
            assignmentSubmittedEmail : sessionStorage.getItem('userEmail')
        }
        // console.log("final obj : ", obj)
        // console.log("final file : ", selectedAssignmentFile)
        var pblob = new Blob([JSON.stringify(obj)], { type: "application/json" })
        fd.append("submitAssignmentRequestVO", pblob)
        fd.append("attachmentFiles", selectedAssignmentFile)
        httpvideos.post('/api/v1/topicAssignmentLMS/updateAssignmentResponseLinkURL',fd, {
            headers: {
                "Content-Type": "multipart/form-data",
                "Accept" : "*/*"
              }
        })
        .then((response) => {
            console.log("saving assignment info information : ", response.data)
            setMessage(response.data)
            setTimeout(() => {
                setMessage()
            }, 1600);
            if(response.data !== "Record Not Found"){
                clearData()
            }
        }).catch((e) => {
            console.log(e)
            setFinalLoading(false)
        })
    }
    function clearData() {
        setRemoveState(false)
        setRenderState(false)
        setFinalLoading(false)
        closeUploadAssignment('SUCCESS')
    }
    return (
        <div className="position-fixed P-Update-Success-1 d-flex justify-content-center align-items-center">
            <div className="card radius10 border-0 col-11 col-md-8 col-lg-4 d-flex flex-column">
                <div className="col-12 p-2 py-3 radius10 border-0 bold1 background-gray font18 text-center">
                    <span>Submit Assignment</span> 
                    <img src={closebtn} alt="close" width="25px" className="closebtn mouseoverstyles1 radiushalf pointer border-0" onClick={() => closeUploadAssignment('NOT SUCCESS')}/>
                </div>
                <div className="col-12 p-3 d-flex flex-column">
                <span className="mb-2 text-center mx-auto font14 blue height15">{message}</span>
                    <div className="d-flex">
                        <span className="col-5 font16 black bold">Assignment Name </span>
                        {selectedAssignment && selectedAssignment.assignmentMaster && selectedAssignment.assignmentMaster.length > 0 ? 
                        <span className="col-7 font16 black">: {selectedAssignment.assignmentMaster[0].assignmentName}</span>
                        : <span className="col-7 font16"> : --</span>
                        }
                    </div>
                    <div className="d-flex mt-2">
                        <span className="col-5 font16 black bold">Assignment File </span>
                        {selectedAssignment && selectedAssignment.assignmentMaster && selectedAssignment.assignmentMaster.length > 0 ? 
                        <span className="col-7 font16"> : <a href={selectedAssignment.assignmentMaster[0].s3PresignedAssignmentLink} className="orange my-auto" target="_blank" key={'assignment_qsn'}>{selectedAssignment.assignmentMaster[0].assignmentName}</a></span>
                        : <span className="col-7 font16"> : --</span>
                        }
                    </div>
                    <div className="d-flex flex-column mt-2">
                        <span className="col-5 font16 black bold mb-2">Answer File    :</span>
                        <span>{ renderState || removeState || selectedAssignmentFile?
                            <span>{selectedAssignmentFile?
                            <div className="col-10 border-button hover-border_orange p-1 d-flex justify-content-around background-accordion radius15">
                                <span className="font12 col-10 pl-0 pr-0">{selectedAssignmentFile.name}</span>
                                <img src={require("../images/closebtn.png")} title="Remove"  onClick={(e) => RemoveAssignmentDocs(e)} alt="delete" width="20px" height="20px" className="pointer"/>
                            </div> :
                            <div className="col-10">
                                <input type="file" className="form-control-file" id={"postfileUpload"}  accept="*" onChange={(e) => {onAssignmentFileChanged(e)}} hidden/>
                                <button className="w-100 border-button hover-border_orange p-1 background-none radius15"  onClick={() => {chooseFilesopen()}}>
                                    <img src={attch} alt="file" width="20px"/>
                                    <span className="font12">Attach File</span>
                                </button>
                            </div>
                            }</span> : <div className="col-10">
                                <input type="file" className="form-control-file" id={"postfileUpload"}  accept="*" onChange={(e) => {onAssignmentFileChanged(e)}} hidden/>
                                <button className="w-100 border-button hover-border_orange p-1 background-none radius15"  onClick={() => {chooseFilesopen()}}>
                                    <img src={attch} alt="file" width="20px"/>
                                    <span className="font12">Attach File</span>
                                </button>
                            </div>
                        }</span>
                    </div>
                </div>
                <div className="footer text-center p-3">
                    {finalLoading ? <div className="col-10 text-center mx-auto"> <Spinner animation="border" className="spinner" variant="secondary" /> </div> :
                    <button className={selectedAssignmentFile ? "background-organe white w-100 border-0 p-1 radius15":"w-100 border-button p-1 black60 background-accordion radius15"} onClick={() => {submitAssignmentFinal()}} disabled={!selectedAssignmentFile}>
                        Submit
                    </button>
                    }
                </div>
              </div>
            </div>
    )
}
export default UploadAssignments