import React, { useEffect, useState } from "react";
import "../styles/BuildProject.css";
import Spinner from 'react-bootstrap/Spinner';
import httpsignup from '../js/http-lms_userprofile'
import { Modal } from 'react-bootstrap';
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import tick from '../images/green_button.png'
import arrow from "../images/left-arrow_icon.png"
import ReactHtmlParser from 'html-react-parser';


const ProjectDescription = (props) => {
    console.log("program details props : ", JSON.stringify(props));
    const [data, setData] = useState([])

    const [refresh, setRefresh] = useState(false)

    const [show, setShow] = useState(false);

    const [step, setStep] = useState();
    const [showCard, setShowCard] = useState(1);
    const [urlSubmit, setUrlSubmit] = useState('col project-submit');
    const [attachSubmit, setAttachSubmit] = useState('col project-submit-select');
    const [pId, setPId] = useState();
    const [projectStatus, setProjectStatus] = useState({});
    const [enableSubmit, setEnableSubmit] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null)
    const [selectedFileName, setSelectedFileName] = useState(null)
    const [projectSubmit, setProjectSubmit] = useState({});
    const [disable, setDisable] = useState(true);
    





    useEffect(() => {
        getLoggedInUserDetails()
    }, [])
    useEffect(() => {
        if (refresh) {
            programCards()
        }
    }, [refresh])


    const programCards = async () => {

        // const response=await axios.get("http://34.199.176.17:13052/programsLMS/getModulesTopicCount")
        httpsignup.get("/programsLMS/getProjectStatus?profileId=&projectId=")
            .then((response) => {
                console.log("get modules count", response)

            }).catch((err) => {
                console.log(err)
            })

    }

    const getLoggedInUserDetails = async () => {
        httpsignup.get(`/skillsquadUserprofileLMS/getSkillsquadUserProfileBySignUpId?signUpId=${sessionStorage.getItem("userId")}`)
            .then((response) => {
                setPId(response.data.profileId);
                httpsignup.get(`/programsLMS/getProjectStatus?profileId=${response.data.profileId}&projectId=${props.projectList.skillsquadProjects.projectId}`)
                    .then((response) => {
                        if (response.data != null && response.data != "") {
                            console.log("BY Batch PROPS : " + JSON.stringify(response.data))
                            setEnableSubmit(true)
                        } else {
                            setEnableSubmit(false)
                        }


                    }).catch((err) => {
                        console.log(err)
                    })

            }).catch((err) => {
                console.log(err)
            })
    }

    function allcourses() {
        props.stopDescription()
    }
    function displaySteps(id) {
        setStep(id);
    }
    const handleShow = () => setShow(true);

    const handleClose = () => {
        setShow(false);
    }

    function showCardList(num) {
        setShowCard(num);
        if (num == 1) {
            setUrlSubmit('col project-submit');
            setAttachSubmit('col project-submit-select');
        } else {
            setUrlSubmit('col project-submit-select');
            setAttachSubmit('col project-submit');
        }
    }

    function startProject() {
        projectStatus.projectId = props.projectList.skillsquadProjects.projectId;
        projectStatus.profileId = pId;
        projectStatus.status = "InProgress";
        httpsignup.post(`/programsLMS/saveProjectStatus`, projectStatus)
            .then((response) => {

                console.log("BY Batch PROPS : " + JSON.stringify(response.data))
                getLoggedInUserDetails()
            }).catch((err) => {
                console.log(err)
            })
    }

    function onAssignmentFileChanged(event) {
        event.preventDefault();
        console.log(event.target.files)
        setSelectedFile(event.target.files[0])
        setSelectedFileName(event.target.files[0].name)
        if(event.target.files.length > 0){
            setDisable(false)
        }
      }

      function savePost(e) {
        e.preventDefault();
        
        projectSubmit.profileId = pId;
        projectSubmit.projectId = props.projectList.skillsquadProjects.projectId;
        console.log("SAVE POST : " + JSON.stringify(projectSubmit))
        let fd = new FormData()
        var pblob = new Blob([JSON.stringify(projectSubmit)], { type: "application/json" })
        fd.append("posts", pblob)
        fd.append("attachmentFiles", selectedFile)
    
    
    
        httpsignup.put(`/programsLMS/updateProjectStatus`, fd, {
          headers: {
            "Content-Type": "multipart/form-data",
            "Accept": "*/*"
          }
        })
          .then((response) => {
            console.log("COMUNITY TITLES : " + JSON.stringify(response.data))
            allcourses()
          }).catch((err) => {
            console.log(err)
          })
      }

      function handleChange(e) {
        // setErrorMessage('')
       
        const { name, value } = e.target;
        console.log(" VALID : " + isValidHttpUrl(value))
        if(isValidHttpUrl(value)){
            setDisable(false)
        setProjectSubmit((pValue) => {
          console.log(pValue)
          return {
            ...pValue,
            [name]: value
          }
        })
    }
        
        console.log("after change : ", projectSubmit)
      }

      function isValidHttpUrl(s) {
        let url;
        try {
          url = new URL(s);
        } catch (e) { return false; }
        return /https?/.test(url.protocol);
      }

    return (
        <>


            <div className="">

                {/* <Navb /> */}

                <div className="">
                    <img src={arrow} width={30} alt="arrow" style={{ cursor: "pointer" }} onClick={allcourses} />
                    <div class="project_desc mx-4 mt-4 mb-4 common_main_text_normal">Project Details</div>
                    <div class="d-flex">
                        <div class="build_project_desc-outline mx-4">
                            <div class="build_project_desc">
                                <div class="build_project_cards_category_desc mb-3 common_main_text_tag">{props.projectList.skillsquadProjects.projectCategory}</div>
                                <div class="build_project_cards_project_name_desc mb-3 common_main_heading">{props.projectList.skillsquadProjects.projectTitle}</div>
                                <div class="build_project_cards_project_desc_desc mb-3 common_main_sub_heading">{props.projectList.skillsquadProjects.projectDescription}</div>
                                <div class="build_project_cards_skill_desc_h mb-2 common_main_text_highlight">SKILLS</div>
                                <div class="build_project_cards_skill_desc_desc common_main_text_normal">{props.projectList.skillsquadProjects.skills}</div>
                            </div>
                            <hr></hr>

                            {props.projectList.skillsquadProjectsStagesList.map((data, index) => {
                                return (<div class="mx-5 mb-2"><div class="stages mb-3 common_main_text_highlight" onClick={() => { displaySteps(index + 1) }}>Stage {index + 1}</div>
                                    {step == index + 1 && <div>{ReactHtmlParser(data.steps)}</div>}
                                </div>)
                            })}

                            {/* <div onClick={displaySteps}>Stage 1</div>
                            
                            {step && <div>Hello1</div>} */}


                            <Modal
                                show={show}
                                onHide={handleClose}
                                className="modal-100"
                                dialogClassName={`modal-left-small h-100 mt-0 ${show ? 'fade-left' : 'fade-right'}`}
                                aria-labelledby="example-custom-modal-styling-title"
                            >
                                <Modal.Header closeButton style={{ backgroundColor: "white" }}>
                                    <Modal.Title id="example-custom-modal-styling-title common_main_heading" >
                                        {props.projectList.skillsquadProjects.projectTitle}
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div style={{color: "grey" }} class="mb-3 common_main_sub_heading">Your Proof of Work (portfolio) can be drive link, Behance profile, github link, or just a file</div>


                                    <div class="container">
                                        <div class="row text-center" style={{ backgroundColor: "#D5DBF6", borderRadius: 50, padding: 10 }}>
                                            <div class={attachSubmit} onClick={() => { showCardList(1) }}>Add attachment <img width={20} src={require("../images/attach_icon.png")} /></div>
                                            <div class={urlSubmit} onClick={() => { showCardList(2) }}>Add link <img width={20} src={require("../images/project_internet_icon.png")} /></div>
                                        </div>
                                    </div>


                                    <div style={{ padding: "2%" }}>


                                        <div >
                                            <div>
                                                {showCard === 1 && <div >
                                                    <Form id="create-User-Form" className='h-100'>
                                                        <div className='scrollForm'>
                                                            <Row className="pb-3">
                                                                <Col className="col-lg-10 col-12 d-flex flex-column align-items-start mt-lg-0 mt-3">
                                                                    <Form.Control
                                                                        type="file"
                                                                        placeholder="www.behance.net/averallll"
                                                                        autoComplete="off"
                                                                        name='role'
                                                                        id={"postfileUpload"} 
                                                                        accept="*" 
                                                                        onChange={(e) => { onAssignmentFileChanged(e) }}

                                                                    />
                                                                </Col>
                                                            </Row></div>
                                                    </Form>
                                                </div>}

                                                {showCard === 2 && <div >
                                                    <Form id="create-User-Form" className='h-100'>
                                                        <div className='scrollForm'>
                                                            <Row className="pb-3">
                                                                <Col className="col-lg-10 col-12 d-flex flex-column align-items-start mt-lg-0 mt-3">
                                                                    <div className="d-flex required"> <h6>Project URL</h6></div>
                                                                    <Form.Control

                                                                        placeholder="www.behance.net/averallll"
                                                                        autoComplete="off"
                                                                        name='githubUrl'
                                                                        defaultValue={projectSubmit.githubUrl}
                                                                        onChange={handleChange}

                                                                    />
                                                                </Col>
                                                            </Row></div>
                                                    </Form>
                                                </div>}

                                            </div></div></div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <div style={{ display: 'flex' }} className="row">
                                        <div className="col-lg-12">
                                            {false ? <div className="col-lg-4 col-12 mt-3 mb-5 text-center">
                                                <Spinner className="mx-auto" animation="border" variant="secondary" />
                                            </div> :
                                                <div className="d-flex">
                                                    <button disabled={disable} className={disable ? "profile-buttons-update mt-3 mb-5 grey" : "profile-buttons-update mt-3 mb-5 UpdatedMainBGColor"} onClick={savePost}>
                                                        Create
                                                    </button>
                                                    <button className=" ms-5 cancelUser mt-3 mb-5" >Clear</button>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </Modal.Footer>

                            </Modal>
                        </div>
                        <div class="project_desc_outline ">
                            <div class="project_desc_start_project mb-3">
                                <div style={{ border: "2px solid white", width: "20%", textAlign: "center", borderRadius: 10, backgroundColor: "white" }} class="mb-5">
                                    <img src={arrow} width={30} alt="arrow" style={{ cursor: "pointer" }} />
                                </div>

                                <div class="project_desc_start_project_heading mb-3 common_main_text_highlight">Ready? Start Building</div>
                                <div class="project_desc_start_project_sub_heading mb-2 common_main_text_normal">Includes the necessary assests, design files, style guide and a README file to help you with each step of the project.</div>
                                {enableSubmit ? <div class="project_desc_start_project_button" style={{ cursor: "pointer" }} onClick={handleShow}>Submit Project</div> :
                                    <div class="project_desc_start_project_button common_main_text_highlight" style={{ cursor: "pointer" }} onClick={startProject}>Start Project</div>}
                            </div>


                            <div class="dayChallenge-task mt-4 mb-4" style={{ borderColor: "grey" }}>
                                <div class="project_desc_milestone_heading mb-4 common_main_text_highlight">Milestones</div>
                                <div class="dayChallenge_box" style={{ padding: 0, margin: 0, backgroundColor: "transparent" }}>
                                    <div class="container">
                                        <div class="row align-items-center" >
                                            <div class="col d-flex align-items-center" style={{ fontSize: 12, color: "grey", fontWeight: 500 }}>
                                                <img class="project_desc_milestone_img" width={30} src={require("../images/project_white_tick_icon.png")} alt="tick" />
                                                <div style={{ marginLeft: 10 }} >Started the project</div>
                                            </div>
                                            <div class="col col-lg-5" style={{ fontSize: 12, color: "grey", fontWeight: 500 }}>
                                                {enableSubmit ? <img src={tick} width={20} alt="arrow" /> : <div>Not started</div>}


                                            </div>
                                        </div></div>
                                </div>
                                <div class="project_vl_u"></div>
                                <div class="dayChallenge_box mb-4" style={{ padding: 0, margin: 0, backgroundColor: "transparent" }}>
                                    <div class="container">
                                        <div class="row align-items-center" >
                                            <div class="col d-flex align-items-center" style={{ fontSize: 12, color: "grey", fontWeight: 500 }}>
                                                <img class="project_desc_milestone_img" width={30} src={require("../images/project_submit_icon.png")} alt="tick" />
                                                <div style={{ marginLeft: 10 }}>Submitted Project</div>
                                            </div>
                                            <div class="col col-lg-5" style={{ fontSize: 12, color: "grey", fontWeight: 500 }}>
                                                Not submitted
                                            </div>
                                        </div></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>


        </>
    );
};

export default ProjectDescription;
