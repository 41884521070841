import React from "react";
import { useState, useEffect, useRef } from "react";

import thum from "../../images/vid2.png"
import eventBus from "../../js/Event-bus";
import httpskillsquaduserprofile from "../../js/http-lms_userprofile"
import 'video-react/dist/video-react.css';
import { Player, ControlBar, PlaybackRateMenuButton, ForwardControl, ReplayControl } from 'video-react';
import screenful from "screenfull";
import Controls from "../../components/Controls";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import ReactPlayer from "react-player";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import Slider from "@material-ui/core/Slider";
import Tooltip from "@material-ui/core/Tooltip";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const useStyles = makeStyles((theme) => ({
    playerWrapper: {
      width: "100%",
  
      position: "relative",
      // "&:hover": {
      //   "& $controlsWrapper": {
      //     visibility: "visible",
      //   },
      // },
    },
  
    controlsWrapper: {
      visibility: "hidden",
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: "rgba(0,0,0,0.4)",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    topControls: {
      display: "flex",
      justifyContent: "flex-end",
      padding: theme.spacing(2),
    },
    middleControls: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    bottomWrapper: {
      display: "flex",
      flexDirection: "column",
  
      // background: "rgba(0,0,0,0.6)",
      // height: 60,
      padding: theme.spacing(2),
    },
  
    bottomControls: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      // height:40,
    },
  
    button: {
      margin: theme.spacing(1),
    },
    controlIcons: {
      color: "#777",
  
      fontSize: 50,
      transform: "scale(0.9)",
      "&:hover": {
        color: "#fff",
        transform: "scale(1)",
      },
    },
  
    bottomIcons: {
      color: "#999",
      "&:hover": {
        color: "#fff",
      },
    },
  
    volumeSlider: {
      width: 100,
    },
  }));
  
  const PrettoSlider = withStyles({
    root: {
      height: 8,
    },
    thumb: {
      height: 24,
      width: 24,
      backgroundColor: "#fff",
      border: "2px solid currentColor",
      marginTop: -8,
      marginLeft: -12,
      "&:focus, &:hover, &$active": {
        boxShadow: "inherit",
      },
    },
    active: {},
    valueLabel: {
      left: "calc(-50% + 4px)",
    },
    track: {
      height: 8,
      borderRadius: 4,
    },
    rail: {
      height: 8,
      borderRadius: 4,
    },
  })(Slider);
  
  function ValueLabelComponent(props) {
    const { children, open, value } = props;
  
    return (
      <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
        {children}
      </Tooltip>
    );
  }
  
  const format = (seconds) => {
    if (isNaN(seconds)) {
      return `00:00`;
    }
    const date = new Date(seconds * 1000);
    const hh = date.getUTCHours();
    const mm = date.getUTCMinutes();
    const ss = date.getUTCSeconds().toString().padStart(2, "0");
    if (hh) {
      return `${hh}:${mm.toString().padStart(2, "0")}:${ss}`;
    }
    return `${mm}:${ss}`;
  };
  
  let count = 0;

const TrailTopicVideo = (props) => {

    const classes = useStyles();
    const [showControls, setShowControls] = useState(false);
    // const [count, setCount] = useState(0);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [timeDisplayFormat, setTimeDisplayFormat] = React.useState("normal");
    const [bookmarks, setBookmarks] = useState([]);
    const [state, setState] = useState({
      pip: false,
      playing: true,
      controls: false,
      light: false,
  
      muted: true,
      played: 0,
      duration: 0,
      playbackRate: 1.0,
      volume: 1,
      loop: false,
      seeking: false,
    });
  
    const playerRef = useRef(null);
    const playerContainerRef = useRef(null);
    const controlsRef = useRef(null);
    const canvasRef = useRef(null);
    const {
      playing,
      controls,
      light,
  
      muted,
      loop,
      playbackRate,
      pip,
      played,
      seeking,
      volume,
    } = state;
  
    const handlePlayPause = () => {
      setState({ ...state, playing: !state.playing });
    };
  
    const handleRewind = () => {
      playerRef.current.seekTo(playerRef.current.getCurrentTime() - 10);
    };
  
    const handleFastForward = () => {
      playerRef.current.seekTo(playerRef.current.getCurrentTime() + 10);
    };
  
    const handleProgress = (changeState) => {
      if (count > 3) {
        controlsRef.current.style.visibility = "hidden";
        count = 0;
      }
      if (controlsRef.current.style.visibility == "visible") {
        count += 1;
      }
      if (!state.seeking) {
        setState({ ...state, ...changeState });
      }
    };
  
    const handleSeekChange = (e, newValue) => {
      console.log({ newValue });
      if(parseFloat(newValue / 100) <= played){
        console.log("you are allowed")
        setState({ ...state, played: parseFloat(newValue / 100) });
        playerRef.current.seekTo(newValue / 100, "fraction");
      }else{
        notify();
        setState({ ...state, played: parseFloat(newValue / 100) });
      }
      
    };
  
    const handleSeekMouseDown = (e) => {
      setState({ ...state, seeking: true });
    };
    const notify = () => toast.error("You cannot seek forward unless you have watched the video at least once");
    const handleSeekMouseUp = (e, newValue) => {
    // notify();
      //setState({ ...state, seeking: false });
      // console.log(sliderRef.current.value)
      //playerRef.current.seekTo(newValue / 100, "fraction");
     
    };
  
    const handleDuration = (duration) => {
     
      setState({ ...state, duration });
    };
  
    const handleVolumeSeekDown = (e, newValue) => {
      setState({ ...state, seeking: false, volume: parseFloat(newValue / 100) });
    };
    const handleVolumeChange = (e, newValue) => {
      // console.log(newValue);
      setState({
        ...state,
        volume: parseFloat(newValue / 100),
        muted: newValue === 0 ? true : false,
      });
    };
  
    const toggleFullScreen = () => {
      screenful.toggle(playerContainerRef.current);
    };
  
    const handleMouseMove = () => {
      console.log("mousemove");
      controlsRef.current.style.visibility = "visible";
      count = 0;
    };
  
    const hanldeMouseLeave = () => {
      controlsRef.current.style.visibility = "hidden";
      count = 0;
    };
  
    const handleDisplayFormat = () => {
      setTimeDisplayFormat(
        timeDisplayFormat == "normal" ? "remaining" : "normal"
      );
    };
  
    const handlePlaybackRate = (rate) => {
      setState({ ...state, playbackRate: rate });
    };
  
    const hanldeMute = () => {
      setState({ ...state, muted: !state.muted });
    };
  
    const addBookmark = () => {
      const canvas = canvasRef.current;
      canvas.width = 160;
      canvas.height = 90;
      const ctx = canvas.getContext("2d");
  
      ctx.drawImage(
        playerRef.current.getInternalPlayer(),
        0,
        0,
        canvas.width,
        canvas.height
      );
      const dataUri = canvas.toDataURL();
      canvas.width = 0;
      canvas.height = 0;
      const bookmarksCopy = [...bookmarks];
      bookmarksCopy.push({
        time: playerRef.current.getCurrentTime(),
        display: format(playerRef.current.getCurrentTime()),
        image: dataUri,
      });
      setBookmarks(bookmarksCopy);
    };
  
    const currentTime =
      playerRef && playerRef.current
        ? playerRef.current.getCurrentTime()
        : "00:00";
  
    const duration =
      playerRef && playerRef.current ? playerRef.current.getDuration() : "00:00";
    const elapsedTime =
      timeDisplayFormat == "normal"
        ? format(currentTime)
        : `-${format(duration - currentTime)}`;
  
    const totalDuration = format(duration);

    // ------
    const [videoURL, setVideoURL] = useState('')
    const [isSmallDev, setIsSmallDev] = useState(false)
    const myCallback = () => (
      httpskillsquaduserprofile.put(`/skillsquadUserprofileLMS/updateVideoUnlockCount/${sessionStorage.getItem('userId')}/${props.selectedTopicData.topicIndex}`).then((response) => {
          console.log("video unlock count updated:", response.data)
          props.callparentfunction();
      }).catch((err) => {
          console.log(err)
      })
  );
    useEffect(() => {
          //console.log("VIDEO DATA : " + JSON.stringify(props.selectedTopicData))
          //console.log("VIDEO DATA : " + JSON.stringify(props.loginprofId))
          setBookmarks([])
        setVideoURL(props.selectedTopicData.s3PresignedUrlVideo)
        if (window.innerWidth < 900) {
            setIsSmallDev(true)
        } else {
            setIsSmallDev(false)
        }
    }, [props.selectedTopicData])
    return (
        <div>
            {props.selectedTopicData.topicIndex <= props.videoCount ? <div className="col-12 p-0 background-black radius10" onContextMenu={e => e.preventDefault()}>
           

           
            <Player playsInline src={videoURL} fluid={false} width={745}>
                <ControlBar autoHide={false} disableDefaultControls={false}>
                    <ReplayControl seconds={5} order={2.1} />
                    <ForwardControl seconds={5} order={3.1} />
                    <PlaybackRateMenuButton rates={[5, 2, 1.5, 1, 0.5, 0.1]} />
                </ControlBar>
            </Player>
        </div> :     
        
        // <Video src={videoURL} controls={['PlayPause',  'Time', 'Volume', 'Fullscreen']} height={isSmallDev ? '' : '440px'} controlsList="nodownload" className="videotag radius10" onEnded={() => myCallback()}></Video>

        <Container maxWidth="md">
        <div
          onMouseMove={handleMouseMove}
          onMouseLeave={hanldeMouseLeave}
          ref={playerContainerRef}
          className={classes.playerWrapper}
        >
          <ReactPlayer
            ref={playerRef}
            width="100%"
            height="100%"
            url={videoURL}
            pip={pip}
            playing={playing}
            controls={false}
            light={light}
            loop={loop}
            playbackRate={playbackRate}
            volume={volume}
            muted={muted}
            onProgress={handleProgress}
            onEnded={() => myCallback()}
          />

          <Controls
            ref={controlsRef}
            onSeek={handleSeekChange}
            onSeekMouseDown={handleSeekMouseDown}
            onSeekMouseUp={handleSeekMouseUp}
            onDuration={handleDuration}
            onRewind={handleRewind}
            onPlayPause={handlePlayPause}
            onFastForward={handleFastForward}
            playing={playing}
            played={played}
            elapsedTime={elapsedTime}
            totalDuration={totalDuration}
            onMute={hanldeMute}
            muted={muted}
            onVolumeChange={handleVolumeChange}
            onVolumeSeekDown={handleVolumeSeekDown}
            onChangeDispayFormat={handleDisplayFormat}
            playbackRate={playbackRate}
            onPlaybackRateChange={handlePlaybackRate}
            onToggleFullScreen={toggleFullScreen}
            volume={volume}
            onBookmark={addBookmark}
            title={props.selectedTopicData.topicName}
            onPlaybackQualityChange={e => console.log('onPlaybackQualityChange', e)}
          />
        </div>
        <ToastContainer 
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        width={100}
        />
        <Grid container style={{ marginTop: 20 }} spacing={3}>
          {bookmarks.map((bookmark, index) => (
            <Grid key={index} item>
              <Paper 
                onClick={() => {
                  playerRef.current.seekTo(bookmark.time);
                  controlsRef.current.style.visibility = "visible";

                  setTimeout(() => {
                    controlsRef.current.style.visibility = "hidden";
                  }, 1000);
                }}
                elevation={3}
              >
              
                <Typography variant="body2" align="center" >
                <div style={{fontWeight: 800, padding: 10,cursor:"pointer"}}>  Bookmark at {bookmark.display}</div>
                </Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>
        <canvas ref={canvasRef} />
      </Container>
        
        }
        
        </div>
        
        
    )
}
export default TrailTopicVideo;