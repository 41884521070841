import React from "react";
import { Route, Navigate } from "react-router-dom";


const ProtectedRoute = ({ children }) => {
  const token = sessionStorage.getItem("JWT");

  if (token == undefined) {
    return <Navigate to="/mentor-page/1:1-services/Recommended" />;
  }
  return children;
};

export default ProtectedRoute;
