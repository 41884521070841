import React from "react";
import Sidebar from "./Sidebar";
import "../fonts/Roboto/Roboto-Black.ttf";
import "../styles/PeerMock.css"
import moment from "moment/moment";
import hand from "../images/hand_wave.png"
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Popup from "../components/LockedPopup"
import { useState, useEffect } from "react";


const PeerMock = () => {
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const openPopup = () => {
        setIsPopupOpen(true);
    };

    const closePopup = () => {
        setIsPopupOpen(false);
    };
    return (
        <>
            <Sidebar />
           
            <div className="jobs-dashboard-container mx-4">

                {/* <Navb /> */}

                <div className="px-md-5 mt160">

                    <section class="section">
                        <div class="container">

                            <div class="row p-4">

                                <div class="col-lg-9">
                                    <div class="row">
                                        <div class="">
                                            <div class="section-title  mb-4 pb-2 ">
                                                <div className="col-lg-12">
                                                    <div className="row">

                                                        <div className="d-flex flex-column bg-white liveclasses-boxshadow liveclasses-top-section text-center">

                                                            <div><div class="peerMock-heading">Peer Mock Interviews</div></div>
                                                            <div class="peerMock-subheading">Practice makes perfect! Prepare for your interviews with thousands of Skillsquad members.</div>


                                                        </div>
                                                       
                                                        <div className="d-flex flex-column  liveclasses-top-section text-center">
                                                            <div class="mt-5 peerMock-perfect-heading mb-3">PRACTICE MAKES PERFECT</div>
                                                            <center><div class="peerMock-start-button button-trans" onClick={openPopup}>START A PRACTICE SESSION <FontAwesomeIcon icon={faLock} style={{marginLeft:10}} /></div>
                                                            {isPopupOpen && <Popup onClose={closePopup} />}
                                                            </center>
                                                            
                                                            <div class='peerMock mt-2'>
                                                                <div class="peer-left"><div></div></div>
                                                                <div class="peer-right"><div>GET MORE FOR FREE</div></div>
                                                            </div>
                                                            
                                                        </div>
                                                        <div class="container mt-5">
                                                        <center><div class="peerMock-how">How it works</div></center>
    <div class="row">
        <div class="col-md-4">
        <div class="referral-text"><center><div class="peer-image mb-3">1</div></center><div class="peer-text-subheading">Visit this page 5 minutes before the interviview start time. Select your interviw type and click "Get Matched".</div></div>
        </div>

        <div class="col-md-4">
        <div class="referral-text"><center><div class="peer-image mb-3">2</div></center><div class="peer-text-subheading">Join your interview over live video chat and take turns interviewing each other.</div></div>
        </div>
        <div class="col-md-4">
        <div class="referral-text"><center><div class="peer-image mb-3">3</div></center><div class="peer-text-subheading ">Fill out the feedback form after your interview. Be as specific as possible to help each other improve.</div></div>
        </div>
    </div>
</div>
                                                        
                                                        
                                                        <div>
                                                            
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>


                                </div>

                                {/*Filter section*/}
                            </div>

                        </div>

                    </section>



                </div>


            </div>


        </>
    );
};

export default PeerMock;
