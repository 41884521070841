import React, { useEffect, useState, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import left from "../../images/left-chev.svg";
import right from "../../images/right-chev.svg";
import { Navigation } from "swiper/modules";


const MentorTimeSlots = ({ mentorTimeSlots, handleTimeSlots, selectedTimeBox }) => {
    const prevRef = useRef(null);
    const nextRef = useRef(null);
    const swiperRef = useRef(null);

    // Function to update arrow visibility based on slides and container width
    const updateNavigationVisibility = () => {
        if (swiperRef.current && prevRef.current && nextRef.current) {
            const swiper = swiperRef.current;
            const { slides, width } = swiper;
            const slidesPerView = swiper.params.slidesPerView;
            const slideWidth = swiper.slides[0]?.offsetWidth || 0;
            const slidesContainerWidth = slidesPerView * slideWidth;

            // Show arrows only if the total slide width exceeds the container width
            const totalSlidesWidth = slides.length * slideWidth;
            if (totalSlidesWidth > width) {
                prevRef.current.style.display = swiper.isBeginning ? "none" : "block";
                nextRef.current.style.display = swiper.isEnd ? "none" : "block";
            } else {
                prevRef.current.style.display = "none";
                nextRef.current.style.display = "none";
            }
        }
    };

    useEffect(() => {
        updateNavigationVisibility();
    }, [mentorTimeSlots]);

    return (
        <div className="time-slots-container">
            <Swiper
                modules={[Navigation]}
                slidesPerView="auto"
                spaceBetween={10}
                navigation={{
                    prevEl: prevRef.current,
                    nextEl: nextRef.current,
                }}
                pagination={false}
                onSwiper={(swiper) => {
                    swiperRef.current = swiper;
                    updateNavigationVisibility();
                }}
                onSlideChange={() => {
                    updateNavigationVisibility();
                }}
            >
                {mentorTimeSlots?.map((time, index) => (
                    <SwiperSlide key={index} style={{ width: time.isBig ? "auto" : "auto" }}>

                        <div
                            className={
                                selectedTimeBox === index
                                    ? "remaining-dates-time-selected"
                                    : "remaining-dates-time"
                            }
                            onClick={() => handleTimeSlots(time, index)}
                        >
                            <div className="booked-time-slot-details-mentor">
                                <div className="b-a-t-date">
                                    {time.startTime}
                                </div>
                            </div>
                        </div>

                    </SwiperSlide>
                ))}
            </Swiper>


            <div
                ref={prevRef}
                className="swiper-button-prev"
                style={{
                    position: "absolute",
                    top: "0",
                    left: "85%",

                    zIndex: 10,
                    cursor: "pointer",
                }}
            >
                <img src={left} alt="Previous" />
            </div>
            <div
                ref={nextRef}
                className="swiper-button-next"
                style={{
                    position: "absolute",
                    top: "0",
                    right: "0",

                    zIndex: 10,
                    cursor: "pointer",
                }}
            >
                <img src={right} alt="Next" />
            </div>
        </div>
    );
}

export default MentorTimeSlots;