import React from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "./Sidebar";
import "../styles/ProgramDetails.css";
import "../fonts/Roboto/Roboto-Black.ttf";
import SideFilter from "../components/SideFilter";
import { useState, useEffect } from "react";
import httpuserprofile from "../js/http-lms_userprofile";
import moment from "moment/moment";
import itreion from "../images/company-logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationDot,
  faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";
import { Container } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import RightSidebar from "./RightSidebar";
import Spinner from "react-bootstrap/Spinner";
import ReactHtmlParser from "html-react-parser";
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import CalenderBlank from "../images/CalendarBlank.svg";
import bookmark from "../images/material-symbols_bookmark.svg";
import money from "../images/CurrencyDollar.svg";
import time from "../images/Clock.svg";
import location from "../images/MapPinLine.svg";
import logos from "../images/logos_amplication-icon.svg";
import { text } from "@fortawesome/fontawesome-svg-core";
import InfiniteScroll from "react-infinite-scroll-component";


const ProgramDetails = () => {
  const [allJobsList, setAllJobsList] = useState([]);
  const [totalPages, setTotalPages] = useState();
  const [dataPerPage, setDataPerPage] = useState(10);
  const [itemOffset, setItemOffset] = useState(0);
  const [endOffset, setEndOffset] = useState(itemOffset + dataPerPage);
  const [currentItems, setCurrentItems] = useState([]);
  const [loading, setLoading] = useState(1);
  const [message, setMessage] = useState("");
  const [scrollNumber, setScrollNumber] = useState(1);
  console.log("all job list", allJobsList)

  const navigate = useNavigate();
  //API call when page loader
  useEffect(() => {
    setLoading(0);

    httpuserprofile
    .get("/skillsquadUserprofileLMS/getAllPostedJobs?profileId=" + sessionStorage.getItem("skillsquadProfileId")+"&limit="+scrollNumber)
    .then((res) => {
      setLoading(1);

      console.log("Post a job list", JSON.stringify(res));
      setAllJobsList([]);
      setAllJobsList(res.data);
    });
  }, []);

  console.log(allJobsList)

  function jobSearch() {
    httpuserprofile
      .get("/skillsquadUserprofileLMS/searchJob?search=" + message)
      .then((res) => {
        setLoading(1);

        console.log("Post a job list", JSON.stringify(res));
        setAllJobsList([]);
        setAllJobsList(res.data);
      });
  }

  var CryptoJS = require("crypto-js");
  function toJobDescription(jobId, title, company, location) {
    sessionStorage.setItem("jobId", jobId);
    var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(jobId), 'skill').toString();
 
    navigate("/job-description/" + title + "-" + company + '-' + location );
    
  }

  useEffect(() => {
    setTotalPages(Math.round(allJobsList.length / dataPerPage));
  }, [allJobsList]);
  useEffect(() => {
    setEndOffset(itemOffset + dataPerPage);
  }, [itemOffset, allJobsList]);
  useEffect(() => {
    setCurrentItems(allJobsList.slice(itemOffset, endOffset));
    console.log(allJobsList.slice(itemOffset, endOffset), "ll");
  }, [endOffset, allJobsList, dataPerPage]);
  const handlePageClick = (event) => {
    const newOffset = (event.selected * dataPerPage) % allJobsList.length;
    setItemOffset(newOffset);
    console.log(totalPages);
    if (event.selected === totalPages - 1) {
      console.log("this is the final page");
    }
  };

  const handleChanges = (event) => {
    setMessage(event.target.value);
    setScrollNumber(1)
    if (message.length > 0) {
      httpuserprofile
        .get("/skillsquadUserprofileLMS/searchJob?search=" + event.target.value + "&profileId=" + sessionStorage.getItem("skillsquadProfileId")+"&limit="+scrollNumber)
        .then((res) => {
          setLoading(1);

          console.log("Post a job list", JSON.stringify(res));
          setAllJobsList([]);
          setAllJobsList(res.data);
        });
    } else if (message.length == 0) {
      httpuserprofile
        .get("/skillsquadUserprofileLMS/getAllPostedJobs?profileId=" + sessionStorage.getItem("skillsquadProfileId")+"&limit="+scrollNumber)
        .then((res) => {
          setLoading(1);

          console.log("Post a job list", JSON.stringify(res));
          setAllJobsList([]);
          setAllJobsList(res.data);
        });
    }
  };

  const InitialJobDescription = (html) => {

    if (React.isValidElement(html)) {

      html = ReactHtmlParser(html.props.children);
    }
    console.log("this is the first element", html)

    const words = html.trim().split(/\s+/);
    console.log(words, "list of items")
    if (words.length > 10) {

      return ReactHtmlParser(words.slice(0, 10).join(' ') + " ");
    } else {

      return ReactHtmlParser(html);
    }
  }

  function scrollset(){
    setScrollNumber(scrollNumber + 1)
  }

  function fetchMoreData ()  {
    // a fake async api call like which sends
    // 20 more records in 1.5 secs
    scrollset()
    var sum = scrollNumber + 1
    setTimeout(() => {
      if (message.length > 0) {
        httpuserprofile
          .get("/skillsquadUserprofileLMS/searchJob?search=" + message + "&profileId=" + sessionStorage.getItem("skillsquadProfileId")+"&limit="+sum)
          .then((res) => {
            setLoading(1);
  
            console.log("Post a job list", JSON.stringify(res));
            setAllJobsList([]);
            setAllJobsList(res.data);
          });
      } else if (message.length == 0) {
        httpuserprofile
          .get("/skillsquadUserprofileLMS/getAllPostedJobs?profileId=" + sessionStorage.getItem("skillsquadProfileId")+"&limit="+sum)
          .then((res) => {
            setLoading(1);
  
            console.log("Post a job list", JSON.stringify(res));
            setAllJobsList([]);
            setAllJobsList(res.data);
          });
      }
    }, 3000);
  };

  return (
    <>
      {/* Main Container */}
      <Sidebar />
      <div className="jobs-dashboard-container mx-4">
        {/* <Navb /> */}

        <div className="px-md-5 mt-5">
          <section class="section">
            <div class="container">
              <div class="row p-4">
                <div class="col-lg-9">
                  <div class="container mt-4 ">
                    <div class="row jobs_program_details">
                      <div class="d-flex mb-3 search-section">
                        <div class="search-container">
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M10.875 3.75C6.93997 3.75 3.75 6.93997 3.75 10.875C3.75 14.81 6.93997 18 10.875 18C14.81 18 18 14.81 18 10.875C18 6.93997 14.81 3.75 10.875 3.75ZM2.25 10.875C2.25 6.11154 6.11154 2.25 10.875 2.25C15.6385 2.25 19.5 6.11154 19.5 10.875C19.5 15.6385 15.6385 19.5 10.875 19.5C6.11154 19.5 2.25 15.6385 2.25 10.875Z"
                              fill="#141414"
                              fill-opacity="0.5"
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M15.9134 15.9135C16.2063 15.6206 16.6812 15.6206 16.9741 15.9135L21.5303 20.4698C21.8232 20.7627 21.8232 21.2375 21.5303 21.5304C21.2374 21.8233 20.7625 21.8233 20.4696 21.5304L15.9134 16.9742C15.6205 16.6813 15.6205 16.2064 15.9134 15.9135Z"
                              fill="#141414"
                              fill-opacity="0.5"
                            />
                          </svg>
                          <input
                            id="message"
                            name="message"
                            type="text"
                            onChange={handleChanges}
                            value={message}
                            style={{
                              border: "none",

                            }}
                            placeholder="What position are you looking for ?"
                          />
                        </div>

                        <div className="search-button">
                          <span>Search</span>
                        </div>
                      </div>
                      {/* <div class="col col-lg-2 common_main_button_black" onClick={jobSearch} style={{ backgroundColor: "black", padding: 5, color: "white", textAlign: "center", borderRadius: 10, marginTop: 5, marginBottom: 5, cursor: "pointer" }}>search</div> */}
                    </div>
                  </div>
                  {loading === 0 ? (
                    <div className=" h-100 d-flex mt-5  bgwhite justify-content-center align-items-center mb-3 py-5 ">
                      {" "}
                      <Spinner animation="border" variant="secondary" />
                    </div>
                  ) : (
                    <div class="me-lg-5">
                      {Array.isArray(allJobsList) ? (
                        <div>
                                <InfiniteScroll
          dataLength={allJobsList.length}
          next={fetchMoreData}
          hasMore={true}
          loader={<h4>Loading...</h4>}
        >
                          {allJobsList.map((data) => {
                            return (
                              <div
                                className="job-card mb-4"
                                onClick={() => {
                                  toJobDescription(data.jobId, data.roleName, data.companyProfile.companyName, data.companyProfile.city.cityName, data.jobId);
                                }}
                              >
                                <div className="logo-container">
                                  <img

                                    src={data.companyProfile.companyLogo}
                                    alt=""
                                  // style={{ height: "100px", width: "100px" }}
                                  />
                                </div>
                                <div className="job-details">
                                  <div className="heading-section d-flex align-items-center">
                                    <h1
                                      className="job-heading"
                                      style={{ marginBottom: "-4px" }}
                                    >
                                      {data.roleName}
                                    </h1>
                                    {data.appliedFlag === "Y" && <div class="d-flex align-items-center"> <img

                                      src={require("../images/tick-circle-solid.png")}
                                      alt=""
                                    // style={{ height: "100px", width: "100px" }}
                                    />  <div style={{ color: "#7E89A6", marginLeft: 5 }}>Application Submitted</div></div>}
                                    {/* <div className="new-post">
                                    {moment
                                      .utc(data.postedDate)
                                      .local()
                                      .startOf("seconds")
                                      .diff(moment(), 'hours') <= -24
                                      ? <span className="">Old</span>
                                      : <span className="">New Post</span>}
                                     
                                    </div> */}
                                  </div>
                                  <span className="company-name">{data.companyProfile.companyName}</span>
                                  <div className="job-perks ">
                                    <div className="job-perks-location">
                                      <img src={location} />
                                      <span>{data.companyProfile.city.cityName}</span>

                                    </div>
                                   {data.jobTypeMaster != null && <div className="job-perks-location">
                                      <img src={time} />
                                      <span>{data.jobTypeMaster.jobTypeName}</span>
                                    </div>}
                                    <div className="job-perks-location">
                                      <img src={money} />
                                      <span>{data.salary}</span>
                                    </div>
                                    <div className="ago-timer align-items-center d-flex">
                                      <img src={CalenderBlank} />
                                      <div style={{marginLeft: 5}}>{moment.utc(data.postedDate).local().startOf('seconds').fromNow()}</div>
                                    </div>
                                  </div>
                                  <div className="job-description d-flex align-items-center">
                                    {/* {ReactHtmlParser(data.jobDescription)} */}
                                    {/* <div className="d-flex align-items-center" style={{ position: "relative" }}>{InitialJobDescription(data.jobDescription)} <span style={{ color: "blue", cursor: "pointer", position: "absolute", bottom: "5px" }}>...Read more</span></div> */}
                                    {/* <span>{InitialJobDescription(data.jobDescription)}</span><span style={{color:"blue",cursor:"pointer"}}>...Read more</span> */}
                                  </div>
                                </div>
                                <div className="bookmark-container">
                                  {/* <img src={bookmark} /> */}
                                  {data.newPost === "Y" && <div className="new-post">
                                    <span className="">New Post</span>

                                  </div>}
                                </div>
                                {/* <div class="col col-lg-2 common_main_text_highlight common_main_button_black" style={{marginTop: 10}}  >Apply now</div> */}
                              </div>
                            );
                          })}
                          </InfiniteScroll>
                        </div>
                      ) : (
                        <div>No Jobs Found</div>
                      )}
                      <div></div>
                    </div>
                  )}
                </div>

                {/*Filter section*/}
              </div>
            </div>
          </section>
        </div>
      </div>
      <RightSidebar />
    </>
  );
};

export default ProgramDetails;
