import React from "react";
import { useState, useEffect, useRef } from "react";
import thum from "../../images/vid2.png"
import eventBus from "../../js/Event-bus";
import httpskillsquaduserprofile from "../../js/http-lms_userprofile"
import 'video-react/dist/video-react.css';
import { Player, ControlBar, PlaybackRateMenuButton, ForwardControl, ReplayControl } from 'video-react';
import Sidebar from "../Sidebar";
import arrow from "../../images/left-arrow_icon.png"
import { Navigate, useNavigate } from "react-router-dom";
import screenful from "screenfull";
import Controls from "../../components/Controls";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import ReactPlayer from "react-player";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import Slider from "@material-ui/core/Slider";
import Tooltip from "@material-ui/core/Tooltip";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Discussion from "../Discussion";
import RightSidebar from "../RightSidebar";
import GreenCheckbox from "../../images/tick mark.svg";
import httpsignup from "../../js/http-lms_userprofile";
import "../../styles/VideoFeedback.css";

const useStyles = makeStyles((theme) => ({
  playerWrapper: {
    width: "100%",

    position: "relative",
    // "&:hover": {
    //   "& $controlsWrapper": {
    //     visibility: "visible",
    //   },
    // },
  },

  controlsWrapper: {
    visibility: "hidden",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: "rgba(0,0,0,0.4)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  topControls: {
    display: "flex",
    justifyContent: "flex-end",
    padding: theme.spacing(2),
  },
  middleControls: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  bottomWrapper: {
    display: "flex",
    flexDirection: "column",

    // background: "rgba(0,0,0,0.6)",
    // height: 60,
    padding: theme.spacing(2),
  },

  bottomControls: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    // height:40,
  },

  button: {
    margin: theme.spacing(1),
  },
  controlIcons: {
    color: "#777",

    fontSize: 50,
    transform: "scale(0.9)",
    "&:hover": {
      color: "#fff",
      transform: "scale(1)",
    },
  },

  bottomIcons: {
    color: "#999",
    "&:hover": {
      color: "#fff",
    },
  },

  volumeSlider: {
    width: 100,
  },
}));

const PrettoSlider = withStyles({
  root: {
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    marginTop: -8,
    marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);

function ValueLabelComponent(props) {
  const { children, open, value } = props;

  return (
    <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
      {children}
    </Tooltip>
  );
}

const format = (seconds) => {
  if (isNaN(seconds)) {
    return `00:00`;
  }
  const date = new Date(seconds * 1000);
  const hh = date.getUTCHours();
  const mm = date.getUTCMinutes();
  const ss = date.getUTCSeconds().toString().padStart(2, "0");
  if (hh) {
    return `${hh}:${mm.toString().padStart(2, "0")}:${ss}`;
  }
  return `${mm}:${ss}`;
};

let count = 0;


const DayTopicVideo = () => {

  const [imageClicked, setImageClicked] = useState(false);
  const [selectedEmoji, setSelectedEmoji] = useState(null);

  //
  const [emojis, setEmojis] = useState([]);
  const [reasons, setReasons] = useState([]);

  // selected reason
  // const [selectedReason, setSelectedReason] = useState(null);
  const [selectedReasons, setSelectedReasons] = useState([]); 

  // store comments
  const [comments, setComments] = useState("");
  const [lmsMasterId, setLmsMasterId] = useState(null);
  const [feedbackCheck, setFeedbackCheck] = useState(false);

  const [reasonData, setReasonData] = useState({
    "emojiId": 1,
    "reasonId": [],
    "commentText": "good",
    // profileId: sessionStorage.getItem("profileId"),
    // lmsTopicMasterId: 3,
    "profileId": sessionStorage.getItem("circullumpld"),
      "lmsTopicMasterId": sessionStorage.getItem("topicId")
      // sessionStorage.getItem(circullumpld)
  });

  // const sendReasonData = async () => {
    
  //   console.log(reasonData, "this is reason data");
  //   const response = await httpsignup
  //     .post("/dashBoardLMS/saveUserVideoFeedback", reasonData)
  //     .then((response) => {
  //       console.log(response.data);
  //     })
  //     .catch((error) => console.log(error));
  // const dummy = selectedReasons.map(reason => reason.reasonId).toString().replace("[","").replace("]","");
  // console.log("dummy",selectedReasons.map(reason => reason.reasonId));
  // };
  const sendReasonData = async () => {
    const updatedReasonData = {
      
      "emojiId" : selectedEmoji?.emojiId,
      // reasonId: JSON.stringify(selectedReasons.map(reason => reason.reasonId)), // Convert the array of reason IDs to a string
      "reasonId" : JSON.stringify(selectedReasons), 
      
      
      "commentText" : comments,
      "profileId" : sessionStorage.getItem("circullumpId"),
      "lmsTopicMasterId" : sessionStorage.getItem("topicId")
    };
    console.log(updatedReasonData, "this is reason data");
    const response = await httpsignup.post("/dashBoardLMS/saveUserVideoFeedback", updatedReasonData)
      .then((response) => { console.log(response.data) 
        checkComment()
      })
      .catch(error => console.log(error));
  };

  const handleReasonData = (e) => {
    const { name, value } = e.target;
    setReasonData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // console.log(selectedReason);
  const getEmojisData = async () => {
    const response = await httpsignup
      .get("/dashBoardLMS/getAllFeedbackEmojiMaster")
      .then((response) => {
        console.log("this is the emojis data", response.data);
        setEmojis(response.data);
      })
      .catch((error) => console.log(error));
  };
  useEffect(() => {
    getEmojisData();
  }, []);

  const getSelectedEmojiReasons = async (id) => {
    const response = await httpsignup
      .get(`dashBoardLMS/getFeedbackReasonMasterByEmojiId?emojiId=${id}`)
      .then((response) => {
        console.log("reasons data", response.data);
        setReasons(response.data);
      })
      .catch((error) => console.log(error));
  };

  const handleImageClick = (emoji) => {
    setSelectedEmoji(emoji);
    getSelectedEmojiReasons(emoji.emojiId);
    setImageClicked(true);

    // Reset selected reason and comments
    // setSelectedReasons([]);
    setSelectedReasons([]);
    setComments("");
  };
  // const getEmojiClass = (emoji) => {
  //   return selectedEmoji === emoji ? "highlighted-text" : "";
  // };

  // Handle selection of reason
  // const handleReasonSelection = (reason) => {
  //   setSelectedReason(reason);
  // };
  const [idValue,setIdValue] = useState(null);
  const handleReasonSelection = (reason) => {

    // setSelectedReasons(prevSelectedReasons => {
    //   if (prevSelectedReasons.includes(reason.reasonId)) {
    //     return prevSelectedReasons.filter(r => r !== reason.reasonId);
    //   } else {
    //     return [...prevSelectedReasons, reason.reasonId];
    //   }
    // });
    console.log("my reason",reason)
    // setSelectedReasons({[...selectedReasons],reasonId:reason.reasonId})
    const newId = reason.reasonId;
    const updatedIds = selectedReasons.filter(id => id !== newId)
    if(!selectedReasons.includes(newId)){
      setSelectedReasons([...selectedReasons,newId])
    }

    
    console.log("all reasons",selectedReasons)

  };
  

  // Handle input change for comments
  const handleCommentsChange = (event) => {
    setComments(event.target.value);
  };

  // State to track which checkboxes are checked
  const [checkedStates, setCheckedStates] = useState(
    new Array(reasons.length).fill(false)
  );

  // Handle checkbox change
  const handleCheckboxChange = (index) => {
    const updatedCheckedStates = [...checkedStates];
    updatedCheckedStates[index] = !updatedCheckedStates[index];
    setCheckedStates(updatedCheckedStates);
  };

  const [intialLaunch, setInitialLaunch] = useState(0);
  const classes = useStyles();
  const [showControls, setShowControls] = useState(false);
  // const [count, setCount] = useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [timeDisplayFormat, setTimeDisplayFormat] = React.useState("normal");
  const [bookmarks, setBookmarks] = useState([]);
  const [state, setState] = useState({
    pip: false,
    playing: true,
    controls: false,
    light: false,

    muted: true,
    played: 0,
    duration: 0,
    playbackRate: 1.0,
    volume: 1,
    loop: false,
    seeking: false,
  });

  const playerRef = useRef(null);
  const playerContainerRef = useRef(null);
  const controlsRef = useRef(null);
  const canvasRef = useRef(null);
  const {
    playing,
    controls,
    light,

    muted,
    loop,
    playbackRate,
    pip,
    played,
    seeking,
    volume,
  } = state;

  const [videoHistory, setVideoHistory] = useState(0)

  const handlePlayPause = () => {
    setState({ ...state, playing: !state.playing });
  };

  const handleRewind = () => {
    console.log("REWID : " + playerRef.current.getCurrentTime())
    playerRef.current.seekTo(playerRef.current.getCurrentTime() - 10);

      httpskillsquaduserprofile.post(`/programsLMS/saveVideoHistory`,
        {
          lmsProfileId: sessionStorage.getItem("dayProfileId"),
          lmsProgramId: sessionStorage.getItem("myCourseProgramId"),
          topicIndex: sessionStorage.getItem("topicIndex"),
          watchTime: parseInt((playerRef.current.getCurrentTime() -10).toFixed()),

        })
        .then((response) => {
        


        }).catch((err) => {
          console.log(err)
        })

   
  };

  const handleFastForward = () => {
    playerRef.current.seekTo(playerRef.current.getCurrentTime() + 10);
  };

  const handleProgress = (changeState) => {
  //  console.log("STETE : " + JSON.stringify((changeState.playedSeconds % 5).toFixed()))
    if ((changeState.playedSeconds % 5).toFixed() == 0 || (changeState.playedSeconds % 5).toFixed() == 5) {
      console.log("test 1 :  " +changeState.playedSeconds )
      
      httpskillsquaduserprofile.get(`/programsLMS/getVideoHistory?profileId=${sessionStorage.getItem("dayProfileId")}&topicIndex=${sessionStorage.getItem("topicIndex")}&programId=${sessionStorage.getItem("myCourseProgramId")}`)
        .then((response) => {
          console.log("test 2 :  " +response.data.watchTime )
          if (intialLaunch === 0) {
            if (changeState.playedSeconds < response.data.watchTime) {
              playerRef.current.seekTo(changeState.playedSeconds + response.data.watchTime)
            }
          }
          httpskillsquaduserprofile.post(`/programsLMS/saveVideoHistory`,
            {
              lmsProfileId: sessionStorage.getItem("dayProfileId"),
              lmsProgramId: sessionStorage.getItem("myCourseProgramId"),
              topicIndex: sessionStorage.getItem("topicIndex"),
              watchTime: parseInt((changeState.playedSeconds).toFixed()),

            })
            .then((response) => {
              setInitialLaunch(1)


            }).catch((err) => {
              console.log(err)
            })




          //     if((changeState.playedSeconds % 5).toFixed() == 0){
          //       console.log((changeState.playedSeconds).toFixed())
          //       httpskillsquaduserprofile.post(`/programsLMS/saveVideoHistory`,
          //       {
          //         lmsProfileId: sessionStorage.getItem("dayProfileId"),
          //         lmsProgramId: sessionStorage.getItem("dayProgramId"),
          //         topicIndex: sessionStorage.getItem("topicIndex"),
          //         watchTime: parseInt( (changeState.playedSeconds).toFixed()),

          //     })
          //       .then((response) => {
          //         console.log("NEW TIME : " + response.data.watchTime)
          // if(response.data.watchTime > 0){
          //   console.log("NEW TIME : " + response.data.watchTime)
          //   if (changeState.playedSeconds < response.data.watchTime) {
          //     playerRef.current.seekTo(changeState.playedSeconds + response.data.watchTime)
          //   }
          // }

          //       }).catch((err) => {
          //         console.log(err)
          //       })
          //     }

          // navigate("/dayChallenge")

        }).catch((err) => {
          console.log(err)
        })
    }



    if (count > 3) {
      controlsRef.current.style.visibility = "hidden";
      count = 0;
    }
    if (controlsRef.current.style.visibility == "visible") {
      count += 1;
    }
    if (!state.seeking) {
      setState({ ...state, ...changeState });
    }
  };

  const handleSeekChange = (e, newValue) => {
    console.log({ newValue });
    if (parseFloat(newValue / 100) <= played) {
      console.log("you are allowed")
      setState({ ...state, played: parseFloat(newValue / 100) });
      playerRef.current.seekTo(newValue / 100, "fraction");
    } else {
      notify();
      setState({ ...state, played: parseFloat(newValue / 100) });
    }

  };

  const handleSeekMouseDown = (e) => {
    setState({ ...state, seeking: true });
  };
  const notify = () => toast.error("You cannot seek forward unless you have watched the video at least once");
  const handleSeekMouseUp = (e, newValue) => {
    // notify();
    // setState({ ...state, seeking: false });
    //   //console.log(sliderRef.current.value)
    // playerRef.current.seekTo(newValue / 100, "fraction");

  };

  const handleDuration = (duration) => {

    setState({ ...state, duration });
  };

  const handleVolumeSeekDown = (e, newValue) => {
    setState({ ...state, seeking: false, volume: parseFloat(newValue / 100) });
  };
  const handleVolumeChange = (e, newValue) => {
    // console.log(newValue);
    setState({
      ...state,
      volume: parseFloat(newValue / 100),
      muted: newValue === 0 ? true : false,
    });
  };

  const toggleFullScreen = () => {
    screenful.toggle(playerContainerRef.current);
  };

  const handleMouseMove = () => {
    console.log("mousemove");
    controlsRef.current.style.visibility = "visible";
    count = 0;
  };

  const hanldeMouseLeave = () => {
    controlsRef.current.style.visibility = "hidden";
    count = 0;
  };

  const handleDisplayFormat = () => {
    setTimeDisplayFormat(
      timeDisplayFormat == "normal" ? "remaining" : "normal"
    );
  };

  const handlePlaybackRate = (rate) => {
    setState({ ...state, playbackRate: rate });
  };

  const hanldeMute = () => {
    setState({ ...state, muted: !state.muted });
  };

  const addBookmark = () => {
    const canvas = canvasRef.current;
    canvas.width = 160;
    canvas.height = 90;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      playerRef.current.getInternalPlayer(),
      0,
      0,
      canvas.width,
      canvas.height
    );
    const dataUri = canvas.toDataURL();
    canvas.width = 0;
    canvas.height = 0;
    const bookmarksCopy = [...bookmarks];
    bookmarksCopy.push({
      time: playerRef.current.getCurrentTime(),
      display: format(playerRef.current.getCurrentTime()),
      image: dataUri,
    });
    setBookmarks(bookmarksCopy);
  };

  const currentTime =
    playerRef && playerRef.current
      ? playerRef.current.getCurrentTime()
      : "00:00";

  const duration =
    playerRef && playerRef.current ? playerRef.current.getDuration() : "00:00";
  const elapsedTime =
    timeDisplayFormat == "normal"
      ? format(currentTime)
      : `-${format(duration - currentTime)}`;

  const totalDuration = format(duration);

  const [videoURL, setVideoURL] = useState('')
  const [isSmallDev, setIsSmallDev] = useState(false)
  const videoRef = useRef(null);
  const navigate = useNavigate();
  const myCallback = () => (
    httpskillsquaduserprofile.put(`/programsLMS/updateScheduleDayChallege?profileId=${sessionStorage.getItem("dayProfileId")}&videoCount=${sessionStorage.getItem("topicCount")}&programId=${sessionStorage.getItem("dayProgramId")}`)
      .then((response) => {

        console.log("video unlock count updated:", response.data)
        navigate("/day-challenge")

      }).catch((err) => {
        console.log(err)
      })
  );

  useEffect(() => {
    //console.log("VIDEO DATA : " + JSON.stringify(props.videoCount))
    //console.log("VIDEO DATA : " + videoRef.current.duration)
    setBookmarks([])
    setVideoURL(sessionStorage.getItem("topicUrl"))
    if (window.innerWidth < 900) {
      setIsSmallDev(true)
    } else {
      setIsSmallDev(false)
    }
    checkComment()
  }, [])
  function dayChallenge() {
    navigate("/day-challenge")
  }

  var myVid = document.getElementById("video1");
  function myTimer() {
    console.log("HHHHHH : " + myVid.currentTime)
    myVid.currentTime = myVid.currentTime - 5;
    // if(myVid.currentTime == 5* 60)
    // {
    //    myVid.pause();
    //    window.clearInterval(timer);
    // }
  }

  function checkComment(){
    httpskillsquaduserprofile.get(`/dashBoardLMS/getUserVideoFeedback?profileId=${sessionStorage.getItem("circullumpId")}&topicId=${sessionStorage.getItem("topicId")}`)
    .then((response) => {
if(response.data.feedbackId != null){
  setFeedbackCheck(true)
}
      
      
    }).catch((err) => {
      console.log(err)
    })
  }

  return (
    <>
      <Sidebar />
      <div className="jobs-dashboard-container mx-4">

        {/* <Navb /> */}

        <div className="px-md-5 mt160">
          <h1 className="ps-md-5 mt-2 mb-4 d-flex align-items-center"><img src={arrow} width={30} alt="arrow" style={{ cursor: "pointer" }} onClick={dayChallenge} /><div style={{ marginLeft: 5 }} class="common_main_heading">{sessionStorage.getItem("topicName")}</div></h1>
          <section class="section">
            <div class="container">

              <div class="row p-4">

                <div class="col-lg-12">
                  <div class="row">
                    <div class="">
                      <div class="section-title  mb-4 pb-2">
                        <div className="col-lg-9">
                          <div className="row">
                            {parseInt(sessionStorage.getItem("completedTask")) < parseInt(sessionStorage.getItem("topicCount")) ? <Container maxWidth="md">
                              <div
                                onMouseMove={handleMouseMove}
                                onMouseLeave={hanldeMouseLeave}
                                ref={playerContainerRef}
                                className={classes.playerWrapper}
                              >
                                <ReactPlayer
                                  ref={playerRef}
                                  width="100%"
                                  height="100%"
                                  url={videoURL}
                                  pip={pip}
                                  playing={playing}
                                  controls={false}
                                  light={light}
                                  loop={loop}
                                  playbackRate={playbackRate}
                                  volume={volume}
                                  muted={muted}
                                  onProgress={handleProgress}
                                  onEnded={() => myCallback()}
                                />

                                <Controls
                                  ref={controlsRef}
                                  onSeek={handleSeekChange}
                                  onSeekMouseDown={handleSeekMouseDown}
                                  onSeekMouseUp={handleSeekMouseUp}
                                  onDuration={handleDuration}
                                  onRewind={handleRewind}
                                  onPlayPause={handlePlayPause}
                                  onFastForward={handleFastForward}
                                  playing={playing}
                                  played={played}
                                  elapsedTime={elapsedTime}
                                  totalDuration={totalDuration}
                                  onMute={hanldeMute}
                                  muted={muted}
                                  onVolumeChange={handleVolumeChange}
                                  onVolumeSeekDown={handleVolumeSeekDown}
                                  onChangeDispayFormat={handleDisplayFormat}
                                  playbackRate={playbackRate}
                                  onPlaybackRateChange={handlePlaybackRate}
                                  onToggleFullScreen={toggleFullScreen}
                                  volume={volume}
                                  onBookmark={addBookmark}
                                  title={sessionStorage.getItem("topicName")}

                                />
                              </div>
                              <ToastContainer
                                position="bottom-center"
                                autoClose={5000}
                                hideProgressBar={true}
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnFocusLoss
                                draggable
                                pauseOnHover
                                theme="light"
                                width={100}
                              />
                            {bookmarks.length > 0 &&   <Grid container style={{ marginTop: 20 }} spacing={3}>
                                {bookmarks.map((bookmark, index) => (
                                  <Grid key={index} item>
                                    <Paper
                                      onClick={() => {
                                        playerRef.current.seekTo(bookmark.time);
                                        controlsRef.current.style.visibility = "visible";

                                        setTimeout(() => {
                                          controlsRef.current.style.visibility = "hidden";
                                        }, 1000);
                                      }}
                                      elevation={3}
                                    >

                                      <Typography variant="body2" align="center" >
                                        <div style={{ fontWeight: 800, padding: 10, cursor: "pointer" }}>  Bookmark at {bookmark.display}</div>
                                      </Typography>
                                    </Paper>
                                  </Grid>
                                ))}
                              </Grid>}
                              {bookmarks.length > 0 &&  <canvas ref={canvasRef} />}
                            </Container> :
                              <Player playsInline src={videoURL} fluid={true} width={745}>
                                <ControlBar autoHide={false} disableDefaultControls={false}>
                                  <ReplayControl seconds={5} order={2.1} />
                                  <ForwardControl seconds={5} order={3.1} />
                                  <PlaybackRateMenuButton rates={[5, 2, 1.5, 1, 0.5, 0.1]} />
                                </ControlBar>
                              </Player>
                            }

                          </div>
                          <div class="row">
<Discussion programId={sessionStorage.getItem("myCourseProgramId")} topicId={sessionStorage.getItem("topicId")} profileId = {sessionStorage.getItem("circullumpId")}/>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>


                </div>

                {/*Filter section*/}
              </div>

            </div>

          </section>



        </div>
        <div style={{ paddingLeft: "4.5rem" }}>
          <div>
            <div
              class="modal fade "
              id="exampleModalToggle"
              aria-hidden="true"
              aria-labelledby="exampleModalToggleLabel"
              tabindex="-1"
            >
              <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content main-modal">
                  <div class="modal-header bg-white">
                    {/* <h1 class="modal-title fs-5" id="exampleModalToggleLabel">
                Modal 1
              </h1> */}
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="modal-body modal-para1">
                    Share your experience
                  </div>
                  <div className="modal-emoji">
                    {emojis.map((emoji, index) => (
                      <div key={index}>
                        <img
                          src={emoji.emojiUrl}
                          alt={emoji.emojiText}
                          onClick={() => handleImageClick(emoji)}
                          style={{ cursor: "pointer" }}
                          className={
                            selectedEmoji === emoji ? "" : "greyed-out"
                          }
                        />
                        {/* <p className={`emoji-text ${getEmojiClass(emoji)}`}>
                          {emoji.emojiText}
                        </p> */}
                        <p
                          className={`emoji-text ${
                            selectedEmoji === emoji ? "before-greyed-out-text" : "greyed-out-text"
                          }`}
                        >
                          {emoji.emojiText}
                        </p>
                      </div>
                    ))}

                    
                  </div>
                  <div>
                    {imageClicked && (
                      <div className="float-div">
                        <p className="para2">What did you like ?</p>
                        <p className="para3">
                          We hope you re looking forward to your course! It
                          would be lovely to know what you liked about the
                          course.
                        </p>

                        
                        <div style={{ cursor: "pointer" }}>
                          
                          <div className="checkbox-container">
                            {reasons.map((reason, index) => (
                              <label
                                key={index}
                                className={`checkbox-label ${
                                  checkedStates[index] ? "checked" : ""
                                }`}
                                onClick={() => handleReasonSelection(reason)} // Call handleReasonSelection on click
                              >
                                {/* {reason.label} */}
                                {reason.reasonText}

                                <input
                                  type="checkbox"
                                  className="custom-checkbox"
                                  name="reason"
                                  value={reason.value}
                                  // checked={checkedStates[index]}
                                  onChange={() => handleCheckboxChange(index)}
                                  checked={selectedReasons === reason} // Check if the reason is selected
                                />
                                <span className="checkmark"></span>
                              </label>
                            ))}
                          </div>
                        </div>

                        <div>
                          {" "}
                          <textarea
                            style={{
                              width: "100%",
                              height: "70px",
                              borderRadius: "9px",
                              paddingTop: "1rem",
                              paddingLeft: "0.5rem",
                            }}
                            className="comment-box"
                            placeholder="Add your comments..."
                            // value={comment}
                            // onChange={handleInputChange}
                            // maxLength={maxLength}

                            value={comments}
                            onChange={handleCommentsChange}
                          />{" "}
                          <button
                            className="submit-btn"
                            data-bs-target="#exampleModalToggle2"
                            data-bs-toggle="modal"
                            onClick={sendReasonData}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    )}
                  </div>


                </div>
              </div>
            </div>

           {feedbackCheck ? <div style={{color: "green",fontWeight: 600}}>Submitted Feedback</div> : <button
              class="btn btn-primary"
              data-bs-target="#exampleModalToggle"
              data-bs-toggle="modal"
              style={{ marginBottom: "3rem" }}
            >
              Give Feedback
            </button>}
          </div>
          <div
            class="modal fade"
            id="exampleModalToggle2"
            aria-hidden="true"
            aria-labelledby="exampleModalToggleLabel2"
            tabindex="-1"
          >
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content main-modal2">
                <div class="modal-header bg-white">
                  <h1 class="modal-title fs-5" id="exampleModalToggleLabel2">
                    {/* Modal 2 */}
                    <img
                      className="header-image"
                      src={GreenCheckbox}
                      alt="checkbox"
                    />
                  </h1>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="modal-body modal2-para1">Feedback Submitted</div>
                <div className="modal2-para2">
                  Thank you for your valuable feedback. Your input helps us
                  improve Skillsquad.
                </div>
                <div className="close-button-div">
                  <button
                    className="close-button"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    Close
                  </button>
                </div>
                {/* <div class="modal-footer">
                  <button
                    class="btn btn-primary"
                    data-bs-target="#exampleModalToggle"
                    data-bs-toggle="modal"
                  >
                    Back to first
                  </button>
                </div> */}
              </div>
            </div>
          </div>
        </div>

      </div>
      {/* <RightSidebar /> */}
    </>
  )
}
export default DayTopicVideo;