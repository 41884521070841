import React from "react";
import { IoCloseOutline } from "react-icons/io5";

const BecomeMentorRequest = ({ request, handleBecomeMentorRequestClose }) => {
  return (
    <div>
      
        <div >
          <div
            className=""
            style={{ position: "relative"}}
          >
            <div className="d-flex align-items-center justify-content-between">
            <h2>Become a mentor</h2>
            <div >
              <IoCloseOutline style={{ fontSize: "2rem" }} onClick={handleBecomeMentorRequestClose} />
            </div>
            </div>
            <div className="submit-mentor-request">
              <div className="after-submit-checkmark"></div>
              <div>
                <h1 className="submit-heading">
                  Your application has been submitted!
                </h1>
                <p className="submit-para">
                  Thank you for submitting your profile! Your profile will now
                  undergo verification by our admin team to ensure it meets our
                  platform's standards. Once verified, you'll receive a
                  notification confirming your approval as a mentor within 3-5
                  days. We appreciate your patience and look forward to
                  welcoming you as part of our mentor community. If you have any
                  questions, feel free to reach out to our{" "}
                  <span className="submit-support"><a href="mailto:support@skillsquad.io" className="submit-support">support@skillsquad.io</a></span>
                </p>
              </div>
              <div className="request-close">
                <button className="btn btn-dark" onClick={handleBecomeMentorRequestClose}>Close</button>
              </div>
            </div>
          </div>
        </div>
      
    </div>
  );
};

export default BecomeMentorRequest;
