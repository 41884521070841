import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Table } from "semantic-ui-react";
import "../../styles/reviewpage.css";
import { CiCircleChevDown } from "react-icons/ci";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { RxCross2 } from "react-icons/rx";
import { AiTwotoneUpCircle } from "react-icons/ai";
import { TiTick } from "react-icons/ti";
import AccordionItem from "../AccordionItem";
import { MdArrowDropUp } from "react-icons/md";
import { IoMdArrowDropdown } from "react-icons/io";

import httpskillsquaduserprofile from "../../js/http-lms_userprofile";

const QNA = ({ topicId, questionsAndAnswers }) => {
  const [optionData, setOptionData] = useState([]);
  const [openItemIndex, setOpenItemIndex] = useState(null);
  const [array, setArray] = useState();

  const toggleItem = (index) => {
    setOpenItemIndex(openItemIndex === index ? null : index);
  };
  const data = ["False", "True", "dfd", "dsde"];
  // console.log(questionsAndAnswers);
  // const {incorrectAnswers} = optionData;
  // console.log("incorrect" + JSON.stringify(incorrectAnswers));
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await httpskillsquaduserprofile.get(
          `/programsLMS/getQuizResults?profileId=${sessionStorage.getItem(
            "dayProfileId"
          )}&programId=${sessionStorage.getItem(
            "myCourseProgramId"
          )}&quizCode=${topicId}`
        );

        setOptionData(response.data);
      } catch (err) {
        console.log(err);
      }
    };

    fetchData();
  }, []);

  console.log(optionData);
  const newArr = optionData.map(
    ({ correctAnswer, incorrectAnswers, question, userAnswer }) => {
      const options = [correctAnswer, ...incorrectAnswers];
      return { options, question, userAnswer, correctAnswer };
    }
  );

  console.log(newArr);

  return (
    <>
      <div
        className="reviewPage"
        style={{ paddingTop: "20%", paddingLeft: "5%", paddingBottom: "10%" }}
      >
        {newArr.map((item, index) => {
          return (
            <div className="row">
              <div
                className=" accordian d-flex flex-column mb-3"
                style={{ padding: "15px", width: "95%" }}
              >
                <div className="d-flex align-items-center">
                  <div className="">
                    <div
                      className="col-lg-1 d-flex align-items-center justify-content-center"
                      style={{
                        border: "1px solid",
                        padding: "11px",
                        borderRadius: "50%",
                        marginRight: "10px",
                        textAlign: "center",
                        width: "20px",
                        height: "20px",
                      }}
                    >
                      {index + 1}
                    </div>
                  </div>
                  <span
                    className="col-lg-8 text-bold"
                    style={{ marginRight: "7px", fontWeight: "600" }}
                  >
                    {item.question}
                  </span>
                  <div className="col-lg-2">
                    <span>
                      {item.userAnswer === item.correctAnswer ? (
                        <TiTick
                          style={{
                            backgroundColor: "green",
                            borderRadius: "50%",
                            padding: "2px",
                            fontSize: "1.5rem",
                            color: "white",
                          }}
                        />
                      ) : (
                        <RxCross2
                          style={{
                            backgroundColor: "red",
                            borderRadius: "50%",
                            padding: "2px",
                            fontSize: "1.5rem",
                            color: "white",
                          }}
                        />
                      )}
                    </span>
                    <span style={{ marginLeft: "7px" }}>
                      {item.userAnswer === item.correctAnswer
                        ? "correct"
                        : "Incorrect"}
                    </span>
                  </div>
                  <div
                    className="d-flex col-lg-1"
                    style={{ justifyContent: "flex-end", flex: 1 }}
                    onClick={() => toggleItem(index)}
                  >
                    <span style={{ fontSize: "1.5rem" }}>
                      {openItemIndex === index ? (
                        <MdArrowDropUp />
                      ) : (
                        <IoMdArrowDropdown />
                      )}
                    </span>
                  </div>
                </div>
                {openItemIndex === index && (
                  <AccordionItem
                    item={item}
                    // data={data}
                    style={{ transition: ".3s ease-in" }}
                  />
                )}
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

QNA.propTypes = {
  questionsAndAnswers: PropTypes.array.isRequired,
};

export default QNA;
