import React from "react";
import { useState, useEffect } from "react";
import thum from "../../images/vid2.png"
import eventBus from "../../js/Event-bus";
import httpskillsquaduserprofile from "../../js/http-lms_userprofile"
import 'video-react/dist/video-react.css';
import { Player, ControlBar, PlaybackRateMenuButton, ForwardControl, ReplayControl } from 'video-react';
import Sidebar from "../Sidebar";
import arrow from "../../images/left-arrow_icon.png"
import { Navigate, useNavigate } from "react-router-dom";


const CompletedTopicVideo = () => {
    const [videoURL, setVideoURL] = useState('')
    const [isSmallDev, setIsSmallDev] = useState(false)
    const navigate = useNavigate();
    
   
    useEffect(() => {
          
         setVideoURL( sessionStorage.getItem("completetopicUrl"))
        if (window.innerWidth < 900) {
            setIsSmallDev(true)
        } else {
            setIsSmallDev(false)
        }
    }, [])
function dayChallenge(){
  navigate("/day-challenge-complete")
}

    return (
        <>
        <Sidebar />
        <div className="jobs-dashboard-container mx-4">

        {/* <Navb /> */}

        <div className="px-md-5 mt160">
          <h1 className="ps-md-5 mt-2 mb-4 d-flex align-items-center"><img  src={arrow} width={30} alt="arrow" style={{cursor: "pointer"}} onClick={dayChallenge} /><div style={{marginLeft: 5}}>{sessionStorage.getItem("completetopicName")}</div></h1>
          <section class="section">
            <div class="container">

              <div class="row p-4">

                <div class="col-lg-9">
                  <div class="row">
                    <div class="">
                      <div class="section-title  mb-4 pb-2">
                        <div className="col-lg-12">
                          <div className="row">
                        
                           <Player playsInline src={videoURL} fluid={false} width={745}>
                           <ControlBar autoHide={false} disableDefaultControls={false}>
                               <ReplayControl seconds={5} order={2.1} />
                               <ForwardControl seconds={5} order={3.1} />
                               <PlaybackRateMenuButton rates={[5, 2, 1.5, 1, 0.5, 0.1]} />
                           </ControlBar>
                       </Player>
                           
              
                </div>
                        </div>

                      </div>
                    </div>
                  </div>


                </div>

                {/*Filter section*/}
              </div>

            </div>

          </section>



        </div>


      </div>
      </>
    )
}
export default CompletedTopicVideo;