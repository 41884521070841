import React from 'react'
import { useMediaQuery } from 'react-responsive'
import '../styles/CoursePage.css'
import Accordion from "./Accordion";
import FAQAccordion from "./FAQAccordion";
import httpsignup from '../js/http-lms_userprofile';
import { useState, useEffect, useRef } from "react";
import moment from "moment/moment";
import { Player, ControlBar, PlaybackRateMenuButton, ForwardControl, ReplayControl } from 'video-react';
import useRazorpay from "react-razorpay";
import { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { NewFooter } from '../components/Footer/NewFooter';
import { Buffer } from "buffer";
import axios from 'axios'
import arrowL from "../images/left-arrow_icon.png"
import Marquee from 'react-fast-marquee';
import Avatar1 from "../images/Avatar1.png";
import Avatar2 from "../images/Avatar2.png";
import Avatar3 from "../images/Avatar3.png";
import Commas from "../images/commas.svg";
import ReactGA from 'react-ga';
import useAnalyticsEventTracker from '../components/UseAnalyticsEventTracker';
import { Helmet } from 'react-helmet';
import { trackEvent } from '../facebookPixel';


const CoursePage = () => {
    const gaEventTracker = useAnalyticsEventTracker('Contact us');
    const params = useParams()
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    const navigate = useNavigate();
    const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })

    const [allDayCirriculumList, setAllDayCirriculumList] = useState([])

    const [scrollLength, setScrollLength] = useState(0)
    const [userDetails, setUserDetails] = useState({})

    const [whatIsNav, setWhatIsNav] = useState('col')
    const [forWhoNav, setForWhoNav] = useState('col')
    const [highlightsNav, setHighlightsNav] = useState('col')
    const [curriculumNav, setCurriculumNav] = useState('col')
    const [toolsCoveredNav, setToolsCoveredNav] = useState('col')
    const [projectsNav, setProjectsNav] = useState('col')
    const [jobRolesNav, setJobRolesNav] = useState('col')
    const [feesNav, setFeesNav] = useState('col')
    const [faqsNav, setFaqsNav] = useState('col')
    const [navSet, setNavSet] = useState('row common_main_text_tag laptop-sidebar sfixed course_nav align-items-center')

    const whatIs = useRef(null)
    const forWho = useRef(null)
    const highlights = useRef(null)
    const curriculum = useRef(null)
    const toolsCovered = useRef(null)
    const projects = useRef(null)
    const jobRoles = useRef(null)
    const fees = useRef(null)
    // const successStories = useRef(null)
    const faqs = useRef(null)

    const executeScrollWhatIs = () => whatIs.current.scrollIntoView()
    const executeScrollForWho = () => forWho.current.scrollIntoView()
    const executeScrollHighlights = () => highlights.current.scrollIntoView()
    const executeScrollCirriculum = () => curriculum.current.scrollIntoView()
    const executeScrollToolsCovered = () => toolsCovered.current.scrollIntoView()
    const executeScrollProjects = () => projects.current.scrollIntoView()
    const executeScrollJobRoles = () => jobRoles.current.scrollIntoView()
    const executeScrollFees = () => fees.current.scrollIntoView()
    // const executeScrollSuccessStories = () => successStories.current.scrollIntoView()
    const executeScrollFAQs = () => faqs.current.scrollIntoView()
    const [copied, setCopied] = useState(false);


    function copy() {
        const el = document.createElement("input");
        el.value = window.location.href;
        document.body.appendChild(el);
        el.select();
        document.execCommand("copy");
        document.body.removeChild(el);
        setCopied(true);
    }

    function whatIssetNav() {
        setWhatIsNav('col laptop_course_nav_button');
        setForWhoNav('col');
        setHighlightsNav('col');
        setCurriculumNav('col');
        setToolsCoveredNav('col');
        setProjectsNav('col');
        setJobRolesNav('col');
        setFeesNav('col');
        setFaqsNav('col');
    }

    function forWhosetNav() {

        setWhatIsNav('col');
        setForWhoNav('col laptop_course_nav_button');
        setHighlightsNav('col');
        setCurriculumNav('col');
        setToolsCoveredNav('col');
        setProjectsNav('col');
        setJobRolesNav('col');
        setFeesNav('col');
        setFaqsNav('col');
    }

    function highlightssetNav() {

        setWhatIsNav('col');
        setForWhoNav('col');
        setHighlightsNav('col laptop_course_nav_button');
        setCurriculumNav('col');
        setToolsCoveredNav('col');
        setProjectsNav('col');
        setJobRolesNav('col');
        setFeesNav('col');
        setFaqsNav('col');
    }

    function curriculumsetNav() {

        setWhatIsNav('col');
        setForWhoNav('col');
        setHighlightsNav('col');
        setCurriculumNav('col laptop_course_nav_button');
        setToolsCoveredNav('col');
        setProjectsNav('col');
        setJobRolesNav('col');
        setFeesNav('col');
        setFaqsNav('col');
    }

    function toolsCoveredsetNav() {

        setWhatIsNav('col');
        setForWhoNav('col');
        setHighlightsNav('col');
        setCurriculumNav('col');
        setToolsCoveredNav('col laptop_course_nav_button');
        setProjectsNav('col');
        setJobRolesNav('col');
        setFeesNav('col');
        setFaqsNav('col');
    }

    function projectssetNav() {

        setWhatIsNav('col');
        setForWhoNav('col');
        setHighlightsNav('col');
        setCurriculumNav('col');
        setToolsCoveredNav('col');
        setProjectsNav('col laptop_course_nav_button');
        setJobRolesNav('col');
        setFeesNav('col');
        setFaqsNav('col');
    }

    function jobRolessetNav() {

        setWhatIsNav('col');
        setForWhoNav('col');
        setHighlightsNav('col');
        setCurriculumNav('col');
        setToolsCoveredNav('col');
        setProjectsNav('col');
        setJobRolesNav('col laptop_course_nav_button');
        setFeesNav('col');
        setFaqsNav('col');
    }

    function feessetNav() {

        setWhatIsNav('col');
        setForWhoNav('col');
        setHighlightsNav('col');
        setCurriculumNav('col');
        setToolsCoveredNav('col');
        setProjectsNav('col');
        setJobRolesNav('col');
        setFeesNav('col laptop_course_nav_button');
        setFaqsNav('col');
    }

    function faqssetNav() {

        setWhatIsNav('col');
        setForWhoNav('col');
        setHighlightsNav('col');
        setCurriculumNav('col');
        setToolsCoveredNav('col');
        setProjectsNav('col');
        setJobRolesNav('col');
        setFeesNav('col');
        setFaqsNav('col laptop_course_nav_button');
    }


    const [fix, setFix] = useState(false)

    const [courseDetails, setCourseDetails] = useState({})

    const [loading, setLoading] = useState(false)

    function setFixedSidebar() {
        //console.log("SSSS : " + window.scrollY)
        setScrollLength(window.scrollY)
        if (window.scrollY >= 300) {
            setFix(true)
        } else {
            setFix(false)
        }
    }

    window.addEventListener("scroll", setFixedSidebar)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    function scrl(navRefs) {
        console.log("SCROLL TO TOOLS")
        window.scrollTo({ behavior: 'smooth', top: navRefs.current.offsetTop - 200 })
    }

    //API call when page loader
    useEffect(() => {
        trackEvent('ViewContent');
        getLoggedInUserDetails();
        getcourseDetails();


        const handleScroll = (e) => {
            if (window.scrollY >= 0 && window.scrollY <= window.innerHeight / 2) {
                whatIssetNav()
            } else if ((whatIs.current.offsetTop + 200) - window.scrollY < window.innerHeight / 2 && forWho.current.offsetTop + 200 - window.scrollY >= window.innerHeight / 2) {
                // For the about section
                forWhosetNav()
            }
            else if (forWho.current.offsetTop + 200 - window.scrollY < window.innerHeight / 2 && highlights.current.offsetTop + 200 - window.scrollY >= window.innerHeight / 2) {
                // For the about section
                highlightssetNav()
            }
            else if (highlights.current.offsetTop + 200 - window.scrollY < window.innerHeight / 2 && curriculum.current.offsetTop + 200 - window.scrollY >= window.innerHeight / 2) {
                // For the about section
                curriculumsetNav()
            }
            else if (curriculum.current.offsetTop + 200 - window.scrollY < window.innerHeight / 2 && toolsCovered.current.offsetTop + 200 - window.scrollY >= window.innerHeight / 2) {
                // For the about section
                toolsCoveredsetNav()
            }
            else if (toolsCovered.current.offsetTop + 200 - window.scrollY < window.innerHeight / 2 && projects.current.offsetTop + 200 - window.scrollY >= window.innerHeight / 2) {
                // For the about section
                projectssetNav()
            }
            else if (projects.current.offsetTop + 200 - window.scrollY < window.innerHeight / 2 && fees.current.offsetTop + 200 - window.scrollY >= window.innerHeight / 2) {
                // For the about section
                feessetNav()
            }
            else if (fees.current.offsetTop + 200 - window.scrollY < window.innerHeight / 2 && jobRoles.current.offsetTop + 200 - window.scrollY >= window.innerHeight / 2) {
                // For the about section

                jobRolessetNav()
            }
            else if (jobRoles.current.offsetTop + 200 - window.scrollY < window.innerHeight / 2 && faqs.current.offsetTop + 200 - window.scrollY >= window.innerHeight / 2) {
                // For the about section
                faqssetNav()
                setNavSet('row common_main_text_tag laptop-sidebar ssticky course_nav')
            }

            else {

            }
        }
        document.addEventListener('scroll', handleScroll);
        return () => {
            document.removeEventListener('scroll', handleScroll);
        }

    }, [])

    function getLoggedInUserDetails() {
        console.log("TRIGGERED LOGGED")
        httpsignup.get(`/skillsquadUserprofileLMS/getSkillsquadUserProfileBySignUpId?signUpId=${sessionStorage.getItem('userId')}`)
            .then((response) => {
                sessionStorage.setItem("coursePageName", response.data.userName)
                sessionStorage.setItem("coursePageEmail", response.data.userEmail)
                sessionStorage.setItem("coursePageMobile", response.data.mobileNumber)
                console.log("USER DETAILS RESPONSE : " + JSON.stringify(response.data))


            }).catch((err) => {
                console.log(err)
            })


    }

    function getcourseDetails() {
        //console.log("TRIGGERED LOGGED")
        httpsignup.get(`/usersLMS/getSkillsquadCourses?seoUrl=${params.courseTag}`)
            .then((response) => {
                console.log("RRR : " + JSON.stringify(response.data))
                setCourseDetails(response.data)

                if (response.data.skillsquadCourses.seoUrl != params.courseTag) {
                    const answer_array = params.courseTag.split('-');
                    sessionStorage.setItem("seoLocation", answer_array[answer_array.length - 1])
                    //setLocation(answer_array[answer_array.length - 1]);
                }

                if (sessionStorage.getItem("checkoutCourse") != null) {
                    handlePayment(response.data.skillsquadCourses.selfPacedDiscountedAmount)
                }
                console.log("PPPPPRRR : " + response.data.skillsquadCourses.lmsProgramId)
                if (response.data.skillsquadCourses.lmsProgramId != null) {
                    if (sessionStorage.getItem("courseProgramId") == null) {
                        sessionStorage.setItem("courseProgramId", response.data.skillsquadCourses.lmsProgramId);
                    }
                    httpsignup.get(`/programsLMS/getCourseDayCircullum?programId=${response.data.skillsquadCourses.lmsProgramId}`)
                        .then((response) => {
                            console.log('RESPONSE : ' + JSON.stringify(response.data))
                            setAllDayCirriculumList(response.data)
                        }).catch((err) => {
                            console.log(err)
                        })


                }
                setLoading(true)
            }).catch((err) => {
                console.log(err)
            })




    }


    const [Razorpay] = useRazorpay();




    const handlePayment = useCallback((payAmount) => {
        if (sessionStorage.getItem('userId') != null) {
            httpsignup.post(`/dashBoardLMS/web?amount=${payAmount}&currency=INR&auth=cnpwX2xpdmVfc2NQV296M29PUjZkSWQ6STZ3YW5QTVZVU0I5bjlxOUJ3RmtTZVhh`)
                .then((response) => {
                    const options: RazorpayOptions = {
                        key: "rzp_live_scPWoz3oOR6dId",
                        amount: parseInt(payAmount) * 100,
                        currency: "INR",
                        name: "Skillsquad",
                        description: "Test Transaction",
                        image: require("../images/skillsquad_logo_fav_png.png"),
                        order_id: response.data.orderId,
                        handler: (res) => {
                            console.log("HHHH : " + JSON.stringify(res));
                            trackEvent('Purchase', { value: payAmount, currency: 'INR' ,  course : courseDetails.programName });
                            httpsignup.post(`/usersLMS/saveCourseUser?name=${sessionStorage.getItem("coursePageName")}&email=${sessionStorage.getItem("coursePageEmail")}&phoneNumber=${sessionStorage.getItem("coursePageMobile")}&programId=${sessionStorage.getItem("courseProgramId")}&purchaseType=${sessionStorage.getItem("purchaseType")}`)
                                .then((response) => {
                                    console.log('RESPONSE : ' + JSON.stringify(response.data))

                                    navigate("/my-course")
                                }).catch((err) => {
                                    console.log(err)
                                })

                        },
                        prefill: {
                            // sessionStorage.getItem("coursePageName")
                            // sessionStorage.getItem("coursePageEmail")
                            // sessionStorage.getItem("coursePageMobile")
                            name: sessionStorage.getItem("coursePageName"),
                            email: sessionStorage.getItem("coursePageEmail"),
                            contact: sessionStorage.getItem("coursePageMobile"),
                        },
                        notes: {
                            address: "Razorpay Corporate Office",
                        },
                        theme: {
                            color: "#5627ff",
                        },
                    };

                    const rzpay = new Razorpay(options);
                    rzpay.open();

                }).catch((err) => {
                    console.log(err)
                })
            console.log(JSON.stringify(payAmount))
        } else {
            navigate("/sign-up/" + params.courseTag)
        }

    }, [Razorpay]);

    function handleLocationPage(tag, location) {
        navigate("/course-page/" + tag + "/" + location)
    }
    function myCourse() {
        if (sessionStorage.getItem('userId') != null) {
            navigate("/dashboard")
        } else {
            navigate("/course")
        }

    }

    function handleBuyNowClick() {
        // Track a custom event
        ReactGA.event({
            category: 'Button Click',
            action: 'Clicked on Buy Now',
            label: 'Product Page',
        });
        // Continue with your button's click handler
    }

    function locationSEO(tag, location) {
        navigate("/courses/" + tag + '-' + location.toLowerCase())
        sessionStorage.setItem("seoLocation", location)
        //setLocation(location)
        setLoading(false)
        window.scrollTo(0, 0)
        setLoading(true)
        //getcourseDetails();
    }

    function purchaseType(type) {
        sessionStorage.setItem("purchaseType", type)
    }

    function freetrail() {
        sessionStorage.setItem("myCourseProgramId", courseDetails.skillsquadCourses.lmsProgramId)
        navigate("/day-circullum")
    }


    return (
        <>
            {loading && <div className='mobile-course'>


                {(isDesktopOrLaptop || isBigScreen || isTabletOrMobile) && loading && <div class="mt-5 main-container">
                    <Helmet>
                        <meta property="og:url" content="https://www.skillsquad.io" />
                        <meta property="og:title" content="AWS Solution Architect Certification Training Course" />
                        <meta property="og:description" content="Comprehensive Training to architect,deploy, and manage scalable applications on Amazon Web Services" />
                        <meta property="og:type" content="website" />
                        <meta property="og:site_name" content="skillsquad.io" />
                        <meta property="og:image" content="https://res.cloudinary.com/diok04k3u/image/upload/v1717652732/Meta_tag_zrtf6k.png" />
                        <meta content="1200" property="og:image:width" />
                        <meta content="630" property="og:image:height" />
                    </Helmet>
                    <div className=" buy_now_fixed" onClick={() => { handlePayment(courseDetails.skillsquadCourses.selfPacedDiscountedAmount); gaEventTracker('email'); }}>
                        <div className="course-price"><div classname="flex-change">₹<span style={{ fontWeight: "bold", fontSize: "17px" }}>{courseDetails.skillsquadCourses.selfPacedDiscountedAmount}</span></div>
                            <p style={{ textDecoration: "line-through" }} classname="original_Amount_strike">{courseDetails.skillsquadCourses.selfPacedOrginalAmount}</p>
                        </div>
                        <div className="buy-now-text"> <span >Buy Now</span></div>
                    </div>
                    {/* <Helmet>
        <title>{courseDetails.skillsquadCourses.seoPageTitle}</title>
        <meta name="description" content="This is a description of my page" />
        </Helmet> */}

                    <div class="laptop_course_hearder">

                        <div class="laptop_course_header_text flex-change mt-4 align-items-center">
                            <img src={arrowL} width={30} alt="arrow" style={{ cursor: "pointer" }} onClick={myCourse} />
                            <div>Course</div><img src={require("../images/arrow - right.png")} />
                            <div>{courseDetails.programName}</div>
                            {/* <img src={require("../images/arrow - right.png")} />
                        <div>Web Design</div>  */}
                        </div>

                        {/* one */}
                        <div class="row mt-5 course-details-cards">
                            <div class="col laptop_course_text_color "><div class="common_main_heading"><h1 className='course-heading'>{courseDetails.programName} {sessionStorage.getItem("seoLocation") != null && <span>in {sessionStorage.getItem("seoLocation")}</span>}</h1></div>
                                <div class="mt-3 common_main_text_normal"><p className='course_subtitle'>{courseDetails.skillsquadCourses.courseSubtitle}</p></div>
                                <div class="row mt-3">
                                    {courseDetails.perksList.map((data, index) => {
                                        return (<div class="col-3  laptop_course_header_tags common_main_text_tag" style={{ marginBottom: "10px" }}><span>{data.perksName}</span></div>)
                                    })}
                                    {/* <div class="col-3 p-1 laptop_course_header_tags common_main_text_tag">1:1 Mentorship</div>
                                <div class="col-4 p-1 laptop_course_header_tags common_main_text_tag">Dedicated placement support</div>
                                <div class="col-3 p-1 laptop_course_header_tags common_main_text_tag">Faculty from MAANG</div> */}
                                </div>
                                <div class="row mt-3">
                                    <div class="common_main_text_tag rating-section-course">
                                        <img
                                            width={20}
                                            loading="lazy"
                                            alt=""
                                            src={require("../images/course_star.png")}
                                        /><span>4.5 <span style={{ textDecoration: "underline" }}>(2540 reviews)</span></span>
                                    </div>

                                    <div class="common_main_text_tag rating-section-course">Pay low as ₹ <span style={{ textDecoration: "underline" }}>{courseDetails.skillsquadCourses.selfPacedDiscountedAmount}</span> <img
                                        width={20}
                                        loading="lazy"
                                        alt=""
                                        src={require("../images/check.png")}
                                    /></div>
                                </div>
                                <div class="laptop_course_header_box mt-3">
                                    <div class="row stats-card">
                                        <div class="col">
                                            <div class="common_main_sub_highlight stats-card-heading">{courseDetails.skillsquadCourses.highestSalary}</div>
                                            <div className="stats-card-desc">Highest Salary</div>
                                        </div>
                                        <div class="col line">
                                            <div class="common_main_sub_highlight stats-card-heading">{courseDetails.skillsquadCourses.hiringPartners}</div>
                                            <div className="stats-card-desc">Hiring Partners</div>
                                        </div>
                                        <div class="col">
                                            <div class="common_main_sub_highlight stats-card-heading">{courseDetails.skillsquadCourses.averageHike}</div>
                                            <div className="stats-card-desc">Average Hike</div>
                                        </div>
                                        <div class="col line">
                                            <div class="common_main_sub_highlight stats-card-heading">{courseDetails.skillsquadCourses.students}</div>
                                            <div className="stats-card-desc">Students</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-2"></div>

                            <div class="col-3 laptop_course_text_color videoPlayer"><div>
                                <Player playsInline src={courseDetails.programDemoVideo} fluid={true} >
                                    <ControlBar autoHide={true} disableDefaultControls={false}>

                                    </ControlBar>
                                </Player>
                                {/* <video class="laptop_course_video" src="http://media.w3.org/2010/05/sintel/trailer.mp4" type='video/mp4' width="305" height="200" /> */}
                            </div></div>

                        </div>

                    </div>

                    <div class="row laptop_course_desc">
                        {/* one */}
                        <div class="col-9 course-card-remaining-details">

                            <div className={fix ? navSet : 'row common_main_text_tag course_nav align-items-center'}>
                                {/* <div>{scrollLength}</div> */}
                                {/* <div>{scrollLength}</div> */}
                                <div class={whatIsNav} style={{ cursor: "pointer" }} onClick={() => { scrl(whatIs); whatIssetNav(); }}>What is?</div>
                                <div class={forWhoNav} style={{ cursor: "pointer" }} onClick={() => { scrl(forWho); forWhosetNav(); }}>For who?</div>
                                <div class={highlightsNav} style={{ cursor: "pointer" }} onClick={() => { scrl(highlights); highlightssetNav(); }}>Highlights</div>
                                <div class={curriculumNav} style={{ cursor: "pointer" }} onClick={() => { scrl(curriculum); curriculumsetNav(); }}>Curriculum</div>
                                <div class={toolsCoveredNav} style={{ cursor: "pointer" }} onClick={() => { scrl(toolsCovered); toolsCoveredsetNav(); }}>Tools-Covered</div>
                                <div class={projectsNav} style={{ cursor: "pointer" }} onClick={() => { scrl(projects); projectssetNav(); }}>Projects</div>

                                <div class={feesNav} style={{ cursor: "pointer" }} onClick={() => { scrl(fees); feessetNav(); }}>Fees</div>
                                <div class={jobRolesNav} style={{ cursor: "pointer" }} onClick={() => { scrl(jobRoles); jobRolessetNav(); }}>Job Roles</div>
                                {/* <div class={scrollLength >= 1600 && scrollLength < 1800 ? 'col-2 laptop_course_nav_button' : 'col-2'} onClick={executeScrollSuccessStories}>Success Stories</div> */}
                                <div class={faqsNav} style={{ cursor: "pointer" }} onClick={() => { scrl(faqs); faqssetNav(); }}>FAQs</div>

                            </div>

                            <div class="mt-5" ref={whatIs}>
                                <div class="mb-4 common_main_sub_highlight common-heading">{courseDetails.skillsquadCourses.whatIsQuestion}</div>
                                <div>{courseDetails.skillsquadCourses.whatIsAnswer}</div>
                            </div>

                            <div class="laptop_course_desc_who_is mt-5" ref={forWho}>
                                <div class="mb-4 common_main_sub_highlight common-heading">{courseDetails.skillsquadCourses.forWhoQuestion}</div>
                                <div>{courseDetails.skillsquadCourses.forWhoAnswer}</div>
                            </div>
                            {courseDetails.courseIncludesList.length > 0 && <div class="mt-5">
                                <div class="mb-4 common_main_sub_highlight common-heading" ref={highlights}>Program Highlights</div>
                                <div class="row mt-3 align-items-center course-program-highlights">
                                    {courseDetails.courseIncludesList.map((data, index) => {
                                        return (<div class="col-6 p-2 main-course-highlight"><img src={data.iconUrl} alt="" /> {data.includesName}</div>)
                                    })}
                                    {/* <div class="col">160+ Hours Instructor-Led Sessions</div>
                                <div class="col">50+ Hours of live Interactive Doubt-Solving Sessions</div> */}
                                </div>

                            </div>}
                            <div class="mt-5" ref={curriculum}>
                                {allDayCirriculumList.length > 0 && <div>
                                    <div className="mb-4 common_main_sub_highlight common-heading">Curriculum</div>
                                    <div>
                                        {allDayCirriculumList.map((res) => {
                                            return (
                                                <Accordion className="accordion" title={res.day} content={res} totalTask={res.totaltasks} dayProgress={res.dayProgress} currentDayProgress={res.currentDayProgress} generic={"generic"} />

                                            )
                                        })}
                                    </div>
                                </div>}
                            </div>

                            <div class="mt-5" ref={toolsCovered}>
                                <div class="mb-4 common_main_sub_highlight common-heading">Master Latest tools and technologies</div>
                                <div class="master-tech">
                                    {courseDetails.skillsquadCourseToolsCoveredList.map((res) => {
                                        return (

                                            <div class="d-flex"><img class="admin-pro-pic my-1 me-2 " src={res.toolUrl} alt="tools" width={40} /></div>


                                        )
                                    })}
                                </div>
                            </div>

                            <div class="laptop_course_desc_who_is mt-5" style={{ backgroundColor: "#F9F9F9" }} ref={projects}>
                                <div class="mb-4 common_main_sub_highlight common-heading">Projects</div>
                                <div className="project-main-heading">By the time you graduate from our Immersive have a solid job-worthy of apps deployed on the Cloud to impress top
                                    tech recruiters with and land the job you want. Here's a peek at some cf the projects yqdll be able to build:</div>
                                <div class="laptop_faq-inside ">
                                    <div class="mt-3 list-of-projects">
                                        {courseDetails.skillsquadCourseProjectsList.map((res) => {
                                            return (
                                                // <div class="col">
                                                //     <div class="row">
                                                //         <div class="col-2"><img src={res.projectImageUrl} /></div>
                                                //         <div class="col"><div>{res.projectHeading}</div>
                                                //             {/* <div>Social Network</div> */}
                                                //         </div>
                                                //     </div>
                                                //     <div class="row">
                                                //         <div class="col">{res.projectDescription}</div>

                                                //     </div>
                                                // </div>
                                                <>
                                                    <div className="project-course-section-inside mb-3" >
                                                        <div style={{ marginBottom: "7px" }}>
                                                            <img src={res.projectImageUrl} style={{ marginRight: "8px", height: "40px", width: "40px", borderRadius: "50%", objectFit: "contain" }} />
                                                            <div className="d-flex flex-column align-items-start">
                                                                <h3 style={{ marginBottom: "3px" }}>{res.projectHeading}</h3>
                                                                {/* <p>Social Network</p> */}
                                                            </div>
                                                        </div>
                                                        <p style={{ fontSize: "16px" }}>{res.projectDescription}</p>
                                                    </div>



                                                </>

                                            )
                                        })}



                                    </div>

                                </div>
                            </div>

                            {/* pricing cards */}
                            <div class="laptop_course_desc_who_is mt-5" ref={fees}>
                                <div class="mb-4 common_main_sub_highlight common-heading">Tuition Fees</div>
                                <div class="row price-card-course" >
                                    <div class="laptop_course_fees" style={{ position: "relative" }}>
                                        <div class="row">
                                            <div class="col"></div>
                                            <div class="col laptop_course_bestseller">Best Seller</div>
                                            <div class="col"></div>
                                        </div>
                                        <div style={{ padding: "5%" }} >
                                            <div className="text-center"> <div class="common_main_heading">Instructor-Led Training</div>
                                                <div class="common_main_text_highlight mt-2">Learn in Expert-Led Sessions</div>
                                                <hr style={{ border: "1px dashed black" }}></hr>
                                            </div>
                                            <div>
                                                <div class="common_main_text_highlight">Solid Experiential Learning</div>
                                                <div class="mt-2"><img src={require("../images/password_check1.png")} alt="" width={20} /> Access to selected free courses.</div>
                                                <div class="mt-2"><img src={require("../images/password_check1.png")} alt="" width={20} /> Course materials and resources.</div>
                                                <div class="mt-2"><img src={require("../images/password_check1.png")} alt="" width={20} /> Community support.</div>
                                                <div class="mt-2"><img src={require("../images/password_check1.png")} alt="" width={20} /> Certification upon completion.</div>
                                                <div class="mt-2"><img src={require("../images/password_check1.png")} alt="" width={20} /> Interview Preparation</div>
                                                <div class="mt-2"><img src={require("../images/password_check1.png")} alt="" width={20} /> Daywise Calender</div>
                                                <div class="mt-2"><img src={require("../images/password_check1.png")} alt="" width={20} /> Cheat Sheets</div>
                                                <div class="mt-2"><img src={require("../images/password_check1.png")} alt="" width={20} /> Hands-on Exercise</div>
                                                <div class="mt-2"><img src={require("../images/password_check1.png")} alt="" width={20} /> Rea-world Projects</div>
                                                <div class="mt-2"><img src={require("../images/password_check1.png")} alt="" width={20} /> Dedicated Placement Assistance</div>
                                                <div class="mt-2"><img src={require("../images/password_check1.png")} alt="" width={20} /> Weekly-Live Classes</div>
                                            </div>
                                            <hr style={{ border: "1px dashed black" }}></hr>
                                            {/* <div className='d-flex align-items-start justify-content-center  flex-column' style={{marginBottom:"10px",paddding:'0px',height:"70px"}}> */}
                                            {/* <div class="common_main_text_highlight mt-3">Upcoming Batches</div>
                                            <div class="flex-change common_main_text_tag_highlight mt-2">
                                                <div > {moment(courseDetails.skillsquadCourses.batchStartDate).format("DD MMM")} : Weekday Batch</div>
                                                <div style={{ margin: "0px 5px 0px 5px" }}>|</div>
                                                <div >{moment(courseDetails.skillsquadCourses.cohartStart).format("DD MMM")} : Weekday Batch</div>
                                            </div> */}
                                            {/* </div> */}
                                        </div>

                                        {/* <div class="laptop_course_fees_footer"style={{position:"absolute",bottom:0,width:"100%"}} >
                                        <div style={{ color: "#349669" }}>{courseDetails.percenatgeOff}% OFF</div>
                                        <div class="row">
                                            <div class="col-2 common_main_heading">₹{courseDetails.skillsquadCourses.discountedAmount}</div>
                                            <div class="col common_main_text_tag linestrike">₹{courseDetails.skillsquadCourses.originalAmount}</div>
                                            <div class="laptop_course_fees_enroll" style={{ cursor: "pointer"}} onClick={() => handlePayment(courseDetails.skillsquadCourses.discountedAmount)}>Enroll Now</div>
                                        </div>
                                        <div style={{ color: "#349669" }}>As low as ₹ {courseDetails.instructorEmi}/month</div>
                                    </div> */}
                                        <div className="laptop_course_fees_footer" style={{ position: "absolute", bottom: 0, width: "100%" }}>
                                            <div className="d-flex flex-column align-items-start">
                                                <div style={{ color: "#349669" }}>{courseDetails.percenatgeOff}% OFF</div>
                                                <div className="footer_course_pricing">
                                                    <div class=" common_main_heading">₹{courseDetails.skillsquadCourses.discountedAmount}</div>
                                                    <div class=" common_main_text_tag linestrike">₹{courseDetails.skillsquadCourses.originalAmount}</div>

                                                </div>
                                                <div style={{ color: "#349669" }}>As low as ₹ {courseDetails.selfPacedEmi}/month</div>
                                            </div>
                                            <div class=" laptop_course_fees_enroll" style={{ cursor: "pointer" }} onClick={() => { handlePayment(courseDetails.skillsquadCourses.selfPacedDiscountedAmount); purchaseType(1) }}>Enroll Now</div>

                                        </div>
                                    </div>


                                    <div class=" laptop_course_fees" style={{ borderColor: "transparent", position: "relative" }}>

                                        <div style={{ padding: "5%" }} class="mt-4">
                                            <div className="text-center second-fees-card"> <div class="common_main_sub_highlight text-center">Self Paced Training</div>
                                                <div>Learn at your own pace</div>
                                                <hr style={{ border: "1px dashed black" }}></hr>
                                            </div>

                                            <div>
                                                <div class="common_main_text_highlight" >Solid Experiential Learning</div>
                                                <div class="mt-2"><img src={require("../images/password_check1.png")} alt="" width={20} /> Interview Preparation</div>
                                                <div class="mt-2"><img src={require("../images/password_check1.png")} alt="" width={20} /> Daywisse Calender</div>
                                                <div class="mt-2"><img src={require("../images/password_check1.png")} alt="" width={20} /> Cheat Sheets</div>
                                                <div class="mt-2"><img src={require("../images/password_check1.png")} alt="" width={20} /> Hands-On Excercise</div>
                                                <div class="mt-2"><img src={require("../images/password_check1.png")} alt="" width={20} /> Real-world Objects</div>
                                                <div class="mt-2"><img src={require("../images/password_check1.png")} alt="" width={20} /> Course materials and resources</div>
                                                <div class="mt-2"><img src={require("../images/password_check1.png")} alt="" width={20} /> Community support</div>
                                                <div class="mt-2"><img src={require("../images/password_check1.png")} alt="" width={20} /> Certification upon Completion</div>
                                                <div class="mt-2" style={{ paddingBottom: "55px" }}><img src={require("../images/wrong_icon.png")} alt="" width={20} /> Weekly-Live Classes</div>


                                            </div>


                                            <hr style={{ border: "1px dashed black" }}></hr>
                                            {/* <div className='d-flex align-items-start justify-content-center  flex-column' style={{marginBottom:"10px",paddding:'0px',height:"70px"}}> */}
                                            {/* <div class="common_main_text_highlight mt-3">Upcoming Batches</div>
                                            <div class="flex-change common_main_text_tag_highlight mt-2">
                                                <div > {moment(courseDetails.skillsquadCourses.batchStartDate).format("DD MMM")} : Weekday Batch</div>
                                                <div style={{ margin: "0px 5px 0px 5px" }}>|</div>
                                                <div >{moment(courseDetails.skillsquadCourses.cohartStart).format("DD MMM")} : Weekday Batch</div>
                                            </div> */}
                                            {/* </div> */}
                                        </div>
                                        <br></br>
                                        <br></br>
                                        <br></br>

                                        {/* <div class="laptop_course_fees_footer " style={{position:"absolute",bottom:0,width:"100%"}}>
                                        <div style={{ color: "#349669" }}>{courseDetails.selfPacedPercentageOff}% OFF</div>
                                        <div class="row d-flex justify-content-between">
                                            <div class="col-2 common_main_heading">₹{courseDetails.skillsquadCourses.selfPacedDiscountedAmount}</div>
                                            <div class="col common_main_text_tag linestrike" >₹{courseDetails.skillsquadCourses.selfPacedOrginalAmount}</div>
                                            <div class=" laptop_course_fees_enroll" style={{ cursor: "pointer" }} onClick={() => handlePayment(courseDetails.skillsquadCourses.selfPacedDiscountedAmount)}>Enroll Now</div>
                                        </div>
                                        <div style={{ color: "#349669" }}>As low as ₹ {courseDetails.selfPacedEmi}/month</div>
                                    </div> */}
                                        <div className="laptop_course_fees_footer" style={{ position: "absolute", bottom: 0, width: "100%" }}>
                                            <div className="d-flex flex-column align-items-start">
                                                <div style={{ color: "#349669" }}>{courseDetails.selfPacedPercentageOff}% OFF</div>
                                                <div className="footer_course_pricing">
                                                    <div class="common_main_heading">₹{courseDetails.skillsquadCourses.selfPacedDiscountedAmount}</div>
                                                    <div class=" common_main_text_tag linestrike" >₹{courseDetails.skillsquadCourses.selfPacedOrginalAmount}</div>

                                                </div>
                                                <div style={{ color: "#349669" }}>As low as ₹ {courseDetails.selfPacedEmi}/month</div>
                                            </div>
                                            <div class=" laptop_course_fees_enroll" style={{ cursor: "pointer" }} onClick={() => { handlePayment(courseDetails.skillsquadCourses.selfPacedDiscountedAmount); purchaseType(1) }}>Enroll Now</div>

                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div class="mt-5 apply-job-roles" ref={jobRoles}>
                                <div class="mb-4 common_main_sub_highlight">Apply for these roles after completing the course</div>
                                <div class="align-items-start applying_job_roles">
                                    <div class="">
                                        <img

                                            loading="lazy"
                                            alt=""
                                            src={require("../images/rectangle-22573@2x.png")}
                                        />
                                    </div>
                                    <div class="d-flex flex-column">
                                        {courseDetails.rolesList.map((data, index) => {
                                            return (<div class=" mt-2"> <div class=""><img
                                                width={40}
                                                loading="lazy"
                                                alt=""
                                                src={require("../images/basilbagsolid.png")}
                                            /> {data.roleName}</div>
                                            </div>)
                                        })}


                                    </div>
                                </div>
                            </div>

                            <div class="laptop_course_desc_who_is mt-5" style={{ backgroundColor: "#F9F9F9" }} ref={faqs}>
                                <div class="mb-4 common_main_sub_highlight common-heading">FAQs</div>
                                <div class="laptop_faq-inside"><div>
                                    {courseDetails.skillsquadCourseFaqsList.map((res, index) => {
                                        return (
                                            <FAQAccordion num={index} title={res.question} content={res} />

                                        )
                                    })}
                                </div></div>
                            </div>

                            <div class="mt-5">
                                <div class="mb-4 common_main_sub_highlight">Find {courseDetails.programName} in other top cities</div>
                                <div class="row">
                                    {courseDetails.cityList.map((data, index) => {
                                        return (<div class="col-2 laptop_course_locations" onClick={() => { locationSEO(courseDetails.skillsquadCourses.seoUrl, data.cityName) }}>{data.cityName}{courseDetails.seoUrl}</div>)
                                    })}

                                </div>
                            </div>

                        </div>

                        {/* two */}

                        <div class="col buy-now-course-details course-card-placed-details ">
                            {(isDesktopOrLaptop || isBigScreen) && <div className={fix ? 'laptop-sidebarc sfixed' : 'laptop-sidebar'}>

                                <div class="laptop_buynow bg-white">
                                    <div class="row ">
                                        <div class="col-2 common_main_sub_highlight">₹{courseDetails.skillsquadCourses.selfPacedDiscountedAmount}</div>
                                        <div class="col common_main_text_tag linestrike">₹{courseDetails.skillsquadCourses.selfPacedOrginalAmount}</div>
                                        <div class="col-4 laptop_price_off common_main_text_tag_highlight">{courseDetails.selfPacedPercentageOff}% OFF</div>
                                    </div>

                                    {/* course card details two */}

                                    <div class="row align-items-center">
                                        <div class="col common_main_text_tag" style={{ color: "#E34444" }}><img
                                            width={15}
                                            loading="lazy"
                                            alt=""
                                            src={require("../images/alarm.png")}
                                        /> {moment(courseDetails.skillsquadCourses.deadlineTimer).fromNow(true)} left at this price!</div>
                                    </div>
                                    <hr style={{ height: 0, border: "none", borderTop: "1px solid black" }}></hr>
                                    <div class="row mt-2">
                                        <div class="col common_main_text_tag"><img
                                            width={20}
                                            loading="lazy"
                                            alt=""
                                            src={require("../images/clock.png")}
                                        /> Course Duration</div>
                                        <div class="col common_main_text_tag" style={{ textAlign: "right" }}>{courseDetails.duration}</div>
                                    </div>
                                    <div class="row mt-2">
                                        <div class="col-5 common_main_text_tag"><img
                                            width={20}
                                            loading="lazy"
                                            alt=""
                                            src={require("../images/barchart.png")}
                                        /> Course Level</div>
                                        <div class="col-7 common_main_text_tag" style={{ textAlign: "right" }}>{courseDetails.levelName}</div>
                                    </div>
                                    <div class="row mt-2">
                                        <div class="col common_main_text_tag"><img
                                            width={20}
                                            loading="lazy"
                                            alt=""
                                            src={require("../images/users.png")}
                                        /> Students Enrolled</div>
                                        <div class="col common_main_text_tag" style={{ textAlign: "right" }}>{courseDetails.skillsquadCourses.studentsEnrolled}</div>
                                    </div>
                                    <div class="row mt-2">
                                        <div class="col common_main_text_tag"><img
                                            width={20}
                                            loading="lazy"
                                            alt=""
                                            src={require("../images/notebook.png")}
                                        /> Language</div>
                                        <div class="col common_main_text_tag" style={{ textAlign: "right" }}>{courseDetails.languageName}</div>
                                    </div>
                                    {/* <div class="row mt-2">
                                    <div class="col common_main_text_tag"><img
                                        width={20}
                                        loading="lazy"
                                        alt=""
                                        src={require("../images/notepad.png")}
                                    /> Subtitle Language</div>
                                    <div class="col common_main_text_tag" style={{ textAlign: "right" }}>{courseDetails.subtitleLanguageName}</div>
                                </div> */}
                                    <hr style={{ height: 0, border: "none", borderTop: "1px solid black" }}></hr>
                                    <div className="common_main_button buy_now_fixed" onClick={() => { handlePayment(courseDetails.skillsquadCourses.selfPacedDiscountedAmount); gaEventTracker('email'); purchaseType(2) }}>Buy Now</div>
                                    <div className="common_main_button mt-2" style={{ backgroundColor: "#EBE5FF", color: '#5627FF' }} onClick={freetrail}>Start Free Trial</div>
                                    {/* <div class="row">
                            <div class="col laptop-wishlist">Add to Wishlist</div>
                            <div class="col laptop-wishlist">Gift Course</div>
                        </div> */}
                                    {/* <div class="row">
                                    <div class="col mt-2">
                                        <span class="common_main_text_tag_highlight">Note: </span><span class="common_main_text_tag">all course have 30-days money-back guarantee</span>
                                    </div>
                                </div> */}


                                    <hr style={{ height: 0, border: "none", borderTop: "1px solid black" }}></hr>


                                    {/* <div class="row">
                                        <div class="col common_main_text_tag_highlight">
                                            Upcoming Batches/Cohort
                                        </div>

                                    </div>
                                    <div class=" common_main_text_tag mt-2 batch-updates">
                                        <div > {moment(courseDetails.skillsquadCourses.batchStartDate).format("DD MMM")} : Weekday Batch</div>
                                        <div style={{ margin: "0px 3px 0px 3px" }}>|</div>
                                        <div >{moment(courseDetails.skillsquadCourses.cohartStart).format("DD MMM")} : Weekday Batch</div>
                                    </div> */}

                                    <hr style={{ height: 0, border: "none", borderTop: "1px solid black" }}></hr>

                                    <div class="row">
                                        <div class="col common_main_text_tag_highlight">
                                            Share this course:
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-4 laptop_price_icons common_main_text_tag" style={{ cursor: "pointer" }} onClick={copy}>

                                            <img
                                                width={15}
                                                loading="lazy"
                                                alt=""
                                                src={require("../images/copy.png")}
                                            />
                                            {!copied ? "Copy Link" : "Copied!"}
                                        </div>
                                        <div class="col laptop_price_icons">
                                            <img src={require("../images/facebook.png")} width={15} alt="" />
                                        </div>
                                        <div class="col laptop_price_icons">
                                            <img src={require("../images/twitter-1.png")} width={15} alt="" />
                                        </div>
                                        <div class="col laptop_price_icons">
                                            <img src={require("../images/envelope.png")} width={15} alt="" />
                                        </div>
                                        <div class="col laptop_price_icons">
                                            <img src={require("../images/whatsapp course.png")} width={15} alt="" />
                                        </div>
                                    </div>

                                    {courseDetails.skillsquadCourses.syllabusUrl != null && <div class="laptop_download mt-3" onClick={() => { window.open(courseDetails.skillsquadCourses.syllabusUrl) }}><img
                                        width={20}
                                        loading="lazy"
                                        alt=""
                                        src={require("../images/download.png")}
                                    /> Download Syllabus for Complete Details</div>}
                                </div>



                            </div>}
                            {isTabletOrMobile && <div className='laptop-sidebarc sfixed'>

                                <div class="laptop_buynow bg-white">
                                    <div class="row ">
                                        <div class="col-2 common_main_sub_highlight">₹{courseDetails.skillsquadCourses.selfPacedDiscountedAmount}</div>
                                        <div class="col common_main_text_tag linestrike">₹{courseDetails.skillsquadCourses.selfPacedOrginalAmount}</div>
                                        <div class="col-4 laptop_price_off common_main_text_tag_highlight">{courseDetails.selfPacedPercentageOff}% OFF</div>
                                    </div>

                                    {/* course card details two */}

                                    <div class="row align-items-center">
                                        <div class="col common_main_text_tag" style={{ color: "#E34444" }}><img
                                            width={15}
                                            loading="lazy"
                                            alt=""
                                            src={require("../images/alarm.png")}
                                        /> {moment(courseDetails.skillsquadCourses.deadlineTimer).fromNow(true)} left at this price!</div>
                                    </div>
                                    <hr style={{ height: 0, border: "none", borderTop: "1px solid black" }}></hr>
                                    <div class="row mt-2">
                                        <div class="col common_main_text_tag"><img
                                            width={20}
                                            loading="lazy"
                                            alt=""
                                            src={require("../images/clock.png")}
                                        /> Course Duration</div>
                                        <div class="col common_main_text_tag" style={{ textAlign: "right" }}>{courseDetails.duration}</div>
                                    </div>
                                    <div class="row mt-2">
                                        <div class="col-5 common_main_text_tag"><img
                                            width={20}
                                            loading="lazy"
                                            alt=""
                                            src={require("../images/barchart.png")}
                                        /> Course Level</div>
                                        <div class="col-7 common_main_text_tag" style={{ textAlign: "right" }}>{courseDetails.levelName}</div>
                                    </div>
                                    <div class="row mt-2">
                                        <div class="col common_main_text_tag"><img
                                            width={20}
                                            loading="lazy"
                                            alt=""
                                            src={require("../images/users.png")}
                                        /> Students Enrolled</div>
                                        <div class="col common_main_text_tag" style={{ textAlign: "right" }}>{courseDetails.skillsquadCourses.studentsEnrolled}</div>
                                    </div>
                                    <div class="row mt-2">
                                        <div class="col common_main_text_tag"><img
                                            width={20}
                                            loading="lazy"
                                            alt=""
                                            src={require("../images/notebook.png")}
                                        /> Language</div>
                                        <div class="col common_main_text_tag" style={{ textAlign: "right" }}>{courseDetails.languageName}</div>
                                    </div>
                                    <div class="row mt-2">
                                        <div class="col common_main_text_tag"><img
                                            width={20}
                                            loading="lazy"
                                            alt=""
                                            src={require("../images/notepad.png")}
                                        /> Subtitle Language</div>
                                        <div class="col common_main_text_tag" style={{ textAlign: "right" }}>{courseDetails.subtitleLanguageName}</div>
                                    </div>
                                    <hr style={{ height: 0, border: "none", borderTop: "1px solid black" }}></hr>
                                    <div class="common_main_button" onClick={() => { handlePayment(courseDetails.skillsquadCourses.selfPacedDiscountedAmount); purchaseType(1) }}>Buy Now</div>
                                    <div class="common_main_button mt-2" style={{ backgroundColor: "#EBE5FF", color: '#5627FF' }} onClick={freetrail}>Start Free Trial</div>
                                    {/* <div class="row">
                            <div class="col laptop-wishlist">Add to Wishlist</div>
                            <div class="col laptop-wishlist">Gift Course</div>
                        </div> */}
                                    <div class="row">
                                        <div class="col mt-2">
                                            <span class="common_main_text_tag_highlight">Note: </span><span class="common_main_text_tag">all course have 30-days money-back guarantee</span>
                                        </div>
                                    </div>


                                    <hr style={{ height: 0, border: "none", borderTop: "1px solid black" }}></hr>


                                    {/* <div class="row">
                                        <div class="col common_main_text_tag_highlight">
                                            Upcoming Batches/Cohort
                                        </div>

                                    </div>
                                    <div class="flex-change common_main_text_tag mt-2">
                                        <div > {moment(courseDetails.skillsquadCourses.batchStartDate).format("DD MMM")} : Weekday Batch</div>
                                        <div style={{ margin: "0px 3px 0px 3px" }}>|</div>
                                        <div >{moment(courseDetails.skillsquadCourses.cohartStart).format("DD MMM")} : Weekday Batch</div>
                                    </div> */}

                                    <hr style={{ height: 0, border: "none", borderTop: "1px solid black" }}></hr>

                                    <div class="row">
                                        <div class="col common_main_text_tag_highlight">
                                            Share this course:
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-4 laptop_price_icons common_main_text_tag" style={{ cursor: "pointer" }} onClick={copy}>

                                            <img
                                                width={15}
                                                loading="lazy"
                                                alt=""
                                                src={require("../images/copy.png")}
                                            />
                                            {!copied ? "Copy Link" : "Copied!"}
                                        </div>
                                        <div class="col laptop_price_icons">
                                            <img src={require("../images/facebook.png")} width={15} alt="" />
                                        </div>
                                        <div class="col laptop_price_icons">
                                            <img src={require("../images/twitter-1.png")} width={15} alt="" />
                                        </div>
                                        <div class="col laptop_price_icons">
                                            <img src={require("../images/envelope.png")} width={15} alt="" />
                                        </div>
                                        <div class="col laptop_price_icons">
                                            <img src={require("../images/whatsapp course.png")} width={15} alt="" />
                                        </div>
                                    </div>

                                    {courseDetails.skillsquadCourses.syllabusUrl != null && <div class="laptop_download mt-3" onClick={() => { window.open(courseDetails.skillsquadCourses.syllabusUrl) }}><img
                                        width={20}
                                        loading="lazy"
                                        alt=""
                                        src={require("../images/download.png")}
                                    /> Download Syllabus for Complete Details</div>}
                                </div>



                            </div>}

                        </div>
                    </div>

                    {/* onlytext */}



                    {/* onlybuttons */}




                </div>

                }
                {(isDesktopOrLaptop || isBigScreen) && <div className="Trusted-heading mt-4">
                    <h1 >
                        The Next Success Story is Going to be Your's..
                    </h1>
                </div>}
                {(isTabletOrMobile && <div className=" Trusted-heading mt-4">
                    <h1 >
                        The Next Success Story is Going to be Your's..
                    </h1>
                </div>)}
                <Marquee className='marquee-type'>
                    {/* <div className="row" style={{ margin: "0 10px" }}> */}
                    <div
                        className="main-card mb-2 mx-4 marq-cards"
                        style={{
                            padding: '15px',
                            borderRadius: '5px',
                            backgroundColor: '#EFF1F0'
                        }}
                    >
                        <div className="content-card d-flex flex-column">
                            <div className="align-items-center mb-3 p-3 content-card-deatails">
                                <img style={{ height: "60px", width: "60px", borderRadius: "15px" }} src={require("../images/Pandu_Babugonda.webp")} alt="" />
                                <div
                                    className="d-flex flex-column align-items-start"
                                    style={{ marginLeft: "7px" }}
                                >
                                    <h3 style={{ marginBottom: "5px" }}>Pandu babugonda</h3>
                                    <p>Tableau Engineer</p>
                                </div>
                                <div className="ms-4">
                                    <img src={Commas} alt="" style={{ float: "right" }} />
                                </div>
                            </div>
                            <p style={{ fontSize: "19px", padding: "20px" }}>
                                "It was a wonderful learning experience with Skillsquad. I had enrolled in tableau course. The trainer is highly qualified, the way of teaching is good,
                                and he made sure we understood"
                            </p>
                        </div>
                    </div>

                    <div
                        className="main-card mb-2 mx-4"
                        style={{
                            padding: "15px",
                            borderRadius: "5px",
                            backgroundColor: "#EFF1F0",
                            borderRadius: "10px",
                        }}
                    >
                        <div className="content-card d-flex flex-column content-card-deatails">
                            <div className="align-items-center mb-3 p-3">
                                <img style={{ height: "60px", width: "60px", borderRadius: "15px" }} src={require('../images/Pavan_sai.webp')} alt="" />
                                <div
                                    className="d-flex flex-column align-items-start"
                                    style={{ marginLeft: "7px" }}
                                >
                                    <h3 style={{ marginBottom: "5px" }}>Pavan Sai</h3>
                                    <p>Java Full stack</p>
                                </div>
                                <div className="ms-4">
                                    <img src={Commas} alt="" style={{ float: "right" }} />
                                </div>
                            </div>
                            <p style={{ fontSize: "19px", padding: "20px" }}>
                                "Each and every concept clearly. the hands-on sections and Projects, assignments helped me learn things practically.
                                I really appreciate the Support team for resolving all our queries very quickly."
                            </p>
                        </div>
                    </div>

                    <div
                        className="main-card mb-2 mx-4"
                        style={{
                            padding: "15px",
                            borderRadius: "5px",
                            backgroundColor: "#EFF1F0",
                            borderRadius: "10px",
                        }}
                    >
                        <div className="content-card  flex-column content-card-deatails">
                            <div className="align-items-center mb-3 p-3">
                                <img style={{ height: "60px", width: "60px", borderRadius: "15px" }} src={require('../images/Randeep_Singh.webp')} alt="" />
                                <div
                                    className="d-flex flex-column align-items-start"
                                    style={{ marginLeft: "7px" }}
                                >
                                    <h3 style={{ marginBottom: "5px" }}>Randeep Singh</h3>
                                    <p>AWS Architech</p>
                                </div>
                                <div className="ms-4">
                                    <img src={Commas} alt="" style={{ float: "right" }} />
                                </div>
                            </div>
                            <p style={{ fontSize: "19px", padding: "20px" }}>
                                "Joining Skillsquad academy was a great decision I made. I was able to learn AWS solutions Architect in details. The instructor was very knowledgeable in e
                                xplaining course modules."
                            </p>
                        </div>
                    </div>
                    <div
                        className="main-card mb-2 mx-3"
                        style={{
                            padding: "15px",
                            borderRadius: "5px",
                            backgroundColor: "#EFF1F0",
                            borderRadius: "10px",
                        }}
                    >
                        <div className="content-card flex-column content-card-deatails">
                            <div className="align-items-center mb-3 p-3">
                                <img style={{ height: "60px", width: "60px", borderRadius: "15px" }} src={require("../images/Rohith Skillsquad.png")} alt="" />
                                <div
                                    className="d-flex flex-column align-items-start"
                                    style={{ marginLeft: "7px" }}
                                >
                                    <h3 style={{ marginBottom: "5px" }}>Rohith</h3>
                                    <p>Devops Engineer</p>
                                </div>
                                <div className="ms-4">
                                    <img src={Commas} alt="" style={{ float: "right" }} />
                                </div>
                            </div>
                            <p style={{ fontSize: "19px", padding: "20px" }}>
                                It was truly great experience learning with Skillsquad.
                                Material provided by team Skills Squad is really helpful in understanding the concepts and post completion of sessions it helps as confidence booster for learner.
                                "
                            </p>
                        </div>
                    </div>
                    {/* </div> */}
                </Marquee>
                <NewFooter />
            </div>}
        </>
    )
};

export default CoursePage;