import React from "react";
import Sidebar from "./Sidebar";
import "../fonts/Roboto/Roboto-Black.ttf";
import "../styles/LiveClassses.css"
import moment from "moment/moment";
import greyC from "../images/grey_clock_icon.png"
import greyL from "../images/grey_location_icon.png"
import greyCourse from "../images/course_icon.png"
import redI from "../images/red_info_icon.png"
import httpsignup from '../js/http-lms_userprofile';
import { useState, useEffect } from "react";
import Spinner from 'react-bootstrap/Spinner';
import RightSidebar from "./RightSidebar";

const LiveClasses = () => {
   

    const [programModulesList, setProgramModulesList] = useState([])
    const [userDetails, setUserDetails] = useState([])
    const [pId, setPId] = useState([])
    const [meetingStatus, setMeetingStatus] = useState('Scheduled')
    const [cId, setCId] = useState(0)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        getLoggedInUserDetails()
        ProgramDetailsList(0, meetingStatus)

    }, [])


    function setStatus(status) {
        setMeetingStatus(status);
    }
    function ProgramDetailsList(id, status) {
        setLoading(true)
        setCId(id)
        httpsignup.get('/lmsVideoRecordingManager/getLiveClassByBatchIds?batchId=' + id + '&profileId=' + sessionStorage.getItem("liveProfileId") + '&meetingStatus=' + status)
            .then((response) => {
                console.log("program details : ", JSON.stringify(response.data))

                if (response.data && response.data.length > 0) {
                    setProgramModulesList(response.data)
                    setLoading(false)
                } else {
                    setLoading(false)
                    setProgramModulesList([])
                }
            }).catch((e) => {
                console.log(e.response, "not successful")
                setLoading(false)

            })
    }

    const getLoggedInUserDetails = async () => {
        httpsignup.get(`/skillsquadUserprofileLMS/getSkillsquadUserProfileBySignUpId?signUpId=${sessionStorage.getItem("userId")}`)
            .then((response) => {
                setPId(response.data.lmsProfileId)
                sessionStorage.setItem("liveProfileId", response.data.lmsProfileId)
                httpsignup.get(`/lmsVideoRecordingManager/getLiveClassByBatchId?profileId=${response.data.lmsProfileId}`)
                    .then((response) => {
                        setUserDetails(response.data)
                        console.log("BY Batch : " + JSON.stringify(response.data))

                    }).catch((err) => {
                        console.log(err)
                    })

            }).catch((err) => {
                console.log(err)
            })
    }

    function openUrl(url){
window.open(url,"_blank")
    }

    return (
        <>
            <Sidebar />

            <div className="jobs-dashboard-container mx-4">

                {/* <Navb /> */}

                <div className="px-md-5 mt160">

                    <section class="section">
                        <div class="container">

                            <div class="row p-4">

                                <div class="col-lg-9">
                                    <div class="row">
                                        <div class="">
                                            <div class="section-title  mb-4 pb-2 ">
                                                <div className="col-lg-12">
                                                    <div className="row">

                                                        <div className="d-flex flex-column bg-white liveclasses-boxshadow liveclasses-top-section">

                                                            <div class="liveclasses-top-section-date mb-2 common_main_heading">{moment(new Date()).format("dddd, MMM Do")}</div>
                                                            <div class="liveclasses-top-section-heading mb-3 common_main_heading">My Classroom</div>
                                                            <div class='liveclass common_main_text_highlight'>
                                                                <div class="left"><div><ul class="nav nav-tab ">
                                                                    <li class="nav-item" style={{borderColor:"transparent"}}>
                                                                        <a class="nav-link active liveclass-tab" href="#t1" data-bs-toggle="tab" onClick={() => { setStatus('Scheduled') }}>Scheduled</a>
                                                                    </li>
                                                                    <li class="nav-item">
                                                                        <a class="nav-link liveclass-tab" href="#t2" data-bs-toggle="tab" onClick={() => { setStatus('Completed') }}>Completed</a>
                                                                    </li>
                                                                    <li class="nav-item">
                                                                        <a class="nav-link liveclass-tab" href="#t2" data-bs-toggle="tab" onClick={() => { setStatus('Cancelled') }}>Cancelled</a>
                                                                    </li>
                                                                </ul></div></div>
                                                                <div class="right"><div class="common_main_text_highlight">{moment(new Date()).format("MMMM YYYY")}
                                                                    {/* <i class="arrow down"></i> */}
                                                                </div></div>
                                                            </div>



                                                        </div>
                                                        <div class="d-flex">
                                                            {userDetails.map((data, index) => {
                                                                return (<div class={cId === data.batchId ? "mt-3 liveActive liveBatch common_main_text_highlight common_main_button_edit_nocircle" : ' mt-3 liveBatch common_main_text_highlight common_main_button_edit_nocircle'} onClick={() => { ProgramDetailsList(data.batchId, meetingStatus) }}>{data.courseName}</div>)
                                                            })}
                                                        </div>
                                                        {loading ? <div className="d-flex py-5 justify-content-center w-100"><Spinner animation="border" variant="lightsecondary" /> </div> :
                                                        (programModulesList.length > 0 ) ? programModulesList.map((data, index) => {
                                                            return (<div class="mt-3"><div class="common_main_heading mb-3">{moment(data.skillsquadLiveClass.meetingDate).format('MMM')}</div>
                                                                <div class="liveclass-monthschedule">
                                                                    <div class="container">


                                                                        <div class="row">
                                                                            <div class="col col-lg-2 align-items-center" style={{ borderRight: "2px solid black" }}>
                                                                                <div class="liveclass-date">
                                                                                    
                                                                                    <div class="mb-3 mt-4">{moment(data.skillsquadLiveClass.meetingDate).format('ddd')}</div>
                                                                                    <div>{moment(data.skillsquadLiveClass.meetingDate).format('DD')}</div>
                                                                                </div>
                                                                            </div>

                                                                            <div class="col-md-auto">
                                                                                <div class="d-flex mt-4 liveclass-topicname"><img src={greyC} alt="clock" width={20} /> <div style={{ marginLeft: 3 }}>{data.skillsquadLiveClass.meetingTime}</div>
                                                                                    <div style={{ marginLeft: 20 }}>{data.skillsquadLiveClass.meetingTopic}</div>

                                                                                </div>
                                                                                <div class="d-flex mt-4 liveclass-topicname"><img src={greyL} alt="clock" width={20} /> <div>{data.skillsquadLiveClass.meetingType}</div>

                                                                                </div>
                                                                                <div class="d-flex mt-4 liveclass-topicname"><img src={greyCourse} alt="clock" width={20} /> <div style={{ marginLeft: 3 }}>{data.courseName}</div>

                                                                                </div>
                                                                            </div>
                                                                            <div class="col">
                                                                                {data.skillsquadLiveClass.meetingStatus === 'Scheduled' && <div>
                                                                                    <div class="common_main_button mt-5" style={{cursor:"pointer"}} onClick={() => {openUrl(data.skillsquadLiveClass.meetingUrl)}}>Join Meeting</div>
                                                                                    <div class="d-flex align-items-center mt-1"><div><img src={redI} alt="info" width={25} /></div><div class="liveclass-info">The meeting link will be updated before 30 minutes of the meeting</div></div>
                                                                                </div>}
                                                                                {data.skillsquadLiveClass.meetingStatus === 'Completed' && <div>
                                                                                    <div class="liveclass-joinmeeting mt-5" style={{ backgroundColor: "grey" }}>Completed</div>

                                                                                </div>}
                                                                                {data.skillsquadLiveClass.meetingStatus === 'Cancelled' && <div>
                                                                                    <div class="liveclass-joinmeeting mt-5" style={{ backgroundColor: "red" }}>Cancelled</div>

                                                                                </div>}

                                                                            </div>
                                                                        </div>
                                                                    </div>


                                                                </div>
                                                            </div>)
                                                        }) : <div class="d-flex align-items-center justify-content-center mt-5" >

                                                            <div class="liveclasses-schedule">No Live session scheduled</div>




                                                        </div>
                                                        }
                                                        {/* <div class="d-flex align-items-center justify-content-center mt-5" >
                                                            
                                                            <div class="liveclasses-schedule">No Live session scheduled</div>




                                                        </div> */}


                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>


                                </div>

                                {/*Filter section*/}
                            </div>

                        </div>

                    </section>



                </div>


            </div>
            <RightSidebar />

        </>
    );
};

export default LiveClasses;
