import React from 'react'
import { useMediaQuery } from 'react-responsive'
import '../styles/CoursePage.css'
import Accordion from "./Accordion";
import FAQAccordion from "./FAQAccordion";
import httpsignup from '../js/http-lms_userprofile';
import { useState, useEffect, useRef } from "react";
import moment from "moment/moment";
import { Player, ControlBar, PlaybackRateMenuButton, ForwardControl, ReplayControl } from 'video-react';
import useRazorpay from "react-razorpay";
import { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { NewFooter } from '../components/Footer/NewFooter';
import { Buffer } from "buffer";
import axios from 'axios'
import arrowL from "../images/left-arrow_icon.png"



const CoursePageSEO = () => {
    const params = useParams()
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    const navigate = useNavigate();
    const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })

    const [allDayCirriculumList, setAllDayCirriculumList] = useState([])

    const [scrollLength, setScrollLength] = useState(0)
    const [userDetails, setUserDetails] = useState({})

    const [whatIsNav, setWhatIsNav] = useState('col')
    const [forWhoNav, setForWhoNav] = useState('col')
    const [highlightsNav, setHighlightsNav] = useState('col')
    const [curriculumNav, setCurriculumNav] = useState('col')
    const [toolsCoveredNav, setToolsCoveredNav] = useState('col')
    const [projectsNav, setProjectsNav] = useState('col')
    const [jobRolesNav, setJobRolesNav] = useState('col')
    const [feesNav, setFeesNav] = useState('col')
    const [faqsNav, setFaqsNav] = useState('col')
    const [navSet, setNavSet] = useState('row common_main_text_tag laptop-sidebar sfixed course_nav')

    const whatIs = useRef(null)
    const forWho = useRef(null)
    const highlights = useRef(null)
    const curriculum = useRef(null)
    const toolsCovered = useRef(null)
    const projects = useRef(null)
    const jobRoles = useRef(null)
    const fees = useRef(null)
    // const successStories = useRef(null)
    const faqs = useRef(null)

    const executeScrollWhatIs = () => whatIs.current.scrollIntoView()
    const executeScrollForWho = () => forWho.current.scrollIntoView()
    const executeScrollHighlights = () => highlights.current.scrollIntoView()
    const executeScrollCirriculum = () => curriculum.current.scrollIntoView()
    const executeScrollToolsCovered = () => toolsCovered.current.scrollIntoView()
    const executeScrollProjects = () => projects.current.scrollIntoView()
    const executeScrollJobRoles = () => jobRoles.current.scrollIntoView()
    const executeScrollFees = () => fees.current.scrollIntoView()
    // const executeScrollSuccessStories = () => successStories.current.scrollIntoView()
    const executeScrollFAQs = () => faqs.current.scrollIntoView()
    const [copied, setCopied] = useState(false);

  function copy() {
    const el = document.createElement("input");
    el.value = window.location.href;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    setCopied(true);
  }

    function whatIssetNav() {
        setWhatIsNav('col laptop_course_nav_button');
        setForWhoNav('col');
        setHighlightsNav('col');
        setCurriculumNav('col');
        setToolsCoveredNav('col');
        setProjectsNav('col');
        setJobRolesNav('col');
        setFeesNav('col');
        setFaqsNav('col');
    }

    function forWhosetNav() {

        setWhatIsNav('col');
        setForWhoNav('col laptop_course_nav_button');
        setHighlightsNav('col');
        setCurriculumNav('col');
        setToolsCoveredNav('col');
        setProjectsNav('col');
        setJobRolesNav('col');
        setFeesNav('col');
        setFaqsNav('col');
    }

    function highlightssetNav() {

        setWhatIsNav('col');
        setForWhoNav('col');
        setHighlightsNav('col laptop_course_nav_button');
        setCurriculumNav('col');
        setToolsCoveredNav('col');
        setProjectsNav('col');
        setJobRolesNav('col');
        setFeesNav('col');
        setFaqsNav('col');
    }

    function curriculumsetNav() {

        setWhatIsNav('col');
        setForWhoNav('col');
        setHighlightsNav('col');
        setCurriculumNav('col laptop_course_nav_button');
        setToolsCoveredNav('col');
        setProjectsNav('col');
        setJobRolesNav('col');
        setFeesNav('col');
        setFaqsNav('col');
    }

    function toolsCoveredsetNav() {

        setWhatIsNav('col');
        setForWhoNav('col');
        setHighlightsNav('col');
        setCurriculumNav('col');
        setToolsCoveredNav('col laptop_course_nav_button');
        setProjectsNav('col');
        setJobRolesNav('col');
        setFeesNav('col');
        setFaqsNav('col');
    }

    function projectssetNav() {

        setWhatIsNav('col');
        setForWhoNav('col');
        setHighlightsNav('col');
        setCurriculumNav('col');
        setToolsCoveredNav('col');
        setProjectsNav('col laptop_course_nav_button');
        setJobRolesNav('col');
        setFeesNav('col');
        setFaqsNav('col');
    }

    function jobRolessetNav() {

        setWhatIsNav('col');
        setForWhoNav('col');
        setHighlightsNav('col');
        setCurriculumNav('col');
        setToolsCoveredNav('col');
        setProjectsNav('col');
        setJobRolesNav('col laptop_course_nav_button');
        setFeesNav('col');
        setFaqsNav('col');
    }

    function feessetNav() {

        setWhatIsNav('col');
        setForWhoNav('col');
        setHighlightsNav('col');
        setCurriculumNav('col');
        setToolsCoveredNav('col');
        setProjectsNav('col');
        setJobRolesNav('col');
        setFeesNav('col laptop_course_nav_button');
        setFaqsNav('col');
    }

    function faqssetNav() {

        setWhatIsNav('col');
        setForWhoNav('col');
        setHighlightsNav('col');
        setCurriculumNav('col');
        setToolsCoveredNav('col');
        setProjectsNav('col');
        setJobRolesNav('col');
        setFeesNav('col');
        setFaqsNav('col laptop_course_nav_button');
    }


    const [fix, setFix] = useState(false)

    const [courseDetails, setCourseDetails] = useState({})

    const [loading, setLoading] = useState(false)

    function setFixedSidebar() {
        //console.log("SSSS : " + window.scrollY)
        setScrollLength(window.scrollY)
        if (window.scrollY >= 300) {
            setFix(true)
        } else {
            setFix(false)
        }
    }

    window.addEventListener("scroll", setFixedSidebar)

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    //API call when page loader
    useEffect(() => {

        getLoggedInUserDetails();
        getcourseDetails();

        const handleScroll = (e) => {
            if (window.scrollY >= 0 && window.scrollY <= window.innerHeight / 2) {
                whatIssetNav()
            } else if (whatIs.current.offsetTop - window.scrollY < window.innerHeight / 2 && forWho.current.offsetTop - window.scrollY >= window.innerHeight / 2) {
                // For the about section
                forWhosetNav()
            }
            else if (forWho.current.offsetTop - window.scrollY < window.innerHeight / 2 && highlights.current.offsetTop - window.scrollY >= window.innerHeight / 2) {
                // For the about section
                highlightssetNav()
            }
            else if (highlights.current.offsetTop - window.scrollY < window.innerHeight / 2 && curriculum.current.offsetTop - window.scrollY >= window.innerHeight / 2) {
                // For the about section
                curriculumsetNav()
            }
            else if (curriculum.current.offsetTop - window.scrollY < window.innerHeight / 2 && toolsCovered.current.offsetTop - window.scrollY >= window.innerHeight / 2) {
                // For the about section
                toolsCoveredsetNav()
            }
            else if (toolsCovered.current.offsetTop - window.scrollY < window.innerHeight / 2 && projects.current.offsetTop - window.scrollY >= window.innerHeight / 2) {
                // For the about section
                projectssetNav()
            }
            else if (projects.current.offsetTop - window.scrollY < window.innerHeight / 2 && jobRoles.current.offsetTop - window.scrollY >= window.innerHeight / 2) {
                // For the about section
                jobRolessetNav()
            }
            else if (jobRoles.current.offsetTop - window.scrollY < window.innerHeight / 2 && fees.current.offsetTop - window.scrollY >= window.innerHeight / 2) {
                // For the about section
                feessetNav()
            }
            else if (fees.current.offsetTop - window.scrollY < window.innerHeight / 2 && faqs.current.offsetTop - window.scrollY >= window.innerHeight / 2) {
                // For the about section
                faqssetNav()
                setNavSet('row common_main_text_tag laptop-sidebar ssticky course_nav')
            }

            else {
                
            }
        }
        document.addEventListener('scroll', handleScroll);
        return () => {
            document.removeEventListener('scroll', handleScroll);
        }

    }, [])

    function getLoggedInUserDetails() {
        console.log("TRIGGERED LOGGED")
        httpsignup.get(`/skillsquadUserprofileLMS/getSkillsquadUserProfileBySignUpId?signUpId=${sessionStorage.getItem('userId')}`)
            .then((response) => {
                sessionStorage.setItem("coursePageName", response.data.userName)
                sessionStorage.setItem("coursePageEmail", response.data.userEmail)
                sessionStorage.setItem("coursePageMobile", response.data.mobileNumber)
                console.log("USER DETAILS RESPONSE : " + JSON.stringify(response.data))
               

            }).catch((err) => {
                console.log(err)
            })


    }
    function getcourseDetails() {
        //console.log("TRIGGERED LOGGED")
        httpsignup.get(`/usersLMS/getSkillsquadCourses?seoUrl=${params.courseTag}`)
            .then((response) => {
                console.log("RRR : " + JSON.stringify(response.data))
                setCourseDetails(response.data)
                 if (response.data.skillsquadCourses.selfPacedDiscountedAmount != null){
                httpsignup.get(`/programsLMS/getCourseDayCircullum?programId=${response.data.skillsquadCourses.selfPacedDiscountedAmount}`)
                .then((response) => {
                    console.log('RESPONSE : ' + JSON.stringify(response.data))
                    setAllDayCirriculumList(response.data)
                }).catch((err) => {
                    console.log(err)
                })
            }
                setLoading(true)
            }).catch((err) => {
                console.log(err)
            })

           


    }



 
    const [Razorpay] = useRazorpay();


    

    const handlePayment = useCallback ((payAmount) => {
        if(sessionStorage.getItem('userId') != null){
        httpsignup.post(`/dashBoardLMS/web?amount=${payAmount}&currency=INR&auth=cnpwX2xpdmVfc2NQV296M29PUjZkSWQ6STZ3YW5QTVZVU0I5bjlxOUJ3RmtTZVhh`)
        .then((response) => {
            const options: RazorpayOptions = {
                key: "rzp_live_scPWoz3oOR6dId",
                amount: parseInt(payAmount) * 100,
                currency: "INR",
                name: "Skillsquad",
                description: "Test Transaction",
                image: require("../images/skillsquad_logo_fav_png.png"),
                order_id: response.data, 
                handler: (res) => {
                    console.log("HHHH : " + JSON.stringify(res));
                    httpsignup.post(`/usersLMS/saveCourseUser?name=${sessionStorage.getItem("coursePageName")}&email=${sessionStorage.getItem("coursePageEmail")}&phoneNumber=${sessionStorage.getItem("coursePageMobile")}&programId=${sessionStorage.getItem("courseProgramId")}`)
                        .then((response) => {
                            console.log('RESPONSE : ' + JSON.stringify(response.data))
    
                            navigate("/my-course")
                        }).catch((err) => {
                            console.log(err)
                        })
    
                },
                prefill: {
                    // sessionStorage.getItem("coursePageName")
                    // sessionStorage.getItem("coursePageEmail")
                    // sessionStorage.getItem("coursePageMobile")
                    name: sessionStorage.getItem("coursePageName"),
                    email: sessionStorage.getItem("coursePageEmail"),
                    contact: sessionStorage.getItem("coursePageMobile"),
                },
                notes: {
                    address: "Razorpay Corporate Office",
                },
                theme: {
                    color: "#5627ff",
                },
            };
    
            const rzpay = new Razorpay(options);
            rzpay.open();
           
        }).catch((err) => {
            console.log(err)
        })
        console.log(JSON.stringify(payAmount))
    }else{
        navigate("/sign-up/"+params.courseTag)
    }
       
    }, [Razorpay]);

   function handleLocationPage(tag){
    navigate("/courses/"+tag)
   }

    return (
        <div>

            {(isDesktopOrLaptop || isBigScreen || isTabletOrMobile ) && loading && <div class="mt-5">
                <div class="laptop_course_hearder ">
                    <div class="laptop_course_header_text d-flex mt-4 align-items-center">
                    <img src={arrowL} width={30} alt="arrow" style={{ cursor: "pointer" }} onClick={() => {handleLocationPage(courseDetails.skillsquadCourses.seoUrl)}} />
                        <div>Course</div><img src={require("../images/arrow - right.png")} />
                        <div>{courseDetails.programName}</div><img src={require("../images/arrow - right.png")} />
                        <div>{params.location}</div>
                        {/* <img src={require("../images/arrow - right.png")} />
                        <div>Web Design</div>  */}
                    </div>
                    <div class="row mt-5">
                        <div class="col laptop_course_text_color"><div class="common_main_heading">{courseDetails.programName} in {params.location}</div>
                            <div class="mt-3 common_main_text_normal">{courseDetails.skillsquadCourses.courseSubtitle}</div>
                            <div class="row mt-3">
                                {courseDetails.perksList.map((data, index) => {
                                    return (<div class="col-3 p-1 laptop_course_header_tags common_main_text_tag">{data.perksName}</div>)
                                })}
                                {/* <div class="col-3 p-1 laptop_course_header_tags common_main_text_tag">1:1 Mentorship</div>
                                <div class="col-4 p-1 laptop_course_header_tags common_main_text_tag">Dedicated placement support</div>
                                <div class="col-3 p-1 laptop_course_header_tags common_main_text_tag">Faculty from MAANG</div> */}
                            </div>
                            <div class="row mt-3">
                                <div class="col-3 common_main_text_tag"><img
                                    width={20}
                                    loading="lazy"
                                    alt=""
                                    src={require("../images/course_star.png")}
                                />4.5 (2540 reviews)</div>
                               
                                <div class="col-3 common_main_text_tag">Pay low as ₹{courseDetails.skillsquadCourses.selfPacedDiscountedAmount} <img
                                    width={20}
                                    loading="lazy"
                                    alt=""
                                    src={require("../images/check.png")}
                                /></div>
                            </div>
                            <div class="laptop_course_header_box mt-3">
                                <div class="row">
                                    <div class="col">
                                        <div class="common_main_sub_highlight">{courseDetails.skillsquadCourses.highestSalary}</div>
                                        <div>Highest Salary</div>
                                    </div>
                                    <div class="col">
                                        <div class="common_main_sub_highlight">{courseDetails.skillsquadCourses.hiringPartners}</div>
                                        <div>Hiring Partners</div>
                                    </div>
                                    <div class="col">
                                        <div class="common_main_sub_highlight">{courseDetails.skillsquadCourses.averageHike}</div>
                                        <div>Average Hike</div>
                                    </div>
                                    <div class="col">
                                        <div class="common_main_sub_highlight">{courseDetails.skillsquadCourses.students}</div>
                                        <div>Students</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-2"></div>
                        <div class="col-3 laptop_course_text_color"><div>
                            <Player playsInline src={courseDetails.programDemoVideo} fluid={true} >
                                <ControlBar autoHide={true} disableDefaultControls={true}>

                                </ControlBar>
                            </Player>
                            {/* <video class="laptop_course_video" src="http://media.w3.org/2010/05/sintel/trailer.mp4" type='video/mp4' width="305" height="200" /> */}
                        </div></div>
                    </div>
                </div>

                <div class="row laptop_course_desc">
                    <div class="col-9">
                        {/* <nav ref={stickyRef} className={classNames("nav", { sticky })}>
                           
                        </nav> */}
                        <div className={fix ? navSet : 'row common_main_text_tag course_nav'}>
                            {/* <div>{scrollLength}</div> */}
                            <div class={whatIsNav} style={{ cursor: "pointer" }} onClick={() => { executeScrollWhatIs(); whatIssetNav(); }}>What is?</div>
                            <div class={forWhoNav} style={{ cursor: "pointer" }} onClick={() => { executeScrollForWho(); forWhosetNav(); }}>For who?</div>
                            <div class={highlightsNav} style={{ cursor: "pointer" }} onClick={() => { executeScrollHighlights(); highlightssetNav(); }}>Highlights</div>
                            <div class={curriculumNav} style={{ cursor: "pointer" }} onClick={() => { executeScrollCirriculum(); curriculumsetNav(); }}>Curriculum</div>
                            <div class={toolsCoveredNav} style={{ cursor: "pointer" }} onClick={() => { executeScrollToolsCovered(); toolsCoveredsetNav(); }}>ToolsCovered</div>
                            <div class={projectsNav} style={{ cursor: "pointer" }} onClick={() => { executeScrollProjects(); projectssetNav(); }}>Projects</div>

                            <div class={jobRolesNav} style={{ cursor: "pointer" }} onClick={() => { executeScrollJobRoles(); jobRolessetNav(); }}>fees</div>
                            <div class={feesNav} style={{ cursor: "pointer" }} onClick={() => { executeScrollFees(); feessetNav(); }}>Job Roles</div>
                            {/* <div class={scrollLength >= 1600 && scrollLength < 1800 ? 'col-2 laptop_course_nav_button' : 'col-2'} onClick={executeScrollSuccessStories}>Success Stories</div> */}
                            <div class={faqsNav} style={{ cursor: "pointer" }} onClick={() => { executeScrollFAQs(); faqssetNav(); }}>FAQs</div>
                        </div>
                        {/* {sticky && (
                            <div
                            style={{
                                height: sticky ? `${stickyRef.current?.clientHeight}px` : '0px',
                              }}
                            />
                        )} */}
                        {/* <div class="d-flex">
    <img src={require("../images/Card 1.png")}  alt="card1" style={{border: "1px solid red"}}/>
    <img src={require("../images/card 2.png")}  alt="card1" style={{border: "1px solid red"}}/>
    <img src={require("../images/Card 3.png")}  alt="card1" style={{border: "1px solid red"}}/>
    <img src={require("../images/Card 4.png")}  alt="card1" style={{border: "1px solid red"}}/>
   </div> */}
                        <div class="mt-5" ref={whatIs}>
                            <div class="mb-4 common_main_sub_highlight">{courseDetails.skillsquadCourses.whatIsQuestion}</div>
                            <div>{courseDetails.skillsquadCourses.whatIsAnswer}</div>
                        </div>

                        <div class="laptop_course_desc_who_is mt-5" ref={forWho}>
                            <div class="mb-4 common_main_sub_highlight">{courseDetails.skillsquadCourses.forWhoQuestion}</div>
                            <div>{courseDetails.skillsquadCourses.forWhoAnswer}</div>
                        </div>
                        {courseDetails.courseIncludesList.length > 0 && <div class="mt-5">
                            <div class="mb-4 common_main_sub_highlight" ref={highlights}>Program Highlights</div>
                            <div class="row mt-3 align-items-center">
                                {courseDetails.courseIncludesList.map((data, index) => {
                                    return (<div class="col"><img src={data.iconUrl} alt="" /> {data.includesName}</div>)
                                })}
                                {/* <div class="col">160+ Hours Instructor-Led Sessions</div>
                                <div class="col">50+ Hours of live Interactive Doubt-Solving Sessions</div> */}
                            </div>

                        </div>}
                        <div class="mt-5" ref={curriculum}>
                            <div class="mb-4 common_main_sub_highlight">Carriculum</div>
                            <div>
                                {allDayCirriculumList.map((res) => {
                                    return (
                                        <Accordion title={res.day} content={res} totalTask={res.totaltasks} dayProgress={res.dayProgress} currentDayProgress={res.currentDayProgress} generic={"generic"} />

                                    )
                                })}
                            </div>
                        </div>

                        <div class="mt-5" ref={toolsCovered}>
                            <div class="mb-4 common_main_sub_highlight">Master Latest tools and technologies</div>
                            <div class="row">
                                {courseDetails.skillsquadCourseToolsCoveredList.map((res) => {
                                    return (
                                        <div class="col"><img class="admin-pro-pic my-1 me-2 careerhelp-img" src={res.toolUrl} alt="tools" width={30} /></div>

                                    )
                                })}
                            </div>
                        </div>
                        <div class="laptop_course_desc_who_is mt-5" style={{ backgroundColor: "#F9F9F9" }} ref={projects}>
                            <div class="mb-4 common_main_sub_highlight">Projects</div>
                            <div>By the time you graduate from our Immersive have a solid job-worthy of apps deployed on the Cloud to impress top
                                tech recruiters with and land the job you want. Here's a peek at some cf the projects yqdll be able to build:</div>
                            <div class="laptop_faq-inside ">
                                <div class="row mt-3">
                                    {courseDetails.skillsquadCourseProjectsList.map((res) => {
                                        return (
                                            <div class="col">
                                                <div class="row">
                                                    <div class="col-2"><img src={res.projectImageUrl} /></div>
                                                    <div class="col"><div>{res.projectHeading}</div>
                                                        {/* <div>Social Network</div> */}
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col">{res.projectDescription}</div>

                                                </div>
                                            </div>

                                        )
                                    })}



                                </div>

                            </div>
                        </div>
                        <div class="laptop_course_desc_who_is mt-5" ref={fees}>
                            <div class="mb-4 common_main_sub_highlight">Tuition Fees</div>
                            <div class="row">
                                <div class="col-5 laptop_course_fees">
                                    <div class="row">
                                        <div class="col"></div>
                                        <div class="col laptop_course_bestseller">Best Seller</div>
                                        <div class="col"></div>
                                    </div>
                                    <div style={{ padding: "5%" }} >
                                        <div class="text-center"> <div class="common_main_heading">Instructor-Led Training</div>
                                            <div class="common_main_text_highlight mt-2">Learn in Expert-Led Sessions</div>
                                            <hr style={{ border: "1px dashed black" }}></hr>
                                        </div>
                                        <div>
                                            <div class="common_main_text_highlight">Solid Experiential Learning</div>
                                            <div class="mt-2"> <img src={require("../images/password_check1.png")} alt="" width={20} /> Access to selected free courses.</div>
                                            <div class="mt-2"> <img src={require("../images/password_check1.png")} alt="" width={20} /> Limited course materials and resources.</div>
                                            <div class="mt-2"> <img src={require("../images/password_check1.png")} alt="" width={20} /> Basic community support.</div>
                                            <div class="mt-2"> <img src={require("../images/password_check1.png")} alt="" width={20} /> No certification upon completion.</div>
                                            <div class="mt-2"> <img src={require("../images/password_check1.png")} alt="" width={20} /> Ad-supported platform.</div>
                                            <div class="mt-2"><img src={require("../images/password_check1.png")} alt="" width={20} /> Placement Guarantee</div>
                                            <div class="mt-2"><img src={require("../images/password_check1.png")} alt="" width={20} /> Mock Interviews</div>
                                            <div class="mt-2"><img src={require("../images/password_check1.png")} alt="" width={20} /> Live Personalized project reviews</div>
                                        </div>
                                        <hr style={{ border: "1px dashed black" }}></hr>

                                        <div class="common_main_text_highlight mt-3">Upcoming Batches</div>
                                        <div class="d-flex common_main_text_tag_highlight mt-2">
                                            <div > {moment(courseDetails.skillsquadCourses.batchStartDate).format("DD MMM")} : Weekday Batch</div>
                                            <div style={{ margin: "0px 5px 0px 5px" }}>|</div>
                                            <div >{moment(courseDetails.skillsquadCourses.cohartStart).format("DD MMM")} : Weekday Batch</div>
                                        </div>
                                    </div>

                                    <div class="laptop_course_fees_footer">
                                        <div style={{ color: "#349669" }}>{courseDetails.percenatgeOff}% OFF</div>
                                        <div class="row">
                                            <div class="col-2 common_main_heading">₹{courseDetails.skillsquadCourses.discountedAmount}</div>
                                            <div class="col common_main_text_tag linestrike">₹{courseDetails.skillsquadCourses.originalAmount}</div>
                                            <div class="col laptop_course_fees_enroll" style={{ cursor: "pointer" }} onClick={() => handlePayment(courseDetails.skillsquadCourses.discountedAmount)}>Enroll Now</div>
                                        </div>
                                        <div style={{ color: "#349669" }}>As low as ₹ {courseDetails.instructorEmi}/month</div>
                                    </div>
                                </div>


                                <div class="col-5 laptop_course_fees" style={{ borderColor: "transparent" }}>

                                    <div style={{ padding: "5%" }} class="mt-4">
                                        <div class="text-center"> <div class="common_main_sub_highlight">Self Paced Training</div>
                                            <div>Learn at your own pace</div>
                                            <hr style={{ border: "1px dashed black" }}></hr>
                                        </div>
                                        <div>
                                            <div class="common_main_text_highlight">Solid Experiential Learning</div>
                                            <div class="mt-2"><img src={require("../images/password_check1.png")} alt="" width={20} /> Access to selected free courses.</div>
                                            <div class="mt-2"><img src={require("../images/password_check1.png")} alt="" width={20} /> Limited course materials and resources.</div>
                                            <div class="mt-2"><img src={require("../images/password_check1.png")} alt="" width={20} /> Basic community support.</div>
                                            <div class="mt-2"><img src={require("../images/password_check1.png")} alt="" width={20} /> No certification upon completion.</div>
                                            <div class="mt-2"><img src={require("../images/password_check1.png")} alt="" width={20} /> Ad-supported platform.</div>
                                            <div class="mt-2"><img src={require("../images/wrong_icon.png")} alt="" width={20} /> Placement Guarantee</div>
                                            <div class="mt-2"><img src={require("../images/wrong_icon.png")} alt="" width={20} /> Mock Interviews</div>
                                            <div class="mt-2"><img src={require("../images/wrong_icon.png")} alt="" width={20} /> Live Personalized project reviews</div>
                                        </div>


                                        <hr style={{ border: "1px dashed black" }}></hr>
                                    </div>
                                    <br></br>
                                    <br></br>
                                    <br></br>

                                    <div class="laptop_course_fees_footer">
                                        <div style={{ color: "#349669" }}>{courseDetails.selfPacedPercentageOff}% OFF</div>
                                        <div class="row">
                                            <div class="col-3 common_main_heading">₹{courseDetails.skillsquadCourses.selfPacedDiscountedAmount}</div>
                                            <div class="col-3 common_main_text_tag linestrike" >₹{courseDetails.skillsquadCourses.selfPacedOrginalAmount}</div>
                                            <div class="col laptop_course_fees_enroll" style={{ cursor: "pointer" }} onClick={() => handlePayment(courseDetails.skillsquadCourses.selfPacedDiscountedAmount)}>Enroll Now</div>
                                        </div>
                                        <div style={{ color: "#349669" }}>As low as ₹ {courseDetails.selfPacedEmi}/month</div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="mt-5" ref={jobRoles}>
                            <div class="mb-4 common_main_sub_highlight">Apply for these roles after completing the course</div>
                            <div class="row align-items-center">
                                <div class="col">
                                    <img

                                        loading="lazy"
                                        alt=""
                                        src={require("../images/rectangle-22573@2x.png")}
                                    />
                                </div>
                                <div class="col">
                                    {courseDetails.rolesList.map((data, index) => {
                                        return (<div class="row align-items-center mt-2"> <div class="col"><img
                                            width={40}
                                            loading="lazy"
                                            alt=""
                                            src={require("../images/basilbagsolid.png")}
                                        /> {data.roleName}</div>
                                        </div>)
                                    })}


                                </div>
                            </div>
                        </div>
                        <div class="laptop_course_desc_who_is mt-5" style={{ backgroundColor: "#F9F9F9" }} ref={faqs}>
                            <div class="mb-4 common_main_sub_highlight">FAQs</div>
                            <div class="laptop_faq-inside"><div>
                                {courseDetails.skillsquadCourseFaqsList.map((res, index) => {
                                    return (
                                        <FAQAccordion num={index} title={res.question} content={res} />

                                    )
                                })}
                            </div></div>
                        </div>
                        <div class="mt-5">
                            <div class="mb-4 common_main_sub_highlight">Find {courseDetails.programName} in other top cities</div>
                            <div class="row">
                                {courseDetails.cityList.map((data, index) => {
                                    return (<div class="col-2 laptop_course_locations" onClick={() => {handleLocationPage("java-backend-course",data.cityName)}}>{data.cityName}{courseDetails.seoUrl}</div>)
                                })}

                            </div>
                        </div>
                    </div>
                    <div class="col"> <div className={fix ? 'laptop-sidebarc sfixed' : 'laptop-sidebar'}> <div class="laptop_buynow bg-white"> <div class="row ">
                        <div class="col-2 common_main_sub_highlight">₹{courseDetails.skillsquadCourses.selfPacedDiscountedAmount}</div>
                        <div class="col common_main_text_tag linestrike">₹{courseDetails.skillsquadCourses.selfPacedOrginalAmount}</div>
                        <div class="col-4 laptop_price_off common_main_text_tag_highlight">{courseDetails.selfPacedPercentageOff}% OFF</div>
                    </div>
                        <div class="row align-items-center">
                            <div class="col common_main_text_tag" style={{ color: "#E34444" }}><img
                                width={15}
                                loading="lazy"
                                alt=""
                                src={require("../images/alarm.png")}
                            /> {moment(courseDetails.skillsquadCourses.deadlineTimer).fromNow(true)} left at this price!</div>
                        </div>
                        <hr style={{ height: 0, border: "none", borderTop: "1px solid black" }}></hr>
                        <div class="row mt-2">
                            <div class="col common_main_text_tag"><img
                                width={20}
                                loading="lazy"
                                alt=""
                                src={require("../images/clock.png")}
                            /> Course Duration</div>
                            <div class="col common_main_text_tag" style={{ textAlign: "right" }}>{courseDetails.duration}</div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-5 common_main_text_tag"><img
                                width={20}
                                loading="lazy"
                                alt=""
                                src={require("../images/barchart.png")}
                            /> Course Level</div>
                            <div class="col-7 common_main_text_tag" style={{ textAlign: "right" }}>{courseDetails.levelName}</div>
                        </div>
                        <div class="row mt-2">
                            <div class="col common_main_text_tag"><img
                                width={20}
                                loading="lazy"
                                alt=""
                                src={require("../images/users.png")}
                            /> Students Enrolled</div>
                            <div class="col common_main_text_tag" style={{ textAlign: "right" }}>69,419,618</div>
                        </div>
                        <div class="row mt-2">
                            <div class="col common_main_text_tag"><img
                                width={20}
                                loading="lazy"
                                alt=""
                                src={require("../images/notebook.png")}
                            /> Language</div>
                            <div class="col common_main_text_tag" style={{ textAlign: "right" }}>{courseDetails.languageName}</div>
                        </div>
                        <div class="row mt-2">
                            <div class="col common_main_text_tag"><img
                                width={20}
                                loading="lazy"
                                alt=""
                                src={require("../images/notepad.png")}
                            /> Subtitle Language</div>
                            <div class="col common_main_text_tag" style={{ textAlign: "right" }}>{courseDetails.subtitleLanguageName}</div>
                        </div>
                        <hr style={{ height: 0, border: "none", borderTop: "1px solid black" }}></hr>
                        <div class="common_main_button" onClick={() => handlePayment(courseDetails.skillsquadCourses.selfPacedDiscountedAmount)}>Buy Now</div>
                        <div class="common_main_button mt-2" style={{ backgroundColor: "#EBE5FF", color: '#5627FF' }}>Start Free Trial</div>
                        {/* <div class="row">
                            <div class="col laptop-wishlist">Add to Wishlist</div>
                            <div class="col laptop-wishlist">Gift Course</div>
                        </div> */}
                        <div class="row">
                            <div class="col mt-2">
                                <span class="common_main_text_tag_highlight">Note: </span><span class="common_main_text_tag">all course have 30-days money-back guarantee</span>
                            </div>
                        </div>


                        <hr style={{ height: 0, border: "none", borderTop: "1px solid black" }}></hr>


                        <div class="row">
                            <div class="col common_main_text_tag_highlight">
                                Upcoming Batches/Cohort
                            </div>

                        </div>
                        <div class="d-flex common_main_text_tag mt-2">
                            <div > {moment(courseDetails.skillsquadCourses.batchStartDate).format("DD MMM")} : Weekday Batch</div>
                            <div style={{ margin: "0px 3px 0px 3px" }}>|</div>
                            <div >{moment(courseDetails.skillsquadCourses.cohartStart).format("DD MMM")} : Weekday Batch</div>
                        </div>

                        <hr style={{ height: 0, border: "none", borderTop: "1px solid black" }}></hr>

                        <div class="row">
                            <div class="col common_main_text_tag_highlight">
                                Share this course:
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-4 laptop_price_icons common_main_text_tag" style={{cursor:"pointer"}} onClick={copy}>
                           
                                <img
                                    width={15}
                                    loading="lazy"
                                    alt=""
                                    src={require("../images/copy.png")}
                                />
                               {!copied ? "Copy Link" : "Copied!"}
                            </div>
                            <div class="col laptop_price_icons">
                                <img src={require("../images/facebook.png")} width={15} alt="" />
                            </div>
                            <div class="col laptop_price_icons">
                                <img src={require("../images/twitter-1.png")} width={15} alt="" />
                            </div>
                            <div class="col laptop_price_icons">
                                <img src={require("../images/envelope.png")} width={15} alt="" />
                            </div>
                            <div class="col laptop_price_icons">
                                <img src={require("../images/whatsapp course.png")} width={15} alt="" />
                            </div>
                        </div>
                    </div>
                        <div class="laptop_download mt-3" onClick={() => { window.open(courseDetails.skillsquadCourses.syllabusUrl) }}><img
                            width={20}
                            loading="lazy"
                            alt=""
                            src={require("../images/download.png")}
                        /> Download Syllabus for Complete Details</div>
                    </div>

                    </div>
                </div>

 {/* onlytext */}
 <div className="container">
        <h1 style={{ textAlign: "center", color: "#000", fontSize: "3.5rem", marginBottom: "-15px",marginTop:"10rem" }}>We have a lot of exciting courses. Startup</h1>
        <h1 style={{ textAlign: "center", color: "#000", fontSize: "3.5rem", marginBottom: "-15px" }}>opportunities and candidates - only a few are</h1>
        <h1 style={{ textAlign: "center", color: "#000", fontSize: "3.5rem",marginBottom:"7rem" }}>perfect for you. we'll help you find the few.</h1>
      </div>

      {/* onlybuttons */}
      <hr style={{ border: "1px solid #000000", borderColor: "#000000 ", marginBottom: "7rem" }} />
      <div className='container-fluid fivebuttonhead '>
        <div className='row d-flex justify-content-between'>
          <div className='col-lg-2 col-md-6 col-sm-12 fivebutton'>Courses</div>
          <div className='col-lg-2 col-md-6 col-sm-12 fivebutton'>Professional network</div>
          <div className='col-lg-2 col-md-6 col-sm-12 fivebutton'>Mentorship</div>
          <div className='col-lg-3 col-md-6 col-sm-12 fivebutton'>Projects</div>
          <div className='col-lg-3 col-md-6 col-sm-12 fivebutton'>Jobs</div>

        </div>
      </div>
      <hr style={{ border: "1px solid #000000", borderColor: "#000000 ", marginTop: "7rem" }} />


<NewFooter/>
            </div>
            
            }
            {/* {isBigScreen && <p>You have a huge screen</p>}
      {isTabletOrMobile && <p style={{fontSize: 30}}>You are a tablet or mobile phone</p>}
      <p>Your are in {isPortrait ? 'portrait' : 'landscape'} orientation</p>
      {isRetina && <p>You are retina</p>} */}

        </div>
    )
};

export default CoursePageSEO;