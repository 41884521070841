import React from 'react'
import { useNavigate } from "react-router-dom";
import { useEffect } from 'react';
import "../styles/SignIn.css"
import itreion from '../images/skillsquad_logo_fav.svg'
import tick from '../images/skillsquad_tick.png'
import swiggy from '../images/Swiggy-Black.png'
import netflix from '../images/netflix-2.png'
import tableau from '../images/tableau-logo-png.png'
import amazon from '../images/amazon-png.png'
import { GoogleLogin } from "react-google-login";
import { gapi } from "gapi-script";
import httpuserprofile from "../js/http-lms_userprofile"
import httpsignup from '../js/http-lms_signup';
import { trackEvent } from '../facebookPixel';



const SignInBS = () => {
  const navigate = useNavigate()


  const clientid = "920842439159-jdjcodvqg846gg738r8hbnsnpb7otpv5.apps.googleusercontent.com";
  //const clientid = "760948710975-4f1eq244thd80o830r4p95l3soiqf00t.apps.googleusercontent.com";

  useEffect(() => {
    trackEvent('InitiateCheckout');
    gapi.load("client:auth2", () => {
      gapi.auth2.init({ clientId: clientid })
    })
  }, [])


  const onSuccess = (res) => {



    // const response=await axios.post("http://34.199.176.17:13051/userloginLMS/validate",{
      httpsignup.post("/skillsquadUserloginLMS/validate", {
      emailId: 'saikishore@syntrino.net',
      passWord: 'Abcd1234#',
      roleId: 1,
      authCode: "sqSKL390"
    }).then((result) => {
      console.log(result.data);
      if (result.data[0].apiStatusMessage === 'Account doesnt exist') {

      }
      else if (result.data[0].apiStatusMessage === 'PASSWORD_INCORRECT') {


      }
      else if (result.data[0].apiStatusMessage === 'Account is terminated') {


      }
      else {
        const bearerToken = result.data[0].jwtToken;
        sessionStorage.setItem("JWT", bearerToken)
        sessionStorage.setItem("jwt_token",bearerToken,{expires:30})
        sessionStorage.setItem("LOGGEDIN", true)
        sessionStorage.setItem("userEmail", res.profileObj["email"])
        sessionStorage.setItem("userName", res.profileObj["name"])
        sessionStorage.setItem("JWTuserName", "saikishore@syntrino.net")
        httpuserprofile.post("/skillsquadUserprofileLMS/saveSkillsquadSignUp", {
          email: res.profileObj["email"],
          familyName: res.profileObj["familyName"],
          givenName: res.profileObj["givenName"],
          googleId: res.profileObj["googleId"],
          imageUrl: res.profileObj["imageUrl"],
          userName: res.profileObj["name"]
        }).then((result) => {
          console.log("HELLO : "+ JSON.stringify(result.data));
          sessionStorage.setItem("userId", result.data.signUpId)
          sessionStorage.setItem("userId",result.data.signUpId)
          navigate("/mentors/1:1-services/recommended")
        }).catch((e) => {
          console.log(e)
        })

      }
    }).catch((e) => {
      console.log(e.response)

    })

  };

  const onFailure = (res) => {
    console.log("Failed", res);
  };

  function signUp(){
    navigate("/sign-up")
}

  return (
    <>
      <div className='row d-flex align-items-center justify-content-between vh-100 login-back-container' style={{ backgroundColor: '#F0F0FA' }}>
        <div className='col-md-5 col-12 m-md-0  h-100 justify-content-center align-items-center bgwhite rounded-1 d-flex leftContainer'>

          <div className="col px-5 pt-5 pb-5 d-flex flex-column">
            <div className="col-12">
              <div className="logo mx-auto text-center pt-1">
                <img src={itreion} alt="logo" className='mx-auto d-none d-lg-block logo-size' />
                <br></br>
                <p className="signin-heading">Sign in to Skillsquad Leap!</p>
                 <GoogleLogin
                  clientId={clientid}
                  onSuccess={onSuccess}
                  onFailure={onFailure}
                  buttonText="Continue with Google"
                  cookiePolicy={"single_host_origin"}
                  isSignedIn={false}
                  theme='dark'
                />
<div class="mt-5">Create an account? <span style={{ color: "blue", cursor: "pointer" }}><u onClick={signUp}>Sign up now</u></span></div>
              </div>

            </div>
          </div>
        </div>
        <div className='col-md-5 col-10 m-md-0  h-100 justify-content-center align-items-center rounded-1 d-flex userSignIn-left-div rightContainer' >
                    <div className="col  pt-5  d-flex flex-column">
                        <div className="col-12">
                            <div className="logo mx-auto pt-1">
                                <p class="signin-heading_2">Journey to your dream tech company</p>
                                <div class="container mt-3">
                                    <div class="row mb-2">
                                        <div class="col-md-1 col-xs-1 text-right">
                                            <img src={tick} style={{width:"90%"}}/>
                                        </div>
                                        <div class="col-md-6 col-xs-6">
                                            <p class="signup-div-text">Tap into our expert network</p>

                                        </div>
                                    </div>
                                    <div class="row mb-2">
                                        <div class="col-md-1 col-xs-1 text-right">
                                            <img src={tick} style={{width:"90%"}} />
                                        </div>
                                        <div class="col-md-10 col-xs-10">
                                            <p class="signup-div-text">Get expert help landing your dream job and pay package</p>

                                        </div>
                                    </div>
                                    <div class="row mb-2">
                                        <div class="col-md-1 col-xs-1 text-right">
                                            <img src={tick} style={{width:"90%"}}/>
                                        </div>
                                        <div class="col-md-11 col-xs-10">
                                            <p class="signup-div-text">Crack the interview code: Learn what companies ask and how to ace it</p>

                                        </div>
                                    </div>
                                    <div class="row mb-2">
                                        <div class="col-md-1 col-xs-1 text-right">
                                            <img src={tick} style={{width:"90%"}}/>
                                        </div>
                                        <div class="col-md-10 col-xs-10">
                                            <p class="signup-div-text">Mock interviews for polished performance</p>

                                        </div>
                                    </div>
                                    <div class="row mb-2">
                                        <div class="col-md-1 col-xs-1 text-right">
                                            <img src={tick} style={{width:"90%"}}/>
                                        </div>
                                        <div class="col-md-10 col-xs-10">
                                            <p class="signup-div-text">Tackle real-world projects, build your portfolio</p>

                                        </div>
                                    </div>
                                    <div class="row mb-2">
                                        <div class="col-md-1 col-xs-1 text-right">
                                            <img src={tick} style={{width:"90%"}}/>
                                        </div>
                                        <div class="col-md-10 col-xs-10">
                                            <p class="signup-div-text">Get referred by experts, launch your career at a tech gaint</p>

                                        </div>
                                    </div>
                                    <div class="row mb-2">
                                        <div class="col-md-1 col-xs-1 text-right">
                                            <img src={tick} style={{width:"90%"}}/>
                                        </div>
                                        <div class="col-md-10 col-xs-10">
                                            <p class="signup-div-text">Optimize your resume, identify perfect companies</p>

                                        </div>
                                    </div>
                                    <div class="row mb-2">
                                        <div class="col-md-1 col-xs-1 text-right">
                                            <img src={tick} style={{width:"90%"}}/>
                                        </div>
                                        <div class="col-md-10 col-xs-10">
                                            <p class="signup-div-text">We'll help you explore option and find your perfect fit.</p>

                                        </div>
                                    </div>
                                </div>
                                
                            </div></div></div>
                </div>
      </div>
    </>
  )
}

export default SignInBS
