import React from "react";
import Sidebar from "./Sidebar";
import "../fonts/Roboto/Roboto-Black.ttf";
import "../styles/LiveClassses.css"
import moment from "moment/moment";
import Spinner from 'react-bootstrap/Spinner';
import { useState } from 'react';
import { useEffect } from 'react';


const LiveClasses = () => {

    const [loading, setLoading] = useState(true)

    function interval() {setInterval(() => {
        setLoading(false);
  }, 500);
}

  useEffect(() => {
    interval()
}, [])

    return (
        <>
            <Sidebar />

            <div className="jobs-dashboard-container mx-4">

                {/* <Navb /> */}

                <div className="px-md-5 mt160">

                    <section class="section">
                        <div class="container">

                            <div class="row p-4">

                                <div class="col-lg-12">
                                    <div class="row">
                                        <div class="">
                                            <div class="section-title  mb-4 pb-2 ">
                                                <div className="col-lg-12">
                                                    <div className="row">
                                                        <h1  class="common_main_heading mb-2">Support Form</h1>
                                                        {loading  ? <div className="d-flex py-5 justify-content-center w-100"><Spinner animation="border" variant="lightsecondary" /> </div> :
                                                      <center> <iframe style={{ overflow: 'hidden',height: '160vh' }}
                                                       
                                                        
                                                      width="600"
                                                     
                                                      src="https://forms.gle/Rjzm4X64st2GEa7N9"
                                                  ></iframe></center> 
    }

                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>


                                </div>

                                {/*Filter section*/}
                            </div>

                        </div>

                    </section>



                </div>


            </div>


        </>
    );
};

export default LiveClasses;
