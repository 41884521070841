import React, { useEffect } from 'react'
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useState } from "react";
import 'react-image-crop/dist/ReactCrop.css'
import httpsignup from '../../js/http-lms_userprofile';
import "react-datepicker/dist/react-datepicker.css";
import RightSidebar from '../RightSidebar';
import Sidebar from '../Sidebar';
import DatePicker from "react-datepicker";
import Select from 'react-select'

const AssignmentsList = ({ onClose, defaultuserExperience, addexperience }) => {

  const [defaultuserDetails, setUserDetails] = useState({})
  //form data
  const [errorMessage, setErrorMessage] = useState("");
  const [formErros, setFormErrors] = useState({});
  const [disable, setDisable] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [cardHeader, setCardHeader] = useState("Add Experience")
  const [cardButton, setCardButton] = useState("Save")
  const [signUpId, setSignUpId] = useState()
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [countriesList, setCountriesList] = useState([])
  const [statesList, setStatesList] = useState([])
  const [citiesList, setCitiesList] = useState([])
  const [selectedCountryOption, setSelectedCountryOption] = useState(null);
  const [selectedStateOption, setSelectedStateOption] = useState(null);
  const [selectedCityOption, setSelectedCityOption] = useState(null);


  useEffect(() => {
    console.log(addexperience)
    if (addexperience === "edit") {
      console.log("UserDetails:", JSON.stringify(defaultuserExperience))
      setUserDetails(defaultuserExperience)

      if (defaultuserExperience.jobTitle != null) {
        setStartDate(new Date(defaultuserExperience.startDate))
        if(defaultuserExperience.currentlyWorking == 'N'){
          
          setChecked(true)
          setEndDate(new Date(defaultuserExperience.endDate))
        }else if(defaultuserExperience.currentlyWorking == 'Y'){
          setChecked(false)
          
        }
        

        httpsignup
          .get("/userprofileLMS/getCountryData?id=" + defaultuserExperience.countryId)
          .then((res) => {
            console.log("user countries", res)

            setSelectedCountryOption(res.data)

            httpsignup
              .get("/userprofileLMS/getUserCountries")
              .then((res) => {
                console.log("user countries", res)
                setCountriesList(res.data)
              })

          })

        httpsignup
          .get("/userprofileLMS/getStateData?countryId=" + defaultuserExperience.countryId + "&stateId=" + defaultuserExperience.stateId)
          .then((res) => {
            console.log("user states", res)


            setSelectedStateOption(res.data)
          })

        httpsignup
          .get("/userprofileLMS/getCityData?stateId=" + defaultuserExperience.stateId + "&cityId=" + defaultuserExperience.cityId)
          .then((res) => {
            console.log("user cities", res)
            setSelectedCityOption(res.data)

          })



        setCardHeader("Edit Experience")
        setCardButton("Update")
      }
    } else {
      getLoggedInUserDetails()
      httpsignup
        .get("/userprofileLMS/getUserCountries")
        .then((res) => {
          console.log("user countries", res)
          setCountriesList(res.data)
        })

    }

  }, [])
  const getLoggedInUserDetails = async () => {
    httpsignup.get(`/skillsquadUserprofileLMS/getSkillsquadUserProfileBySignUpId?signUpId=${sessionStorage.getItem('userId')}`)
      .then((response) => {

        setSignUpId(response.data.signUpId)
      }).catch((err) => {
        console.log(err)
      })
  }

  // validate data and enable/disable submit btn
  useEffect(() => {
    setFormErrors(validate(defaultuserDetails))
    enableDisableBtn()
  }, [defaultuserDetails])
  function enableDisableBtn() {
    if (defaultuserDetails.jobTitle && defaultuserDetails.companyName && defaultuserDetails.companyWebsite && defaultuserDetails.workType && defaultuserDetails.country && defaultuserDetails.city && defaultuserDetails.skillsTools && defaultuserDetails.primaryRole && defaultuserDetails.abountRole) {
      console.log("false")
      //setDisable(false)
    }
    else {
      console.log("true")
      //setDisable(true)
    }
  }
  //updating defaultuserDetails for final submission
  function handleChange(e) {
    httpsignup.get(`/skillsquadUserprofileLMS/companyCheck?signUpId=${sessionStorage.getItem('userId')}&companyName=${e.target.value}`)
      .then((response) => {

        if (response.data != 0) {
          setErrorMessage('Already Record exist')
          setDisable(true)
        } else {
          setErrorMessage('')
          setDisable(false)
        }

      }).catch((err) => {
        console.log(err)
      })

    const { name, value } = e.target;
    setUserDetails((pValue) => {
      console.log(pValue)
      return {
        ...pValue,
        [name]: value
      }
    })
    console.log("after change : ", defaultuserDetails)
  }
  function SubmitFinalData(e) {
    e.preventDefault()
    setFormErrors(validate(defaultuserDetails))
    setIsSubmit(true)
  }


  useEffect(() => {
    if (Object.keys(formErros).length === 0 && isSubmit) {
      if (addexperience === "new") {
        defaultuserDetails.signUpId = signUpId;


      }

      if (checked == false) {
        defaultuserDetails.endDate = null;
        defaultuserDetails.currentlyWorking = 'Y';
      } else {
        defaultuserDetails.endDate = endDate;
        defaultuserDetails.currentlyWorking = 'N';
      }
      defaultuserDetails.startDate = startDate;

      httpsignup.put('/skillsquadUserprofileLMS/updateSkillsquadUserExperience', defaultuserDetails)
        .then((response) => {
          onClose()
          console.log("update response : ", response.data)
          setErrorMessage("Updated sucessfully")
          setTimeout(() => {
            setErrorMessage('')
          }, 5000)
          setUserDetails({})
          setIsSubmit(false)
        }).catch((e) => {
          console.log("error update : ", e.response)
        })
    }
  }, [formErros])

  const validate = (values) => {
    const errors = {};
    const allowaplaspaces = /^[a-zA-Z ]*$/;
    // if (!values.jobTitle) {
    //   errors.jobTitle = "Cohort Name is required!"
    // } else if (!allowaplaspaces.test(values.jobTitle)) {
    //   errors.jobTitle = "Cohort name should contain alphabets and spaces"
    // }
    // if (!values.companyName) {
    //   errors.companyName = "Company Name is required!"
    // }
    // if (!values.companyWebsite) {
    //   errors.companyWebsite = "Company Website  is required!"
    // }

    // if (!values.workType) {
    //   errors.workType = "Work Type  is required!"
    // }

    // if (!values.country) {
    //   errors.country = "Country  is required!"
    // }

    // if (!values.city) {
    //   errors.city = "City  is required!"
    // }

    // if (!values.skillsTools) {
    //   errors.skillsTools = "Skills tools  is required!"
    // }

    // if (!values.primaryRole) {
    //   errors.primaryRole = "Primary Role  is required!"
    // }

    // if (!values.abountRole) {
    //   errors.abountRole = "About Rolee  is required!"
    // }

    return errors;
  }
  function handleCountryChange(e) {
    console.log("selected country", e)
    //reset states & cities, once changed selected country
    setSelectedCountryOption(e)
    setSelectedStateOption(null);
    setSelectedCityOption(null)
    setStatesList([])
    setCitiesList([])
    defaultuserDetails.stateId = null;
    defaultuserDetails.cityId = null;
    // formData.countryId = e.id;
    setUserDetails((pValue) => {
      console.log(pValue)
      return {
        ...pValue,
        ["countryId"]: e.countryId
      }
    })
    // handleChange(e)
    httpsignup
      .get("/userprofileLMS/getUserStates?countryId=" + e.countryId)
      .then((res) => {
        console.log("user states", res)
        setStatesList(res.data)
      })
  }
  // triggered after changing the state
  // reset selected city
  // fetch cities list based on selected state
  function handleStateChange(e) {
    console.log("selected state", e)
    //reset cities, once changed selected country
    setSelectedStateOption(e)
    setSelectedCityOption(null)
    setCitiesList([])
    // formData.stateId = e.id;
    defaultuserDetails.cityId = null;
    setUserDetails((pValue) => {
      console.log(pValue)
      return {
        ...pValue,
        ["stateId"]: e.stateId
      }
    })
    // handleChange(e)
    httpsignup
      .get("/userprofileLMS/getUserCities?stateId=" + e.stateId)
      .then((res) => {
        console.log("user cities", res)
        setCitiesList(res.data)
      })
  }
  // triggered after changing the city
  function handleCityChange(e) {
    console.log("selected city : ", e)
    setSelectedCityOption(e)
    // formData.cityId = e.id;
    setUserDetails((pValue) => {
      console.log(pValue)
      return {
        ...pValue,
        ["cityId"]: e.cityId
      }
    })
  }

  // react-select custom styles and events
  const colourStyles = {
    control: (styles) => ({
      ...styles,
      // border: `1px solid #ced4da`, 
      // boxShadow: `0 0 0 1px`, 
      // ':hover' : {
      //   // boxShadow: `0 0 0 1px `,  
      //   backgroundColor : "blue"
      // } 
    }),
    option: (provided, state) => ({
      ...provided,
      fontWeight: state.isSelected ? "bold" : "normal",
      color: state.isFocused || state.isSelected ? 'white' : 'black',
      backgroundColor: state.isFocused || state.isSelected ? 'blue' : 'transparent',
      fontSize: "14px",
      paddingTop: "4px",
      paddingBottom: "4px",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: state.data.color,
      fontSize: state.selectProps.myFontSize
    })
  };

  const [checked, setChecked] = React.useState(false);

  const handleCheckChange = () => {
   
    setChecked(!checked);
  };

  return (
    <div className="profile-popup-container">
      <div className="profile-popup">
        <div style={{ textAlign: "right", color: "black", cursor: "pointer" }} class="mb-3"> <div onClick={onClose}>X</div></div>
        <h2 class="d-flex common_main_text_highlight">{cardHeader}</h2>
        <p class="common_main_text_normal">Let’s get started with some personal details</p>
        <p class="mt-2 common_main_text_normal">Your online profiles</p>
        <center><p style={{ font: 18, fontWeight: 700, color: "red" }}>{errorMessage}</p></center>
        <Form id="create-User-Form">
          <Row className="pb-3">
            <Col className="col-lg-4 col-12 d-flex flex-column align-items-start">
              <div className="d-flex required align-items-center pb-1" ><img class="img-fluid"
                src={require("../../images/work_icon.png")}
                alt="Image not found"
                className="more"
                width={20}

              /> <h6 style={{ fontWeight: 600, marginLeft: 5 }}>Job Title</h6></div>
              <Form.Control
                className={formErros.jobTitle ? "borderred" : ""}
                placeholder="Enter job title"
                autoComplete="off"
                name='jobTitle'
                defaultValue={defaultuserDetails.jobTitle}
                onChange={handleChange}
              />
              <p className="red font12">{formErros.jobTitle}</p>
            </Col>
            <Col className="col-lg-4 col-12 d-flex flex-column align-items-start">
              <div className="d-flex required align-items-center pb-1">
                <img class="img-fluid"
                  src={require("../../images/company_new_icon.png")}
                  alt="Image not found"
                  className="more"
                  width={20}

                />
                <h6 style={{ fontWeight: 600, marginLeft: 5 }}>Company Name</h6></div>
              <Form.Control
                className={formErros.companyName ? "borderred" : ""}
                placeholder="Enter Company Name"
                autoComplete="off"
                name='companyName'
                defaultValue={defaultuserDetails.companyName}
                onChange={handleChange}
              />
              <p className="red font12">{formErros.companyName}</p>
            </Col>

            <Col className="col-lg-4 col-12 d-flex flex-column align-items-start">
              <div className="d-flex required align-items-center pb-1">
                <img class="img-fluid"
                  src={require("../../images/website.png")}
                  alt="Image not found"
                  className="more"
                  width={20}

                />
                <h6 style={{ fontWeight: 600, marginLeft: 5 }}>Company Website</h6></div>
              <Form.Control
                className={formErros.companyWebsite ? "borderred" : ""}
                placeholder="Enter Company Website"
                autoComplete="off"
                name='companyWebsite'
                defaultValue={defaultuserDetails.companyWebsite}
                onChange={handleChange}
              />
              <p className="red font12">{formErros.companyWebsite}</p>
            </Col>

          </Row>
          <Row className="pb-3">


            <Col className="col-lg-4 col-12 d-flex flex-column align-items-start mt-lg-0 mt-3">
              <div className="d-flex required align-items-center pb-1">
                <img class="img-fluid"
                  src={require("../../images/work_type_icon.png")}
                  alt="Image not found"
                  className="more"
                  width={20}

                />
                <h6 style={{ fontWeight: 600, marginLeft: 5 }}>Work Type</h6></div>
              <Form.Control
                className={formErros.workType ? "borderred" : ""}
                placeholder="Enter work type"
                autoComplete="off"
                name='workType'
                defaultValue={defaultuserDetails.workType}
                onChange={handleChange}
              />
              <p className="red font12">{formErros.workType}</p>
            </Col>

            <Col className="col-lg-4 col-12 d-flex flex-column align-items-start">
              <div className="d-flex required align-items-center pb-1">

                <h6 style={{ fontWeight: 600, marginLeft: 5 }}>Country</h6></div>
              {/* <Form.Control
                        className={formErros.country ? "borderred" : ""}
                        placeholder="Enter Country"
                        autoComplete="off"
                        name='country'
                        defaultValue={defaultuserDetails.country}
                        onChange={handleChange}
                      />
                      <p className="red font12">{formErros.country}</p> */}

              <Select
                value={selectedCountryOption}
                onChange={handleCountryChange}
                placeholder="Select Country"
                className="multiselectdropdown"
                classNamePrefix="react-select"
                styles={colourStyles}
                getOptionLabel={option =>
                  `${option.countryName}`
                }
                options={countriesList}
              />
              <p className="red font12">{formErros.countryId}</p>
            </Col>

            <Col className="col-lg-4 col-12 d-flex flex-column align-items-start mt-lg-0 mt-3">
              <div className="d-flex required align-items-center pb-1">

                <h6 style={{ fontWeight: 600, marginLeft: 5 }}>State</h6></div>
              <Select
                value={selectedStateOption}
                onChange={handleStateChange}
                placeholder="Select State"
                className="multiselectdropdown"
                classNamePrefix="react-select"
                styles={colourStyles}
                getOptionLabel={option =>
                  `${option.stateName}`
                }
                options={statesList}
              />
              <p className="red font12">{formErros.stateId}</p>
            </Col>
          </Row>
          <Row class="pb-3">
            <Col className="col-lg-4 col-12 d-flex flex-column align-items-start mt-lg-0 mt-3">
              <div className="d-flex required align-items-center pb-1">

                <h6 style={{ fontWeight: 600, marginLeft: 5 }}>City</h6></div>
              <Select
                value={selectedCityOption}
                onChange={handleCityChange}
                placeholder="Select City"
                className="multiselectdropdown"
                classNamePrefix="react-select"
                styles={colourStyles}
                getOptionLabel={option =>
                  `${option.cityName}`
                }
                options={citiesList}
              />
              <p className="red font12">{formErros.cityId}</p>
            </Col>
            <Col className="col-lg-4 col-12 d-flex flex-column align-items-start">
              <div className="d-flex required align-items-center pb-1">
                <img class="img-fluid"
                  src={require("../../images/skills_icon.png")}
                  alt="Image not found"
                  className="more"
                  width={20}

                />
                <h6 style={{ fontWeight: 600, marginLeft: 5 }}>Skills, Tools, Role etc.</h6></div>
              <Form.Control
                className={formErros.skillsTools ? "borderred" : ""}
                placeholder="Enter skills tools role etc."
                autoComplete="off"
                name='skillsTools'
                defaultValue={defaultuserDetails.skillsTools}
                onChange={handleChange}
              />
              <p className="red font12">{formErros.skillsTools}</p>
            </Col>

            <Col className="col-lg-4 col-12 d-flex flex-column align-items-start mt-lg-0 mt-3">
              <div className="d-flex required align-items-center pb-1">

                <h6 style={{ fontWeight: 600, marginLeft: 5 }}>Your primary Role</h6></div>
              <Form.Control
                className={formErros.primaryRole ? "borderred" : ""}
                placeholder="Enter Primary role"
                autoComplete="off"
                name='primaryRole'
                defaultValue={defaultuserDetails.primaryRole}
                onChange={handleChange}
              />
              <p className="red font12">{formErros.primaryRole}</p>
            </Col>
          </Row>


          <Row className="mt-3">



            <Col className="col-lg-4 col-12 d-flex flex-column align-items-start mt-lg-0 mt-3">
              <div className="d-flex required"><h6 style={{ fontWeight: 600, marginLeft: 5 }}>Start Date</h6></div>
              <DatePicker selected={startDate} onChange={(sdate) => setStartDate(sdate)} dateFormat="MM/dd/yyyy" />
            </Col>

            <Col className="col-lg-4 col-12 d-flex flex-column align-items-start mt-lg-0 mt-3">

              <div ><h6 style={{ fontWeight: 600, marginLeft: 5 }} className="d-flex mt-4 align-items-center"><input class="form-check-input" type="checkbox" id="check1" name="option1" value="something" checked={!checked}
                onChange={handleCheckChange} />
                <label class="form-check-label mx-3">Currently Working</label></h6></div>

            </Col>

           {checked && <Col className="col-lg-4 col-12 d-flex flex-column align-items-start mt-lg-0 mt-3" >
              <div className="d-flex required"><h6 style={{ fontWeight: 600, marginLeft: 5 }}>End Date</h6></div>
              <DatePicker selected={endDate} onChange={(edate) => setEndDate(edate)} dateFormat="MM/dd/yyyy" disabled={!checked} />
            </Col>}

          </Row>

          <Row className="mt-3">
            <Col className="col-lg-8 col-12 d-flex flex-column align-items-start">
              <div className="d-flex required align-items-center pb-1">

                <h6 style={{ fontWeight: 600, marginLeft: 5 }}>Work Description</h6></div>
              <Form.Control as="textarea" rows={3}
                className={formErros.abountRole ? "borderred" : ""}
                placeholder="Enter About role"
                autoComplete="off"
                name='abountRole'
                defaultValue={defaultuserDetails.abountRole}
                onChange={handleChange}
              />
              <p className="red font12">{formErros.abountRole}</p>
            </Col>






          </Row>

          <div>
            <Row >

              <Col className="col-lg-2 col-12 d-flex flex-column align-items-start mt-lg-0 mt-3">
                <button disabled={disable} className={disable ? "profile-buttons-update mt-3 grey" : "profile-buttons-update mt-3 mb-5 common_main_button"} onClick={SubmitFinalData}>
                  {cardButton}
                </button>
              </Col>


            </Row>
          </div>
        </Form>
        {/* <div class="activityButton" onClick={allcourses} style={{width: "100%", textAlign:"center"}}>See More Courses</div> */}

      </div>
    </div>
  )
}

export default AssignmentsList;
