import React, { useState, useEffect } from "react";
import { BsArrowUpRight } from "react-icons/bs";
import First from "../../images/01- Choose Project From Wide Options.png";
import Second from "../../images/02-Start Coding Collaborate with Peers.png";
import Third from "../../images/03-Chat with Peers and Mentors.png";
import Fourth from "../../images/04-Deploy Your Project and Showcase.png";

import "../../styles/ProjectBuilder.css";
import { Link } from "react-router-dom";

const MobileProjectComponent = ({
  data,
  activeTab,
  handleChangeTab,
  progress,
}) => (
  <div className="container mt-2">
    <div className="row">
      <div className="col-md-12 col-lg-3 mb-5 d-flex justify-content-center align-items-center">
        <div className="row d-flex justify-content-center align-items-center">
          {data.map((tab, index) => (
            <div key={index} className="col-lg-12 mb-4">
              <div
                className={`btn btn-primary ${
                  activeTab === index ? "active" : ""
                }`}
                onClick={() => handleChangeTab(index)}
                style={{
                  width: "100%",
                  maxWidth: "450px",
                  fontSize: "18px",
                  padding: "25px",
                  position: "relative",
                  cursor: "pointer",
                  backgroundColor:
                    activeTab === index ? "black" : "rgb(32,33,37)",
                  overflow: "hidden",
                }}
              >
                {tab.content}
                {activeTab === index && (
                  <div
                    className="progress"
                    style={{
                      position: "absolute",
                      bottom: "0",
                      left: 0,
                      width: "100%",
                      height: "8px",
                      zIndex: 1,
                    }}
                  >
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{
                        width: `${progress}%`,
                        backgroundColor: "white",
                        height: "100%",
                      }}
                      aria-valuenow={progress}
                      aria-valuemin="0"
                      aria-valuemax="100"
                    />
                  </div>
                )}
              </div>
              {activeTab === index && (
                <div className="mt-3 text-center">
                  <img
                    alt="image"
                    src={tab.image}
                    style={{ maxWidth: "100%", height: "auto" }}
                  />
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  </div>
);

const DesktopProjectComponent = ({
  data,
  activeTab,
  handleChangeTab,
  imageSrc,
  progress,
}) => (
  <div className="container mt-2">
    <div className="row">
      <div className="col-md-12 col-lg-3 mb-5 d-flex justify-content-center align-items-center">
        <div className="row d-flex justify-content-center align-items-center">
          {data.map((tab, index) => (
            <div key={index} className="col-lg-7 mb-4">
              <div
                className={`btn btn-primary ${
                  activeTab === index ? "active" : ""
                }`}
                onClick={() => handleChangeTab(index)}
                style={{
                  width: "450px",
                  height: "auto",
                  fontSize: "18px",
                  padding: "25px",
                  position: "relative",
                  cursor: "pointer",
                  backgroundColor:
                    activeTab === index ? "black" : "rgb(32,33,37)",
                }}
              >
                {tab.content}
                {activeTab === index && (
                  <div
                    className="progress"
                    style={{
                      position: "absolute",
                      bottom: 0,
                      left: 0,
                      width: "100%",
                      height: "8px",
                    }}
                  >
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{
                        width: `${progress}%`,
                        backgroundColor: "white",
                        height: "100%",
                      }}
                      aria-valuenow={progress}
                      aria-valuemin="0"
                      aria-valuemax="100"
                    />
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="col-md-12 col-lg-8 mb-5 d-flex justify-content-center align-items-center">
        <div className="row">
          <div className="col-lg-12 offset-lg-5">
            <img
              alt="image"
              src={imageSrc}
              style={{ maxWidth: "100%", height: "400px" }}
            />
          </div>
        </div>
      </div>
    </div>
  </div>
);

const Index = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [progress, setProgress] = useState(0);
  const [imageSrc, setImageSrc] = useState(First);

  const data = [
    {
      title: "Tab 1",
      content: "Choose Project From Wide Options",
      image: First,
    },
    {
      title: "Tab 2",
      content: "Start Coding Collaborate with Peers",
      image: Second,
    },
    { title: "Tab 3", content: "Chat with Peers and Mentors", image: Third },
    {
      title: "Tab 4",
      content: "Deploy Your Project and Showcase",
      image: Fourth,
    },
  ];

  const tabDuration = 10000;

  useEffect(() => {
    const stepsPerSecond = 50;
    const steps = Math.ceil((tabDuration / 1000) * stepsPerSecond);
    const increment = 100 / steps;

    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress < 100) {
          return Math.min(prevProgress + increment, 100);
        } else {
          clearInterval(interval);
          changeTab();
          return 0;
        }
      });
    }, 1000 / stepsPerSecond);

    return () => clearInterval(interval);
  }, [activeTab, tabDuration]);

  const changeTab = () => {
    setActiveTab((prevTab) => (prevTab + 1) % data.length);
    setImageSrc(data[(activeTab + 1) % data.length].image);
    setProgress(0);
  };

  const handleChangeTab = (index) => {
    setActiveTab(index);
    setImageSrc(data[index].image);
    setProgress(0);
  };

  return (
    <div
      className=""
      style={{ backgroundColor: "rgba(39, 40, 44, 1)", minHeight: "" }}
    >
      <div className="container py-5">
        <div
          className="row d-flex justify-content-center"
          style={{ position: "relative" }}
        >
          <div className="col-lg-12 text-center text-white">
            <h1 className="font-weight-bold mb-4 mt-3 transformheading">
              Transform Learning into Action with Our Project-Based Learning
              Platform
            </h1>
            <p className="lead mb-4 d-none d-md-block transform-para">
              Turn theory into practice with our Project-Based Learning
              platform, where learning transforms into actionable projects.
            </p>
            <button className="btn btn-primary btn-lg mt-2 start-building-projects">
              Start Building Projects
            </button>
          </div>
          <div
            className="col-lg-auto text-center text-lg-right"
            style={{ position: "absolute", right: "0", top: "30px" }}
          >
            <Link
              to="/sign-up"
              className="text-blue d-inline-block d-none d-md-block"
              href="#"
              style={{
                textDecoration: "underline",
                color: "rgb(16,107,221)",
                fontSize: "15px",
              
              }}
            >
              Know More
              <BsArrowUpRight />
            </Link>
          </div>
        </div>
      </div>
      {/* Render mobile or desktop version based on screen size */}
      {window.matchMedia("(max-width: 768px)").matches ? (
        <MobileProjectComponent
          data={data}
          activeTab={activeTab}
          handleChangeTab={handleChangeTab}
          progress={progress}
        />
      ) : (
        <DesktopProjectComponent
          data={data}
          activeTab={activeTab}
          handleChangeTab={handleChangeTab}
          imageSrc={imageSrc}
          progress={progress}
        />
      )}
    </div>
  );
};

export default Index;
