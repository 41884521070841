import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const SkeletonSlotBooking = () => {
  return (
    <div className="container" style={{display:"flex",gap:"30px",margin:"100px auto",width:"100%"}}>
      <div className="s-b-p-s-l">
        <Skeleton height={500} width={800} />
      </div>
      <div className="s-b-p-s-l">
        <Skeleton height={600} width={400} />
      </div>
    </div>
  );
};

export default SkeletonSlotBooking;
