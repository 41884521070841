import React, { useEffect } from 'react'
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useState } from "react";
import 'react-image-crop/dist/ReactCrop.css'
import httpsignup from '../js/http-lms_userprofile';
import httplmssignup from '../js/http-lms_userprofile_user';
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select'
import { useNavigate } from "react-router-dom";
import useRazorpay from "react-razorpay";
import { useCallback } from "react";

const RechargePopup = ({ onClose, refreshPage }) => {

    const [defaultuserDetails, setUserDetails] = useState({})
    //form data
    const [errorMessage, setErrorMessage] = useState("");
    const [formErros, setFormErrors] = useState({});
    const [disable, setDisable] = useState(true);

    const [isSubmit, setIsSubmit] = useState(false);
    const [gender, setGender] = useState();
    const [checkUserExist, setCheckUserExist] = useState("");
    const [rechargeList, setRechargeList] = useState([])
    const [payment, setPayment] = useState(false)
    const [rechargeCalc, setRechargeCalc] = useState({})
    const [rechargeIndex, setRechargeIndex] = useState(null)
    const [wallet, setWallet] = useState(0);
    const navigate = useNavigate()

    useEffect(() => {
        getLoggedInUserDetails()
        getAllRecharges()

    }, [])
    const getLoggedInUserDetails = async () => {

        httpsignup.get(`/skillsquadUserprofileLMS/getSkillsquadUserProfileBySignUpId?signUpId=${sessionStorage.getItem('userId')}`)
            .then((response) => {
                console.log("INDETAILS : " + JSON.stringify(response.data))
                sessionStorage.setItem("coursePageName", response.data.userName)
                sessionStorage.setItem("coursePageEmail", response.data.userEmail)
                sessionStorage.setItem("coursePageMobile", response.data.mobileNumber)
                setUserDetails(response.data)
                if(response.data.eWalletAmount === null){
                    setWallet(0);
                  }else{setWallet(response.data.eWalletAmount);}

            }).catch((err) => {
                console.log(err)
            })
    }



    function getAllRecharges() {
        httpsignup.get(`/dashBoardLMS/getAllRecharges`)
            .then((response) => {
                console.log("INDETAILS : " + JSON.stringify(response.data))
                setRechargeList(response.data)

            }).catch((err) => {
                console.log(err)
            })
    }

    function getRechargeCalculations(id,index) {
        httpsignup.get(`/dashBoardLMS/getRechargeCalculations?rechargeId=${id}`)
            .then((response) => {
                console.log("rechargeCalc : " + JSON.stringify(response.data))
                setRechargeCalc(response.data)
                setRechargeIndex(index);
                setDisable(false);
                sessionStorage.setItem("rechargeAmount", response.data.rechargeAmount);
            }).catch((err) => {
                console.log(err)
            })
    }

function paymentPage(){
    setPayment(true)
}

const [Razorpay] = useRazorpay();




    const handlePayment = useCallback ((payAmount) => {
        let totalAmount = rechargeCalc.amount + rechargeCalc.extra;
        
       // httpsignup.post(`/dashBoardLMS/web?amount=${payAmount}&currency=INR&auth=cnpwX2xpdmVfc2NQV296M29PUjZkSWQ6STZ3YW5QTVZVU0I5bjlxOUJ3RmtTZVhh`)
       httpsignup.post(`/dashBoardLMS/web?amount=${payAmount}&currency=INR&auth=cnpwX3Rlc3RfNVIxNzJVeXhRODVGQnY6V1BkRXNoQ2R2MlExYWg5dTRNWXNwVHJl`)
       .then((response) => {
            const options: RazorpayOptions = {
               // key: "rzp_live_scPWoz3oOR6dId",
               key: "rzp_test_5R172UyxQ85FBv",
                amount: parseInt(payAmount) * 100,
                currency: "INR",
                name: "Skillsquad",
                description: "Test Transaction",
                image: require("../images/skillsquad_logo_fav_png.png"),
                order_id: response.data.orderId, 
                handler: (res) => {
                    console.log("HHHH : " + JSON.stringify(res));
                    httpsignup.put(`/dashBoardLMS/updateEwallet?signUpId=${sessionStorage.getItem('userId')}&rechargeAmount=${sessionStorage.getItem("rechargeAmount")}`)
                        .then((response) => {
                            refreshPage();
    
                            onClose();
                        }).catch((err) => {
                            console.log(err)
                        })
    
                },
                prefill: {
                    // sessionStorage.getItem("coursePageName")
                    // sessionStorage.getItem("coursePageEmail")
                    // sessionStorage.getItem("coursePageMobile")
                    name: sessionStorage.getItem("coursePageName"),
                    email: sessionStorage.getItem("coursePageEmail"),
                    contact: sessionStorage.getItem("coursePageMobile"),
                },
                notes: {
                    address: "Razorpay Corporate Office",
                },
                theme: {
                    color: "#5627ff",
                },
            };
    
            const rzpay = new Razorpay(options);
            rzpay.open();
           
        }).catch((err) => {
            console.log(err)
        })
        console.log(JSON.stringify(payAmount))
    
       
    }, [Razorpay]);


    return (
        <div className="completeprofile-popup-container">
              { payment ?<div><div style={{  marginTop: "2%" }}><center><h1>Payment Details</h1></center></div> <div className="recharge-popup" style={{  marginTop: "2%" }}>
              <div class="col mb-4" style={{ textAlign: 'right' }}>
              <div style={{ textAlign: "right", color: "black", cursor: "pointer", fontWeight: 900 }} class="mb-2"> <div onClick={onClose}><img src={require("../images/close_button_icon.png")} alt="close" width={30} style={{borderRadius: 50}}/></div></div>
            </div>
<h3 class="d-flex">Billing Plan</h3>
<div style={{textAlign:"center",border:"2px solid #5627FF",backgroundColor:"#F4F8FF",padding: "2%",borderRadius: 10}}><div style={{fontSize: 12, color:"var(--Gray-600, #6E7485)"}}>Recharge</div><div style={{fontWeight: 600}}>₹ {rechargeCalc.amount}</div></div>
<hr></hr>
<h3 class="d-flex">Order Summary</h3>
<div class="row" style={{fontSize:12}}>
    <div class="col" style={{textAlign:"left",color:"var(--Gray-600, #6E7485)"}}>Subtotal</div>
    <div class="col" style={{textAlign:"right"}}>₹ {rechargeCalc.amount}/-</div>
</div>
<div class="row" style={{fontSize:12}}>
    <div class="col" style={{textAlign:"left",color:"var(--Gray-600, #6E7485)"}}>GST @18%</div>
    <div class="col" style={{textAlign:"right"}}>₹ {rechargeCalc.gst}/-</div>
</div>
<hr></hr>
<div class="row mb-2">
    <div class="col" style={{textAlign:"left",fontSize: 12}}>Total:</div>
    <div class="col" style={{textAlign:"right",fontWeight: 600, fontSize: 18}}>₹ {rechargeCalc.total} INR</div>
</div>
<div class="d-flex align-items-center" style={{textAlign:"center",border:"1px solid #5627FF",backgroundColor:"#F1EDFF",padding: "2%",borderRadius: 10}}><div><img src={require("../images/money_icon.png")} alt="" /></div><div><div style={{fontSize: 12,color:"var(--Gray-600, #6E7485)",textAlign: "left"}}>{rechargeCalc.extraPercentage}% Extra</div><div style={{fontWeight: 600}}>₹ {rechargeCalc.extra} cashback in wallet after recharge</div></div></div>
<button  className="profile-buttons-update mt-3 mb-2 UpdatedMainBGColor mx-2" onClick={() =>  handlePayment(rechargeCalc.total)}>
                            Complete Payment
                        </button>
</div> </div>: 
            <div style={{ width: "70%", marginTop: "2%", textAlign: "center", backgroundColor: "transparent" }}>
                <h1>Add Money to Wallet</h1>

                <h3>Available Balance : ₹ {wallet}</h3>
                <center><div class="row">
                    {rechargeList.map((post, i) => {
                        return (

                            <div class={rechargeIndex === i ? "col-2 mt-2 mx-2 recharge-select" : "col-2 mt-2 mx-2 recharge-list" }  onClick={() => getRechargeCalculations(post.rechargeId,i)}><div>{post.bestSellerFlag === "Y" ? <div style={{ backgroundColor: "#FFAB25", color: "white", fontSize: 12, margin: "0px 30px 0px 30px", borderBottomLeftRadius: 10, borderBottomRightRadius: 10 }}>Best Seller</div> : <br />}<div style={{ padding: "10%", fontSize: 18, fontWeight: 600 }}>₹ {post.amount}</div><div style={{ color: "#5627FF", backgroundColor: "rgba(110, 93, 231, 0.10)", fontSize: 12, bottom: 0 }}>Get Extra ₹{post.extra}</div></div></div>


                        )
                    })}
                </div></center>


                <div>
                    <center>
                        <button className="profile-buttons-update mt-3 mb-2 UpdatedMainBGColor mx-2" onClick={onClose} >
                            Cancel
                        </button>
                        <button disable={disable} className={disable ? "profile-buttons-update mt-3 mb-2 grey mx-2" : "profile-buttons-update mt-3 mb-2 UpdatedMainBGColor mx-2"} onClick={paymentPage}>
                            Proceed
                        </button>

                    </center>
                </div>
                {/* <div class="activityButton" onClick={allcourses} style={{width: "100%", textAlign:"center"}}>See More Courses</div> */}

            </div>
          }
        </div>
    )
}

export default RechargePopup;
