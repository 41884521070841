import React from "react";
import SkillshareLogo from "../../images/skillsquadnew_logo.png";

// import "../styles/bnavbar.css";
import "../../styles/NewHeader.css";
import { FiMenu } from "react-icons/fi";
import { RiBook2Line } from "react-icons/ri";
import { GoPeople } from "react-icons/go";
import { GiVideoConference } from "react-icons/gi";
import { IoCodeSlashOutline } from "react-icons/io5";
import { IoBriefcaseOutline } from "react-icons/io5";
import { LiaChalkboardTeacherSolid } from "react-icons/lia";
import { BsArrowUpRight } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";

export const NewHeader = () => {
  const data = [
    { id: 10, title: "Courses", icon: <RiBook2Line />, url: "/course" },

    { id: 20, title: "Projects", icon: <IoCodeSlashOutline />, url: "/projects" },

  ];

  const navigate = useNavigate();
  const [cId, setCId] = useState(0)
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  function loginPage() {
    navigate("/sign-in")
  }

  function signUpPage() {
    navigate("/sign-up")
  }

  function projectPage(url) {
    console.log("UUU : " + url)
    if (url != null) {
      navigate(url)
    }

  }

  function navtabHighlight(id) {
    console.log("IDDD : " + id)
    setCId(id)
  }

  function homePage() {
    setActivetab("");
    navigate("/")
  }


  const [activeTab, setActivetab] = useState(0);


  // const handleSectionChange = (tab) => {
  //   console.log("event triggered", tab);
  //   setActivetab(tab);
  //  console.log("after setting the tab",activeTab)
  // }
  function handleSectionChange(id){
    setActivetab(id);
  }


  return (
    <header>
      <nav className="navbar navbar-expand-lg bg-white mt-3 mb-3">
        <div className="container">
          <div className="navbar-brand" onClick={homePage} style={{ cursor: "pointer" }}>
            <img
              src={SkillshareLogo}
              alt="Logo"
              className="d-inline-block align-text-top"
              style={{
                width: "auto",
                height: "40px",
              }}
            />
          </div>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <FiMenu />
          </button>
          <div
            className="collapse navbar-collapse justify-content-between"
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav" style={{ margin: "left" }}>

              {data.map((item, index) => (
                <Link to={item.url} >
                  <li
                    onClick={() => handleSectionChange(item.id)}
                    className={`above-login ${activeTab === item.id ? "navbar-hover" : ""}`}
                    key={index}
                    
                    
                  >
                    <div
                    

                    

                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        // justifyContent: 'center',
                        alignItems: "center",
                      color:"black"
                      }}
                    >
                      <span style={{ marginRight: "5px", fontSize: "20px" }}>
                        {item.icon}
                      </span>
                      <span>{item.title}</span>

                    </div>



                  </li>
                </Link>
              ))}
              {/* <div className="d-flex">
                <Link to="/allCourse" onClick={() => handleSectionChange("allCourse")} className={activeTab === "allCourse" ? "bg-primary" : ""}>Learn</Link>
              </div> */}
            </ul>

            <div className="navbar-nav d-flex align-items-center header-login-button" >
              {" "}

              <div
                className="navbar-login-button logindiv"

                onClick={loginPage} >
                Login
              </div>

              <button class="login-button-nav logindiv" onClick={signUpPage} >
                Sign up
              </button>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};
