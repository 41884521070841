import React, { useEffect, useRef } from 'react'
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useState } from "react";
import 'react-image-crop/dist/ReactCrop.css'
import httpsignup from '../js/http-lms_userprofile';
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select'
import attch from '../images/file_attach.svg';


const CommunityPopup = ({ onClose, profileId, profileData }) => {

  const [selectedCommunityTitleOption, setSelectedCommunityTitleOption] = useState(null);
  const [selectedHastTagOption, setSelectedHashTagOption] = useState(null);
  const [defaultDetails, setDetails] = useState({})
  const [communityTitleList, setCommunityTitleList] = useState([])
  const [communityHashtagList, setCommunityHashtagList] = useState([])
  const inputFile = useRef(null)
  const [selectedFile, setSelectedFile] = useState(null)
  const [selectedFileName, setSelectedFileName] = useState(null)
  const [textCount, setTextCount] = useState(0)


  useEffect(() => {
    getCommunityTitles()
  }, [])
  const getCommunityTitles = async () => {
    httpsignup.get(`/skillsquadUserprofileLMS/getComunityTitle`)
      .then((response) => {
        console.log("COMUNITY TITLES : " + JSON.stringify(response.data))
        setCommunityTitleList(response.data)
      }).catch((err) => {
        console.log(err)
      })
  }

  function onAssignmentFileChanged(event) {
    event.preventDefault();
    console.log(event)
    setSelectedFile(event.target.files[0])
    setSelectedFileName(event.target.files[0].name)
  }

  function chooseFilesopen(e) {
    e.preventDefault();
    document.getElementById("postfileUpload").click()
  }
  function savePost(e) {
    e.preventDefault();
    console.log("SAVE POST : " + JSON.stringify(defaultDetails))
    defaultDetails.profileId = profileId;
    let fd = new FormData()

    // console.log("final obj : ", obj)
    // console.log("final file : ", selectedAssignmentFile)
    var pblob = new Blob([JSON.stringify(defaultDetails)], { type: "application/json" })
    fd.append("posts", pblob)
    fd.append("attachmentFiles", selectedFile)



    httpsignup.post(`/skillsquadUserprofileLMS/saveCommunityPosts`, fd, {
      headers: {
        "Content-Type": "multipart/form-data",
        "Accept": "*/*"
      }
    })
      .then((response) => {
        console.log("COMUNITY TITLES : " + JSON.stringify(response.data))
        onClose()
      }).catch((err) => {
        console.log(err)
      })
  }


  function handleCommunityTitleChange(e) {


    console.log("selected degree", e)
    console.log("selected default", e)
    //reset states & cities, once changed selected country
    setSelectedCommunityTitleOption(e)
    defaultDetails.titleId = e.titleId;

    httpsignup.get(`/skillsquadUserprofileLMS/getCommunityHashtag?titleId=${defaultDetails.titleId}`)
      .then((response) => {
        console.log("COMUNITY Hashtag: " + JSON.stringify(response.data))
        let data = [];
        response.data.map(x => {
          data.push({
            value: x.hashtagId,
            label: x.hasttagName

          })
        })
        console.log(data, 'ppppppppppp')
        setCommunityHashtagList(data)
        //setCommunityHashtagList(response.data)
      }).catch((err) => {
        console.log(err)
      })

  }

  function handleHashTagChange(e) {
    console.log("Selected Program details:", e)
    setSelectedHashTagOption(e)
    let p_id = [];
    e.map(x => {
      p_id.push(x.value)
    })
    // formData.batchId = e.value;
    setDetails((pValue) => {
      console.log(pValue)
      return {
        ...pValue,
        ["hashtagIds"]: p_id
      }
    })
  }

  const onButtonClick = (e) => {
    // `current` points to the mounted file input element
    e.preventDefault();
    inputFile.current.click();
  };

  function handleChange(e) {
    // setErrorMessage('')
    setTextCount(e.target.value.length)
    const { name, value } = e.target;
    setDetails((pValue) => {
      console.log(pValue)
      return {
        ...pValue,
        [name]: value
      }
    })
    console.log("after change : ", defaultDetails)
  }

  const colourStyles = {
    control: styles => ({ ...styles, borderRadius:'10px' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {

      return {
        ...styles,
      backgroundColor: isFocused ? "#5627ff" : null,
      color: isFocused ? "white" : "black",
      fontWeight : isFocused ? 600 : 500,

      };
    },

  }

  return (
    <div className="locked-popup-container">
      <div className="locked-popup-card" style={{ width: "40%", padding: "2%" }}>
        <div class="container">
          <div class="row">
            <div class="col">
              <div class="common_main_heading">Create Post</div>
            </div>
            <div class="col">

            </div>
            <div class="col mb-4" style={{ textAlign: 'right' }}>
              <div style={{ textAlign: "right", color: "black", cursor: "pointer", fontWeight: 900 }} class="mb-2"> <div onClick={onClose}><img src={require("../images/close_button_icon.png")} alt="close" width={30} style={{borderRadius: 50}}/></div></div>
            </div>
          </div>
        </div>


        <div class="d-flex align-items-center mb-3"><img referrerPolicy="no-referrer" className='admin-pro-pic my-1 me-2 careerhelp-img' style={{ width: 100, height: 100 }} src={profileData.profilePictureUrl} alt="okay" />
          <div style={{marginLeft: 10}}><div class="common_main_text_highlight mb-2">{profileData.userName}</div>
            <Row className="pb-3">
              <Col className="col-lg-12 col-12 d-flex flex-column align-items-start">
                <Select

                  placeholder="Choose a category"
                  className="multiselectdropdown"
                  classNamePrefix="react-select"
                  value={selectedCommunityTitleOption}
                  onChange={handleCommunityTitleChange}
                  getOptionLabel={option =>
                    `${option.titleName}`
                  }

                  options={communityTitleList}
                  styles={colourStyles}
                />
              </Col>

            </Row>
          </div>
        </div>



        <Form id="create-User-Form">

          {/* <Row className="pb-3">
            <Col className="col d-flex flex-column align-items-start">
              <Form.Control

                placeholder="Add Title"
                autoComplete="off"
                name='postTitle'
                class="border-0"
                defaultValue={defaultDetails.postTitle}
                onChange={handleChange}
                style={{ border: 0 }}
              />
            </Col>
          </Row> */}

          <Row className="pb-3">
            <Col className="col d-flex flex-column align-items-start">
              <Form.Control
                as="textarea"
                placeholder="what do you want to ask about?"
                autoComplete="off"
                name='postDesc'
                class="border-0"
                defaultValue={defaultDetails.postDesc}
                onChange={handleChange}
                style={{ border: 0 }}
                rows="4"
              />
            </Col>

          </Row>


          <Row className="pb-3">
            {/* <Col className="col-lg-4 col-12 d-flex flex-column align-items-start">
              <Select

                placeholder="Choose a category"
                className="multiselectdropdown"
                classNamePrefix="react-select"
                value={selectedCommunityTitleOption}
                onChange={handleCommunityTitleChange}
                getOptionLabel={option =>
                  `${option.titleName}`
                }

                options={communityTitleList}

              />
            </Col> */}
            {/* <Col className="col-lg-4 col-12 d-flex flex-column align-items-start">
              <Select

                placeholder="Choose a hashtag"
                className="multiselectdropdown"
                classNamePrefix="react-select"
                value={selectedHastTagOption}
                onChange={handleHashTagChange}


                options={communityHashtagList}
                isMulti
              />
            </Col> */}
          </Row>

          <Row> <Col className="col" style={{ textAlign: "right" }}>{textCount}/1000</Col></Row>
          <hr></hr>
          <div class="container mt-4">
            <div class="row">
              <div class="col d-flex align-items-center" >
                <img style={{ width: 30, height: 30, marginLeft: 10, cursor: "pointer" }} src={require("../images/add_file.png")} alt="okay" onClick={(e) => { chooseFilesopen(e) }} />
                <span className="font12 col-10 pl-0 pr-0">{selectedFileName}</span>
                <input type="file" className="form-control-file" id={"postfileUpload"} accept="*" onChange={(e) => { onAssignmentFileChanged(e) }} hidden />


              </div>

              <div class="col col-lg-2 d-flex align-items-center">
                {textCount == 0 ? <div class="d-flex align-items-center" style={{ backgroundColor: "#f2f2f2", color: "grey", padding: "5% 20% 5% 20%", borderRadius: 50, cursor: "pointer" }} ><div>Post</div>
                  {/* <img style={{ width: 25, height: 25, marginLeft: 10 }} src={require("../images/post_icon.png")} alt="okay" /> */}

                </div> : <div class="d-flex align-items-center common_main_button"  onClick={savePost}><div>Post</div>
                  {/* <img style={{ width: 25, height: 25, marginLeft: 10 }} src={require("../images/post_icon.png")} alt="okay" /> */}

                </div>}

              </div>
            </div>
          </div>
        </Form>


      </div>
    </div>
  )
}

export default CommunityPopup;
