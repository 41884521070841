import React, { useEffect, useState } from "react";
import "../styles/Pricing.css";

import Sidebar from "../pages/Sidebar";
import { useNavigate } from "react-router-dom";
import httpprograms from "../js/http-lms_programs"

import httpsignup from '../js/http-lms_userprofile';
import httplmssignup from '../js/http-lms_userprofile_user';

import fire from "../images/fire-icon.png";

import arrow from "../images/left-arrow_icon.png"



const Pricing = () => {

    const [data, setData] = useState([])
    const [refresh, setRefresh] = useState(false)
    const [loading, setLoading] = useState(0)
    const [purchased, setPurchased] = useState()

    const [error, setError] = useState()


    const navigate = useNavigate();

    function handleClick(pId) {
        sessionStorage.setItem("programId", pId)
        navigate("/videos");
    }

    function handleClickStart() {

        navigate("/dashboard");
    }

    function buynow() {

        window.open("https://pages.razorpay.com/javabackendselflive-English", "_blank")
    }



    function contactUs() {
        window.open("https://forms.gle/VvXpYkT2FJ9Btnfr8", "_blank")
    }

    useEffect(() => {
        programCards()
        getLoggedInUserDetails()
    }, [])
    useEffect(() => {
        if (refresh) {
            programCards()
        }
    }, [refresh])

    const getLoggedInUserDetails = async () => {
        httpsignup.get(`/userprofileLMS/getSkillsquadUserProfileBySignUpId?signUpId=${sessionStorage.getItem('userId')}`)
            .then((response) => {

                if (response.data.lmsProfileId != null) {
                    httplmssignup.get(`/userprofileLMS/getByProfileId/${response.data.lmsProfileId}`)
                        .then((response) => {

                            httplmssignup.get(`/userprofileLMS/getProgramIdByBatchId?BatchId=${response.data.batchId}`)
                                .then((response) => {

                                    setPurchased(response.data);
                                }).catch((err) => {
                                    console.log(err)
                                })

                        }).catch((err) => {
                            console.log(err)
                        })
                }

            }).catch((err) => {
                console.log(err)
            })
    }
    const programCards = async () => {

        // const response=await axios.get("http://34.199.176.17:13052/programsLMS/getModulesTopicCount")
        httpprograms.get("/programsLMS/getModulesTopicCount")
            .then((response) => {
                console.log("get modules count", JSON.stringify(response.data))
                setData(response.data)
                setLoading(1)
                setRefresh(false)
            }).catch((err) => {
                setLoading(1)
                setError("Bad request")
            })

    }


    return (
        <>
            <Sidebar />

            <div className="jobs-dashboard-container mx-4">

                {/* <Navb /> */}

                <div className="px-md-5 mt160">
                <img src={arrow} width={30} alt="arrow" style={{ cursor: "pointer" }} onClick={handleClickStart} />
                 
                    {/* <button class="pricing-button "><span><FontAwesomeIcon icon={faArrowLeft} /></span></button> */}
                    <div class="pricing-heading mb-2"><div class="common_main_heading">Simple & flexible pricing</div>
                        {/* <center><div class="pricing-heading-sub-heading">your wane cly one, donat for de delightl p.</div></center> */}
                    </div>
                    <section class="section">
                        <div class="container">

                            <div class="row">

                                <div class="col-lg-12">
                                    <div class="row">
                                        <div class="">
                                            <div class="section-title  mb-4 pb-2">
                                                <div className="col-lg-12">
                                                    <div className="row">
                                                        <div id="p-left">
                                                            <div class="col-lg-12 pricing-card">
                                                                <div class="d-flex align-items-center"><div class="common_main_text_highlight">Job Assistance</div><div class="pricing-off common_main_text_tag">80% off</div></div>

                                                                <div id="left"><div id="left">

                                                                    <div class=" common_main_text_normal mt-2">Learn from Industry Experts</div>
                                                                </div><div id="right">
                                                                    </div>
                                                                    <div class="mt-5">
                                                                        <div id="left">
                                                                            <div class="common_main_text_normal mt-5"><div ><div>BATCH START ON</div><div>11th Dec 2023</div></div></div>
                                                                        </div><div id="right"><div class="d-flex align-items-center common_main_text_normal mt-5"><img src={fire} alt="correct" width={25} height={25} />Limited Seats Available</div>
                                                                        </div>
                                                                    </div>
                                                                    <div id="left" class="common_main_heading mt-3">₹6500</div>
                                                                </div>
                                                                <div id="right" class='common_main_text_normal'>                <ul>

                                                                    <li>Self-paced</li>
                                                                    <li>Discord community</li>
                                                                    <li>Resume Templates</li>
                                                                    <li>Placement Assistance</li>
                                                                    <li>Interview guidance</li>
                                                                    <li>Module wise Assignments</li>
                                                                    <li>10+ Major project + Case Studies</li>
                                                                    <li>Weekly Live Sessions</li>
                                                                    <li>Resume Preparation</li>
                                                                    <li>LinkedIn Profile Review</li>
                                                                </ul></div>


                                                                <button class="pricing-buy common_main_text_highlight common_main_button" onClick={buynow}>Buy Now</button>

                                                            </div>
                                                        </div>
                                                        <div id="p-right">
                                                            <div class="col-lg-12 pricing-card">
                                                                <div class="d-flex align-items-center"><div class="common_main_text_highlight">Job Guarantee</div><div class="pricing-off common_main_text_tag">80% off</div></div>

                                                                <div id="left"><div id="left">

                                                                    <div class="common_main_text_normal mt-2">Learn from Industry Experts</div>
                                                                </div><div id="right">
                                                                    </div>
                                                                    <div class="mt-5">
                                                                        <div id="left">
                                                                            <div class="common_main_text_normal mt-5"><div ><div>BATCH START ON</div><div>11th Dec 2023</div></div></div>
                                                                        </div><div id="right"><div class="d-flex align-items-center common_main_text_normal mt-5"><img src={fire} alt="correct" width={25} height={25} />Limited Seats Available</div>
                                                                        </div>
                                                                    </div>
                                                                    <div id="left" class="common_main_heading mt-3">₹30000</div>
                                                                </div>
                                                                <div id="right" class='common_main_text_normal'>

                                                                    <ul>
                                                                        <li style={{ listStyleType: "none" }} class="common_main_text_highlight">Everything from Placement assistance Plus</li>
                                                                        <li>Placement Guarantee</li>
                                                                        <li>Mock Interviews</li>
                                                                        <li>Live Personalized project reviews</li>
                                                                        <li style={{ color: 'transparent' }}></li>
                                                                        <li style={{ color: 'transparent' }}></li>
                                                                        <li style={{ color: 'transparent' }}></li>
                                                                        <li style={{ color: 'transparent' }}></li>

                                                                    </ul></div>


                                                                <button class="pricing-buy mt-4 common_main_button common_main_text_highlight" onClick={contactUs}>Contact us</button>

                                                            </div>
                                                        </div>






                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>


                                </div>

                                {/*Filter section*/}
                            </div>

                        </div>

                    </section>



                </div>


            </div>


        </>
    );
};

export default Pricing;
