import React, { useContext, useState } from 'react'
import EditorContainer from '../screens/Playground/AssignmentEditorContainer'
import InputConsole from '../screens/Playground/InputConsole'
import OutputConsole from '../screens/Playground/OutputConsole'
import Navbar from '../screens/Playground/Navbar'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import { languageMap, PlaygroundContext } from '../context/PlaygroundContext'
import { ModalContext } from '../context/ModalContext'
import Modal from '../components/Modal'
import { Buffer } from 'buffer'
import axios from 'axios'
import httpsignup from '../js/http-lms_userprofile';
import { useEffect } from "react";
import arrow from "../images/left-arrow_icon.png"
import { Navigate, useNavigate } from "react-router-dom";
import Popup from "../components/AssignmentStatusPopup"
const MainContainer = styled.div`
  display: grid;
  margin-top:70px;

  grid-template-columns: ${({ isFullScreen }) => isFullScreen ? '1fr' : '15fr 15fr'};
  min-height: ${({ isFullScreen }) => isFullScreen ? '100vh' : 'calc(100vh - 4.5rem)'};
  @media (max-width: 768px){
    grid-template-columns: 1fr;
  }
`

const Consoles = styled.div`
  display: grid;
  width: 100%;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr;
`

const AssignmentEditor = () => {
    useEffect(() => {
        httpsignup.get(`/api/v1/topicAssignmentLMS/getCodingAssignment?assigmentId=${sessionStorage.getItem("assignmentTopicId")}`)
        .then((response) => {
           console.log("response : " + JSON.stringify(response))
           setAssignmentInfo(response.data)
           setCurrentCode(response.data.code)
        }).catch((err) => {
            console.log(err)
        })
       
    
    }, [])
    const [assignmentInfo, setAssignmentInfo] = useState()

  const { folderId, playgroundId } = useParams()
  const { folders, savePlayground } = useContext(PlaygroundContext)
  const { isOpenModal, openModal, closeModal } = useContext(ModalContext)
  const [currentCode, setCurrentCode] = useState()
  const navigate = useNavigate();
  const [currentOutput, setCurrentOutput] = useState('')
  const [isFullScreen, setIsFullScreen] = useState(false)
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [statusName, setStatusName] = useState('')
  const [finalOutput, setFinalOutput] = useState('')
 
  const openPopup = () => {
    setIsPopupOpen(true);
};

const closePopup = () => {
  setIsPopupOpen(false);
};

  // all logic of the playground
  const saveCode = () => {
    savePlayground(folderId, playgroundId, currentCode, assignmentInfo.language)
  }

 



  const encode = (str) => {
    return Buffer.from(str, "binary").toString("base64")
  }

  const decode = (str) => {
    return Buffer.from(str, 'base64').toString()
  }

  const postSubmission = async (language_id, source_code, stdin) => {
    const options = {
      method: 'POST',
      url: 'https://judge0-ce.p.rapidapi.com/submissions',
      params: { base64_encoded: 'true', fields: '*' },
      headers: {
        'content-type': 'application/json',
        'Content-Type': 'application/json',
        'X-RapidAPI-Key': 'bf2ac34fe5msh6c09329d2bac83ap16906djsn2ea026337141',
        'X-RapidAPI-Host': 'judge0-ce.p.rapidapi.com'
      },
      data: JSON.stringify({
        language_id: language_id,
        source_code: source_code,
        stdin: stdin
      })
    };

    const res = await axios.request(options);
    return res.data.token
  }

  const getOutput = async (token) => {
    // we will make api call here
    const options = {
      method: 'GET',
      url: "https://judge0-ce.p.rapidapi.com/submissions/" + token,
      params: { base64_encoded: 'true', fields: '*' },
      headers: {
        'X-RapidAPI-Key': 'bf2ac34fe5msh6c09329d2bac83ap16906djsn2ea026337141',
        'X-RapidAPI-Host': 'judge0-ce.p.rapidapi.com'
      }
    };

    // call the api
    const res = await axios.request(options);
    if (res.data.status_id <= 2) {
      const res2 = await getOutput(token);
      return res2;
    }
    return res.data;
  }

  const runCode = async () => {
    openModal({
      show: true,
      modalType: 6,
      identifiers: {
        folderId: "",
        cardId: "",
      }
    })
    console.log(JSON.stringify(languageMap))
    const language_id = languageMap[(assignmentInfo.language).toLowerCase()].id;
    const source_code = encode(currentCode);
    const stdin = encode(assignmentInfo.testCase);

    // pass these things to Create Submissions
    const token = await postSubmission(language_id, source_code, stdin);

    // get the output
    const res = await getOutput(token);
    const status_name = res.status.description;
    const decoded_output = decode(res.stdout ? res.stdout : '');
    const decoded_compile_output = decode(res.compile_output ? res.compile_output : '');
    const decoded_error = decode(res.stderr ? res.stderr : '');

    let final_output = '';
    if (res.status_id !== 3) {
      // our code have some error
      if (decoded_compile_output === "") {
        final_output = decoded_error;
      }
      else {
        final_output = decoded_compile_output;
      }
    }
    else {
      final_output = decoded_output;
    }
    setCurrentOutput(status_name + "\n\n" + final_output);
    setStatusName(status_name)
    setFinalOutput(final_output)
    console.log("STATUS ANE : " + status_name)
    closeModal();
    openPopup();
  }

  const getFile = (e, setState) => {
    const input = e.target;
    if ("files" in input && input.files.length > 0) {
      placeFileContent(input.files[0], setState);
    }
  };

  const placeFileContent = (file, setState) => {
    readFileContent(file)
      .then((content) => {
        setState(content)
      })
      .catch((error) => console.log(error));
  };

  function readFileContent(file) {
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onload = (event) => resolve(event.target.result);
      reader.onerror = (error) => reject(error);
      reader.readAsText(file);
    });
  }

  function dayChallenge(){
   
    if(sessionStorage.getItem("cirriculumTopicId") == 'Yes'){
      navigate("/day-circullum")
    }else{
    navigate("/day-challenge")
    }
  }

  return (
    <div >
        {assignmentInfo != null ? <div><MainContainer isFullScreen={isFullScreen}>
      <div class="px-5">
      <h1 className="ps-md-5 mt-2 mb-4 d-flex align-items-center"><img  src={arrow} width={30} alt="arrow" style={{cursor: "pointer"}} onClick={dayChallenge} /><div style={{marginLeft: 5}}>Problem Statement</div></h1>
      <div class="mt-2"><strong>{assignmentInfo.program}</strong></div>
      <div class="mt-2">
        <h3>Input:</h3></div>
        <div  style={{backgroundColor:"#E2E2E2", padding: 3, borderRadius: 10}}>
        <ul>
            
        {assignmentInfo.input.map((data) => {
        return (  <li>{data}</li>)})}
      
      </ul>
        </div>

        {/* <div class="mt-2">
        <h3>Test Case:</h3></div>
        <div  style={{backgroundColor:"#E2E2E2", padding: 3, borderRadius: 10}}>
        {assignmentInfo.testCase}
        </div>
      
      <div class="mt-2" ><h3>Output:</h3></div>
      <div  style={{backgroundColor:"#E2E2E2", padding: 3, borderRadius: 10}}>
      <ul>
      {assignmentInfo.output.map((data) => {
        return (  <li>{data}</li>)})}
      </ul>
      </div> */}
      <div class="mt-2"><h3>Example:</h3></div>
      <div  style={{backgroundColor:"#E2E2E2", padding: 3, borderRadius: 10}}>
      <ul>
      {assignmentInfo.example.map((data) => {
        return (  <li>{data}</li>)})}
      </ul>
      </div>
      <div class="mt-2"><h3>Note:</h3></div>
      <div  style={{backgroundColor:"#E2E2E2", padding: 3, borderRadius: 10}}>
      <ul>
      {assignmentInfo.note.map((data) => {
        return (  <li>{data}</li>)})}
      </ul>
      </div>
      <div class="mt-2"><h3>Hint:</h3></div>
      <div  style={{backgroundColor:"#E2E2E2", padding: 3, borderRadius: 10}}>
      <ul>
      {assignmentInfo.hint.map((data) => {
        return (  <li>{data}</li>)})}
      </ul>
      </div>
      </div>
        <EditorContainer
          title={assignmentInfo.title}
          currentLanguage={assignmentInfo.language}
          setCurrentLanguage={assignmentInfo.language}
          currentCode={currentCode}
          setCurrentCode={setCurrentCode}
          folderId={folderId}
          playgroundId={playgroundId}
          saveCode={saveCode}
          runCode={runCode}
          getFile={getFile}
          isFullScreen={isFullScreen}
          setIsFullScreen={setIsFullScreen}
          statusName ={statusName}
          finalOutput={finalOutput}
          expectedOutput = {assignmentInfo.output[0]}
        />
        {/* <Consoles>
          <InputConsole
            currentInput={assignmentInfo.testCase}
            setCurrentInput={assignmentInfo.testCase}
            getFile={getFile}
          />
          <OutputConsole
            currentOutput={currentOutput}
          />
        </Consoles> */}
      </MainContainer></div> : <div> No Data Found</div>}
      
      
      {isPopupOpen && <Popup onClose={closePopup} statusName ={statusName} finalOutput={finalOutput} program={assignmentInfo.program} expectedOutput = {assignmentInfo.output[0]}/>}
      {isOpenModal.show && <Modal />}
    </div>
  )
}

export default AssignmentEditor