import React from "react";
import moment from "moment/moment";
import ReactHtmlParser from "html-react-parser";

const IndividualReply = ({profileName,profilePicture,reply,creationId}) => {
  return (
    <div>
      <div class="d-flex flex-column" style={{ position: "relative" }}>
        <div class="d-flex align-items-start">
          <img
            className=""
            style={{ width: "40px", height: "40px",borderRadius:"50%",marginRight:"6px" }}
            src={profilePicture}
            alt="profile"
          />
          <div classname="name-and-timing">
            <span className="reply-user-name">{profileName}</span>

            <span className="reply-user-upload-time">
              {" "}
              {moment(creationId).format(
                "D MMM YYYY HH:MM"
              )}
            </span>
            <div className="individual-discussion-reply">
              {ReactHtmlParser(reply)}
            </div>
          </div>
        </div>
        {/* <div className="show-reply">
          <div className="show-reply-message">
            <img
              src={require("../images/chevrons-up.svg")}
              alt="up and down arrow"
            />
            <span className="show-all-reply-message">Show All Replies (2)</span>
          </div>
          <div className="show-reply-message">
            <img src={require("../images/corner-down-right.svg")} alt="reply" />
            <span className="show-all-reply-message">Reply</span>
          </div>
        </div>
        <div
          className="sub-replies"
          style={{
            position: "absolute",
            left: "10%",
            bottom: "-20px",
            display: "block",
          }}
        ></div> */}
      </div>
    </div>
  );
};

export default IndividualReply;
