import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const OfferSkeleton = () => {
  return (
    <div className="">
      <Skeleton height={40} width={75} />
    </div>
  );
};

export default OfferSkeleton;
