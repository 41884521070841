import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Popup } from 'semantic-ui-react';
import Swal from 'sweetalert2';

import { timeConverter } from '../../utils';
import mytimer from './mytimer.css'

const Countdown = ({ countdownTime, timeOver, setTimeTaken }) => {
  const totalTime = countdownTime * 1000;
  const [timerTime, setTimerTime] = useState(totalTime);
  const { hours, minutes, seconds } = timeConverter(timerTime);

  useEffect(() => {
    const timer = setInterval(() => {
      const newTime = timerTime - 1000;

      if (newTime >= 0) {
        setTimerTime(newTime);
      } else {
        clearInterval(timer);

        Swal.fire({
          icon: 'info',
          title: `Oops! Time's up.`,
          text: 'See how you did!',
          confirmButtonText: 'Check Results',
          timer: 5000,
          willClose: () => timeOver(totalTime - timerTime),
        });
      }
    }, 1000);

    return () => {
      clearInterval(timer);
      setTimeTaken(totalTime - timerTime + 1000);
    };

    // eslint-disable-next-line
  }, [timerTime]);

  return (
    // <Button.Group size="massive" basic floated="right">
    //   <Popup
    //     content="Hours"
    //     trigger={<Button active>{hours}</Button>}
    //     position="bottom left"
    //   />
    //   <Popup
    //     content="Minutes"
    //     trigger={<Button active>{minutes}</Button>}
    //     position="bottom left"
    //   />
    //   <Popup
    //     content="Seconds"
    //     trigger={<Button active>{seconds}</Button>}
    //     position="bottom left"
    //   />
    // </Button.Group>
//     <div class='timer'>
//     <div class='block'>{hours}</div>
//     <span class='separator'>:</span>
//     <div class='block'>{minutes}</div>
//     <span class='separator'>:</span>
//     <div class='block'>{seconds}</div>
// </div>
<div ><div class='timer' >
  <div class='block'>{hours}</div>
  <span class='separator'>:</span>
    <div class='block'>{minutes}</div>
     <span class='separator'>:</span>
     <div class='block'>{seconds}</div>
</div></div>
  );
};

Countdown.propTypes = {
  countdownTime: PropTypes.number.isRequired,
  timeOver: PropTypes.func.isRequired,
  setTimeTaken: PropTypes.func.isRequired,
};

export default Countdown;

/*
import React, { useState } from 'react';

const Quiz = ({ data }) => {
  const [selectedOption, setSelectedOption] = useState(null);

  const handleItemClick = (e, { name }) => {
    setSelectedOption(name);
  };

  return (
    <div>
      <Menu vertical fluid size="massive" pagination='' style={{ border: 'none' }}>
        {data.options.map((option, i) => {
          const decodedOption = he.decode(option);
          const isActive = selectedOption === decodedOption;

          let optionStyle = {
            marginBottom: '20px',
            border: '1px solid',
            borderRadius: '4px',
            padding: '2px',
            color: isActive ? 'rgb(86,39,255)' : '',
            backgroundColor: isActive ? 'rgb(241,238,255)' : 'transparent',
          };

          return (
            <div key={decodedOption} style={{ marginBottom: '20px', border: 'none' }}>
              <Menu.Item 
                name={decodedOption}
                onClick={handleItemClick}
                style={optionStyle}
              >
                <div className='questionnum'>
                  {decodedOption}
                </div>
              </Menu.Item>
            </div>
          );
        })}
      </Menu>
    </div>
  );
};


*/