import React, { useState } from 'react';
import ProgressBar from "react-bootstrap/ProgressBar";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock, faLockOpen, faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { Navigate, useNavigate } from "react-router-dom";
import App from "../components/App";

const Accordion = ({ title, content, totalTask, dayProgress, currentDayProgress, daySummary, generic, hideCircullum, eligible,freeTrail,endFreeTrail }) => {
    const [isActive, setIsActive] = useState(false);


    const navigate = useNavigate();


    //   function getLoggedInUserDetails() {
    //     console.log("TRIGGERED LOGGED")
    //     httpsignup.get(`/skillsquadUserprofileLMS/getSkillsquadUserProfileBySignUpId?signUpId=${sessionStorage.getItem('userId')}`)
    //       .then((response) => {
    //         sessionStorage.setItem("dayProfileId", response.data.lmsProfileId)
    //         sessionStorage.setItem("dayProgramId", sessionStorage.getItem("myCourseProgramId"))
    //         httpsignup.get(`/programsLMS/getScheduleDayChallege?profileId=${sessionStorage.getItem("dayProfileId")}&programId=${sessionStorage.getItem("myCourseProgramId")}`)
    //           .then((response) => {
    //             console.log("UserDetails Day Challeneg : " + JSON.stringify(response.data))
    //             setLoading(false)
    //             setDayChallenge(response.data)
    //             setLastTopicCount(response.data.extraTopicList.length + 2)
    //             setLastAssignmentCount(response.data.extraTopicList.length + 3)
    //             setCompletedTask(response.data.completedTask);
    //             setTopicId(response.data.quizCode)
    //             if (response.data.completedTask == response.data.mcqCount) {
    //               setQuizCompleted(true)
    //             }

    //             if (response.data.completionStatus === 'lock') {
    //               sessionStorage.setItem("daylock", response.data.completionStatus)
    //               sessionStorage.setItem("dayCompleteId", response.data.completedDay)
    //               navigate("/day-challenge-complete-status")
    //             }
    //             else if (response.data.completionStatus === 'lock today' && sessionStorage.getItem("continueLearning") === null) {
    //               sessionStorage.setItem("daylock", response.data.completionStatus)
    //               sessionStorage.setItem("dayCompleteId", response.data.completedDay)
    //               navigate("/day-challenge-complete-status")
    //             }


    //           }).catch((err) => {
    //             console.log(err)
    //           })

    //       }).catch((err) => {
    //         console.log(err)
    //       })


    //   }

    function openVideo(url, topicIndex, topicName,day, topicId) {
        if(freeTrail === 'Y'){
            if(parseInt(day.split(":")[0].replace('Day ','')) < 2) {
                sessionStorage.setItem("cirricullumUrl", url)
                sessionStorage.setItem("cirricullumIndex", topicIndex)
                sessionStorage.setItem("cirricullumTopic", topicName)
                sessionStorage.setItem("topicId",topicId)
                navigate("/curricullm_videos");
            }
            else{
endFreeTrail();
            }
        }else{
        if (sessionStorage.getItem("circullumpId") === null) {
            navigate("/sign-up");
        } else {
        if(eligible === 'Eligible'){
            sessionStorage.setItem("cirricullumUrl", url)
            sessionStorage.setItem("cirricullumIndex", topicIndex)
            sessionStorage.setItem("cirricullumTopic", topicName)
             sessionStorage.setItem("topicId",topicId)
             sessionStorage.setItem("showDiscussion", true)
            navigate("/curricullm_videos");
            }
        }
    }
    }

    function cheatSheetWindow(url,day) {
        // window.open(url, "_blank");
        if (sessionStorage.getItem("circullumpId") === null) {
            navigate("/sign-up");
        } else {
         if(eligible === 'Eligible'){
            console.log("URL : " + url)
            window.open(url, "_blank")
            }
        }
    }

    function quiz(id,day) {
        if (sessionStorage.getItem("circullumpId") === null) {
            navigate("/sign-up");
        } else {
         if(eligible === 'Eligible'){
            hideCircullum(id)
            setIsActive(false)
            }
        }

    }

    function assignment(id,day) {
        if (sessionStorage.getItem("circullumpId") === null) {
            navigate("/sign-up");
        } else {
         if(eligible === 'Eligible'){
            sessionStorage.setItem("assignmentTopicId", id)
            sessionStorage.setItem("cirriculumTopicId", 'Yes')
            navigate("/assignment-editor")
            }
        }
        
      }



    return (
        <div>

            <div className={isActive ? "common_main_heading mt-2 day_cirriculum_days_active" : "common_main_heading mt-2 day_cirriculum_days"} onClick={() => setIsActive(!isActive)}>
                <div class="container align-items-center"><div class="row"><div class="col-7 flex-change align-items-center"><div class="common_main_text_highlight">{title}</div></div>
                    <div class="col-1  common_main_text_highlight"> {isActive ? <div> {dayProgress === "completed" && <div>{totalTask}/{totalTask}</div>}
                        {dayProgress === "unlock" && generic != 'generic' && <div>{currentDayProgress}/{totalTask}</div>}
                        {dayProgress === "lock" && generic != 'generic' && <div>0/{totalTask}</div>}</div> : <></>} </div>
                    <div class="col-2  common_main_text_highlight"> {isActive ? <div>{dayProgress === "completed" && generic != 'generic' && <ProgressBar now={100} label={100} />}
                        {dayProgress === "unlock" && generic != 'generic' && <ProgressBar now={(currentDayProgress / totalTask) * 100} label={((currentDayProgress / totalTask) * 100).toFixed(1)} />}
                        {dayProgress === "lock" && generic != 'generic' && <ProgressBar now={0} label={0} />}</div>

                        : <></>} </div>
                    <div class="col-2 flex-change" style={{ textAlign: "right" }}> <div>
                        {dayProgress === "completed" && generic != 'generic' && <div><span style={{ fontSize: 20, color: "#1ae881", marginLeft: 20 }}><FontAwesomeIcon icon={faCircleCheck} /></span></div>}
                        {dayProgress === "unlock" && generic != 'generic' && <div><span style={{ fontSize: 20, marginLeft: 20 }}><FontAwesomeIcon icon={faLockOpen} /></span></div>}
                        {dayProgress === "lock" && generic != 'generic' && <div><span style={{ fontSize: 20, marginLeft: 20 }}><FontAwesomeIcon icon={faLock} /></span></div>}</div><img class={isActive ? "cirriculum-img mx-2" : "mx-2"} width={20} src={require("../images/cirriculum_arrow_icon.png")} alt="arrow" /> </div>

                </div></div>

            </div>
            <div>
                {dayProgress === "completed" && generic != 'generic' && <ul class="list-group vertical-steps" style={{ padding: 0 }}>

                    {isActive && <div style={{ backgroundColor: "#f5f6fa", borderBottomLeftRadius: 5, borderBottomRightRadius: 5, padding: "7%" }}>

                        <li class="list-group-item active completed">
                            <span><div><div class="common_main_sub_highlight"> {content.topicName}</div>
                                <div class="common_main_text_normal"> {content.topicDesc}</div>
                                <div class="flex-change align-items-center accordion-mobile-change name mt-2" style={{ border: '1px solid #e7e7e9', padding: 10, borderRadius: 10, cursor: "pointer" }} onClick={() => { openVideo(content.topicUrl, content.topicIndex, content.topicName, title,content.topicId) }}><img width={20} src={require("../images/pink_play_icon.png")} alt="play" /><div style={{ color: "#e8759b", marginLeft: "5%" }}>{content.topicName}</div></div>     </div><div class="mt-3"></div></span>

                        </li>



                        {content.cirriculumData != null && <> {content.cirriculumData.map((res) => {
                            return (

                                <li class="list-group-item active completed">
                                    <span><div ><div class="common_main_sub_highlight"> {res.topicName}</div>
                                        <div class="common_main_text_normal"> {res.topicDesc}</div>
                                        <div class="flex-change align-items-center accordion-mobile-change name mt-2" style={{ border: '1px solid #e7e7e9', padding: 10, borderRadius: 10, cursor: "pointer" }} onClick={() => { openVideo(res.topicUrl, res.topicIndex, res.topicName, title,res.topicId) }}><img width={20} src={require("../images/pink_play_icon.png")} alt="play" /><div style={{ color: "#e8759b", marginLeft: "5%" }}>{res.topicName}</div></div></div><div class="mt-3"></div></span>

                                </li>



                            )
                        })} </>}

                        {(content.cheatSheet != null && content.cheatSheet != "") &&

                            <li class="list-group-item active completed">
                                <span><div > <div class="common_main_sub_highlight">Cheat Sheet</div>
                                    <div class="common_main_text_normal">Cheat Sheet for better learning and practicing basics.</div>
                                    <div class="flex-change align-items-center accordion-mobile-change name mt-2" style={{ border: '1px solid #e7e7e9', padding: 10, borderRadius: 10, width: "20%" }} onClick={() => { cheatSheetWindow(content.cheatSheet,title) }}><img width={20} src={require("../images/blue_file_icon.png")} alt="play" /><div style={{ color: "#7780d0", marginLeft: "5%" }}>Cheat Sheet</div></div></div><div class="mt-3"></div></span>

                            </li>



                        }

                        {(content.interviewQuestion != null && content.interviewQuestion != "") &&

                            <li class="list-group-item active completed">
                                <span><div > <div class="common_main_sub_highlight">Interview Question</div>
                                    <div class="common_main_text_normal">Interview Question for better learning and practicing basics.</div>
                                    <div class="flex-change align-items-center accordion-mobile-change name mt-2" style={{ border: '1px solid #e7e7e9', padding: 10, borderRadius: 10, width: "20%" }} onClick={() => { cheatSheetWindow(content.interviewQuestion, title) }}><img width={20} src={require("../images/blue_file_icon.png")} alt="play" /><div style={{ color: "#7780d0", marginLeft: "5%" }}>Interview Questions</div></div></div><div class="mt-3"></div></span>

                            </li>



                        }

                        {content.quizCode != null &&

                            <li class="list-group-item active completed">
                                <span><div > <div class="common_main_sub_highlight">MCQ questions</div>
                                    <div class="common_main_text_normal">MCQs for better learning and practicing basics.</div>
                                    <div class="flex-change align-items-center accordion-mobile-change name mt-2" style={{ border: '1px solid #e7e7e9', padding: 10, borderRadius: 10, width: "20%" }} onClick={() => { quiz(content.quizCode,title) }}><img width={20} src={require("../images/blue_file_icon.png")} alt="play" /><div style={{ color: "#7780d0", marginLeft: "5%" }}>MCQ's</div></div></div><div class="mt-3"></div></span>

                            </li>



                        }

                        {content.coding != null && <> {content.coding.map((res) => {
                            return (

                                <li class="list-group-item active completed">
                                    <span><div > <div class="common_main_sub_highlight">Assessment</div>
                                        <div class="common_main_text_normal">Assessment for better learning and practicing basics.</div>
                                        <div class="flex-change align-items-center accordion-mobile-change name mt-2" style={{ border: '1px solid #e7e7e9', padding: 10, borderRadius: 10, width: "20%" }} onClick={() => {assignment(res,title)}}><img width={20} src={require("../images/blue_file_icon.png")} alt="play" /><div style={{ color: "#7780d0", marginLeft: "5%" }}>Assessments</div></div></div><div class="mt-3"></div></span>

                                </li>



                            )
                        })} </>}


                    </div>}

                </ul>}

                {dayProgress === "unlock" && generic != 'generic' && <ul class="list-group vertical-steps" style={{ padding: 0 }}>

                    {isActive && <div style={{ backgroundColor: "#f5f6fa", borderBottomLeftRadius: 5, borderBottomRightRadius: 5, padding: "7%" }}>
                        {currentDayProgress === 1 ? <li class="list-group-item  active completed" style={{ backgroundColor: "transparent" }}>
                            <span><div><div class="common_main_sub_highlight"> {content.topicName}</div>
                                <div class="common_main_text_normal"> {content.topicDesc}</div>
                                <div class="flex-change align-items-center accordion-mobile-change name mt-2" style={{ border: '1px solid #e7e7e9', padding: 10, borderRadius: 10, cursor: "pointer" }} onClick={() => { openVideo(content.topicUrl, content.topicIndex, content.topicName, title,content.topicId) }}><img width={20} src={require("../images/pink_play_icon.png")} alt="play" /><div style={{ color: "#e8759b", marginLeft: "5%" }}>{content.topicName}</div></div>     </div><div class="mt-3"></div></span>

                        </li> : <li class="list-group-item" style={{ backgroundColor: "transparent" }}>
                            <span><div><div class="common_main_sub_highlight"> {content.topicName}</div>
                                <div class="common_main_text_normal"> {content.topicDesc}</div>
                                <div class="flex-change align-items-center accordion-mobile-change name mt-2" style={{ border: '1px solid #e7e7e9', padding: 10, borderRadius: 10, cursor: "pointer" }} onClick={() => { openVideo(content.topicUrl, content.topicIndex, content.topicName, title,content.topicId) }}><img width={20} src={require("../images/pink_play_icon.png")} alt="play" /><div style={{ color: "#e8759b", marginLeft: "5%" }}>{content.topicName}</div></div>     </div><div class="mt-3"></div></span>

                        </li>}




                        {content.cirriculumData != null && <> {content.cirriculumData.map((res, index) => {
                            return (
                                <>
                                    {currentDayProgress === index + 2 ?
                                        <li class="list-group-item   active completed" style={{ backgroundColor: "transparent" }}>
                                            <span><div ><div class="common_main_sub_highlight"> {res.topicName}</div>
                                                <div class="common_main_text_normal"> {res.topicDesc}</div>
                                                <div class="flex-change align-items-center accordion-mobile-change name mt-2" style={{ border: '1px solid #e7e7e9', padding: 10, borderRadius: 10, cursor: "pointer" }} vonClick={() => { openVideo(res.topicUrl, res.topicIndex, res.topicName, title,res.topicId) }}><img width={20} src={require("../images/pink_play_icon.png")} alt="play" /><div style={{ color: "#e8759b", marginLeft: "5%" }}>{res.topicName}</div></div></div><div class="mt-3"></div></span>

                                        </li> :
                                        <li class="list-group-item" style={{ backgroundColor: "transparent" }}>
                                            <span><div ><div class="common_main_sub_highlight"> {res.topicName}</div>
                                                <div class="common_main_text_normal"> {res.topicDesc}</div>
                                                <div class="flex-change align-items-center accordion-mobile-change name mt-2" style={{ border: '1px solid #e7e7e9', padding: 10, borderRadius: 10, cursor: "pointer" }} onClick={() => { openVideo(res.topicUrl, res.topicIndex, res.topicName, title,res.topicId) }}><img width={20} src={require("../images/pink_play_icon.png")} alt="play" /><div style={{ color: "#e8759b", marginLeft: "5%" }}>{res.topicName}</div></div></div><div class="mt-3"></div></span>

                                        </li>}

                                </>

                            )
                        })} </>}

                        {(content.cheatSheet != null && content.cheatSheet != "") && <>
                            {(content.cirriculumData != null && currentDayProgress === content.cirriculumData.length + 2) ? <li class="list-group-item active completed" style={{ backgroundColor: "transparent" }}>
                                <span><div > <div class="common_main_sub_highlight">Cheat Sheet</div>
                                    <div class="common_main_text_normal">Cheat Sheet for better learning and practicing basics.</div>
                                    <div class="flex-change align-items-center accordion-mobile-change name mt-2" style={{ border: '1px solid #e7e7e9', padding: 10, borderRadius: 10, width: "20%" }} onClick={() => { cheatSheetWindow(content.cheatSheet,title) }}><img width={20} src={require("../images/blue_file_icon.png")} alt="play" /><div style={{ color: "#7780d0", marginLeft: "5%" }}>Cheat Sheet</div></div></div><div class="mt-3"></div></span>

                            </li> :

                                <>
                                    {(content.cirriculumData == null && currentDayProgress === 2) ? <li class="list-group-item active completed" style={{ backgroundColor: "transparent" }}>
                                        <span><div > <div class="common_main_sub_highlight">Cheat Sheet</div>
                                            <div class="common_main_text_normal">Cheat Sheet for better learning and practicing basics.</div>
                                            <div class="flex-change align-items-center accordion-mobile-change name mt-2" style={{ border: '1px solid #e7e7e9', padding: 10, borderRadius: 10, width: "20%" }} onClick={() => { cheatSheetWindow(content.cheatSheet,title) }}><img width={20} src={require("../images/blue_file_icon.png")} alt="play" /><div style={{ color: "#7780d0", marginLeft: "5%" }}>Cheat Sheet</div></div></div><div class="mt-3"></div></span>

                                    </li> :

                                        <li class="list-group-item" style={{ backgroundColor: "transparent" }}>
                                            <span><div > <div class="common_main_sub_highlight">Cheat Sheet</div>
                                                <div class="common_main_text_normal">Cheat Sheet for better learning and practicing basics.</div>
                                                <div class="flex-change align-items-center accordion-mobile-change name mt-2" style={{ border: '1px solid #e7e7e9', padding: 10, borderRadius: 10, width: "20%" }} onClick={() => { cheatSheetWindow(content.cheatSheet,title) }}><img width={20} src={require("../images/blue_file_icon.png")} alt="play" /><div style={{ color: "#7780d0", marginLeft: "5%" }}>Cheat Sheet</div></div></div><div class="mt-3"></div></span>

                                        </li>
                                    }
                                </>
                            }



                        </>

                        }

                        {(content.interviewQuestion != null && content.interviewQuestion != "") &&

                            <>
                                {(content.cirriculumData != null && currentDayProgress === content.cirriculumData.length + 3) ? <li class="list-group-item active completed" style={{ backgroundColor: "transparent" }}>
                                    <span><div > <div class="common_main_sub_highlight">Interview Question</div>
                                        <div class="common_main_text_normal">Interview Question for better learning and practicing basics.</div>
                                        <div class="flex-change align-items-center accordion-mobile-change name mt-2" style={{ border: '1px solid #e7e7e9', padding: 10, borderRadius: 10, width: "20%" }} onClick={() => { cheatSheetWindow(content.interviewQuestion, title) }}><img width={20} src={require("../images/blue_file_icon.png")} alt="play" /><div style={{ color: "#7780d0", marginLeft: "5%" }}>Interview Questions</div></div></div><div class="mt-3"></div></span>

                                </li> :

                                    <>
                                        {(content.cirriculumData == null && currentDayProgress === 3) ? <li class="list-group-item active completed" style={{ backgroundColor: "transparent" }}>
                                            <span><div > <div class="common_main_sub_highlight">Interview Question</div>
                                                <div class="common_main_text_normal">Interview Question for better learning and practicing basics.</div>
                                                <div class="flex-change align-items-center accordion-mobile-change name mt-2" style={{ border: '1px solid #e7e7e9', padding: 10, borderRadius: 10, width: "20%" }} onClick={() => { cheatSheetWindow(content.interviewQuestion, title) }}><img width={20} src={require("../images/blue_file_icon.png")} alt="play" /><div style={{ color: "#7780d0", marginLeft: "5%" }}>Interview Questions</div></div></div><div class="mt-3"></div></span>

                                        </li> :

                                            <li class="list-group-item" style={{ backgroundColor: "transparent" }}>
                                                <span><div > <div class="common_main_sub_highlight">Interview Question</div>
                                                    <div class="common_main_text_normal">Interview Question for better learning and practicing basics.</div>
                                                    <div class="flex-change align-items-center accordion-mobile-change name mt-2" style={{ border: '1px solid #e7e7e9', padding: 10, borderRadius: 10, width: "20%" }} onClick={() => { cheatSheetWindow(content.interviewQuestion, title) }}><img width={20} src={require("../images/blue_file_icon.png")} alt="play" /><div style={{ color: "#7780d0", marginLeft: "5%" }}>Interview Questions</div></div></div><div class="mt-3"></div></span>

                                            </li>
                                        }
                                    </>
                                }



                            </>





                        }

                        {content.quizCode != null &&

                            <>


                                {(content.cirriculumData != null && currentDayProgress === content.cirriculumData.length + 4) ? <li class="list-group-item active completed" style={{ backgroundColor: "transparent" }}>
                                    <span><div > <div class="common_main_sub_highlight">MCQ questions</div>
                                        <div class="common_main_text_normal">MCQs for better learning and practicing basics.</div>
                                        <div class="flex-change align-items-center accordion-mobile-change name mt-2" style={{ border: '1px solid #e7e7e9', padding: 10, borderRadius: 10, width: "20%" }}><img width={20} src={require("../images/blue_file_icon.png")} alt="play" /><div style={{ color: "#7780d0", marginLeft: "5%" }} onClick={() => { quiz(content.quizCode,title) }}>MCQ's</div></div></div><div class="mt-3"></div></span>

                                </li> : <>

                                    {(content.cirriculumData == null && currentDayProgress === 4) ? <li class="list-group-item active completed" style={{ backgroundColor: "transparent" }}>
                                        <span><div > <div class="common_main_sub_highlight">MCQ questions</div>
                                            <div class="common_main_text_normal">MCQs for better learning and practicing basics.</div>
                                            <div class="flex-change align-items-center accordion-mobile-change name mt-2" style={{ border: '1px solid #e7e7e9', padding: 10, borderRadius: 10, width: "20%" }}><img width={20} src={require("../images/blue_file_icon.png")} alt="play" /><div style={{ color: "#7780d0", marginLeft: "5%" }} onClick={() => { quiz(content.quizCode,title) }}>MCQ's</div></div></div><div class="mt-3"></div></span>

                                    </li> :

                                        <li class="list-group-item" style={{ backgroundColor: "transparent" }}>
                                            <span><div > <div class="common_main_sub_highlight">MCQ questions</div>
                                                <div class="common_main_text_normal">MCQs for better learning and practicing basics.</div>
                                                <div class="flex-change align-items-center accordion-mobile-change name mt-2" style={{ border: '1px solid #e7e7e9', padding: 10, borderRadius: 10, width: "20%" }}><img width={20} src={require("../images/blue_file_icon.png")} alt="play" /><div style={{ color: "#7780d0", marginLeft: "5%" }} onClick={() => { quiz(content.quizCode,title) }}>MCQ's</div></div></div><div class="mt-3"></div></span>

                                        </li>
                                    } </>
                                }



                            </>





                        }

                        {content.coding != null && <> {content.coding.map((res, index) => {
                            return (

                                <>
                                    {(content.cirriculumData != null && currentDayProgress === content.cirriculumData.length + 4 + index + 1) ? <li class="list-group-item" style={{ backgroundColor: "transparent" }}>
                                        <span><div > <div class="common_main_sub_highlight">Assessment</div>
                                            <div class="common_main_text_normal">Assessment for better learning and practicing basics.</div>
                                            <div class="flex-change align-items-center accordion-mobile-change name mt-2" style={{ border: '1px solid #e7e7e9', padding: 10, borderRadius: 10, width: "20%" }} onClick={() => {assignment(res,title)}}><img width={20} src={require("../images/blue_file_icon.png")} alt="play" /><div style={{ color: "#7780d0", marginLeft: "5%" }}>Assessments</div></div></div><div class="mt-3"></div></span>

                                    </li> :

                                        <>{(content.cirriculumData == null && currentDayProgress === 4 + index + 1) ? <li class="list-group-item" style={{ backgroundColor: "transparent" }}>
                                            <span><div > <div class="common_main_sub_highlight">Assessment</div>
                                                <div class="common_main_text_normal">Assessment for better learning and practicing basics.</div>
                                                <div class="flex-change align-items-center accordion-mobile-change name mt-2" style={{ border: '1px solid #e7e7e9', padding: 10, borderRadius: 10, width: "20%" }} onClick={() => {assignment(res,title)}}><img width={20} src={require("../images/blue_file_icon.png")} alt="play" /><div style={{ color: "#7780d0", marginLeft: "5%" }}>Assessments</div></div></div><div class="mt-3"></div></span>

                                        </li> :

                                            <li class="list-group-item" style={{ backgroundColor: "transparent" }}>
                                                <span><div > <div class="common_main_sub_highlight">Assessment</div>
                                                    <div class="common_main_text_normal">Assessment for better learning and practicing basics.</div>
                                                    <div class="flex-change align-items-center accordion-mobile-change name mt-2" style={{ border: '1px solid #e7e7e9', padding: 10, borderRadius: 10, width: "20%" }} onClick={() => {assignment(res,title)}}><img width={20} src={require("../images/blue_file_icon.png")} alt="play" /><div style={{ color: "#7780d0", marginLeft: "5%" }}>Assessments</div></div></div><div class="mt-3"></div></span>

                                            </li>
                                        }</>
                                    }



                                </>





                            )
                        })} </>}


                    </div>}

                </ul>}

                {(dayProgress === "lock" || generic === 'generic') && <ul class="list-group vertical-steps" style={{ padding: 0 }}>

                    {isActive && <div style={{ backgroundColor: "#f5f6fa", borderBottomLeftRadius: 5, borderBottomRightRadius: 5, padding: "7%" }}>

                        <li class="list-group-item" style={{ backgroundColor: "transparent" }}>
                            <span><div><div class="common_main_sub_highlight"> {content.topicName}</div>
                                <div class="common_main_text_normal"> {content.topicDesc}</div>
                                <div class="flex-change align-items-center accordion-mobile-change name mt-2" style={{ border: '1px solid #e7e7e9', padding: 10, borderRadius: 10, cursor: "pointer" }} onClick={() => { openVideo(content.topicUrl, content.topicIndex, content.topicName, title,content.topicId) }}><img width={20} src={require("../images/pink_play_icon.png")} alt="play" /><div style={{ color: "#e8759b", marginLeft: "5%" }}>{content.topicName}</div></div>     </div><div class="mt-3"></div></span>

                        </li>



                        {content.cirriculumData != null && <> {content.cirriculumData.map((res) => {
                            return (

                                <li class="list-group-item" style={{ backgroundColor: "transparent" }}>
                                    <span><div ><div class="common_main_sub_highlight"> {res.topicName}</div>
                                        <div class="common_main_text_normal"> {res.topicDesc}</div>
                                        <div class="flex-change align-items-center accordion-mobile-change name mt-2" style={{ border: '1px solid #e7e7e9', padding: 10, borderRadius: 10, cursor: "pointer" }} onClick={() => { openVideo(res.topicUrl, res.topicIndex, res.topicName, title,content.topicId) }}><img width={20} src={require("../images/pink_play_icon.png")} alt="play" /><div style={{ color: "#e8759b", marginLeft: "5%" }}>{res.topicName}</div></div></div><div class="mt-3"></div></span>

                                </li>



                            )
                        })} </>}

                        {(content.cheatSheet != null && content.cheatSheet != "")  &&

                            <li class="list-group-item" style={{ backgroundColor: "transparent" }}>
                                <span><div > <div class="common_main_sub_highlight">Cheat Sheet</div>
                                    <div class="common_main_text_normal">Cheat Sheet for better learning and practicing basics.</div>
                                    <div class="flex-change align-items-center accordion-mobile-change name mt-2" style={{ border: '1px solid #e7e7e9', padding: 10, borderRadius: 10, width: "20%" }} onClick={() => { cheatSheetWindow(content.cheatSheet,title) }}><img width={20} src={require("../images/blue_file_icon.png")} alt="play" /><div style={{ color: "#7780d0", marginLeft: "5%" }}>Cheat Sheet</div></div></div><div class="mt-3"></div></span>

                            </li>



                        }

                        {(content.interviewQuestion != null && content.interviewQuestion != "") &&

                            <li class="list-group-item" style={{ backgroundColor: "transparent" }}>
                                <span><div > <div class="common_main_sub_highlight">Interview Question</div>
                                    <div class="common_main_text_normal">Interview Question for better learning and practicing basics.</div>
                                    <div class="flex-change align-items-center accordion-mobile-change name mt-2" style={{ border: '1px solid #e7e7e9', padding: 10, borderRadius: 10, width: "20%" }} onClick={() => { cheatSheetWindow(content.interviewQuestion, title) }}><img width={20} src={require("../images/blue_file_icon.png")} alt="play" /><div style={{ color: "#7780d0", marginLeft: "5%" }}>Interview Questions</div></div></div><div class="mt-3"></div></span>

                            </li>



                        }

                        {content.quizCode != null &&

                            <li class="list-group-item" style={{ backgroundColor: "transparent" }}>
                                <span><div > <div class="common_main_sub_highlight">MCQ questions</div>
                                    <div class="common_main_text_normal">MCQs for better learning and practicing basics.</div>
                                    <div class="flex-change align-items-center accordion-mobile-change name mt-2" style={{ border: '1px solid #e7e7e9', padding: 10, borderRadius: 10, width: "20%" }}><img width={20} src={require("../images/blue_file_icon.png")} alt="play" /><div style={{ color: "#7780d0", marginLeft: "5%" }} onClick={() => { quiz(content.quizCode,title) }}>MCQ's</div></div></div><div class="mt-3"></div></span>

                            </li>



                        }

                        {content.coding != null && <> {content.coding.map((res) => {
                            return (

                                <li class="list-group-item" style={{ backgroundColor: "transparent" }}>
                                    <span><div > <div class="common_main_sub_highlight">Assessment</div>
                                        <div class="common_main_text_normal">Assessment for better learning and practicing basics.</div>
                                        <div class="flex-change align-items-center accordion-mobile-change name mt-2" style={{ border: '1px solid #e7e7e9', padding: 10, borderRadius: 10, width: "20%" }} onClick={() => {assignment(res,title)}}><img width={20} src={require("../images/blue_file_icon.png")} alt="play" /><div style={{ color: "#7780d0", marginLeft: "5%" }}>Assessments</div></div></div><div class="mt-3"></div></span>

                                </li>



                            )
                        })} </>}


                    </div>}

                </ul>}
            </div>

        </div>
    );
};

export default Accordion;