import React, { useRef } from "react";
import arrow from "../images/left-arrow_icon.png"
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import JoditEditor from "jodit-react"
import { useState, useEffect } from "react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import httpsignup from '../js/http-lms_userprofile';
import moment from "moment/moment";
import ReactHtmlParser from 'html-react-parser';

const CareerHelpView = (props) => {
    console.log("COMMUNITY REPLIES : " + JSON.stringify(props))
    const editor = useRef(null)

    const contentFieldChanged = (data) => {
        setPost({ ...post, 'content': data })
    }
    const [post, setPost] = useState({
        content: ''
    })
    const [value, setValue] = useState('');
    const [replyDetails, setReplyDetails] = useState([]);

    useEffect(() => {
        getReply()
        // setInterval(getReply, 3000); 
    }, [])

    function getReply() {

        httpsignup.get(`/skillsquadUserprofileLMS/getCommunityReplyForPost?postId=${props.postData.postId}`)
            .then((response) => {
                console.log("REPLIES TITLES : " + JSON.stringify(response.data))
                setReplyDetails(response.data)
            }).catch((err) => {
                console.log(err)
            })
    }

    function saveReply(e) {
        e.preventDefault();
        httpsignup.post(`/skillsquadUserprofileLMS/saveCommunityReply`, {
            "profileId": props.profileId,
            "replyText": post.content,
            "postId": props.postData.postId
        })
            .then((response) => {
                setPost({
                    content: ''
                })
                getReply()
            }).catch((err) => {
                console.log(err)
            })
    }
    return (
        <div className="jobs-dashboard-container mx-4">

            {/* <Navb /> */}

            <div className="px-md-5 mt160">

                <section class="section">
                    <div class="container">

                        <div class="row">

                            <div class="col-lg-9">
                                <div class="row">
                                    <div class="">
                                        <div class="section-title  mb-4 pb-2">
                                            <img src={arrow} width={30} alt="arrow" style={{ cursor: "pointer" }} onClick={props.closeCareerView} />
                                            <div style={{ border: "1px solid black", padding: "1%", borderRadius: 10, marginLeft: "5%" }}>
                                                {/* <div></div> */}
                                                <div class="container">
                                                    <div class="row">
                                                        <div class="col d-flex align-items-center">
                                                            <img className='admin-pro-pic my-1 me-2' style={{ width: 60, height: 60 }} src={props.postData.profileUrl} alt="okay" />
                                                            <div  ><div class="common_main_text_highlight">{props.postData.profileName}</div><div class="d-flex align-items-center common_main_text_normal">
                                                                <div>{props.postData.role}</div>
                                                                <img style={{ width: 5, height: 5, margin: "0px 5px 0px 5px" }} src={require("../images/dot_icon.png")} alt="okay" />
                                                                <p class="text-muted mb-0" > {moment.utc(props.postData.createdAt).local().startOf('seconds').fromNow()}</p>
                                                            </div>
                                                            </div></div>


                                                        <div class="col col-lg-3 d-flex align-items-center">

                                                        </div>

                                                    </div>
                                                    <div class="row">
                                                        <div class="col-4">
                                                            <div class="mt-2 text-center common_main_text_normal" style={{ backgroundColor: '#F4f4f4', padding: 5, borderRadius: 50, border: "1px solid grey" }}>{props.postData.postCategory}</div>


                                                        </div>
                                                        <div class="mt-4 common_main_text_highlight" >{props.postData.postTitle}</div>
                                                        <div class="mt-4 common_main_text_normal" >{props.postData.postDesc}</div>
                                                        <div class="d-flex align-items-center mt-2 mb-2 common_main_text_normal" style={{ color: "blue" }}>
                                                            {props.postData.skillsquadCommunityHashtagMasterList != null && props.postData.skillsquadCommunityHashtagMasterList.map((hash) => {
                                                                return (<div style={{ marginRight: 10 }}>{hash.hasttagName}</div>)
                                                            })}

                                                        </div>
                                                        {props.postData.attachUrl != null &&
                                                            <div class="row">
                                                                <div class="col">
                                                                    <div class="d-flex align-items-center" onClick={() => { window.open(props.postData.attachUrl, "_blank") }} style={{ backgroundColor: "#F4f4f4", padding: "2%", borderRadius: 10, cursor: "pointer" }}>
                                                                        <div> <img src={require("../images/paper_icon.png")} width={100} style={{ paddingRight: 10 }} alt="paper" /></div> <div > <div class="common_main_text_highlight">{props.postData.fileName.split("/")[1]}</div><div class="common_main_text_normal">{props.postData.fileSize.toFixed(2)} MB</div></div></div>

                                                                </div></div>}
                                                        <hr class="mt-4" style={{ margin: 0, padding: 0, border: "1px solid black" }}></hr>
                                                        <div class="community">
                                                            <div class="community-left"> <div class="d-flex align-items-center mt-4 mb-2 common_main_text_highlight" > <img style={{ width: 25, height: 25, marginRight: 10 }} src={require("../images/reply_icon.png")} alt="okay" /><div>{props.postData.repliesCount}</div></div></div>

                                                        </div>

                                                    </div>




                                                </div>

                                                {/* <div></div> */}


                                                <div>
                                                    <div class="mt-4 mb-2 common_main_text_highlight" >Replies:</div>
                                                    {replyDetails.length > 0 ?
                                                        <div>
                                                            {replyDetails.map((post, i) => {
                                                                return (<div class="container mb-2">
                                                                    <div class="row">
                                                                        <div class="col d-flex align-items-center">
                                                                            <img className='admin-pro-pic my-1 me-2' style={{ width: 40, height: 40 }} src={post.profileUrl} alt="okay" />
                                                                            <div class="d-flex align-items-center" ><div class="common_main_text_highlight">{post.profileName}</div><div>  
                                                                            </div>
                                                                                <img style={{ width: 5, height: 5, margin: "0px 5px 0px 5px" }} src={require("../images/dot_icon.png")} alt="okay" />
                                                                                <p class="text-muted mb-0 common_main_text_highlight" > {moment.utc(post.createdAt).local().startOf('seconds').fromNow()}</p>
                                                                            </div></div>


                                                                        <div class="col col-lg-3 d-flex align-items-center">

                                                                        </div>

                                                                    </div>
                                                                    <div style={{ marginLeft: "7%" }} class="common_main_text_normal">{ReactHtmlParser(post.replyText)}</div>
                                                                </div>)
                                                            })}

                                                        </div> : <div class="d-flex align-items-center justify-content-center mt-5" >

                                                            <div class="common_main_text_highlight" >No Replies Available, Be first to reply</div>




                                                        </div>}
                                                </div>


                                            </div>
                                            <div style={{ width: "80%", padding: "1%", borderRadius: 10, marginLeft: "5%" }}>
                                                <div class="mt-4 mb-2 common_main_text_highlight" > Your Reply:</div>
                                                <div class="mt-4" style={{ padding: "1%", borderRadius: 10 }}><Form id="create-User-Form">
                                                    <Row className="pb-3">
                                                        <Col className="col d-flex flex-column align-items-start">
                                                            {/* <Form.Control
                                                            as="textarea"
                                                            rows={3}
                                                            placeholder="Please reply..."
                                                            autoComplete="off"
                                                            name='postDesc'
                                                            class="border-0"

                                                        /> */}
                                                            <JoditEditor

                                                                ref={editor}
                                                                value={post.content}
                                                                onChange={contentFieldChanged}
                                                            />
                                                            {/* <ReactQuill theme="snow" value={value} onChange={setValue} />; */}
                                                        </Col>

                                                    </Row>
                                                </Form>
                                                    <div class="container">

                                                        <div class="row">
                                                            <div class="col">

                                                            </div>
                                                            <div class="col-md-auto">

                                                            </div>
                                                            <div class="col col-lg-2">
                                                                <div class= 'common_main_text_highlight common_main_button'  onClick={saveReply}>Add Reply</div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div></div></div></div></div></div></section></div></div>
    )
}

export default CareerHelpView