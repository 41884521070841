import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const MentorCardSkeleton = () => {
  return (
    <div className="new-mentor-card">
      <div className="n-m-i-l">
      
        <div style={{ cursor: 'pointer' }}>
          <div className="n-m-p-i-s">
            <div>
              <Skeleton circle={true} height={60} width={60} />
            </div>
            <div className="n-m-p-d">
              <div className="n-m-t">
                <div className="n-m-n">
                  <Skeleton width={120} height={20} />
                </div>
                <Skeleton width={17} height={16} />
              </div>
              <div className="n-m-r">
                <Skeleton width={100} height={15} />
              </div>
              <div className="n-m-t">
                <Skeleton width={80} height={20} />
                <div className="n-m-t">
                  <span className="n-m-r-n">
                    <Skeleton width={30} height={15} />
                  </span>
                  <span className="n-m-r-r">
                    <Skeleton width={50} height={15} />
                  </span>
                </div>
              </div>
            </div>
          </div>

          
          <div className="n-m-n-c-d">
            <div className="n-m-c-d">
              <Skeleton width={20} height={20} />
              <div className="n-m-c-n">
                <Skeleton width={100} height={15} />
              </div>
            </div>
            <div className="n-m-c-d">
              <Skeleton width={20} height={20} />
              <div className="n-m-c-n">
                <Skeleton width={150} height={15} />
              </div>
            </div>
          </div>
        </div>

        
        <div className="n-m-s-d">
          <div className="n-m-p-d">
            <div className="n-m-s-n">
              <Skeleton width={120} height={20} />
            </div>
            <div className="n-m-t-d">
              <Skeleton width={20} height={20} />
              <span>
                <Skeleton width={150} height={15} />
              </span>
            </div>

            <div className="n-p-d">
              <div className="n-m-a">
                <div className="available-on">
                  <Skeleton width={80} height={15} />
                </div>
                <div className="n-m-a-d">
                  <Skeleton width={100} height={15} />
                </div>
              </div>
              <div className="n-m-pricing-button">
                <Skeleton width={120} height={50} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MentorCardSkeleton;
