import React, { useEffect } from 'react'
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useState } from "react";
import 'react-image-crop/dist/ReactCrop.css'
import httpsignup from '../js/http-lms_userprofile';
import httplmssignup from '../js/http-lms_userprofile_user';
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select'
import { useNavigate } from "react-router-dom";

import OTPInput, { ResendOTP } from "otp-input-react";
import PhoneInput from 'react-phone-input-2';
import '../styles/CountryCode.css';
import { trackEvent } from '../facebookPixel';


const CompleteProfilePopup = ({ onClose }) => {

    const [defaultuserDetails, setUserDetails] = useState({})
    //form data
    const [errorMessage, setErrorMessage] = useState("");
    const [formErros, setFormErrors] = useState({});
    const [disable, setDisable] = useState(true);

    const [isSubmit, setIsSubmit] = useState(false);
    const [gender, setGender] = useState();
    const [checkUserExist, setCheckUserExist] = useState("");
    const [OTP, setOTP] = useState("");
    const [confirmationCode, setConfirmationCode] = useState(false)
    const [phone, setPhone] = useState("");
    const [numberExist, setNumberExist] = useState(false)
    const [isVerified, setIsVerified] = useState(false);
    const [isTiming, setIsTiming] = useState(false);
     const [timer, setTimer] = useState(30);
    const navigate = useNavigate()

    useEffect(() => {
        trackEvent('InitiateCheckout');
        getLoggedInUserDetails()

    }, [])
    const getLoggedInUserDetails = async () => {

        httpsignup.get(`/skillsquadUserprofileLMS/getSkillsquadUserProfileBySignUpId?signUpId=${sessionStorage.getItem('userId')}`)
            .then((response) => {
                console.log("INDETAILS : " + JSON.stringify(response.data))
                setUserDetails(response.data)

            }).catch((err) => {
                console.log(err)
            })
    }

    // validate data and enable/disable submit btn
    useEffect(() => {
        
        setFormErrors(validate(defaultuserDetails))
        enableDisableBtn()
    }, [defaultuserDetails])

    useEffect(() => {
        let timerInterval;
    if(timer === 0){
        setIsTiming(false)
    }else{
        if (isTiming) {
          timerInterval = setInterval(() => {
            setTimer(prevTimer => prevTimer - 1);
          }, 1000);
        }
    }
    
        return () => {
          clearInterval(timerInterval);
        };
      }, [isTiming]);

    function enableDisableBtn() {
        if (defaultuserDetails.userName  && defaultuserDetails.uniqueUserName  && checkUserExist == '') {
            console.log("false")
            setDisable(false)
        }
        else {
            console.log("true")
            setDisable(true)
        }
    }
    //updating defaultuserDetails for final submission
    function handleChange(e) {
        setErrorMessage('')
        const { name, value } = e.target;
        setUserDetails((pValue) => {
            console.log(pValue)
            return {
                ...pValue,
                [name]: value
            }
        })
        console.log("after change : ", defaultuserDetails)
    }

    function handleUserChange(e) {
        setFormErrors(validate(defaultuserDetails))
        httplmssignup.get("/skillsquadUserprofileLMS/checkUserName?userName=" + e.target.value)
            .then((response) => {
                console.log("Update user profile data : ", response)
                if (response.data == 0) {
                    setErrorMessage('')
                    setCheckUserExist('')
                    const { name, value } = e.target;
                    setUserDetails((pValue) => {
                        console.log(pValue)
                        return {
                            ...pValue,
                            [name]: value
                        }
                    })
                    console.log("after change : ", defaultuserDetails)
                } else if (response.data == 1) {
                    console.log("already exist")
                    setCheckUserExist("User name already Exist!")
                }
            }).catch((e) => {
                console.log("error updating user : ", e.response)

            })
        
    }

  



    function SubmitFinalData(e) {
        e.preventDefault()
        setFormErrors(validate(defaultuserDetails))
        setIsSubmit(true)
    }
    useEffect(() => {
        if (Object.keys(formErros).length === 0 && isSubmit) {
            defaultuserDetails.gender = gender;

            httplmssignup.put("/skillsquadUserprofileLMS/updateSkillsquadUserProfile", defaultuserDetails)
                .then((response) => {
                    console.log("Update user profile data : ", response)
                    setErrorMessage("Updated sucessfully")
                    setTimeout(() => {
                        setErrorMessage('')
                    }, 5000)
                    setIsSubmit(false)
                    if (sessionStorage.getItem("checkoutCourse") != null) {
                        navigate("/courses/" + sessionStorage.getItem("checkoutCourse"))
                    } else { onClose() }
                }).catch((e) => {
                    console.log("error updating user : ", e.response)

                })



        }
    }, [formErros])
    const validate = (values) => {
        console.log("username : " + JSON.stringify(values))
        const errors = {};
        const allowaplaspaces = /^[a-zA-Z ]*$/;
        const emailregex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
        const phonenumberregex = /^[6789]\d{9}$/;
        const uniqueUserNameRegex = /^(?=.*_).{3,}$/;
        const alphanumericRegex = /^[a-zA-Z0-9_]+$/;
    
        
        if (!values.userName) {
            errors.userName = "Name is required!";
        } else if (values.userName.length < 5) {
            errors.userName = "Name must be at least 5 characters long!";
        } else if (!allowaplaspaces.test(values.userName)) {
            errors.userName = "Name should contain only alphabets and spaces!";
        }
    
       
        // if (!values.userEmail) {
        //     errors.userEmail = "Email Id is required!";
        // } else if (!emailregex.test(values.userEmail)) {
        //     errors.userEmail = "Please enter a valid email id!";
        // }
    
        
        // if (!values.mobileNumber) {
        //     errors.mobileNumber = "Phone Number is required!";
        // } 
       
        // else if (!phonenumberregex.test(values.mobileNumber)) {
        //     errors.mobileNumber = "Please enter a valid phone number!";
        // }
    
        // if (!values.gender) {
        //     console.log("GENDER : " + values.gender);
        //     errors.gender = "Gender is required!";
        // }

        if (!values.uniqueUserName) {
            errors.uniqueUserName = "Username is required!";
        } else if (values.uniqueUserName.length < 3) {
            errors.uniqueUserName = "Username must be at least 3 characters long!";
        }  else if (!alphanumericRegex.test(values.uniqueUserName)) {
          
            // Check if the value contains spaces
            errors.uniqueUserName = "Only alphanumeric characters and underscores are allowed. Spaces are not allowed.";

            
        }
        


        return errors;
    }

    function addGender(e) {
       
        setGender(e.target.value)
        defaultuserDetails.gender = e.target.value;
        setFormErrors(validate(defaultuserDetails))
        enableDisableBtn()
    }

    function handleMobileNumber(phone) {
        console.log("mobile length : " + phone.length)
        console.log("mobile number : " + phone)
            
        
        if (phone.length === 12) {
            defaultuserDetails.mobileNumber = phone
        setFormErrors(validate(defaultuserDetails))
        enableDisableBtn()
        httpsignup.get(`/dashBoardLMS/checkMobileNumberVerified?mobileNumber=${defaultuserDetails.mobileNumber}`)
           .then((response) => {
               if(response.data === 'New Number'){
                setNumberExist(false)
            httpsignup.post(`/dashBoardLMS/sendOTP?profileId=${defaultuserDetails.profileId}&mobileNumber=${defaultuserDetails.mobileNumber}`)
            .then((response) => {
            
                if(response.data.orderId != null){
                 setConfirmationCode(true)
                 if (!isTiming) {
                    setIsTiming(true);
                  }
                 
                }
               
 
            }).catch((err) => {
                console.log(err)
            })
              
        }else{
            setNumberExist(true)
        }
           }).catch((err) => {
               console.log(err)
           })
          

        }
    }

    function verifyOTP(OTP){
        if (OTP.length === 6) {
            httpsignup.post(`/dashBoardLMS/updateOTP?profileId=${defaultuserDetails.profileId}&OTP=${OTP}`)
            .then((response) => {
                console.log("INDETAILS : " + JSON.stringify(response.data))
                if(response.data.status == "verified"){
                 setConfirmationCode(false)
                 setIsVerified(true)
                }
               
 
            }).catch((err) => {
                console.log(err)
            })
 
         }
    }

      function resendOTP(){
        setOTP("")
        //defaultuserDetails.mobileNumber = phone
  
       // if (phone.length === 12) {
           httpsignup.post(`/dashBoardLMS/resendOTP?profileId=${defaultuserDetails.profileId}`)
           .then((response) => {
               console.log("INDETAILS : " + JSON.stringify(response.data))
               if(response.data.orderId != null){
                setConfirmationCode(true)
               }
              

           }).catch((err) => {
               console.log(err)
           })

       // }
    }


    return (
        <div className="completeprofile-popup-container">
            <div className="profile-popup" style={{ width: "30%", marginTop: "4%" }}>

                <h2 class="d-flex">Get your profile started</h2>
                <p>Add a username that's unique to you,this is how you'll appear to others.</p>
                <p style={{fontWeight : 600}}>You can't change your username, so choose wisely</p>

                <center><p style={{ font: 18, fontWeight: 700, color: "green" }}>{errorMessage}</p></center>
                <Form id="create-User-Form">
                    <Row className="pb-3">
                        <Col className="col-lg-12 col-12 d-flex flex-column align-items-start">
                            <div className="d-flex required align-items-center pb-1" ><img class="img-fluid"
                                src={require("../images/name_new_icon.png")}
                                alt="Image not found"
                                className="more"
                                width={20}

                            /> <h6 style={{ fontWeight: 700, marginLeft: 5 }}>Name</h6></div>
                            <Form.Control
                                className={formErros.userName ? "borderred" : ""}
                                placeholder="Enter first name"
                                autoComplete="off"
                                name='userName'
                                defaultValue={defaultuserDetails.userName}
                                onChange={handleChange}
                            />
                            <p className="red font12">{formErros.userName}</p>
                        </Col>




                    </Row>
                    <Row className="pb-3">
                        <Col className="col-lg-12 col-12 d-flex flex-column align-items-start">
                            <div className="d-flex required align-items-center pb-1" ><img class="img-fluid"
                                src={require("../images/name_new_icon.png")}
                                alt="Image not found"
                                className="more"
                                width={20}

                            /> <h6 style={{ fontWeight: 700, marginLeft: 5 }}>Choose a username</h6></div>
                            <Form.Control
                                className={formErros.uniqueUserName ? "borderred" : ""}
                                placeholder="john_smith"
                                autoComplete="off"
                                name='uniqueUserName'
                                defaultValue={defaultuserDetails.uniqueUserName}
                                onChange={handleUserChange}
                            />

                            <p className="red font12">{formErros.uniqueUserName}</p>
                            <p className="red font12">{checkUserExist}</p>
                        </Col>
                        <p>Build trust by using your full name or business name</p>



                    </Row>
                    
                    {/* <Row className="pb-3">
                        <Col className="col-lg-12 col-12 d-flex flex-column align-items-start">
                            <div className="d-flex required align-items-center pb-1">
                                <img class="img-fluid"
                                    src={require("../images/email_icon.png")}
                                    alt="Image not found"
                                    className="more"
                                    width={20}

                                />
                                <h6 style={{ fontWeight: 700, marginLeft: 5 }}>Email</h6></div>
                            <Form.Control
                                className={formErros.userEmail ? "borderred" : ""}
                                placeholder="e.g. xxxx@gmail.com"
                                autoComplete="off"
                                name='userEmail'
                                defaultValue={defaultuserDetails.userEmail}
                                disabled
                                onChange={handleChange}
                            />
                            <p className="red font12">{formErros.userEmail}</p>
                        </Col>

                    </Row> */}
                    {/* <Row className="pb-3">

                        <Col className="col-lg-12 col-12 d-flex flex-column align-items-start">
                            <div className="d-flex required align-items-center pb-1">
                                <img class="img-fluid"
                                    src={require("../images/phone_icon.png")}
                                    alt="Image not found"
                                    className="more"
                                    width={20}

                                />
                                <h6 style={{ fontWeight: 700, marginLeft: 5 }}>Phone{isVerified && <img width={20} src={require("../images/Tick.png")} alt="" />}</h6></div>
                           */}
                            {/* <Form.Control
                                className={formErros.phoneNumber ? "borderred" : ""}
                                placeholder="Enter mobile with country code"
                                autoComplete="off"
                                name='mobileNumber'
                                maxLength={13}
                                defaultValue={defaultuserDetails.mobileNumber}
                                onChange={handleMobileNumber}
                            /> */}

                            {/* <PhoneInput
                            class="react-tel-input form-control"
                                name='mobileNumber'
                                country={'in'}
                                 enableSearch={true}
                                 value={phone}
                                 onChange={(phone) => handleMobileNumber(phone)}
                            /> <p className="red font12">{formErros.mobileNumber}</p>
                             { numberExist &&  <p className="red font12">This Number is already used. Please provide other</p>}
                        </Col>

                    </Row>
                    {confirmationCode && <Row className="pb-3">

                        <Col className="col-lg-12 col-12 d-flex flex-column align-items-start">
                            <div className="d-flex align-items-center pb-1">

                                <h6 style={{ fontWeight: 700, marginLeft: 5 }}>Confirmation code</h6></div>
                            <div class="d-flex align-items-center"> <OTPInput class="mx-2" value={OTP} onChange={(OTP) => {setOTP(OTP); verifyOTP(OTP);}} autoFocus OTPLength={6} otpType="number" disabled={false} /> <div class="d-flex align-items-center" style={{ color: "#007AFF", fontWeight: 500,cursor:"pointer" }} onClick={resendOTP}><img src={require("../images/otp_send_again.png")} alt="" /> Send again</div></div>

                        </Col>
                        <Col className="col-lg-12 col-6 d-flex flex-column align-items-start">
                            <div style={{ fontSize: 12 }}>we've sent an SMS with activation code to your phone +<span style={{ fontWeight: 600 }}>{defaultuserDetails.mobileNumber}</span></div>
                        </Col>

                    </Row>} */}
                    {/* <Row className="pb-3">

                        <Col className="col-lg-12 col-12 d-flex flex-column align-items-start">
                            <div className="d-flex align-items-center pb-1">

                                <h6 style={{ fontWeight: 700, marginLeft: 5 }}>Bio</h6></div>
                            <Form.Control
                                as={"textarea"}

                                placeholder="Enter bio"
                                autoComplete="off"
                                name='bio'
                                defaultValue={defaultuserDetails.bio}
                                onChange={handleChange}
                            />

                        </Col>

                    </Row> */}


                    {/* <div className="d-flex required align-items-center pb-1">

                        <h6 style={{ fontWeight: 700, marginLeft: 5 }}>Gender</h6></div>

                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="inlineRadioOptions" checked={gender == "Female"}
                            onClick={(e) => { addGender(e) }} id="inlineRadio1" value="Female" />
                        <label class="form-check-label" for="inlineRadio1">Female</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" checked={gender == "Male"}
                            onClick={(e) => { addGender(e) }} value="Male" />
                        <label class="form-check-label" for="inlineRadio2">Male</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio3" checked={gender == "Other"}
                            onClick={(e) => { addGender(e) }} value="Other" />
                        <label class="form-check-label" for="inlineRadio3">Other</label>
                    </div>

                    <p className="red font12">{formErros.gender}</p> */}

                    <div>
                        <Row >

                            <Col className="col-lg-2 col-12 d-flex flex-column align-items-start mt-lg-0 mt-3">
                                <button disabled={disable} className={disable ? "profile-buttons-update mt-3 mb-2 grey" : "profile-buttons-update mt-3 mb-2 UpdatedMainBGColor"} onClick={SubmitFinalData}>
                                    Create my account
                                </button>
                            </Col>


                        </Row>
                    </div>
                </Form>
                {/* <div class="activityButton" onClick={allcourses} style={{width: "100%", textAlign:"center"}}>See More Courses</div> */}

            </div>
        </div>
    )
}

export default CompleteProfilePopup;
