
import '../styles/LockedPopup.css';
import React, { useEffect, useState } from "react";
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Marquee from "react-fast-marquee"
import { useNavigate } from "react-router-dom";
import star from "../images/star.png";
import httpsignup from '../js/http-lms_userprofile';
import ConfettiExplosion from 'react-confetti-explosion';



const LockedPopup = ({ onClose, unlockPop, freeTrail }) => {
  const navigate = useNavigate();
  function allcourses() {
    navigate("/dashboard")
  }
  function myCourses() {
    navigate("/my-course")
  }
  const getLoggedInUserDetails = async () => {
    console.log("sesion " + sessionStorage.getItem('userId'))
    httpsignup.get(`/skillsquadUserprofileLMS/getSkillsquadUserProfileBySignUpId?signUpId=${sessionStorage.getItem('userId')}`)
      .then((response) => {
        setUserType(response.data.userTypeMasterId)

      }).catch((err) => {
        console.log(err)
      })
  }

  function handleCourse(){

    navigate("/courses/"+sessionStorage.getItem("seoUrl"));
  }
 

  useEffect(() => {
    getLoggedInUserDetails()

  }, [])
  const [userType, setUserType] = useState(0);
  const [quote, setQuote] = useState();
  const [isExploding, setIsExploding] = React.useState(true);
  return (
    <div>
      {unlockPop ? <div class="nextday-popup-container">
        <div class="container text-center">
          <div class="row">
            <div class="col">
              {freeTrail === 'Y' ? <div class="common_main_heading">Free Trial Access is Completed</div> : <div class="common_main_heading">This Locked Please complete Current</div>}
              {freeTrail === 'Y' ? <></> : <div class="common_main_heading">Day to unlock This</div>}
            </div>
          </div>
          <div class="row align-items-center">
            <div class="col">
              <center>
              {freeTrail === 'Y' ? <div class="row" style={{marginLeft:"20%"}}><div class="common_main_button_black px-2 col-4 mt-4" style={{marginRight: 5}}  onClick={onClose}>Close</div><div class="common_main_button_black px-2 col-4 mt-4" style={{marginLeft: 5}}  onClick={handleCourse}>Go to purchase Course</div></div> : <div class="common_main_button_black mt-4" style={{ width: "40%" }} onClick={onClose}>Go Back to current Schedule</div>}
                </center>
            </div>
          </div>
        </div>



      </div> :
        <div className="locked-popup-container" style={{ marginTop: 20 }}>



          <div className="locked-popup">


            <div class="locked-popup-card-paid">
              <div class="locked-popup-div">
                <div>


                  <div class="locked-popup-content mt-3 mb-3">Hey, {sessionStorage.getItem('userName')}</div>

                </div>
              </div>
              <div style={{ padding: "2%" }}>
                <div>{isExploding && <ConfettiExplosion />}</div>
                <div>{quote}</div>
                <div style={{ display: "inline-flex", margin: "0 auto" }} class="align-items-center"> <div className='locked-join-box-button button-trans text-center common_main_button' style={{ fontSize: 20, backgroundColor: '#5627ff', fontWeight: 500 }} onClick={myCourses}>Continue Learning</div> </div>

              </div>
            </div>
          </div>


        </div>
      }
    </div>
  );
};

export default LockedPopup;