import React from "react";
import Sidebar from "./Sidebar";
import "../fonts/Roboto/Roboto-Black.ttf";
import { useState, useEffect } from "react";
import "../styles/JobReferral.css";
import forward from "../images/forward.png"
import internet from "../images/internet.png"
import flash from "../images/flash.png"
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Popup from "../components/LockedPopup"
import RightSidebar from "./RightSidebar";


const JobReferral = () => {
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const openPopup = () => {
        setIsPopupOpen(true);
    };

    const closePopup = () => {
        setIsPopupOpen(false);
    };
    return (
        <>
            <Sidebar />

            <div className="jobs-dashboard-container mx-4">

                {/* <Navb /> */}

                <div className="px-md-5 mt160">

                    <section class="section">
                        <div class="container">

                            <div class="row p-4">

                                <div class="col-lg-9">
                                    <div class="row">
                                        <div class="">
                                            <div class="section-title  mb-4 pb-2">
                                                <div className="col-lg-12">
                                                    <div className="row">
                                                    <center>
                                                        <div className="d-flex flex-column">

                                                            <div class="common_main_heading mb-2">Get <span class="referral-heading-highlight">job referrals</span> to top tech companies</div>
                                                           <center> <div class="referral-heading-tag mb-2 common_main_sub_heading">Land more interviews with job referrals from employees at Google, Facebook, Amazon, and more.</div></center>
                                                            <div><div class="referral-blue-button button-trans common_main_button"onClick={openPopup}>Get a referral<FontAwesomeIcon icon={faLock} style={{marginLeft:10}} /></div></div>
                                                            {isPopupOpen && <Popup onClose={closePopup} />}
                                                            <div class="d-flex" >
                                                                <div class="referral-text"><div class="referral-image mb-3"><img src={forward} alt="forward" /></div><div class="referral-text-heading mb-1">Accelerate your job search</div><div class="referral-text-subheading">Referrals are the fastest way to get interviews in tech, and are proven to improve your chances of landing the offer.</div></div>
                                                                <div class="referral-text"><div class="referral-text-heading mb-1 "><div class="referral-image mb-3"><img src={internet} alt="internet" /></div>Connect with our 300+ referral network</div><div class="referral-text-subheading ">get your resume seen by an exclusive network of hundreds of Exponent members currently employed at top tech companies</div></div>
                                                                <div class="referral-text"><div class="referral-text-heading mb-1 "><div class="referral-image mb-3"><img src={flash} alt="forward" /></div>Get seen by employees in minutes</div><div class="referral-text-subheading ">We made our referral process as smooth as possible - all you need is your resume and the job position you're interested in.</div></div>
                                                            </div>


                                                        </div>
                                                        </center>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>


                                </div>

                                {/*Filter section*/}
                            </div>

                        </div>

                    </section>



                </div>


            </div>


            <RightSidebar />
        </>
    );
};

export default JobReferral;
