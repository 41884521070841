import React from "react";
import { useState, useEffect } from "react";
import eventBus from "../../js/Event-bus";
import httpvideos from "../../js/http-lms_videos"
import Spinner from 'react-bootstrap/Spinner';
//images
import attch from '../../images/file_attach.svg';
import delete_file from '../../images/file_delete.svg'
import closebtn from '../../images/close_btn.svg'

const TopicDetails = ({selectedTopicData, setSelectedTopicData}) => {
    const [topicFullData, setTopicFullData] = useState([])
    const [loading, setLoading] = useState(true)
    const [renderState, setRenderState] = useState(false)
    const [removeState, setRemoveState] = useState(false)
    const [finalLoading, setFinalLoading] = useState(false)
    // submit assignment
    const [message, setMessage] = useState()
    const [pop2, setPop2] = useState(false);
    const [selectedSubmittedAssignment, setSelectedSubmittedAssignment] = useState({})

    useEffect(() => {
        console.log("HELLO ")
                getTopicAssignmentData(selectedTopicData.lmsTopicMasterId)
    },[selectedTopicData])
    function getTopicAssignmentData(topicId) {
        httpvideos.get('/api/v1/topicAssignmentLMS/lmsTopicAssignmentManager?RoleId='+sessionStorage.getItem("roleid")+'&UserId='+sessionStorage.getItem('userEmail')+'&topicMasterId='+topicId+'&studentId='+sessionStorage.getItem('profileid'))
        .then((response) => {
            console.log("topic data information : ", response.data)
            if(response.data && response.data.length > 0){
                if(response.data[0].assignmentMasterVO && response.data[0].assignmentMasterVO.length>0){
                    for(var i =0; i < response.data[0].assignmentMasterVO.length-1; i++){
                        response.data[0].assignmentMasterVO[i].selectedAssignmentFile = null;
                        response.data[0].assignmentMasterVO[i].finalLoading = false;
                    }
                }
            }
            setTopicFullData(response.data)
            setLoading(false)
        }).catch((e) => {
            console.log(e)
            setLoading(false)
        })
    }
    function UpdateTopicAssignmentData(topicId, assignment,index){
        httpvideos.get('/api/v1/topicAssignmentLMS/lmsTopicAssignmentManager?RoleId='+sessionStorage.getItem("roleid")+'&UserId='+sessionStorage.getItem('userEmail')+'&topicMasterId='+topicId+'&studentId='+sessionStorage.getItem('profileid'))
        .then((response) => {
            console.log("render data : ", response.data)
            if(response.data && response.data.length > 0){
                let assignmentsArray = response.data[0].assignmentMasterVO;
                if( assignmentsArray && assignmentsArray.length>0){
                    for(var i =0; i < assignmentsArray.length; i++){
                        if(assignmentsArray[i].assignmenId == assignment.assignmenId){
                            console.log("selected updated : ", assignmentsArray[i])
                            topicFullData[0].assignmentMasterVO[index] = assignmentsArray[i];
                            setTopicFullData(topicFullData)
                        }
                    }
                }
                
            }
        }).catch((e) => {
            console.log(e)
        })
    }
    function chooseFilesopen(assignment){
        document.getElementById("postfileUpload"+assignment.assignmenId).click()
    }
    function onAssignmentFileChanged(event, assignment, i) {
        assignment.selectedAssignmentFile = event.target.files[0]
        topicFullData[0].assignmentMasterVO[i] = assignment;
        setTopicFullData(topicFullData)
        setRenderState(assignment.assignmenId)
    }
    function submitAssignmentFinal(assignment,i) {
        setFinalLoading(assignment.assignmenId)
        let filename = sessionStorage.getItem('profileid')+'_'+sessionStorage.getItem('fullName')+'_'+assignment.assignmentName+'_answerset'
        let fd = new FormData()
        var obj = {
            assignmentId : assignment.assignmenId,
            studentId : sessionStorage.getItem('profileid'),
            assignmentResponseAttachmentFileName : filename,
            assignmentSubmittedEmail : sessionStorage.getItem('userEmail')
        }
        // console.log("final obj : ", obj)
        // console.log("final file : ", assignment.selectedAssignmentFile)
        var pblob = new Blob([JSON.stringify(obj)], { type: "application/json" })
        fd.append("submitAssignmentRequestVO", pblob)
        fd.append("attachmentFiles", assignment.selectedAssignmentFile)
        httpvideos.post('/api/v1/topicAssignmentLMS/updateAssignmentResponseLinkURL',fd, {
            headers: {
                "Content-Type": "multipart/form-data",
                "Accept" : "*/*"
              }
        })
        .then((response) => {
            console.log("saving assignment info information : ", response.data)
            setMessage(response.data)
            setFinalLoading(false)
            setTimeout(() => {
                setMessage()
            }, 1600);
            if(response.data !== "Record Not Found"){
                clearData()
                UpdateTopicAssignmentData(selectedTopicData.lmsTopicMasterId, assignment,i)
            }
        }).catch((e) => {
            console.log(e)
            setFinalLoading(false)
        })
    }

    function deleteAssignment(assignment,i){
        console.log("delete assignment : ", assignment)
        setFinalLoading(assignment.assignmenId)
        httpvideos.delete('/api/v1/topicAssignmentLMS/lmsDeleteAssignmentsFromS3?RoleId='+sessionStorage.getItem("roleid")+'&UserId='+sessionStorage.getItem('userEmail')+'&assignmentId='+assignment.assignmenId+'&studentId='+sessionStorage.getItem('profileid'))
        .then((response) => {
            console.log("deleting assignment : ", response.data)
            setMessage(response.data)
            setTimeout(() => {
                setMessage()
            }, 1600);
            clearData()
            UpdateTopicAssignmentData(selectedTopicData.lmsTopicMasterId, assignment,i)
        }).catch((e) => {
            console.log(e)
        })
    }
    function viewAssignment(assignment,i){
        console.log("view assignment : ", assignment,i)
        setSelectedSubmittedAssignment(assignment)
        setPop2(true);
    }
    function closePopUp(){
        setPop2(false)
        setSelectedSubmittedAssignment({})
    }
    function clearData() {
        let dropdown = document.getElementById('assignmentdropdown')
        if(dropdown) {
            dropdown.value = '';
        }
        setRemoveState(false)
        setRenderState(false)
        setFinalLoading(false)
    }
    function RemoveAssignmentDocs(e, assignment){
        setRenderState(null)
        setFinalLoading(null)
        assignment.selectedAssignmentFile = null;
        setRemoveState(assignment.assignmenId)
        console.log(assignment)
    }
    return (
        
        <div className="">
            {pop2 && (
            <div className="position-fixed P-Update-Success-1 d-flex justify-content-center align-items-center">
              <div className="card border-0 pb-2 col-11 col-md-8 col-lg-3 d-flex flex-column">
                <div className="col-12 p-2 py-3 radius10 border-0 bold1 background-gray font18 text-center">
                    <span>Assignment Info</span> 
                    <img src={closebtn} alt="close" width="25px" className="closebtn mouseoverstyles1 radiushalf pointer border-0" onClick={() => closePopUp()}/>
                </div>
                <div className="col-12 p-3 py-3 d-flex flex-column">
                    <div className="d-flex">
                        <span className="col-6 font16 black bold">Assignment Name </span>
                        <span className="col-6 font16 black">: {selectedSubmittedAssignment.s3URLAssignmentFile}</span>
                    </div>
                    <div className="d-flex mt-2">
                        <span className="col-6 font16 black bold">Assignment Score </span>
                        <span className="col-6 font16 black">: {selectedSubmittedAssignment.assignmentSubmitionFlagVO.assignmentScorePct}</span>
                    </div>
                    <div className={selectedSubmittedAssignment.assignmentSubmitionFlagVO.assignmentFeedback ? 'd-flex flex-column mt-2' : 'd-flex mt-2'}>
                        <span className="col-6 font16 black bold">Assignment Feedback </span>
                        <span className={selectedSubmittedAssignment.assignmentSubmitionFlagVO.assignmentFeedback ? 'col-12 font16 black' : 'col-6 black60'}>
                            {selectedSubmittedAssignment.assignmentSubmitionFlagVO.assignmentFeedback ? selectedSubmittedAssignment.assignmentSubmitionFlagVO.assignmentFeedback : ': No Feedback'}
                        </span>
                    </div>
                </div>
                {/* <div className="footer text-center p-2">
                    <button className="col-10 mx-auto background-organe white w-100 border-0 p-1 radius15" onClick={closePopUp}>close</button>
                </div> */}
              </div>
            </div>
          )}
            {loading ? <div className="d-flex flex-column align-items-center justify-content-center pb-5 pt-3"> <Spinner animation="border" variant="secondary" /></div> :
            <div>
                {(topicFullData.length > 0 &&topicFullData[0].lmsTopicMaster) ? <h5 className="my-4">{topicFullData[0].lmsTopicMaster.topicName}</h5> : <h5>{selectedTopicData.topicName}</h5>}
                <h6>Description</h6>
                {(topicFullData.length>0 &&topicFullData[0].lmsTopicMaster && topicFullData[0].lmsTopicMaster.s3PresignedTopicMaterialLink) ? <a href={topicFullData[0].lmsTopicMaster.s3PresignedTopicMaterialLink} className="orange" target="_blank">Topic description</a> : <p className="font16 black60 bold">{selectedTopicData.topicDescription}</p>}
            
                {/* <div className="my-4">
                    <div className="col-lg-12 d-flex flex-column align-items-start">
                        <h6 className="mb-2">Assignment Details</h6>
                        <span className="mb-2 text-center mx-auto font14 blue height15">{message}</span>
                        {topicFullData.length>0 && topicFullData[0].assignmentMasterVO && topicFullData[0].assignmentMasterVO.length>0 ? 
                            <div className="col-12 pl-0 pr-0 mt-2">
                                <div className="col-12 pl-0 pr-0 d-flex">
                                    <div className="col-6 col-md-3">
                                        <h6>Assignment Files</h6>
                                    </div>
                                    <div className="col-7 d-none d-md-block">
                                        <h6>Answer Files</h6>
                                    </div>
                                    <div className="col-2 d-none d-md-block">
                                        <h6>Action</h6>
                                    </div>
                                </div>
                                <div className="d-md-flex flex-column">
                                    {topicFullData[0].assignmentMasterVO.map((assignment,i)=>{
                                        return(
                                            <div className="col-12 pl-0 pr-0 d-md-flex mt-2" key={'files'+i}>
                                                <div className="col-3">
                                                    <a href={assignment.s3PresignedAssignmentLink} className="orange my-auto" target="_blank" key={'assignment_qsn'+i}>{assignment.s3URLAssignmentFile}</a>
                                                </div>
                                                <div className="col-7">
                                                    {assignment && assignment.assignmentSubmitionFlagVO.assignmentSubmittedFlag=='Y'?
                                                        <a href={assignment.assignmentSubmitionFlagVO.s3PreSignedAssignmentLink} className="orange my-auto text-wrap col-12" target="_blank" key={'assignment_answer'+i}>{assignment.assignmentSubmitionFlagVO.submittedAssignmentFileName}</a>
                                                    :   
                                                        <span>{ (renderState === assignment.assignmenId) || (removeState === assignment.assignmenId) || assignment.selectedAssignmentFile ? 
                                                            <span>{ assignment.selectedAssignmentFile?
                                                                <div className="col-10 border-button hover-border_orange p-1 d-flex justify-content-around background-accordion radius15">
                                                                    <span className="font12 col-10 pl-0 pr-0">{assignment.selectedAssignmentFile.name}</span>
                                                                    <img src={require("../../images/closebtn.png")} title="Remove"  onClick={(e) => RemoveAssignmentDocs(e, assignment)} alt="delete" width="20px" height="20px" className="pointer"/>
                                                                </div> :
                                                                <div className="col-10">
                                                                    <input type="file" className="form-control-file" id={"postfileUpload"+assignment.assignmenId}  accept="*" onChange={(e) => {onAssignmentFileChanged(e,assignment, i)}} hidden/>
                                                                    <button className="w-100 border-button hover-border_orange p-1 background-none radius15"  onClick={() => {chooseFilesopen(assignment)}}>
                                                                        <img src={attch} alt="file" width="20px"/>
                                                                        <span className="font12">Attach File</span>
                                                                    </button>
                                                                </div>
                                                            }</span>
                                                            :
                                                            <div className="col-10">
                                                                <input type="file" className="form-control-file" id={"postfileUpload"+assignment.assignmenId}  accept="*" onChange={(e) => {onAssignmentFileChanged(e,assignment, i)}} hidden/>
                                                                <button className="w-100 border-button hover-border_orange p-1 background-none radius15"  onClick={() => {chooseFilesopen(assignment)}}>
                                                                    <img src={attch} alt="file" width="20px"/>
                                                                    <span className="font12">Attach File</span>
                                                                </button>
                                                            </div>
                                                        }</span>
                                                    }
                                                </div>
                                                <div className="col-2">
                                                {((finalLoading === assignment.assignmenId) || assignment.finalLoading) ? <div className="col-10 text-center"> <Spinner animation="border" className="spinner" variant="secondary" /> </div> :
                                                <span>
                                                    {assignment.assignmentSubmitionFlagVO.assignmentSubmittedFlag=='Y'?
                                                        (assignment.assignmentSubmitionFlagVO.assignmentScorePct ? 
                                                        <button className="col-10 border-orange p-1 background-none radius15"  onClick={() => {viewAssignment(assignment,i)}}>
                                                            <span className="font12 orange">View</span>
                                                        </button> :
                                                        <button className="col-10 border-orange p-1 background-none radius15"  onClick={() => {deleteAssignment(assignment,i)}}>
                                                            <img src={delete_file} alt="file" width="18px"/>
                                                            <span className="font12 orange">Delete</span>
                                                        </button>
                                                        )
                                                    : 
                                                    <div className="col-10">
                                                        <button className={assignment.selectedAssignmentFile ? "background-organe white w-100 border-0 p-1 radius15":"w-100 border-button p-1 black60 background-accordion radius15"} onClick={() => {submitAssignmentFinal(assignment,i)}} disabled={!assignment.selectedAssignmentFile}>
                                                            <span className="font12">Submit</span>
                                                            </button>
                                                        </div>
                                                    }
                                                    </span>
                                                }
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                            : <p className="font16 black60 bold">No Assignments found</p>
                        }
                    </div>
                    {/* <div className="col-lg d-flex flex-column p-3 borderoutline mx-lg-5 mt-4 ms-2 rounded-top">
                        <h6>Deadline</h6>
                        <h5>15 Oct 22</h5>
                        <h5>12:00 PM (IST)</h5>
                    </div> }
                </div> */}
            </div>
            }
        </div>
    )
}
export default TopicDetails;