import React from 'react'

const PrivacyPolicy = () => {
    return (
      <>
        <div>
          {/* <NewHeader /> */}
          <div  style={{ width: "100%" }}>
            {/* <Navb /> */}
  
            <div className="pt-5">
              <section class="section">
                <div class="container">
                  <div class="row ">
                    <div class="col-lg-12 ">
                      <div class="row mb-3">
                        {/* <div className="col"></div> */}
                        <div className="col" style={{ textAlign: "left" }}>
                          <center>
                            <div
                              style={{ fontSize: 50, fontWeight: 700 }}
                              class="mb-5"
                            >
                              Privacy Policy
                            </div>
                          </center>
                          <div
                            class="mt-3"
                            style={{
                              border: "1px solid grey",
                              borderRadius: 10,
                              boxShadow: "2px 1px 5px 0px rgba(0, 0, 0, 0.15)",
                              padding: "3%",
                              background: "white",
                            }}
                          >
                            <div class="mt-3">
                              Welcome to SkillSquad. This Privacy Policy
                              constitutes a binding legal agreement between you
                              ("User," "you," or "your") and SkillSquad
                              ("Company," "we," "us," or "our"). By accessing,
                              using, or participating in any of the services
                              offered by SkillSquad, you expressly consent to the
                              collection, use, disclosure, and retention of your
                              personal information as outlined in this policy.
                              This policy is intended to ensure full compliance
                              with applicable data protection regulations,
                              including but not limited to the General Data
                              Protection Regulation (GDPR), the California
                              Consumer Privacy Act (CCPA), and other relevant
                              laws.
                            </div>
  
                            <div
                              class="mt-3"
                              style={{
                                fontSize: 20,
                                fontWeight: 700,
                                lineHeight: 2,
                              }}
                            >
                              Overview of the Privacy Policy
                            </div>
                            <div class="mt-3">
                              This Privacy Policy delineates the categories of
                              personal and non-personal data collected, the
                              methods of collection, the purposes for which such
                              data is utilized, and the circumstances under which
                              it may be disclosed to third parties. It further
                              articulates the security measures employed to
                              safeguard your data and your rights concerning the
                              access, modification, and deletion of your
                              information. This document is a critical component
                              of our commitment to maintaining the integrity,
                              confidentiality, and security of user data while
                              ensuring that our operations align with prevailing
                              legal standards.
                            </div>
  
                            <div
                              class="mt-3"
                              style={{
                                fontSize: 20,
                                fontWeight: 700,
                                lineHeight: 2,
                              }}
                            >
                              Purpose of Data Collection
                            </div>
                            <div class="mt-3">
                              The data collected by SkillSquad is procured for the
                              primary objective of facilitating the provision of
                              our services, including but not limited to, the
                              customization of user experiences, the enhancement
                              of service functionality, and the enablement of
                              effective communication between users and the
                              platform. Additionally, we may aggregate and analyze
                              data to identify trends, improve our offerings, and
                              ensure compliance with legal obligations. The
                              processing of personal data is conducted in strict
                              accordance with principles of data minimization,
                              ensuring that only the data necessary for legitimate
                              business purposes is collected and retained.
                            </div>
  
                            <div
                              class="mt-3"
                              style={{
                                fontSize: 20,
                                fontWeight: 700,
                                lineHeight: 2,
                              }}
                            >
                              Data We Collect
                              <br />
                              Personal Information
                            </div>
                            <div class="mt-3">
                              SkillSquad may collect personally identifiable
                              information ("Personal Information") that you
                              provide voluntarily, including but not limited to,
                              your name, email address, phone number, postal
                              address, and demographic details. This data is
                              collected to facilitate user account management,
                              enhance user interaction with the platform, and
                              personalize the user experience in accordance with
                              our service agreements and contractual obligations.
                            </div>
  
                            <div
                              class="mt-3"
                              style={{
                                fontSize: 20,
                                fontWeight: 700,
                                lineHeight: 2,
                              }}
                            >
                              Non-Personal Information
                            </div>
                            <div class="mt-3">
                              In addition to Personal Information, SkillSquad may
                              collect non-personally identifiable information
                              ("Non-Personal Information") through automated
                              processes, such as log files, cookies, and other
                              tracking technologies. This includes data such as IP
                              addresses, browser types, device identifiers, usage
                              statistics, and interactions with our platform.
                              Non-Personal Information is utilized for analytical
                              purposes, to monitor and improve the functionality
                              of the platform, and to enhance user experience by
                              providing insights into usage patterns and
                              preferences.
                            </div>
  
                            <div
                              class="mt-3"
                              style={{
                                fontSize: 20,
                                fontWeight: 700,
                                lineHeight: 2,
                              }}
                            >
                              Sensitive Information
                            </div>
                            <div class="mt-3">
                              SkillSquad may collect certain categories of
                              sensitive information, which includes but is not
                              limited to, information regarding your health,
                              financial status, or other aspects of a highly
                              personal nature, solely when necessary and with
                              explicit consent. The collection and processing of
                              such sensitive data are conducted with heightened
                              security measures and strict adherence to applicable
                              data protection regulations to ensure the
                              safeguarding of your privacy.
                            </div>
  
                            <div
                              class="mt-3"
                              style={{
                                fontSize: 20,
                                fontWeight: 700,
                                lineHeight: 2,
                              }}
                            >
                              Payment Information
                            </div>
                            <div class="mt-3">
                              For transactions involving payment, SkillSquad
                              collects payment-related information, including
                              credit card details, billing addresses, and
                              transaction history ("Payment Information"). This
                              data is processed through secure payment gateways in
                              compliance with Payment Card Industry Data Security
                              Standard (PCI DSS) requirements. Payment Information
                              is used exclusively for processing transactions,
                              managing financial records, and preventing fraud.
                            </div>
  
                            <div
                              class="mt-3"
                              style={{
                                fontSize: 20,
                                fontWeight: 700,
                                lineHeight: 2,
                              }}
                            >
                              How We Collect Data
                              <br />
                              Information Provided by Users
                            </div>
                            <div class="mt-3">
                              SkillSquad collects data directly from users through
                              various means, including but not limited to,
                              registration forms, user profiles, communication
                              with support services, and participation in platform
                              activities. This includes Personal Information,
                              Sensitive Information, and Payment Information that
                              users voluntarily provide when creating accounts,
                              subscribing to services, or engaging with the
                              platform. The collection of such data is predicated
                              on the user's explicit consent and is essential for
                              fulfilling service obligations and operational
                              requirements.
                            </div>
  
                            <div
                              class="mt-3"
                              style={{
                                fontSize: 20,
                                fontWeight: 700,
                                lineHeight: 2,
                              }}
                            >
                              Information Collected Automatically
                            </div>
                            <div class="mt-3">
                              SkillSquad employs automated technologies to gather
                              Non-Personal Information during user interactions
                              with the platform. This encompasses data collected
                              through cookies, web beacons, server logs, and
                              similar tracking mechanisms. Automatically collected
                              information may include IP addresses, device
                              identifiers, browser types, operating systems, and
                              browsing activity. This data is integral to
                              optimizing platform performance, analyzing user
                              behavior, and enhancing overall user experience by
                              facilitating the continuous improvement of our
                              services.
                            </div>
  
                            <div
                              class="mt-3"
                              style={{
                                fontSize: 20,
                                fontWeight: 700,
                                lineHeight: 2,
                              }}
                            >
                              Information from Third Parties
                            </div>
                            <div class="mt-3">
                              SkillSquad may receive data from third-party
                              sources, including but not limited to, partners,
                              affiliates, service providers, and publicly
                              available databases. This third-party information
                              may include aggregated data, demographic details, or
                              additional insights relevant to user profiles and
                              service personalization. Such data is acquired in
                              compliance with applicable legal requirements and is
                              utilized to augment our service offerings, validate
                              user information, and ensure the accuracy and
                              relevance of our operational processes.
                            </div>
  
                            <div
                              class="mt-3"
                              style={{
                                fontSize: 20,
                                fontWeight: 700,
                                lineHeight: 2,
                              }}
                            >
                              Use of Collected Data
                              <br />
                              To Provide and Improve Services
                            </div>
                            <div class="mt-3">
                              The data collected by SkillSquad is utilized for the
                              primary purpose of delivering, administering, and
                              enhancing our services. This includes processing
                              transactions, managing user accounts, and optimizing
                              platform functionality. Data is analyzed to assess
                              service performance, identify areas for improvement,
                              and ensure the efficient delivery of services in
                              accordance with our contractual obligations and
                              service agreements.
                            </div>
  
                            <div
                              class="mt-3"
                              style={{
                                fontSize: 20,
                                fontWeight: 700,
                                lineHeight: 2,
                              }}
                            >
                              Personalization and User Experience
                            </div>
                            <div class="mt-3">
                              SkillSquad employs collected data to tailor and
                              personalize user interactions with the platform.
                              This includes customizing content, recommendations,
                              and features to align with user preferences and
                              activity patterns. The utilization of data for
                              personalization aims to enhance user satisfaction
                              and engagement by providing a more relevant and
                              individualized experience in accordance with user
                              preferences and historical interactions.
                            </div>
  
                            <div
                              class="mt-3"
                              style={{
                                fontSize: 20,
                                fontWeight: 700,
                                lineHeight: 2,
                              }}
                            >
                              Communication and Notifications
                            </div>
                            <div class="mt-3">
                              Collected data is used to facilitate communication
                              between SkillSquad and its users. This encompasses
                              sending transactional notifications, updates
                              regarding service changes, promotional materials,
                              and other relevant communications. Notifications may
                              be delivered via email, SMS, or in-platform
                              messages. Such communication is conducted based on
                              user consent and in adherence to applicable
                              regulatory requirements regarding direct marketing
                              and electronic communications.
                            </div>
  
                            <div
                              class="mt-3"
                              style={{
                                fontSize: 20,
                                fontWeight: 700,
                                lineHeight: 2,
                              }}
                            >
                              Compliance with Legal Obligations
                            </div>
                            <div class="mt-3">
                              SkillSquad processes collected data to ensure
                              compliance with applicable legal and regulatory
                              obligations. This includes adherence to data
                              protection laws, responding to legal requests or
                              inquiries, and fulfilling statutory requirements.
                              Data may be used for auditing purposes, reporting to
                              regulatory authorities, and maintaining accurate
                              records in compliance with legal mandates and
                              industry standards.
                            </div>
  
                            <div
                              class="mt-3"
                              style={{
                                fontSize: 20,
                                fontWeight: 700,
                                lineHeight: 2,
                              }}
                            >
                              Data Sharing and Disclosure
                              <br />
                              Sharing with Service Providers
                            </div>
                            <div class="mt-3">
                              SkillSquad may disclose personal and non-personal
                              data to third-party service providers engaged to
                              perform functions on our behalf. These functions may
                              include, but are not limited to, payment processing,
                              data storage, analytics, customer support, and
                              marketing services. Service providers are
                              contractually bound to use such data solely for the
                              purpose of executing their designated tasks and are
                              obligated to maintain stringent confidentiality and
                              security standards as prescribed by applicable data
                              protection laws.
                            </div>
  
                            <div
                              class="mt-3"
                              style={{
                                fontSize: 20,
                                fontWeight: 700,
                                lineHeight: 2,
                              }}
                            >
                              Legal Requirements and Protection
                            </div>
                            <div class="mt-3">
                              SkillSquad reserves the right to disclose personal
                              data when required to do so by law, regulation, or
                              governmental request. This includes disclosure to
                              comply with legal processes, respond to lawful
                              requests from public authorities, enforce our terms
                              of service, protect our rights, privacy, safety, or
                              property, or address any legal claims or disputes.
                              Such disclosures are made in accordance with legal
                              requirements and are limited to the extent necessary
                              to fulfill the specific legal obligation.
                            </div>
  
                            <div
                              class="mt-3"
                              style={{
                                fontSize: 20,
                                fontWeight: 700,
                                lineHeight: 2,
                              }}
                            >
                              Business Transfers
                            </div>
                            <div class="mt-3">
                              In the event of a merger, acquisition,
                              reorganization, or any other form of business
                              transfer, SkillSquad may transfer or disclose
                              personal data to the acquiring entity. Such
                              transfers are conducted in accordance with
                              applicable data protection regulations and are
                              subject to the provisions of this Privacy Policy.
                              The acquiring entity will be bound by obligations to
                              safeguard the data and ensure continuity in its
                              protection in alignment with this policy.
                            </div>
  
                            <div
                              class="mt-3"
                              style={{
                                fontSize: 20,
                                fontWeight: 700,
                                lineHeight: 2,
                              }}
                            >
                              Public Forums and User Profiles
                            </div>
                            <div class="mt-3">
                              SkillSquad provides users with the opportunity to
                              engage in public forums and create user profiles.
                              Any data disclosed in these forums or profiles
                              becomes publicly accessible and is not subject to
                              the same level of confidentiality as other types of
                              data. Users are advised to exercise caution when
                              sharing personal information in these areas and to
                              be aware that such information may be visible to
                              other users and the general public.
                            </div>
  
                            <div
                              class="mt-3"
                              style={{
                                fontSize: 20,
                                fontWeight: 700,
                                lineHeight: 2,
                              }}
                            >
                              Data Security
                              <br />
                              Security Measures
                            </div>
                            <div class="mt-3">
                              SkillSquad implements a comprehensive array of
                              security measures designed to safeguard the
                              integrity, confidentiality, and availability of
                              personal and non-personal data. These measures
                              encompass administrative, technical, and physical
                              safeguards, including but not limited to access
                              controls, firewalls, intrusion detection systems,
                              and regular security audits. Our security protocols
                              are aligned with industry standards and best
                              practices to mitigate risks and protect against
                              unauthorized access, alteration, or disclosure of
                              data.
                            </div>
  
                            <div
                              class="mt-3"
                              style={{
                                fontSize: 20,
                                fontWeight: 700,
                                lineHeight: 2,
                              }}
                            >
                              Data Encryption
                            </div>
                            <div class="mt-3">
                              To enhance the protection of data, SkillSquad
                              employs robust encryption technologies. Personal and
                              sensitive data is encrypted during transmission and
                              at rest using advanced encryption standards. This
                              encryption is designed to prevent unauthorized
                              access and ensure that data remains secure
                              throughout its lifecycle. Despite these measures, no
                              system can provide absolute security; therefore, we
                              cannot guarantee that data breaches will not occur.
                            </div>
  
                            <div
                              class="mt-3"
                              style={{
                                fontSize: 20,
                                fontWeight: 700,
                                lineHeight: 2,
                              }}
                            >
                              User Responsibility
                            </div>
                            <div class="mt-3">
                              Users are responsible for maintaining the
                              confidentiality of their account credentials and for
                              taking reasonable precautions to protect their
                              personal data. This includes safeguarding passwords,
                              monitoring account activity, and reporting any
                              unauthorized access or security incidents to
                              SkillSquad promptly. Users should be aware that
                              sharing credentials or personal information with
                              third parties can compromise data security and that
                              SkillSquad is not liable for any damages resulting
                              from user negligence or failure to adhere to
                              recommended security practices.
                            </div>
  
                            <div
                              class="mt-3"
                              style={{
                                fontSize: 20,
                                fontWeight: 700,
                                lineHeight: 2,
                              }}
                            >
                              User Rights and Control
                              <br />
                              Accessing and Updating Information
                            </div>
                            <div class="mt-3">
                              Users possess the right to access, review, and
                              update their personal information held by
                              SkillSquad. Upon request, SkillSquad will provide
                              users with access to their data, allowing for the
                              correction or completion of any inaccuracies or
                              omissions. Such requests should be submitted in
                              accordance with our procedures for data access and
                              correction, and SkillSquad will undertake to address
                              these requests in a timely manner while ensuring
                              compliance with applicable data protection
                              regulations.
                            </div>
  
                            <div
                              class="mt-3"
                              style={{
                                fontSize: 20,
                                fontWeight: 700,
                                lineHeight: 2,
                              }}
                            >
                              Data Portability
                            </div>
                            <div class="mt-3">
                              SkillSquad acknowledges the right of users to
                              receive their personal data in a structured,
                              commonly used, and machine-readable format. Users
                              may request data portability to facilitate the
                              transfer of their data to another data controller or
                              processor, where technically feasible. Such requests
                              must be made in compliance with our data portability
                              procedures, and SkillSquad will endeavor to comply
                              with these requests within the framework of
                              applicable legal requirements.
                            </div>
  
                            <div
                              class="mt-3"
                              style={{
                                fontSize: 20,
                                fontWeight: 700,
                                lineHeight: 2,
                              }}
                            >
                              Right to Erasure (Right to be Forgotten)
                            </div>
                            <div class="mt-3">
                              Users have the right to request the erasure of their
                              personal data under certain conditions, including
                              but not limited to, instances where the data is no
                              longer necessary for the purposes for which it was
                              collected, or where consent has been withdrawn.
                              SkillSquad will assess such requests on a
                              case-by-case basis and will act in accordance with
                              applicable legal standards to ensure that data is
                              deleted or anonymized in a manner that complies with
                              regulatory obligations and data retention policies.
                            </div>
  
                            <div
                              class="mt-3"
                              style={{
                                fontSize: 20,
                                fontWeight: 700,
                                lineHeight: 2,
                              }}
                            >
                              Opt-Out and Preferences
                            </div>
                            <div class="mt-3">
                              Users may exercise their right to opt out of
                              receiving marketing communications and other
                              non-essential notifications by adjusting their
                              preferences within their account settings or by
                              following the unsubscribe instructions provided in
                              communications. SkillSquad respects user preferences
                              and will process opt-out requests promptly, ensuring
                              that users' preferences are duly recorded and
                              honored in accordance with relevant regulations
                              governing electronic communications and data
                              protection.
                            </div>
  
                            <div
                              class="mt-3"
                              style={{
                                fontSize: 20,
                                fontWeight: 700,
                                lineHeight: 2,
                              }}
                            >
                              Cookies and Tracking Technologies
                              <br />
                              Use of Cookies
                            </div>
                            <div class="mt-3">
                              SkillSquad employs cookies and other tracking
                              technologies to enhance user experience, facilitate
                              the functionality of our services, and gather
                              analytical data. Cookies are small data files placed
                              on users' devices that enable us to remember
                              preferences, track user activity, and provide
                              tailored content. The use of these technologies is
                              essential for the operation of certain features and
                              functionalities of the SkillSquad platform, as well
                              as for optimizing performance and usability.
                            </div>
  
                            <div
                              class="mt-3"
                              style={{
                                fontSize: 20,
                                fontWeight: 700,
                                lineHeight: 2,
                              }}
                            >
                              Types of Cookies Used
                            </div>
                            <div class="mt-3">
                              <li>
                                Essential Cookies: These cookies are crucial for
                                the operation of the website and enable core
                                functionalities such as user authentication and
                                session management. Without these cookies,
                                essential features of the platform may not
                                function properly.
                              </li>
                              <li>
                                Performance Cookies: These cookies collect data on
                                user interactions with the platform, including
                                metrics such as page visits and error rates. This
                                information is used to enhance the performance and
                                efficiency of the website.
                              </li>
                              <li>
                                Functional Cookies: These cookies allow the
                                platform to remember user preferences and
                                settings, providing a more personalized
                                experience. Functional cookies may include
                                language preferences, display settings, and other
                                customizations.
                              </li>
                              <li>
                                Targeting and Advertising Cookies: These cookies
                                track user behavior across websites to deliver
                                targeted advertisements and measure the
                                effectiveness of marketing campaigns. They may
                                also be used to build user profiles for more
                                relevant advertising.
                              </li>
                            </div>
  
                            <div
                              class="mt-3"
                              style={{
                                fontSize: 20,
                                fontWeight: 700,
                                lineHeight: 2,
                              }}
                            >
                              Managing Cookie Preferences
                            </div>
                            <div class="mt-3">
                              Users have the ability to manage their cookie
                              preferences through their browser settings. Most
                              browsers allow users to block or delete cookies, or
                              to receive notifications when cookies are being
                              placed. Additionally, users may modify their
                              preferences for specific types of cookies via the
                              settings provided on our platform. Please note that
                              disabling cookies may affect the functionality and
                              performance of the SkillSquad platform and may limit
                              the user experience.
                            </div>
  
                            <div
                              class="mt-3"
                              style={{
                                fontSize: 20,
                                fontWeight: 700,
                                lineHeight: 2,
                              }}
                            >
                              Third-Party Links and Services
                              <br />
                              Links to External Sites
                            </div>
                            <div class="mt-3">
                              The SkillSquad platform may contain hyperlinks to
                              external websites and resources operated by third
                              parties. Such links are provided for the convenience
                              of users and do not signify endorsement or
                              affiliation with these external entities. SkillSquad
                              is not responsible for the content, privacy
                              practices, or security measures of these external
                              sites. Users are advised to review the privacy
                              policies and terms of service of any third-party
                              sites they visit. SkillSquad disclaims any liability
                              for the actions or omissions of third parties and
                              for any damages arising from interactions with
                              external sites.
                            </div>
  
                            <div
                              class="mt-3"
                              style={{
                                fontSize: 20,
                                fontWeight: 700,
                                lineHeight: 2,
                              }}
                            >
                              Third-Party Services and Integrations
                            </div>
                            <div class="mt-3">
                              SkillSquad may integrate or utilize third-party
                              services and applications to enhance platform
                              functionality and user experience. These third-party
                              services may include payment processors, analytics
                              providers, marketing tools, and other integrations.
                              The collection, use, and management of personal data
                              by such third parties are governed by their
                              respective privacy policies and terms of service.
                              SkillSquad does not assume responsibility for the
                              data handling practices of these third parties and
                              encourages users to review their privacy policies to
                              understand how their data is managed.
                            </div>
  
                            <div
                              class="mt-3"
                              style={{
                                fontSize: 20,
                                fontWeight: 700,
                                lineHeight: 2,
                              }}
                            >
                              Data Retention
                              <br />
                              Retention Period
                            </div>
                            <div class="mt-3">
                              SkillSquad retains personal and non-personal data
                              for a duration commensurate with the purpose for
                              which it was collected and in accordance with
                              applicable legal, regulatory, and contractual
                              obligations. Data retention periods may vary based
                              on the type of data, the purpose of processing, and
                              statutory requirements. Generally, data will be
                              retained for as long as necessary to fulfill the
                              purposes outlined in this Privacy Policy, after
                              which it will be securely disposed of or anonymized.
                            </div>
  
                            <div
                              class="mt-3"
                              style={{
                                fontSize: 20,
                                fontWeight: 700,
                                lineHeight: 2,
                              }}
                            >
                              Criteria for Retention
                            </div>
                            <div class="mt-3">
                              The retention of data by SkillSquad is governed by
                              the following criteria:
                              <li>
                                Legal and Regulatory Requirements: Data is
                                retained for the period necessary to comply with
                                legal obligations, including statutory retention
                                periods and regulatory requirements.
                              </li>
                              <li>
                                Contractual Obligations: Data is retained as long
                                as required to fulfill contractual obligations and
                                to support ongoing business operations or service
                                delivery.
                              </li>
                              <li>
                                Operational Necessity: Data is retained to ensure
                                the effective operation of the platform, including
                                customer support, dispute resolution, and auditing
                                purposes.
                              </li>
                              <li>
                                User Consent: Where data processing is based on
                                user consent, the data will be retained until
                                consent is withdrawn or until the purpose for
                                which consent was granted has been fulfilled.
                              </li>
                              <br />
                              SkillSquad undertakes to periodically review data
                              retention practices and to implement measures to
                              ensure compliance with data protection regulations
                              and to mitigate risks associated with data
                              retention.
                            </div>
  
                            <div
                              class="mt-3"
                              style={{
                                fontSize: 20,
                                fontWeight: 700,
                                lineHeight: 2,
                              }}
                            >
                              International Data Transfers
                              <br />
                              Data Transfers Outside Your Country
                            </div>
                            <div class="mt-3">
                              SkillSquad operates on a global scale and may
                              transfer personal data across international borders
                              to facilitate the provision of services and to
                              support operational activities. Such transfers
                              involve sending data from one jurisdiction to
                              another, including to countries that may not have
                              the same level of data protection as the user's
                              country of residence. By using our platform and
                              services, users consent to the transfer of their
                              personal data to countries outside their country of
                              residence, including jurisdictions where SkillSquad
                              has its operational infrastructure.
                            </div>
  
                            <div
                              class="mt-3"
                              style={{
                                fontSize: 20,
                                fontWeight: 700,
                                lineHeight: 2,
                              }}
                            >
                              Safeguards in Place
                            </div>
                            <div class="mt-3">
                              To ensure the protection of personal data during
                              international transfers, SkillSquad implements
                              appropriate safeguards as required by applicable
                              data protection laws. These safeguards include:
                              <br />
                              <li>
                                Standard Contractual Clauses (SCCs): Where data is
                                transferred to jurisdictions lacking adequate data
                                protection standards, we utilize Standard
                                Contractual Clauses approved by regulatory
                                authorities to ensure that the data is afforded a
                                level of protection comparable to that required in
                                the user's country of residence.
                              </li>
                              <li>
                                Binding Corporate Rules (BCRs): For intra-group
                                transfers, SkillSquad may implement Binding
                                Corporate Rules that establish a comprehensive
                                framework for data protection and compliance with
                                international data protection standards.
                              </li>
                              <li>
                                Data Transfer Agreements: We may enter into
                                specific data transfer agreements with third
                                parties to ensure that any transferred data is
                                protected in accordance with applicable data
                                protection regulations.
                              </li>{" "}
                              <br /> SkillSquad continually reviews and updates
                              its data transfer mechanisms to ensure compliance
                              with evolving legal requirements and to maintain the
                              integrity and security of personal data.
                              <div
                                class="mt-3"
                                style={{
                                  fontSize: 20,
                                  fontWeight: 700,
                                  lineHeight: 2,
                                }}
                              >
                                Children s Privacy
                                <br />
                                Age Restrictions
                              </div>
                              <div class="mt-3">
                                SkillSquad's platform is accessible to individuals
                                aged 13 and above. Users aged 13 to 18 may access
                                and use the platform under the supervision of a
                                parent or legal guardian. Individuals aged 18 and
                                over are permitted to use the platform
                                independently. We do not knowingly collect or
                                process personal data from individuals under the
                                age of 13. If we become aware that personal data
                                has been collected from a Minor without
                                appropriate consent, we will take immediate action
                                to delete the data and terminate any associated
                                accounts.
                              </div>
                              <div
                                class="mt-3"
                                style={{
                                  fontSize: 20,
                                  fontWeight: 700,
                                  lineHeight: 2,
                                }}
                              >
                                Parental Consent
                              </div>
                              <div class="mt-3">
                                For users aged 13 to 18, SkillSquad requires that
                                parental or guardian consent be obtained prior to
                                the collection, use, or disclosure of personal
                                data. Parents or guardians may review, amend, or
                                request the deletion of their child's personal
                                data. To exercise these rights or address any
                                concerns, parents or guardians should contact
                                SkillSquad directly. We are committed to ensuring
                                that personal data of minors is handled in
                                compliance with applicable data protection laws
                                and regulations.
                              </div>
                              <div
                                class="mt-3"
                                style={{
                                  fontSize: 20,
                                  fontWeight: 700,
                                  lineHeight: 2,
                                }}
                              >
                                Changes to This Privacy Policy
                                <br />
                                Notification of Changes
                              </div>
                              <div class="mt-3">
                                SkillSquad reserves the right to amend or update
                                this Privacy Policy at its sole discretion.
                                Material changes to this policy will be
                                communicated to users through appropriate means,
                                including but not limited to, notifications via
                                email, platform announcements, or updates posted
                                on our website. Continued use of the platform
                                following the implementation of changes
                                constitutes acceptance of the revised policy.
                                Users are encouraged to review this Privacy Policy
                                periodically to remain informed of any updates or
                                modifications.
                              </div>
                              <div
                                class="mt-3"
                                style={{
                                  fontSize: 20,
                                  fontWeight: 700,
                                  lineHeight: 2,
                                }}
                              >
                                Version History
                              </div>
                              <div class="mt-3">
                                This Privacy Policy is subject to periodic review
                                and revision. The version history of the Privacy
                                Policy, including the date of the last update,
                                will be documented and available for reference.
                                Users may access the version history to track
                                changes made to the policy over time. The most
                                current version of the Privacy Policy supersedes
                                all prior versions and is effective as of the date
                                specified at the beginning of this document.
                              </div>
                              <div
                                class="mt-3"
                                style={{
                                  fontSize: 20,
                                  fontWeight: 700,
                                  lineHeight: 2,
                                }}
                              >
                                Changes to Contact Information
                                <br />
                                How to Contact Us
                              </div>
                              <div class="mt-3">
                                Users seeking to inquire about or address any
                                concerns related to this Privacy Policy, or who
                                wish to exercise their rights under applicable
                                data protection laws, may contact SkillSquad using
                                the following methods:
                                <br />
                                <br />
                                <li>Email: contactus@skillsquad.io</li>
                              </div>
                              <div
                                class="mt-3"
                                style={{
                                  fontSize: 20,
                                  fontWeight: 700,
                                  lineHeight: 2,
                                }}
                              >
                                Data Protection Officer (DPO) Information
                              </div>
                              <div class="mt-3">
                                SkillSquad has appointed a Data Protection Officer
                                (DPO) to oversee compliance with data protection
                                laws and to act as a point of contact for
                                privacy-related matters. The DPO can be reached
                                at:
                                <br/><br/>
                                <li>Email: contactus@skillsquad.io
                                </li><br/>The DPO is responsible for addressing inquiries regarding the processing of personal data, handling requests related to data protection rights, and ensuring that SkillSquad adheres to applicable data protection regulations.
  
                              </div>
                              
                            </div>
                          </div>
                        </div>
                        {/* <div className="col"></div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            {/* <NewFooter /> */}
          </div>
        </div>
      </>
    );
  };
  
  export default PrivacyPolicy;