import React from "react";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Routes, Route, useLocation, useParams } from "react-router-dom";
import "./styles/App.css";
import Navb from "./pages/Navb";
import Programs from "./pages/Programs";
import BatchDetails from "./pages/BatchDetails";
import BatchList from "./pages/BatchList";
import Assignments from "./pages/Assignments";
import UserAssignment from "./pages/assignments/Assignments";
import ProgramDetails from "./pages/ProgramDetails";
import SignInBS from "./pages/SignInBS"
import { useEffect, useState } from 'react';
import UpdateSelf from "./pages/UserProfile/UpdateSelf"
import MentorLanding from './pages/MentorLandingPage/MentorLanding'
import MentorProfile from "./pages/UserProfile/MentorProfile"
import JobDescription from "./pages/JobDescription";
import Home from "./pages/Home";
import AssignedAssignments from "./pages/assignments/AssignedAssignments";
import ReceivedAssignments from "./pages/assignments/ReceivedAssignments";
import AssignmentsList from "./pages/assignments/AssignmentsList";
import AddCohort from "./pages/assignments/AddCohort";
import AddLinks from "./pages/assignments/AddLinks";
import S_dashboard from "./pages/S_dashboard";
import Videos from "./pages/Videos";
import TrailVideos from "./pages/TrailVideos";
import CreateUsers from "./pages/CreateUsers";
import TrailPrograms from "./pages/TrailPrograms";
import JobReferral from "./pages/JobReferral";
import Offline from "./pages/Offline"
import LiveClasses from "./pages/LiveClasses";
import PeerMock from "./pages/PeerMock";
import Support from "./pages/support";
import Pricing from "./components/Pricing";
import SignUpBS from "./pages/SignUpBS";
import Homes from './screens/Home';
import Playground from './screens/Playground';
import Error404 from './screens/Error404';
import { GlobalStyle } from './style/global';
import ModalProvider from './context/ModalContext';
import PlaygroundProvider from './context/PlaygroundContext';
import FrontEnd from "./components/FrontEnd";
import NewCoursesVideos from "./pages/NewCoursesVideos";
import DayChallenge from "./pages/DayChallenge";
import DayChallengeComplete from "./pages/DayChallengeComplete";
import CompletedTopicVideo from "./pages/coursedetails/CompletedTopicVideo";
import TopicVideo from "./pages/coursedetails/TopicVideo";
import Quiz from "./components/App/index"
import AssignmentEditor from "./components/AssignmentEditor";
import CompletedAssignmentEditor from "./components/CompletedAssignmentEditor";
import CareerHelp from "./pages/CareerHelp";
import ProjectDescription from "./pages/ProjectDescription";
import DayTopicVideo from "./pages/coursedetails/DayTopicVideo";
import DayChallengeCompleteStatus from "./components/DayChallengeCompleteStatus";
import Discussion from "./pages/Discussion";
import DiscussionPopup from "./components/DiscussionPopup";
import DayCircullum from "./components/DayCircullum";
import RefundPolicy from "./pages/policypages/RefundPolicy"
import CodeOfConduct from "./pages/policypages/CodeOfConduct";
import PrivacyPolicy from "./components/Footer/PrivacyPolicy";
import MentorTermsPage from "./components/Footer/MentorTermsPage";
import CoursePageSEO from "./components/CoursePageSEO";
import CoursePage from "./components/CoursePage";
import SkillsquadProject from "./pages/SkillsquadProject";
import SkillsquadHomePage from "./pages/SkillsquadHomePage";
import Allcourse from "./pages/Allcourse";
import CurricullumVideosVideo from "./pages/coursedetails/CurricullumVideos";
import ReactGA from 'react-ga';
import { initGA, logPageView } from './analytics'; 
import {PopupProvider} from './components/PopupContext'

import httpsignup from "./js/http-lms_userprofile";
import SlotBookingPage from "./pages/UserProfile/SlotBookingPage";
import MentorListing from "./pages/AllMentors/MentorListing";
// import JoinMeeting from "./pages/JoinMeeting";
import UserSessionDetails from './pages/UserSessionDetails'
// import LoginPage from './components/NewLoginPage/LoginPage'
//import CustomDatePicker from "./components/CustomDatePicker";
import { MentorProvider } from "./pages/MentorLandingPage/MentorDataContext";
import NewNavbar from "./components/NewNavbar";
import {AboutUs} from './pages/BecomeMentorLandingPage/page files/AboutUs'
import MobileApplication from "./components/MobileApplication";
import ProtectedRoute from "./components/ProtectedRoute";


const TRACKING_ID = "G-4G5NL7FVK9";
ReactGA.initialize(TRACKING_ID);




function App() {
  const handleBlockInspect = (event) => {

  }
const {mainPath} = useParams();
  // useEffect(() => {
  //   setInterval(() => {
  //     httpsignup
  //     .put(`/userprofileLMS/updateLogin?signUp=${sessionStorage.getItem("userId")}`)
  //     .then((response) => {
  //       console.log(response);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  //   }, 5000)
  // })

  useEffect(() => { 
    initGA(); 
  }, []);

  const [connection, SetConnection] = useState("")
  useEffect(() => {
    window.addEventListener('contextmenu', handleBlockInspect);
    return function cleanup() {
      window.removeEventListener('contextmenu', handleBlockInspect);
    }
  }, [])

  useEffect(() => {
    setInterval(() => {
      if (window.navigator.onLine) {
        SetConnection("Online")
      } else {
        SetConnection("Offline")
      }
    }, 1000)
  })
 const ConditionalComponent = () => {
    const { mainPath } = useParams();
  
    return mainPath === "mentor-page" ? <MentorLanding /> : <MentorListing />;
  };
  return (
    <>
      {connection === "Online" && (
        <PlaygroundProvider>
           <PopupProvider>
           <MentorProvider>
          <ModalProvider>
            <BrowserRouter>
              <GlobalStyle />
              <NewNavbar />
              <Routes>
              <Route path='/:mainPath/:service/:category' element={<ConditionalComponent />} />
              <Route path='/:mainPath/:service/:category' element={<ConditionalComponent />} />
              <Route path='/:mainPath/:service/:category/:offername' element={<ConditionalComponent />} />
              <Route path="/:uniqueUserName" element={<MentorProfile />} />
              
              <Route path="/become-a-mentor" element={<AboutUs />} />
              <Route path="/nav" element={<NewNavbar />} />
              <Route path="/:uniqueUserName/:id" element={<SlotBookingPage />} />
              {/* <Route path="/:" element={<SlotBookingPage />} /> */}
              <Route path="/mentors/1:1-services/recommended" element={<MentorListing />} />
                <Route path="/homes" element={<Homes />} />
                {/* <Route path="/custom" element={<CustomDatePicker />} /> */}
                {/* <Route path="/user-login" element={<LoginPage />} /> */}
                <Route path="/" element={<ProtectedRoute><MentorLanding /></ProtectedRoute>} />
                <Route path="/session-details" element={<UserSessionDetails />} />
                {/* <Route path="/meeting" element={<JoinMeeting />} /> */}
                <Route path="/all-mentors/mentor-profile" element={<MentorProfile />} />
                <Route path="/:uniqueUserName" element={<UpdateSelf />} />
                <Route path="/playground/:folderId/:playgroundId" element={<Playground />} />
                <Route path="*" element={<Error404 />} />
                <Route path="/sign-up" element={<SignUpBS />} />
                <Route path="/sign-up/:checkout" element={<SignUpBS />} />
                <Route path="/sign-in" element={<SignInBS />} />
                <Route path="/user-profile" element={<UpdateSelf />} />
                <Route path="/edit-profile" element={<AssignedAssignments />} />
                <Route path="/add-education" element={<ReceivedAssignments />} />
                <Route path="/add-experience" element={<AssignmentsList />} />
                <Route path="/add-cohart" element={<AddCohort />} />
                <Route path="/add-links" element={<AddLinks />} />
                <Route path="/activity" element={<BatchList />} />
                <Route path="/build-project" element={<Programs />} />
                <Route path="/view-modules" element={<BatchDetails />} />
                <Route path="/job-portal" element={<ProgramDetails />} />
                <Route path="/job-description/:jobDes" element={<JobDescription />} />
                <Route path="/home" element={<Home />} />
                <Route path="/dashboard" element={< TrailPrograms />} />
                <Route path="/my-course" element={<S_dashboard />} />
                <Route path="/course-details/:id" element={<Videos />} />
                <Route path="/assignments" element={<UserAssignment />} />
                <Route path="/videos" element={<TrailVideos />} />
                <Route path="/my-course-videos" element={<Videos />} />
                <Route path="/my-new-course-videos" element={<NewCoursesVideos />} />
                <Route path="/add-user" element={<CreateUsers />} />
                <Route path="/job-referral" element={<JobReferral />} />
                <Route path="/live-classes" element={<LiveClasses />} />
                <Route path="/peer-mock" element={<PeerMock />} />
                <Route path="/pricing" element={<Pricing />} />
                <Route path="/support" element={<Support />} />
                <Route path="/day-challenge" element={<DayChallenge />} />
                <Route path="/topic-video" element={<TopicVideo />} />
                <Route path="/complete-topic-video" element={<CompletedTopicVideo />} />
                <Route path="/frontend" element={<FrontEnd />} />
                <Route path="/quiz" element={<Quiz />} />
                <Route path="/day-challenge-complete" element={<DayChallengeComplete />} />
                <Route path="/assignment-editor" element={<AssignmentEditor />} />
                <Route path="/completed-assignment-editor" element={<CompletedAssignmentEditor />} />
                <Route path="/career-help" element={<CareerHelp />} />
                <Route path="/project-description" element={<ProjectDescription />} />
                <Route path="/day-topic-video" element={<DayTopicVideo />} />
                <Route path="/day-challenge-complete-status" element={<DayChallengeCompleteStatus/>} />
                <Route path="/discussion" element={<Discussion/>} />
                <Route path="/discussion-popup" element={<DiscussionPopup/>} />
                <Route path="/day-circullum" element={<DayCircullum/>} />
                <Route path="/refund-policy" element={<RefundPolicy/>} />
                <Route path="/code-of-conduct" element={<CodeOfConduct/>} />
                <Route path="/privacy-policy" element={<PrivacyPolicy/>} />
                <Route path="/terms-and-conditions" element={<MentorTermsPage/>} />
                <Route path="/projects" element={<SkillsquadProject/>} />
                <Route path="/course" element={<Allcourse/>} />
                <Route path="/courses/:courseTag/:location" element={<CoursePageSEO />} />
                <Route path="/courses/:courseTag" element={<CoursePage/>} />
                {/* <Route path="/" element={<SkillsquadHomePage/>} /> */}
                <Route path="/curricullm_videos" element={<CurricullumVideosVideo/>} />
              </Routes>
              <MobileApplication />
            </BrowserRouter>
          </ModalProvider>
          </MentorProvider>
          </PopupProvider>
        </PlaygroundProvider>)}

     

        {connection === "Offline" && (
        // <div className="d-flex justify-content-center align-items-center wrapperOffline">
        // <div className="msgBoxOffline py-4 px-5 d-flex">
        //   <h3>You are offline.</h3>
        //   <img
        //             src={require("../src/images/offline2.png")}
        //             alt=""
        //             className="ms-3 offline-icon"
        //           />
        // </div>
        // </div>
        <div>

          <Offline />
        </div>
      )}

    </>
  );
}

const RouteTracker = () => { 
  const location = useLocation(); 
  useEffect(() => {
    logPageView(); 
  }, [location]); 
  
  return null; 
};

export default App;


