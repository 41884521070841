import React from 'react'
import Sidebar from "../Sidebar"
import Navb from "../Navb";
import { useState, useEffect } from "react";
import Assigned from './Assigned';
import Spinner from 'react-bootstrap/Spinner';
import Submited from './Submited';
import { useNavigate} from "react-router-dom";


const Assignments = () => {

    const [button, setButton] = useState(1);
    const [collapse, setCollapse] = useState(1);
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    useEffect((e)=> {
        setLoading(true)
        setTimeout(()=>{
          setLoading(false)
        },1000)}, [button])

    function buttonClick(e) {
        if (e.target.className === "btn1 profile-buttons mt-2 me-2") {
          setButton(1);
          setCollapse(1);
        } else if (e.target.className === "btnx profile-buttons mx-md-3 mt-2 me-2") {
          setButton(2);
          setCollapse(2);
        } 
      }


  return (
    <div>
      <Sidebar />
      {/* Main Container */}
      <div className="jobs-dashboard-container mx-4 mt160">
        {/* <Navb /> */}
        <div className="px-md-5 mt160">
          <div className="d-md-flex mb-4 mt-4 ">
            <h1 className="me-2 mt-2">Assignments List</h1>
            <div className="d-flex m-left-auto">
              <div className="me-3">
                <button onClick={buttonClick} className={button === 1 ? "active-button profile-buttons mt-2 me-2" : "btn1 profile-buttons mt-2 me-2"}>
                  Assigned
                </button>
                <button onClick={buttonClick} className={button === 2 ? "active-button profile-buttons mx-md-3 mt-2 me-2": "btnx profile-buttons mx-md-3 mt-2 me-2"}>
                  Submited
                </button>
              </div>
              {/* <div className="d-flex">
                <h6 className="me-2 mt-2">Filter by</h6>
                <img src={require("../../images/filter.png")} alt="Image not found" className="filter-icon mt-2"/>
              </div> */}
            </div>
          </div>
          <div className="scrollme">
            {collapse === 1 && <Assigned />}
            {collapse === 2 && <Submited />}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Assignments
