import React, { useEffect } from 'react'
import Sidebar from "./Sidebar";
import httpsignup from '../js/http-lms_userprofile';
import { useState } from 'react';
import Popup from "../components/CommunityPopup"
import moment from "moment/moment";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import ProfilePopup from "../components/CompleteProfilePopup"
import CareerHelpView from './CareerHelpView';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons'
import "../styles/common.css";
import RightSidebar from "./RightSidebar";
import Spinner from 'react-bootstrap/Spinner';
import { useNavigate } from "react-router-dom";
import { trackEvent } from '../facebookPixel';
import { usePopup } from '../components/PopupContext';

const CareerHelp = () => {
    const [image, setImage] = useState(
        "../images/ProfilePlaceholder.png"
    );
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isProfilePopupOpen, setIsProfilePopupOpen] = useState(false);
    const [profileId, setProfileId] = useState();
    const [postList, setPostList] = useState([])
    const [postData, setPostData] = useState({})
    const [profileData, setProfileData] = useState({})
    const [careerHelp, setCareerHelp] = useState(false)
    const [defaultDetails, setDetails] = useState({})
    const [reply, setReply] = useState()
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate()
    const openPopup = () => {
        setIsPopupOpen(true);
    };

    const closePopup = () => {
        getPostList()
        setIsPopupOpen(false);
    };

    
    
    
    
    
    
    
    
    
const {hideNavButtons} = usePopup();
    const openProfilePopup = () => {
        setIsProfilePopupOpen(true);
        hideNavButtons();
    };

    const closeProfilePopup = () => {
        setIsProfilePopupOpen(false);
        hideNavButtons();
    };

    useEffect(() => {
        trackEvent('ViewContent');
        getLoggedInUserDetails()
        getPostList()
        // setInterval(getPostList, 3000); 
    }, [])
    const getLoggedInUserDetails = async () => {
        httpsignup.get(`/skillsquadUserprofileLMS/getSkillsquadUserProfileBySignUpId?signUpId=${sessionStorage.getItem('userId')}`)
            .then((response) => {
                sessionStorage.setItem("circullumpId", response.data.lmsProfileId);
                sessionStorage.setItem("skillsquadProfileId", response.data.profileId);
                console.log("RESPONSE Profile: " + JSON.stringify(response.data))
                if (response.data.uniqueUserName == null) {
                    openProfilePopup()
                }else{
                    if( sessionStorage.getItem("checkoutCourse") != null){
                        navigate("/courses/" +sessionStorage.getItem("checkoutCourse"))
                    }
                    if(sessionStorage.getItem("freeTrailId") != null){
                        
                        sessionStorage.setItem("myCourseProgramId",sessionStorage.getItem("freeTrailId"))
                        navigate("/day-circullum")
                    }
                }
                setProfileData(response.data)
                setImage(response.data.profilePictureUrl);
                setProfileId(response.data.profileId)
            }).catch((err) => {
                console.log(err)
            })
    }

    function getPostList() {
        setLoading(true)
        console.log("defaultDetails : " + JSON.stringify(defaultDetails))
        setDetails({})
        console.log("defaultDetails : " + JSON.stringify(defaultDetails))
        httpsignup.get(`/skillsquadUserprofileLMS/getCommunityPosts`)
            .then((response) => {
                console.log("RESPONSE : " + JSON.stringify(response.data))
                setLoading(false)
                setPostList(response.data)
            }).catch((err) => {
                setLoading(false)
                console.log(err)
            })
    }
    function openCareerView(post) {
        setPostData(post)
        setCareerHelp(true)
    }

    function closeCareerView() {
        setPostData({})
        setCareerHelp(false)
        getPostList()
    }


    function saveReply(postId) {

        httpsignup.post(`/skillsquadUserprofileLMS/saveCommunityReply`, {
            "profileId": profileId,
            "replyText": "<p>" + message + "</p>",
            "postId": postId
        })
            .then((response) => {
                handleClick()
                getPostList()
            }).catch((err) => {
                console.log(err)
            })

    }
    function clear() {
        setDetails({})
    }

    function handleChange(e) {
        // setErrorMessage('')
        const { name, value } = e.target;
        setDetails((pValue) => {
            console.log(pValue)
            return {
                ...pValue,
                [name]: value
            }
        })
        console.log("after change : ", defaultDetails)
    }

    const handleChanges = event => {
        setMessage(event.target.value);
    };

    const handleClick = () => {
        // 👇️ clear input value
        setMessage('');
    };

    return (
        <>
            <Sidebar />
            <div className="jobs-dashboard-container mx-4">
                {isPopupOpen && <Popup onClose={closePopup} profileId={profileId} profileData={profileData} />}
                {isProfilePopupOpen && <ProfilePopup onClose={closeProfilePopup} />}
                {/* <Navb /> */}
                {careerHelp == false ?
                    <div className="px-md-5 mt160">

                    <section class="section">
                        <div class="container">

                            <div class="row">

                                <div class="col-lg-10">
                                    <div class="row">
                                        <div class="">
                                            <div class="section-title  mb-4 pb-2">
                        <div style={{ marginLeft: "38%" }} class="common_main_heading">Get career help</div>
                        <div class=" mt-4 common_main_sub_heading" style={{ marginLeft: "25%" }}>or share your expertise with the Skillsquad community</div>
                        <div  onClick={openPopup} class="mt-4 d-flex align-items-center careerhelp-post" >

                            <div class="container">
                                <div class="row">
                                    <div class="col d-flex align-items-center">
                                        <img referrerPolicy="no-referrer" className='admin-pro-pic my-1 me-2 careerhelp-img' style={{ width: 40, height: 40 }} src={profileData.profilePictureUrl} alt="okay" />
                                        <div  class="common_main_text_normal">Ask for help from the community...</div>
                                    </div>

                                    <div class="col col-lg-1 d-flex align-items-center" style={{marginRight: 10}}>
                                        {/* <img className='admin-pro-pic my-1 me-2' style={{ width: 25, height: 25 }} src={require("../images/attach_icon.png")} alt="okay" />
                                        <div class="d-flex align-items-center" style={{ backgroundColor: "black", color: "white", padding: "5% 10% 5% 10%", borderRadius: 10 }}><div>Post</div>
                                            <img style={{ width: 25, height: 25, marginLeft: 10 }} src={require("../images/post_icon.png")} alt="okay" />

                                        </div> */}
                                        <div class="careerhelp-img" ><FontAwesomeIcon icon={faCirclePlus} /></div>

                                    </div>

                                </div>
                            </div>




                            <div></div>

                        </div>
                        {loading ? <div className="d-flex py-5 justify-content-center w-100"><Spinner animation="border" variant="lightsecondary" /> </div> :

                        (postList.length > 0) ?
                            <div>
                                {postList.map((post, i) => {
                                    return (
                                        <div class="mt-4" style={{ width: "60%", border: "1px solid rgb(182, 182, 182)", padding: "1%", borderRadius: 10, marginLeft: "15%", boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19)" }}>
                                            <div class="container">
                                                <div class="row">
                                                    <div class="col d-flex align-items-center">
                                                        <img className='admin-pro-pic my-1 me-2' style={{ width: 60, height: 60 }} src={post.profileUrl} alt="okay" />
                                                        <div  ><div class="common_main_text_highlight">{post.profileName}</div><div class="d-flex align-items-center">
                                                            <div class="common_main_text_normal text-muted mb-0">{post.role}</div>
                                                            <img style={{ width: 5, height: 5, margin: "0px 5px 0px 5px" }} src={require("../images/dot_icon.png")} alt="okay" />
                                                            <p class="text-muted mb-0 common_main_text_normal" > {moment.utc(post.createdAt).local().startOf('seconds').fromNow()}</p>
                                                        </div>
                                                        </div></div>


                                                    <div class="col col-lg-3 d-flex align-items-center">

                                                    </div>

                                                </div>
                                                <div class="row">
                                                    <div class="col-4">
                                                        <div class="mt-2 text-center common_main_text_normal" style={{ backgroundColor: '#F4f4f4', padding: "5px 15px 5px 15px", borderRadius: 50, border: "1px solid grey" }}>{post.postCategory}</div>


                                                    </div>
                                                    <div class="mt-2 common_main_text_highlight">{post.postTitle}</div>

                                                    {post.postDesc.length > 500 ? <div class=""> <div class="mt-2 sr common_main_text_normal" >{post.postDesc}</div><div onClick={() => { openCareerView(post) }} style={{ cursor: 'pointer' }}>...more</div></div> : <div class="mt-2 common_main_text_normal" >{post.postDesc}</div>}
                                                   
                                                    <div class="d-flex align-items-center mt-2 mb-2 common_main_text_normal" style={{ color: "blue" }}>
                                                        {post.skillsquadCommunityHashtagMasterList != null && post.skillsquadCommunityHashtagMasterList.map((hash) => {
                                                            return (<div style={{ marginRight: 10 }}>{hash.hasttagName}</div>)
                                                        })}

                                                    </div>
                                                    {post.attachUrl != null &&
                                                        <div class="row">
                                                            <div class="col">
                                                                <div class="d-flex align-items-center" onClick={() => { window.open(post.attachUrl, "_blank") }} style={{ backgroundColor: "#F4f4f4", padding: "2%", borderRadius: 10, cursor: "pointer" }}>
                                                                    <div> <img src={require("../images/paper_icon.png")} width={100} style={{ paddingRight: 10 }} alt="paper" /></div> <div > <div class="common_main_text_highlight" >{post.fileName.split("/")[1]}</div><div class='common_main_text_normal'>{post.fileSize.toFixed(2)} MB</div></div></div>

                                                            </div></div>}
                                                    <hr class="mt-2" style={{ margin: 0, padding: 0, border: "1px solid black" }}></hr>
                                                    <div class="community mb-3">
                                                        <div class="community-left"> <div class="d-flex align-items-center mt-2 mb-2 common_main_text_highlight" onClick={() => { openCareerView(post) }} style={{ cursor: 'pointer' }}> <img style={{ width: 20, height: 20, marginRight: 5 }} src={require("../images/reply_icon.png")} alt="okay" /><div>{post.repliesCount}</div></div></div>

                                                    </div>
                                                    <div class="hover-effect" style={{ borderRadius: 50, border: "1px solid grey", padding: 5 }}>
                                                        <div class="d-flex align-items-center">
                                                            <img referrerPolicy="no-referrer" className='admin-pro-pic my-1 me-2' style={{ width: 30, height: 30 }} src={profileData.profilePictureUrl} alt="okay" />
                                                            {/* <Form.Control

                                                                placeholder="Post your comment"
                                                                autoComplete="off"
                                                                name='postReply'
                                                                class="border-0"
                                                                
                                                                onChange={handleChange}
                                                                style={{ border: 0 }}
                                                                value={defaultDetails.postReply}
                                                            /> */}
                                                            <input
                                                                id="message"
                                                                name="message"
                                                                type="text"
                                                                onChange={handleChanges}
                                                                value={message}
                                                                style={{ border: 0, width: "100%", padding: 10 }}
                                                                placeholder="Post your comment"
                                                            />

                                                            <img referrerPolicy="no-referrer" className='admin-pro-pic my-1 me-2' style={{ width: 30, height: 30, marginLeft: 5, cursor: "pointer" }} src={require("../images/up_arrow_icon.png")} alt="okay" onClick={() => { saveReply(post.postId) }} />
                                                        </div>

                                                    </div>

                                                </div>




                                            </div>
                                        </div>
                                    )
                                })
                                }

                            </div> : <div class="d-flex align-items-center justify-content-center mt-5" >

                                <div class="liveclasses-schedule">No Posts Available</div>




                            </div>

                        }




</div></div></div></div></div></div></section></div> : <div>{careerHelp && <CareerHelpView postData={postData} closeCareerView={closeCareerView} profileId={profileId}/>}</div>}</div>
                    <RightSidebar />
        </>
    )
}

export default CareerHelp