import React from "react";
import Sidebar from "./Sidebar";
import "../fonts/Roboto/Roboto-Black.ttf";
import "../styles/Dashboard.css";
import "../styles/Student.css";
import Navb from "./Navb";
import ProgressBar from "react-bootstrap/ProgressBar";
import { useState, useEffect } from "react";
import httpuserdetails from '../js/http-lms-userdetails';
import httpvideos from "../js/http-lms_videos"
import { useNavigate } from "react-router-dom";
import Spinner from 'react-bootstrap/Spinner';
import NoRecords from '../components/NORecFound';
import showPwdImg from '../images/showpwd.svg';
import TrailPrograms from "./TrailPrograms";
import httpsignup from '../js/http-lms_userprofile';
import moment from "moment/moment";
import pads from "../images/exam_pads.png"
import coach from "../images/coach.png"
import RightSidebar from "./RightSidebar";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay } from '@fortawesome/free-solid-svg-icons'
import thumbnail from "../images/Thumbnail.png"
import { trackEvent } from '../facebookPixel';


function S_dashboard() {

  const navigate = useNavigate()



  const [userDetails, setUserDetails] = useState([])

  const [programDetails, setProgramDetails] = useState([])

  const [newCourseLoading, setNewCourseLoading] = useState(true)
  const [loading, setLoading] = useState(true)

  const [error, setError] = useState()
  const [totalTopic, setTotalTopic] = useState(0);
  const [watchTopic, setWatchTopic] = useState(0);
  const [allocatedPrograms, setAllocatedPrograms] = useState([])

  console.log("program details",programDetails)
  console.log("allocatedPrograms details",allocatedPrograms)
  const totalData = [...programDetails,...allocatedPrograms];
  console.log("total details",totalData)
  function handleClick(pId) {
    sessionStorage.setItem("myCourseProgramId", pId)
    navigate("/my-course-videos");

  }

  function dayHandleClick(pId, pName) {
    sessionStorage.setItem("myCourseProgramId", pId)
    sessionStorage.setItem("myCourseProgramName", pName)
    navigate("/day-challenge");
  }

  function newHandleClick(pId) {
    sessionStorage.setItem("myNewCourseProgramId", pId)
    navigate("/my-new-course-videos");
    
  }
  function newdayHandleClick(pId, pName) {
    sessionStorage.setItem("myCourseProgramId", pId)
    sessionStorage.setItem("myCourseProgramName", pName)
   
    navigate("/day-challenge");
  }

  useEffect(() => {
    trackEvent('ViewContent');
    getLoggedInUserDetails()

  }, [])




  const getLoggedInUserDetails = async () => {
    httpsignup.get(`/skillsquadUserprofileLMS/getSkillsquadUserProfileBySignUpId?signUpId=${sessionStorage.getItem("userId")}`)
      .then((response) => {
        // console.log("UserDetails:", response)
        setWatchTopic(response.data.unlockVideoCount + 1);
        sessionStorage.setItem("profileid", response.data.lmsProfileId)
        sessionStorage.setItem("roleid", 2)
        sessionStorage.setItem("fullName", response.data.userName)
        programCards(response.data.lmsProfileId, response.data.userAccess);
        getUserNewCourses(response.data.lmsProfileId, response.data.userAccess);
        setUserDetails(response.data)
      }).catch((err) => {
        console.log(err)
      })
  }


  function programCards(id, access) {
    setLoading(true)
    httpvideos.get('/lmsVideoRecordingManager/api/v1/getLMSProgramDetails?roleId=2' + '&profileId=' + id + '&accessType=' + access)
      .then((response) => {
        if (response.data == "Records not found") {
          setLoading(false)

          setError(response.data)
        }
        else {
          //  console.log("progDetails: " + JSON.stringify(response))
          setProgramDetails(response.data)
          setTotalTopic(response.data[0].topicCount);
          setLoading(false)
        }
      }).catch((err) => {
        console.log(err)
        setLoading(false)
      })
  }

  function allcourses() {
    navigate("/dashboard")
  }

  function getUserNewCourses(id, access) {
    httpvideos.get(`/programsLMS/getPaidPrograms?profileId=${id}&accessType=${access}`)
      .then((response) => {
        console.log("HELLO : " + JSON.stringify(response.data))
        if (response.data.length !== 0) {
          // setUserType(response.data.userTypeMasterId)
          setNewCourseLoading(false)
          setAllocatedPrograms(response.data)
        } else {
          setNewCourseLoading(false)
        }


      }).catch((err) => {
        setNewCourseLoading(false)
        console.log(err)
      })
  }



  return (
    <>
      <Sidebar />

      <div className="jobs-dashboard-container mx-4">

        {/* <Navb /> */}

        <div className="px-md-5 mt160">
          <div className="ps-md-5 mt-2 mb-4 common_main_heading">My Courses</div>
          <section class="section">
            <div class="container">

              <div class="row p-4">

                <div class="">
                  <div class="row">
                    <div class="">
                      <div class="section-title  mb-4 pb-2">
                        <div className="">
                          <div >

                          <div className="all-allocated-courses">
                            {loading ? <div className="d-flex py-5 justify-content-center w-100"><Spinner animation="border" variant="lightsecondary" /> </div> :
                              (error || programDetails.length < 1 || programDetails[0].programId == null) ? <div className='col-12 text-center'><center> <div> <div><img src={pads} alt="pad" width={300} height={200} class="mb-3" /></div><div class="mb-3 mycourse-nodata">You haven't enrolled in any courses yet </div><div class="activityButton" onClick={allcourses}>See More Courses</div></div></center>

                              </div> :

                              totalData.map((res) => {
                                  return (
                                  




                                    <div class="">

                                      <div class="allcourse-cards mt-2 pb-3 mb-4" >
                                        <div class="allcourse-video-card mb-3" >
                                          <img src={res.programIcon} alt="play" style={{ borderRadius: "15px 15px 0px 0px" }} />

                                        </div>
                                        <div style={{ padding: "2%" }}>

                                          <div class="d-flex mx-2"><div class="common_main_sub_highlight mb-2">{res.programName}</div></div>

                                          <div class="d-flex mb-3 common_main_text_tag mx-2">
                                            {/* <div><span class="common_main_text_tag_highlight">{res.skillsquadCourses.rating}</span> <img src={require("../images/5 stars.png")} /><span style={{ color: "#8F9BB7" }}>({res.skillsquadCourses.ratingUser}) | {res.skillsquadCourses.duration} hours </span></div> */}
                                            <div><span class="common_main_text_tag_highlight">4.7</span> <img src={require("../images/5 stars.png")} /><span style={{ color: "#8F9BB7" }}>(2624) | 42+ hours </span></div>
                                          </div>
                                        
                                          <hr class="mx-2" style={{ height: 0, border: "none", border: "1px solid #5627FF" }}></hr>
                                          <div class='mb-1 p-1' style={{}}>
                                            <div class="common_main_text_highlight">Overall Progress</div>
                                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                              <div class="common_main_text_highlight" style={{ display: "flex", marginTop: "0.5rem", marginBottom: "0.5rem" }}>{res.completedTopics}/{res.totalTopic} <p style={{ color: "#8E92BC", fontSize: "14px", marginLeft: "0.5rem" }}>Lessons Completed</p></div>
                                              <div style={{ color: "#546FFF" }} class="common_main_text_highlight">{Math.round((res.completedTopics / res.totalTopic) * 100)}%  </div>

                                            </div>
                                           
                                            <div className="progress-bar-container">
                                              <ProgressBar
                                                style={{ height: '8px', backgroundColor: "#BAC8FF" }}
                                                now={Math.round((res.completedTopics / res.totalTopic) * 100)}
                                                className="progress-bar-custom"
                                              />
                                              <div
                                                className="progress-circle"
                                                style={{ left: `calc(${Math.round((res.completedTopics / res.totalTopic) * 100)}% - 0px)` }} // Adjust position to center the circle
                                              ></div>
                                            </div>
                                          </div>
                                          <div class="row mx-2">
                                           
                                          </div>
                                         

                                          <hr class="mx-2" style={{ height: 0, border: "none", border: "1px solid #5627FF" }}></hr>
                                          <div class="row mx-2 common_main_text_tag">
                                            
                                            {/* <p className='thirdivpara2' style={{ margin: "0px", fontSize: "16px", marginLeft: "0px", paddingLeft: "0px" }}>February 8, 2024</p> */}
                                           
                                          </div>
                                          <div class="row mx-2 mt-2">
                                            {/* <div class="col text-center common_main_text_highlight common_main_button" style={{ border: "1px solid grey", borderRadius: "5px", padding: "2%" }} >Continue Learning</div> */}
                                            {userDetails.userAccess == "Existing" && <div class="myCourse-continue" onClick={() => { handleClick(res.programId) }}><span class="common_main_text_highlight common_main_button_highlight">CONTINUE LEARNING  <span style={{ marginLeft: 5 }}></span></span></div>}
                                            {/* {(userDetails.userAccess == "New" && res.programName == 'Java Backend Course (English)') && <div class="myCourse-continue" onClick={() => { dayHandleClick(res.programId, res.programName) }}><span class="common_main_text_highlight common_main_button_highlight">CONTINUE LEARNING  <span style={{ marginLeft: 5 }}></span></span></div>} */}
                                            {(userDetails.userAccess == "New" && res.programStatus == 'Y') && <div class="myCourse-continue col text-center common_main_text_highlight common_main_button" onClick={() => { dayHandleClick(res.programId, res.programName) }}>Continue Learning</div>}

                                            {(userDetails.userAccess == "New" && res.programStatus != 'Y') && <div class="myCourse-continue" onClick={() => { handleClick(res.programId) }}><span class="common_main_text_highlight common_main_button_highlight">CONTINUE LEARNING  <span style={{ marginLeft: 5 }}></span></span></div>}
                                          </div>
                                         

                                        </div>
                                      </div>
                                      

                                    </div>
                                    


                                  )
                                }
                                )}
                                </div>
                                {/* <div className="more-courses-allocated">
                            {newCourseLoading ? <div className="d-flex py-5 justify-content-center w-100"><Spinner animation="border" variant="lightsecondary" /> </div> :
                              (error || allocatedPrograms.length == 0) ? <></> :

                                allocatedPrograms.map((res) => {
                                  return (
                                    <>
                                    <div class=" mx-3" >

                                    <div class="allcourse-cards mt-2 pb-3" >
                                      <div class="allcourse-video-card mb-3" >
                                        <img src={res.programIcon} alt="play" style={{ borderRadius: "15px 15px 0px 0px" }} />

                                      </div>
                                      <div style={{ padding: "2%" }}>

                                        <div class="d-flex mx-2"><div class="common_main_sub_highlight mb-2">{res.programName}</div></div>

                                        <div class="d-flex mb-3 common_main_text_tag mx-2">
                                          <div><span class="common_main_text_tag_highlight">4.7</span> <img src={require("../images/5 stars.png")} /><span style={{ color: "#8F9BB7" }}>(2624) | 42+ hours </span></div>
                                        </div> */}
                                        {/* <div class="d-flex mb-3">
                                          <div>Learn: Figma, UI Design, UX methods, </div>
                                        </div>
                                        <hr class="mx-2" style={{ height: 0, border: "none", border: "1px solid #5627FF" }}></hr>
                                        <div class='mb-1 p-1' style={{}}>
                                          <div class="common_main_text_highlight">Overall Progress</div>
                                          <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <div class="common_main_text_highlight" style={{ display: "flex", marginTop: "0.5rem", marginBottom: "0.5rem" }}>{res.completedTopics}/{res.totalTopic} <p style={{ color: "#8E92BC", fontSize: "14px", marginLeft: "0.5rem" }}>Lessons Completed</p></div>
                                            <div style={{ color: "#546FFF" }} class="common_main_text_highlight">{Math.round((res.completedTopics / res.totalTopic) * 100)}%  </div>

                                          </div>
                                          
                                          <div className="progress-bar-container">
                                            <ProgressBar
                                              style={{ height: '8px', backgroundColor: "#BAC8FF" }}
                                              now={Math.round((res.completedTopics / res.totalTopic) * 100)}
                                              className="progress-bar-custom"
                                            />
                                            <div
                                              className="progress-circle"
                                              style={{ left: `calc(${Math.round((res.completedTopics / res.totalTopic) * 100)}% - 0px)` }} 
                                            ></div>
                                          </div>
                                        </div>
                                        <div class="row mx-2">
                                        </div>

                                        <hr class="mx-2" style={{ height: 0, border: "none", border: "1px solid #5627FF" }}></hr>
                                        <div class="row mx-2 common_main_text_tag">
                                         


                                          </div> 
                                          <p className='thirdivpara2' style={{ margin: "0px", fontSize: "16px", marginLeft: "0px", paddingLeft: "0px" }}>February 8, 2024</p>
                                         
                                       
                                        <div class="row mx-2 mt-2">
                                          {userDetails.userAccess == "Existing" && <div class="myCourse-continue" onClick={() => { handleClick(res.programId) }}><span class="common_main_text_highlight common_main_button_highlight">CONTINUE LEARNING  <span style={{ marginLeft: 5 }}></span></span></div>}
                                          {(userDetails.userAccess == "New" && res.programStatus == 'Y') &&  <div class="myCourse-continue col text-center common_main_text_highlight common_main_button" onClick={() => { newdayHandleClick(res.programId, res.programName) }}>Continue Learning</div>}

                                          {(userDetails.userAccess == "New" && res.programStatus != 'Y') && <div class="myCourse-continue" onClick={() => { newHandleClick(res.programId) }}><span class="common_main_text_highlight common_main_button_highlight">CONTINUE LEARNING  <span style={{ marginLeft: 5 }}></span></span></div>}
                                        </div>
                                       

                                      </div>
                                    </div>

                                  </div>
                                 
                                  </>
                                    
                                  )
                                }
                                )
                                
                                }
                          </div> */}




                          </div>
                        </div>

                      </div>
                    </div>
                  </div>


                </div>

                {/*Filter section*/}
              </div>

            </div>

          </section>



        </div>


      </div>

      {/* <RightSidebar /> */}
    </>
  );

}

export default S_dashboard;
