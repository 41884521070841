import React, { useEffect, useState, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import left from "../../images/left-chev.svg";
import right from "../../images/right-chev.svg";
import { usePopup } from "../../components/PopupContext";
import { Navigation } from "swiper/modules";
import { useMentors } from "../MentorLandingPage/MentorDataContext";
import { useNavigate, useParams } from "react-router-dom";

const Technologies = () => {
  const { category, offername } = useParams();
  const { getCatagories, catagories, getId, selectedCat, services } =
    useMentors();

  useEffect(() => {
    getCatagories();
    // getId("recommended",null);
  }, []);

  useEffect(() => {
    if (category && !offername) {
      const newCat = category
        .replace(/[&/.-]/g, " ")
        .replace(/\s+/g, " ")
        .trim();
      console.log(newCat);
      getId(newCat, null);
    } else if(category === undefined) {
      getId("recommended", null);
    }
  }, [category]);

  const navigate = useNavigate();
  const handleSelectedItems = (id, name) => {
    const currentMainPath = window.location.pathname.split("/")[1];
    const formattedName = name.replace(/[\s/]+/g, "-").toLowerCase();
    const formattedService = "1-1 services".replace(/\s+/g, "-").toLowerCase();
    const fullPath = `/${currentMainPath}/${formattedService}/${formattedName}`;
  
    navigate(fullPath);
  };
  
  

  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const swiperRef = useRef(null);

  // Function to update arrow visibility based on slides and container width
  const updateNavigationVisibility = () => {
    if (swiperRef.current && prevRef.current && nextRef.current) {
      const swiper = swiperRef.current;
      const { slides, width } = swiper;
      const slidesPerView = swiper.params.slidesPerView;
      const slideWidth = swiper.slides[0]?.offsetWidth || 0;
      const slidesContainerWidth = slidesPerView * slideWidth;

      // Show arrows only if the total slide width exceeds the container width
      const totalSlidesWidth = slides.length * slideWidth;
      if (totalSlidesWidth > width) {
        prevRef.current.style.display = swiper.isBeginning ? "none" : "block";
        nextRef.current.style.display = swiper.isEnd ? "none" : "block";
      } else {
        prevRef.current.style.display = "none";
        nextRef.current.style.display = "none";
      }
    }
  };

  useEffect(() => {
    updateNavigationVisibility(); // Initial check
  }, [catagories]);

  return (
    <div
      className={
        window.location.pathname === "/mentor-page"
          ? "mentor-page-layout"
          : "technologies-container"
      }
      style={{ position: "relative" }}
    >
      <Swiper
        modules={[Navigation]}
        slidesPerView="auto"
        spaceBetween={10}
        navigation={{
          prevEl: prevRef.current,
          nextEl: nextRef.current,
        }}
        pagination={false}
        onSwiper={(swiper) => {
          swiperRef.current = swiper;
          updateNavigationVisibility();
        }}
        onSlideChange={() => {
          updateNavigationVisibility();
        }}
      >
        {catagories?.map((tech, index) => (
          <SwiperSlide
            key={index}
            style={{ width: tech.isBig ? "auto" : "auto" }}
          >
            <div
              className={`technology ${
                selectedCat === tech.masterId ? "selectedCat" : ""
              }`}
              onClick={() =>
                handleSelectedItems(tech.masterId, tech.categoryMasterName)
              }
            >
              <div className="recommend">{tech.categoryMasterName}</div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

      <div
        ref={prevRef}
        className="swiper-button-prev"
        style={{
          position: "absolute",
          top: "75%",
          left: "-10px",
          transform: "translateY(-50%)",
          zIndex: 10,
          cursor: "pointer",
        }}
      >
        <img src={left} alt="Previous" />
      </div>
      <div
        ref={nextRef}
        className="swiper-button-next"
        style={{
          position: "absolute",
          top: "75%",
          right: "-10px",
          transform: "translateY(-50%)",
          zIndex: 10,
          cursor: "pointer",
        }}
      >
        <img src={right} alt="Next" />
      </div>
    </div>
  );
};

export default Technologies;
