import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { TextField, Button } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { IoCloseOutline } from 'react-icons/io5';
import Select from 'react-select';

const ExperienceForm = ({ onOpenEdu, onCloseEdu, formDataEdu,editIndexEdu, handleSaveEducation,editEdu }) => {
  const [schoolLogoIcon, setschoolLogoIcon] = useState(formDataEdu.schoolLogoIcon || null);
  const convertStringToBoolean = (value) => {
    if (value === "true") return true;
    if (value === "false") return false;
    return Boolean(value);
  };
  const initialValues = {
    schoolCollegeName: formDataEdu.schoolCollegeName || '',
    majorFieldOfStudy: formDataEdu.majorFieldOfStudy || '',
    schoolLogoIcon: formDataEdu.schoolLogoIcon || null,
    startDate: formDataEdu.startDate ? dayjs(formDataEdu.startDate) : null,
    endDate: formDataEdu.endDate ? dayjs(formDataEdu.endDate) : null,
    currentlyStudying: convertStringToBoolean(formDataEdu.currentlyStudying),
  };

  const validate = (values) => {
    const errors = {};

    if (!values.schoolCollegeName || !values.schoolCollegeName.trim()) {
      errors.schoolCollegeName = 'School or college name is required';
    }
    if (!values.majorFieldOfStudy || !values.majorFieldOfStudy.trim()) {
      errors.majorFieldOfStudy = 'Field of study is required';
    }
    if (!values.startDate) {
      errors.startDate = 'Start date is required';
    }
    if (!values.currentlyStudying && !values.endDate) {
      errors.endDate = 'End date is required';
    }
    if (values.endDate && values.startDate && dayjs(values.endDate).isBefore(dayjs(values.startDate))) {
      errors.endDate = "End date can't be before start date";
    }

    return errors;
  };

  const onSubmit = (values) => {
    const formattedValues = {
      ...formDataEdu,...values,
      startDate: values.startDate ? dayjs(values.startDate).format('YYYY-MM-DD') : null,
      endDate: values.endDate ? dayjs(values.endDate).format('YYYY-MM-DD') : null,
     
    };
    console.log(formattedValues);
    handleSaveEducation(formattedValues,schoolLogoIcon);
  };
console.log("updating form data",formDataEdu)
  return (
    <Formik
      initialValues={initialValues}
      // validationSchema={validationSchema}
      validate={validate}
      onSubmit={onSubmit}
    >
      {({ values, setFieldValue, touched, errors }) => (
        <Form>
          <div >
            <div  >
              <h2>{editIndexEdu === null ? "Add Education" : "Edit Education"}</h2>

              {/* Job Title */}
              <div className="add-course-layout mb-3">
                <p className="course-title" style={{ marginBottom: "4px" }}>
                School <span style={{ color: "red" }}>*</span>
                </p>
                <Field
                  className="course-title-input"
                  type="text"
                  name="schoolCollegeName"
                  placeholder="Add title here"
                />
                <ErrorMessage name="schoolCollegeName" component="div" style={{ color: 'red' }} />
              </div>

              {/* Company Name */}
              <div className="add-course-layout mb-3">
                <p className="course-title" style={{ marginBottom: "4px" }}>
                 Degree <span style={{ color: "red" }}>*</span>
                </p>
                <Field
                  className="course-title-input"
                  type="text"
                  name="majorFieldOfStudy"
                  placeholder="Add your degree here"
                />
                <ErrorMessage name="majorFieldOfStudy" component="div" style={{ color: 'red' }} />
              </div>

              {/* Company Logo */}
              <div className="add-course-layout mb-3">
                <p className="course-title" style={{ marginBottom: "4px" }}>
                  School Logo
                </p>
                {values.schoolLogoIcon && (
                  <img
                    src={typeof values.schoolLogoIcon === 'string' ? values.schoolLogoIcon : URL.createObjectURL(values.schoolLogoIcon)}
                    alt="Uploaded"
                    style={{ maxWidth: "100px", marginTop: "10px", marginRight: "20px" }}
                  />
                )}
                <input
                  type="file"
                  name="schoolLogoIcon"
                  accept="image/*"
                  onChange={(event) => {
                    setschoolLogoIcon(event.currentTarget.files[0]);
                  }}
                />
              </div>

             

              {/* Dates Section */}
              <div className="add-course-layout mb-3">
                <div className="dates-grid">
                  <div>
                    <p className="course-title" style={{ marginBottom: "4px" }}>
                      Start Date <span style={{ color: "red" }}>*</span>
                    </p>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        value={values.startDate}
                        views={['year', 'month']} 
                        onChange={(date) => setFieldValue("startDate", date)}
                        maxDate={dayjs()}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                    {touched.startDate && errors.startDate ? (
                      <div style={{ color: 'red' }}>{errors.startDate}</div>
                    ) : null}
                  </div>

                  {!values.currentlyStudying  && (
                    <div>
                      <p className="course-title" style={{ marginBottom: "4px" }}>
                        End Date <span style={{ color: "red" }}>*</span>
                      </p>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          value={values.endDate}
                          views={['year', 'month']} 
                          onChange={(date) => setFieldValue("endDate", date)}
                          maxDate={dayjs()}
                          minDate={values.startDate}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                      {touched.endDate && errors.endDate ? (
                        <div style={{ color: 'red' }}>{errors.endDate}</div>
                      ) : null}
                    </div>
                  )}
                </div>
              </div>

              {/* Currently Working Checkbox */}
              <div className="mb-3">
              <Field
                  type="checkbox"
                  name="currentlyStudying"
                  id="currentlyStudying"
                  checked={values.currentlyStudying}
                  onChange={(event) => {
                    const checked = event.target.checked;
                    setFieldValue('currentlyStudying', checked);
                    if (checked) {
                      setFieldValue('endDate', null);
                    }
                  }}
                />
                <label htmlFor="currentlyStudying" style={{ marginLeft: "5px" }}>
                  Currently Learning this course
                </label>
              </div>

              {/* Save Button */}
              <div className="d-flex justify-content-end">
                <button type="submit" className="saveButton">Save</button>
              </div>

              {/* Close Button */}
              <IoCloseOutline
                onClick={onCloseEdu}
                style={{ position: "absolute", top: "20px", right: "20px", fontSize: "2rem", cursor: "pointer" }}
              />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ExperienceForm;
