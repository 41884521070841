import React from 'react';
import '../styles/Popup.css';
import ComparisonSlider from "./ComparisonSlider";
import topImage from "../images/Logo.png";
import bottomImage from "../images/Full-Stack-Developer.png"
import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider';

const Popup = ({ onClose }) => {
  return (
    <div className="popup-container mt-5">
      <div className="popup  mt-4">
      <div style = {{textAlign: "right", width: '100%', color:"white",cursor:"pointer"}} class="mb-1"> <div  onClick={onClose}><strong>X</strong></div></div>
        { <ComparisonSlider topImage={topImage} bottomImage={bottomImage} /> }
        {/* <div style={{ width: '50%'}}>
      <ReactCompareSlider
        itemOne={
          <ReactCompareSliderImage
            src="https://github.com/nerdyman/stuff/raw/main/libs/react-compare-slider/demo-images/sydney-opera-house-1.jpg"
            alt="Image one"
          />
        }
        itemTwo={
          <ReactCompareSliderImage
            src="https://github.com/nerdyman/stuff/raw/main/libs/react-compare-slider/demo-images/sydney-opera-house-2.jpg"
            alt="Image two"
          />
        }
        style={{ width: '100%', height: '50%' }}
      />
     
    </div> */}
      </div>
    </div>
  );
};

export default Popup;