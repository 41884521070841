import React from "react";
import SkillshareLogo from "../images/skillsquadnew_logo.png";
// import BigLogo from "../images/biglogo.png"; // Path to your big logo image

import ProjectImg from "../images/projectimg.png";
import ff from "../";
import "../styles/SkillsquadProject.css";
import firstscroll from "../images/firstscroll.png";
import secondscroll from "../images/secondscroll.png";
import thirdscroll from "../images/thirdscroll.png";
// import fourthscroll from "../images/fourthscroll.png";
import Avatar1 from "../images/Avatar1.png";
import Avatar2 from "../images/Avatar2.png";
import Avatar3 from "../images/Avatar3.png";

import Commas from "../images/commas.svg";
import SignUpIcon from "../images/signupicon.png";
import ExpertsIcon from "../images/expertsicon.png";
import DiscoverIcon from "../images/discovericon.png";
import TrackProgressIcon from "../images/trackprogressicon.png";
import GetSupportIcon from "../images/getsupporticon.png";
import MentorsIcon from "../images/mentors icon.png";

// import "../styles/bnavbar.css";
import "../styles/blackpage.css";
import { FiMenu } from "react-icons/fi";
import { RiBook2Line } from "react-icons/ri";
import { GoPeople } from "react-icons/go";
import { GiVideoConference } from "react-icons/gi";
import { IoCodeSlashOutline } from "react-icons/io5";
import { IoBriefcaseOutline } from "react-icons/io5";
import { LiaChalkboardTeacherSolid } from "react-icons/lia";
import { BsArrowUpRight } from "react-icons/bs";
import { BsArrowRight } from "react-icons/bs";

import { NewHeader } from "../components/NewHeader";
import { NewFooter } from "../components/Footer/NewFooter";
import Marquee from "react-fast-marquee";
import Index from "../components/ProjectBuilder/index";

import Howitworks1 from "../images/HOW IT icon.png";
import Howitworks2 from "../images/How It icon2.png";
import Howitworks3 from "../images/How it icon3.png";
import { useNavigate } from "react-router-dom";
import CoursePage from "../components/CoursePage";

export default function SkillsquadProject() {
  const data = [
    { title: "Courses", icon: <RiBook2Line /> },
    { title: "Professional Network", icon: <GoPeople /> },
    { title: "Mentorship", icon: <GiVideoConference /> },
    { title: "Projects", icon: <IoCodeSlashOutline /> },
    { title: "Job", icon: <IoBriefcaseOutline /> },
    {
      title: (
        <span style={{ textDecoration: "underline", color: "black" }}>
          Become a Mentor <BsArrowUpRight style={{ marginLeft: "1px" }} />
        </span>
      ),
      icon: <LiaChalkboardTeacherSolid />,
    },
  ];
  const navigate = useNavigate();

  function signUpPage() {
    navigate("/sign-up");
  }
  const CoursePage = () =>{
    navigate("/course")
  }
  const projectPage = (id)=>{
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" })
    }
  }

  return (
    <div className="project-page">
      <NewHeader />

      <section id="hero-section" className="bg-grey" style={{ minHeight: "100vh" }}>
        <div className="container d-none d-md-block">
          <div className="row mb-5 divcolor">
            <div className=" col-lg-6 col-sm-12 mt-5 d-flex justify-content-between">
              <div className=" ">
                <h1 className="home-heading">
                  Build projects that stand out from the crowd.
                </h1>
                <p className="home-description">
                Develop projects that make an impact and set you apart in your field. Showcase your skills with confidence
                </p>

                <div className="d-flex align-items-center mt-5">
                  <button
                    className="home-button"
                    style={{
                      marginRight: "20px",
                      color: "white",
                      backgroundColor: "black",
                      width: "20%",
                      borderRadius: "5px",
                      height: "40px",
                    }}
                    onClick={signUpPage}
                  >
                    Signup
                  </button>
                  <a style={{ fontSize: "18px" }} onClick={signUpPage}>
                    Browse All Projects
                    <span style={{ marginLeft: "3px" }}>
                      <BsArrowRight />
                    </span>
                  </a>
                </div>
              </div>
            </div>
            <div className=" col-lg-6 col-sm-12 d-flex flex-column align-items-center justify-content-center mt-5 codes">
              <div className="code align-self-end text-center">Code</div>
              <div className="create align-self-center text-center">Create</div>
              <div className="conquer align-self-start text-center">
                Conquer
              </div>
            </div>
          </div>
        </div>

        <div className="container d-block d-sm-none">
          <div className="row mb-5 divcolor">
            <div style={{ color: "#807A82", marginBottom: "-25px",marginTop:'10px' }}>
             
            </div>
            <div className="order-2 col-lg-6 col-sm-12 mt-5 d-flex justify-content-between">
              <div className=" ">
                <h1 className="home-heading">
                  Build projects that stand out from the crowd.
                </h1>
                <p className="home-description">
                Develop projects that make an impact and set you apart in your field. Showcase your skills with confidence
                </p>

                <div className="d-flex align-items-center mt-5">
                  <button className="home-button" onClick={signUpPage}>
                    Signup
                  </button>
                  <a style={{ fontSize: "18px" }} onClick={signUpPage}>
                    Browse All Projects
                    <span style={{ marginLeft: "3px" }}>
                      <BsArrowRight />
                    </span>
                  </a>
                </div>
              </div>
            </div>
            <div className="order-1 col-lg-6 col-sm-12 d-flex flex-column align-items-center justify-content-center mt-5 codes">
              <div className="code align-self-end text-center">Code</div>
              <div className="create align-self-center text-center">Create</div>
              <div className="conquer align-self-start text-center">
                Conquer
              </div>
            </div>
          </div>
        </div>

        <Marquee>
          {/* <div className="container mt-5 mb-5" style={{ width: "100%" }}> */}
          <div className=" d-flex flex-wrap mt-5 mb-5">
            <div>
              <img
                src={firstscroll}
                className="card1 mb-2 mx-4"
                style={{ maxWidth: "100%", height: "280px" }}
                alt="..."
              />{" "}
              <img
                src={secondscroll}
                className="card1 mb-2 mx-4"
                style={{ maxWidth: "100%", height: "280px" }}
                alt="..."
              />{" "}
              <img
                src={thirdscroll}
                className="card1 mb-2 mx-4"
                style={{ maxWidth: "100%", height: "280px" }}
                alt="..."
              />{" "}
              <img
                src={secondscroll}
                className="card1 mb-2 mx-4"
                style={{ maxWidth: "100%", height: "280px" }}
                alt="..."
              />
            </div>

            {/* <img src={secondscroll} className="card1 mb-2 mx-2" alt="..." />

            <img src={thirdscroll} className="card1 mb-2 mx-2" alt="..." />

            <img src={firstscroll} className="card1 mb-2 mx-2" alt="..." />

            <img src={secondscroll} className="card1 mb-2 mx-2" alt="..." /> */}
          </div>
        </Marquee>

        <div class="container d-none d-md-block">
          <div class="row mb-5">
            <div class="col-12 d-flex justify-content-center mb-5">
              <button
                className="d-none d-md-block"
                type="button"
                class="btn btn-primary w-22"
                style={{
                  backgroundColor: "black",
                  fontSize: "18px",
                  borderRadius: "5px",
                  width: "230px",
                }}
                onClick={signUpPage}
              >
                Browse All Projects
              </button>
            </div>
          </div>
        </div>
      </section>

      <section>
        <Index />
      </section>

      <section>
      <div className="container-fluid mt-5 ">
          <div className="row">
            <div
              className="col d-flex justify-content-center mt-5 mb-4"
              style={{ fontSize: "40px" }}
            >
              <b>How it works</b>
            </div>
            <div
              className="d-flex justify-content-center mt-3 mb-3"
              style={{
                color: "rgba(120, 144, 156, 1)",
                textAlign: "center",
                fontSize: "20px",
                lineHeight: "30px",
              }}
            >
              {/* Easily navigate the Skillsquad platform and leverage its features
              to
              <br /> maximize your learning, networking, and career advancement. */}
             Efficiently navigate the Skillsquad platform to enhance your learning, networking, and career advancement opportunities
            </div>
            <button className="d-block d-sm-none d-flex justify-content-center btn btn-primary btn-lg mt-2 start-building-projects2" onClick={signUpPage}>Learn More</button>
            <div className="container-fluid d-flex justify-content-center p-5 mt-5 mb-5 align-items-center ">
              <div classname="container ml-5 d-none d-md-block">
                <img
                  className="d-none d-md-block"
                  src={Howitworks1}
                  alt="Card image cap"
                  style={{ maxWidth: "500px", height: "auto" }}
                  // style={{ width: "200px" }}
                />
              </div>
              <div className="d-none d-md-block">
                <img
                  className="d-none d-md-block"
                  src={require('../images/howitworkscode.png')}
                  alt="Card image cap"
                  style={{
                    maxWidth: "390px",
                    height: "auto",
                    position: "relative",
                  }}
                  // style={{ width: "200px" }}
                />
                <button
                  className="btn btn-primary d-none"
                  style={{
                    // position: "absolute",
                    // display: "flex",
                    // alignItems: "center",
                    // justifyContent: "center",
                    // top: "0",

                    backgroundColor: "white",
                    fontSize: "18px",
                    borderRadius: "5px",
                    color: "black",
                    width: "150px",
                    height: "40px",

                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                  onClick={signUpPage}
                >
                  Learn more
                </button>
              </div>

              <div classname="container mr-5 d-none d-md-block">
                <img
                  className="d-none d-md-block"
                  src={Howitworks3}
                  alt="Card image cap"
                  style={{ width: "500px", height: "auto" }}
                  // style={{ width: "200px" }}
                />
              </div>
            </div>
          </div>
        </div>
        {/* Cards Section */}
        <div className="container">
          <div className="justify-content-center cards-alignment">
            <div className="row d-flex justify-content-center">
              {/* Card 1 - Sign Up */}
              <div
                className="card p-2 col-lg-4"
                style={{
                 
                  backgroundColor: "rgba(255, 237, 172, 1)",
                  borderRadius: "20px",
                  border: "none",
                  marginBottom: "30px",
                }}
              >
                <div className="card-body p-4" style={{ fontSize: "25px" }}>
                  Sign Up
                  <p className="card-text mt-3" style={{ fontSize: "20px" }}> 
                    Signing up is quick, easy, and completely free.
                  </p>
                </div>
                <div className="d-flex justify-content-center p-1">
                  <img
                    className="card-img-top"
                    src={SignUpIcon}
                    alt="Sign Up Icon"
                    style={{ width: "200px", height: "auto" }}
                  />
                </div>
              </div>

              {/* Card 2 - Connect with Mentors */}
              <div
                className="card p-2 col-lg-4"
                style={{
                 
                  backgroundColor: "rgba(245, 235, 226, 1)",
                  borderRadius: "20px",
                  border: "none",
                  marginBottom: "30px",
                }}
              >
                <div className="card-body p-4" style={{ fontSize: "25px" }}>
                  Connect with Mentors
                  <p className="card-text mt-3" style={{ fontSize: "20px" }}>
                    Access a network of experienced mentors who support you
                    throughout your professional journey.
                  </p>
                </div>
                <div className="d-flex justify-content-center p-1">
                  <img
                    className="card-img-top"
                    src={MentorsIcon}
                    alt="Mentors Icon"
                    style={{ width: "300px", height: "auto" }}
                  />
                </div>
              </div>

              {/* Card 3 - Learn from Experts */}
              <div
                className="card p-2 col-lg-4"
                style={{
                
                  backgroundColor: "rgba(245, 245, 245, 1)",
                  borderRadius: "20px",
                  border: "none",
                  marginBottom: "30px",
                }}
              >
                <div className="card-body p-4" style={{ fontSize: "25px" }}>
                  Learn from Experts
                  <p className="card-text mt-3" style={{ fontSize: "20px" }}>
                    Learn from industry experts and gain valuable insights to
                    accelerate your career growth.
                  </p>
                </div>
                <div className="d-flex justify-content-center p-1">
                  <img
                    className="card-img-top"
                    src={ExpertsIcon}
                    alt="Experts Icon"
                    style={{ width: "100%", height: "auto" }}
                  />
                </div>
              </div>

              {/* Card 4 - Discover Job Opportunities */}
              <div
                className="card p-2 col-lg-4"
                style={{
                 
                  backgroundColor: "rgba(232, 239, 254, 1)",
                  borderRadius: "20px",
                  border: "none",
                  marginBottom: "30px",
                }}
              >
                <div className="card-body p-4" style={{ fontSize: "25px" }}>
                  Discover Job Opportunities
                  <p className="card-text mt-3" style={{ fontSize: "20px" }}>
                    Explore job listings and career opportunities tailored to
                    your skills, interests, and experience level.
                  </p>
                </div>
                <div className="d-flex justify-content-center p-1">
                  <img
                    className="card-img-top"
                    src={DiscoverIcon}
                    alt="Discover Icon"
                    style={{ width: "350px", height: "auto" }}
                  />
                </div>
              </div>

              {/* Card 5 - Track Your Progress */}
              <div
                className="card p-2 col-lg-4"
                style={{
                  
                  backgroundColor: "rgba(249, 235, 254, 1)",
                  borderRadius: "20px",
                  border: "none",
                  marginBottom: "30px",
                }}
              >
                <div className="card-body p-4" style={{ fontSize: "25px" }}>
                  Track Your Progress
                  <p className="card-text mt-3" style={{ fontSize: "20px" }}>
                    Monitor your learning progress, track your achievements, and
                    set goals to keep yourself motivated.
                  </p>
                </div>
                <div className="d-flex justify-content-center p-1">
                  <img
                    className="card-img-top"
                    src={TrackProgressIcon}
                    alt="Track Progress Icon"
                    style={{ width: "310px", height: "auto" }}
                  />
                </div>
              </div>

              {/* Card 6 - Get Support */}
              <div
                className="card p-2 col-lg-4"
                style={{
                  
                  backgroundColor: "rgba(238, 254, 246, 1)",
                  borderRadius: "20px",
                  border: "none",
                  marginBottom: "30px",
                }}
              >
                <div className="card-body p-4" style={{ fontSize: "25px" }}>
                  Get Support
                  <p className="card-text mt-3" style={{ fontSize: "20px" }}>
                    Access customer support and assistance whenever you need
                    help.
                  </p>
                </div>
                <div className="d-flex justify-content-center p-1">
                  <img
                    className="card-img-top"
                    src={GetSupportIcon}
                    alt="Get Support Icon"
                    style={{ width: "320px", height: "auto" }}
                  />
                </div>
              </div>

              {/* Add more cards as needed */}
            </div>
          </div>
        </div>
      </section>


      {/* How it works page */}
      <section>
        <div className="container ">
          <div className="row"></div>
        </div>
        {/* How it works section */}
        <div className="container Trusted-heading" style={{padding:"0px 35px"}}>
          <h1 >
           The Next Success Story is Going to be Your's..
          </h1>
        </div>
        <Marquee>
          {/* <div className="row" style={{ margin: "0 10px" }}> */}
          <div
            className="main-card mb-2 mx-4 marq-cards"
            style={{
              padding:'15px',
              borderRadius:'5px',
              backgroundColor: '#EFF1F0'
              }}
          >
            <div className="content-card d-flex flex-column">
              <div className="align-items-center mb-3 p-3 content-card-deatails">
                <img style={{height:"60px",width:"60px",borderRadius:"15px"}} src={require("../images/Kamera Chinni Krishna.webp")} alt="" />
                <div
                  className="d-flex flex-column align-items-start"
                  style={{ marginLeft: "7px" }}
                >
                  <h3 style={{ marginBottom: "5px" }}>Chinni Krishna</h3>
                  <p>Software Developer</p>
                </div>
                <div className="ms-4">
                  <img src={Commas} alt="" style={{ float: "right" }} />
                </div>
              </div>
              <p style={{ fontSize: "19px", padding: "8px" }}>
                "As a recent graduate, Skillsquad was instrumental in boosting my resume with valuable data analysis skills. The practical 
                projects helped me gain real-world experience, and the career guidance from my mentor was invaluable."
              </p>
            </div>
          </div>

          <div
            className="main-card mb-2 mx-4"
            style={{
              padding: "15px",
              borderRadius: "5px",
              backgroundColor: "#EFF1F0",
              borderRadius: "10px",
            }}
          >
            <div className="content-card d-flex flex-column content-card-deatails">
              <div className="align-items-center mb-3 p-3">
                <img style={{height:"60px",width:"60px",borderRadius:"15px"}} src={require('../images/Kandhula.Rakesh.webp')} alt="" />
                <div
                  className="d-flex flex-column align-items-start"
                  style={{ marginLeft: "7px" }}
                >
                  <h3 style={{ marginBottom: "5px" }}>Kandhula Rakesh</h3>
                  <p>Software Developer</p>
                </div>
                <div className="ms-4">
                  <img src={Commas} alt="" style={{ float: "right" }} />
                </div>
              </div>
              <p style={{ fontSize: "19px", padding: "8px" }}>
                "Skillsquad provided me with the leadership skills I needed to advance in my career. The courses offered practical strategies and real-world scenarios, which I w
                as able to immediately implement in my projects. I'm now a more confident and effective leader."
              </p>
            </div>
          </div>

          <div
            className="main-card mb-2 mx-4"
            style={{
              padding: "15px",
              borderRadius: "5px",
              backgroundColor: "#EFF1F0",
              borderRadius: "10px",
            }}
          >
            <div className="content-card  flex-column content-card-deatails">
              <div className="align-items-center mb-3 p-3">
                <img style={{height:"60px",width:"60px",borderRadius:"15px"}} src={require('../images/Manish_Pranay.webp')} alt="" />
                <div
                  className="d-flex flex-column align-items-start"
                  style={{ marginLeft: "7px" }}
                >
                  <h3 style={{ marginBottom: "5px" }}>Manish Pranay</h3>
                  <p>Data Analyst</p>
                </div>
                <div className="ms-4">
                  <img src={Commas} alt="" style={{ float: "right" }} />
                </div>
              </div>
              <p style={{ fontSize: "19px", padding: "8px" }}>
                "I really enjoyed studing the course with Skillsquad. Team has been abosulutely exceptional and provided materials were fabulous. I strongly recommend who 
                is willing to explore themselves with any types of Industry.
"
              </p>
            </div>
          </div>
          <div
            className="main-card mb-2 mx-3"
            style={{
              padding: "15px",
              borderRadius: "5px",
              backgroundColor: "#EFF1F0",
              borderRadius: "10px",
            }}
          >
            <div className="content-card flex-column content-card-deatails">
              <div className="align-items-center mb-3 p-3">
                <img style={{height:"60px",width:"60px",borderRadius:"15px"}} src={require("../images/Mohammed._Irfan.webp")} alt="" />
                <div
                  className="d-flex flex-column align-items-start"
                  style={{ marginLeft: "7px" }}
                >
                  <h3 style={{ marginBottom: "5px" }}>Mohammed Irfan</h3>
                  <p>UI/UX Designer</p>
                </div>
                <div className="ms-4">
                  <img src={Commas} alt="" style={{ float: "right" }} />
                </div>
              </div>
              <p style={{ fontSize: "19px", padding: "8px" }}>
                "Initially i was not sure about it. But then as the course gradually paced up, i became confident on my decision of choosing & joining Skillsquad & on the learning as well. At 
                SkillsSquad, you would have everything you need to acquire your leaning curve."
              </p>
            </div>
          </div>
          {/* </div> */}
        </Marquee>
      </section>

      <section>
        <div
          className="container-fluid mt-5 mb-5 p-5 faq-style1"
          style={{ backgroundColor: "rgba(251, 251, 227, 1)" }}
        >
          <div className="row p-5 d-flex justify-content-center align-items-start faq-style">
            {/* <div className="col-lg-4 col-sm-12 align-items-start">
              <div className="col d-flex flex-column justify-content-center align-items-start faq-heading">
                <div><b>Frequently asked questions</b></div>
                <div className="col mt-4 faq-alternative">
                <p>Can't find the answer you are looking for?</p>
              </div>
              </div>
             
            </div> */}
            <div className="col-lg-4 col-sm-12 faq-parent d-flex flex-column  align-items-start">
              <div className="col d-flex flex-column  faq-heading">
                <div><b>Frequently asked questions</b></div>
                <div className="col mt-4 faq-alternative">
                <p className="faq-child">Can't find the answer you are looking for?</p>
              </div>
              </div>
             
            </div>

            <div className="col-lg-8 col-sm-12 ">
              <div
                className="accordion"
                id="accordionPanelsStayOpenExample"
                style={{
                  backgroundColor: "rgba(251, 251, 227, 1)",
                  fontSize: "15px",
                  border: "none",
                }}
              >
                <div
                  className="accordion-item"
                  style={{ backgroundColor: "rgba(251, 251, 227, 1)" }}
                >
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#panelsStayOpen-collapseOne"
                      aria-expanded="false"
                      aria-controls="panelsStayOpen-collapseOne"
                      style={{
                        backgroundColor: "rgba(251, 251, 227, 1)",
                        fontSize: "20px",
                        border: "none",
                        // borderBottom:'1px solid red'
                      }}
                    >
                      1. What kind of projects will I work on?
                    </button>
                  </h2>
                  <div
                    id="panelsStayOpen-collapseOne"
                    className="accordion-collapse collapse show"
                  >
                    <div className="accordion-body">
                      You will have the opportunity to work on a diverse range
                      of projects spanning various skill levels and topics.
                      These projects are designed to enhance your learning
                      experience and provide practical, hands-on application of
                      the skills you're learning.
                    </div>
                  </div>
                </div>
                <div
                  className="accordion-item"
                  style={{ backgroundColor: "rgba(251, 251, 227, 1)" }}
                >
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#panelsStayOpen-collapseTwo"
                      aria-expanded="false"
                      aria-controls="panelsStayOpen-collapseTwo"
                      style={{
                        backgroundColor: "rgba(251, 251, 227, 1)",
                        fontSize: "20px",
                      }}
                    >
                      2. Can I choose the project topic or is it assigned?
                    </button>
                  </h2>
                  <div
                    id="panelsStayOpen-collapseTwo"
                    className="accordion-collapse collapse"
                  >
                    <div className="accordion-body">
                      While some projects may have pre-assigned topics to ensure
                      a comprehensive learning experience, we also provide
                      opportunities for participants to suggest topics or choose
                      from a selection based on their interests and goals.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2
                    className="accordion-header"
                    style={{ backgroundColor: "rgba(251, 251, 227, 1)" }}
                  >
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#panelsStayOpen-collapseThree"
                      aria-expanded="false"
                      aria-controls="panelsStayOpen-collapseThree"
                      style={{
                        backgroundColor: "rgba(251, 251, 227, 1)",
                        fontSize: "20px",
                      }}
                    >
                      3. Are the projects individual or team-based?
                    </button>
                  </h2>
                  <div
                    id="panelsStayOpen-collapseThree"
                    className="accordion-collapse collapse"
                  >
                    <div
                      className="accordion-body"
                      style={{ backgroundColor: "rgba(251, 251, 227, 1)" }}
                    >
                      Our projects are structured to accommodate both individual
                      and team-based work, depending on the course and project
                      requirements. This flexibility allows participants to
                      collaborate with peers, fostering a dynamic learning
                      environment.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2
                    className="accordion-header"
                    style={{ backgroundColor: "rgba(251, 251, 227, 1)" }}
                  >
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#panelsStayOpen-collapseFour"
                      aria-expanded="false"
                      aria-controls="panelsStayOpen-collapseFour"
                      style={{
                        backgroundColor: "rgba(251, 251, 227, 1)",
                        fontSize: "20px",
                      }}
                    >
                      4. How much time commitment is required for each project?
                    </button>
                  </h2>
                  <div
                    id="panelsStayOpen-collapseFour"
                    className="accordion-collapse collapse"
                  >
                    <div
                      className="accordion-body"
                      style={{ backgroundColor: "rgba(251, 251, 227, 1)" }}
                    >
                      The time commitment varies depending on the complexity and
                      scope of each project. Generally, we recommend allocating
                      a few hours per week for project work to ensure steady
                      progress and engagement throughout the course duration.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2
                    className="accordion-header"
                    style={{ backgroundColor: "rgba(251, 251, 227, 1)" }}
                  >
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#panelsStayOpen-collapseFive"
                      aria-expanded="false"
                      aria-controls="panelsStayOpen-collapseFive"
                      style={{
                        backgroundColor: "rgba(251, 251, 227, 1)",
                        fontSize: "20px",
                      }}
                    >
                      5. Is there a community where I can interact with other
                      participants?
                    </button>
                  </h2>
                  <div
                    id="panelsStayOpen-collapseFive"
                    className="accordion-collapse collapse"
                  >
                    <div
                      className="accordion-body"
                      style={{ backgroundColor: "rgba(251, 251, 227, 1)" }}
                    >
                      Yes, we provide access to a vibrant community and forum
                      where participants can connect, share insights,
                      collaborate on projects, and seek assistance from mentors
                      and fellow learners. This collaborative environment
                      enhances the learning journey and promotes peer-to-peer
                      support.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2
                    className="accordion-header"
                    style={{
                      backgroundColor: "rgba(251, 251, 227, 1)",
                      fontSize: "20px",
                    }}
                  >
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#panelsStayOpen-collapseSix"
                      aria-expanded="false"
                      aria-controls="panelsStayOpen-collapseSix"
                      style={{
                        backgroundColor: "rgba(251, 251, 227, 1)",
                        fontSize: "20px",
                      }}
                    >
                      6. What is the duration of each Build Project course?
                    </button>
                  </h2>
                  <div
                    id="panelsStayOpen-collapseSix"
                    className="accordion-collapse collapse"
                  >
                    <div
                      className="accordion-body"
                      style={{ backgroundColor: "rgba(251, 251, 227, 1)" }}
                    >
                      The duration of each Build Project course varies,
                      typically ranging from several weeks to a few months,
                      depending on the complexity and depth of the projects.
                      Detailed course durations and schedules are provided on
                      the respective course pages.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2
                    className="accordion-header"
                    style={{ backgroundColor: "rgba(251, 251, 227, 1)" }}
                  >
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#panelsStayOpen-collapseSeven"
                      aria-expanded="false"
                      aria-controls="panelsStayOpen-collapseSeven"
                      style={{
                        backgroundColor: "rgba(251, 251, 227, 1)",
                        fontSize: "20px",
                      }}
                    >
                      7. Are there any discounts or scholarships available for
                      the courses?
                    </button>
                  </h2>
                  <div
                    id="panelsStayOpen-collapseSeven"
                    className="accordion-collapse collapse"
                  >
                    <div
                      className="accordion-body"
                      style={{ backgroundColor: "rgba(251, 251, 227, 1)" }}
                    >
                      We offer various discounts and scholarship opportunities
                      to make our courses accessible to a diverse range of
                      learners. Please refer to our website or contact our
                      support team for more information on current discounts and
                      scholarship programs available for our Build Project
                      courses.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Above footercontent */}
      <section>
        <div className="container">
          {/* <h1
            style={{
              textAlign: "center",
              color: "#000",
              fontSize: "3.5rem",
              marginBottom: "-15px",
              marginTop: "10rem",
            }}
          >
            We have a lot of exciting courses. Startup
          </h1>
          <h1
            style={{
              textAlign: "center",
              color: "#000",
              fontSize: "3.5rem",
              marginBottom: "-15px",
            }}
          >
            opportunities and candidates - only a few are
          </h1>
          <h1
            style={{
              textAlign: "center",
              color: "#000",
              fontSize: "3.5rem",
              marginBottom: "7rem",
            }}
          >
            perfect for you. we'll help you find the few.
          </h1> */}
          <h1 className="whitepage">
            Discover our diverse selection of courses, startup opportunities, and candidates, and let us assist you in finding the best match tailored to your needs
          </h1>
        </div>

        {/* onlybuttons */}
        <hr className="whitepage-buttons" />
        <div className='container fivebuttonhead '>
        <div className='row main-buttons' >
          <button className=' col-md-6 col-sm-12 fivebutton' onClick={CoursePage}>Courses</button>
          {/* <button className='col-lg-2 col-md-6 col-sm-12 fivebutton'>Professional network</button> */}
          {/* <button className='col-lg-2 col-md-6 col-sm-12 fivebutton'>Mentorship</button> */}
          <button className=' col-md-6 col-sm-12 fivebutton' onClick={() => projectPage("hero-section")}>Projects</button>
          {/* <button className='col-lg-3 col-md-6 col-sm-12 fivebutton'>Jobs</button> */}

        </div>
      </div>
        <hr
          style={{
            border: "1px solid #000000",
            borderColor: "#000000 ",
            marginTop: "7rem",
          }}
        />
      </section>

      {/* style={{width: '1919px',height: '953px'}}   marginRight:'229px'*/}

      <NewFooter />
    </div>
  );
}
