import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';
import "../styles/Sidebar.css";

import feed from '../images/icon--homesimpledoor.svg';
import page from '../images/icon--page.svg';
import allcourse from '../images/ph_video.svg';
import mycourse from '../images/fluent_learning-app-24-regular.svg';
import discussion from '../images/octicon_comment-discussion-16.svg';
import playground from '../images/mingcute_code-line.svg'
import jobs from '../images/hugeicons_job-search.svg'
import mentor from '../images/simple-icons_codementor.svg'
import live from '../images/fluent_call-24-regular.svg';
import projects from '../images/Availability.svg';
// import playground from '../images/Booking.svg';
import user from '../images/icon--user-1.svg';

const Sidebar = () => {
  mixpanel.init("5340c7c3be4b8152c06aa3e9066ed4dc" , {
    debug : true
    })

  const location = useLocation();
  const [sidebarData] = useState([
    // { path: "/career-help", name: "Feed", icon: feed },
    { path: "/all-mentors", name: "1 : 1 Services", icon: mentor },
    { path: "/session-details", name: "Bookings", icon: live },
    // { path: "/activity", name: "Activity", icon: page },
    // { path: "/dashboard", name: "All Course", icon: allcourse },
    // { path: "/my-course", name: "My Course", icon: mycourse },
    // { path: "/discussion", name: "Discussion", icon: discussion },
    // { path: "/homes", name: "Playground", icon: playground },
    // { path: "/build-project", name: "Projects", icon: projects },
    // { path: "/job-portal", name: "Jobs", icon: jobs },
 
    { path: "/user-profile", name: "Profile", icon: user }
  ]);
  
  const isActive = (path) => {
    return location.pathname === path;
  };

  const handleActiveTab = (index) => {
    mixpanel.track(`Sidebar Item Clicked: ${sidebarData[index].name}`);
  };

  return (
    <div className='position-fixed sidebar'>
      
        <div className='new-sidebar'>
          <ul>
            {sidebarData.map((item, index) => (
              <Link key={index} to={item.path} className="link-name">
                <li 
                  className={`main-list-item ${isActive(item.path) ? "sideactive" : ""}`} 
                  onClick={() => handleActiveTab(index)}
                >
                  <div className="side-option">
                    <img className="side-menu-img" src={item.icon} alt={item.name} />
                    <div>{item.name}</div>
                  </div>
                </li>
              </Link>
            ))}
          </ul>
       
      </div>
    </div>
  );
};

export default Sidebar;
