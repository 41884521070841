import React, { useRef, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { TextField, Button } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { IoCloseOutline } from "react-icons/io5";
import Select from "react-select";
import httpsignup from "../../js/http-lms_userprofile";

const ExperienceForm = ({
  onOpen,
  onClose,
  formData,
  editIndex,
  handleSubmitExp,
  optionCat,
  editExp,
  refe
}) => {
  const [companyLogoIcon, setCompanyLogoIcon] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setCompanyLogoIcon(file);
    }
  };
  const duplicateErrorRef = useRef({ jobTitle: "", companyName: "" });

  const fetchDuplicateCompany = async (jobTitle, companyName) => {
    try {
      const response = await httpsignup.get(
        `/skillsquadUserprofileLMS/checkExperience?jobTitle=${jobTitle}&companyName=${companyName}&signUpId=${sessionStorage.getItem(
          "userId"
        )}`
      );

      if (response.data === "Already Exist" && editIndex === null) {
        duplicateErrorRef.current = {
          jobTitle: "This combination of job title and company already exists.",
          companyName:
            "This combination of company and job title already exists.",
        };
      } else {
        duplicateErrorRef.current = { jobTitle: "", companyName: "" };
      }
    } catch (error) {
      console.log("Error checking duplicate company:", error);
      duplicateErrorRef.current = { jobTitle: "", companyName: "" };
    }
  };

  const handleImageClick = () => {
    document.getElementById("file-input").click();
  };
  const convertStringToBoolean = (value) => {
    if (value === "true") return true;
    if (value === "false") return false;
    return Boolean(value);
  };
  const initialValues = {
    jobTitle: formData.jobTitle || "",
    companyName: formData.companyName || "",
    companyLogoIcon: formData.companyLogoIcon || null,
    cityId: formData.cityId || null,
    startDate: formData.startDate ? dayjs(formData.startDate) : null,
    endDate: formData.endDate ? dayjs(formData.endDate) : null,
    currentlyWorking: convertStringToBoolean(formData.currentlyWorking),
  };

  const validate = (values) => {
    const errors = {};
    fetchDuplicateCompany(values.jobTitle, values.companyName);

    // Apply the duplicate errors (synchronously) from the ref
    if (duplicateErrorRef.current.jobTitle) {
      errors.jobTitle = duplicateErrorRef.current.jobTitle;
    }
    if (duplicateErrorRef.current.companyName) {
      errors.companyName = duplicateErrorRef.current.companyName;
    }

    if (!values.jobTitle || !values.jobTitle.trim()) {
      errors.jobTitle = "Job title is required";
    }
    if (!values.companyName || !values.companyName.trim()) {
      errors.companyName = "Company name is required";
    }
    if (values.cityId === null) {
      errors.cityId = "City is required";
    }
    if (!values.startDate) {
      errors.startDate = "Start date is required";
    }
    if (!values.currentlyWorking && !values.endDate) {
      errors.endDate = "End date is required";
    }
    if (
      values.endDate &&
      values.startDate &&
      dayjs(values.endDate).isBefore(dayjs(values.startDate))
    ) {
      errors.endDate = "End date can't be before start date";
    }

    return errors;
  };

  const onSubmit = (values) => {
    const formattedValues = {
      ...formData,
      ...values,
      startDate: values.startDate
        ? dayjs(values.startDate).format("YYYY-MM-DD")
        : null,
      endDate: values.endDate
        ? dayjs(values.endDate).format("YYYY-MM-DD")
        : null,
    };
    console.log(formattedValues);
    handleSubmitExp(formattedValues, companyLogoIcon);
  };
  console.log("updating form data", formData);
  return (
    <Formik
      initialValues={initialValues}
      // validationSchema={validationSchema}
      validate={validate}
      onSubmit={onSubmit}
    >
      {({ values, setFieldValue, touched, errors, setFieldError }) => (
        <Form>
          <div ref={refe}>
            <div >
              <h2>
                {editIndex === null ? "Add Experience" : "Edit Experience"}
              </h2>

              {/* Job Title */}
              <div className="add-course-layout mb-3">
                <p className="course-title" style={{ marginBottom: "4px" }}>
                  Title <span style={{ color: "red" }}>*</span>
                </p>
                <Field
                  className="course-title-input"
                  type="text"
                  name="jobTitle"
                  placeholder="Add title here"
                  onBlur={async (e) => {
                    const { value } = e.target;
                    setFieldValue("jobTitle", value, false);
                    if (value && values.companyName) {
                      await fetchDuplicateCompany(
                        value,
                        values.companyName,
                        setFieldError
                      );
                    }
                  }}
                />
                <ErrorMessage
                  name="jobTitle"
                  component="div"
                  style={{ color: "red" }}
                />
              </div>

              {/* Company Name */}
              <div className="add-course-layout mb-3">
                <p className="course-title" style={{ marginBottom: "4px" }}>
                  Company <span style={{ color: "red" }}>*</span>
                </p>
                <Field
                  className="course-title-input"
                  type="text"
                  name="companyName"
                  placeholder="Add company here"
                  onBlur={async (e) => {
                    const { value } = e.target;
                    setFieldValue("companyName", value, false);
                    if (value && values.jobTitle) {
                      await fetchDuplicateCompany(
                        values.jobTitle,
                        value,
                        setFieldError
                      );
                    }
                  }}
                />
                <ErrorMessage
                  name="companyName"
                  component="div"
                  style={{ color: "red" }}
                />
              </div>

              {/* Company Logo */}
              <div className="add-course-layout mb-3">
                <p className="course-title" style={{ marginBottom: "4px" }}>
                  Company Logo
                </p>
                {values.companyLogoIcon && (
                  <img
                    src={
                      typeof values.companyLogoIcon === "string"
                        ? values.companyLogoIcon
                        : URL.createObjectURL(values.companyLogoIcon)
                    }
                    alt="Uploaded"
                    style={{
                      maxWidth: "100px",
                      marginTop: "10px",
                      marginRight: "20px",
                    }}
                  />
                )}
                <input
                  type="file"
                  name="companyLogoIcon"
                  accept="image/*"
                  onChange={(event) => {
                    setCompanyLogoIcon(event.currentTarget.files[0]);
                  }}
                />
              </div>

              {/* Location */}
              <div className="add-course-layout mb-3">
                <p className="course-title" style={{ marginBottom: "4px" }}>
                  Location <span style={{ color: "red" }}>*</span>
                </p>
                <Select
                  value={optionCat.find(
                    (option) => option.value == values.cityId
                  )}
                  onChange={(option) => setFieldValue("cityId", option.value)}
                  options={optionCat}
                  name="cityId"
                />
                {touched.cityId && errors.cityId ? (
                  <div style={{ color: "red" }}>{errors.cityId}</div>
                ) : null}
              </div>

              {/* Dates Section */}
              <div className="add-course-layout mb-3">
                <div className="dates-grid">
                  <div>
                    <p className="course-title" style={{ marginBottom: "4px" }}>
                      Start Date <span style={{ color: "red" }}>*</span>
                    </p>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        value={values.startDate}
                        views={["year", "month"]}
                        onChange={(date) => setFieldValue("startDate", date)}
                        maxDate={dayjs()}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                    {touched.startDate && errors.startDate ? (
                      <div style={{ color: "red" }}>{errors.startDate}</div>
                    ) : null}
                  </div>

                  {!values.currentlyWorking && (
                    <div>
                      <p
                        className="course-title"
                        style={{ marginBottom: "4px" }}
                      >
                        End Date <span style={{ color: "red" }}>*</span>
                      </p>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          value={values.endDate}
                          views={["year", "month"]}
                          onChange={(date) => setFieldValue("endDate", date)}
                          maxDate={dayjs()}
                          minDate={values.startDate}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                      {touched.endDate && errors.endDate ? (
                        <div style={{ color: "red" }}>{errors.endDate}</div>
                      ) : null}
                    </div>
                  )}
                </div>
              </div>

              {/* Currently Working Checkbox */}
              <div className="mb-3">
                <Field
                  type="checkbox"
                  name="currentlyWorking"
                  id="currentlyWorking"
                  checked={values.currentlyWorking}
                  onChange={(event) => {
                    const checked = event.target.checked;
                    setFieldValue("currentlyWorking", checked);
                    if (checked) {
                      setFieldValue("endDate", null);
                    }
                  }}
                />
                <label htmlFor="currentlyWorking" style={{ marginLeft: "5px" }}>
                  Currently Working in this company
                </label>
              </div>

              {/* Save Button */}
              <div className="d-flex justify-content-end">
                <button type="submit" className="saveButton">
                  Save
                </button>
              </div>

              {/* Close Button */}
              <IoCloseOutline
                onClick={onClose}
                style={{
                  position: "absolute",
                  top: "20px",
                  right: "20px",
                  fontSize: "2rem",
                  cursor: "pointer",
                }}
              />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ExperienceForm;
