import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from "axios";
import httpsignup from '../../js/http-lms_userprofile';
import {
  Container,
  Segment,
  Item,
  Dropdown,
  Divider,
  Button,
  Message,
} from 'semantic-ui-react';

import mindImg from '../../images/mind.svg';

import {
  CATEGORIES,
  NUM_OF_QUESTIONS,
  DIFFICULTY,
  QUESTIONS_TYPE,
  COUNTDOWN_TIME,
} from '../../constants';
import { shuffle } from '../../utils';

import Offline from '../Offline';
import { IoReaderOutline } from "react-icons/io5";
import { MdAccessTimeFilled } from "react-icons/md";
import { FaRegStar } from "react-icons/fa";

const Main = (props) => {
  const data = [
    {
      logo: <IoReaderOutline />,
      mini: "35 Questions",
      description: "10 points for a correct answer",
    },
    {
      logo: <MdAccessTimeFilled />,
      mini: "15 minutes",
      description: "Total duration of the quiz",
    },
    {
      logo: <FaRegStar />,
      mini: "Win XP and Trophies",
      description: "Answer all questions correctly",
    },
  ];
  const listInstr = [
    "10 Trophies awarded for a correct answer and no trophies for a incorrect answer.",
    "Tap on options to select the correct answer",
    "Tap on the bookmark icon to save interesting questions",
    "Click submit if you are sure you want to complete all the quizzes",
    "Get maximum correct answers to climb up the leaderboard",
  ];
  const [category, setCategory] = useState('0');
  const [numOfQuestions, setNumOfQuestions] = useState(5);
  const [difficulty, setDifficulty] = useState('easy');
  const [questionsType, setQuestionsType] = useState('0');
  const [countdownTime, setCountdownTime] = useState({
    hours: 0,
    minutes: props.quizDuration * 60,
    seconds: 0,
  });
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState(null);
  const [offline, setOffline] = useState(false);

  const handleTimeChange = (e, { name, value }) => {
    setCountdownTime({ ...countdownTime, [name]: value });
  };

  let allFieldsSelected = false;
  if (
    category &&
    numOfQuestions &&
    difficulty &&
    questionsType &&
    (countdownTime.hours || countdownTime.minutes || countdownTime.seconds)
  ) {
    allFieldsSelected = true;
  }

  const fetchData = () => {
    setProcessing(true);

    if (error) setError(null);

    const API = `/programsLMS/getQuizQuestionsVO?quizCode=${props.topicId}`;

    httpsignup.get(API)

      .then(data =>

        setTimeout(() => {
          const { response_code, results } = data.data;
          console.log("DATA : " + JSON.stringify(data.data))
          if (response_code === 1) {
            const message = (
              <p>
                The API doesn't have enough questions for your query. (Ex.
                Asking for 50 Questions in a Category that only has 20.)
                <br />
                <br />
                Please change the <strong>No. of Questions</strong>,{' '}
                <strong>Difficulty Level</strong>, or{' '}
                <strong>Type of Questions</strong>.
              </p>
            );

            setProcessing(false);
            setError({ message });

            return;
          }

          results.forEach(element => {
            element.options = shuffle([
              element.correct_answer,
              ...element.incorrect_answers,
            ]);
          });

          setProcessing(false);
          props.startQuiz(
            results,
            countdownTime.hours + countdownTime.minutes + countdownTime.seconds
          );
        }, 1000)
      )
      .catch(error =>
        setTimeout(() => {
          if (!navigator.onLine) {
            setOffline(true);
          } else {
            setProcessing(false);
            setError(error);
          }
        }, 1000)
      );
  };

  if (offline) return <Offline />;

  return (
    <Container>
      <Segment>
        
        <Item.Group divided>
          <Item>
            
            <Item.Content>
              <Item.Header>
                
              </Item.Header>
              {error && (
                <Message error onDismiss={() => setError(null)}>
                  <Message.Header>Error!</Message.Header>
                  {error.message}
                </Message>
              )}
              {/* <Divider /> */}
              <div className='main-instructionPage' style={{paddingTop:"10px",paddingLeft:"50px"}}> 
                <div>
                  <h1 className='mb-4'>Brief explanation about this quiz</h1>
                  {/* <Instruction /> */}
                  <div className="d-flex flex-column">
        {data.map((item) => (
          <div className="d-flex mb-3">
            <div className="instructionLogo">
              <div
                className="d-flex justify-content-center align-items-center"
                style={{
                  backgroundColor: "#2B2124",
                  color: "white",
                  padding: "15px",
                  borderRadius: "50%",
                  fontSize: "2rem",
                }}
              >
                {item.logo}
              </div>
            </div>
            <div className="d-flex flex-column" style={{ marginLeft: "17px" }}>
              <p
                style={{
                  fontSize: "23px",
                  color: "black",
                  marginBottom: "0px",
                }}
              >
                {item.mini}
              </p>
              <p style={{ color: "grey", fontSize: "17px" }}>
                {item.description}
              </p>
            </div>
          </div>
        ))}
      </div>
      <h2 className="mb-4" style={{ fontSize: "20px", fontWeight: "800" }}>
        Please read the text below carefully so you can understand it
      </h2>
      <div style={{ paddingLeft: "18px" }}>
        {listInstr.map((item) => (
          <li
            className=""
            style={{ fontSize: "19px", color: "", marginBottom: "30px" }}
          >
            {item}
          </li>
        ))}
      </div>
      <div style={{ paddingRight: "30px" }}>
        <button
          className="button mt-5 mb-4"
          style={{
            float: "right",
            fontSize: "25px",
            height: "50px",
            width: "250px",
            backgroundColor: "blue",
            borderRadius: "10px",
            color: "white",
          }}
          content={processing ? "Processing..." : "Start"}
          onClick={fetchData}
          disabled={!allFieldsSelected || processing}
        >
          Start Quiz
        </button>
      </div>

                  </div>
              </div>
              
            </Item.Content>
          </Item>
        </Item.Group>
      </Segment>
      <br />
    </Container>
  );
};

Main.propTypes = {
  startQuiz: PropTypes.func.isRequired,
};

export default Main;
