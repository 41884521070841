import React from "react";
import PropTypes from "prop-types";
import { Segment, Header, Button } from "semantic-ui-react";
import "../../styles/Resultpage.css";
import ShareButton from "../ShareButton";
import { calculateScore, calculateGrade, timeConverter } from "../../utils";
import Result from ".";
import { TiTick } from "react-icons/ti";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { MdBlockFlipped } from "react-icons/md";
import { FaTrophy } from "react-icons/fa6";
import low from "../../images/ribbon1.png";
import good from "../../images/ribbon2.png";
import perfect from "../../images/ribbon3.png";
import average from "../../images/ribbon4.png";
import head from "../../images/resultpage2.jpeg";
import crown from "../../images/resultpage3.jpeg";
import httpsignup from '../../js/http-lms_userprofile';
import { useState, useEffect } from "react";

const Stats = ({
  totalQuestions,
  correctAnswers,
  timeTaken,
  replayQuiz,
  resetQuiz,
  quitQuiz,
}) => {
  const score = calculateScore(totalQuestions, correctAnswers);
  const { grade, remarks } = calculateGrade(score);
  const { hours, minutes, seconds } = timeConverter(timeTaken);

  const resultPage = [
    {
      id: 1,
      crown: crown,
      head: head,
      perfectScore: perfect,
      leftImage: "left image",
      rightImage: "rightImage",
      correctAnswers: correctAnswers,
      totalQuestions: totalQuestions,
      trophyImage: "trophy image",
      countOfTrophies: "35k Trophies",
      resultText: "Congratulations You Scored a Perfect 100%!",
      buttonIcon: <TiTick />,
      buttonText: "Keep it Up",
      buttonBackgrounColor: "green",
    },
    {
      id: 2,
      head: head,
      perfectScore: good,
      leftImage: "left image",
      rightImage: "rightImage",
      correctAnswers: correctAnswers,
      totalQuestions: totalQuestions,
      trophyImage: "trophy image",
      countOfTrophies: "35k Trophies",
      resultText: "Well Done You scored well on the quiz.",
      buttonIcon: <TiTick />,
      buttonText:
        "Consider reviewing the questions you missed to strengthen you understanding.",
      buttonBackgrounColor: "green",
    },
    {
      id: 3,

      head: head,
      perfectScore: average,
      leftImage: "left image",
      rightImage: "rightImage",
      correctAnswers: correctAnswers,
      totalQuestions: totalQuestions,
      trophyImage: "trophy image",
      countOfTrophies: "24k Trophies",
      resultText: "You're making progress",
      buttonIcon: <AiOutlineExclamationCircle />,
      buttonText:
        "Keep practicing and reviwing the material to improve your score",
      buttonBackgrounColor: "yellow",
    },
    {
      id: 4,

      head: head,
      perfectScore: low,
      leftImage: "left image",
      rightImage: "rightImage",
      correctAnswers: correctAnswers,
      totalQuestions: totalQuestions,
      trophyImage: "trophy image",
      countOfTrophies: "5k Trophies",
      resultText: "Don't worry if you didn't score as high as you hoped",
      buttonIcon: <MdBlockFlipped />,
      buttonText: "Review the questions and Try again",
      buttonBackgrounColor: "red",
    },
  ];

  const displayCorrectResultPage = (correctAnswers) => {
    if (correctAnswers === 35) {
      return resultPage.filter((item) => item.id === 1);
    } else if (correctAnswers >= 32 && correctAnswers <= 34) {
      return resultPage.filter((item) => item.id === 2);
    } else if (correctAnswers >= 24 && correctAnswers <= 32) {
      return resultPage.filter((item) => item.id === 3);
    } else {
      return resultPage.filter((item) => item.id === 4);
    }
  };

  const result = displayCorrectResultPage(correctAnswers);
  const [userImage, setUserImage] = useState();

  function getLoggedInUserDetails() {
    console.log("TRIGGERED LOGGED")
    httpsignup.get(`/skillsquadUserprofileLMS/getSkillsquadUserProfileBySignUpId?signUpId=${sessionStorage.getItem('userId')}`)
        .then((response) => {
         
          setUserImage(response.data.profilePictureUrl);

        }).catch((err) => {
            console.log(err)
        })


}

useEffect(() => {
  getLoggedInUserDetails()
}, [])

  return (
    <div>
      {/* <Header as="h1" textAlign="center" block>
        {remarks}
      </Header>
      <Header as="h2" textAlign="center" block>
        Grade: {grade}
      </Header>
      <Header as="h3" textAlign="center" block>
        Total Questions: {totalQuestions}
      </Header>
      <Header as="h3" textAlign="center" block>
        Correct Answers: {correctAnswers}
      </Header>
      <Header as="h3" textAlign="center" block>
        Your Score: {score}%
      </Header>
      <Header as="h3" textAlign="center" block>
        Passing Score: 60%
      </Header>
      <Header as="h3" textAlign="center" block>
        Time Taken:{' '}
        {`${Number(hours)}h ${Number(minutes)}m ${Number(seconds)}s`}
      </Header> */}
      <div className="  text-white">
        {result.map((item) => (
          <div
            className=" resultpage d-flex flex-column align-items-center justify-content-center"
            // style={{ position: "absolute", top: "15%" }}
          >
            <div className="d-flex flex-column align-items-center mb-3">
              {item.id === 1 && (
                <img
                  src={item.crown}
                  alt="crown"
                  style={{
                    width: "80px",
                    height: "80px",
                    borderRadius: "0%",
                    
                    marginTop:"100px"
                  }}
                />
              )}
              <img
                src={userImage}
                alt="head"
                style={{
                  width: "150px",
                  height: "150px",
                  borderRadius: "50%",
                  position: "relative",
                  zIndex: 1,
                  bottom: "-30px",
                  
                }}
              />
              <img
                src={item.perfectScore}
                alt="head"
                style={{ width: "400px", height: "85px", zIndex: 2 }}
              />
            </div>
            <p className="text-center" style={{ marginBottom: "0px" }}>
              Correct Answers
            </p>
            <div className="d-flex align-items-center justify-content-center mb-3">
              <h1 className="text-white" style={{ marginTop: "0px" }}>
                {item.correctAnswers} / {item.totalQuestions}
              </h1>
            </div>
            <div className="d-flex align-items-center justify-content-center mb-3">
              <FaTrophy
                className=""
                style={{ marginRight: "5px", fontSize: "1.5rem",color:"yellow" }}
              />
              <p>{item.countOfTrophies}</p>
            </div>
            <div className="d-flex align-items-center text-center mb-3">
              <TiTick
                style={{
                  fontSize: "2rem",
                  backgroundColor: "green",
                  borderRadius: "50%",
                  padding: "2px",
                  marginRight: "5px",
                }}
              />
              <p>{item.resultText}</p>
            </div>
            <div className="d-flex align-items-center text-black" style={{backgroundColor:item.buttonBackgrounColor,padding:"5px",paddingRight:"22px",paddingLeft:"22px",borderRadius:"7px"}}>
              {item.buttonIcon}
              <p style={{ marginLeft: "5px" ,color:"white"}}>{item.buttonText}</p>
            </div>
          </div>
        ))}
      </div>
      <div style={{ marginTop: 35 }}>
        {/* <Button
          primary
          content="Play Again"
          onClick={replayQuiz}
          size="big"
          icon="redo"
          labelPosition="left"
          style={{ marginRight: 15, marginBottom: 8 }}
        />*/}
        {/* <Button
          color="teal"
          content="Back to Your schedule"
          onClick={quitQuiz}
          size="big"
          icon="home"
          labelPosition="left"
          style={{ marginBottom: 8 }}
        /> */}
        {/* <ShareButton />  */}
      </div>
    </div>
  );
};

Stats.propTypes = {
  totalQuestions: PropTypes.number.isRequired,
  correctAnswers: PropTypes.number.isRequired,
  timeTaken: PropTypes.number.isRequired,
  replayQuiz: PropTypes.func.isRequired,
  resetQuiz: PropTypes.func.isRequired,
  quitQuiz: PropTypes.func.isRequired,
};

export default Stats;