import React from "react";
import httpsignup from "../js/http-lms_userprofile";
import { useState, useEffect } from "react";
import "../styles/Discussion.css";
import ReactHtmlParser from "html-react-parser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faReply } from "@fortawesome/free-solid-svg-icons";
import arrow from "../images/left-arrow_icon.png";
import moment from "moment/moment";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import IndividualReply from "./IndividualReply";
import { Spinner } from "react-bootstrap";
import {motion} from 'framer-motion';

const DiscussionView = ({ discussionsData, closeViewBox }) => {
  const [userDetails, setUserDetails] = useState({});

  const [discussionReplyData, setDiscussionReplyData] = useState([]);
  console.log("reply is", discussionReplyData);

  const [upvoteData, setUpvoteData] = useState({});

  const [image, setImage] = useState();

  const [message, setMessage] = useState("");

  const [error, setError] = useState(false);
  const [editorOpen, setEditorOpen] = useState(false);

  useEffect(() => {
    getLoggedInUserDetails();
    getDiscussionReply(discussionsData.discussionId);
  }, []);

  function clear() {
    setMessage("");
  }

  const getLoggedInUserDetails = async () => {
    httpsignup
      .get(
        `/skillsquadUserprofileLMS/getSkillsquadUserProfileBySignUpId?signUpId=${sessionStorage.getItem(
          "userId"
        )}`
      )
      .then((response) => {
        setUserDetails(response.data);
        getupvote(response.data.lmsProfileId);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getDiscussionReply = async (id) => {
    httpsignup
      .get(`/usersLMS/getDiscussionsReply?discussionId=${id}`)
      .then((response) => {
        setDiscussionReplyData(response.data);
        console.log("REPLY : " + JSON.stringify(response.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function saveUpvote(e) {
    e.preventDefault();
    httpsignup
      .post(
        `/usersLMS/saveUpvote?discussionId=${discussionsData.discussionId}&profileId=${userDetails.lmsProfileId}`
      )
      .then((response) => {
        getupvote(userDetails.lmsProfileId);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function getupvote(id) {
    httpsignup
      .get(
        `/usersLMS/upvoteCount?discussionId=${discussionsData.discussionId}&profileId=${id}`
      )
      .then((response) => {
        setUpvoteData(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const handleChanges = (event) => {
    setError(false);
    setMessage(event.target.value);
  };

  const [loading, setLoading] = useState(false);
  function saveReply(e) {
    e.preventDefault();
    console.log("save reply is triggering", textValue);
    if (textValue === "") {
      setError(true);
    } else {
      setError(false);
      setLoading(true);
      httpsignup
        .post(`/usersLMS/saveDiscussionReply`, {
          discussionId: discussionsData.discussionId,
          profileId: userDetails.lmsProfileId,
          reply: textValue,
          creationUserI: userDetails.userEmail,
          lastUpdatedUserI: userDetails.userEmail,
        })
        .then((response) => {
          console.log(response);
          setMessage(response.data.reply);
          setLoading(false);
          getDiscussionReply(discussionsData.discussionId);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  const [textValue, setTextValue] = useState("");
  const [body, setBody] = useState("");
  const handleTextEditor = (html) => {
    // console.log(e);
    setTextValue(html);
    // setBody(ReactHtmlParser(e));
    // console.log(body, "dsjkdbjwd");
  };
  const [editorError,setEditorError] = useState(false);
const handleEditorData = () =>{
  if(textValue === ""){
    setEditorError(true)
  }else{
    setEditorError(false)
    setEditorOpen(false);
    setTextValue("");
  }
 
}

const handleCancelButton = () =>{
  setEditorOpen(!editorOpen);
  setEditorError(!editorError);
}
  return (
    <>
      <div className="jobs-dashboard-container">
        {/* <Navb /> */}

        <div className="px-md-5  mt-5">
          <section class="section discussion_view">
            <div class="container">
              <div class="row ">
                <div class="col-lg-12">
                  <div class="row">
                    <div class="">
                      <div class="mb-4">
                        <div className="col-lg-9">
                          <img
                            src={arrow}
                            width={30}
                            alt="arrow"
                            style={{ cursor: "pointer" }}
                            onClick={closeViewBox}
                          />
                          <div class="discussion_thread_card mt-2">
                            <div class="row">
                              <div class="col">
                                <div class="d-flex align-items-center">
                                  <img
                                    className=" "
                                    style={{
                                      width: 40,
                                      height: 40,
                                      borderRadius: "50%",
                                      marginRight: "6px",
                                    }}
                                    src={discussionsData.profilePicture}
                                    alt="profile"
                                  />
                                  <div>
                                    <div class="common_main_text_highlight">
                                      {discussionsData.userName}
                                    </div>
                                    <div class="common_main_text_tag">
                                      {moment(
                                        discussionsData.discussionCreatedDate
                                      ).format("D MMM YYYY hh:mm")}{" "}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="row" style={{marginLeft:"20px"}}>
                              <div class="col discussion_title mt-3">
                                {discussionsData.title}
                              </div>
                            </div>
                            <div class="row" style={{marginLeft:"20px"}}>
                              <div class="col mt-3">
                                {ReactHtmlParser(discussionsData.description)}
                              </div>
                            </div>
                            <div class="discussion_footer" style={{marginLeft:"20px"}}>
                              <div class="d-flex justify-content-between">
                                <div
                                  class="discussion_tag_coursename"
                                  style={{ marginRight: 5 }}
                                >
                                  {discussionsData.courseName}
                                </div>{" "}
                                <div class="discussion_tag_coursename">
                                  {discussionsData.topicName}
                                </div>
                              </div>
                              <div className="reply-and-upvote"> 
                                <div className="d-flex align-items-center"  onClick={() => setEditorOpen(!editorOpen)}>
                                <img src={require("../images/uil_comment-dots.png")} />
                                <span>{discussionsData.replyCount}</span>
                                  </div>
                                  <div
                                class="discussion_upvote"
                                onClick={(e) => {
                                  saveUpvote(e);
                                }}
                                style={{ cursor: "pointer" }}
                              >
                               
                                <div class="d-flex align-items-center">
                                  
                                  {upvoteData.upvoteFlag === "Y" ? (
                                   <motion.div
                                  //  initial={{
                                  //    y: 30,
                                  //    opacity: 0,
                                  //    scale: 0.8,
                                  //  }}
                                  initial={upvoteData.upvoteFlag !== "Y" ? {y:0,opacity:1,scale:1} : {y:30,opacity:0,scale:0.8}}
                                   animate={{
                                     y: 0,
                                     opacity: 1,
                                     scale: 1,
                                   }}
                                   transition={{
                                     type: "spring",
                                     stiffness: 100,
                                   }}
                                   class="d-flex align-items-center"
                                   style={{
                                    
                                   }}
                                 >
                                   <img
                                     src={require("../images/Upvote.png")}
                                     style={{
                                       marginRight: 5,objectFit:"contain",
                                       height:"20px",width:"20px"

                                     }}
                                   />
                                   {upvoteData.upvoteCount}
                                 </motion.div>
                                  ) : (
                                    <div
                                      class="d-flex align-items-center"
                                      
                                    >
                                      <img
                                        src={require("../images/bx_upvote.png")}
                                        // style={{ marginRight: 5 ,color:""}}
                                      />
                                     <span class="">Upvote</span>
                                    </div>
                                  )}
                                </div>
                              </div>
                                  </div>
                            
                            </div>
                          </div>
                          <div
                            class="mt-4 common_main_heading mb-4"
                            style={{ textAlign: "center" }}
                          ></div>
                          <div class="discussion_thread_card discussion-reply-box">
                            {/* <input
                              id="message"
                              name="message"
                              type="text"
                              onChange={handleChanges}
                              value={message}
                              className="discussion-reply-input"
                              placeholder="Type your reply here"
                            /> */}
                            {/* <div
                              class="send-button"
                              onClick={(e) => {
                                saveReply(e);
                              }}
                            >
                              <img src={require("../images/suggest.png")} />
                              <div class="mx-2">Send</div>
                            </div> */}
                            {editorOpen && (
                              <>
                                <ReactQuill
                                  value={textValue}
                                  placeholder="Write something from your mind.."
                                  modules={DiscussionView.modules}
                                  formats={DiscussionView.formats}
                                  onChange={handleTextEditor}
                                />
                                <div className="d-flex justify-content-between align-items-center" style={{position:"relative"}}>
                                  {editorError && <p style={{color:"red"}}>Please enter your answer</p>}
                                <div className="cancel-and-save" >
                                  <div className="d-flex align-items-center"></div>
                                  <div
                                    class="btn btn-info"
                                    style={{ padding: "6px 20px" }}
                                    // onClick={clear}
                                    onClick={handleCancelButton}
                                  >
                                    CANCEL
                                  </div>
                                  <div
                                    class=""
                                    //   style={{ padding: "6px 20px" }}
                                    onClick={(e) => {
                                      saveReply(e);
                                    }}
                                  >
                                    <div class="btn btn-primary" onClick={handleEditorData}>
                                      <div class="">
                                        {loading ? <Spinner
                                            animation="border"
                                            variant="lightsecondary"
                                          /> : "REPLY"}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                </div>
                              </>
                            )}

                            {/* {error && (
                              <div
                                class="common_main_text_tag"
                                style={{ color: "red" }}
                              >
                                Please enter your suggestion
                              </div>
                            )} */}

                            {/* <div class="discussion_footer mt-2">
                              {" "}
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                              <div class="row">
                                {" "}
                                <div
                                  class="col common_main_button_black mx-2"
                                  style={{ padding: "6px 20px" }}
                                  onClick={clear}
                                >
                                  Cancel
                                </div>
                                <div
                                  class="col common_main_button mx-2"
                                  style={{ padding: "6px 20px" }}
                                  onClick={(e) => {
                                    saveReply(e);
                                  }}
                                >
                                  <div class="d-flex align-items-center">
                                    <img
                                      src={require("../images/suggest.png")}
                                    />
                                    <div class="mx-2">Suggest</div>
                                  </div>
                                </div>
                              </div>
                            </div> */}
                          </div>

                          {discussionReplyData.map((data, index) => {
                            return (
                              <div class="discussion_thread_card mt-4">
                                <div class="row">
                                  {/* <div
                                    class="d-flex flex-column"
                                    style={{ position: "relative" }}
                                  >
                                    <div class="d-flex align-items-start">
                                      <img
                                        className="admin-pro-pic my-1 me-2"
                                        style={{ width: 40, height: 40 }}
                                        src={data.profilePictureUrl}
                                        alt="profile"
                                      />
                                      <div classname="name-and-timing">
                                        <span className="reply-user-name">
                                          {data.profileName}
                                        </span>

                                        <span className="reply-user-upload-time">
                                          {" "}
                                          {moment(
                                            data.skillsquadDiscussionReply
                                              .creationD
                                          ).format("D MMM YYYY HH:MM")}
                                        </span>
                                        <div className="individual-discussion-reply">
                                          {data.skillsquadDiscussionReply.reply}
                                        </div>
                                        
                                      </div>
                                    </div>
                                    <div className="show-reply">
                                      <div className="show-reply-message">
                                        <img
                                          src={require("../images/chevrons-up.svg")}
                                          alt="up and down arrow"
                                        />
                                        <span className="show-all-reply-message">
                                          Show All Replies (2)
                                        </span>
                                      </div>
                                      <div className="show-reply-message">
                                        <img
                                          src={require("../images/corner-down-right.svg")}
                                          alt="reply"
                                        />
                                        <span className="show-all-reply-message">
                                          Reply
                                        </span>
                                      </div>
                                    </div>
                                    <div className="sub-replies" style={{position:"absolute",left:"10%",bottom:"-20px",display:"block"}}>
                                            
                                        </div>
                                  </div>
                                   */}
                                  <IndividualReply
                                    profilePicture={data.profilePictureUrl}
                                    profileName={data.profileName}
                                    creationId={
                                      data.skillsquadDiscussionReply.creationD
                                    }
                                    reply={data.skillsquadDiscussionReply.reply}
                                  />
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default DiscussionView;

DiscussionView.modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { header: [3, 4, 5, 6] }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image", "video"],
    ["clean"],
    ["code-block"],
  ],
};

DiscussionView.formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "link",
  "image",
  "video",
  "code-block",
];
