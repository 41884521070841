import React, { useState, useEffect, useRef } from "react";
import "../styles/newnav.css";
import { FaBars, FaTimes } from "react-icons/fa";
// import mcell from "../images/m-call.svg";
import mcell from "../images/callB.svg";
import mlearn from "../images/m-learn.svg";
import noprofile from "../images/No profile 1.svg";
import mbrowse from "../images/m-browse.svg";
import msell from "../images/m-seller.svg";
import mnav from "../images/m-nav.svg";
import mup from "../images/m-up.svg";
import { usePopup } from "./PopupContext";
import { useMentors } from "../pages/MentorLandingPage/MentorDataContext";
import httpsignup from "../js/http-lms_userprofile";
import { Link, useNavigate, useParams } from "react-router-dom";
import search from "../pages/MentorLandingPage/mentorimages/search 1.svg";
import logo from "../images/blue_skillsquad_logo.png";
import mentor from "../images/simple-icons_codementor.svg";
import CompleteProfilePopup from "./CompleteProfilePopup";
import useElementInView from "../pages/MentorLandingPage/useElementInView";
import { IoIosLogOut } from "react-icons/io";
import { VscClose } from "react-icons/vsc";

const NewNavbar = () => {
  const {
    openFirstPopup,
    isFirstPopupOpen,
    isSecondPopupOpen,
    isInitialPop,
    updateSearch,
    getAllMentorsListByCat,
    allMentors,
    stopMentorLoading,
    setMentorLoading,
    updateAdmin,
    adminProfile,
    updateSearchBar,
    userProfileData,
  } = usePopup();
  const nav = useRef(null);
  useEffect(() => {
    if (
      // window.location.pathname === "/" ||
      window.location.pathname === "/sign-in" ||
      window.location.pathname === "/nav" ||
      window.location.pathname === "/custom" ||
      window.location.pathname === "/forgetpassword" ||
      window.location.pathname === "/verifyotp" ||
      window.location.pathname === "/updatepass" ||
      window.location.pathname === "/projects" ||
      window.location.pathname === "/sign-up" ||
      window.location.pathname === "/course" ||
      window.location.pathname === "/refund-policy" ||
      window.location.pathname === "/code-of-conduct" ||
      window.location.pathname === "/privacy-policy" ||
      window.location.pathname == "/terms-and-conditions"
    ) {
      nav.current.style.display = "none";
    } else {
      nav.current.style.display = "flex";
    }
  }, [window.location.pathname]);

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isProfilePopupOpen, setIsProfilePopupOpen] = useState(false);
  const dropdownRef = useRef(null);
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowOptions(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const navigate = useNavigate();
  function handleProfile1() {
    console.log("ko");
    setShowOptions(false);
    navigate("/user-profile");
  }

  function signUp() {
    navigate("/sign-up");
  }

  function handleProfile2() {
    window.open("https://app.youform.com/forms/bhhp6ean", "_blank");
    setShowOptions(false);
  }
  const [mentorInfo, setMentorInfo] = useState(null);

  const [mentorHomeInfo, setMentorHomeInfo] = useState(null);

  const [updateInfo, setUpdateInfo] = useState(false);
  const [profileId, setprofileId] = useState(null);
  const getLoggedInUserDetails = async () => {
    const response = await httpsignup
      .get(
        `/skillsquadUserprofileLMS/getSkillsquadUserProfileBySignUpId?signUpId=${sessionStorage.getItem(
          "userId"
        )}`
      )
      .then((response) => {
        setprofileId(response.data);
        setIsLoggedIn(true);
        setprofileId(response.data);
        getMentorInfo(response.data.profileId);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getLoggedInUserDetails();
  }, [sessionStorage.getItem("userId")]);
  const getMentorInfo = async (id) => {
    try {
      const response = await httpsignup.get(
        `/mentor/getSkillsquadMentorApplicationsByProfileId?profileId=${id}`
      );
      if (response && response.data) {
        setMentorInfo(response.data);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const getMentorHomeInfo = async () => {
    try {
      const redirectChange = await httpsignup.get(
        `/mentor/getMentorHomeInfo?mentorId=${mentorInfo?.mentorId}`
      );
      if (redirectChange && redirectChange.data) {
        setMentorHomeInfo(redirectChange.data);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    getMentorHomeInfo();
  }, [mentorInfo?.mentorId]);
  useEffect(() => {
    if (mentorHomeInfo) {
      const Info = Object.values(mentorHomeInfo).every(
        (value) => value === "Y"
      );
      setUpdateInfo(Info);
    }
    console.log("update info", updateInfo, mentorHomeInfo);
  }, [mentorHomeInfo, updateInfo, mentorInfo?.mentorId]);
  const { uniqueUserName } = useParams();
  const getUnique = async () => {
    if (uniqueUserName !== undefined && uniqueUserName !== null) {
      try {
        console.log("before making api request", uniqueUserName);
        const response = await httpsignup.get(
          `/skillsquadUserprofileLMS/getSkillsquadUserProfileByUniqueName?uniqueUserName=${uniqueUserName}`
        );
        console.log("unique mentor id in provider", response.data);
        sessionStorage.setItem("userId", response.data.signUpId);
        setIsLoggedIn(false);
      } catch (error) {
        console.log(error);
      }
    } else {
      console.error("error");
    }
  };
  useEffect(() => {
    getUnique();
  }, [uniqueUserName]);

  const [appliedMentor, setAppliedMentor] = useState(false);
  const getStatusApplied = async () => {
    try {
      const response = await httpsignup.get(
        `/mentor/checkMentorApplied?profileId=${profileId?.profileId}`
      );
      if (response.data === "Applied") {
        setAppliedMentor(true);
        console.log("applied", appliedMentor);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getStatusApplied();
  }, [isSecondPopupOpen]);
  const [menuOpen, setMenuOpen] = useState(false);
  const [servicesDropdownOpen, setServicesDropdownOpen] = useState(false);

  const toggleMenu = (gate) => {
    setMenuOpen(!menuOpen);
    if (gate != "") {
      navigate(gate);
    }
  };
  const handleAllMentors = () => {
    if (sessionStorage.getItem("JWT")) {
      navigate("/mentors/1:1-services/recommended");
      setMenuOpen(!menuOpen);
    } else {
      navigate("/sign-up");
    }
  };
  const toggleServicesDropdown = () => {
    setServicesDropdownOpen(!servicesDropdownOpen);
  };
  const handleOpenPopup = () => {
    if (sessionStorage.getItem("userId") && sessionStorage.getItem("JWT")) {
      navigate("/user-profile");
      openFirstPopup();
      setMenuOpen(false);
    } else {
      navigate("/sign-up");
      setMenuOpen(false);
    }
  };
  const handleignUpClick = () => {
    signUp();
    setMenuOpen(false);
  };
  //   ----------------------------------------------------
  const [targetRef, isInView] = useElementInView();
  const {
    scrollToElementById,
    handleSearch,
    getAllLanguages,
    allLanguages,
    getmentorListByLanguage,
    getId
  } = useMentors();
  const [query, setQuery] = useState("");

  const handleFilter = () => {
    if (query.length >= 1) {
      handleSearch(query);
      scrollToElementById("bookings-section");
    } else {
      alert("The query should have at least three characters ");
    }
  };

  const handleKeyDown = (e) => {
    e.preventDefault();
    if (e.key === "Enter") {
      handleFilter(query);
    }
  };
  function loginPage() {
    navigate("/sign-in");
  }

  function handleClickLogOut() {
    window.location.href = "/mentor-page/1:1-services/recommended";
    sessionStorage.clear();
    localStorage.clear();
    setShowOptions(false);
  }
  const [showOptions, setShowOptions] = useState(false);
  const handleShowEditOptions = () => {
    setShowOptions(!showOptions);
  };
  const closeProfilePopup = () => {
    setIsProfilePopupOpen(false);
  };
  useEffect(() => {
    if (window.location.pathname.split("/")[1]) {
      updateSearchBar(true);
    } else {
      updateSearchBar(false);
    }
  }, [window.location.pathname]);

  const handleLearnPage = () => {
    window.open("https://learning.skillsquad.io/", "_blank");
    setShowOptions(!showOptions);
  };
  const handleHomePage = () => {
    if (sessionStorage.getItem("userId") && sessionStorage.getItem("JWT")) {
      navigate("/mentors/1:1-services/recommended");
    } else {
      navigate("/mentor-page/1:1-services/recommended");
    }
  };

  const handleMentorNavigation = () => {
    window.open("https://www.skillsquad.io/", "_blank");
    setShowOptions(!showOptions);
  };
  const handleSearchNav = (e) => {
    const { value } = e.target;
    setQuery(value);
    if(value.trim().length <= 0 ){
      getId("recommended",null);
    }
   
  };
  const removeHandleSearch = () =>{
    setQuery("");
    getId("recommended",null);
  }
  useEffect(() => {
    getAllLanguages();
  }, [sessionStorage.getItem("userId")]);
  const [selectedLanguage, setSelectedlanguage] = useState(21);
  const handleLanguageSelect = (index, native) => {
    setSelectedlanguage(index);
    sessionStorage.setItem("lan", native);
  };
  const handleSubmitLanguage = () => {
    getmentorListByLanguage(selectedLanguage);
  };
  return (
    <>
      {isProfilePopupOpen && (
        <CompleteProfilePopup
          onClose={closeProfilePopup}
          refreshPage={() => getLoggedInUserDetails()}
        />
      )}
      <header className="navbar shadow-lg " ref={nav}>
        <div className="navbar-container container">
          <div
            className="navbar-logo"
            style={{ display: "flex", alignItems: "center", maxWidth: "auto" }}
          >
            <div>
              <img
                className="lap_logo"
                src={logo}
                alt="Logo"
                onClick={handleHomePage}
                style={{ cursor: "pointer", height: "30px" }}
              />
              <img
                className="mobile_logo"
                src={require("../images/skillsquad_logo_fav_png.png")}
                alt="Logo"
                onClick={handleHomePage}
                style={{ cursor: "pointer" }}
              />
            </div>
            {updateSearch &&
              (window.location.pathname.split("/")[1] === "mentors" ||
                window.location.pathname.split("/")[1] === "mentor-page") && (
                <div
                  className="h-m-s"
                  style={{
                    height: "50px",
                    marginLeft: "20px",
                    width: "350px",
                  }}
                >
                  <div className="" style={{position:"relative",height:"100%",width:"100%"}}>
                  <input
                    className="h-m-s-i"
                    placeholder="Search by company,role..."
                    onChange={handleSearchNav}
                    // onKeyDown={handleKeyDown}
                    value={query}
                  />
                 {query.trim().length > 0 &&  <VscClose className="close-i-n" onClick={removeHandleSearch} />}
                  </div>
                  <div className="search-icon" onClick={handleFilter}>
                    <img src={search} alt="search" />
                  </div>
                </div>
              )}
          </div>

          <nav className={`navbar-menu ${menuOpen ? "active" : ""}`}>
            <ul>
              {!isLoggedIn && (
                <li className="d-flex align-items-center nav-profile-place lap-nav-place hover-bg">
                  <img
                    className="admin-pro-pic "
                    style={{
                      width: 35,
                      height: 35,
                      gap: "8px",
                      display: "flex",
                      alignItems: "center",
                      marginRight: "8px",
                      flexWrap: "no-wrap",
                    }}
                    src={
                      profileId?.profilePictureUrl
                        ? profileId.profilePictureUrl
                        : noprofile
                    }
                    alt="okay"
                  />

                  <Link to="/sign-in" onClick={() => setMenuOpen(false)}>
                    <h6 className="mobile-username">
                      {profileId?.userName ? profileId?.userName : "Login"}
                    </h6>
                  </Link>
                </li>
              )}

              <li className="l-s">
                <div className="n-l-s">
                  <div className="s-l-c-i">
                    <div>Language</div>
                    <svg
                      width="9"
                      height="5"
                      viewBox="0 0 9 5"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.796387 0.648193L4.50009 4.3519L8.20379 0.648193"
                        stroke="#170F49"
                        stroke-width="1.2963"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                  <div>
                    {sessionStorage.getItem("lan")
                      ? sessionStorage.getItem("lan")
                      : "English"}
                  </div>
                </div>
                <div className="s-r-l">
                  <div className="s-l">Select language</div>
                  <div className="a-r-b">
                    {allLanguages?.map((l, index) => (
                      <div
                        className="r-l-b"
                        onClick={() =>
                          handleLanguageSelect(l.languageId, l.nativescript)
                        }
                      >
                        <div className="s-r-c">
                          <input
                            type="checkbox"
                            checked={selectedLanguage === l.languageId}
                          />
                          <div className="l-n">{l.nativescript}</div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="a-p-l" onClick={handleSubmitLanguage}>
                    Apply
                  </div>
                </div>
              </li>
              <li>
                <a onClick={handleLearnPage}>
                  <img className="nav-image" src={mlearn} alt="learn page" />{" "}
                  Learn
                </a>
              </li>
              {isLoggedIn && (
                <li
                  className={
                    window.location.pathname === "/session-details"
                      ? "m-l-active-nav"
                      : ""
                  }
                >
                  <a onClick={() => toggleMenu("/session-details")}>
                    <img className="nav-image" src={mcell} alt="learn page" />
                    <span> Bookings</span>
                  </a>
                </li>
              )}
              {!(
                isInitialPop ||
                isFirstPopupOpen ||
                isSecondPopupOpen ||
                adminProfile ||
                updateInfo ||
                appliedMentor
              ) && (
                <li>
                  <a onClick={handleOpenPopup}>
                    <img
                      className="nav-image"
                      src={msell}
                      alt="become a seller"
                    />
                    <span className={`become-seller`}>
                      {" "}
                      Become an Expert <img src={mnav} />
                    </span>
                  </a>
                </li>
              )}
              {updateInfo && (
                <li onClick={handleMentorNavigation}>
                  <a>
                    <img
                      className="nav-image"
                      src={msell}
                      alt="become a seller"
                    />
                    <span className={`become-seller`}>
                      {" "}
                      Mentor Dashboard <img src={mnav} />
                    </span>
                  </a>
                </li>
              )}
              {isLoggedIn && (
                <li className="nav-logout" onClick={handleClickLogOut}>
                  <a>
                    <IoIosLogOut style={{ height: "24px", width: "24px" }} />
                    <span className=""> Logout</span>
                  </a>
                </li>
              )}
              {/* <li style={{ color: "#000" }} className="cat-service">
                <span onClick={toggleServicesDropdown} className="m-browse">
                  <img
                    className="nav-image"
                    src={mbrowse}
                    alt="browse categories"
                  />
                  Browse Categories <img className="m-up" src={mup} />
                </span>
                <div
                  className={`cat-sub-menu ${
                    servicesDropdownOpen ? "active" : ""
                  }`}
                >
                  <div>Design</div>
                  <div>Development</div>
                  <div>UI/UX Design</div>
                </div>
              </li> */}
              {!isLoggedIn && (
                <Link to="/sign-in" onClick={() => setMenuOpen(false)}>
                  <li className="n-l-button">
                    <a>Login</a>
                  </li>
                </Link>
              )}

              {sessionStorage.getItem("JWT") ? (
                <li
                  className={`nav-item dropdown align-items-center ${
                    isLoggedIn ? "nav-profile-place-large" : "nav-profile-place"
                  }`}
                >
                  <button
                    class="dropdown-toggle"
                    style={{
                      background: "none",
                      border: "none",

                      position: "relative",
                    }}
                    onClick={handleShowEditOptions}
                  >
                    <div className="d-flex align-items-center nav-profile-place">
                      <img
                        className="admin-pro-pic-place admin-pro-pic"
                        style={{
                          width: 35,
                          height: 35,
                          gap: "8px",
                          display: "flex",
                          alignItems: "center",
                          marginRight: "8px",
                          flexWrap: "no-wrap",
                        }}
                        src={
                          profileId?.profilePictureUrl
                            ? profileId.profilePictureUrl
                            : noprofile
                        }
                        alt="okay"
                      />

                      <h6 className="mobile-username">
                        {profileId?.userName ? profileId?.userName : "Login"}
                      </h6>
                    </div>
                  </button>
                  {showOptions && (
                    <ul
                      ref={dropdownRef}
                      style={{
                        width: "150px",
                        position: "absolute",
                        top: "100%",
                        alignItems: "flex-start",
                        right: "0",
                      }}
                      className="user-dropdown"
                    >
                      {!(
                        isInitialPop ||
                        isFirstPopupOpen ||
                        isSecondPopupOpen ||
                        adminProfile
                      ) && (
                        <>
                          <li onClick={handleProfile1}>
                            <p>Edit</p>
                          </li>
                        </>
                      )}
                      <li onClick={handleProfile2}>
                        <p>Feedback</p>
                      </li>
                      <li onClick={handleClickLogOut}>
                        <p>Logout</p>
                      </li>
                    </ul>
                  )}
                </li>
              ) : (
                <li>
                  {" "}
                  <button
                    class="login-button-nav logindiv hidesign"
                    onClick={handleignUpClick}
                  >
                    Sign up
                  </button>
                </li>
              )}
            </ul>
          </nav>

          <div className="navbar-icon">
            {!isLoggedIn && !appliedMentor && (
              <div className="out-mentor">
                <a onClick={handleOpenPopup}>
                  <img
                    className="nav-image"
                    src={msell}
                    alt="become a seller"
                  />
                  <span className={`become-seller`}>
                    {" "}
                    Become an Expert <img src={mnav} />
                  </span>
                </a>
              </div>
            )}
            {updateInfo && (
              <div onClick={handleMentorNavigation} className="m-d-l">
                <li>
                  <a>
                    <img
                      className="nav-image"
                      src={msell}
                      alt="become a seller"
                    />
                    <span className={`become-seller`}>
                      {" "}
                      Mentor Dashboard <img src={mnav} />
                    </span>
                  </a>
                </li>
              </div>
            )}
            {!isLoggedIn && (
              <div class="s-u-b" onClick={handleignUpClick}>
                Sign up
              </div>
            )}
            <div onClick={() => toggleMenu("")}>
              {menuOpen ? <FaTimes /> : <FaBars />}
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default NewNavbar;
{
  /* <div
className={`navbar-menu-inside ${
  menuOpenInside ? "active-inside" : ""
}`}
>
<ul>
  <li onClick={toggleMenuInside}>
    <a href="#transition">Another transition</a>
  </li>
  <li onClick={toggleMenuInside}>
    <a href="#service2">Service 2</a>
  </li>
</ul>
</div> */
}
