import React from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "./Sidebar";
import "../fonts/Roboto/Roboto-Black.ttf";
import { useState, useEffect } from "react";
import RightSidebar from "./RightSidebar";
import "../styles/Home.css"
import Marquee from "react-fast-marquee"
import Modal from 'react-bootstrap/Modal';
import fullstack from '../images/java_fullstack.png'
import javabackend from '../images/java-backend.png'
import reactjs from '../images/react-js.png'
import team from "../images/emp_women.png"
import ring from "../images/ring.png"
import whatsapp from "../images/whatsapp.png"
import Popup from "../components/Popup "
import background from "../images/free_trail.jpg";
import tv from "../images/tv.png"
import coding from "../images/coding.png"
import calendar from "../images/calendar.png"
import star from "../images/star.png";
import { trackEvent } from '../facebookPixel';



const Home = () => {



    const [userName, setUserName] = useState();
    const [showCard, setShowCard] = useState(1);

    const navigate = useNavigate();
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const openPopup = () => {
        setIsPopupOpen(true);
    };

    const closePopup = () => {
        setIsPopupOpen(false);
    };
    //API call when page loader
    useEffect(() => {
        trackEvent('ViewContent');

        setUserName(sessionStorage.getItem("userName"));

    }, [])

    function showCardList(num) {
        setShowCard(num);
    }

    function showAllCourses() {
        navigate("/dashboard")
    }

    function requestCall(){
        window.open("https://forms.gle/FZnu8MK6aJHr4SL7A","_blank")
    }

    function bookASlot(){
        window.open("https://forms.gle/UTmFbDm3WjmqVVoE8","_blank")
    }

    return (
        <>


            {/* Main Container */}
            <Sidebar />

            <div className="jobs-dashboard-container mx-4">

                {/* <Navb /> */}

                <div className="px-md-5 mt160">

                    <section class="section">
                        <div class="container">

                            <div class="row">

                                <div class="col-lg-9">
                                    <div class="row">
                                        <div class="">
                                            <div class="section-title  mb-4 pb-2">
                                                <h4 class="title d-flex"><h4 class="text-muted mb-3" style={{ marginRight: 10 }}>Hey! </h4> {userName}</h4>
                                                <p style={{ fontWeight: 500 }} class="mb-3">Welcome to Skillsquad, Unlock your impact. Elevate your future</p>


                                                <div class="row">

                                                    <div class="col-md-4 mb-3 ">
                                                        <div class="card">
                                                            <div class="card-body " style={{ paddingTop: 5, paddingBottom: 5, color: "#5749CC" }}>

                                                                <h5 class="card-title" >Premium <br />Classes </h5>


                                                            </div>
                                                            <div style={{ textAlign: "right", padding: "3%" }}><img src={tv} alt="tv" width={50} height={50} /></div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 mb-3">
                                                        <div class="card d-flex" >
                                                            <div class="card-body" style={{ paddingTop: 5, paddingBottom: 5, color: "#BD9D26" }}>
                                                                <h5 class="card-title">Coding <br />Problems </h5>


                                                            </div>
                                                            <div style={{ textAlign: "right", padding: "3%" }}><img src={coding} alt="tv" width={50} height={50} /></div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 mb-3">
                                                        <div class="card" >
                                                            <div class="card-body" style={{ paddingTop: 5, paddingBottom: 5, color: "#9C37BF" }}>
                                                                <h5 class="card-title">Live <br />Masterclass </h5>

                                                            </div>
                                                            <div style={{ textAlign: "right", padding: "3%" }}><img src={calendar} alt="tv" width={50} height={50} /></div>
                                                        </div>
                                                    </div>


                                                </div>

                                            </div>
                                        </div>
                                    </div>


                                </div>

                                {/*Filter section*/}
                            </div>

                        </div>

                    </section>



                </div>
                <div className="home-dashboard-container">
                    <div class="homeTestCard">
                        <div style={{ marginTop: "2%", fontSize: 24, fontWeight: 600 }}>
                            Are you confused about picking the right course?

                        </div>
                        <div style={{ fontSize: 18 }} className="mb-3">
                            Book a slot our team will help you to achieve your career goals

                        </div>
                        <div style={{ backgroundColor: "white", borderRadius: 10 }} className="mb-3">

                            <div class="row mx-md-5 align-items-center">
                                <div class="col px-md-1"><div class="p-3" style={{ color: "black", fontSize: 20, fontWeight: 600 }}>Book a free career counselling slot.
                                    {/* <div style={{ color: "red", fontSize: 16, fontWeight: 500 }}>You've exhausted all the 3 attempts</div> */}
                                </div></div>
                                <div class="col "><div class="p-1"><button type="button" class="btn btn-primary button-trans" style={{ backgroundColor: "#5627ff" }} onClick={bookASlot}>Book a Slot</button></div></div>
                            </div>

                        </div>
                    </div>

                    <div className="mb-3">
                        <Marquee><div style={{ marginRight: 20, marginLeft: 20 }}>Unlock Live Classes</div>
                            <div style={{ marginRight: 20, marginLeft: 20 }}>Instructors & Mentors</div>
                            <div style={{ marginRight: 20, marginLeft: 20 }}>Teaching Assistants</div>
                            <div style={{ marginRight: 20, marginLeft: 20 }}>Skillsquad Community</div>
                            <div style={{ marginRight: 20, marginLeft: 20 }}>Placements & Assistance</div>
                            <div style={{ marginRight: 20, marginLeft: 20 }}>Learn in preferred language</div>
                            <div style={{ marginRight: 20, marginLeft: 20 }}>And a lot more</div>
                        </Marquee>
                    </div>
                </div>
                <div class="course-card mt-3">
                    <div class="course-card-heading">
                        <h5>Explore how Skillsquad can help you create an outstanding profile!</h5>
                        <p class="text-muted">Build a remarkable portfolio showcasing in-demand skills acquired through Skillsquad's in-depth courses.</p>
                    </div>

                    <div className="d-flex flex-column bg-white liveclasses-boxshadow liveclasses-top-section">


                        <div class="liveclasses-top-section-heading mb-3">Available Courses</div>
                        <div class='liveclass'>
                            <div class="left"><div><ul class="nav nav-tabs">
                                <li class="nav-item">
                                    <a class="nav-link active liveclass-tab" href="#t1" data-bs-toggle="tab" onClick={() => { showCardList(1) }}>Java Backend Course</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link liveclass-tab" href="#t2" data-bs-toggle="tab" onClick={() => { showCardList(2) }}>Java Fullstack Course</a>
                                </li>
                                {/* <li class="nav-item">
                                    <a class="nav-link liveclass-tab" href="#t2" data-bs-toggle="tab" onClick={() => { showCardList(3) }}>AWS Cloud Program</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link liveclass-tab" href="#t2" data-bs-toggle="tab" onClick={() => { showCardList(2) }}>Data science Course</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link liveclass-tab" href="#t2" data-bs-toggle="tab" onClick={() => { showCardList(3) }}>Tableau Course</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link liveclass-tab" href="#t2" data-bs-toggle="tab" onClick={() => { showCardList(2) }}>Cybersecurity Training</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link liveclass-tab" href="#t2" data-bs-toggle="tab" onClick={() => { showCardList(3) }}>Blockchain Course</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link liveclass-tab" href="#t2" data-bs-toggle="tab" onClick={() => { showCardList(3) }}>IoT Course</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link liveclass-tab" href="#t2" data-bs-toggle="tab" onClick={() => { showCardList(3) }}>PMP Course</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link liveclass-tab" href="#t2" data-bs-toggle="tab" onClick={() => { showCardList(3) }}>UI/UX Course</a>
                                </li> */}

                            </ul></div></div>

                        </div>



                    </div>
                    <div style={{ padding: "2%" }}>


                        <div >
                            <div>
                                {showCard === 1 && <div class="course-details">
                                    <div class="d-flex">
                                        <div >
                                            <div>
                                                <div class="d-flex"><div class="course-fellow-heading">Fellowship Program in Software Development</div><div class='course-fellow-tag'>Backend Specialization</div></div>
                                                <ul>
                                                    <li>Get hands-on experience with in-demand tech like <strong>Java, and more through real-world projects.</strong></li>
                                                    <li>We'll tailor a learning plan just for you, helping you ace interviews at top tech companies.</li>
                                                    <li>Master full-stack development and get guaranteed job placement.</li>
                                                </ul>
                                            </div>

                                            <div class="d-flex"><div class="course-button mx-5" onClick={openPopup}>View your future resume</div><div class="course-button" onClick={() => { window.open("https://www.skillsquad.io", "Popup", "toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, resizable=0, width=580, height=600, top=30") }}>View More</div></div>
                                            {isPopupOpen && <Popup onClose={closePopup} />}
                                        </div>
                                        <div><img src={fullstack} alt="fullstack" width={250} height={200} /></div>
                                    </div>
                                </div>}
                                {showCard === 2 && <div class="course-details">
                                    <div class="d-flex">
                                        <div >
                                            <div>
                                                <div class="d-flex"><div class="course-fellow-heading">Fellowship Program in Software Development</div><div class='course-fellow-tag'>Fullstack Specialization</div></div>
                                                <ul>
                                                    <li>Build real work-like projects to gain work experience in <strong>Java Backend, React, Javascript and more</strong></li>
                                                    <li>Get a curated, <strong>personalized curriculum</strong> to crack jobs at the fastest growing companies</li>
                                                    <li>Land a top career in Fullstack Development with <strong>guaranted placement</strong></li>
                                                </ul>
                                            </div>

                                            <div class="d-flex"><div class="course-button mx-5" onClick={openPopup}>View your future resume</div><div class="course-button" onClick={() => { window.open("https://www.skillsquad.io", "Popup", "toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, resizable=0, width=580, height=600, top=30") }}>View More</div></div>
                                            {isPopupOpen && <Popup onClose={closePopup} />}
                                        </div>
                                        <div><img src={javabackend} alt="fullstack" width={250} height={200} /></div>
                                    </div>
                                </div>}
                                {showCard === 3 && <div class="course-details">
                                    <div class="d-flex">
                                        <div >
                                            <div>
                                                <div class="d-flex"><div class="course-fellow-heading">Fellowship Program in Software Development</div><div class='course-fellow-tag'>Fullstack Specialization</div></div>
                                                <ul>
                                                    <li>Build real work-like projects to gain work experience in <strong>MongoDB, React, Javascript and more</strong></li>
                                                    <li>Get a curated, <strong>personalized curriculum</strong> to crack jobs at the fastest growing companies</li>
                                                    <li>Land a top career in Fullstack Development with <strong>guaranted placement</strong></li>
                                                </ul>
                                            </div>

                                            <div class="d-flex"><div class="course-button mx-5" onClick={openPopup}>View your future resume</div><div class="course-button" onClick={() => { window.open("https://www.skillsquad.io", "Popup", "toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, resizable=0, width=580, height=600, top=30") }}>View More</div></div>
                                            {isPopupOpen && <Popup onClose={closePopup} />}
                                        </div>
                                        <div><img src={reactjs} alt="fullstack" width={250} height={200} /></div>
                                    </div>
                                </div>}
                            </div>

                        </div>
                    </div>
                </div>
                <div class="mt-5">
                    <div class="stepper-heading">First 6 steps to your dream career!</div>
                    <ul class="list-group vertical-steps mt-3">
                        <li class="list-group-item active completed">
                            <span><div class="steps liveclasses-boxshadow">Register for a Free Session with SKILLSQUAD Founders</div><div class="mt-3"></div></span>

                        </li>

                        <li class="list-group-item active completed">
                            <span><div class="steps liveclasses-boxshadow">Join the Whatsapp Group for the Free Session</div>
                                <div class="whatsapp mt-3">
                                    <div class="d-flex align-items-center">
                                        <div><img src={whatsapp} alt="whatsapp" width={80} height={70} /></div>
                                        <div class="whatsapp-heading">Join Our WhatsApp groups for <strong class="home-strong">Exclusive Community Resources</strong> and <strong class="home-strong">Support!</strong>

                                        </div>

                                    </div>
                                    <div class="d-flex">
                                        <div className="d-flex align-items-center" style={{ fontWeight: 600 }}> <div style={{ margin: '0 1em' }}><img src={star} alt="star" width={17} /></div><span style={{ fontSize: 16 }}>Lecture Note</span></div>
                                        <div className="d-flex align-items-center" style={{ fontWeight: 600 }}> <div style={{ margin: '0 1em' }}><img src={star} alt="star" width={17} /></div><span style={{ fontSize: 16 }}>Updates</span></div>
                                        <div className="d-flex align-items-center" style={{ fontWeight: 600 }}> <div style={{ margin: '0 1em' }}><img src={star} alt="star" width={17} /></div><span style={{ fontSize: 16 }}>Learner Community</span></div>
                                        <div className="d-flex align-items-center" style={{ fontWeight: 600 }}> <div style={{ margin: '0 1em' }}><img src={star} alt="star" width={17} /></div><span style={{ fontSize: 16 }}>Joining Links</span></div>

                                    </div>
                                    <div class="d-flex">
                                        <div className="d-flex align-items-center" style={{ fontWeight: 600 }}> <div style={{ margin: '0 1em' }}><img src={star} alt="star" width={17} /></div><span style={{ fontSize: 16 }}>Resource Sharing</span></div>
                                        <div className="d-flex align-items-center" style={{ fontWeight: 600 }}> <div style={{ margin: '0 1em' }}><img src={star} alt="star" width={17} /></div><span style={{ fontSize: 16 }}>Network</span></div>
                                    </div>

                                    <div class="d-flex"></div>
                                    <div className='join-box-button mt-3 button-trans' style={{ width: "30%", textAlign: "center" }}>Join Whatsapp Group</div>
                                </div>
                            </span>
                        </li>
                        <li class="list-group-item active completed">
                            <span><div class="steps liveclasses-boxshadow">Attend a free career counselling session</div>
                                <div class="mt-3"></div>
                            </span>
                        </li>
                        <li class="list-group-item active completed">
                            <span><div class="steps liveclasses-boxshadow">Access 7-days free trial and paid</div>

                                <div style={{ backgroundImage: `url(${background})` }} class="freetrail mt-3"><div class="freetrail-heading mb-2">Start your free 7-day trial</div>
                                    <div class="freetrail-subheading mb-2">Get access to all features so you can decide which plan is right for you. No credit card required.</div>
                                    <center> <div class="freetrail-button button-trans" onClick={showAllCourses}>Start Your Free Trial</div></center>
                                </div>
                            </span>
                        </li>
                        <li class="list-group-item active completed">
                            <span><div class="steps liveclasses-boxshadow">Prepare for Interview</div><div class="mt-3"></div></span>
                        </li>
                        <li class="list-group-item active completed">
                            <span><div class="steps liveclasses-boxshadow">Crack Your dream Job</div><div class="mt-3"></div></span>
                        </li>
                    </ul>
                </div>
                <div class="home-footer-card mt-5 mb-5 align-items-center">
                    {/* <div><img src={team} alt="team" width={200} height={200} /></div> */}
                    <center><div style={{ marginLeft: 50, textAlign: "center" }}><h6>Want more information about Scaler Courses?</h6>
                        <h4>Have a chat with the team</h4>
                        <div class="request-call d-flex mt-2" style={{cursor:"pointer"}} onClick={requestCall}><img src={ring} alt="ring" width={20} height={20} /><p style={{ marginLeft: 10 }}>Request a Call Back</p></div>
                    </div></center></div>



            </div>


            <RightSidebar />
        </>
    );
};

export default Home;
