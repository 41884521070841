import "./AboutUs.css";
import { FaArrowRightLong } from "react-icons/fa6";
import Amazon from "../amazon.svg";
import Apple from "../Apple.svg";
import Google from "../Google.svg";
import Microsoft from "../Microsoft.svg";
import Samsung from "../Samsung.svg";
import HeroImg from "../Partner page Hero section.png";
import LargeImg from "../Rectangle 22489.png";
import MoneyIcon from "../grommet-icons_money.svg";
// import WorldIcon from "../clarity_world-line.svg";
import CommuIcon from "../iconoir_community.svg";
import MentorICon from "../Mask group.svg";
import CompanyICon from "../Mask group (1).svg";
import VerifiedSymb from "../material-symbols_verified.svg";
import ExperIcon from "../hugeicons_new-job.svg";
import ProfileIcon from "../hugeicons_profile-02.svg";
import ClientsIcon from "../ph_video-conference-thin.svg";
import PaidIcon from "../hugeicons_money-bag-02.svg";
import MoneyAnimayion1 from "../Money Animation 1.gif";
import MoneyAnimayion2 from "../Money Animation.gif";
import React, { useState } from "react";
import { FaUserCircle, FaHandshake, FaMoneyBillWave } from "react-icons/fa";
import { NewFooter } from "../../../components/Footer/NewFooter";

export const AboutUs = () => {
  
  const sampleMentors = [
    {
      id: 1,
      name: "Vishal Pathak",
      role: "UX/UI Designer",
      rating: 4.5,
      reviews: 770,
      company: "Microsoft",
      experience: 8,
      services: [
        "UI/UX",
        "Graphic Design",
        "Figma",
        "Brand Design",
        "Illustrator",
      ],
      profilePicture: MentorICon,
    },
    {
      id: 2,
      name: "Vishal Pathak2",
      role: "UX/UI Designer",
      rating: 4.5,
      reviews: 770,
      company: "Microsoft",
      experience: 8,
      services: [
        "UI/UX",
        "Graphic Design",
        "Figma",
        "Brand Design",
        "Illustrator",
      ],
      profilePicture: MentorICon,
    },
    {
      id: 3,
      name: "Vishal Pathak3",
      role: "UX/UI Designer",
      rating: 4.5,
      reviews: 770,
      company: "Microsoft",
      experience: 8,
      services: [
        "UI/UX",
        "Graphic Design",
        "Figma",
        "Brand Design",
        "Illustrator",
      ],
      profilePicture: MentorICon,
    },
    {
      id: 4,
      name: "Vishal Pathak4",
      role: "Design",
      rating: 4.5,
      reviews: 770,
      company: "Microsoft",
      experience: 8,
      services: [
        "UI/UX",
        "Graphic Design",
        "Figma",
        "Brand Design",
        "Illustrator",
        "Design",
      ],
      profilePicture: MentorICon,
    },
    {
      id: 5,
      name: "Vishal Pathak4",
      role: "Design",
      rating: 4.5,
      reviews: 770,
      company: "Microsoft",
      experience: 8,
      services: [
        "UI/UX",
        "Graphic Design",
        "Figma",
        "Brand Design",
        "Illustrator",
        "Design",
        "Data Science",
      ],
      profilePicture: MentorICon,
    },

    
  ];
  const MentorList = () => {
    const [selectedCategory, setSelectedCategory] = useState("Data Science");
    const [visibleMentors, setVisibleMentors] = useState(4);

    const categories = [
    //   "All",
      "Data Science",
      "DevOps",
      "Design",
      "AWS",
      "Research",
      "Coding",
      "IoT",
      "UI/UX",
      "Full Stack",
      "AI/ML",
    ];

    const handleCategoryChange = (category) => {
      setSelectedCategory(category);
      setVisibleMentors(4); // Reset the number of visible mentors when category changes
    };

    const loadMoreMentors = () => {
      setVisibleMentors((prevVisible) => prevVisible + 4);
    };

    const filteredMentors = sampleMentors.filter((mentor) =>
      selectedCategory === "All"
        ? true
        : mentor.services.includes(selectedCategory)
    );

    return (
      <div className="mentor-list">
        {/* Navbar */}
        <div className="category-navbar">
          {categories.map((category, index) => (
            <button
              key={index}
              className={`category-button ${
                selectedCategory === category ? "active" : ""
              }`}
              onClick={() => handleCategoryChange(category)}
            >
              {category}
            </button>
          ))}
        </div>

        {/* Mentor Cards */}
        <div className="mentor-cards">
          {filteredMentors.slice(0, visibleMentors).map((mentor) => (
            <div key={mentor.id} className="mentor-card mt-4">
              <div className="d-flex gap-3 ">
                <div>
                  <img
                    src={mentor.profilePicture}
                    alt={mentor.name}
                    className="mentor-image"
                  />
                </div>
                <div>
                  <div className="mentor-name d-flex justify-content-center align-items-center gap-1">
                    <span>{mentor.name}</span> <img src={VerifiedSymb} />
                  </div>
                  <div className="mentor-role">{mentor.role}</div>
                  <div className="mentor-rating">
                    ?<span className="mentor-rating2"> {mentor.rating} </span>
                    <span className="mentor-rating3">({mentor.reviews})</span>
                  </div>
                </div>
              </div>
              <div className="mentor-company justify-content-center align-items-center mt-3">
                <img src={CompanyICon} className="img-fluid" />
                <span style={{ marginLeft: "0.5rem" }}>{mentor.company}</span>
              </div>
              <div className="mentor-experience justify-content-center align-items-center mt-2">
                <img src={ExperIcon} className="img-fluid" />{" "}
                <span style={{ marginLeft: "0.5rem" }}>Experience: </span>
                <span className="mentor-experience2">
                  {mentor.experience} years
                </span>
              </div>
              <div className="mentor-services mt-2">
                <div>Services</div>
                <div>
                  {mentor.services.map((service, index) => (
                    <span key={index} className="badge">
                      {service}
                    </span>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* View More Button */}
        {visibleMentors < filteredMentors.length && (
          <button onClick={loadMoreMentors} className="load-more-button">
            View More
          </button>
        )}
      </div>
    );
  };


function Faq() {
    const [activeIndex, setActiveIndex] = useState(null);
  
    // Updated faqItems array with questions and corresponding answers
    const faqItems = [
      {
        question: "1. What is SkillSquad?",
        answer: "SkillSquad is a platform designed to make top-tier industry expertise accessible to everyone. It allows users to connect with experts in real-time for personalized advice, career coaching, and skill development, all while paying only for the time they use.",
      },
      {
        question: "2. What Can I Sell?",
        answer: "Be creative! You can offer any service as long as it's legal and complies with our terms. With over 20 categories available, you can explore various ideas and find inspiration for the services you can provide.",
      },
      {
        question: "3. How much money can I make?",
        answer: "Your earning potential is entirely up to you. You can work as much or as little as you want. Many sellers use SkillSquad as a side hustle while continuing their regular jobs and use the platform to earn extra income.",
      },
      {
        question: "4. How much does it cost? Is SkillSquad really free?",
        answer: "Yes, joining SkillSquad is free. There are no subscription fees or charges to list your sessions. You keep 90% of each transaction, making it a cost-effective platform to offer your services.",
      },
      {
        question: "5. How much time will I need to invest?",
        answer: "The time investment is highly flexible. Initially, you'll need to spend some time learning how the marketplace operates. After that, you can decide how much time and effort you want to invest, based on your goals and availability.",
      },
      {
        question: "6. How do I price my sessions?",
        answer: "Your pricing should reflect the value of the service you provide and what your audience is willing to pay. On SkillSquad, creators typically charge between $50 and $500 per session, but you have full control over your pricing strategy.",
      },
      {
        question: "7. How do I get paid?",
        answer: "After you complete a session with a buyer, the payment is automatically transferred to your account. There's no need to chase payments or wait 60 or 90 days to receive your money.",
      },
      {
        question: "8. What are the transaction charges?",
        answer: "Payment gateways such as Stripe and PayPal typically charge a transaction fee of about 3%. This fee is standard and beyond our control, as it is a cost associated with using these payment services.",
      },
    ];
  
    const handleToggle = (index) => {
      setActiveIndex(activeIndex === index ? null : index);
    };
  
    return (
      <div className="faq-container">
        <div className="faq-left">
          <div className="faq-left-heading1">Frequently asked questions</div>
          <div className="faq-left-heading2 mt-5">Can't find the answer you are looking for?</div>
        </div>
  
        <div className="faq-right">
          {faqItems.map((item, index) => (
            <div
              key={index}
              className={`faq-item ${activeIndex === index ? "active" : ""}`}
            >
              <div className="about-faq-question" onClick={() => handleToggle(index)}>
                <span>{item.question}</span>
                <span>{activeIndex === index ? "-" : "+"}</span>
              </div>
              {activeIndex === index && (
                <div className="about-faq-answer">
                  <div>{item.answer}</div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    );
  }
  return (
    <>
      <div className="container-fluid container-custom">
        <div className="row align-items-center mb-3">
          {/* Left Column */}
          <div className="col-md-6 text-center text-md-left mb-4 mb-md-0">
            <div className=" share-heading display-4 font-weight-bold">
              Share Your <br /> Expertise
              <br />
              Without Boundaries.
            </div>
            <div className="lead Earn-heading mt-4">
              Earn On Your Own Schedule.
            </div>
            <button className="StartEarning-button btn btn-light btn-lg mt-4">
              Start Earning <FaArrowRightLong />
            </button>
            <div className="Needhelp-heading mt-4  ">
              Need help? Contact{" "}
              <span className="contactnum">+91 97-38-38-38-3</span>
            </div>
          </div>

         
          <div className="col-md-6 text-center position-relative">
            <div className="image-container">
              <img
                src={HeroImg}
                alt="Thomas Magnum"
                // className="profile-image"
                className="img-fluid"
                style={{ maxWidth: "100%", width: "70%" }}
              />
              <div className="overlay-text">
                <div className="profile-details">
                  <div className="hero-name">Thomas Magnum</div>
                  <div className="hero-subname">Software Engineer</div>
                </div>
                
              </div>
              <div className="overlay-text2">
                <div className="earning-info">
                  Earned <span className="text-primary">5 Lakhs</span> Through
                  Skillsquad!
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Logos Row */}
        <div className="row mt-5 justify-content-between">
          <div className="col  text-center">
            <img src={Amazon} alt="Amazon" className="img-fluid" />
          </div>
          <div className="col  text-center">
            <img src={Google} alt="Google" className="img-fluid" />
          </div>
          <div className="col  text-center">
            <img src={Apple} alt="Apple" className="img-fluid" />
          </div>
          <div className="col  text-center">
            <img src={Microsoft} alt="Microsoft" className="img-fluid" />
          </div>
          <div className="col text-center">
            <img src={Samsung} alt="Microsoft" className="img-fluid" />
          </div>

          <div className="col  text-center">
            <img src={Amazon} alt="Amazon" className="img-fluid" />
          </div>
          <div className="col  text-center">
            <img src={Google} alt="Google" className="img-fluid" />
          </div>
          <div className="col  text-center">
            <img src={Apple} alt="Apple" className="img-fluid" />
          </div>
          <div className="col  text-center">
            <img src={Microsoft} alt="Microsoft" className="img-fluid" />
          </div>
          <div className="col  text-center">
            <img src={Samsung} alt="Microsoft" className="img-fluid" />
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row animation-section">
          {/* Animated GIF Section */}

          <div className="col-3 text-center">
            <img src={MoneyAnimayion1} alt="Animated GIF" className="img-fluid animation-dim" />
          </div>
          <div className="col text-center animation-headline">
            Partner with Skillsquad<br/> at 0% commission for the 1st month!
          </div>
          <div className="col-3 text-center">
            <img src={MoneyAnimayion2} alt="Animated GIF" className="img-fluid animation-dim" />
          </div>
        </div>
      </div>

      <div className="container-fluid partner-section-container">
        {/* Section Title */}
        <div className="row" style={{ margin: "auto" }}>
          <div className="col-12 text-center">
            <div className="section-title mt-5 mb-3">Why Partner with us</div>
          </div>
        </div>

        {/* Image Section */}
        <div className="row justify-content-center my-4">
          <div className="col-12">
            <img
              src={LargeImg}
              className="img-fluid"
              style={{ maxWidth: "100%", width: "100%", padding: "0rem 5rem" }}
              alt="Partner with Us"
              //   className="img-fluid rounded shadow-sm"
            />
          </div>
        </div>

        {/* Feature Cards */}
        {/* <div className="row d-flex justify-content-center text-center ">
          <div className="col-md-4 mb-3">
            <div className="card feature-card h-100 ">
              <div className="card-body">
                <div className="feature-icon mb-3">
                  <img src={MoneyIcon} />
                </div>
                <h5 className="card-title">Turn knowledge into money.</h5>
                <p className="card-text">
                  Share your skills and insights with those who need them and
                  get paid for the time you spend making a difference.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-3">
            <div className="card feature-card h-100">
              <div className="card-body">
                <div className="feature-icon mb-3">
                  <img src={WorldIcon} />
                </div>
                <h5 className="card-title">Reach a global audience.</h5>
                <p className="card-text">
                  Connect with professionals from around the world who are eager
                  to learn from your experience and expertise.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-3">
            <div className="card feature-card h-100">
              <div className="card-body">
                <div className="feature-icon mb-3">
                  <img src={CommuIcon} />
                </div>
                <h5 className="card-title">Vibrant Community</h5>
                <p className="card-text">
                  Create a space where knowledge is shared, ideas flourish,
                  collaborate on projects and everyone grows together.
                </p>
              </div>
            </div>
          </div>
        </div> */}

        <div className="row d-flex justify-content-center text-center mx-5 mt-5">
          <div className="col-md-4 mb-3 d-flex justify-content-center">
            {" "}
            {/* Added justify-content-center to the col */}
            <div className="card feature-card h-100">
              <div className="card-body">
                <div className="feature-icon mb-3">
                  <img src={MoneyIcon} alt="Money Icon" />
                </div>
                <h5 className="card-title">Turn knowledge into money.</h5>
                <p className="card-text">
                  Share your skills and insights with those who need them and
                  get paid for the time you spend making a difference.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-3 d-flex justify-content-center">
            {" "}
            {/* Added justify-content-center to the col */}
            <div className="card feature-card h-100">
              <div className="card-body">
                <div className="feature-icon mb-3">
                  {/* <img src={WorldIcon} alt="World Icon" /> */}
                </div>
                <h5 className="card-title">Reach a global audience.</h5>
                <p className="card-text">
                  Connect with professionals from around the world who are eager
                  to learn from your experience and expertise.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-3 d-flex justify-content-center">
            {" "}
            {/* Added justify-content-center to the col */}
            <div className="card feature-card h-100">
              <div className="card-body">
                <div className="feature-icon mb-3">
                  <img src={CommuIcon} alt="Community Icon" />
                </div>
                <h5 className="card-title">Vibrant Community</h5>
                <p className="card-text">
                  Create a space where knowledge is shared, ideas flourish,
                  collaborate on projects and everyone grows together.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Mentor Category Section */}
        {/* <MentorList /> */}
        <div className="row">
          <div className="how-it-works">
            <h2 className="section-title mb-5">How it works</h2>
            <div className="steps-container">
              <div className="step-card">
                <div className="icon-container">
                  <img src={ProfileIcon} alt="icon" className="img-fluid" />
                </div>
                <h3 className="step-title">Step 1: Create Your Profile</h3>
                <p className="step-description">
                  Share your skills and insights with those who need them and
                  get paid for the time you spend making a difference.
                </p>
              </div>

              <div className="step-card">
                <div className="icon-container">
                  <img src={ClientsIcon} alt="icon" className="img-fluid" />
                </div>
                <h3 className="step-title">Step 2: Engage with Clients</h3>
                <p className="step-description">
                  Connect with professionals from around the world who are eager
                  to learn from your experience and expertise.
                </p>
              </div>

              <div className="step-card">
                <div className="icon-container">
                  <img src={PaidIcon} alt="icon" className="img-fluid" />
                </div>
                <h3 className="step-title">Step 3: Get Paid Instantly</h3>
                <p className="step-description">
                  Create a space where knowledge is shared, ideas flourish,
                  collaborate on projects, and everyone grows together.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <p className="catagories-heading pt-5">
            Popular Categories to Sell Online
          </p>

          {/* Category Boxes */}
          <div className="categories">
            {[
              "IT Mentorship",
              "AI",
              "API Development",
              "Business Consulting",
              "Career & Advice",
              "Cloud",
              "Data Science",
              "DevOps",
              "Design",
              "Finance",
              "Programming",
              "Networking",
              "No/Low Code",
              "Product Management",
              "Product Research",
              "Sales/BD",
              "Talent Acquisition",
              "Mental Health",
              "Language Learning",
              "Business Consulting",
            ].map((category, index) => (
              <a href="#" key={index} className="category-box">
                {category} <span>&#x2192;</span>
              </a>
            ))}
          </div>

          {/* Section Below the Boxes */}
          <div className="earn-section mt-5">
            <div className="sub-earn-section">
              <div className="earn-section-heading1">
                We earn only when
                <br /> you earn
              </div>
              <p className="earn-section-heading2 mt-3">
                We charge a small commission on your earnings.
                <br />
                No CC required. No upfront fees. No recurring charges.
              </p>
              <a className="start-link mt-3">10% commission</a>
              <button href="#" className=" btn mt-3">
                Get started for free &#x2192;
              </button>
            </div>
          </div>

          {/* Support Section */}

          <div className="support-sec">
            <div className="support-left">
              <p className="support-heading1">
                Skillsquad Supplier
                <br /> Support Available 24/7
              </p>
            </div>
            <div className="support-right">
              <p  className="support-heading2">
                Skillsquad Supplier Support Is Available To Solve All Your
                Doubts And Issues Before And After You Start Your Online Selling
                Business.
              </p>
              <button className="btn support-btn">Talk to Support</button>
            </div>
          </div>
        </div>
       
      </div>
      <Faq />
      <NewFooter />
    </>
  );
};
