import React, { useEffect, useState } from "react";
import "../styles/RightSidebar.css";
import { useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faLock, faLockOpen,faCircleArrowLeft,faCircleArrowRight,faCircleCheck,faSquareCaretLeft,faSquareCaretRight } from '@fortawesome/free-solid-svg-icons'
import ProgressBar from 'react-bootstrap/ProgressBar';
import httpsignup from '../js/http-lms_userprofile';
import star from "../images/star.png";
import blackL from "../images/black_lock_icon.png";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';








const RightSidebar = (props) => {
 console.log("Props Progress : " + JSON.stringify(props.progressData))
  const navigate = useNavigate();
  const location = useLocation();
  const [newUserpercentage, setNewUserpercentage] = useState(0);
  const [userExperiencepercentage, setUserExperiencepercentage] = useState(0);
  const [userCohortpercentage, setUserCohortpercentage] = useState(0);
  const [userLinkspercentage, setUserLinkspercentage] = useState(0);
  const [newUser, setNewUser] = useState(true);
  const [progressData, setProgressData] = useState(true);
  const [loading, setLoading] = useState(true)
  const [profileId, setProfileId] = useState(true)
  const [totalTaskDays, setTotalTaskDays] = useState(0);
  const [dayStatus, setDayStatus] = useState({})


  function handleClick() {
    navigate("/edit-profile");
  }

  function handleClick2() {
    navigate("/user-profile");
  }

  function handleClick3() {
    navigate("/add-education");
  }

  function handleClick4() {
    navigate("/add-experience");
  }

  function handleClick5() {
    navigate("/add-cohart");
  }
  function handleClick7() {
    navigate("/add-links");
  }

  function handleClick6() {
    sessionStorage.clear()
    navigate("/");
  }
  function navigateToUserProfile() {
    navigate("/user-profile")
  }

  function experience() {
    navigate("/user-profile")
  }

  function cohart() {
    navigate("/user-profile")
  }

  function profile() {
    navigate("/add-user")
  }
  useEffect(() => {
    getLoggedInUserDetails()
    getLoggedInUserLinks();
    getLoggedInUserCohort();
    getLoggedInUserExperience();
    // programProgress();
  }, [])

  

  // function dayChallengeStatus(id) {
  //   httpsignup.get(`/programsLMS/getDayChallengeStatus?profileId=${id}&programId=${sessionStorage.getItem("myCourseProgramId")}`)
  //     .then((response) => {
  //       console.log("UserDetails Day Challeneg Right Side : " + JSON.stringify(response.data))
  //       setDayStatus(response.data)
  //     }).catch((err) => {
  //       console.log(err)
  //     })
  // }

  const getLoggedInUserDetails = async () => {
    httpsignup.get(`/skillsquadUserprofileLMS/getSkillsquadUserProfileBySignUpId?signUpId=${sessionStorage.getItem('userId')}`)
      .then((response) => {

        if (response.data.userTypeMasterId == 1) {
          setNewUserpercentage(20);
          setNewUser(true)
        } else if (response.data.userTypeMasterId == 2) {
          setNewUser(false)
          setNewUserpercentage(40);
        } else if (response.data.userTypeMasterId == 3) {
          setNewUser(false)
          setNewUserpercentage(40);
        }
        props.progressDataFunc(response.data.lmsProfileId,0)
        //dayChallengeStatus(response.data.lmsProfileId)
        setProfileId(response.data.lmsProfileId)
      }).catch((err) => {
        console.log(err)
      })
  }

  const getLoggedInUserLinks = async () => {

    httpsignup.get(`/skillsquadUserprofileLMS/getSkillsquadUserLinksBySignUpId?signUpId=${sessionStorage.getItem('userId')}`)
      .then((response) => {

        if ((response.data.githubUrl != null && response.data.githubUrl != "") || (response.data.twitterUrl != null && response.data.twitterUrl.trim() != "") || (response.data.linkedinUrl != null && response.data.linkedinUrl.trim() != "") || (response.data.websiteUrl != null && response.data.websiteUrl.trim() != "")) {
          setUserLinkspercentage(20);
        }

      }).catch((err) => {
        console.log(err)
      })
  }
  const getLoggedInUserCohort = async () => {
    httpsignup.get(`/skillsquadUserprofileLMS/getSkillsquadUserCohortBySignUpId?signUpId=${sessionStorage.getItem('userId')}`)
      .then((response) => {
        if (response.data.cohortName != null) {
          setUserCohortpercentage(20);

        }


      }).catch((err) => {
        console.log(err)
      })
  }

  const getLoggedInUserExperience = async () => {
    httpsignup.get(`/skillsquadUserprofileLMS/getSkillsquadUserExperienceBySignUpId?signUpId=${sessionStorage.getItem('userId')}`)
      .then((response) => {
        if (response.data.jobTitle != null) {
          setUserExperiencepercentage(20);
        }
      }).catch((err) => {
        console.log(err)
      })
  }
  function talkToCounseller() {

    window.open("https://forms.gle/FZnu8MK6aJHr4SL7A", "_blank")
  }
  function dayChallengeComplate(id) {
    sessionStorage.setItem("dayId", id)
    props.callDayComplete();
    navigate("/day-challenge-complete")
  }
  const [counter, setCounter] = useState(0);
  // const incrementCounter = () => setCounter(counter + 1);
  // let decrementCounter = () => setCounter(counter - 1);
  // if(counter<=0) {
  //   decrementCounter = () => setCounter(0);
  // }

  function incrementCounter(){
    
    if( (2 * counter + 1) >= props.totalTaskDays){
      props.progressDataFunc(profileId, counter)
    }else{
      setCounter(counter + 1)
      props.progressDataFunc(profileId, counter+1)
    }
    
  }

  function decrementCounter(){
    setCounter(counter - 1)
    if(counter - 1 <= 0){
      setCounter(0)
    }
    if(counter - 1 <= 0){
      props.progressDataFunc(profileId,0)
    }else{
      props.progressDataFunc(profileId,counter-1)
    }
   
  }

  function daycircullum() {
    navigate("/day-circullum")
  }

  function dayChallenge() {
    navigate("/day-challenge")
  }

  return (
    <>
      <div class="programContainer bgwhite " >

        <div class="programBox" id="three" style={{ backgroundColor: '#FBFAFA' }}>

          {/*location.pathname === '/home' || location.pathname === '/activity' || location.pathname === '/buildProject' || location.pathname === '/jobPortal' || location.pathname === '/jobDescription' ? <div> <button type="button" class="btn btn-primary" onClick={handleClick2}>Complete Profile</button> <hr /></div> : <></>*/}

          {location.pathname === '/day-challenge' || location.pathname === '/day-challenge-complete' ? <div><center><div class="overAllPrgress mx-2 row align-items-center"><h6 class="mb-3">Overall Progress</h6>
            <div class="col-4">
            <div style={{ width: 80, height: 80 }}>
            <CircularProgressbar value={props.progressData.overallProgress} text={`${props.progressData.overallProgress}%`} 
            
            styles={buildStyles({
              // Rotation of path and trail, in number of turns (0-1)
              rotation: 0.50,
          
              // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
              strokeLinecap: 'round',
          
              // Text size
              textSize: '20px',
          
              // How long animation takes to go from one percentage to another, in seconds
              pathTransitionDuration: 0.5,
          
              // Can specify path transition in more detail, or remove it entirely
              // pathTransition: 'none',
          
              // Colors
              pathColor: `#546FFF`,
              textColor: 'white',
              trailColor: 'white',
              
            })}
            
            />
            </div>
             
              
            </div>
            <div class='col-8'>
                <div class="common_main_sub_heading">{props.progressData.completedTask}/{props.progressData.totalTasks}</div>
                <div style={{color: "#8E92BC"}}>Lessons Completed</div>
              </div>

          </div>
          </center>
          {/* <div>
          <div class="mt-2 mx-2 common_main_button_edit_nocircle" style={{ textAlign: "left", marginLeft: 10,backgroundColor: "white", borderRadius: 10,padding: 10 }}>
            <div class="row">
              <div class="col-2"><img src={require("../images/Trophy.png")} alt="" /></div>
              <div class="col"><div>Daily Goals</div><div>{dayStatus.completedDays}/{dayStatus.totalDays} Completed</div></div>
              <div class="col-2"></div>
            </div>
            {dayStatus.videoExist == 'Y' && <div>
{dayStatus.videoCompleteStatus == 'Y' ?
            <div class="row" style={{color: "#0FA958"}}>
              <div class="col-8">Watch Video Lectures</div>
              <div class="col">{dayStatus.completedVideo}/{dayStatus.totalVideo}</div>
             
            </div> : <div class="row">
              <div class="col-8">Watch Video Lectures</div>
              <div class="col">5/5</div>
             
            </div>
          }
          </div>
          }
            {dayStatus.interviewExist == 'Y' && <div>
{dayStatus.interviewCompleteStatus == 'Y' ?
            <div class="row" style={{color: "#0FA958"}}>
              <div class="col-8">Complete Intereview Questions</div>
              <div class="col">{dayStatus.interviewCompleted}/{dayStatus.totalIntereview}</div>
             
            </div> : <div class="row">
              <div class="col-8">Complete Intereview Questions</div>
              <div class="col">5/5</div>
             
            </div>
          }
          </div>
          }
           {dayStatus.cheatSheetExist == 'Y' && <div>
{dayStatus.cheatSheetCompleteStatus == 'Y' ?
            <div class="row" style={{color: "#0FA958"}}>
              <div class="col-8">Complete Cheat Sheet</div>
              <div class="col">{dayStatus.cheatSheetCompleted}/{dayStatus.totalCheatSheet}</div>
             
            </div> : <div class="row">
              <div class="col-8">Complete Cheat Sheet</div>
              <div class="col">5/5</div>
             
            </div>
          }
          </div>
          }
            {dayStatus.mcqExist == 'Y' && <div>
{dayStatus.mCQCompleteStatus == 'Y' ?
            <div class="row" style={{color: "#0FA958"}}>
              <div class="col-8">Complete Quiz</div>
              <div class="col">{dayStatus.mcqCompleted}/{dayStatus.totalMcq}</div>
             
            </div> : <div class="row">
              <div class="col-8">Complete Quiz</div>
              <div class="col">5/5</div>
             
            </div>
          }
          </div>
          }
            {dayStatus.assessmentsExist == 'Y' && <div>
{dayStatus.assessmentCompleteStatus == 'Y' ?
            <div class="row" style={{color: "#0FA958"}}>
              <div class="col-8">Complete Coding Practice</div>
              <div class="col">{dayStatus.assessmentComplted}/{dayStatus.totalAssessmentS}</div>
             
            </div> : <div class="row">
              <div class="col-8">Complete Coding Practice</div>
              <div class="col">5/5</div>
             
            </div>
          }
          </div>
          }
            </div>
          </div> */}
            <div class="mt-2 mx-2 common_main_button_edit_nocircle" style={{ textAlign: "left", marginLeft: 10, fontWeight: 700,backgroundColor: "white", borderRadius: 10,padding: 10 }}>
    
              
              <div class="row align-items-center"><div class="col-2" style={{textAlign: "left", fontSize: 32,cursor:"pointer"}} onClick={decrementCounter}><FontAwesomeIcon icon={faSquareCaretLeft} /></div><div class="col common_main_sub_highlight" style={{textAlign: "center"}}>Learning Calendar</div><div class="col-2" style={{textAlign: "right", fontSize: 32,cursor:"pointer"}} onClick={incrementCounter} ><FontAwesomeIcon icon={faSquareCaretRight} /></div></div>
              <hr></hr>
              <div class="row mt-3">
                {/* {loading ? <></> : progressData.totalTaskList.length === 0 ? <></> :
progressData.totalTaskList.map((res) => {
  return(
    <div class="col">
      {res.status === 'completed' ? <div style={{backgroundColor: "#1ae881",borderRadius: "50%", padding: 15, textAlign: "center", fontSize: 25, cursor: "pointer"}}  onClick={dayChallengeComplate}><div>{res.taskNumber}</div></div> :
      <div style={{backgroundColor: "#E9E9E9",borderRadius: "50%", padding: 15, textAlign: "center", fontSize: 25}} class="d-flex"><div>{res.taskNumber}</div><img src={blackL} alt="correct" width={30} height={20} /></div>
      }
      
      
      </div>
  )})} */}
                <ol>
                  {props.sideBarLoading ? <></> : props.progressData.totalTaskList.length === 0 ? <></> :
                    props.progressData.totalTaskList.map((res) => {
                      return (
                       
                        <li class="mt-3">
                          {res.status === 'completed' ? <div style={{ backgroundColor: "rgba(84, 111, 255, 0.2)", borderRadius: 10, padding: 10, textAlign: "center" }} class="align-items-center common_main_heading admin-pro-pic-right" onClick={() => {dayChallengeComplate(res.taskNumber)}}><div >{res.taskNumber}</div><div><FontAwesomeIcon icon={faCircleCheck} /></div></div> :
                            <div style={{ backgroundColor: "#E9E9E9", borderRadius: 10, padding: 10, textAlign: "center" }} class="align-items-center common_main_heading admin-pro-pic-right"><div onClick={dayChallenge}>{res.taskNumber}{props.progressData.currentDay === res.taskNumber ? <div><FontAwesomeIcon icon={faLockOpen} style={{ fontSize: 15, marginLeft: 2 }} /></div> : <div><FontAwesomeIcon icon={faLock} style={{ fontSize: 15, marginLeft: 2 }} /></div>}</div></div>
                          }
                        </li>
                       
                       
                      )
                    })}
                </ol>

              </div>
              <hr></hr>
            <div class="row"> <div class="col"></div><div class="col-10"><div class="common_main_button" onClick={daycircullum}>View Full Curriculum</div></div><div class="col"></div></div>  
            </div>
          </div> : location.pathname === '/edit-profile' || location.pathname === '/add-education' || location.pathname === '/add-experience' || location.pathname === '/add-cohart' || location.pathname === '/add-links' ?

            <ul className=' list-unstyled' >
              <div style={{ textAlign: 'left', marginLeft: 10 }}><button onClick={navigateToUserProfile} class="btn btn-primary"><FontAwesomeIcon icon={faArrowLeft} />Back</button></div>



              <li className={location.pathname === '/edit-profile' ? 'd-flex mt-5 selectedOption ps-3 pe-3 md-5' : 'd-flex mt-5 sidemenuoption ps-3 pe-3 md-5'} id="img1" onClick={handleClick}>


                <h6 className={location.pathname === '/edit-profile' ? 'sidebar-options white my-auto' : 'sidebar-options black my-auto'}>Add Profile</h6>
              </li>

              <li className={location.pathname === '/add-education' ? 'd-flex mt-5 selectedOption ps-3 pe-3 md-5' : 'd-flex mt-5 sidemenuoption ps-3 pe-3 md-5'} id="img2" onClick={handleClick3} >

                <h6 className={location.pathname === '/add-education' ? 'sidebar-options white my-auto' : 'sidebar-options black my-auto'}>Add Education</h6>
              </li>

              <li className={location.pathname === '/add-experience' ? 'd-flex mt-5 selectedOption ps-3 pe-3 md-5' : 'd-flex mt-5 sidemenuoption ps-3 pe-3 md-5'} id="img3" onClick={handleClick4} >

                <h6 className={location.pathname === '/add-experience' ? 'sidebar-options white my-auto' : 'sidebar-options black my-auto'}>Add Experience</h6>
              </li>


              <li className={location.pathname === '/add-cohart' ? 'd-flex mt-5 selectedOption ps-3 pe-3 md-5' : 'd-flex mt-5 sidemenuoption ps-3 pe-3 md-5'} id="img4" onClick={handleClick5} >

                <h6 className={location.pathname === '/add-cohart' ? 'sidebar-options white my-auto' : 'sidebar-options black my-auto'}>Add Cohart</h6>
              </li>


              <li className={location.pathname === '/add-links' ? 'd-flex mt-5 selectedOption ps-3 pe-3 md-5' : 'd-flex mt-5 sidemenuoption ps-3 pe-3 md-5'} id="img4" onClick={handleClick7} >

                <h6 className={location.pathname === '/add-links' ? 'sidebar-options white my-auto' : 'sidebar-options black my-auto'}>Add Links</h6>
              </li>



            </ul> : <center>
              
              {location.pathname === '/my-course' || location.pathname === '/day-topic-video' || location.pathname === '/discussion' ? 
              // <iframe class="mt-3" src="https://survey.zohopublic.in/zs/N9DqcX" style={{height:700,width:'100%',overflow:'hidden'}} 
              // frameborder='0'  marginwidth='0' marginheight='0'  allow='geolocation'></iframe>
              <></>
              
              :
              <><div class="profile_complete mb-5"><div class="mb-3">Your profile is {newUserpercentage + userExperiencepercentage + userCohortpercentage + userLinkspercentage}% complete</div>
              <ProgressBar variant="warning" now={newUserpercentage + userExperiencepercentage + userCohortpercentage + userLinkspercentage} label={newUserpercentage + userExperiencepercentage + userCohortpercentage + userLinkspercentage} />
              <div class="mt-3 mb-3">Complete your profile to apply for jobs and go through our Skillsquad Leap Learning.</div>
              {newUser && <div class="d-flex mb-1"> <div>Add your <u style={{ cursor: "pointer" }} onClick={profile}>Profile</u></div><div class="mx-4">40%</div></div>}
              <div class="d-flex mb-1"> <div>Add a <u style={{ cursor: "pointer" }} onClick={experience}>Experience</u></div><div class="mx-4">20%</div></div>
              <div class="d-flex mb-1"> <div>Add your <u style={{ cursor: "pointer" }} onClick={cohart}>Cohart</u></div><div class="mx-4">20%</div></div>


            </div>
              <div class="join-box"><h6>Join the paid program to experience live learning & a lot more</h6>
                <div class="mt-4 mb-2">
                  <div className="d-flex align-items-center" style={{ fontWeight: 600 }}> <div style={{ margin: '0 1em' }}><img src={star} alt="star" width={17} /></div><span style={{ fontSize: 12.5, color: "#696A6A" }}>Unlock Live Classes</span></div>
                  <div className="d-flex align-items-center" style={{ fontWeight: 600 }}> <div style={{ margin: '0 1em' }}><img src={star} alt="star" width={17} /></div><span style={{ fontSize: 12.5, color: "#696A6A" }}>Instructors & Mentors</span></div>
                  <div className="d-flex align-items-center" style={{ fontWeight: 600 }}> <div style={{ margin: '0 1em' }}><img src={star} alt="star" width={17} /></div><span style={{ fontSize: 12.5, color: "#696A6A" }}>Teaching Assistants</span></div>
                  <div className="d-flex align-items-center" style={{ fontWeight: 600 }}> <div style={{ margin: '0 1em' }}><img src={star} alt="star" width={17} /></div><span style={{ fontSize: 12.5, color: "#696A6A" }}>Skillsquad Community</span></div>
                  <div className="d-flex align-items-center" style={{ fontWeight: 600 }}> <div style={{ margin: '0 1em' }}><img src={star} alt="star" width={17} /></div><span style={{ fontSize: 12.5, color: "#696A6A" }}>Placements & Assistance</span></div>
                  <div className="d-flex align-items-center" style={{ fontWeight: 600 }}> <div style={{ margin: '0 1em' }}><img src={star} alt="star" width={17} /></div><span style={{ fontSize: 12.5, color: "#696A6A" }}>Learn in preferred language</span></div>
                  <div className="d-flex align-items-center" style={{ fontWeight: 600 }}> <div style={{ margin: '0 1em' }}><img src={star} alt="star" width={17} /></div><span style={{ fontSize: 12.5, color: "#696A6A" }}>And a lot more</span></div>
                </div>

                {/* <center> <div className='join-box-button button-trans' onClick={talkToCounseller}>Talk to Counsellor</div></center> */}
              </div></>}</center>}




        </div>
      </div>
    </>
  )
}

export default RightSidebar;
