import React, { useContext } from 'react'
import styled from 'styled-components'
import { IoTrashOutline } from 'react-icons/io5'
import { BiEditAlt } from 'react-icons/bi'
import { FcOpenedFolder } from 'react-icons/fc'
import logo from '../../images/skillsquad_logo_fav.svg'
import { ModalContext } from '../../context/ModalContext'
import { PlaygroundContext } from '../../context/PlaygroundContext'
import { useNavigate } from 'react-router-dom'
import Sidebar from "../../pages/Sidebar";
import html from "../../images/html_icon.png"
import javascript from "../../images/javascript_icon.png"
import python from "../../images/python_icon.png"
import java from "../../images/java_icon.png"
import cpp from "../../images/c++_icon.png"

const StyledRightComponent = styled.div`
    position: absolute;
    top: 30px;
    right: 0;
    width: 80%;
    padding: 2rem;
    margin-top: 50px;

    @media (max-width: 768px){
        position: relative;
        width: 100%;
        padding: 1rem 0.5rem;
    }
`

const Header = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0.75rem 0;
  border-bottom: 1px solid #989898;
  margin-bottom: 1rem;
`

const Heading = styled.h3`
  font-size: ${props => props.size === 'small' ? "1.25rem" : "1.125rem"};
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  span{
    font-weight: 700;
  }
`

const AddButton = styled.div`
    font-size: 1rem;
    border-radius: 30px;
    color: black;
    display: flex;
    align-items: center;
    gap: 0.25rem;
    span{
        font-size: 1.5rem;
        font-weight: 700;
    }

    &:hover{
        cursor: pointer;
    }
`

const FolderCard = styled.div`
    margin-bottom: 1rem;
`

const FolderIcons = styled.div`
    display: flex;
    align-items: center;
    gap: 0.7rem;
    cursor: pointer;
`

const PlayGroundCards = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
margin-bottom: 2%;
    @media (max-width: 428px){
        grid-template-columns: 1fr;
    }    
`

const Card = styled.div`
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    box-shadow: 0 0 4px 0px #989898;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover{
      scale: 1.05;
      box-shadow: 0 0 8px 0px #989898;
    }
`

const CardContainer = styled.div`
  display: flex;
  align-items: center;
`

const CardContent = styled.div`
`

const Logo = styled.img`
    width: 70px;
    margin-right: 1rem;

    @media (max-width: 425px){
        width: 50px;
        margin-right: 0.5rem;
    }
`
const Button = styled.button`
  padding: 0.7rem 0.4rem;
  width: 10rem;
  background: #5627ff;
  border: none;
  border-radius: 32px;
  font-weight: 700;
  cursor: pointer;
  color:white;
`
const RightComponent = () => {
  const navigate = useNavigate();
  function navToHome() {
    navigate("/frontend");
  }
  const { openModal } = useContext(ModalContext);
  const { folders, deleteFolder, deleteCard } = useContext(PlaygroundContext);

  return (
    <>
      <Sidebar />
      <StyledRightComponent>
        <Header>
          <Heading size="large" class="common_main_heading">
            Create <span>Playground !</span>
          </Heading>
          {/* <AddButton onClick={() => openModal({
          show: true,
          modalType: 1,
          identifiers: {
            folderId: "",
            cardId: "",
          }
        })}> <span>+</span> New Folder</AddButton> 
        <Button onClick={navToHome}>Back to Home</Button> */}
        </Header>

        {
          Object.entries(folders).map(([folderId, folder]) => (
            <FolderCard key={folderId}>

              <PlayGroundCards>

                {
                  Object.entries(folder['playgrounds']).map(([playgroundId, playground]) => (
                    <div>
                      {playground.title === "HTML" ? <Card onClick={navToHome}>
                        <CardContainer>
                          <Logo src={html} />
                          <CardContent>
                            <p class="common_main_text_highlight">HTML/CSS</p>
                            <p style={{ color: "grey" }} class="common_main_text_normal">HTML/CSS/JS playground</p>
                          </CardContent>
                        </CardContainer>
                      </Card> :
                        <Card key={playgroundId} onClick={() => {
                          navigate(`/playground/${folderId}/${playgroundId}`)
                        }}>
                          <CardContainer>
                            {playground.title === "JavaScript" ? <Logo src={javascript} /> :
                              playground.title === "Python" ? <Logo src={python} /> :
                                playground.title === "Java" ? <Logo src={java} /> :
                                  playground.title === "C++" ? <Logo src={cpp} />


                                    : <Logo src={logo} />}

                            <CardContent>
                              <p class="common_main_text_highlight">{playground.title}</p>
                              <p style={{ color: "grey" }} class="common_main_text_normal">{playground.desc}</p>
                            </CardContent>
                          </CardContainer>

                        </Card>}
                    </div>
                  ))
                }
              </PlayGroundCards>
            </FolderCard>
          ))
        }
      </StyledRightComponent>
    </>
  )


}

export default RightComponent;