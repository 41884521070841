import React, { useState, useEffect } from 'react';
import moment from 'moment';

const SessionTimer = ({ date, time, onJoinEnable = () => {} }) => {
  const [timeLeft, setTimeLeft] = useState("");

  useEffect(() => {
    const sessionStartTime = moment(date).set({
      hour: moment(time, "hh:mm A").hour(),
      minute: moment(time, "hh:mm A").minute(),
      second: 0,
    });

    let sessionEndTime = sessionStartTime.clone().add(15, 'minutes'); 
    const now = moment();

    const updateTimer = () => {
      const now = moment();

      if (now.isSameOrAfter(sessionStartTime) && now.isBefore(sessionEndTime)) {
        
        setTimeLeft("mentor joined");
        onJoinEnable(true);
      } else if (now.isSameOrAfter(sessionEndTime)) {
        
        setTimeLeft("session ended");
        onJoinEnable(false);
      } else {
        
        const durationUntilStart = moment.duration(sessionStartTime.diff(now));

        if (durationUntilStart.asMinutes() <= 5) {
          onJoinEnable(true); 
        }

        if (durationUntilStart.asSeconds() <= 0) {
          setTimeLeft("session started");
        } else {
          setTimeLeft(formatDuration(durationUntilStart));
        }
      }
    };

    updateTimer();
    const intervalId = setInterval(updateTimer, 1000);

    return () => clearInterval(intervalId);
  }, [date, time, onJoinEnable]);

 
  const formatDuration = (duration) => {
    const days = Math.floor(duration.asDays());
    const hours = Math.floor(duration.asHours()) % 24;
    const minutes = duration.minutes();
    const seconds = duration.seconds();

    let timeString = "";

    if (days >= 1) {
      timeString += `${days}d `;
    }
    if (hours > 0) {
      timeString += `${hours}hrs `;
    }
    if (minutes > 0) {
      timeString += `${minutes}mins `;
    }
    if (seconds > 0 || timeString === "") {
      timeString += `${seconds}s`;
    }

    return timeString.trim() + " left";
  };

  return <span>{timeLeft}</span>;
};

export default SessionTimer;
